export class Wildcard {
  title: string;
  value: string;
  color?: string;

  constructor(title: string, value: string, color?: string) {
    this.title = title;
    this.value = value;
    this.color = color;
  }

  static getList(): Wildcard[][] {
    return [
      [
        new Wildcard('Preset', '%P', '#112A45'),
        new Wildcard('Input Name', '%I', '#112A45'),
        new Wildcard('Input label', '%i', '#112A45'),
        new Wildcard('Encoder', '%E', '#112A45'),
        new Wildcard('First TC', '%C', '#112A45'),
        new Wildcard('_Proxy', '%x', '#112A45'),
        new Wildcard('Take Number', '%t', '#112A45'),
        new Wildcard('Seg Number', '%g', '#112A45'),
        new Wildcard('Roll Number', '%R', '#112A45'),
        new Wildcard('Scene', '%S', '#112A45'),
        new Wildcard('Sub Scene', '%u', '#112A45'),
        new Wildcard('EditShare Proxy Path', '%e', '#112A45'),
        new Wildcard('UUID', '%U', '#112A45'),
      ],
      [
        new Wildcard('Arri Clip', '%c', '#403D1C'),
        new Wildcard('Arri Take', '%v', '#403D1C'),
        new Wildcard('Arri Reel', '%r', '#403D1C'),
      ],
      [
        new Wildcard('List 1', '%1', '#732C2C' + '80'),
        new Wildcard('List 2', '%2', '#86632F' + '80'),
        new Wildcard('List 3', '%3', '#678132' + '80'),
        new Wildcard('List 4', '%4', '#2D777B' + '80'),
        new Wildcard('List 5', '%5', '#4836B2' + '80'),
        new Wildcard('List 6', '%6', '#9236B2' + '80'),
        new Wildcard('List 7', '%7', '#003399' + '80'),
        new Wildcard('List 8', '%8', '#808000' + '80'),
        new Wildcard('List 9', '%9', '#355e3b' + '80'),
        new Wildcard('List 10', '%0','#b5651d' + '80'),
      ],
      // [
      //   new Wildcard('Local 1', '', '#301B1B'),
      //   new Wildcard('Local 2', '', '#301B1B'),
      //   new Wildcard('Local 3', '', '#301B1B'),
      //   new Wildcard('Local 4', '', '#301B1B'),
      //   new Wildcard('Local 5', '', '#301B1B'),
      //   new Wildcard('Local 6', '', '#301B1B'),
      //   new Wildcard('Local 7', '', '#301B1B'),
      //   new Wildcard('Local 8', '', '#301B1B'),
      //   new Wildcard('Local 9', '', '#301B1B'),
      // ],
      [
        new Wildcard('Global 1', '%A', '#272D13'),
        new Wildcard('Global 2', '%B', '#272D13'),
        new Wildcard('Global 3', '%F', '#272D13'),
        new Wildcard('Global 4', '%J', '#272D13'),
        new Wildcard('Global 5', '%K', '#272D13'),
        new Wildcard('Global 6', '%L', '#272D13'),
      ],
      [
        new Wildcard('YYYY MMDD', '%D', '#19272C'),
        new Wildcard('YYYY', '%Y', '#19272C'),
        new Wildcard('YY', '%y', '#19272C'),
        new Wildcard('MM', '%M', '#19272C'),
        new Wildcard('DD', '%d', '#19272C'),
      ],
      [
        new Wildcard('HHMMSS', '%T', '#192F1F'),
        new Wildcard('HH', '%H', '#192F1F'),
        new Wildcard('MM', '%m', '#192F1F'),
        new Wildcard('SS', '%s', '#192F1F'),
        new Wildcard('Days of Week Full', '%W', '#192F1F'),
        new Wildcard('Days of Week Short', '%w', '#192F1F'),
      ],
    ];
  }

  static getNameSeparators(): Wildcard[] {
    return [
      new Wildcard('_', '_', '#A66E1B'),
      new Wildcard('-', '-', '#5B4626'),
      new Wildcard(' ', ' ', '#5C5C5C'),
    ];
  }

  static getPathSeparators(): Wildcard[] {
    return [
      new Wildcard('\\', '\\', '#3A2608'),
    ];
  }

}
