import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material modules
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

// External modules
import { NgxElectronModule } from 'ngx-electron';

// Services
import { SettingsService } from './services/settings.service';
import { StateService } from './services/state.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    // Material
    MatIconModule,

    // External
    NgxElectronModule
  ],
  providers: [
    MatIconRegistry,
    SettingsService,
    StateService,
  ],
})
export class SettingsModule { }
