<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Project Settings</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="project-settings-content">

    <!-- Export / Import -->
    <div class="row">
      <div class="title">Project settings</div>
      <div class="button" (click)="exportProjectSettings()">Export</div>
      <div *ngIf="!isWeb" class="button" (click)="importProjectSettings()">Import</div>
      <label *ngIf="isWeb" for="importFile" class="button">Import</label>
      <input *ngIf="isWeb" type="file" id="importFile" (change)="importProjectSettingFile($event)" hidden>
    </div>

    <!-- Messages -->
    <div class="message" *ngIf="alertMessage.exportSuccess" (click)="alertMessage.exportSuccess = false">
      <div class="title">Saved</div>
      <div class="description">Note: if Presets use a different path, please select it again after importing the settings</div>
    </div>
    <div class="message" *ngIf="alertMessage.importSuccess" (click)="alertMessage.importSuccess = false">
      <div class="title">Applied</div>
      <div class="description">Note: if Presets use a different path, please select it again after importing the settings</div>
    </div>
    <div class="message error" *ngIf="alertMessage.exportError" (click)="alertMessage.exportError = null">
      <div class="title">Error</div>
      <div class="description">{{ alertMessage?.exportError?.message }}</div>
    </div>
    <div class="message error" *ngIf="alertMessage.importError" (click)="alertMessage.importError = null">
      <div class="title">Error</div>
      <div class="description">{{ alertMessage?.importError?.message }}</div>
    </div>

    <!-- Debug -->
    <mat-checkbox class="checkbox debug" [checked]="project?.debug" (change)="checkDebug($event.checked)" disableRipple>Debug</mat-checkbox>

  </div>
</div>
