<div class="user-login-content">

  <!-- Users -->
  <div class="users">

    <!-- Root -->
    <div class="user root" (click)="selectUser('root')" [class.selected]="selectedUser === 'root'">
      <mat-icon svgIcon="user" class="icon"></mat-icon>
      <div class="title">Superuser</div>
    </div>

    <!-- Admin -->
    <div class="user admin" (click)="selectUser('admin')" [class.selected]="selectedUser === 'admin'">
      <mat-icon svgIcon="user" class="icon"></mat-icon>
      <div class="title">Admin</div>
    </div>

    <!-- User -->
    <div class="user operator" (click)="selectUser('user')" [class.selected]="selectedUser === 'user'">
      <mat-icon svgIcon="user" class="icon"></mat-icon>
      <div class="title">Operator</div>
    </div>

    <!-- Guest -->
    <div class="user guest" (click)="selectUser('guest')" [class.selected]="selectedUser === 'guest'">
      <mat-icon svgIcon="user" class="icon"></mat-icon>
      <div class="title">Guest</div>
    </div>

  </div>

  <!-- Login -->
  <div class="login">
    <input *ngIf="selectedUser" class="input" type="password" [(ngModel)]="password" (ngModelChange)="resetError()" (keydown.enter)="login()">
    <div *ngIf="selectedUser" class="button" (click)="login()">Login</div>
  </div>

  <!-- Error -->
  <div class="error" *ngIf="selectedUser && loginError">Password is not correct. Please check and try again.</div>

</div>
