import { Directive, ElementRef, HostListener, Input } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { RS422ScrollActions } from '../types/rs422-scroll-actions';
import { JogSpeedometer } from '../types/jog-speedometer';


@Directive({
  selector: '[appRs422Scroll]'
})
export class Rs422ScrollDirective {

  // Input
  @Input('appRs422ScrollActions') actions: RS422ScrollActions = new RS422ScrollActions();

  // Private
  private dragging: boolean = false;
  private mouseMoved: boolean = false;
  private jogSpeedometer = new JogSpeedometer();
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef
  ) {
    this.jogSpeedometer.forwardSpeedChanged
      .pipe(takeUntil(this.alive))
      .subscribe(value => this.actions.jog.next(value));
    this.jogSpeedometer.reverseSpeedChanged
      .pipe(takeUntil(this.alive))
      .subscribe(value => this.actions.jog.next(-value));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.mouseMoved) {
      this.mouseMoved = false;
      return;
    }
    this.sendVarAndShuttleAction(event);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.dragging = true;
    this.actions.mouseStart.next();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.dragging) {
      // VAR or SHUTTLE
      this.mouseMoved = true;
      const rect = this.elementRef.nativeElement.getBoundingClientRect();
      this.sendVarAndShuttleAction(event, rect);
      // JOG
      this.jogSpeedometer.setPosition(event, rect);
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (this.dragging) {
      this.actions.mouseStop.next();
      if (this.mouseMoved) {
        this.actions.mouseMoveStop.next();
      }
    }
    this.dragging = false;
  }

  /**
   * Speed
   */

  sendVarAndShuttleAction(event: MouseEvent, rect?: DOMRect): void {
    rect = rect || this.elementRef.nativeElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const persent = x / rect.width;
    const varSpeed = this.getVarSpeed(persent);
    const shuttleSpeed = this.getShuttleSpeed(persent);
    this.actions.var.next(varSpeed);
    this.actions.shuttle.next(shuttleSpeed);
  }

  getVarSpeed(persent: number): number {
    let mult = 1;
    let speed = persent;
    if (speed < 0.5) {
      mult = -1;
      speed = (0.5 - speed);
    } else {
      speed = speed - 0.5;
    }
    speed = speed * 5;
    return mult * Math.min(speed, 2.2);
  }

  getShuttleSpeed(persent: number): number {
    let mult = 1;
    let speed = persent;
    if (speed < 0.5) {
      mult = -1;
      speed = (0.5 - speed);
    } else {
      speed = speed - 0.5;
    }
    if (speed < 0.1) {
      return mult * speed * 10;
    }
		speed = speed - 0.1;
    return mult * Math.min(Math.exp(speed  * 7.5), 20);
  }


}
