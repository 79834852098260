export class Resolution {
  width: number;
  height: number;
  interlaced?: boolean;

  constructor(width: number, height: number, interlaced?: boolean) {
    this.width = width;
    this.height = height;
    this.interlaced = interlaced;
  }

  static getVideoResolution(resolution: string, fps?: string): Resolution {
    switch(resolution) {
      case 'ntsc':
        const interlaced = !(fps === '23.98' || fps === '24')
        return new Resolution(720, 486, interlaced);
  
      case 'ntsc-p':
        return new Resolution(720, 480, false);
  
      case 'pal':
        return new Resolution(720, 576, true);
  
      case 'pal-p':
        return new Resolution(720, 576, false);
  
      case '720p':
        return new Resolution(1280, 720, false);
  
      case '1080i':
        return new Resolution(1920, 1080, true);
  
      case '1080p':
        return new Resolution(1920, 1080, false);
  
      // case CINEDECK_RES_1080p_HALF:
      //   return new Resolution(960, 540, false);
  
      case '2k':
        return new Resolution(2048, 1080, false);
  
      // case CINEDECK_RES_2K_HALF:
      //   return new Resolution(1024, 540, false);
  
      case '4k':
        return new Resolution(4096, 2160, false);
  
      case 'uhd':
        return new Resolution(3840, 2160, false);
  
      case 'invalid':
      default:
        return new Resolution(0, 0, false);
    }
    return new Resolution(0, 0, false);
  }
}
