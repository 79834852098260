import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { ChannelService } from '@modules/channel/services/channel.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Channel } from '@modules/channel/types/channel';
import { Option } from '@modules/elements/types/option';
import { ChannelLayoutStatus } from '@modules/channel/types/channel-layout-settings';
import { ChannelLayoutSettings } from '@modules/channel/types/channel-layout-settings';

@Component({
  selector: 'app-channel-layout-settings',
  templateUrl: './channel-layout-settings.component.html',
  styleUrls: ['./channel-layout-settings.component.less']
})
export class ChannelLayoutSettingsComponent implements OnInit, OnDestroy {

  // Public
  public channel: Channel;
  public options: Option<ChannelLayoutStatus>[] = [
    new Option('hover', 'Hover'),
    new Option('always', 'Always')
  ];
  public allOptions: Option<ChannelLayoutStatus>[] = [new Option<ChannelLayoutStatus>('never', 'Never')].concat(this.options);
  public partialOptions: Option<ChannelLayoutStatus>[] = [
    new Option('never', 'Never'),
    new Option('always', 'Always')
  ];
  public diskTimeOptions: Option<number>[] = [
    new Option(-1, 'Show all'),
    new Option(0, 'Manual'),
    new Option(10000, '10s'),
    new Option(30000, '30s'),
    new Option(60000, '1m'),
    new Option(300000, '5m'),
  ];

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private channelService: ChannelService,
    public dialogRef: MatDialogRef<ChannelLayoutSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {channel: Channel},
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.channelService.getChannel(this.data.channel.id)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(channel => {
        this.channel = channel;
        this.checkForNullValuesLayoutSettings();
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  checkForNullValuesLayoutSettings(): void {
    if (!this.channel?.layoutSettings) {
      return;
    }
    let valueUpdated = false;
    const layoutSettings = new ChannelLayoutSettings();
    const propertis = Object.getOwnPropertyNames(layoutSettings);
    for (const key of propertis) {
      if (this.channel.layoutSettings[key] === null || this.channel.layoutSettings[key] === undefined) {
        this.channel.layoutSettings[key] = layoutSettings[key];
        valueUpdated = true;
      }
    }
    if (valueUpdated) {
      this.channelService.updateChannel(this.channel);
    }
  }

  /**
   * Actions
   */

  updateLayout(field: string, option: Option<ChannelLayoutStatus>): void {
    this.channel.layoutSettings[field] = option.value;
    this.channelService.updateChannel(this.channel);
  }

  updateField(field: string, value: number|string|boolean): void {
    this.channel.layoutSettings[field] = value;
    this.channelService.updateChannel(this.channel);
  }

  copyToAllChannels(): void {
    const channels = this.channelService.getAllChannelsSync();
    channels.forEach(channel => channel.layoutSettings = JSON.parse(JSON.stringify(this.channel.layoutSettings)));
    this.channelService.updateChannels(channels);
  }

  close(): void {
    this.dialogRef.close();
  }


}
