import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

// Services
import { PopoverService } from '@modules/popover/services/popover.service';

// Pipes
import { FilenameNormalizePipe } from '@modules/elements/pipes/filename-normalize.pipe';

// Types
import { UserList, UserListItem } from '@modules/preset/types/user-list';
import { NodeService } from '@modules/node/services/node.service';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.less']
})
export class TagListComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() list: UserList;
  @Input() selected: UserListItem;
  @Input() editing: UserListItem;
  @Input() showSettings: boolean = false;

  // Output
  @Output() select = new EventEmitter<{list: UserList, item: UserListItem}>();
  @Output() create = new EventEmitter<{list: UserList, item: UserListItem}>();
  @Output() update = new EventEmitter<{list: UserList, item: UserListItem}>();
  @Output() delete = new EventEmitter<{list: UserList, item: UserListItem}>();
  @Output() updateList = new EventEmitter<UserList>();
  @Output() export = new EventEmitter<UserList>();
  @Output() import = new EventEmitter<{list: UserList, file?: any}>();

  // Public
  public itemNames: string[];
  public isTagSelected = (item: UserListItem): boolean => {
    return this.selected.id !== item.id;
  };
  public isWeb = false;

  // Private
  private popoverClose = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private popoverService: PopoverService,
    private nodeService: NodeService,
    private filenameNormalizePipe: FilenameNormalizePipe,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.isWeb = !this.nodeService.isElectron;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('list' in changes) {
      this.updateListNames();
    }
  }

  ngOnDestroy(): void {
    this.popoverClose.next();
    this.popoverClose.complete();
  }

  /**
   * Methods
   */

  updateListNames(): void {
    this.itemNames = this.list.items.map(item => item.name);
  }

  itemIds(index: number, item: UserListItem) {
    return item?.id;
  }

  /**
   * Actions
   */

  selectTag(item: UserListItem): void {
    this.selected = item;
    this.select.emit({list: this.list, item});
    this.updateListNames();
  }

  createTag(name: string): void {
    const normalizeName = this.filenameNormalizePipe.transform(name);
    const item = new UserListItem(normalizeName);
    this.create.emit({list: this.list, item});
    this.updateListNames();
  }

  deleteTag(item: UserListItem): void {
    if (!item?.id) {
      return;
    }
    this.delete.emit({list: this.list, item});
    this.updateListNames();
  }

  renameTag(item: UserListItem): void {
    this.editing = item;
  }

  changeListName(name: string): void {
    this.list.name = name;
    this.updateList.emit(this.list);
    this.updateListNames();
  }

  changeTagName(name: string, item: UserListItem): void {
    const normalizeName = this.filenameNormalizePipe.transform(name);
    item.name = normalizeName;
    this.update.emit({list: this.list, item});
    this.updateListNames();
  }

  tagEditingChange(editing: boolean, item: UserListItem): void {
    this.editing = editing ? item : null;
  }

  showCreatePopover(template: TemplateRef<any>, iconElement: any): void {
    this.popoverService.create(
      iconElement['_elementRef'],
      {
        content: template,
        placement: 'top',
        trigger: 'click',
        innerShadow: false,
        arrow: true,
        popoverOffsetY: 0,
        showUntil: this.popoverClose
      }
    );
  }

  exportList(): void {
    this.export.emit(this.list);
  }

  importList(): void {
    this.import.emit({list: this.list});
  }

  importListFile(event): void {
    const file = event.target?.files[0];
    if (file) {
      this.import.emit({list: this.list, file});
    }
  }

  deleteAllTags(): void {
    this.selected = null;
    this.list.items = [];
    this.list.selected = null;
    this.updateList.emit(this.list);
    this.selectTag(null);
  }

}
