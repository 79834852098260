import { Injectable, OnDestroy } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { map, switchMap, startWith, filter, distinctUntilChanged, tap, throttleTime } from 'rxjs/operators';

// Decorators
import { warmUpObservable } from '@decorators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { NetworkService } from '@modules/core/services/network.service';
import { NotificationService } from '@modules/elements/services/notification.service';

// Types
import { Deck } from '../types/deck';
import { DeckSocket } from '../types/deck-socket';
import { GlobalState } from '@modules/settings/types/global-state';
import { DeckDrive, DeckStatus } from '../types/deck-status';
import { DeckError } from '../types/deck-error';
import { DeckChannel } from '../types/deck-channel';
import { RS422Info } from '@modules/rs422/types/rs422-info';
import { RS422Timecodes } from '@modules/rs422/types/rs422-timecodes';
import { EncoderRecordQueue } from '@modules/stream/types/encoder-record-queue';
import { StreamTimecode } from '@modules/stream/types/stream-timecode';

@Injectable({
  providedIn: 'root'
})
export class DeckService implements OnDestroy {

  // Private
  private decks: Observable<Deck[]>;
  private decksValue: Deck[];
  private connections = new Map<string, DeckSocket>();
  private deckChannels = new Map<string, BehaviorSubject<DeckChannel[]>>();
  private statuses = new Map<string, BehaviorSubject<DeckStatus>>();
  private timecodes = new Map<string, BehaviorSubject<StreamTimecode[]>>();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private networkService: NetworkService,
    private notificationService: NotificationService,
  ) {
    // Get Decks
    this.decks = this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.decks)
      );
    // Create connection with each Deck
    this.decks.subscribe(decks => {
      this.decksValue = decks;
      decks.forEach(deck => this.getConnection(deck));
    });
    // Show Decks Error in Notification center
    this.decks
      .pipe(
        switchMap(decks => merge(...decks.map(deck => this.error(deck).pipe(map(error => ({deck, error})))))),
        filter(({deck, error}) => !(error?.input !== undefined && error?.input !== null && error.input >= 0)),
        filter(({deck, error}) => {
          return !error?.message?.toLowerCase()?.includes('frame replay');
        }),
      )
      .subscribe(({deck, error}) => this.notificationService.error(error.message, `${deck.name} (${deck.address})`));
    // Show Decks Success in Notification center
    this.decks
      .pipe(
        switchMap(decks => merge(...decks.map(deck => this.success(deck).pipe(map(success => ({deck, success})))))),
      )
      .subscribe(({deck, success}) => this.notificationService.success(success.message, `${deck.name} (${deck.address})`));
    this.decks
      .pipe(
        switchMap(decks => merge(...decks.map(deck => this.warning(deck).pipe(map(warning => ({deck, warning})))))),
      )
      .subscribe(({deck, warning}) => this.notificationService.warning(warning.message, `${deck.name} (${deck.address})`));
    // Get decks channels count
    this.decks
      .pipe(
        // Create base objects
        tap((decks: Deck[]) => {
          for (const deck of decks) {
            if (!this.deckChannels.has(deck.id)) {
              this.deckChannels.set(deck.id, new BehaviorSubject([]));
            }
          }
        }),
        distinctUntilChanged((previous, current) => previous?.length === current?.length && isEqual(previous.keys(), current.keys()) && isEqual(previous, current)),
        // Online status changed
        switchMap((decks: Deck[]) => 
          combineLatest(decks.map(deck =>
            this.getOnlineStatus(deck)
              .pipe(map(isOnline => { return {deck: deck, isOnline}} ))
          ))
        ),
        distinctUntilChanged(isEqual),
        map((decks: {deck: Deck, isOnline: boolean}[]) => decks.map(deck => deck.deck)),
        // Get channels info
        switchMap((decks: Deck[]) =>
          combineLatest(decks.map(deck =>
            this.getChannels(deck)
              .pipe(
                startWith(this.deckChannels.get(deck.id).value),
                map(channels => { return {id: deck.id, channels: channels}} )
              )
          ))
        )
      )
      .subscribe((channels: {id: string, channels: DeckChannel[]}[]) => {
        for (const channel of channels) {
          if (!isEqual(this.deckChannels.get(channel.id).value, channel.channels)) {
            this.deckChannels.get(channel.id).next(channel.channels);
          }
        }
      });
  }

  /**
   * Service lifecycle
   */

  ngOnDestroy() {
    this.connections.forEach(connection => connection.disconnect());
    this.connections.clear();
  }

  /**
   * WebSocket Connections
   */

  private getConnection(deck: Deck): DeckSocket {
    if (!deck || !deck?.id) {
      console.error('Can not find deck: ', deck);
      return;
    }

    // Variables
    const key = deck.id;

    // Return
    if (this.connections.has(key)) {
      return this.connections.get(key);
    } else {
      const connection = new DeckSocket(deck);
      // Status
      connection?.on<DeckStatus>('status')
        .subscribe(status => {
          if (!this.statuses.has(key)) {
            this.statuses.set(key, new BehaviorSubject(null));
          }
          this.statuses.get(key).next(status);
        });
      // Timecodes
      connection?.on<StreamTimecode[]>('timecodesUpdate')
        .subscribe(timecodes => {
          if (!this.timecodes.has(key)) {
            this.timecodes.set(key, new BehaviorSubject(null));
          }
          this.timecodes.get(key).next(timecodes);
        });
      // Connections
      this.connections.set(key, connection);
      return connection;
    }
  }

  disconnect(deck: Deck): void {
    // Variables
    const key = deck.id;

    if (this.connections.has(key)) {
      const connection = this.connections.get(key);
      connection.disconnect();
      this.connections.delete(key);
      this.statuses.delete(key);
    }
  }

  /**
   * WebSocket Events
   */

  getStatus(deck: Deck): Observable<DeckStatus> {
    if (!deck) {
      return of(null);
    }
    if (!this.statuses.has(deck.id)) {
      this.statuses.set(deck.id, new BehaviorSubject(null));
    }
    return this.statuses.get(deck.id)?.asObservable();
  }

  getTimecodes(deck: Deck): Observable<StreamTimecode[]> {
    if (!deck) {
      return of(null);
    }
    if (!this.timecodes.has(deck.id)) {
      this.timecodes.set(deck.id, new BehaviorSubject(null));
    }
    return this.timecodes.get(deck.id)?.asObservable();
  }

  getCurrentTimecodes(deck: Deck): StreamTimecode[] {
    if (!deck) {
      return null;
    }
    if (!this.timecodes.has(deck.id)) {
      this.timecodes.set(deck.id, new BehaviorSubject(null));
    }
    return this.timecodes.get(deck.id)?.value;
  }

  getOnlineStatus(deck: Deck): Observable<boolean> {
    const connection = this.getConnection(deck);
    return connection?.connected() || of(false);
  }

  isOnline(deck: Deck): boolean {
    const connection = this.getConnection(deck);
    return connection?.isConnected() || false;
  }

  error(deck: Deck): Observable<DeckError> {
    const connection = this.getConnection(deck);
    return connection?.on<DeckError>('error');
  }

  success(deck: Deck): Observable<DeckError> {
    const connection = this.getConnection(deck);
    return connection?.on<DeckError>('success');
  }

  warning(deck: Deck): Observable<DeckError> {
    const connection = this.getConnection(deck);
    return connection?.on<DeckError>('warning');
  }

  projectUpdated(deck: Deck): Observable<{input: number}> {
    const connection = this.getConnection(deck);
    return connection?.on<{input: number}>('projectUpdate');
  }

  ndiInputsUpdated(deck: Deck): Observable<{input: number}> {
    const connection = this.getConnection(deck);
    return connection?.on<{input: number}>('ndiInputsUpdate');
  }

  inputSettingsUpdated(deck: Deck): Observable<{input: number}> {
    const connection = this.getConnection(deck);
    return connection?.on<{input: number}>('inputSettingsUpdate');
  }

  clipAdded(deck: Deck): Observable<any> {
    const connection = this.getConnection(deck);
    return connection?.on<any>('clipAdded');
  }

  segmentBreak(deck: Deck): Observable<any> {
    const connection = this.getConnection(deck);
    return connection?.on<any>('segmentBreak');
  }

  remoteStatus(deck: Deck): Observable<{index: number, status: RS422Info}> {
    const connection = this.getConnection(deck);
    return connection?.on<{index: number, status: RS422Info}>('remoteStatus');
  }

  remoteTimecodes(deck: Deck): Observable<RS422Timecodes> {
    const connection = this.getConnection(deck);
    return connection?.on<RS422Timecodes>('remoteTimecodes');
  }

  rs422ModeChanged(deck: Deck): Observable<{index: number, mode: number}> {
    const connection = this.getConnection(deck);
    return connection?.on<{index: number, mode: number}>('rs422ModeChanged');
  }

  recordQueueUpdated(deck: Deck): Observable<{encoders: EncoderRecordQueue[]}[]> {
    const connection = this.getConnection(deck);
    return connection?.on<{encoders: EncoderRecordQueue[]}[]>('recordQueueUpdated');
  }

  playlistsChanged(deck: Deck): Observable<any> {
    const connection = this.getConnection(deck);
    return connection?.on<any>('playlistsChanged');
  }

  filenameChanged(deck: Deck): Observable<{input: number}> {
    const connection = this.getConnection(deck);
    return connection?.on<{input: number}>('filenameChanged');
  }

  loggerDBChanged(deck: Deck): Observable<any> {
    const connection = this.getConnection(deck);
    return connection?.on<any>('loggerDBChanged');
  }

  recordFinished(deck: Deck): Observable<{input: number}> {
    const connection = this.getConnection(deck);
    return connection?.on<any>('recordFinished');
  }

  trimFinisned(deck: Deck): Observable<any> {
    const connection = this.getConnection(deck);
    return connection?.on<any>('trimFinisned');
  }

  /**
   * Deck
   */

  getDecks(): Observable<Deck[]> {
    return this.decks;
  }

  getDecksSync(): Deck[] {
    return this.decksValue;
  }

  getDeck(id: string): Observable<Deck> {
    return this.getDecks()
      .pipe(
        map(decks => decks.find(deck => deck.id === id))
      );
  }

  getDeckSync(id: string): Deck {
    return this.decksValue.find(item => item.id === id);
  }

  addDeck(deck: Deck): void {
    const decks = this.decksValue;
    decks.push(deck);
    this.stateService.updateState({decks});
  }

  updateDeck(deck: Deck): void {
    const decks = this.decksValue;
    const index = decks.findIndex(item => item.id === deck.id);
    if (index !== -1) {
      decks[index] = deck;
      this.stateService.updateState({decks});
    }
  }

  deleteDeck(deck: Deck): void {
    const decks = this.decksValue;
    const index = decks.indexOf(deck);
    if (index !== -1) {
      this.disconnect(deck);
      decks.splice(index, 1);
      this.stateService.updateState({decks});
    }
  }

  getChannels(deck: Deck): Observable<DeckChannel[]> {
    if (!this.isOnline(deck)) {
      return of([]);
    }
    return this.networkService.getChannels(Deck.getAddress(deck));
  }

  getChannelsSubject(deck: Deck): Observable<DeckChannel[]> {
    return this.deckChannels.get(deck.id).asObservable();
  }

  getChannelsSubjectSync(deck: Deck): DeckChannel[] {
    return this.deckChannels.get(deck.id).value;
  }

  getDrives(deck: Deck): Observable<DeckDrive[]> {
    return this.getStatus(deck)
      .pipe(
        map((status) => status?.drives),
        throttleTime(5000),
        distinctUntilChanged(isEqual),
      );
  }

  /**
   * Deck Control
   */

  restart(deck: Deck): void {
    if (!this.isOnline(deck)) { return; }
    this.networkService.restart(Deck.getAddress(deck));
  }

  exit(deck: Deck): void {
    if (!this.isOnline(deck)) { return; }
    this.networkService.exit(Deck.getAddress(deck));
  }

  restartDevice(deck: Deck): void {
    if (!this.isOnline(deck)) { return; }
    this.networkService.restartDevice(Deck.getAddress(deck));
  }

  shutdownDevice(deck: Deck): void {
    if (!this.isOnline(deck)) { return; }
    this.networkService.shutdownDevice(Deck.getAddress(deck));
  }

  deleteFileDatabase(deck: Deck): void {
    if (!this.isOnline(deck)) { return; }
    this.networkService.deleteFileDatabase(Deck.getAddress(deck));
  }

  deleteSettings(deck: Deck): void {
    if (!this.isOnline(deck)) { return; }
    this.networkService.deleteSettings(Deck.getAddress(deck));
  }

  /**
   * UI Mode
   */

  updateFileOverwrite(deck: Deck, fileOverwrite: boolean): Observable<void> {
    if (!this.isOnline(deck)) { return; }
    return this.networkService.updateFileOverwrite(Deck.getAddress(deck), fileOverwrite);
  }

  /**
   * UI Mode
   */

  getMode(deck: Deck): Observable<string> {
    if (!this.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.getUiMode(Deck.getAddress(deck));
  }

  updateMode(deck: Deck, mode: string): Observable<any> {
    if (!this.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.updateUiMode(Deck.getAddress(deck), mode);
  }

  getModes(deck: Deck): Observable<string[]> {
    if (!this.isOnline(deck)) {
      return of([]);
    }
    return this.networkService.getUiModes(Deck.getAddress(deck));
  }

  /**
   * NDI IP
   */

  getNdiIPs(deck: Deck): Observable<string[]> {
    if (!this.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.getNdiIPs(Deck.getAddress(deck));
  }

  @warmUpObservable
  updateNdiIPs(deck: Deck, ips: string[]): Observable<string[]> {
    if (!this.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.updateNdiIPs(Deck.getAddress(deck), ips);
  }

  @warmUpObservable
  changeNdiIPs(deck: Deck, ips: string[]): Observable<string[]> {
    if (!this.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.changeNdiIPs(Deck.getAddress(deck), ips);
  }

}
