import { Injectable } from '@angular/core';

// RxJS
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { Schedule } from '../types/schedule';
import { GlobalState } from '@modules/settings/types/global-state';
import { Stream } from '@modules/stream/types/stream';
import { ChannelsGroup } from '@modules/channel/types/channels-group';
import { ChannelService } from '@modules/channel/services/channel.service';
import { RrulePipe } from '@modules/elements/pipes/rrule.pipe';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  // Private
  private schedules: Observable<Schedule[]>;
  private schedulesValue: Schedule[];
  private timeouts: {[id: string]: number[]} = {};
  private channelsGroups: ChannelsGroup[];

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private streamService: StreamService,
    private channelService: ChannelService,
    private rrulePipe: RrulePipe
  ) {
    // Schedules
    this.schedules = this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.schedules)
      );
    this.schedules.subscribe(schedules => this.schedulesValue = schedules);

    // Create timers
    this.schedules.pipe(take(1)).subscribe(schedules => schedules.map(schedule => this.createTimers(schedule)));

    // Get Channel Groups
    this.channelService.getChannelsGroups().subscribe(groups => this.channelsGroups = groups);
  }

  /**
   * Schedule
   */

  getSchedules(): Observable<Schedule[]> {
    return this.schedules;
  }

  getSchedule(id: string): Observable<Schedule> {
    return this.getSchedules()
      .pipe(
        map(schedules => schedules.find(schedule => schedule.id === id))
      );
  }

  addSchedule(schedule: Schedule): void {
    const schedules = this.schedulesValue;
    schedules.push(schedule);
    this.stateService.updateState({schedules});
    this.createTimers(schedule);
  }

  updateSchedule(schedule: Schedule): void {
    const schedules = this.schedulesValue;
    const index = schedules.findIndex(item => item.id === schedule.id);
    if (index !== -1) {
      schedules[index] = schedule;
      this.stateService.updateState({schedules});
      this.deleteTimers(schedule);
      this.createTimers(schedule);
    }
  }

  deleteSchedule(schedule: Schedule): void {
    const schedules = this.schedulesValue;
    const index = schedules.findIndex(item => item.id === schedule.id);
    if (index !== -1) {
      schedules.splice(index, 1);
      this.stateService.updateState({schedules});
      this.deleteTimers(schedule);
    }
  }

  deleteScheduleForDeck(deckId: string): void {
    this.streamService.getStreams()
      .pipe(
        take(1),
        map(streams => streams.filter(stream => stream.deckId === deckId).map(stream => stream.id) || [])
      )
      .subscribe(streamIds => {
        const schedules = this.schedulesValue.filter(schedule => {
          const includes = streamIds.includes(schedule.streamId);
          if (includes) {
            this.deleteTimers(schedule);
          }
          return !includes;
        });
        this.stateService.updateState({schedules});
      });
  }

  /**
   * Timers
   */

  createTimers(schedule: Schedule): void {
    const startDate = schedule.interval === 'once' ? schedule.startDate : this.getRepeatScheduleStartDate(schedule);
    const endDate = schedule.interval === 'once' ? schedule.endDate :  this.getRepeatScheduleEndDate(schedule);
    const startTimeDiff = new Date(startDate).getTime() - Date.now();
    const endTimeDiff = new Date(endDate).getTime() - Date.now();
    if (!this.timeouts[schedule.id]) {
      this.timeouts[schedule.id] = [];
    }
    if (schedule.startDate && startTimeDiff > 0) {
      // Update preset if needed
      if (schedule.presetId) {
        const timeBeforeRecord = 5000;
        const presetTimeoutId = window.setTimeout(() => this.updatePreset(schedule), startTimeDiff - timeBeforeRecord);
        this.timeouts[schedule.id].push(presetTimeoutId);
      }
      // Start record
      const timeoutId = window.setTimeout(() => this.startRecord(schedule), startTimeDiff);
      this.timeouts[schedule.id].push(timeoutId);
    }
    if (schedule.endDate && endTimeDiff > 0) {
      const timeoutId = window.setTimeout(() => this.stopRecord(schedule), endTimeDiff);
      this.timeouts[schedule.id].push(timeoutId);
    }
  }

  deleteTimers(schedule: Schedule): void {
    const timers = this.timeouts[schedule.id];
    if (timers && timers.length) {
      timers.map(timeoutId => window.clearTimeout(timeoutId));
    }
    this.timeouts[schedule.id] = [];
  }

  /**
   * Record
   */

  updatePreset(schedule: Schedule): void {
    if (schedule.streamId) {
      // Set Preset
      this.streamService.setPreset(schedule.presetId, schedule.streamId, 'client');
    } else if (schedule.groupId) {
      this.getStreamsForGroup(schedule.groupId).subscribe(streams => {
        for (const stream of streams) {
          this.streamService.setPreset(schedule.presetId, stream.id, 'client');
        }
      });
    }
  }

  startRecord(schedule: Schedule): void {
    if (schedule.streamId) {
      // Start Record
      this.streamService.getStream(schedule.streamId)
        .pipe(take(1))
        .subscribe(stream => this.streamService.record(stream));
    } else if (schedule.groupId) {
      this.getStreamsForGroup(schedule.groupId).subscribe(streams => {
        this.streamService.groupRecord(streams);
      });
    }
  }

  stopRecord(schedule: Schedule): void {
    if (schedule.streamId) {
      // Stop Record
      this.streamService.getStream(schedule.streamId)
        .pipe(take(1))
        .subscribe(stream => this.streamService.stopRecord(stream));
    } else if (schedule.groupId) {
      this.getStreamsForGroup(schedule.groupId).subscribe(streams => {
        this.streamService.groupStopRecord(streams);
      });
    }
    if (schedule && schedule.interval === 'repeat') {
      this.createTimers(schedule);
      this.stateService.updateState({});
    }
  }

  getNearestScheduleForStream(stream: Stream): Observable<Schedule> {
    return combineLatest([
      this.getSchedules().pipe(map(schedules => schedules.filter(schedule => schedule?.streamId === stream?.id))),
      this.channelService.getChannels()
        .pipe(
          map(channels => channels.filter(channel => channel.streamId === stream?.id).map(channel => channel.id)),
          map(channelsIds => this.channelsGroups.filter(group => !!group.channelIds.find(id => channelsIds.includes(id))).map(group => group.id)),
          switchMap(channelsGroupsIds => this.getSchedules().pipe(map(schedules => schedules.filter(schedule => channelsGroupsIds.includes(schedule.groupId)))))
        )
    ])
      .pipe(
        map(([schedulesStreams, schedulesGroups]) => schedulesStreams.concat(schedulesGroups)),
        map(schedules => schedules.filter(schedule => schedule.interval !== 'once' || (schedule.interval === 'once' && new Date(schedule.startDate).getTime() > Date.now()))),
        map(schedules => schedules.sort((s1, s2) => this.getScheduleDate(s1).getTime() - this.getScheduleDate(s2).getTime())),
        map(schedules => schedules.length ? schedules[0] : null),
        distinctUntilChanged(isEqual)
      );
  }

  /**
   * Methods
   */

  private getStreamsForGroup(groupId: string): Observable<Stream[]> {
    return this.channelService.getChannelsGroups()
      .pipe(
        map(groups => groups.find(group => group.id === groupId)?.channelIds),
        filter(channelIds => !!channelIds),
        switchMap((channelIds: string[]) => 
          this.channelService.getChannels()
            .pipe(
              map(channels =>
                channels
                  .filter(channel => channelIds.includes(channel.id))
                  .map(channel => channel.streamId)
                  .filter(streamId => !!streamId)
              ),
            )
        ),
        switchMap(streamIds =>
          this.streamService.getStreams()
            .pipe(
              map(streams => streams.filter(stream => streamIds.includes(stream.id)))
            )
        ),
        take(1)
      );
  }

  getScheduleDate(schedule: Schedule): Date {
    const startDate = schedule.interval === 'once' ? new Date(schedule.startDate) : this.getRepeatScheduleStartDate(schedule);
    const endDate = schedule.interval === 'once' ? new Date(schedule.endDate) :  this.getRepeatScheduleEndDate(schedule);
    const startTimeDiff = new Date(startDate).getTime() - Date.now();
    const endTimeDiff = new Date(endDate).getTime() - Date.now();
    if (endTimeDiff > 0 && startTimeDiff < 0) {
      return endDate;
    }
    return startDate;
  }

  private getRepeatScheduleStartDate(schedule: Schedule): Date {
    return this.rrulePipe.transform(schedule.rrule, 'date') as Date;
  }

  private getRepeatScheduleEndDate(schedule: Schedule): Date {
    const startDate = this.getRepeatScheduleStartDate(schedule);
    const endDate = new Date(startDate);
    const endTime = new Date(schedule.endDate);
    endDate.setHours(endTime.getHours());
    endDate.setMinutes(endTime.getMinutes());
    endDate.setSeconds(endTime.getSeconds());
    // If end date less then start date -> set next date end
    if (startDate && endDate && startDate.getTime() >= endDate.getTime()) {
      endDate.setDate(endDate.getDate() + 1);
    }
    return endDate;
  }


}
