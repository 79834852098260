import { Clip } from "@modules/deck/types/clip";

export const TimecodeNull = '4294967295';

export class Playlist {
  name: string;
  frameRate: string;
  resolution: string;
  drop: number;
  pixelFormat: string;
  startTcFrames: number;
  duration: number;
  inEnabled: boolean;
  outEnabled: boolean;
  inFrame: number;
  outFrame: number;
  soundtrackOn: boolean;
  transitionsOn: boolean;
  useClipsTc: boolean;
  usePsf: boolean;
  clips: PlaylistClip[];
  drive: string; // virtual
  path: string; // virtual
}

export class PlaylistClip {
  filename: string;
  wrapper: string;
  position: string;
  in: string;
  out: string;
  markersId?: string;

  static create(clip: Clip, position: number, filename?: string): PlaylistClip {
    const playlistClip = new PlaylistClip();
    playlistClip.filename = filename;
    playlistClip.wrapper = clip.wrapperKey;
    playlistClip.position = position + '';
    playlistClip.in = TimecodeNull;
    playlistClip.out = TimecodeNull;
    return playlistClip;
  }
}
