import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

// External modules
import { ContextMenuModule } from 'ngx-contextmenu';

// Modules
import { PopoverModule } from '@modules/popover/popover.module';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,

    // External
    ContextMenuModule,

    // Material
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: []
})
export class MarkerModule { }
