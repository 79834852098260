import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

// Services
import { MatDialog } from '@angular/material/dialog';
import { StreamService } from '@modules/stream/services/stream.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { UserService } from '@modules/users/services/user.service';

// Components
import { StreamSettingsComponent } from '../stream-settings/stream-settings.component';

// Types
import { Stream } from '@modules/stream/types/stream';
import { Channel } from '@modules/channel/types/channel';
import { PresetService } from '@modules/preset/services/preset.service';
import { Preset } from '@modules/preset/types/preset';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';


@Component({
  selector: 'app-stream-list',
  templateUrl: './stream-list.component.html',
  styleUrls: ['./stream-list.component.less']
})
export class StreamListComponent implements OnInit, OnDestroy {

  // Public
  public streams: Stream[];
  public selected: Stream;
  public locked = false;
  public allowed: boolean;
  public channels: Channel[];

  // Private
  private alive = new Subject();
  private selectedPreset = new BehaviorSubject<Preset>(null);

  /**
   * Constructor
   */

  constructor(
    private dialog: MatDialog,
    private streamService: StreamService,
    private deckService: DeckService,
    private channelService: ChannelService,
    private presetService: PresetService,
    private contextMenuService: ContextMenuService,
    userService: UserService
  ) {
    userService
      .getCurrentPermission('configureInputSources')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowed = allowed);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Streams
    this.streamService.getStreams()
      .pipe(
        takeUntil(this.alive),
        map(streams => streams.filter(stream => (stream.deckChannel !== null && stream.deckChannel !== undefined) && stream.deckId))
      )
      .subscribe(streams => this.streams = streams);
    // Get Channels
    this.channelService.getChannels()
      .pipe(takeUntil(this.alive))
      .subscribe(channels => this.channels = channels);
    // Get selected preset
    this.presetService.getCurrentPreset()
      .pipe(takeUntil(this.alive))
      .subscribe(this.selectedPreset);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  getName(stream: Stream): string {
    const deck = this.deckService.getDeckSync(stream.deckId);
    return deck?.name + ' - ' + stream?.deckChannelName;
  }

  getOnlineStatus(stream: Stream): Observable<string> {
    return this.streamService.getInputOnlineStatus(stream)
      .pipe(
        takeUntil(this.alive)
      );
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  openSettings(): void {
    this.dialog.open(StreamSettingsComponent, { autoFocus: false, disableClose: true });
  }

  setChannel(stream: Stream, channel: Channel): void {
    this.channelService.updateChannel({ ...channel, streamId: stream.id  });
    this.channelService.assignStreamToChannel(channel, stream.id)
        .pipe(take(1), filter(result => result))
        .subscribe(() => {
          const presetId = stream.presetId || this.selectedPreset.value?.id;
          this.streamService.setPreset(presetId, stream.id);
          const preset = this.presetService.getPresetSync(presetId);
          if (preset) {
            this.presetService.selectPreset(preset);
          }
        });
  }

  openContextMenu($event: MouseEvent, contextMenu: ContextMenuComponent, item: any): void {
    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: contextMenu,
      event: $event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

}
