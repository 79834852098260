import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

// RxJS
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';
import { HotkeysService } from '@modules/elements/services/hotkeys.service';

// Components
import { AudioLevelsComponent } from '@modules/channel/components/audio-levels/audio-levels.component';
import { PlayerProgressBarComponent } from '@modules/elements/components/player-progress-bar/player-progress-bar.component';

// Types
import { Playlist, PlaylistClip, TimecodeNull } from '@modules/playlist-editor/types/playlist';
import { Stream } from '@modules/stream/types/stream';
import { Timecode } from '@modules/elements/types/timecode';
import { Clip } from '@modules/deck/types/clip';
import { StreamStatus } from '@modules/stream/types/stream-status';
import { RS422ScrollActions } from '@modules/rs422/types/rs422-scroll-actions';
import { RS422ScrollMode } from '@modules/rs422/types/rs422-settings';
import { NodeService } from '@modules/node/services/node.service';

@Component({
  selector: 'app-playlist-player',
  templateUrl: './playlist-player.component.html',
  styleUrls: ['./playlist-player.component.less']
})
export class PlaylistPlayerComponent implements OnInit, OnChanges, OnDestroy {

  // View Child
  @ViewChild('timecode', { static: false }) timecodeRef: ElementRef;
  @ViewChild('elapsedTimecode', { static: false }) elapsedTimecodeRef: ElementRef;
  @ViewChild('remainTimecode', { static: false }) remainTimecodeRef: ElementRef;
  @ViewChild('inPointTC', { static: false }) inPointTimecodeRef: ElementRef;
  @ViewChild('outPointTC', { static: false }) outPointTimecodeRef: ElementRef;
  @ViewChild(AudioLevelsComponent, { static: false }) audioLevels: AudioLevelsComponent;
  @ViewChild(PlayerProgressBarComponent, { static: false }) playbackProgressBar: PlayerProgressBarComponent;

  // Inputs
  @Input() stream: Stream;
  @Input() playlist: Playlist;

  // Public
  public clip: Clip;
  public playlistClip: PlaylistClip;
  public status: StreamStatus;
  public openCurrentPlaylist = false;
  public popoverClose = new Subject<void>();
  public TimecodeNull = TimecodeNull;
  public altSelected = false;
  public playbackScrollActions = new RS422ScrollActions();
  public playbackScrollMode: RS422ScrollMode;
  public clips: Clip[] = [];

  // Private
  private alive = new Subject<void>();
  private timebase: number;
  private currentTimecode: string;

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    private fileManagerService: FileManagerService,
    private playlistService: PlaylistService,
    private hotkeysService: HotkeysService,
    private nodeService: NodeService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Timebase
    const interlaced = Timecode.isInterlaced(+this.playlist.frameRate, this.playlist.resolution);
    this.timebase = Timecode.getTimebase(+this.playlist.frameRate, interlaced);
    // Get Clip
    this.streamService.getStatus(this.stream)
      .pipe(
        tap(status => this.status = status),
        map(status => {
          this.openCurrentPlaylist = status?.playback?.openPlaylistName === this.playlist.name && status?.playback?.isLoaded;
          if (this.playlist.name !== status?.playback?.openPlaylistName) {
            this.playlistClip = null;
            return null;
          }
          this.playlistClip = this.playlist.clips[status.playback.clipPosition];
          return this.playlist.clips[status.playback.clipPosition]?.filename;
        }),
        distinctUntilChanged(isEqual),
        switchMap(clipPath => clipPath ? this.fileManagerService.getClip(this.stream.deckId, clipPath) : of(null)),
        tap(clip => {
          console.log('[Playlist][Player] playlist = ', this.playlist);
          console.log('[Playlist][Player] clip = ', clip);
          console.log('[Playlist][Player] playlistClip = ', this.playlistClip);
        }),
        takeUntil(this.alive)
      )
      .subscribe(clip => {
        this.clip = clip;
        this.updateInOutPoints();
      });

    // Hotkeys
    this.setupHotkeys();

    // Actions
    this.setupActions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('playlist' in changes) {
      this.updateClips();
    }
  }

  ngOnDestroy(): void {
    this.popoverClose.next();
    this.popoverClose.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Playback
   */

  playPlaylist(): void {
    if ((this.status?.playback?.openPlaylistName === this.playlist.name) && this.status?.playback?.isLoaded) {
      return;
    }
    this.streamService.ejectFile(this.stream)
        .subscribe(() => this.playlistService.playPlaylist(this.stream, this.playlist, true));
  }

  ejectFile(): void {
    this.streamService.ejectFile(this.stream);
  }

  play(): void {
    this.streamService.play(this.stream);
    this.changeScrollMode(null);
  }

  reverse(): void {
    this.streamService.reverse(this.stream);
    this.changeScrollMode(null);
  }

  fastForward(): void {
    this.streamService.fastForward(this.stream);
  }

  rewind(): void {
    this.streamService.rewind(this.stream);
  }

  pause(): void {
    this.streamService.pause(this.stream);
  }

  goToStart(): void {
    this.streamService.goToStart(this.stream);
  }

  goToEnd(): void {
    this.streamService.goToEnd(this.stream);
  }

  setInPoint(value?: {tc?: string, frame?: number, offset?: number}): void {
    if (value) {
      this.playlistClip.in = (this.clip.startTimecodeFrames + value.frame) + '';
    } else {
      const timecode = new Timecode(this.currentTimecode, this.clip.timebase, !!this.clip.drop);
      this.playlistClip.in = timecode.frameCount + '';
    }
    this.playlist.clips[this.status.playback.clipPosition].in = this.playlistClip.in;
    this.updatePlaylistInfoByClips();
    this.playlistService.updatePlaylist(this.stream.deckId, this.playlist);
    this.updateInOutPoints();
  }

  setOutPoint(value?: {tc?: string, frame?: number, offset?: number}): void {
    if (value) {
      this.playlistClip.out = (this.clip.startTimecodeFrames + value.frame) + '';
    } else {
      const timecode = new Timecode(this.currentTimecode, this.clip.timebase, !!this.clip.drop);
      this.playlistClip.out = timecode.frameCount + '';
    }
    this.playlist.clips[this.status.playback.clipPosition].out = this.playlistClip.out;
    this.updatePlaylistInfoByClips();
    this.playlistService.updatePlaylist(this.stream.deckId, this.playlist);
    this.updateInOutPoints();
  }

  removeInPoint(): void {
    this.playlistClip.in = TimecodeNull;
    this.playlist.clips[this.status.playback.clipPosition].in = TimecodeNull;
    this.updatePlaylistInfoByClips();
    this.playlistService.updatePlaylist(this.stream.deckId, this.playlist);
    this.updateInOutPoints();
  }

  removeOutPoint(): void {
    this.playlistClip.out = TimecodeNull;
    this.playlist.clips[this.status.playback.clipPosition].out = TimecodeNull;
    this.updatePlaylistInfoByClips();
    this.playlistService.updatePlaylist(this.stream.deckId, this.playlist);
    this.updateInOutPoints();
  }

  jump(value: {tc?: string, frame?: number, offset?: number}): void {
    this.streamService.jump(this.stream, value);
  }

  goToNextClip(stream: Stream): void {
    this.streamService.goToNextClip(this.stream);
  }

  goToPrevClip(stream: Stream): void {
    this.streamService.goToPrevClip(this.stream);
  }

  hideStopOverlay(stream: Stream): void {
    this.streamService.hideStopOverlay(this.stream);
  }

  loopPlayback(): void {
    // const value: StreamStatusPlaybackLoop = this.status.playback.loopPlayback === 'off' ? 'continuous' : 'off';
    // this.streamService.loopPlayback(this.stream, value);
  }

  goToNextAndPlay(): void {
    this.streamService.goToNextAndPlay(this.stream);
  }

  goToNextAndPause(): void {
    this.streamService.goToNextAndPause(this.stream);
  }

  goToPrevAndPlay(): void {
    this.streamService.goToPrevAndPlay(this.stream);
  }

  goToPrevAndPause(): void {
    this.streamService.goToPrevAndPause(this.stream);
  }

  changeScrollMode(mode: RS422ScrollMode): void {
    this.playbackScrollMode = this.playbackScrollMode !== mode ? mode : null;
  }

  /**
   * Methods
   */

  updateClips(): void {
    if (!this.stream?.deckId || !this.playlist) {
      this.clips = [];
      return;
    }
    const deckId = this.stream.deckId;
    const paths = this.playlist.clips.map(clip => clip.filename);
    this.fileManagerService.getIndexedFiles(deckId, paths)
      .pipe(
        map(files => {
          const clips = [];
          this.playlist.clips.forEach(playlistClip => {
            const clip = files.find(file => {
              const filename = this.nodeService.path.win32.normalize(file?.drivePath + file?.dirPath + file?.name);
              return playlistClip.filename === filename;
            });
            if (clip) {
              const duration = clips.reduce((sum, item) => sum + (+item.durationFrames), 0);
              const interlaced = Timecode.isInterlaced(+clip.fps, clip.resolution);
              const timebase = Timecode.getTimebase(+clip.fps, interlaced);
              const playlistStartTimecode = new Timecode(duration, timebase, clip.drop === 1);
              const result = {...playlistClip, ...clip, playlistStartTimecode: playlistStartTimecode.toString()};
              clips.push(result);
            } else {
              clips.push(playlistClip);
            }
          });
          return clips;
        }),
        takeUntil(this.alive)
      )
      .subscribe(result => {
        this.clips = result;
        setTimeout(() => this.updateInOutPoints(), 100);
      });
  }

  setupHotkeys(): void {
    // Remove In Point
    this.hotkeysService.addShortcut({ keys: 'shift.meta.i', description: 'Remove IN Point'})
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.removeInPoint());
    // Remove Out Point
    this.hotkeysService.addShortcut({ keys: 'shift.meta.o', description: 'Remove OUT Point'})
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.removeOutPoint());
    // Set In Point
    this.hotkeysService.addShortcut({ keys: 'i', description: 'Set IN Point'})
      .pipe(takeUntil(this.alive))
      .subscribe((event) => this.setInPoint());
    // Set Out Point
    this.hotkeysService.addShortcut({ keys: 'o', description: 'Set OUT Point'})
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.setOutPoint());
  }

  setTimecode(timecodes): void {
    if (timecodes && this.timecodeRef) {
      const playbackTc = timecodes?.playback;
      this.currentTimecode = playbackTc;
      if (playbackTc) {
        this.timecodeRef.nativeElement.innerHTML = playbackTc || '00:00:00:00';
      }
      // Progress bar
      if (this.playbackProgressBar && playbackTc) {
        this.playbackProgressBar.setValue(playbackTc);
      }
      if (timecodes.elapsed && this.elapsedTimecodeRef) {
        this.elapsedTimecodeRef.nativeElement.innerHTML = timecodes.elapsed || '00:00:00:00';
      }
      if (this.clip && timecodes.elapsed && this.remainTimecodeRef) {
        const currentClipTimecode = new Timecode(timecodes.elapsed, this.timebase, +this.playlist.drop === 1);
        const remainDuration = (+this.playlist.duration) - currentClipTimecode.frameCount;
        let remainTimecode = (new Timecode(remainDuration, this.timebase, +this.playlist.drop === 1)).toString();
        if (remainDuration < 0) {
          remainTimecode = null;
        }
        this.remainTimecodeRef.nativeElement.innerHTML = remainTimecode || '00:00:00:00';
      }
    }
  }
  
  setAudioLevel(levels): void {
    if (this.audioLevels && levels) {
      this.audioLevels.setAudioLevel(levels);
    }
  }

  updateInOutPoints(): void {
    // In Point
    if (this.inPointTimecodeRef && this.playlistClip?.in && this.playlistClip?.in !== TimecodeNull && this.clip) {
      const inPointCurrentFrame = +this.playlistClip.in - this.clip.startTimecodeFrames;
      const timecode = new Timecode(this.clip.startTimecode, this.clip.timebase, !!this.clip.drop);
      timecode.add(inPointCurrentFrame);
      this.inPointTimecodeRef.nativeElement.innerHTML = timecode.toString() || '--:--:--:--';
    } else if (this.inPointTimecodeRef) {
      this.inPointTimecodeRef.nativeElement.innerHTML = '--:--:--:--';
    }
    // Out Point
    if (this.outPointTimecodeRef && this.playlistClip?.out && this.playlistClip?.out !== TimecodeNull && this.clip) {
      const outPointCurrentFrame = +this.playlistClip.out - this.clip.startTimecodeFrames;
      const timecode = new Timecode(this.clip.startTimecode, this.clip.timebase, !!this.clip.drop);
      timecode.add(outPointCurrentFrame);
      this.outPointTimecodeRef.nativeElement.innerHTML = timecode.toString() || '--:--:--:--';
    } else if (this.outPointTimecodeRef) {
      this.outPointTimecodeRef.nativeElement.innerHTML = '--:--:--:--';
    }
  }

  updatePlaylistInfoByClips(): void {
    let frames = 0;
    this.playlist.clips.forEach((playlistClip, clipIndex) => {
      const clip = this.clips[clipIndex];
      const startFrame = playlistClip.in !== TimecodeNull ? +playlistClip.in : clip.startTimecodeFrames;
      const endFrame = playlistClip.out !== TimecodeNull ? +playlistClip.out : clip.endTimecodeFrames;
      const durationFrames = endFrame - startFrame;
      frames += durationFrames;
    });
    this.playlist.duration = frames;
  }

  setupActions(): void {
    // Actions
    this.playbackScrollActions.var
      .pipe(
        filter(speed => this.playbackScrollMode === 'var'),
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(speed => {
        if (speed >= 0) {
          this.streamService.varForward(this.stream, speed);
        } else {
          this.streamService.varReverse(this.stream, -speed);
        }
      });
    this.playbackScrollActions.shuttle
      .pipe(
        filter(speed => this.playbackScrollMode === 'shuttle'),
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(speed => {
        if (speed >= 0) {
          this.streamService.shuttleForward(this.stream, speed);
        } else {
          this.streamService.shuttleReverse(this.stream, -speed);
        }
      });
    this.playbackScrollActions.jog
      .pipe(
        filter(speed => this.playbackScrollMode === 'jog'),
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(speed => {
        if (speed >= 0) {
          this.streamService.jogForward(this.stream, speed);
        } else {
          this.streamService.jogReverse(this.stream, -speed);
        }
      });
  }

}
