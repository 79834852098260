<div class="preset-settings-project-content">

  <!-- Header -->
  <div class="preset-settings-header">
    <div class="label">Avid Interplay Setup</div>
  </div>

  <!-- Disabled Status -->
  <div class="settings-status-text" *ngIf="recording">Settings unavailable during recording</div>
  <div class="settings-status-text" *ngIf="!deckOnline">Settings are not available if there is no connection</div>
  <div class="settings-status-text" *ngIf="preset && !preset?.isValid()">
    <mat-icon svgIcon="warning" class="icon error"></mat-icon>
    Selected preset is from a prior version: please delete and create a new one.
  </div>

  <!-- Enabled -->
  <div class="avid-enabled">
    <mat-checkbox class="checkbox" [class.inherit]="!isDefaultSetting('interplaSettings.enabled')" [disabled]="disabled" [(ngModel)]="settings?.interplaSettings.enabled" (ngModelChange)="updateSettings()" disableRipple>Enabled</mat-checkbox>
  </div>

  <!-- Avid Interplay Setup -->
  <div class="avid-setup-content" *ngIf="settings?.interplaSettings">

    <!-- Settings -->
    <div class="avid-settings">
      <mat-checkbox class="checkbox" [disabled]="disabled || !settings?.interplaSettings.enabled" [class.inherit]="!isDefaultSetting('interplaSettings.multiResProxy')" [(ngModel)]="settings.interplaSettings.multiResProxy" (ngModelChange)="updateSettings()" disableRipple>Multi-res Proxy</mat-checkbox>
      <mat-checkbox class="checkbox" [disabled]="disabled || !settings?.interplaSettings.enabled" [class.inherit]="!isDefaultSetting('interplaSettings.ewcEnabled')" [(ngModel)]="settings.interplaSettings.ewcEnabled" (ngModelChange)="updateSettings()" disableRipple>Edit While Record (EWC)</mat-checkbox>
      <div class="field" [class.disabled]="disabled || !settings?.interplaSettings.enabled">
        <div class="label">Head Frame</div>
        <input class="input" type="number" [(ngModel)]="settings.interplaSettings.headFrameNumber" (blur)="updateSettings()">
      </div>
    </div>

    <!-- Form -->
    <div class="avid-form">

      <!-- User Name -->
      <div class="field" [class.disabled]="disabled || !settings?.interplaSettings.enabled" [class.inherit]="!isDefaultSetting('interplaSettings.username')">
        <div class="label">User Name</div>
        <input class="input" [(ngModel)]="settings.interplaSettings.username" (blur)="updateSettings()">
      </div>

      <!-- Password -->
      <div class="field" [class.disabled]="disabled || !settings?.interplaSettings.enabled" [class.inherit]="!isDefaultSetting('interplaSettings.password')">
        <div class="label">Password</div>
        <input class="input" [(ngModel)]="settings.interplaSettings.password" (blur)="updateSettings()">
      </div>

      <!-- URI -->
      <div class="field" [class.disabled]="disabled || !settings?.interplaSettings.enabled" [class.inherit]="!isDefaultSetting('interplaSettings.uri')">
        <div class="label">URI</div>
        <input class="input" [(ngModel)]="settings.interplaSettings.uri" (blur)="updateSettings()">
        <div class="button button-select">select</div>
      </div>

      <!-- URL -->
      <div class="field" [class.disabled]="disabled || !settings?.interplaSettings.enabled" [class.inherit]="!isDefaultSetting('interplaSettings.url')">
        <div class="label">URL</div>
        <input class="input" [(ngModel)]="settings.interplaSettings.url" (blur)="updateSettings()">
      </div>

    </div>

  </div>

</div>
