import { Component, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { ChannelService } from '@modules/channel/services/channel.service';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { Playlist } from '@modules/playlist-editor/types/playlist';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-playlist-channel',
  templateUrl: './playlist-channel.component.html',
  styleUrls: ['./playlist-channel.component.less']
})
export class PlaylistChannelComponent implements OnInit {

  // Public
  public stream: Stream;
  public playlist: Playlist;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private channelService: ChannelService,
    private streamService: StreamService,
    private playlistService: PlaylistService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.channelService.getSelectedChannels()
      .pipe(
        filter(channels => channels.length === 1),
        map(channels => channels[0]),
        switchMap(channel => this.streamService.getStream(channel.streamId)),
        tap(stream => this.stream = stream),
        switchMap(stream => this.streamService.getStatus(stream)),
        map(status => status?.playback?.openPlaylistName),
        distinctUntilChanged(isEqual),
        switchMap(playlistFilename => this.playlistService.getPlaylist(this.stream?.deckId, playlistFilename)),
        takeUntil(this.alive)
      )
      .subscribe(playlist => this.playlist = playlist);
  }

}
