export type RS422ScrollMode = 'var' | 'shuttle' | 'jog';
export type RS422PlaybackMode = 'remote' | 'local';

export class RS422Settings {
  scrollMode: RS422ScrollMode;
  showStatus: boolean;
  servo: boolean;

  constructor() {
    this.scrollMode = null;
    this.showStatus = false;
    this.servo = false;
  }
}
