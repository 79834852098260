import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// Lodash
import { isEqual, has, omit } from 'lodash-es';

// RxJS
import { BehaviorSubject, combineLatest, Subject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// Services
import { PresetService } from '@modules/preset/services/preset.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { PresetSettingsCopyComponent } from './preset-settings-copy/preset-settings-copy.component';

// Types
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Preset } from '@modules/preset/types/preset';
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { StreamStatus } from '@modules/stream/types/stream-status';

@Component({
  selector: 'app-preset-settings',
  templateUrl: './preset-settings.component.html',
  styleUrls: ['./preset-settings.component.less']
})
export class PresetSettingsComponent implements OnInit, OnDestroy {

  objectKeys = Object.keys;

  // Public
  public streamId = new BehaviorSubject<string>(null);
  public presetId: string;
  public stream: Stream;
  public streams: Stream[] = [];
  public preset: Preset;
  public settings: StreamSettings;
  public streamSettings: Partial<StreamSettings>;
  public settingsOptions: PresetSettingsOptions;
  public recording = false;
  public deckOnline = false;
  public playbackOnly = false;
  public streamStatus: StreamStatus;
  public selectedTab = 0;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<PresetSettingsComponent>,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    private streamService: StreamService,
    private deckService: DeckService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {stream?: Stream, presetId?: string},
  ) {
    this.streamId.next(data.stream?.id);
    this.presetId = data.presetId;
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Stream, Preset, Settings and Streams form Preset
    this.streamId
      .pipe(
        switchMap(id => this.streamService.getStream(id)),
        tap(stream => this.stream = stream),
        switchMap(stream => 
          combineLatest([
            this.presetService.getPreset(stream?.presetId || this.presetId),
            this.streamService.projectUpdated(stream).pipe(startWith(0))
          ]).pipe(map(([preset, _]) => preset))
        ),
        tap(preset => {
          console.log('[PRESET][SETTINGS] ', preset);
          
          this.preset = preset;
          this.streamSettings = this.preset?.settings[this.stream?.id];
          const settings = this.preset?.getSetting(this.stream?.id);
          const currentSettings = JSON.parse(JSON.stringify(settings));
          console.log('[SETTINGS] ', currentSettings);
          if (!isEqual(currentSettings, this.settings)) {
            const tabDiff = this.settings?.encode?.length - currentSettings?.encode?.length;
            this.settings = currentSettings;
            if (this.selectedTab > 3 && tabDiff !== 0) {
              this.selectedTab = this.selectedTab - tabDiff;
            }
          }
        }),
        map(preset => Object.keys(preset?.settings)),
        mergeMap(keys =>
          this.streamService.getStreams()
            .pipe(
              map(streams => streams.filter(stream =>
                this.preset?.id === stream?.presetId && keys.includes(stream.id) &&
                stream.deckChannel !== null && stream.deckChannel !== undefined
              )),
              take(1)
            )
        ),
        takeUntil(this.alive),
      )
      .subscribe(streams => this.streams = streams);
    // Get Options
    this.presetSettingsService.getSettingsOptions()
      .pipe(takeUntil(this.alive))
      .subscribe(options => this.settingsOptions = options);
    // Get Deck status
    this.streamId
      .pipe(
        switchMap(id => this.streamService.getStream(id)),
        filter(stream => {
          if (!stream) {
            this.recording = false;
            this.deckOnline = true;
            this.playbackOnly = false;
          }
          return !!stream;
        }),
        switchMap(stream => this.deckService.getDeck(stream.deckId).pipe(map(deck => ({deck, stream}) ))),
        switchMap(({deck, stream}) => combineLatest([this.deckService.getStatus(deck), this.streamService.getInputOnlineStatus(stream), of(stream)])),
        takeUntil(this.alive),
      )
      .subscribe(([status, onlineStatus, stream]) => {
        const deckChannel = stream?.deckChannel ?? 0;
        this.streamStatus = status?.inputs[deckChannel];
        this.recording = this.streamStatus?.record?.record ?? false;
        this.deckOnline = onlineStatus === 'online';
        this.playbackOnly = !this.streamStatus.record && !!this.streamStatus.playback;
      });

      // Reload settings
      this.streamId
        .pipe(
          distinctUntilChanged(isEqual),
          filter(streamId => streamId !== null && streamId !== undefined),
          takeUntil(this.alive),
        )
        .subscribe(streamId => this.streamService.reloadSettings(streamId))
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Action
   */

  selectInput(stream?: Stream): void {
    this.streamId.next(stream?.id);
  }

  close(): void {
    this.dialogRef.close();
  }

  openSettingCopyModal(): void {
    this.dialog.open(PresetSettingsCopyComponent, {data: {stream: this.stream}, disableClose: true});
  }

  resetAllSettings(): void {
    if (!this.preset || !this.stream || !this.preset?.settings[this.stream?.id]) {
      return;
    }

    // Remove keys
    const encodeLength = this.settings?.encode?.length ?? 0;
    for (let tab = 0; tab <= encodeLength + 4; tab++) {
      const keys = this.getSettingsKeys(tab);
      this.preset.settings[this.stream.id] = omit(this.preset.settings[this.stream.id], keys);
    }
    // Update
    const settings = this.preset.getSetting(this.stream.id);
    this.presetService.updatePreset(this.preset);
    this.presetSettingsService.updateStreamSettings(this.stream, settings);
  }

  resetCurrentTab(): void {
    if (!this.preset || !this.stream || !this.preset?.settings[this.stream?.id]) {
      return;
    }

    // Remove keys
    const keys = this.getSettingsKeys(this.selectedTab);
    this.preset.settings[this.stream.id] = omit(this.preset.settings[this.stream.id], keys);
    // Update
    const settings = this.preset.getSetting(this.stream.id);
    this.presetService.updatePreset(this.preset);
    this.presetSettingsService.updateStreamSettings(this.stream, settings);
  }

  /**
   * Methods
   */

  getName(stream: Stream): string {
    const deck = this.deckService.getDeckSync(stream.deckId);
    return deck?.name + ' - ' + stream?.deckChannelName;
  }

  getOnlineStatus(stream: Stream): Observable<string> {
    return this.streamService.getInputOnlineStatus(stream)
      .pipe(
        takeUntil(this.alive)
      );
  }

  getSettingsKeys(tab: number): string[] {
    const deckChannel = this.stream?.deckChannel ?? 0;
    const encodeLength = this.settings?.encode?.length ?? 0;
    // Inputs
    if (tab === 0) {
      return [
        'input.activeVideoInput',
        'input.activeAudioInput',
        'input.activeAnalogAudioSource',
        `channels[${deckChannel}].srtPort`,
        `channels[${deckChannel}].srtReceiverUrl`,
        `channels[${deckChannel}].srtPassphrase`,
        `channels[${deckChannel}].isYuvLevelA`,
        `channels[${deckChannel}].activeSyncSource`,
        'input.activeResolution',
        'input.activeFrameRate',
        'input.activePixelFormat',
        `channels[${deckChannel}].inputRange`,
        'input.hdr',
        'input.hdrTransferPQ',
        'input.hdrMatrixConst',
        'input.frameDropBehavior',
        'input.signalLossBehavior',
        'input.aspectRatioString',
        'input.audioDelays',
      ];
    }
    // Outputs
    if (tab === 1) {
      return [
        `channels[${deckChannel}].upconvertMode`,
        `channels[${deckChannel}]._444output`,
        `channels[${deckChannel}].audioOutput`,
        `channels[${deckChannel}].audioPlaythroug`,
      ];
    }
    // Encoders
    if (tab < (encodeLength + 2)) {
      const encoderIndex = tab - 2;
      return [
        `encode[${encoderIndex}].enabled`,
        `encode[${encoderIndex}].activeCodec`,
        `encode[${encoderIndex}].activeQuality`,
        `encode[${encoderIndex}].activeWrapper`,
        `encode[${encoderIndex}].activeAudioCodec`,
        `encode[${encoderIndex}].h264DefaultAudioCodec`,
        `encode[${encoderIndex}].isCBR`,
        `encode[${encoderIndex}].fieldWrapped`,
        `encode[${encoderIndex}].forceBT709`,
        `encode[${encoderIndex}].fieldShift`,
        `encode[${encoderIndex}].isTopFirst`,
        `channels[${deckChannel}].maxH264Duration`,
        `automation.tcSource`,
        `encode[${encoderIndex}].segmentMode`,
        `encode[${encoderIndex}].segmentDuration`,
        `encode[${encoderIndex}].isSegmentLimitMode`,
        `encode[${encoderIndex}].segmentLimit`,
        `encode[${encoderIndex}].encoderName`,
        `channels[${deckChannel}].recTCOffsetEnabled`,
        `channels[${deckChannel}].recTCOffsetString`,
        `channels[${deckChannel}].recTCOffsetNegative`,
        `channels[${deckChannel}].audioRouting[${encoderIndex}]`,
        `channels[${deckChannel}].audioRouting[${encoderIndex}].audioFrequencyModel.currentBitDepth`,
        `encode[${encoderIndex}].bigEndianType`,
        `encode[${encoderIndex}].generateXMLMode`,
        `encode[${encoderIndex}].overrideXMLPath`,
        `encode[${encoderIndex}].XMLPath`,
        `encode[${encoderIndex}].useMxfClipFolders`,
        `encode[${encoderIndex}].overrideAAFPath`,
        `encode[${encoderIndex}].AAFPath`,
        `encode[${encoderIndex}].captionsMode`,
        `encode[${encoderIndex}].sccField1`,
        `encode[${encoderIndex}].sccField2`,
        `encode[${encoderIndex}].CCLine`,
        `encode[${encoderIndex}].srtCC`,
        `encode[${encoderIndex}].zeroBasedSrtCC`,
        `channels[${deckChannel}].burnIn[${encoderIndex}]`,
        `encode[${encoderIndex}].sameDiskAsPrimary`,
        `channels[${deckChannel}].driveAssignments[${encoderIndex}].activeWriteMode`,
      ];
    }
    // Editshare
    if (tab === (encodeLength + 2)) {
      // TODO: Add keys
    }
    // Timecode and Automation
    if (tab === (encodeLength + 3)) {
      return [
        'editShareSettings'
      ];
    }
    // Timecode and Automation
    if (tab === (encodeLength + 3)) {
      return [
        'automation.trigger',
        'automation.manualParams.source',
        'automation.deviceParams.source',
        'automation.edlParams.source',
        'automation.recRunParams.frames',
        'automation.genTCType',
        'automation.genTCDropFlag',
        'automation.proTools',
        'automation.sdiOffset',
        'automation.ndiOffset',
        'automation.genTcOffset',
        'automation.extLtc1Offset',
        'automation.extLtc2Offset',
        'automation.irigOffset',
        'automation.showIrigDateEnabled',
        'automation.forceRP188',
        `channels[${deckChannel}].ltcSource`,
        `channels[${deckChannel}].comPort`,
        `channels[${deckChannel}].preroll`,
        `channels[${deckChannel}].remoteCtrl.jogModeStandard`,
        `channels[${deckChannel}].remoteCtrl.jogSensitive`,
        `channels[${deckChannel}].useGOPTimecode`,
        'tcSource',
      ];
    }
    // Project Preferences
    if (tab === (encodeLength + 4)) {
      return [
        'interplaSettings',
      ];
    }
    // Output Overlays
    if (tab === (encodeLength + 4)) {
      return [
        `channels[${deckChannel}].charOut`,
      ];
    }

    return [];
  }

}
