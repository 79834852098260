<div
  class="channels-list-items"
  [class.selected]="selected"
  [class.error]="error"
  (appClick)="selectChannel($event)"
  (appDblClick)="openFullscreen()"
  appDroppable
  appDroppableZIndex="1"
  (appDroppableEnter)="dndEnter($event)"
  (appDroppableLeave)="dndLeave($event)"
  (appDroppableOver)="dndDragOver($event)"
  (appDroppableDrop)="dndDrop($event)"
>

  <div class="channel-item">
    <div
      class="name"
      [matTooltip]="deck?.name + ' - '  + stream?.deckChannelName"
      popover
      [popoverContent]="channelPopover"
      popoverPlacement="bottom"
      popoverTrigger="click"
      [popoverOffsetY]="-5"
      [popoverShowUntil]="popoverClose"
    >{{ channelIndex | number:'3.0' }}</div>
    <div class="channel-online-status" [class.deck-online]="online" [class.input-online]="inputs && settings && inputs[settings?.input.activeVideoInput]?.enabled && !!inputs[settings?.input.activeVideoInput]?.streaming" [matTooltip]="getTitle(settings?.input.activeVideoInput, 'inputTypes')"></div>
    <div class="action-status">
      <div class="record-button" *ngIf="status?.record?.record || status?.record?.isWaitingForRecord">
        <mat-icon svgIcon="record" class="icon"></mat-icon>
      </div>
      <div class="play-button" *ngIf="status?.playback?.isPlayingForward">
        <mat-icon svgIcon="play" class="icon"></mat-icon>
      </div>
      <div class="pause-button" *ngIf="status?.playback?.isPaused">
        <mat-icon svgIcon="pause" class="icon"></mat-icon>
      </div>
    </div>
    <div class="timecode" #timecode [matTooltip]="getTitle(settings?.tcSource, 'encoderTcSource')">00:00:00:00</div>
    <div class="actions">
      <div class="action" *ngIf="error">
        <mat-icon svgIcon="warning" class="icon" [class.error]="error" [matTooltip]="error"></mat-icon>
      </div>
      <div class="action" [class.disabled]="!stream?.presetId">
        <mat-icon svgIcon="fullscreen" class="icon" (click)="openFullscreen()"></mat-icon>
      </div>
      <div class="action">
        <mat-icon #menuButton svgIcon="dots-vertical" class="icon" (click)="openMenu($event)" [matMenuTriggerFor]="moreMenu"></mat-icon>
      </div>
    </div>
  </div>

  <!-- Drop area -->
  <div class="drop-area" *ngIf="dropArea.show">
    <div class="title" *ngIf="dropArea.type === 'preset'">Assign Preset here</div>
  </div>

  <!-- Menu -->
  <mat-menu #moreMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="openFullscreen()">Fullscreen</button>
    <button mat-menu-item (click)="deleteStream()">Delete</button>
  </mat-menu>

</div>

<!-- Channel Popover -->
<ng-template #channelPopover>
  <div class="channel-popover">

    <!-- Info -->
    <div class="channel-info" [class.record]="status?.record?.record">
      <div class="info">
        <div class="name">
          <app-online-status class="online-status" [value]="online"></app-online-status>
          <div class="stream-name">{{ deck?.name }} - {{ stream?.deckChannelName }}</div>
        </div>
      </div>
      <div class="address">
        {{ deck?.address }}
        <!-- Record Info -->
        <div class="resolution" *ngIf="settings?.input">
          {{ getTitle(settings?.input.activeResolution, 'resolutions') }} / 
          {{ getTitle(settings?.input.activeFrameRate, 'framerates') }} / 
          {{ getTitle(settings?.input.activePixelFormat, 'colorSpaces') }}
        </div>
        <div class="only-tag" *ngIf="status && !!status?.record && !status?.playback">INPUT ONLY</div>
        <div class="only-tag" *ngIf="status && !status?.record && !!status?.playback">OUTPUT ONLY</div>
      </div>
    </div>

  </div>
</ng-template>