<div class="preset-list-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Presets</div>
    <div class="actions">
      <mat-icon svgIcon="reload" class="action-icon" [class.disabled]="locked || !allowed" (click)="reloadPresets()"></mat-icon>
      <mat-icon svgIcon="minus" class="action-icon" [class.disabled]="locked || !allowed" (click)="deletePreset(selected)"></mat-icon>
      <mat-icon svgIcon="plus" class="action-icon" [class.disabled]="locked || !allowed" #addIcon (click)="showCreatePopover(createPopover, addIcon)"></mat-icon>
      <mat-icon *ngIf="!isWeb" svgIcon="settings-sliders" class="action-icon" [class.disabled]="!allowed || !allowedChangePresetFolder" (click)="openSettings()"></mat-icon>
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" [class.disabled]="!allowed" (click)="lock()"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content">
    <div
      *ngFor="let preset of presets"
      class="preset-tag"
      [class.editing]="preset.id === editing?.id"
      [class.selected]="preset.id === selected?.id"
      [class.disabled]="!allowed || locked"
      [class.deprecated]="!preset?.isValid()"
      (appClick)="selectPreset(preset)"
      (appDblClick)="openPreset(preset, $event)"
      appDraggable
      [appDraggableEnabled]="preset && preset.isValid()"
      [appDraggableData]="{data: preset, type: 'preset'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [contextMenu]="inputMenu"
      [contextMenuSubject]="preset"
      matTooltip="Selected preset is from a prior version: please delete and create a new one."
      [matTooltipDisabled]="preset?.isValid()"
    >
      <app-input-inline [value]="preset.name" [editing]="preset.id === editing?.id" (valueChange)="changePresetName($event, preset)" (editingChange)="presetEditingChange($event, item)"></app-input-inline>
      <mat-icon svgIcon="dots-vertical" class="icon icon-menu" (click)="openContextMenu($event, inputMenu, preset)"></mat-icon>

      <!-- Drag placeholder -->
      <ng-template #dragPlaceholder>
        <div class="preset-tag" [class.selected]="preset.id === selected?.id">{{ preset.name }}</div>
      </ng-template>
    </div>

  </div>

</div>

<!-- Popover Create -->
<ng-template #createPopover>
  <app-create-item-popover [popoverClose]="popoverClose" [excludeNames]="presetNames" (created)="createPreset($event)"></app-create-item-popover>
</ng-template>

<!-- Context menu -->
<context-menu #inputMenu>
  <ng-template contextMenuItem let-item [enabled]="isValidPresetFunc" (execute)="openPreset($event.item)">Open Preset</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [enabled]="isValidPresetFunc" [subMenu]="channelsMenu">Assign to channel</ng-template>
  <context-menu #channelsMenu>
    <ng-template *ngFor="let channel of channels; let index = index" contextMenuItem [enabled]="channel.streamId" (execute)="setChannel($event.item, channel)">Channel {{ index + 1 }}</ng-template>
  </context-menu>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem let-item [visible]="!isWeb" (execute)="showPreset($event.item)">Show in Finder</ng-template>
  <ng-template contextMenuItem let-item [enabled]="isValidPresetFunc" (execute)="saveAs($event.item)">Save as</ng-template>
  <ng-template contextMenuItem let-item (execute)="renamePreset($event.item)">Rename</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem let-item (execute)="deletePreset($event.item)">Delete</ng-template>
</context-menu>
