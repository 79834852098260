<div class="rs422-control-content" (click)="clickStopPropagation($event)">

  <!-- Status -->
  <div class="status" *ngIf="settings?.showStatus">
    <div class="item" [class.selected]="info?.stopStatus"><div class="rect"></div><div class="title">Stop</div></div>
    <div class="item" [class.selected]="info?.playStatus"><div class="rect"></div><div class="title">Play</div></div>
    <div class="item" [class.selected]="info?.reviewStatus"><div class="rect"></div><div class="title">Rev</div></div>
    <div class="item" [class.selected]="info?.recordStatus"><div class="rect"></div><div class="title">Rec</div></div>
    <div class="item" [class.selected]="info?.rewindStatus"><div class="rect"></div><div class="title">Rew</div></div>
    <div class="item" [class.selected]="info?.fastForwardStatus"><div class="rect"></div><div class="title">FFwd</div></div>
    <div class="item" [class.selected]="info?.jogStatus"><div class="rect"></div><div class="title">Jog</div></div>
    <div class="item" [class.selected]="info?.shuttleStatus"><div class="rect"></div><div class="title">Shuttle</div></div>
    <div class="item" [class.selected]="info?.varStatus"><div class="rect"></div><div class="title">Var</div></div>
    <div class="item" [class.selected]="info?.servoLockStatus"><div class="rect"></div><div class="title">Servo</div></div>
    <div class="item" [class.selected]="info?.tsoModeStatus"><div class="rect"></div><div class="title">TSO</div></div>
    <div class="item" [class.selected]="info?.autoStatus"><div class="rect"></div><div class="title">Auto</div></div>
    <div class="item" [class.selected]="info?.localStatus"><div class="rect"></div><div class="title">Local</div></div>
    <div class="item" [class.selected]="info?.cassetteStatus"><div class="rect"></div><div class="title">Cassette</div></div>
    <div class="item" [class.selected]="info?.recordInhibitStatus"><div class="rect"></div><div class="title">Rec Inh</div></div>
    <div class="item" [class.selected]="info?.tapeTopStatus"><div class="rect"></div><div class="title">Tape Top</div></div>
    <div class="item" [class.selected]="false"><div class="rect"></div><div class="title">Tape End</div></div>
    <div class="item" [class.selected]="info?.nearEndOfTapeStatus"><div class="rect"></div><div class="title">Near End</div></div>
    <div class="item" [class.selected]="info?.autoEditStatus"><div class="rect"></div><div class="title">Auto Edit</div></div>
    <div class="item" [class.selected]="info?.cueStatus"><div class="rect"></div><div class="title">Cueing</div></div>
    <div class="item" [class.selected]="info?.cueCompleteStatus"><div class="rect"></div><div class="title">Cued up</div></div>
    <div class="item" [class.selected]="false"><div class="rect"></div><div class="title">EE Auto</div></div>
    <div class="item" [class.selected]="info?.eeStatus"><div class="rect"></div><div class="title">EE</div></div>
    <div class="item" [class.selected]="info?.stillStatus"><div class="rect"></div><div class="title">Still</div></div>
    <div class="item" [class.selected]="false"><div class="rect"></div><div class="title">DF</div></div>
    <div class="item" [class.selected]="info?.syncStatus"><div class="rect"></div><div class="title">Sync</div></div>
    <div class="item" [class.selected]="false"><div class="rect"></div><div class="title">Error</div></div>
  </div>

  <!-- Timecode -->
  <div class="timecodes">

    <!-- In Point -->
    <div class="tc-in-point">
      <div class="title">IN TC</div>
      <div class="timecode" [class.disabled]="!timecodes?.remoteInTimecodePreset" #timecodeIn>--:--:--:--</div>
    </div>

    <!-- TC RS422 -->
    <div class="tc-rs422">
      <div class="title">VTR TC</div>
      <div class="timecode" #timecode>00:00:00:00</div>
    </div>

    <!-- Out Point -->
    <div class="tc-out-point">
      <div class="title">OUT TC</div>
      <div class="timecode" [class.disabled]="!timecodes?.remoteOutTimecodePreset" #timecodeOut>--:--:--:--</div>
    </div>

  </div>

  <!-- Actions -->
  <div class="actions">

    <!-- Left -->
    <div class="actions-left">
      <div class="action small-font" [class.selected]="info?.reviewStatus" (click)="review()">Review</div>
      <div class="action small-font" [class.selected]="info?.previewStatus" (click)="preview()">Preview</div>
      <div class="action" [class.selected]="info?.ejectStatus" (click)="eject()">Eject</div>
      <div class="action small-font" [class.selected]="info?.standbyStatus" (click)="standby()">Stand by</div>

      <!-- Remote Status -->
      <div class="remote-status">
        <div class="title">remote</div>
        <div class="status" [class.online]="streamStatus?.info?.remote"></div>
      </div>

      <!-- Playback -->
      <div class="action action-playback" *ngIf="stream && !streamStatus?.record?.record && streamStatus?.playback?.haveFileToOpen && streamStatus?.playback" (click)="openFile()">
        <mat-icon svgIcon="play" class="icon"></mat-icon>
      </div>
    </div>

    <!-- Center -->
    <div class="actions-center">
      <div class="action small" (click)="setInTimecode()">
        <mat-icon svgIcon="out-point" class="icon rotate" ></mat-icon>
      </div>
      <div class="action" [class.disabled]="!timecodes?.remoteInTimecodePreset" (click)="moveToInTimecode()">
        <mat-icon svgIcon="forward-out-point" class="icon rotate next-clip"></mat-icon>
      </div>
      <div class="action" [class.selected]="info?.rewindStatus" (click)="rewind()">
        <mat-icon svgIcon="forward" class="icon rotate"></mat-icon>
      </div>
      <div class="action" [class.selected]="info?.directionStatus && !info?.rewindStatus" (click)="playReverse()">
        <mat-icon svgIcon="play" class="icon rotate"></mat-icon>
      </div>
      <div class="action pause" [class.selected]="info?.stopStatus" (click)="stop()">
        <mat-icon svgIcon="pause" class="icon"></mat-icon>
      </div>
      <div class="action" [class.selected]="info?.playStatus" (click)="play()">
        <mat-icon svgIcon="play" class="icon"></mat-icon>
      </div>
      <div class="action" [class.selected]="info?.fastForwardStatus" (click)="fastForward()">
        <mat-icon svgIcon="forward" class="icon"></mat-icon>
      </div>
      <div class="action" [class.disabled]="!timecodes?.remoteOutTimecodePreset" (click)="moveToOutTimecode()">
        <mat-icon svgIcon="forward-out-point" class="icon next-clip"></mat-icon>
      </div>
      <div class="action small" (click)="setOutTimecode()">
        <mat-icon svgIcon="out-point" class="icon" ></mat-icon>
      </div>
      <div class="action" [class.selected]="info?.cinedeckAuto" [style.color]="'#FF0000'" (click)="autoMode()">AUTO</div>

      <div
        class="action small"
        popover
        [popoverContent]="inOutPointsPopover"
        popoverPlacement="top"
        popoverTrigger="click"
        [popoverShowUntil]="popoverClose"
      >
        <mat-icon svgIcon="keyboard-timecode" class="icon" ></mat-icon>
      </div>
      <div class="action small-font" (click)="preroll()">Preroll</div>
      <div class="action small-font" [class.selected]="settings?.scrollMode === 'shuttle'" (click)="changeScrollMode('shuttle')">Shuttle</div>
      <div class="action" (click)="cueToTimecodeOffset(-1)">-1</div>
      <div class="action" [class.selected]="settings?.scrollMode === 'jog'" (click)="changeScrollMode('jog')">Jog</div>
      <div class="action" (click)="cueToTimecodeOffset(1)">+1</div>
      <div class="action" [class.selected]="settings?.scrollMode === 'var'" (click)="changeScrollMode('var')">VAR</div>
      <div
        class="action"
        popover
        [popoverContent]="cueTcPopover"
        popoverPlacement="top"
        popoverTrigger="click"
        [popoverShowUntil]="popoverClose"
      >
        Cue to
      </div>
      <div class="action small" (click)="resetInOutPoints()">
        <mat-icon svgIcon="remove-points" class="icon"></mat-icon>
      </div>
      <div class="action record"
        *ngIf="!streamStatus?.record?.record && !streamStatus?.record?.isWaitingForRecord"
        [class.disabled]="info?.cinedeckAuto && !timecodes?.remoteInTimecodePreset"
        matTooltip="IN TC must be specified in AUTO mode"
        [matTooltipDisabled]="!(info?.cinedeckAuto && !timecodes?.remoteInTimecodePreset)"
        (click)="startRecord()"
      >
        <mat-icon svgIcon="record" class="icon"></mat-icon>
      </div>
      <div class="action pause" *ngIf="streamStatus?.record?.record || streamStatus?.record?.isWaitingForRecord" (click)="stopRecord()">
        <mat-icon svgIcon="stop" class="icon"></mat-icon>
      </div>

      <!-- Servo -->
      <div class="action action-servo" [class.disabled]="info?.cinedeckAuto" [class.selected]="!info?.cinedeckAuto && settings?.servo" (click)="changeServo()">
        <mat-icon svgIcon="link" class="icon"></mat-icon>
      </div>

    </div>

    <!-- Right -->
    <div class="actions-right">
      <div class="action action-settings">
        <mat-icon svgIcon="settings" class="icon" (click)="changeStatusView()"></mat-icon>
      </div>
    </div>

  </div>

</div>


<!-- Cue TC Popover -->
<ng-template #cueTcPopover>
  <div class="popover-timecode-content">
    <div class="row">
      <div class="title">Cue to</div>
      <input class="input" placeholder="--:--:--:--" [(ngModel)]="cueTcValue" (keydown.enter)="cueToTimecode(cueTcValue)" (keydown.esc)="closePopover()">
      <div class="button" (click)="cueToTimecode(cueTcValue)" [class.disabled]="!cueTcValue || !cueTcValue?.length">Save</div>
    </div>
  </div>
</ng-template>

<!-- In/Out Point Popover -->
<ng-template #inOutPointsPopover>
  <div class="popover-timecode-content">
    <!-- In Point -->
    <div class="row">
      <div class="title">IN TC</div>
      <input class="input" placeholder="--:--:--:--" [(ngModel)]="inTcValue" (keydown.enter)="setInTimecode(inTcValue)" (keydown.esc)="closePopover()" (focus)="inTcEdit = true" (blur)="inTcEdit = false">
      <div class="button" (click)="setInTimecode(inTcValue)" [class.disabled]="!inTcValue || !inTcValue?.length">Save</div>
    </div>
    <!-- Out Point -->
    <div class="row">
      <div class="title">OUT TC</div>
      <input class="input" placeholder="--:--:--:--" [(ngModel)]="outTcValue" (keydown.enter)="setOutTimecode(outTcValue)" (keydown.esc)="closePopover()" (focus)="outTcEdit = true" (blur)="outTcEdit = false">
      <div class="button" (click)="setOutTimecode(outTcValue)" [class.disabled]="!outTcValue || !outTcValue?.length">Save</div>
    </div>
  </div>
</ng-template>
