import { Component, OnInit } from '@angular/core';

// RxJS
import { filter, take, tap } from 'rxjs/operators';

// Services
import { UserService } from '@modules/users/services/user.service';

// Types
import { UserNames } from '@modules/users/types/user';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.less']
})
export class UserLoginComponent implements OnInit {

  // Public
  public password: string;
  public selectedUser: UserNames;
  public loginError = false;

  /**
   * Constructor
   */

  constructor(
    private userService: UserService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  /**
   * Action
   */

  selectUser(user: UserNames): void {
    this.selectedUser = user;
    this.loginError = false;
    this.userService
      .isEmptyPassword(user)
      .pipe(
        take(1),
        filter(success => success),
        tap(() => this.password = null)
      )
      .subscribe(() => this.login());
  }

  resetError(): void {
    this.loginError = false;
  }

  login(): void {
    this.loginError = false;
    this.userService
      .login(this.selectedUser, this.password)
      .pipe(take(1))
      .subscribe(success => this.loginError = !success);
  }

}
