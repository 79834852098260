import { Component, Inject, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Option } from '@modules/elements/types/option';
import { Preset } from '@modules/preset/types/preset';
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';

type ChannelData = {type: string, value: number};

@Component({
  selector: 'app-preset-settings-audio-routing',
  templateUrl: './preset-settings-audio-routing.component.html',
  styleUrls: ['./preset-settings-audio-routing.component.less']
})
export class PresetSettingsAudioRoutingComponent implements OnInit {

  // Public
  public stream: Stream;
  public preset: Preset;
  public settings: StreamSettings;
  public encoderIndex: number;
  public audioRoutingData: object[];
  public audioChannelOptions: Option<ChannelData>[] = [];

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<PresetSettingsAudioRoutingComponent>,
    private streamService: StreamService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: {stream?: Stream, presetId?: string, encoderIndex: number},
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Data
    this.encoderIndex = this.data.encoderIndex;
    // Get Stream and Settings
    this.streamService.getStream(this.data.stream?.id)
      .pipe(
        takeUntil(this.alive),
        map(stream => this.stream = stream),
        switchMap(stream => this.presetService.getPreset(stream?.presetId || this.data.presetId)),
        takeUntil(this.alive),
      )
      .subscribe(preset => {
        this.preset = preset;
        this.settings = this.preset.getSetting(this.stream?.id);
        this.audioRoutingData = this.settings?.channels[this.stream?.deckChannel ?? 0]?.audioRouting[this.encoderIndex]?.audioRoutingData;
        this.createAudioChannelOptions();
      });
  }

  /**
   * Methods
   */

  createAudioChannelOptions(): void {
    this.audioChannelOptions = [];
    // Silent
    this.audioChannelOptions.push(new Option({type: 'silent', value: 0}, 'Silent'));
    // Channels
    for (let index = 0; index < this.audioRoutingData.length; index++) {
      this.audioChannelOptions.push(new Option({type: 'digital', value: index}, `${index + 1}`));
    }
    // Tones
    this.audioChannelOptions.push(new Option({type: 'tone_generator', value: 200}, 'Tone 200Hz'));
    this.audioChannelOptions.push(new Option({type: 'tone_generator', value: 400}, 'Tone 400Hz'));
    this.audioChannelOptions.push(new Option({type: 'tone_generator', value: 600}, 'Tone 600Hz'));
    this.audioChannelOptions.push(new Option({type: 'tone_generator', value: 800}, 'Tone 800Hz'));
    this.audioChannelOptions.push(new Option({type: 'tone_generator', value: 1000}, 'Tone 1KHz'));
    this.audioChannelOptions.push(new Option({type: 'tone_generator', value: 1200}, 'Tone 1.2KHz'));
  }

  getSelectedOption(channel: Object[]): Option<ChannelData> {
    return this.audioChannelOptions.find(option => option.value.type === channel[0] && option.value.value === channel[1]);
  }

  /**
   * Action
   */

  selectOption(option: Option<ChannelData>, index: number): void {
    const deckChannel = this.stream?.deckChannel ?? 0;
    if (this.settings?.channels[deckChannel]?.audioRouting[this.encoderIndex]?.audioRoutingData) {
      this.audioRoutingData[index] = [option.value.type, option.value.value];
      this.settings.channels[deckChannel].audioRouting[this.encoderIndex].audioRoutingData = this.audioRoutingData;
      if (this.stream) {
        this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
      } else {
        this.presetSettingsService.updateDefaultSetting(this.preset.id, this.settings);
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
