import { Injectable } from '@angular/core';

// RxJS
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { AlertService } from '@modules/elements/services/alert.service';
import { ElectronService } from 'ngx-electron';
import { NodeService } from '@modules/node/services/node.service';

// Types
import { Project } from '../types/project';
import { GlobalState } from '@modules/settings/types/global-state';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  // Private
  private project: Observable<Project>;
  private state: GlobalState;

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private electronService: ElectronService,
    private alertService: AlertService,
    private nodeService: NodeService,
  ) {
    // Project
    this.project = this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.project)
      );
    // State
    this.stateService.getState().subscribe(state => this.state = state);
  }

  /**
   * Project
   */

  getProject(): Observable<Project> {
    return this.project;
  }

  updateProject(project: Project): void {
    this.stateService.updateState({ project });
  }

  exportSettings(): Observable<Error> {
    const result = new Subject<Error>();
    if (this.nodeService.isElectron) {
      const currentWindow = this.electronService.remote.getCurrentWindow();
      this.electronService.remote.dialog
        .showSaveDialog(currentWindow, { defaultPath: 'project', showsTagField: false, properties: ['createDirectory']})
        .then(data => {
          if (!data.filePath) {
            result.complete();
            return;
          }
          const folder = data.filePath;
          const exportPath = folder + '.json';
          this.nodeService.fs?.writeFile(
            exportPath,
            JSON.stringify(this.state),
            (writeError: Error) => {
              if (writeError) {
                const error = new Error(`Project file '${exportPath}' not export: ` + writeError);
                console.error(error.message);
                result.next(error);
                result.complete();
              }
              console.log(`Project file '${exportPath}' export`);
              result.next(null);
              result.complete();
            }
          );
        })
        .catch(() => {});
    } else {
      this.nodeService.downloadJson(this.state, 'project');
    }
      return result.asObservable();
  }

  importSettings(): Observable<Error> {
    const complete = (state: GlobalState, result: Subject<Error>, errorMessage?: string, filePaths?: string) => {
      if (errorMessage) {
        const error = new Error(errorMessage);
        console.error(error);
        result.next(error);
      } else {
        this.stateService.updateState(state);
        console.log(`Project file '${filePaths}' import`);
        result.next(null);
      }
      result.complete();
    };

    const result = new Subject<Error>();
    const currentWindow = this.electronService.remote.getCurrentWindow();
    this.electronService.remote.dialog
      .showOpenDialog(currentWindow, { properties: ['openFile']})
      .then(data => {
        if (!data.filePaths.length) {
          result.complete();
          return;
        }
        const filePaths = data.filePaths[0];
        this.nodeService.fs.readFile(filePaths, (readError, data) => {
          if (readError) {
            complete(null, result, `Project file '${filePaths}' not import: ` + readError);
            return;
          };
          let stateJson = JSON.parse(data);
          if (stateJson) {
            const state = new GlobalState(stateJson);
            const presetsFolderPath = this.nodeService.path.normalize(state.presetsFolder);
            if (state.presetsFolder && this.nodeService.fs.existsSync(presetsFolderPath)) {
              if (state.presetsFolder !== this.state.presetsFolder) {
                this.alertService.show('Use a new path?', 'Path to Presets folder does not match the new one', ['Old', 'New'])
                  .subscribe(buttonIndex => {
                    if (buttonIndex === 0) {
                      state.presetsFolder = this.state.presetsFolder;
                    }
                    complete(state, result, null, filePaths);
                  });
              } else {
                complete(state, result, null, filePaths);
              }
            } else {
              this.alertService.show('New path for Presets does not exist', 'Use the old path for Presets or choose a new one?', ['Old', 'Choose'])
                  .subscribe(buttonIndex => {
                    if (buttonIndex === 0) {
                      state.presetsFolder = this.state.presetsFolder;
                      complete(state, result, null, filePaths);
                    } else if (buttonIndex === 1) {
                      this.electronService.remote.dialog
                        .showOpenDialog(currentWindow, { defaultPath: this.state.presetsFolder, properties: ['openDirectory']})
                        .then(data => {
                          if (!data.filePaths.length) {
                            return;
                          }
                          state.presetsFolder = data.filePaths[0];
                          complete(state, result, null, filePaths);
                        });
                    }
                  });
            }
          } else {
            complete(null, result, `Project file '${filePaths}' can't parse JSON`);
          }
        });
      })
      .catch(() => {});
    return result.asObservable();
  }

  importSettingFile(file): void {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (readEvent) => {
        const jsonText = readEvent.target.result as string;
        const stateJson = JSON.parse(jsonText);
        const state = new GlobalState(stateJson);
        this.stateService.updateState(state);
      };
    }
  }

}
