import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input-settings',
  templateUrl: './input-settings.component.html',
  styleUrls: ['./input-settings.component.less']
})
export class InputSettingsComponent implements OnInit, OnChanges {

  // ViewChild
  @ViewChild('input', { static: false }) input: ElementRef;

  // Inputs
  @Input() value: string | number;
  @Input() placeholder: string = '';
  @Input() type = 'text';
  @Input() validation: (value: string | number) => Error;
  @Input() error: Error;

  // Output
  @Output() valueChange = new EventEmitter<string | number>();

  // Public
  public inputValue: string | number;

  // Private
  private focused: boolean = false;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes && !this.focused) {
      this.inputValue = this.value;
      this.updateInputValue(this.inputValue);
    }
  }

  /**
   * Actions
   */

  startEdit(): void {
    this.focused = true;
  }

  endEdit(): void {
    this.focused = false;
    if (this.type === 'number') {
      this.inputValue = +this.inputValue;
    }
    this.valueChange.emit(this.inputValue);
  }

  updateInputValue(value: string | number): void {
    if (this.validation) {
      if (this.type === 'number') {
        value = +value;
      }
      this.error = this.validation(value);
    }
  }

  blur(): void {
    this.input?.nativeElement?.blur();
  }

}
