import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';

// RxJS
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

// Types
import { User, UserNames } from '../types/user';
import { PermissionKey, Permissions } from '../types/permissions';
import { warmUpObservable } from '@decorators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUser = new BehaviorSubject<UserNames>(null);

  /**
   * Constructor
   */

  constructor(private stateService: StateService) { }

  /**
   * Login
   */

  @warmUpObservable
  login(user: UserNames, password: string): Observable<boolean> {
    return this.stateService.getState()
      .pipe(
        map(state => {
          const currentUser = state.users && state.users[user];

          if (currentUser && (
            (!password && !currentUser.password) ||
            currentUser.password === this.hashPassword(password)
          )) {
            this.currentUser.next(user);
            return true;
          }
          return false;
        }),
        take(1)
      );
  }

  logout(): void {
    this.currentUser.next(null);
  }

  /**
   * User
   */

  getCurrentUser(): Observable<User> {
    return combineLatest([
      this.currentUser,
      this.stateService.getState()
    ])
      .pipe(
        map(([user, state]) => state.users && state.users[user]),
        distinctUntilChanged(isEqual)
      );
  }

  getUser(user: UserNames): Observable<User> {
    return this.stateService.getState()
      .pipe(
        map((state) => state.users && state.users[user]),
        distinctUntilChanged(isEqual)
      );
  }

  isEmptyPassword(user: UserNames): Observable<boolean> {
    return this.stateService.getState()
      .pipe(
        map(state => {
          const currentUser = state.users && state.users[user];
          return currentUser && !currentUser.password;
        }),
        take(1)
      );
  }

  updatePassword(user: UserNames, password: string): void {
    if (this.currentUser.value !== user && this.currentUser.value !== 'root') {
      return;
    }

    this.stateService.getState()
      .pipe(take(1))
      .subscribe(state => {
        if (state.users && state.users[user]) {
          state.users[user].password = password ? this.hashPassword(password) : null;
          this.stateService.updateState({ users: state.users });
        }
      });
  }

  /**
   * Permissions
   */

  getCurrentPermission(premission: PermissionKey): Observable<boolean> {
    return this.getCurrentPermissions()
      .pipe(
        map(permissions => permissions && permissions[premission]),
        distinctUntilChanged()
      );
  }

  getCurrentPermissions(): Observable<Permissions> {
    return this.currentUser
      .pipe(
        switchMap(user => this.getPermissions(user)),
      );
  }

  getPermissions(user: UserNames): Observable<Permissions> {
    return this.stateService.getState()
      .pipe(
        map(state =>
          state.users &&
          state.users[user]?.permissions
        ),
        distinctUntilChanged(isEqual)
      );
  }

  updateCurrentPermissions(permissions: Permissions): void {
    this.updatePermissions(this.currentUser.value, permissions);
  }

  updatePermissions(user: UserNames, permissions: Permissions): void {
    this.stateService.getState()
      .pipe(take(1))
      .subscribe(state => {
        if (state.users && state.users[user]?.permissions) {
          state.users[user].permissions = {
            ...state.users[user].permissions,
            ...permissions
          };
          this.stateService.updateState({ users: state.users });
        }
      });
  }

  /**
   * Hashing provided password
   */
  private hashPassword(password: string): string {
    return password;
  }
}
