<div class="directory-tree-content">
  <div class="title-header">
    <div class="title">Folders</div>
  </div>
  <div class="tree-footer">
    <div class="title" [class.selected]="selection.isEmpty()" (click)="unselectFolder()">Show All Files</div>
  </div>
  <div class="tree-content">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="directory-tree">
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [class.selected]="selection.isSelected(node)" (click)="selectFolder(node)">
        <div class="button-tree-expand" *ngIf="getLevel(dataSource.data, node) === 0">
          <mat-icon svgIcon="arrow" class="mat-icon-rtl-mirror tree-icon" ></mat-icon>
        </div>
        <mat-icon [svgIcon]="getLevel(dataSource.data, node) === 0 ? 'hard-disk' : 'folder-close'" class="folder-icon"></mat-icon>
        {{ node.name }}
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node" [class.selected]="selection.isSelected(node)" (appClick)="selectFolder(node)" (appDblClick)="treeControl.toggle(node)">
          <div class="button-tree-expand" matTreeNodeToggle>
            <mat-icon svgIcon="arrow" class="mat-icon-rtl-mirror tree-icon" [class.expanded]="treeControl.isExpanded(node)"></mat-icon>
          </div>
          <mat-icon [svgIcon]="getLevel(dataSource.data, node) === 0 ? 'hard-disk' : (treeControl.isExpanded(node) ? 'folder-open' : 'folder-close')" class="folder-icon"></mat-icon>
          {{ node.name }}
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.directory-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
