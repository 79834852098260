<div mat-dialog-title class="mat-dialog-title">Add Deck</div>
<div mat-dialog-content class="mat-dialog-content">
  <div class="form">
    <!-- Address -->
    <div class="row">
      <div class="label">IP Address</div>
      <input class="input" placeholder="Enter IP Address..." [ngModel]="deck.address" (ngModelChange)="updateAddress($event)" (blur)="updatedAddress()" [class.error]="error && error.message">
      <app-online-status *ngIf="deck?.address?.length" class="online-status" [size]="8" [value]="onlineStatus"></app-online-status>
    </div>
    <!-- Name -->
    <div class="row">
      <div class="label">Name</div>
      <input class="input" placeholder="Enter deck name..." [ngModel]="deck.name" (ngModelChange)="updateName($event)" [class.error]="error && error.message">
    </div>
    <!-- Port -->
    <div class="row">
      <div class="label"></div>
      <mat-checkbox class="checkbox" [disabled]="false" [checked]="defaultPort" (change)="updateDefaultPort($event)" disableRipple>Use default port</mat-checkbox>
      <input *ngIf="!defaultPort" class="input input-port" placeholder="None" [ngModel]="deck.port" (ngModelChange)="updatePort($event)" (blur)="updatedAddress()" [class.error]="error && error.message">
    </div>
    <!-- SSL -->
    <div class="row">
      <div class="label"></div>
      <mat-checkbox class="checkbox" [disabled]="false" [checked]="deck?.ssl" (change)="updateSSL($event)" disableRipple>SSL</mat-checkbox>
      <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="addAllInputs" disableRipple>Add All Inputs</mat-checkbox>
    </div>
    <!-- Version -->
    <div class="row" *ngIf="deckInfo?.version">
      <div class="label"></div>
      <div class="version">{{ deckInfo?.version }}</div>
    </div>
    <!-- Inputs -->
    <div class="inputs" *ngIf="onlineStatus && channels && channels.length">
      <div class="deck-input" *ngFor="let channel of channels">
        <div class="online-status" [class.online]="true" [class.record]="status?.inputs[channel.index]?.record?.record" [class.pauseRecord]="status?.inputs[channel.index]?.record?.pauseRecord"></div>
        <div class="name">{{ channel.name }}</div>
      </div>
    </div>
    <!-- Error -->
    <div *ngIf="error && error.message" class="error-message">{{ error.message }}</div>
  </div>
</div>
<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="close()">Cancel</div>
  <div class="button" (click)="save()" [class.disabled]="error">Save</div>
</div>
