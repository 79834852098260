export type ChannelLayoutStatus = 'never' | 'hover' | 'always';

export class ChannelLayoutSettings {
  disks: ChannelLayoutStatus;
  input: ChannelLayoutStatus;
  preset: ChannelLayoutStatus;
  timecode: ChannelLayoutStatus;
  audioLevels: ChannelLayoutStatus;
  disksTimeUpdate: number;
  recordButtons: ChannelLayoutStatus;
  playbackButtons: ChannelLayoutStatus;
  playbackCountdown: ChannelLayoutStatus;
  rs422Buttons: ChannelLayoutStatus;
  playbackFilename: ChannelLayoutStatus;
  encoderRecordQueue: ChannelLayoutStatus;
  markerComment: ChannelLayoutStatus;
  markerProgressBar: ChannelLayoutStatus;
  autoPlaybackLoadingFile: boolean;

  constructor() {
    this.disks = 'never';
    this.input = 'hover';
    this.preset = 'hover';
    this.timecode = 'hover';
    this.audioLevels = 'never';
    this.disksTimeUpdate = 10000;
    this.recordButtons = 'hover';
    this.playbackButtons = 'hover';
    this.playbackCountdown = 'hover';
    this.rs422Buttons = 'hover';
    this.playbackFilename = 'never';
    this.encoderRecordQueue = 'never';
    this.markerComment = 'always';
    this.markerProgressBar = 'always';
    this.autoPlaybackLoadingFile = false;
  }
}
