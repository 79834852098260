import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input-inline',
  templateUrl: './input-inline.component.html',
  styleUrls: ['./input-inline.component.less']
})
export class InputInlineComponent implements OnInit, OnChanges {

  // ViewChild
  @ViewChild('input', { static: false }) input: ElementRef;

  // Inputs
  @Input() value: string;
  @Input() placeholder: string = '';
  @Input() editing: boolean = false;

  // Outputs
  @Output() valueChange = new EventEmitter<string>();
  @Output() editingChange = new EventEmitter<boolean>();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['editing'];
    if (change && !change.firstChange && change.previousValue !== change.currentValue && this.editing) {
      setTimeout(() => this.input?.nativeElement?.focus(), 10);
    }
  }

  /**
   * Action
   */

  startEdit(): void {
    setTimeout(() => this.input?.nativeElement?.focus(), 10);
    this.editing = true;
    this.editingChange.emit(true);
  }


  endEdit(): void {
    this.editing = false;
    this.editingChange.emit(false);
    this.valueChange.emit(this.value);
  }

}
