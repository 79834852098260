// Common
import {
  Component, Input, TemplateRef, OnInit, OnDestroy, NgZone, Renderer2, ViewChild, ElementRef,
  EventEmitter, Output, AfterViewInit
} from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, style, animate, AnimationEvent } from '@angular/animations';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

// RX
import { fromEvent, Subject, timer } from 'rxjs';
import { takeUntil, distinct, switchMap, map, filter } from 'rxjs/operators';

// Services
import { DragNDropService } from '@modules/drag-n-drop/services/drag-n-drop.service';

const returnBackMotion: AnimationTriggerMetadata = trigger('returnBackMotion', [
  transition('field => home', [
    style({ top: '{{ currentTop }}', left: '{{ currentLeft }}', opacity: 1 }),
    animate(
      `.5s ease-in-out`,
      style({ top: '{{ destinationTop }}', left: '{{ destinationLeft }}', opacity: .6})
    )
  ]),
]);

@Component({
  selector: 'app-draggable-element',
  templateUrl: './draggable-element.component.html',
  styleUrls: ['./draggable-element.component.less'],
  animations: [returnBackMotion],
})
export class DraggableElementComponent implements OnInit, OnDestroy, AfterViewInit {

  // Inputs
  @Input() dragPlaceholder: TemplateRef<any>;
  @Input() dragDropAreaPlaceholder: TemplateRef<any>;
  @Input() dragPlaceholderNode: any;
  @Input() mouseXAdjustment = 0;
  @Input() mouseYAdjustment = 0;
  @Input() height = 0;
  @Input() width = 0;
  @Input() customStyles = {};
  @Input() multipleStyle = false;
  @Input() homeTop = 0;
  @Input() homeLeft = 0;
  @Input() returnBackAnimation = false;
  @Input() onDropArea = false;

  // Outputs
  @Output() readonly animationFinish = new EventEmitter<void>();

  // Public
  public returnBackMotionState = 'field';
  public currentTop = 0;
  public currentLeft = 0;
  public repositionStyles: SafeStyle = null;
  public afterDropTemplate: TemplateRef<any>;

  // Private
  private alive: Subject<void> = new Subject();
  private afterDrop: Subject<void> = new Subject();

  // View Children
  @ViewChild('container', { static: true }) container: ElementRef;

  /**
   * Constructor
   */

  constructor (
    private ngZone: NgZone,
    private renderer: Renderer2,
    private dragNDropService: DragNDropService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    // Set initial position
    this.renderer.setStyle(this.container.nativeElement, 'top', this.homeTop + 'px');
    this.renderer.setStyle(this.container.nativeElement, 'left', this.homeLeft + 'px');

    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'mousemove')
        .pipe(
          takeUntil(this.alive),
          takeUntil(this.afterDrop),
          distinct((event: MouseEvent) => `${event.pageY}x${event.pageX}`)
        )
        .subscribe((event: MouseEvent) => {
          this.dragNDropService.checkDroppableAreas(event.pageX, event.pageY);

          // don't need change detection here
          this.currentTop = event.pageY - this.mouseYAdjustment;
          this.currentLeft = event.pageX - this.mouseXAdjustment;

          this.renderer.setStyle(this.container.nativeElement, 'top', this.currentTop + 'px');
          this.renderer.setStyle(this.container.nativeElement, 'left', this.currentLeft + 'px');
        });

      fromEvent(document, 'mousedown')
        .pipe(
          filter((event: MouseEvent) => !this.elementRef.nativeElement.contains(event.target)),
          takeUntil(this.alive)
        )
        .subscribe(() => this.ngZone.run(() => this.dragNDropService.setDragging(null)));
    });

    this.dragNDropService.getDropOutside()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        if (this.returnBackAnimation) {
          this.returnBackMotionState = 'home';
        } else {
          this.animationFinish.emit();
        }
      });

    this.dragNDropService.getRepositionThread()
      .pipe(
        switchMap((reposition: boolean) => (
          timer(reposition ? 0 : 1000)
            .pipe(map(() => reposition))
        )),
        takeUntil(this.alive)
      )
      .subscribe((reposition: boolean) => {
        this.setReposition(reposition);
      });

    this.dragNDropService.getControlledDrop()
      .pipe(
        filter((afterDropTemplate: TemplateRef<any>) => !!afterDropTemplate),
        takeUntil(this.alive)
      )
      .subscribe((afterDropTemplate: TemplateRef<any>) => {
        this.afterDropTemplate = afterDropTemplate;
        this.afterDrop.next();
      });
  }

  ngAfterViewInit() {
    this.dragNDropService.setCurrentPlaceholder(this.container.nativeElement);
  }

  ngOnDestroy(): void {
    this.dragNDropService.setCurrentPlaceholder(null);
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleAfterReturnBackAnimation(e: AnimationEvent): void {
    if (e.toState === 'home') {
      this.animationFinish.emit();
    }
  }

  /**
   * Helpers
   */

  private setReposition(reposition: boolean) {
    if (reposition && this.repositionStyles === null) {
      this.ngZone.run(() => {
        this.repositionStyles = this.sanitizer.bypassSecurityTrustStyle(`
          translateX(${ this.mouseXAdjustment + 10 }px)
          translateY(${ this.mouseYAdjustment + 10 }px)
        `);
      });
    } else if (!reposition && this.repositionStyles !== null) {
      this.ngZone.run(() => {
        this.repositionStyles = null;
      });
    }
  }
}
