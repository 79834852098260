import { Component, Inject, OnInit } from '@angular/core';

// RxJS
import { combineLatest, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { UserService } from '@modules/users/services/user.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { ScheduleService } from '@modules/schedule/services/schedule.service';
import { ChannelService } from '@modules/channel/services/channel.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Option } from '@modules/elements/types/option';
import { Schedule, ScheduleAction, ScheduleInterval } from '@modules/schedule/types/schedule';
import { ChannelsGroup } from '@modules/channel/types/channels-group';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RRule, RRuleSet, rrulestr, Frequency, Options, ByWeekday, Weekday } from 'rrule';

@Component({
  selector: 'app-schedule-create',
  templateUrl: './schedule-create.component.html',
  styleUrls: ['./schedule-create.component.less']
})
export class ScheduleCreateComponent implements OnInit {

  // Public
  public editing = false;
  public schedule = new Schedule();
  public streamId: string;
  public startTime: Date = null;
  public endTime: Date = null;
  public minDate = new Date();
  public rrule: RRule = new RRule({freq: Frequency.DAILY});
  public error = {
    noTime: false,
    startTime: false,
    pastTime: false,
    intersectionTime: false,
  };
  // Options
  public inputOptions: Option<string>[] = [];
  public presetsOptions: Option<string>[] = [];
  public actionsOptions: Option<ScheduleAction>[] = [
    new Option('record', 'Record')
  ];
  public intervalOptions: Option<ScheduleInterval>[] = [
    new Option('once', 'Once'),
    new Option('repeat', 'Repeat')
  ];
  public frequencyOptions: Option<Frequency>[] = [
    new Option(Frequency.DAILY, 'Daily'),
    new Option(Frequency.WEEKLY, 'Weekly'),
    new Option(Frequency.MONTHLY, 'Monthly'),
  ];
  public weekdayOptions: Option<Weekday>[] = [
    new Option(RRule.MO, 'Monday'),
    new Option(RRule.TU, 'Tuesday'),
    new Option(RRule.WE, 'Wednesday'),
    new Option(RRule.TH, 'Thursday'),
    new Option(RRule.FR, 'Friday'),
    new Option(RRule.SA, 'Saturday'),
    new Option(RRule.SU, 'Sunday'),
  ];

  // Private
  private groups: ChannelsGroup[] = [];
  private schedules: Schedule[];
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    private userService: UserService,
    private presetService: PresetService,
    private scheduleService: ScheduleService,
    private channelService: ChannelService,
    public dialogRef: MatDialogRef<ScheduleCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {schedule: Schedule},
  ) {
    if (data?.schedule) {
      this.schedule = JSON.parse(JSON.stringify(data.schedule));
      this.schedule.startDate = this.schedule.startDate && new Date(this.schedule.startDate);
      this.schedule.endDate = this.schedule.endDate && new Date(this.schedule.endDate);
      this.editing = true;
      this.startTime = this.schedule.startDate && new Date(this.schedule.startDate);
      this.endTime = this.schedule.endDate && new Date(this.schedule.endDate);
      this.streamId = this.schedule.streamId || this.schedule.groupId;
      this.rrule = this.schedule.rrule ? rrulestr(this.schedule.rrule) : this.rrule;
      // Use End date if Start date not set
      this.schedule.startDate = this.startTime ? this.schedule.startDate : this.schedule.endDate;
    }
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Set current user for new schedule
    if (!this.editing) {
      this.userService.getCurrentUser()
        .pipe(take(1))
        .subscribe(user => this.schedule.user = user.name);
    }
    // Get Presets
    this.presetService.getPresets()
      .pipe(
        map(presets => presets.filter(preset => preset.isValid())),
        takeUntil(this.alive),
      )
      .subscribe(presets => {
        this.presetsOptions = presets.map(preset => new Option(preset.id, preset.name));
        this.presetsOptions.unshift(new Option(null, '(Current Preset)'));
      });
    // Get Inputs and Groups
    combineLatest([
      this.streamService.getStreams().pipe(map(streams => streams.filter(stream => (stream.deckChannel !== null && stream.deckChannel !== undefined) && stream.deckId))),
      this.channelService.getChannelsGroups()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([streams, groups]) => {
        this.groups = groups;
        this.inputOptions = streams.map(stream => new Option(stream.id, stream.name));
        this.inputOptions.push(...this.groups.map(group => new Option(group.id, group.name)));
      });
    // Get Schedules
    this.scheduleService.getSchedules()
      .pipe(takeUntil(this.alive))
      .subscribe(schedules => this.schedules = schedules);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  validation(): boolean {
    if (!this.streamId) { return false; }
    if (!this.schedule.action) { return false; }
    if (!this.schedule.interval) { return false; }
    if (this.schedule.interval === 'once' && (!this.schedule.startDate || !(this.schedule.startDate instanceof Date))) { return false; }
    if (!(this.startTime && this.startTime instanceof Date) && !(this.endTime && this.endTime instanceof Date)) { return false; }
    if (this.schedule.interval === 'repeat' && this.rrule?.origOptions?.freq === Frequency.WEEKLY && !this.rrule?.origOptions?.byweekday) { return false; }
    if (this.schedule.interval === 'repeat' && this.rrule?.origOptions?.freq === Frequency.MONTHLY && !this.schedule.startDate) { return false; }
    return true;
  }


  datesOverlaps(dateStart1: Date, dateEnd1: Date, dateStart2: Date, dateEnd2: Date): boolean {
    if (dateStart1 <= dateStart2 && dateStart2 <= dateEnd1) { return true; }
    if (dateStart1 <= dateEnd2   && dateEnd2   <= dateEnd1) { return true; }
    if (dateStart2 <  dateStart1 && dateEnd1   <  dateEnd2) { return true; }
    return false;
  }

  /**
   * Actions
   */

  updateInput(option: Option<string>): void {
    this.streamId = option.value;
  }

  updatePreset(option: Option<string>): void {
    this.schedule.presetId = option.value;
  }

  updateAction(option: Option<ScheduleAction>): void {
    this.schedule.action = option.value;
  }

  updateInterval(option: Option<ScheduleInterval>): void {
    this.schedule.interval = option.value;
  }

  updateDate(event: MatDatepickerInputEvent<Date>): void {
    if (event?.value) {
      this.error.pastTime = false;
      this.schedule.startDate = event.value;
      this.schedule.endDate = new Date(event.value);
    }
  }

  updateTime(value: string): void {
    this.error.noTime = false;
    this.error.startTime = false;
    this.error.pastTime = false;
  }

  updateFrequency(option: Option<Frequency>): void {
    this.rrule.origOptions.freq = option.value;
    this.rrule = rrulestr(this.rrule.toString());
    if (this.rrule.origOptions.freq === Frequency.WEEKLY) {
      this.schedule.startDate = null;
      this.schedule.endDate = null;
    }
  }

  updateWeekday(option: Option<Weekday>): void {
    if (!Array.isArray(this.rrule.origOptions.byweekday)) {
      this.rrule.origOptions.byweekday = [];
    }
    const index = this.rrule.origOptions.byweekday.findIndex((weekday: Weekday) => weekday.weekday === option.value.weekday);
    if (index !== -1) {
      this.rrule.origOptions.byweekday.splice(index, 1);
    } else {
      this.rrule.origOptions.byweekday.push(option.value);
    }
    this.rrule = rrulestr(this.rrule.toString());
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    // Check for validation
    if (!this.validation()) {
      return;
    }

    // Update stream or group id
    const index = this.groups.findIndex(group => group.id === this.streamId);
    if (index !== -1) {
      this.schedule.streamId = null;
      this.schedule.groupId = this.streamId;
    } else {
      this.schedule.groupId = null;
      this.schedule.streamId = this.streamId;
    }

    // Update dates
    this.schedule.startDate = this.schedule.startDate ?? new Date();
    this.schedule.endDate = this.schedule.endDate ?? new Date();
    // Set Stat Time
    if (this.startTime) {
      this.schedule.startDate.setHours(this.startTime.getHours());
      this.schedule.startDate.setMinutes(this.startTime.getMinutes());
      this.schedule.startDate.setSeconds(0);
    } else {
      this.schedule.startDate = null;
    }
    // Set End Time
    if (this.endTime) {
      this.schedule.endDate.setHours(this.endTime.getHours());
      this.schedule.endDate.setMinutes(this.endTime.getMinutes());
      this.schedule.endDate.setSeconds(0);
      // If end date
      if (this.startTime && this.endTime && this.schedule.startDate.getTime() >= this.schedule.endDate.getTime()) {
      }
    } else {
      this.schedule.endDate = null;
    }

    // Validate time
    if (!(this.startTime && this.startTime instanceof Date) && !(this.endTime && this.endTime instanceof Date)) {
      this.error.noTime = true;
      return;
    }
    // If end date less then start date -> set next date end
    if (this.startTime && this.endTime && this.schedule.startDate.getTime() >= this.schedule.endDate.getTime()) {
      this.schedule.endDate.setDate(this.schedule.endDate.getDate() + 1);
    }
    if (this.schedule.interval === 'once' && (this.schedule.startDate || this.schedule.endDate).getTime() <= Date.now()) {
      this.error.pastTime = true;
      return;
    }
    this.error.intersectionTime = false;

    // RRule
    this.rrule.origOptions.dtstart = this.schedule.startDate || this.schedule.endDate;
    if (this.rrule.origOptions.freq !== Frequency.WEEKLY) {
      this.rrule.origOptions.byweekday = null;
    }
    this.rrule = rrulestr(this.rrule.toString());
    this.schedule.rrule = this.rrule.toString();

    // Save
    if (this.editing) {
      this.scheduleService.updateSchedule(this.schedule);
    } else {
      this.scheduleService.addSchedule(this.schedule);
    }

    // Close
    this.dialogRef.close();
  }

}
