<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Channel Status</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="channels-settings-content">

  </div>
</div>
