<div class="preset-name-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Naming Templates{{preset ? ' - ' + preset.name : ''}}</div>
    <div class="actions">
      <mat-icon svgIcon="settings-sliders" class="action-icon" (click)="openSettings()" [class.disabled]="!stream || !allowed"></mat-icon>
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" (click)="lock()" [class.disabled]="!stream || !allowed"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon" [class.disabled]="!stream"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content" *ngIf="stream && preset">

    <!-- Same tamplate -->
    <div class="settings-section" [class.disabled]="!allowed || locked">
      <div class="settings-content">
        <mat-checkbox [(ngModel)]="preset.nameTemplates.sameTemplatesForAllEncoders" (ngModelChange)="updateSettings()" disableRipple>All encoders use same templates</mat-checkbox>
      </div>
    </div>

    <!-- Encoders -->
    <ng-container *ngFor="let nameTemplate of ((preset.nameTemplates.sameTemplatesForAllEncoders && preset?.nameTemplates?.nameTemplates?.length) ? [preset?.nameTemplates.nameTemplates[0]] : preset?.nameTemplates.nameTemplates); let encoderIndex = index">

      <!-- Path -->
      <div class="settings-section" [class.disabled]="!allowed || locked">
        <div class="settings-header">{{ encoderIndex === 0 ? 'Primary' : 'Proxy ' + encoderIndex }} Paths</div>
        <div class="settings-content">
          <app-preset-name-template class="name-template" title="Primary" [nameTemplate]="nameTemplate.primaryPath" (nameTemplateChange)="updateNameTemplate($event, 'primaryPath', encoderIndex)" [showSample]="false"></app-preset-name-template>
          <app-preset-name-template class="name-template" title="Secondary" [nameTemplate]="nameTemplate.secondaryPath" (nameTemplateChange)="updateNameTemplate($event, 'secondaryPath', encoderIndex)" [showSample]="false" [disabled]="preset.nameTemplates.secondaryPathSame[encoderIndex]"></app-preset-name-template>
          <mat-checkbox [(ngModel)]="preset.nameTemplates.secondaryPathSame[encoderIndex]" (ngModelChange)="updateSecondaryPathSame(encoderIndex)" disableRipple>Secondary Path Same as Primary Path</mat-checkbox>
        </div>
      </div>

      <!-- File name -->
      <div class="settings-section" [class.disabled]="!allowed || locked">
        <div class="settings-header">{{ encoderIndex === 0 ? 'Primary' : 'Proxy ' + encoderIndex }} File Name</div>
        <div class="settings-content">
          <app-preset-name-template class="name-template" [nameTemplate]="nameTemplate.fileName" (nameTemplateChange)="updateNameTemplate($event, 'fileName', encoderIndex)" [showSample]="true"></app-preset-name-template>
        </div>
      </div>

      <!-- Tape ID -->
      <div class="settings-section" [class.disabled]="!allowed || locked">
        <div class="settings-header">Encoder Tape ID (opAtom) or Reel (MOV)</div>
        <div class="settings-content">
          <app-preset-name-template class="name-template" [nameTemplate]="nameTemplate.tapeId" (nameTemplateChange)="updateNameTemplate($event, 'tapeId', encoderIndex)" [showSample]="true"></app-preset-name-template>
        </div>
      </div>

      <!-- Primary Misc -->
      <div class="settings-section" [class.disabled]="!allowed || locked">
        <div class="settings-header">{{ encoderIndex === 0 ? 'Primary' : 'Proxy ' + encoderIndex }} Misc</div>
        <div class="settings-content">
          <div class="misc-content">
            <!-- Segment suffix -->
            <div class="suffix">
              <div class="title">Segment Suffix</div>
              <input class="input" [(ngModel)]="nameTemplate.primarySegSuffix" (blur)="updateSettings()">
            </div>
            <!-- EDL suffix -->
            <div class="suffix">
              <div class="title">EDL Suffix</div>
              <input class="input"[(ngModel)]="nameTemplate.primaryEdlSuffix" (blur)="updateSettings()">
            </div>
            <!-- Take Counter -->
            <div class="counter">
              <div class="title">Take Counter</div>
              <div class="field" [class.disabled]="true">
                <div class="label">Start</div>
                <input class="input">
              </div>
              <div class="field" [class.disabled]="true">
                <div class="label">Leading Zeroes</div>
                <input class="input">
              </div>
            </div>
            <!-- Roll Counter -->
            <div class="counter">
              <div class="title">Roll Counter</div>
              <div class="field" [class.disabled]="true">
                <div class="label">Start</div>
                <input class="input">
              </div>
              <div class="field" [class.disabled]="true">
                <div class="label">Leading Zeroes</div>
                <input class="input">
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>

    <!-- Replace spaces -->
    <div class="settings-section" [class.disabled]="!allowed || locked">
      <div class="settings-content">
        <mat-checkbox [(ngModel)]="preset.nameTemplates.allowSpaces" (ngModelChange)="updateSettings()" disableRipple>Replace spaces with underscores</mat-checkbox>
      </div>
    </div>

  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!stream">
    <div class="text">Select a <b>Channel</b> first to start working with <b>Naming Templates</b></div>
  </div>
  <div class="placeholder-empty" *ngIf="stream && !preset">
    <div class="text">Select a <b>Preset</b> for <b>{{ stream.name }}</b></div>
  </div>

</div>
