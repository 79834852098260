import { Injectable } from '@angular/core';

// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// Types
import { GlobalState } from '../types/global-state';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  // Private
  private currentStates: BehaviorSubject<GlobalState>;
  private defaultStates = new GlobalState();

  /**
   * Constructor
   */

  constructor() {
    this.currentStates = new BehaviorSubject<GlobalState>({
      ...this.defaultStates,
      ...this.getSavedState()
    });
    this.currentStates.subscribe((states: GlobalState) => this.saveState(states));
  }

  /**
   * Public Methods
   */

  getState(): Observable<GlobalState> {
    return this.currentStates
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  updateState(states: GlobalState): void {
    this.currentStates.next({
      ...this.currentStates.value,
      ...states
    });
  }

  /**
   * Private Methods
   */

  private getSavedState(): GlobalState {
    try {
      return new GlobalState(JSON.parse(localStorage.getItem('app.states')) || {});
    } catch (e) {
      console.error('Can not parse JSON from localStore. ', e);
      return {} as GlobalState;
    }
  }

  private saveState(states: GlobalState): void {
    localStorage.setItem('app.states', JSON.stringify(states));
  }
}
