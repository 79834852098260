import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '@modules/layout/services/layout.service';

// Types
import { Layout } from '@modules/layout/types/layout';

@Component({
  selector: 'app-center-panel',
  templateUrl: './center-panel.component.html',
  styleUrls: ['./center-panel.component.less']
})
export class CenterPanelComponent implements OnInit, OnDestroy {

  // Public
  public layout: Layout;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private layoutService: LayoutService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.layoutService.getLayout()
      .pipe(takeUntil(this.alive))
      .subscribe(layout => this.layout = layout);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

}
