import { Component, OnInit } from '@angular/core';

// RxJS
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@modules/users/services/user.service';
import { ScheduleService } from '@modules/schedule/services/schedule.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { ChannelService } from '@modules/channel/services/channel.service';

// Components
import { ScheduleCreateComponent } from '../schedule-create/schedule-create.component';

// Types
import { Schedule } from '@modules/schedule/types/schedule';
import { UserNames } from '@modules/users/types/user';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.less']
})
export class ScheduleListComponent implements OnInit {

  // Public
  public locked = false;
  public allowed: boolean;
  public allowedEdit: boolean;
  public schedules: Schedule[] = [];

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private scheduleService: ScheduleService,
    private streamService: StreamService,
    private presetService: PresetService,
    private channelService: ChannelService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Allow
    this.userService
      .getCurrentPermission('addScheduledRecords')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowed = allowed);
    this.userService
      .getCurrentPermission('editScheduledRecords')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowedEdit = allowed);

    // Get Schedules
    this.scheduleService.getSchedules()
      .pipe(
        map(schedules => 
          schedules
            .sort((s1, s2) => this.scheduleService.getScheduleDate(s1).getTime() - this.scheduleService.getScheduleDate(s2).getTime())
            .sort((s1, s2) => {
              const complete1 = this.isCompleted(s1);
              const complete2 = this.isCompleted(s2);
              if (complete1 && complete2) {
                return this.scheduleService.getScheduleDate(s2).getTime() - this.scheduleService.getScheduleDate(s1).getTime();
              }
              return Number(complete1) - Number(complete2);
            })
        ),
        takeUntil(this.alive)
      )
      .subscribe(schedules => this.schedules = schedules);
  }

  /**
   * Methods
   */

  getName(user: UserNames): Observable<string> {
    return this.userService.getUser(user)
      .pipe(
        map(user => user?.displayName),
        takeUntil(this.alive)
      );
  }

  getPreset(id: string): Observable<string> {
    return this.presetService.getPreset(id)
      .pipe(
        map(preset => preset?.name || '(Current)'),
        takeUntil(this.alive)
      );
  }

  getPresetValidation(id: string): Observable<boolean> {
    return this.presetService.getPreset(id)
      .pipe(
        map(preset => preset ? preset?.isValid() : true),
        takeUntil(this.alive)
      );
  }

  getInput(schedule: Schedule): Observable<string> {
    return combineLatest([
      this.streamService.getStream(schedule.streamId),
      this.channelService.getChannelsGroups().pipe(map(groups => groups.find(group => group.id === schedule.groupId)))
    ])
      .pipe(
        map(([stream, group]) => stream?.name || group?.name),
        takeUntil(this.alive)
      )
  }

  isCompleted(schedule: Schedule): boolean {
    return schedule.interval === 'once' &&
      ((schedule.startDate && schedule.endDate && (new Date(schedule.endDate).getTime() - Date.now()) < 0) ||
      (!schedule.endDate && (new Date(schedule.startDate).getTime() - Date.now()) < 0) ||
      (!schedule.startDate && (new Date(schedule.endDate).getTime() - Date.now()) < 0));
  }

  isInProgress(schedule: Schedule): boolean {
    return schedule.startDate && (new Date(schedule.startDate).getTime() - Date.now()) < 0 &&
      schedule.endDate && (new Date(schedule.endDate).getTime() - Date.now()) > 0;
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  createSchedule(): void {
    this.dialog.open(ScheduleCreateComponent, { autoFocus: false, disableClose: true });
  }

  editSchedule(schedule: Schedule): void {
    this.dialog.open(ScheduleCreateComponent, {autoFocus: false, data: {schedule}, disableClose: true});
  }

  deleteSchedule(schedule: Schedule): void {
    this.scheduleService.deleteSchedule(schedule);
  }

  duplicateSchedule(schedule: Schedule): void {
    const cloneSchedule = Schedule.cloneWithNewId(schedule);
    this.scheduleService.addSchedule(cloneSchedule);
  }

}
