import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { StateService } from '@modules/settings/services/state.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Types
import { Wildcard } from '@modules/preset/types/wildcard';
import { Stream } from '@modules/stream/types/stream';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Option } from '@modules/elements/types/option';
import { Preset } from '@modules/preset/types/preset';

@Component({
  selector: 'app-preset-name-settings',
  templateUrl: './preset-name-settings.component.html',
  styleUrls: ['./preset-name-settings.component.less']
})
export class PresetNameSettingsComponent implements OnInit, OnDestroy {

  // Public
  public stream: Stream;
  public preset: Preset;
  public wildcards: Wildcard[][] = Wildcard.getList();
  public nameSeparators: Wildcard[] = Wildcard.getNameSeparators();
  public pathSeparators: Wildcard[] = Wildcard.getPathSeparators();
  public defaultNameSeparator = new Option<string>('underscore', 'Underscore');
  public defaultNameSeparatorOptions: Option<string>[] = [
    new Option('none', 'None'),
    new Option('underscore', 'Underscore'),
    new Option('dash', 'Dash'),
    new Option('space', 'Space'),
  ];
  public removeNearSeparator = true;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private streamService: StreamService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    public dialogRef: MatDialogRef<PresetNameSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {stream: Stream},
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Stream and Settings
    this.streamService.getStream(this.data.stream.id)
      .pipe(
        takeUntil(this.alive),
        map(stream => this.stream = stream),
        switchMap(stream => this.presetService.getPreset(stream?.presetId)),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(preset => {
        this.preset = preset;
        this.defaultNameSeparator = this.defaultNameSeparatorOptions.find(option => option.value == preset.defaultNameSeparator)
      });
    // Replace user list
    this.presetService.getCurrentPreset()
      .pipe(
        map(preset => preset.userList),
        distinctUntilChanged(),
        takeUntil(this.alive),
      )
      .subscribe(list => {
        const userList = [];
        list.forEach(item => userList.push(new Wildcard(item.name, item.value, item.color + '80')));
        this.wildcards[2] = userList;
      });

    // Global Wildcards
    this.stateService.getState()
      .pipe(
        map(state => state.globalList),
        distinctUntilChanged(),
        takeUntil(this.alive),
      )
      .subscribe(list => {
        const globalList = [];
        list.forEach(item => globalList.push(new Wildcard(item.name, item.value, item.color + '80')));
        this.wildcards[3] = globalList;
      });
    // Remove near Separator
    this.stateService.getState()
      .pipe(
        map(state => state.removeNearSeparator),
        takeUntil(this.alive),
      )
      .subscribe(removeNearSeparator => this.removeNearSeparator = removeNearSeparator);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Action
   */

  close(): void {
    this.dialogRef.close();
  }

  updateSettings(): void {
    this.presetSettingsService.updatePresetNameTemplate(this.preset);
  }

  updateNameTemplate(nameTemplate: string, field: string, encoderIndex: number = 0): void {
    this.preset.nameTemplates.nameTemplates[encoderIndex][field] = nameTemplate;
    if (field === 'primaryPath' && this.preset.nameTemplates.secondaryPathSame[encoderIndex]) {
      this.preset.nameTemplates.nameTemplates[encoderIndex]['secondaryPath'] = nameTemplate;
    }
    this.updateSettings();
  }

  updateImageNameTemplate(nameTemplate: string): void {
    this.preset.nameTemplates.imageCaptureFilenameTemplate = nameTemplate;
    this.updateSettings();
  }

  updateSecondaryPathSame(encoderIndex: number): void {
    if (this.preset.nameTemplates.secondaryPathSame[encoderIndex]) {
      this.preset.nameTemplates.nameTemplates[encoderIndex].secondaryPath = this.preset.nameTemplates.nameTemplates[encoderIndex].primaryPath;
    }
    this.updateSettings();
  }

  changeDefaultNameSeparator(option: Option<string>): void {
    this.defaultNameSeparator = option;
    this.preset.defaultNameSeparator = option.value;
    this.presetService.updatePreset(this.preset);
  }

  changeRemoveNearSeparator(value: boolean): void {
    this.removeNearSeparator = value;
    this.stateService.updateState({removeNearSeparator: value});
  }

  /**
   * DnD
   */

  noDropPredicate(item): boolean {
    return false;
  }

}
