import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { DeckService } from '@modules/deck/services/deck.service';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';

// Types
import { Marker } from '@modules/marker/types/marker';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-playlist-files-item',
  templateUrl: './playlist-files-item.component.html',
  styleUrls: ['./playlist-files-item.component.less']
})
export class PlaylistFilesItemComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() stream: Stream;
  @Input() clip: any;
  @Input() index: number;
  @Input() thumbnailUrl: string;
  @Input() selected: boolean;
  @Input() played: boolean;
  @Input() currentPlay: boolean;

  // Outputs
  @Output() openContextMenu = new EventEmitter<MouseEvent>()

  // Public
  public marker: Marker;
  public noCache = Math.random() * 1000 + '';
  public popoverClose = new Subject<void>();

  // Private
  private alive = new Subject<void>();
  private markerId = new BehaviorSubject(null);

  /**
   * Constructor
   */

  constructor(
    private playlistService: PlaylistService,
    private deckService: DeckService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.markerId.asObservable()
      .pipe(
        map(markerId => ({markerId, deckId: this.stream?.deckId})),
        distinctUntilChanged(isEqual),
        filter(({markerId, deckId}) => {
          if (!markerId || !deckId) {
            this.marker = null;
            return false;
          }
          return true;
        }),
        switchMap(({markerId, deckId}) => {
          const deck = this.deckService.getDeckSync(deckId);
          return this.deckService.loggerDBChanged(deck)
            .pipe(
              startWith(<string>null),
              map(() => markerId)
            )
        }),
        switchMap(markerId => this.playlistService.getMarker(this.stream?.deckId, markerId)),
        takeUntil(this.alive)
      )
      .subscribe(marker => this.marker = marker);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('clip' in changes || 'stream' in changes) {
      this.markerId.next(this.clip?.markersId);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
    this.popoverClose.next();
    this.popoverClose.complete();
  }

  contextMenu(event: MouseEvent): void {
    this.openContextMenu.emit(event);
  }

}
