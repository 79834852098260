<div
  class="draggable-container"
  [ngClass]="{'multiple-container': multipleStyle}"
  #container
  [style.height]="height > 0 ? height + 'px' : 'auto'"
  [style.width]="width > 0 ? width + 'px' : 'auto'"
  [style.transform]="repositionStyles"
  [ngStyle]="customStyles"
  [@returnBackMotion]="{
    value: returnBackMotionState,
    params: {
      destinationTop: homeTop + 'px',
      destinationLeft: homeLeft + 'px',
      currentTop: currentTop + 'px',
      currentLeft: currentLeft + 'px'
    }
  }"
  (@returnBackMotion.done)="handleAfterReturnBackAnimation($event)"
>
  <div [class.after-drop-container]="!!afterDropTemplate" *ngIf="!(onDropArea && dragDropAreaPlaceholder)">
    <ng-container
      *ngTemplateOutlet="!!afterDropTemplate ? afterDropTemplate : dragPlaceholder"
    ></ng-container>
  </div>
  <div *ngIf="onDropArea && dragDropAreaPlaceholder">
    <ng-container *ngTemplateOutlet="dragDropAreaPlaceholder"></ng-container>
  </div>
</div>
