import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

// External modules
import { ContextMenuModule } from 'ngx-contextmenu';
import { AngularFittextModule } from 'angular-fittext';

// Modules
import { ElementsModule } from '@modules/elements/elements.module';

// Components
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { ScheduleCreateComponent } from './components/schedule-create/schedule-create.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    // Modules
    ElementsModule,

    // External
    AngularFittextModule,
    ContextMenuModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    ScheduleListComponent,
    ScheduleCreateComponent
  ],
  exports: [
    ScheduleListComponent
  ]
})
export class ScheduleModule { }
