import { UniqueID } from '@modules/elements/types/unique-id';
import { RS422Settings } from '@modules/rs422/types/rs422-settings';
import { ChannelLayoutSettings } from './channel-layout-settings';
import { ChannelStreamSettings } from './channel-stream-settings';

export class Channel {
  id: string;
  streamId?: string;
  audioLevels?: boolean = true;
  rs422Settings?: RS422Settings;
  layoutSettings?: ChannelLayoutSettings;
  streamSettings?: ChannelStreamSettings;
  

  constructor() {
    this.id = UniqueID.generate();
    this.audioLevels = true;
    this.rs422Settings = new RS422Settings();
    this.layoutSettings = new ChannelLayoutSettings();
    this.streamSettings = new ChannelStreamSettings();
  }
}
