<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Channel errors</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="channel-errors-dialog-content">

    <!-- Errors -->
    <div class="channel-errors">
      <div class="error" *ngFor="let error of (errors | async)">
        <div class="date">{{ error.date | date:'medium' }}</div>
        <div class="message">{{ error.message }}</div>
      </div>
    </div>

  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="clear()" [class.disabled]="!(errors | async).length">Clear</div>
</div>
