import { Component, Input, OnInit, ElementRef, ViewChild, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter, NgZone } from '@angular/core';

// RxJS
import { BehaviorSubject, interval, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { ChannelService } from '@modules/channel/services/channel.service';

// Types
import { WebGLRenderer } from '@modules/stream/types/webgl-renderer';
import { Stream } from '@modules/stream/types/stream';
import { Volume } from '@modules/project/types/volume';

// Cinedeck Web Preview
declare var CinedeckPreview: any;

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.less']
})
export class StreamComponent implements OnInit, OnChanges, OnDestroy {

  // ViewChild
  @ViewChild('webPreview', { static: true }) webPreviewElement: ElementRef<HTMLElement>;

  // Inputs
  @Input() streamId: string;
  @Input() audioPair: number = 0;
  @Input() aspectRatio;
  @Input() volume: Volume;

  // Output
  @Output() timecodeChange = new EventEmitter<any>();
  @Output() audioLevels = new EventEmitter<Array<{ peak: number, rm: number }>>();

  // Public
  public preview: any;
  public noPreview = false;
  public online = false;

  // Private
  private streamIdChanges = new BehaviorSubject<string>(null);
  private renderer: WebGLRenderer;
  private frameSize = { width: null, height: null };
  private alive = new Subject<void>();
  private previewAlive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    private deckService: DeckService,
    private channelService: ChannelService,
    private ngZone: NgZone,
  ) {
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Online Status
    this.getOnlineStatus();

    // Setup preview
    this.setupPreview();

    // Update canvas size
    this.channelService.getChannelsGrid()
      .pipe(
        delay(10),
        takeUntil(this.alive)
      )
      .subscribe(channelsGrid => this.zoomFit());

    // Disable preview
    this.streamService.previewsDisabled.asObservable()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(disable => {
        if (this.preview) {
          this.preview.setVideo(!disable);
        }
      });
    // Check for resize canvas
    interval(1000)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.checkForCanvasSizeChange());

    // Check volume
    interval(3000)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.updateVolume());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('streamId' in changes) {
      this.streamIdChanges.next(this.streamId);
    }
    if ('aspectRatio' in changes && !isEqual(changes.aspectRatio.currentValue, changes.aspectRatio.previousValue)) {
      this.zoomFit();
    }
    if ('volume' in changes && isEqual(changes.volume.currentValue, changes.volume.previousValue) && !changes.volume.firstChange) {
      this.updateVolume();
    }
    if ('audioPair' in changes && this.preview) {
      this.preview.setAudioPair(this.audioPair);
    }
  }

  ngOnDestroy(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer?.destroy();
    });
    this.preview?.destroy();
    this.alive.next();
    this.alive.complete();
    this.previewAlive.next();
    this.previewAlive.complete();
  }

  /**
   * Methods
   */

  getOnlineStatus(): void {
    // Get online status
    this.streamIdChanges
      .pipe(
        switchMap(streamId => this.streamService.getStream(streamId)),
        switchMap(stream => this.streamService.getInputOnlineStatus(stream)),
        takeUntil(this.alive)
      )
      .subscribe(onlineStatus => {
        this.online = onlineStatus === 'online';
      });
    // Recreation Preview
    this.streamIdChanges
      .pipe(
        switchMap(streamId => this.streamService.getStream(streamId)),
        switchMap(stream => this.streamService.getOnlineStatus(stream)),
        distinctUntilChanged(),
        takeUntil(this.alive)
      )
      .subscribe(onlineStatus => {
        if (onlineStatus) {
          if (!this.preview) {
            this.setupPreview();
          }
        } else {
          this.destroyPreview();
        }
      });
  }

  setupPreview(): void {
    this.previewAlive.next();
    this.streamIdChanges
      .pipe(
        switchMap(streamId => (streamId ? this.streamService.getStream(streamId) : of(null)) ),
        switchMap(stream => this.deckService.getDeck(stream?.deckId).pipe(map(deck => ([stream, deck])))),
        map(([stream, deck]) => ({address: deck?.address, port: deck?.port, channel: stream?.deckChannel, ssl: deck?.ssl})),
        distinctUntilChanged(isEqual),
        takeUntil(this.previewAlive)
      )
      .subscribe(({address, port, channel, ssl}) => {
        this.ngZone.runOutsideAngular(() => {
          this.destroyPreview();
          if (address) {
            this.preview = new CinedeckPreview({
              videoTagNode: this.webPreviewElement.nativeElement,
              debug: false,
              address: address,
              channel: channel,
              port: port,
              ssl: ssl,
              audio: this.volume?.value > 0,
              audioPair: this.audioPair
            });
            this.preview.addTimecodeLisener((data) => {
              if (data && data.timecodes) {
                this.timecodeChange.emit(data.timecodes);
              }
              if (data && data.audioLevels && data.audioLevels.peaks && data.audioLevels.rms) {
                const audioLevels = [];
                for (let index = 0; index < data.audioLevels.peaks.length; index++) {
                  audioLevels.push({peak: data.audioLevels.peaks[index], rm: data.audioLevels.rms[index]})
                }
                this.audioLevels.emit(audioLevels);
              }
            });
          }
        });

        setTimeout(() => this.zoomFit(), 100);
      });
  }

  destroyPreview(): void {
    if (this.preview) {
      this.preview?.destroy();
      while (this.webPreviewElement.nativeElement.firstChild) {
        this.webPreviewElement.nativeElement.removeChild(this.webPreviewElement.nativeElement.lastChild);
      }
      this.preview = null;
    }
  }

  updateVolume(): void {
    if (this.preview) {
      this.preview.setAudio(this.volume?.value > 0);
    }
  }

  private getAspectRatioValue(aspect: string | null | undefined): number {
    switch (aspect) {
      case '1': return 1;
      case '0.9091': return 10/11;
      case '1.109': return 59/54;
      case '4:3': return 4/3;
      case '1.2121': return 40/33;
      case '1.4587': return 118/81;
      case '1.5': return 15/10;
      case '2': return 2;
      case '16:9': return 16/9;
      case '2.35': return 2.35;
      case 'auto':
      default:
        return -1;
    }
  }

  checkForCanvasSizeChange(): void {
    const canvas = this.preview?.canvas;
    if (!canvas) {
      return;
    }
    const frame = {width: +canvas.getAttribute('width'), height: +canvas.getAttribute('height')};
    // Update canvas style
    if (this.frameSize.width !== frame.width || this.frameSize.height !== frame.height) {
      this.frameSize.width = frame.width;
      this.frameSize.height = frame.height;
      this.zoomFit();
    }
  }

  zoomFit(): void {
    const canvas = this.preview?.canvas;
    if (!canvas) {
      return;
    }
    canvas.style.display = 'block';
    canvas.style.margin = 'auto';
    canvas.style.width = 'auto';
    canvas.style.height = 'auto';
    canvas.style.minWidth = 'auto';
    canvas.style.minHeight = 'auto';
    canvas.style.maxWidth = '100%';
    canvas.style.maxHeight = '100%';
    let widthRation = this.frameSize.width
      ? canvas.offsetParent?.clientWidth / this.frameSize.width
      : 0;
    let heightRation = this.frameSize.height
      ? canvas.offsetParent?.clientHeight / this.frameSize.height
      : 0;
    if (widthRation > 1 || heightRation > 1) {
      if (widthRation < heightRation) {
        canvas.style.width = '100%';
      } else {
        canvas.style.height = '100%';
      }
    }
    // Aspect ration
    const aspectRationValue = this.getAspectRatioValue(this.aspectRatio);
    if (this.aspectRatio && aspectRationValue > 0) {
      if (widthRation > 1 || heightRation > 1) {
        if (widthRation < heightRation) {
          const scaleY = (this.frameSize.width / aspectRationValue) / this.frameSize.height;
          canvas.style.transform = `scale(1, ${scaleY})`;
        } else {
          const scaleX = (this.frameSize.height * aspectRationValue) / this.frameSize.width;
          canvas.style.transform = `scale(${scaleX}, 1)`;
        }
      }
    } else {
      canvas.style.transform = 'scale(1, 1)';
    }
  }

}
