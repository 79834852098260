import { Injectable } from '@angular/core';
// RxJS
import { ConnectableObservable, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, publishBehavior, switchMap, tap } from 'rxjs/operators';

// Lodash
import { cloneDeep, pullAllWith, isEqual } from 'lodash-es';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { AlertService } from '@modules/elements/services/alert.service';

// Types
import { Channel } from '../types/channel';
import { GlobalState } from '@modules/settings/types/global-state';
import { ChannelsGroup } from '../types/channels-group';
import { ChannelsGrid, ChannelsGridSize } from '../types/channels-grid';
import { ChannelStreamSettings } from '../types/channel-stream-settings';
import { RS422Settings } from '@modules/rs422/types/rs422-settings';
import { Stream } from '@modules/stream/types/stream';


@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  // Private
  private channels: Observable<Channel[]>;
  private channelsValue: Channel[];
  private channelsList: Observable<Channel[]>;
  private channelsListValue: Channel[];
  private selectedChannels: ConnectableObservable<Channel[]>;
  private selectedChannelsValue: Channel[];
  private allChannelsValue: Channel[];

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private alertService: AlertService
  ) {
    // Channels
    this.channels = this.stateService.getState()
      .pipe(
        tap((state: GlobalState) => {
          if (state.channels && state.channelsGrid && state.channels.length < state.channelsGrid.count) {
            const channels = state.channels;
            const createCount = state.channelsGrid.count - channels.length;
            for (let index = 0; index < createCount; index++) {
              channels.push(new Channel());
            }
            this.stateService.updateState({channels});
          }
        }),
        tap((state: GlobalState) => this.allChannelsValue = state.channels),
        map((state: GlobalState) => state.channels.slice(0, state.channelsGrid.count))
      );
    this.channels.subscribe(channels => this.channelsValue = channels);

    // Channels List
    this.channelsList = this.stateService.getState()
      .pipe(
        map(state => state?.channelsList),
        distinctUntilChanged(isEqual)
      );
    this.channelsList.subscribe(channels => this.channelsListValue = channels);

    // Selected Channels
    this.selectedChannels = publishBehavior([])(
      this.stateService.getState()
        .pipe(
          map(state => {
            const selectedChannelsIds = state.selectedChannelsIds || [];
            return ([...state.channels, ...state.channelsList] || []).filter(channel => selectedChannelsIds.includes(channel.id));
          }),
          tap(channels => this.selectedChannelsValue = channels)
        )
    );
    this.selectedChannels.connect();
  }


  /**
   * Channels
   */

  getChannels(): Observable<Channel[]> {
    return this.channels;
  }

  getChannelsSync(): Channel[] {
    return this.channelsValue;
  }

  getAllChannelsSync(): Channel[] {
    return this.allChannelsValue;
  }

  getChannel(id: string): Observable<Channel> {
    return this.getChannels()
      .pipe(
        map(channels => channels.find(channel => channel.id === id))
      );
  }

  updateChannel(channel: Channel): void {
    const channels = this.allChannelsValue;
    const index = channels.findIndex(item => item.id === channel.id);
    if (index !== -1) {
      channels[index] = channel;
      this.updateChannels(channels);
    }
  }

  updateChannels(channels: Channel[]): void {
    this.stateService.updateState({channels});
  }

  getChannelFromAll(id: string): Observable<Channel> {
    return combineLatest([this.getChannels(), this.getChannelsList()])
      .pipe(
        map(([channels, channelsList]) => [...channels, ...channelsList]),
        map(channels => channels.find(channel => channel.id === id))
      )
  }

  /**
   * Channel List
   */

  getChannelsList(): Observable<Channel[]> {
    return this.channelsList;
  }

  getChannelFromChannelList(id: string): Observable<Channel> {
    return this.getChannelsList()
      .pipe(
        map(channels => channels.find(channel => channel.id === id)),
        distinctUntilChanged(isEqual)
      );
  }

  addChannelToChannelsList(channel: Channel): void {
    const channels = this.channelsListValue;
    channels.push(channel);
    this.updateChannelsList(channels);
  }

  removeChannelFromChanneslList(channel: Channel): void {
    const channels = this.channelsListValue;
    const index = channels.findIndex(item => item.id === channel.id);
    if (index !== -1) {
      channels.splice(index, 1);
      this.updateChannelsList(channels);
    }
  }

  updateChannelsList(channels: Channel[]): void {
    this.stateService.updateState({channelsList: channels});
  }

  /**
   * Channel Grid
   */

  getChannelsGrid(): Observable<ChannelsGrid> {
    return this.stateService.getState()
      .pipe(
        map(state => state.channelsGrid),
        map(grid => {
          if (grid && !grid?.size) {
            grid.size = [];
            for (let index = 0; index < grid.count; index++) {
              grid.size.push(new ChannelsGridSize(1, 1));
            }
            this.setChannelsGrid(grid);
          }
          return grid;
        })
      )
  }

  setChannelsGrid(channelsGrid: ChannelsGrid): void {
    this.orderSelectedChannelFirst();
    this.stateService.updateState({channelsGrid});
  }

  /**
   * Channels Selection
   */

  getSelectedChannels(): Observable<Channel[]> {
    return this.selectedChannels;
  }

  selectChannels(channels: Channel[], addToSelected = false): void {
    this.stateService.updateState({
      selectedChannelsIds: [
        ...channels.map(channel => channel.id),
        ...(addToSelected ? this.selectedChannelsValue : []).map(channel => channel.id)
      ].filter((value, index, array) => array.indexOf(value) === index)
    });
  }

  getChannelsGroups(): Observable<ChannelsGroup[]> {
    return this.stateService.getState().pipe(map(state => state.channelsGroups));
  }

  orderSelectedChannelFirst(): void {
    if (!this.selectedChannelsValue.length) {
      return;
    }
    let channels = this.allChannelsValue;
    pullAllWith(channels, this.selectedChannelsValue, isEqual);
    const orderedChannels = this.selectedChannelsValue.concat(channels);
    this.updateChannels(orderedChannels);
  }

  /**
   * Channel Fullscreen
   */

  getFullscreenChannel(): Observable<Channel> {
    return this.stateService.getState()
      .pipe(
        map(state => state.fullscreenChannelId),
        distinctUntilChanged(isEqual),
        switchMap(channelId => this.getChannelFromAll(channelId))
      );
  }

  setFullscreenChannel(channel: Channel): void {
    this.stateService.updateState({fullscreenChannelId: channel?.id})
  }

  removeFullscreenChannel(): void {
    this.stateService.updateState({fullscreenChannelId: null})
  }

  /**
   * Stream
   */

  assignStreamToChannel(channel: Channel, streamId: string): Observable<boolean> {
    if (channel.streamId === streamId) {
      return of(false);
    }
    const foundChannel = this.channelsValue.find(ch => ch?.streamId === streamId);
    if (foundChannel) {
      return this.alertService.show(
        'Input is already use on another Channel',
        'Input will be reassigned to another channel but this has no effect on the input\'s state. (eg recording or playback will continue without interuption.)',
        ['Cancel', 'Change'],
        0
      )
        .pipe(
          map(buttonIndex => {
            if (buttonIndex === 1) {
              const channels = this.allChannelsValue;
              const foundChannelIndex = channels.findIndex(ch => ch?.streamId === streamId);
              const currentChannelIndex = channels.findIndex(ch => ch.id === channel.id)
              const foundChannelValue = cloneDeep(channels[foundChannelIndex]);
              const copyChannel = cloneDeep(channel);
              channels[currentChannelIndex] = foundChannelValue;
              channels[foundChannelIndex] = copyChannel;
              this.updateChannels(channels);
            }
            return buttonIndex === 1;
          })
        )
    } else {
      this.updateChannel({ ...channel, streamId  });
      return of(true);
    }
  }

  findChannelFromStream(streamId: string): Observable<Channel> {
    return this.channels
      .pipe(
        map(channels => channels.find(channel => channel.streamId === streamId)),
        distinctUntilChanged(isEqual)
      );
  }

}
