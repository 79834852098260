import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

// RxJS
import { BehaviorSubject, Subject, combineLatest, interval } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Types
import { Stream } from '@modules/stream/types/stream';
import { StreamStatus } from '@modules/stream/types/stream-status';
import { Channel } from '@modules/channel/types/channel';
import { MatMenuTrigger } from '@angular/material/menu';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { Preset } from '@modules/preset/types/preset';
import { DraggableEvent } from '@modules/drag-n-drop/types/draggable-event';
import { Deck } from '@modules/deck/types/deck';
import { StreamInputs } from '@modules/stream/types/stream-inputs';
import { StreamTimecode } from '@modules/stream/types/stream-timecode';

@Component({
  selector: 'app-channels-list-item',
  templateUrl: './channels-list-item.component.html',
  styleUrls: ['./channels-list-item.component.less']
})
export class ChannelsListItemComponent implements OnInit, OnChanges, OnDestroy {

  // ViewChild
  @ViewChild('menuButton', { read: MatMenuTrigger, static: false}) menuTrigger: MatMenuTrigger;
  @ViewChild('timecode', { static: false }) timecodeRef: ElementRef;

  // Inputs
  @Input() channel: Channel;
  @Input() channelIndex: number;

  // Public
  public channelSubject = new BehaviorSubject<Channel>(null);
  public stream: Stream;
  public deck: Deck;
  public preset: Preset;
  public settings: StreamSettings;
  public status: StreamStatus;
  public inputs: StreamInputs;
  public online = false;
  public selected = false;
  public error;
  public dropArea = {
    show: false,
    type: 'stream' // 'stream' or 'preset'
  };
  public popoverClose = new Subject<void>();

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    private channelService: ChannelService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    private deckService: DeckService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.updateChannel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('channel' in changes) {
      this.channelSubject.next(this.channel);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
    this.popoverClose.next();
    this.popoverClose.complete();
  }

  /**
   * Methods
   */

  updateChannel(): void {
    const streamSubject = this.channelSubject.asObservable()
      .pipe(
        distinctUntilChanged(isEqual),
        map(channel => channel?.streamId),
        switchMap(streamId => this.streamService.getStream(streamId)),
      );
    // Stream
    streamSubject
      .pipe(takeUntil(this.alive))
      .subscribe(stream => this.stream = stream);
    // Status
    streamSubject
      .pipe(
        switchMap(stream => this.streamService.getStatus(stream)),
        takeUntil(this.alive)
      )
      .subscribe(status => this.status = status);
    // Online
    streamSubject
      .pipe(
        switchMap(stream => this.streamService.getOnlineStatus(stream)),
        takeUntil(this.alive)
      )
      .subscribe(online => this.online = online);
    // Deck
    streamSubject
      .pipe(
        switchMap(stream => this.deckService.getDeck(stream?.deckId)),
        takeUntil(this.alive)
      )
      .subscribe(deck => this.deck = deck);
    // Settings
    streamSubject
      .pipe(
        switchMap(stream => this.presetService.getPreset(stream?.presetId)),
        takeUntil(this.alive)
      )
      .subscribe(preset => {
        this.preset = preset;
        this.settings = this.preset?.getSetting(this.stream?.id);
        this.validate();
      });
    // Inputs updates
    streamSubject
      .pipe(
        switchMap(stream => this.streamService.inputSettingsUpdated(stream).pipe(startWith(1))),
        switchMap(() => interval(5000).pipe(startWith(1))),
        switchMap(() => this.streamService.getInputs(this.stream)),
        takeUntil(this.alive),
      )
      .subscribe(inputs => this.inputs = inputs);
    // Timecode
    streamSubject
      .pipe(
        switchMap(stream => this.streamService.getTimecode(stream)),
        takeUntil(this.alive)
      )
      .subscribe(timecode => this.setTimecode(timecode));
    // Selected channels
    combineLatest([
      this.channelService.getSelectedChannels(),
      this.channelSubject.asObservable()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([selectedChannels, channel]) => {
        this.selected = !!selectedChannels.find(selectedChannel => selectedChannel?.id === channel?.id);
      });
  }

  validate(): void {
    this.error = null;
    if (!this.preset) {
      this.error = 'Preset Not Found';
    }
  }

  getTitle(value: string|number, key: string): string {
    return this.presetSettingsService.getTitle(value, key);
  }

  setTimecode(timecodes: StreamTimecode): void {
    if (timecodes && this.timecodeRef) {
      const playbackTc = timecodes.playback;
      let timecode;
      // Get Timecode
      if (playbackTc) {
        timecode = playbackTc;
      } else {
        timecode = timecodes[this.settings?.tcSource];
      }
      // Timecode
      if (this.timecodeRef && timecode) {
        this.timecodeRef.nativeElement.innerHTML = timecode?.timecode || '00:00:00:00';
      }
    }
  }

  /**
   * Actions
   */

  openMenu(event: MouseEvent): void {
    this.popoverClose.next();
    event?.preventDefault();
    event?.stopPropagation();
    this.menuTrigger.openMenu();
  }

  openFullscreen(): void {
    this.popoverClose.next();
    this.channelService.setFullscreenChannel(this.channel);
  }

  deleteStream(): void {
    this.popoverClose.next();
    this.channelService.removeChannelFromChanneslList(this.channel);
  }

  selectChannel(event: MouseEvent): void {
    this.channelService.selectChannels([ this.channel ], event.shiftKey);
  }

  /**
   * Drag and drop
   */

  dndEnter(event: DraggableEvent): void {
    this.dropArea.show = true;
    this.dropArea.type = event.dragData.type;
  }

  dndLeave(event: DraggableEvent): void {
    this.dropArea.show = false;
  }

  dndDragOver(event: DraggableEvent): void {
  }

  dndDrop(event: DraggableEvent): void {
    this.dropArea.show = false;
    if (event.dragData.type === 'preset' && this.stream?.id) {
      const preset: Preset = event.dragData.data;
      this.streamService.setPreset(preset.id, this.stream.id);
      this.presetService.selectPreset(preset);
    }
  }

}
