import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { NodeService } from '@modules/node/services/node.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Stream } from '@modules/stream/types/stream';
import { File } from '@modules/file-manager/types/file';
import { SelectionModel } from '@angular/cdk/collections';
import { DeckDrive } from '@modules/deck/types/deck-status';


@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.less']
})
export class FileManagerComponent implements OnInit, OnDestroy {

  // Public
  public stream: Stream;
  public deckId: string;
  public files: File[] = [];
  public selection = new SelectionModel<File>(true, []);
  public currentPath = new BehaviorSubject<string>('');
  public drives: DeckDrive[] = [];
  public selectedDisk: DeckDrive;
  public error: Error;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private fileManagerService: FileManagerService,
    private deckService: DeckService,
    private nodeService: NodeService,
    public dialogRef: MatDialogRef<FileManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      stream: Stream,
      deckId?: string,
      path?: string,
      onlyDir?: boolean,
      onlyFile?: boolean,
      singleSelect?: boolean,
      fileExt?: string[],
    },
  ) {
    this.stream = data.stream;
    this.deckId = data.deckId || this.stream.deckId;
    if (data?.path && data?.path?.length) {
      const defaultPath = this.nodeService.path.win32.dirname(data?.path);
      this.currentPath.next(defaultPath);
    }
    if (data?.singleSelect) {
      this.selection = new SelectionModel(false, []);
    }
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Files
    this.currentPath
      .pipe(
        tap(currentPath => {
          this.error = null;
          if (currentPath.length === 0) {
            this.files = [];
          }
        }),
        filter(currentPath => currentPath.length > 0),
        switchMap(currentPath =>
          this.fileManagerService.getFiles(this.deckId, currentPath)
            .pipe(
              catchError(error => {
                this.error = error;
                return of([]);
              }),
            )
        ),
        map(files => files.sort( (f1, f2) => f1.filename.localeCompare(f2.filename) )),
        map(files => files.sort( (f1, f2) => (f1.isDir === f2.isDir)? 0 : f1.isDir? -1 : 1 )),
        tap(files => this.selection.clear()),
        map(files => this.data.onlyDir ? files.filter(file => file.isDir) : files),
        map(files => this.data.fileExt?.length ? files.filter(file => file.isDir || this.data.fileExt.includes(file.filename.split('.').pop().toLowerCase())) : files),
        takeUntil(this.alive)
      )
      .subscribe(files => this.files = files);

    // Get Disks 
    const deck = this.deckService.getDeckSync(this.deckId);
    this.deckService.getDrives(deck)
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(drives => this.drives = drives);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }

  openFile(file: File): void {
  }

  selectDisk(disk: DeckDrive): void {
    this.selectedDisk = disk;
  }

  changeDisk(disk: DeckDrive): void {
    this.selectedDisk = null;
    const nextPath = this.nodeService.path.win32.join(disk.rootPath);
    this.currentPath.next(nextPath);
  }

  selectFile(event: MouseEvent, file: File): void {
    if (!event.shiftKey) {
      this.selection.clear();
    }
    this.selection.toggle(file);
  }

  changeDirUp(): void {
    const parsePath = this.nodeService.path.win32.parse(this.currentPath.value);
    let nextPath = this.nodeService.path.win32.join(this.currentPath.value, '..');
    if (nextPath === '.') {
      nextPath = '';
    }
    if (parsePath && parsePath.root === parsePath.dir && parsePath.base === '' && parsePath.name === '' && parsePath.ext === '') {
      nextPath = '';
    }
    this.selection.clear();
    this.currentPath.next(nextPath);
  }

  changeDir(file?: File): void {
    if (file.isDir) {
      const nextPath = this.nodeService.path.win32.join(this.currentPath.value, file.filename);
      this.currentPath.next(nextPath);
    }
  }

  select(): void {
    let value = this.selection?.selected?.length
      ? this.selection.selected.map(file => this.nodeService.path.win32.join(this.currentPath.value, file.filename))
      : [this.nodeService.path.win32.join(this.selectedDisk?.rootPath || this.currentPath.value)];

    this.dialogRef.close({event: 'select', value});
  }

}
