import {
  AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnChanges, Output, TemplateRef, SimpleChanges, NgZone
} from '@angular/core';

// RxJS
import { Subject, fromEvent, Observable, BehaviorSubject, merge, EMPTY, ReplaySubject } from 'rxjs';
import { takeUntil, filter, tap, switchMap, delay } from 'rxjs/operators';

// Services
import { PopoverService } from '../services/popover.service';

// Types
import { PopoverPlacement } from '../types/placement';

@Directive({
  selector: '[popover]',
  exportAs: 'popover',
})
export class PopoverDirective implements AfterViewInit, OnChanges, OnDestroy {

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private visibleStatus = new BehaviorSubject<boolean>(false);
  private showPopover = new Subject<MouseEvent>();
  private customTrigger = new ReplaySubject<Observable<MouseEvent|void>>(1);
  private mouseEntered = false;

  // Inputs
  @Input() popoverTrackMouse = false;
  @Input() popoverContent: TemplateRef<void>;
  @Input() popoverPlacement: PopoverPlacement;
  @Input() popoverDisabled = false;
  @Input() popoverTrigger: 'click' | 'hover';
  @Input() popoverCustomTrigger: Observable<MouseEvent>;
  @Input() popoverInnerShadow = false;
  @Input() popoverAllowedOutsideSelectorsClick: string;
  @Input() popoverDelay = 0;
  @Input() popoverArrow = true;
  @Input() popoverOffsetX = 0;
  @Input() popoverOffsetY = 0;
  @Input() popoverShowUntil: Observable<void>;

  // Outputs
  @Output() readonly popoverVisibleChange = new EventEmitter<boolean>();

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef,
    private popoverService: PopoverService,
    private ngZone: NgZone
  ) {
    this.visibleStatus
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(status => this.popoverVisibleChange.emit(status));

    this.showPopover
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(event => this.popoverService.create(
        this.elementRef,
        {
          trackMouse: this.popoverTrackMouse,
          content: this.popoverContent,
          placement: this.popoverPlacement,
          trigger: this.popoverTrigger,
          innerShadow: this.popoverInnerShadow,
          allowedOutsideSelectors: this.popoverAllowedOutsideSelectorsClick,
          arrow: this.popoverArrow,
          popoverOffsetX: this.popoverOffsetX,
          popoverOffsetY: this.popoverOffsetY,
          showUntil: this.popoverShowUntil ? merge(this.componentNotDestroyed, this.popoverShowUntil) : this.componentNotDestroyed,
          visibleChange: this.visibleStatus
        },
        event
      ));
  }

  /**
   * Component lifecycle
   */

  ngAfterViewInit(): void {
    let trigger: Observable<MouseEvent> = EMPTY;

    if (this.popoverTrigger === 'click') {
      trigger = fromEvent(this.elementRef.nativeElement, 'click');
    } else if (this.popoverTrigger === 'hover') {
      trigger = fromEvent(this.elementRef.nativeElement, 'mouseenter');
      trigger = trigger.pipe(
        tap(() => this.mouseEntered = true),
        delay(this.popoverDelay),
        filter(() => this.mouseEntered)
      );

      /*
      ** Close delayed popover. It's not even instantiated yet
      */
      this.ngZone.runOutsideAngular(() => {
        fromEvent(this.elementRef.nativeElement, 'mouseleave')
          .pipe(
            takeUntil(this.componentNotDestroyed),
            filter(() => this.mouseEntered)
          )
          .subscribe(() => this.mouseEntered = false);
      });
    }

    this.ngZone.runOutsideAngular(() => {
      merge(
        trigger.pipe(tap(event => event.preventDefault())),
        this.customTrigger.pipe(switchMap(customTrigger => customTrigger || EMPTY))
      )
        .pipe(
          filter(() => !this.visibleStatus.value && !this.popoverDisabled),
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe(event => this.ngZone.run(() => this.showPopover.next(event || null)));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popoverCustomTrigger) {
      this.customTrigger.next(this.popoverCustomTrigger);
    }
  }

  ngOnDestroy(): void {
    this.customTrigger.next(null);
    this.customTrigger.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }
}
