<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Configure Paths and Filenames</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <div class="preset-name-settings-content" *ngIf="stream && preset" cdkDropListGroup>

    <!-- Encoders -->
    <div class="encoder-name-settings">

      <ng-container *ngFor="let nameTemplate of ((preset.nameTemplates.sameTemplatesForAllEncoders && preset.nameTemplates?.nameTemplates?.length) ? [preset.nameTemplates?.nameTemplates[0]] : preset.nameTemplates?.nameTemplates); let encoderIndex = index">

        <!-- Path -->
        <div class="settings-section">
          <div class="settings-header">{{ encoderIndex === 0 ? 'Primary' : 'Proxy ' + encoderIndex }} Paths</div>
          <div class="settings-content">
            <app-preset-name-template class="name-template" title="Primary" [isPathTemplate]="true" [nameTemplate]="nameTemplate.primaryPath" (nameTemplateChange)="updateNameTemplate($event, 'primaryPath', encoderIndex)" [showInput]="true" [draggable]="true"></app-preset-name-template>
            <app-preset-name-template class="name-template" title="Secondary" [isPathTemplate]="true" [nameTemplate]="nameTemplate.secondaryPath" (nameTemplateChange)="updateNameTemplate($event, 'secondaryPath', encoderIndex)" [showInput]="true" [draggable]="true" [disabled]="preset.nameTemplates.secondaryPathSame[encoderIndex]"></app-preset-name-template>
            <mat-checkbox class="checkbox" [(ngModel)]="preset.nameTemplates.secondaryPathSame[encoderIndex]" (ngModelChange)="updateSecondaryPathSame(encoderIndex)" disableRipple>Secondary Path Same as Primary Path</mat-checkbox>
          </div>
        </div>
  
        <!-- File name -->
        <div class="settings-section">
          <div class="settings-header">{{ encoderIndex === 0 ? 'Primary' : 'Proxy ' + encoderIndex }} File Name</div>
          <div class="settings-content">
            <app-preset-name-template class="name-template" [nameTemplate]="nameTemplate.fileName" (nameTemplateChange)="updateNameTemplate($event, 'fileName', encoderIndex)" [defaultNameSeparator]="defaultNameSeparator.value" [showInput]="true" [draggable]="true"></app-preset-name-template>
          </div>
        </div>
  
        <!-- Tape ID -->
        <div class="settings-section">
          <div class="settings-header">{{ encoderIndex === 0 ? 'Primary' : 'Proxy ' + encoderIndex }} Tape ID</div>
          <div class="settings-content">
            <app-preset-name-template class="name-template" [nameTemplate]="nameTemplate.tapeId" (nameTemplateChange)="updateNameTemplate($event, 'tapeId', encoderIndex)" [defaultNameSeparator]="defaultNameSeparator.value" [showInput]="true" [draggable]="true"></app-preset-name-template>
          </div>
        </div>
  
      </ng-container>

      <!-- Still Image Setup -->
      <div class="settings-section">
        <div class="settings-header">Still Image Setup</div>
        <div class="settings-content">
          <app-preset-name-template class="name-template" [nameTemplate]="preset.nameTemplates.imageCaptureFilenameTemplate" (nameTemplateChange)="updateImageNameTemplate($event)" [defaultNameSeparator]="defaultNameSeparator.value" [showSample]="false" [showInput]="true" [draggable]="true"></app-preset-name-template>
          <div class="image-format">
            <div class="label">Still Image Format</div>
            <app-select class="select" [options]="[{value: 'bmp', title: 'BMP'}]" [selected]="{value: 'bmp', title: 'BMP'}"></app-select>
          </div>
        </div>
      </div>

    </div>

    <!-- Options -->
    <div class="name-options">

      <!-- Wildcards -->
      <div class="settings-section">
        <div class="settings-header">Wildcards</div>
        <div class="settings-content">
          <div class="name-list name-list-wildcard" *ngFor="let list of wildcards" cdkDropList [cdkDropListEnterPredicate]="noDropPredicate">
            <div class="wildcard-item" *ngFor="let wildcard of list" [style.backgroundColor]="wildcard.color" cdkDrag [cdkDragData]="wildcard">{{ wildcard.title }}</div>
          </div>
        </div>
      </div>

      <!-- Separators -->
      <div class="separators-section">
        <!-- Name -->
        <div class="settings-section">
          <div class="settings-header">Naming Separators</div>
          <div class="settings-content">
            <div class="name-list" cdkDropList [cdkDropListEnterPredicate]="noDropPredicate">
              <div class="separator-item" *ngFor="let item of nameSeparators" [style.backgroundColor]="item.color" cdkDrag [cdkDragData]="item">{{ item.title }}</div>
            </div>
            <div class="name-format">
              <div class="label">Default Naming Separator</div>
              <app-select class="select" [options]="defaultNameSeparatorOptions" [selected]="defaultNameSeparator" (selectedChange)="changeDefaultNameSeparator($event)"></app-select>
            </div>
          </div>
        </div>
        <!-- Path -->
        <div class="settings-section">
          <div class="settings-header">Path Separator</div>
          <div class="settings-content">
            <div class="name-list" cdkDropList [cdkDropListEnterPredicate]="noDropPredicate">
              <div class="separator-item" *ngFor="let item of pathSeparators" [style.backgroundColor]="item.color" cdkDrag [cdkDragData]="item">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Checkbox -->
      <mat-checkbox class="checkbox checkbox-wildcard" [checked]="removeNearSeparator" (change)="changeRemoveNearSeparator($event.checked)" disableRipple>Remove redundant separators</mat-checkbox>

    </div>

  </div>
</div>
