<div class="user-tag-list-content">

  <!-- Header -->
  <div class="list-header shadow">
    <div class="title">Global Lists</div>
    <div class="actions">
      <mat-icon
        svgIcon="settings-sliders"
        class="action-icon"
        [class.disabled]="!allowed || locked"
        popover
        [popoverContent]="settingsPopover"
        popoverPlacement="bottom"
        popoverTrigger="click"
        [popoverOffsetY]="-5"
        popoverAllowedOutsideSelectorsClick=".mat-option"
        [popoverShowUntil]="popoverClose"
      ></mat-icon>
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" [class.disabled]="!lists || !allowed" (click)="lock()"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon" [class.disabled]="!lists"></mat-icon>
    </div>
  </div>

  <!-- List -->
  <div class="lists" [class.disabled]="!allowed || locked" *ngIf="lists">
    <app-tag-list
      *ngFor="let list of lists | slice: 0 : layout?.globalListCount"
      [showSettings]="true"
      [list]="list"
      [selected]="list?.selected"
      (select)="selectTag($event.list, $event.item)"
      (create)="createTag($event.list, $event.item)"
      (update)="updateTag($event.list, $event.item)"
      (delete)="deleteTag($event.list, $event.item)"
      (updateList)="updateList($event)"
      (export)="exportList($event)"
      (import)="importList($event)"
    ></app-tag-list>
  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!lists">
    <div class="text">Select a <b>Preset</b> first to start working with <b>Lists</b></div>
  </div>

</div>

<ng-template #settingsPopover>
  <div class="settings-popover">
    <div class="title">Global lists count</div>
    <app-select class="select" [options]="userListCountOptions" [selected]="layout?.globalListCount" (selectedChange)="updateUserListCount($event)" placeholder="None"></app-select>
  </div>
</ng-template>
