import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

// Types
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.less']
})
export class ColorSelectComponent implements OnInit, OnChanges {

  // Inputs
  @Input() color: string;
  @Input() argbConvert: boolean = false;

  // Outputs
  @Output() colorChange = new EventEmitter<string>();

  // Public
  public currentColor: string;


  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('color' in changes || 'argbConvert' in changes) {
      this.currentColor = this.argbConvert ? this.argbToRgba(this.color) : this.color;
    }
  }

  /**
   * Methods
   */

  argbToRgba(color: string): string {
    return color.replace(/#(..)(......)/, '#$2$1');
  }

  rgbaToArgb(color: string): string {
    return color.replace(/#(......)/, '#ff$1');
  }

  /**
   * Actions
   */

  colorChanged(event: ColorEvent): void {
    this.currentColor = event.color.hex;
    this.color = this.argbConvert ? this.rgbaToArgb(this.currentColor) : this.currentColor;
    this.colorChange.emit(this.color);
  }

}
