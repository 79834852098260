<div class="right-panel-content">

  <!-- Inputs -->
  <app-stream-list *ngIf="layout.inputs" class="stream-list"></app-stream-list>

  <!-- Preset Name -->
  <app-preset-name *ngIf="layout.templates" class="preset-name"></app-preset-name>

  <!-- Record Settings -->
  <app-preset-record-settings *ngIf="layout.recordSettings" class="preset-record-settings"></app-preset-record-settings>

</div>


<!-- Playlist -->
<div class="playlist-content" *ngIf="layout.playlist && showPlaylist">
  <app-playlist-channel class="stream-list"></app-playlist-channel>
</div>
