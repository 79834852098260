import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// Components
import { MatSelect } from '@angular/material/select';

// Types
import { Option } from '../../types/option';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less']
})
export class SelectComponent<T> implements OnInit, AfterViewInit {

  // Inputs
  @Input() options: Option<T>[] = [];
  @Input() selected: Option<T> | T | Option<T>[] | T[];
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() highlighted = false;
  @Input() disabledOptions: Option<T>[];
  @Input() multiple = false;

  // Outputs
  @Output() selectedChange = new EventEmitter<Option<T> | T | Option<T>[] | T[]>();

  // ViewChild
  @ViewChild(MatSelect) select: MatSelect;

  // Private
  private isOpen = false;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.select.overlayDir.positions = [
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
      }
    ];
    this.select.overlayDir.offsetX = 0;
  }

  /**
   * Methods
   */

  compareOptions(option1: Option<T>, option2: Option<T> | T): boolean {
    return option1 && option2 &&
      (option2 instanceof Object && 'value' in option2 && 'title' in option2)
      ? (isEqual(option1.value, option2.value) && option1.title === option2.title)
      : (isEqual(option1.value, option2));
  }

  isDisabled(option: Option<T> | T): boolean {
    return !!this.disabledOptions?.find(item => this.compareOptions(item, option));
  }

  updateSelected(value: Option<T> | T): void {
    this.selected = value;
    if (!this.multiple) {
      this.selectedChange.emit(value);
    }
  }

  openedChange(value: boolean): void {
    if (!value && this.isOpen && this.multiple) {
      this.selected = (this.selected as Array<Option<T>>).map(item => item.value);
      this.selectedChange.emit(this.selected);
    }
    this.isOpen = value;
  }

}
