import { Component } from '@angular/core';

// Services
import { SettingsService } from './modules/settings/services/settings.service';
import { NodeService } from '@modules/node/services/node.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Cinedeck';

  /**
   * Constructor
   */

  constructor(
    private settingsService: SettingsService,
    private nodeService: NodeService,
  ) {
    this.settingsService.setup();
    this.nodeService.setup();
  }

}
