export class Volume {
  value: number;
  muteLeft: boolean;
  muteRight: boolean;

  constructor() {
    this.value = 100;
    this.muteLeft = false;
    this.muteRight = false;
  }
}
