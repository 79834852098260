// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Components
import { DraggableElementComponent } from './components/draggable-element/draggable-element.component';

// Modules
import { OverlayModule } from '@angular/cdk/overlay';

// Directives
import { DraggableDirective } from './directives/draggable.directive';
import { DroppableDirective } from './directives/droppable.directive';

// Services
import { DragNDropService } from './services/drag-n-drop.service';

@NgModule({
  exports: [
    DraggableDirective,
    DroppableDirective,
  ],
  declarations: [
    DraggableDirective,
    DroppableDirective,
    DraggableElementComponent,
  ],
  imports: [
    AngularCommonModule,
    OverlayModule,
  ],
  providers: [
    DragNDropService,
  ],
  entryComponents: [
    DraggableElementComponent,
  ]
})
export class DragNDropModule {}
