import { Injectable, NgZone } from '@angular/core';

// Lodash
import { has, isEqual, sortBy } from 'lodash-es';

// RxJS
import { Observable, combineLatest, of, race, timer, Subject, ConnectableObservable, BehaviorSubject } from 'rxjs';
import { catchError, concatMap, delay, distinctUntilChanged, exhaustMap, filter, map, publishBehavior, startWith, switchMap, take, tap } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { ElectronService } from 'ngx-electron';
import { NotificationService } from '@modules/elements/services/notification.service';
import { NodeService } from '@modules/node/services/node.service';

// Pipe
import { DifferencePipe } from '@modules/elements/pipes/difference.pipe';
import { FilenameNormalizePipe } from '@modules/elements/pipes/filename-normalize.pipe';

// Types
import { Preset } from '@modules/preset/types/preset';
import { UserList, UserListItem } from '@modules/preset/types/user-list';
import { XmlTag } from '@modules/preset/types/xml-tag';
import { GlobalState } from '@modules/settings/types/global-state';
import { StreamSettings, StreamSettingsNameTemplates } from '@modules/stream/types/stream-settings';

// Decorators
import { warmUpObservable } from '@decorators';


interface FileEvent {
  event: 'add' | 'change' | 'unlink' | 'ready' | 'error';
  file?: { path: string, root: string };
  error?: Error;
  initialScan: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PresetService {

  // Private
  private presets: ConnectableObservable<Preset[]>;
  private presetsSubject = new BehaviorSubject<Preset[]>([]);
  private presetsList: {
    status: 'loading' | 'ready' | 'error',
    presets: { [path: string]: Preset }
  };
  private presetsFolder: string;
  private selectedPreset: Preset;
  private selectedPresetId: Observable<string>;
  private retryPresetsFolder = new Subject<void>();
  private presetUpdatesQueue = new Subject<Observable<void>>();
  private globalList: UserList[];
  private needUpdatePresetNameTemplate = new Subject<Preset>();
  private renamePresetId: string;
  private restartWatcher = new BehaviorSubject<void>(null);

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private electronService: ElectronService,
    private ngZone: NgZone,
    private differencePipe: DifferencePipe,
    private filenameNormalizePipe: FilenameNormalizePipe,
    private notificationService: NotificationService,
    private nodeService: NodeService,
  ) {
    // Load Presets
    if (this.nodeService.isElectron) {
      this.loadPresetFromDisk();
    } else {
      this.loadPresetFromLocalStorage();
    }

    // Selected Preset ID
    this.selectedPresetId = this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.selectedPresetId)
      );
    this.getCurrentPreset().subscribe(preset => this.selectedPreset = preset);

    this.presetUpdatesQueue
      .pipe(concatMap(update => update))
      .subscribe(() => {});

    this.stateService.getState()
      .pipe(
        map(state => state.globalList)
      )
      .subscribe(globalList => this.globalList = globalList);
  }

  /**
   * Load Presets
   */

   private loadPresetFromDisk(): void {
    this.presets = publishBehavior<Preset[]>([])(
      this.stateService.getState()
        .pipe(
          map((state: GlobalState) => state.presetsFolder),
          distinctUntilChanged(),
          map(presetsFolder => presetsFolder.replace('{{home}}', this.nodeService.os.homedir())),
          tap(presetsFolder => {
            console.log(`Preset folder set to: ${presetsFolder}`);
            this.presetsFolder = presetsFolder;
            this.presetsList = { status: 'loading', presets: {} };
          }),
          switchMap(presetsFolder => this.restartWatcher.pipe(map(() => presetsFolder))),
          switchMap(presetsFolder => this.watchPresetsFolder(presetsFolder)),
          tap(fileEvent => {
            console.log('New file event: ', fileEvent);
          }),
          concatMap(fileEvent => this.processFileEvent(fileEvent)),
          map(({ fileEvent, preset }) => {
            this.presetsList.status = fileEvent.event === 'error' ? 'error' : fileEvent.initialScan ? 'loading' : 'ready';
            console.log(preset);
            if ((fileEvent.event === 'add' || fileEvent.event === 'change') && preset) {
              if (Object.entries(this.presetsList.presets).some(
                ([ presetPath, presetValue ]) => presetPath !== fileEvent.file.path && preset.id === presetValue.id
              )) {
                // Found preset duplicate, generate new id
                preset.id = Preset.generateId();
                this.presetsList.presets[fileEvent.file.path] = preset;
                this.updatePreset(preset);
              } else {
                this.presetsList.presets[fileEvent.file.path] = preset;
              }
              this.presetsList.presets[fileEvent.file.path].name = this.nodeService.path.basename(fileEvent.file.path, '.json');
              if (this.renamePresetId === preset.id) {
                this.renamePresetId = null;
                this.setNeedUpdatePresetNameTemplate(this.presetsList.presets[fileEvent.file.path]);
              }
            } else if (fileEvent.event === 'unlink') {
              delete this.presetsList.presets[fileEvent.file.path];
            }

            console.log('Presets List: ', this.presetsList);

            const presets = Object.values(this.presetsList.presets);

            if (this.presetsList.status === 'ready' && !presets.length) {
              console.log('No presets found in folder. Adding default...');
              this.addPreset(Preset.emptyPreset('Default'), true);
            }

            const sortedPresets = sortBy(presets, 'name');
            this.presetsSubject.next(sortedPresets);
            return sortedPresets;
          })
        )
    );
    this.presets.connect();
  }

  private loadPresetFromLocalStorage(): void {
    if (!this.presetsList) {
      this.presetsList = { status: 'loading', presets: {} };
    }
    const savedPresets = this.getSavedPresets();
    for (const presetId in savedPresets) {
      const parsedData = savedPresets[presetId];
      const preset = new Preset(parsedData);
      this.presetsList.presets[presetId] = preset;
    }
    this.presetsList.status = 'ready';

    // Check for Default Preset
    const presets = Object.values(this.presetsList.presets);
    const sortedPresets = sortBy(presets, 'name');
    this.presetsSubject.next(sortedPresets);

    // Add Default Preset if needed
    if (this.presetsList.status === 'ready' && !presets.length) {
      console.log('No presets found in folder. Adding default...');
      this.addPreset(Preset.emptyPreset('Default'), false);
    }
  }

  private savePresetToLocalStorage(): void {
    // Save to LocalStorage
    this.savePresets(this.presetsList.presets);
    // Notify
    const presets = Object.values(this.presetsList.presets);
    const sortedPresets = sortBy(presets, 'name');
    this.presetsSubject.next(sortedPresets);
  }

  private getSavedPresets(): { [id: string]: Preset } {
    try {
      return JSON.parse(localStorage.getItem('app.presets')) || {};
    } catch (e) {
      console.error('Can not parse JSON from localStore. ', e);
      return {};
    }
  }

  private savePresets(presets: { [id: string]: Preset }): void {
    localStorage.setItem('app.presets', JSON.stringify(presets));
  }

  /**
   * Presets
   */

  getPresets(): Observable<Preset[]> {
    this.retryPresetsFolder.next();
    return this.presetsSubject
      .pipe(
        distinctUntilChanged(isEqual)
      );
  }

  getPreset(id: string): Observable<Preset> {
    return this.presetsSubject
      .pipe(
        map(presets => presets.find(item => item.id === id)),
      );
  }

  getPresetSync(id: string): Preset {
    return Object.values(this.presetsList.presets || {}).find(preset => preset.id === id);
  }

  addPreset(preset: Preset, restartWatcher: boolean = false): void {
    if (this.nodeService.isElectron) {
      // Electron app
      this.nodeService.fs.mkdir(this.presetsFolder, { recursive: true }, (error: Error) => {
        if (error) {
          console.error(`Can't create preset folder '${this.presetsFolder}'`, error);
        }
        console.log(`Preset folder '${this.presetsFolder}' created`);
        const presetPath = this.nodeService.path.join(this.presetsFolder, `${preset.name}.json`);
        console.log(`Saving preset to file: ${presetPath}; Content: `, preset);
        this.nodeService.fs.writeFile(presetPath, JSON.stringify(preset), (writeError: Error) => {
          if (writeError) {
            console.error(`Preset file '${presetPath}' not saved: `, writeError);
          }
          console.log(`Preset file '${presetPath}' saved`);
          if (restartWatcher) {
            this.restartWatcher.next();
          }
        });
      });
    } else {
      // Web
      this.presetsList.presets[preset.id] = preset;
      this.savePresetToLocalStorage();
    }
  }

  deletePreset(preset: Preset): void {
    const presetPath = this.findPresetKey(preset.id);
    if (this.nodeService.isElectron) {
      // Electron
      if (presetPath) {
        console.log('Removing preset: ', presetPath);
        this.nodeService.fs.unlink(presetPath, (error: Error) => {
          if (error) {
            console.error(`Preset file '${presetPath}' not removed: `, error);
          }
          console.log(`Preset file '${presetPath}' removed`);
        });
      }
    } else {
      // Web
      if (this.presetsList.presets[preset?.id]) {
        delete this.presetsList.presets[preset?.id];
        this.savePresetToLocalStorage();
      }
    }
  }

  updatePreset(preset: Preset): void {
    const presetPath = this.findPresetKey(preset.id);
    this.presetsList.presets[presetPath] = preset;
    if (this.nodeService.isElectron) {
      // Electron
      const presets = Object.values(this.presetsList.presets);
      const sortedPresets = sortBy(presets, 'name');
      this.presetsSubject.next(sortedPresets);
      if (presetPath) {
        this.presetUpdatesQueue.next(
          new Observable(subscriber => {
            console.log('Updating preset: ', presetPath);
            this.nodeService.fs.writeFile(presetPath, JSON.stringify(preset), (error: Error) => {
              if (error) {
                console.error(`Preset file '${presetPath}' not updated: `, error);
              }
              console.log(`Preset file '${presetPath}' updated`);
              subscriber.next();
              subscriber.complete();
            });
          })
        );
      }
    } else {
      // Web
      this.savePresetToLocalStorage();
    }
  }

  showPresetInFinder(preset: Preset): void {
    if (!preset?.id) {
      return;
    }
    const presetPath = this.findPresetKey(preset.id);
    this.electronService.shell.showItemInFolder(presetPath);
  }

  renamePreset(preset: Preset, name: string): void {
    if (this.nodeService.isElectron) {
      // Electron
      const presetPath = this.findPresetKey(preset.id);
      if (presetPath) {
        this.renamePresetId = preset.id;
        this.nodeService.fs.rename(presetPath, this.nodeService.path.join(this.nodeService.path.dirname(presetPath), name + '.json'), () => {});
      }
    } else {
      // Web
      preset.name = name;
      this.updatePreset(preset);
    }
  }

  getCurrentPreset(): Observable<Preset> {
    return combineLatest([this.getPresets(), this.selectedPresetId])
      .pipe(
        map(([presets, selectedPresetId]) => presets.find(item => item.id === selectedPresetId) ?? presets[0]),
      );
  }

  selectPreset(preset: Preset): void {
    this.stateService.updateState({selectedPresetId: preset.id});
  }

  exportPreset(preset: Preset, filePath: string): void {
    if (!this.nodeService.isElectron) {
      console.error('`Preset file export not posible from web app')
      return;
    }
    const exportPath = filePath.slice(-5) === '.json' ? filePath : filePath + '.json';
    this.nodeService.fs.writeFile(
      exportPath,
      JSON.stringify({
        ...preset,
        id: Preset.generateId(),
        name: this.nodeService.path.basename(exportPath, '.json')
      }),
      (writeError: Error) => {
        if (writeError) {
          console.error(`Preset file '${exportPath}' not saved: `, writeError);
        }
        console.log(`Preset file '${exportPath}' saved`);
      }
    );
  }

  savePreset(preset: Preset): void {
    if (this.nodeService.isElectron) {
      const currentWindow = this.electronService.remote.getCurrentWindow();
      const presetPath = this.getPresetPath(preset).slice(0, -5);
      this.electronService.remote.dialog
        .showSaveDialog(currentWindow, { defaultPath: presetPath, showsTagField: false, properties: ['createDirectory']})
        .then(data => {
          if (!data.filePath) {
            return;
          }
          const folder = data.filePath;
          this.exportPreset(preset, folder);
        })
        .catch(() => {});
    } else {
      this.nodeService.downloadJson(preset, preset.name);
    }
  }

  reloadPresets(): void {
    this.restartWatcher.next();
  }

  getPresetPath(preset: Preset): string {
    return this.findPresetKey(preset.id);
  }

  setNeedUpdatePresetNameTemplate(preset: Preset): void {
    this.needUpdatePresetNameTemplate.next(preset);
  }

  getNeedUpdatePresetNameTemplate(): Observable<Preset> {
    return this.needUpdatePresetNameTemplate.asObservable();
  }

  needUpdateAllPresetNameTemplate(): void {
    this.getPresets()
      .pipe(
        take(1)
      )
      .subscribe(presets => presets.map(preset => this.setNeedUpdatePresetNameTemplate(preset)));
  }

  private findPresetKey(presetId: string): string {
    for (const entry of Object.entries(this.presetsList.presets)) {
      if (entry[1].id === presetId) {
        return entry[0];
      }
    }
    return null;
  }

  @warmUpObservable
  private writePresetToFile(presetPath: string, preset: Preset): Observable<void> {
    return new Observable(subscriber => {
      console.log(`Saving preset to file: ${presetPath}; Content: `, preset);
      this.nodeService.fs.writeFile(presetPath, JSON.stringify(preset), (writeError: Error) => {
        if (writeError) {
          console.error(`Preset file '${presetPath}' not saved: `, writeError);
          subscriber.error(writeError);
        } else {
          console.log(`Preset file '${presetPath}' saved`);
          subscriber.next();
          subscriber.complete();
        }
      });
    });
  }

  private watchPresetsFolder(presetsFolder: string): Observable<FileEvent> {
    return new Observable<FileEvent>(subscriber => {
      const watcher = this.nodeService.chokidar.watch(`${presetsFolder}/*.json`, {
        awaitWriteFinish: true
      });

      let initialScan = true;

      const next = (event: 'add' | 'change' | 'unlink' | 'ready', file: string) =>
        this.ngZone.run(() => subscriber.next({ event, file: { path: file, root: presetsFolder }, initialScan }));

      watcher
        .on('add', (file: string) => next('add', file))
        // Workaround for bug https://github.com/paulmillr/chokidar/issues/977
        .on('change', (file: string) => this.nodeService.fs.readdir(this.nodeService.path.dirname(file), (error: Error, files: string[]) => {
          next((files || []).includes(this.nodeService.path.basename(file)) ? 'change' : 'unlink', file);
        }))
        .on('unlink', (file: string) => next('unlink', file))
        .on('ready', () => {
          initialScan = false;
          next('ready', null);
        })
        .on('error', (error: Error) => subscriber.error(error));

      return () => watcher.close().then(() => {}).catch(() => {});
    })
      .pipe(
        catchError((error, caught) => {
          console.log('Error with presets file catchError: ', error);
          return race(this.retryPresetsFolder.pipe(take(1)), timer(5000))
            .pipe(
              exhaustMap(() => caught),
              startWith({ event: 'error', error } as FileEvent)
            );
        })
      );
  }

  private processFileEvent(fileEvent: FileEvent): Observable<{ fileEvent: FileEvent, preset: Preset }> {
    if (fileEvent.event === 'add' || fileEvent.event === 'change') {
      return new Observable<{ fileEvent: FileEvent, preset: Preset }>(subscriber => {
        this.nodeService.fs.readFile(fileEvent.file.path, (error: Error, data: string) => {
          let preset: Preset;

          if (!error) {
            try {
              const parsedData = JSON.parse(data);
              preset = new Preset(parsedData);
            } catch (error) {
              console.error('Error parsing preset file. Ignored. Error: ', error);
            }
          } else {
            console.error('Error opening preset file. Ignored. Error: ', error);
          }

          this.ngZone.run(() => {
            subscriber.next({
              fileEvent,
              preset
            });
            subscriber.complete();
          });
        });
      });
    } else {
      return of({ fileEvent, preset: null as Preset });
    }
  }

  /**
   * Settings
   */

  updateDefaultSettings(presetId: string, settings: StreamSettings): void {
    const presetKey = this.findPresetKey(presetId);
    if (presetKey) {
      this.presetsList.presets[presetKey].defaultSetting = settings;
      this.updatePreset(this.presetsList.presets[presetKey]);
      this.setNeedUpdatePresetNameTemplate(this.presetsList.presets[presetKey]);
    }
  }

  updateStreamSettings(presetId: string, streamId: string, settings: StreamSettings): void {
    const presetKey = this.findPresetKey(presetId);
    if (presetKey && streamId) {
      const defaultSetting = this.presetsList.presets[presetKey].defaultSetting;
      const diffSettings: Partial<StreamSettings> = this.differencePipe.transform(settings, defaultSetting);
      this.updateAudioRoutingData(settings, diffSettings);
      // Virtual fields
      if (!has(settings, 'audioDelaysLocked') && has(this.presetsList.presets[presetKey].settings[streamId], 'audioDelaysLocked')) {
        diffSettings.audioDelaysLocked = this.presetsList.presets[presetKey].settings[streamId].audioDelaysLocked;
      } else if (has(settings, 'audioDelaysLocked')) {
        diffSettings.audioDelaysLocked = settings.audioDelaysLocked;
      }
      if (!has(settings, 'sameDiskAsPreset') && has(this.presetsList.presets[presetKey].settings[streamId], 'sameDiskAsPreset')) {
        diffSettings.sameDiskAsPreset = this.presetsList.presets[presetKey].settings[streamId].sameDiskAsPreset;
      } else if (has(settings, 'sameDiskAsPreset')) {
        diffSettings.sameDiskAsPreset = settings.sameDiskAsPreset;
      }
      this.presetsList.presets[presetKey].settings[streamId] = diffSettings;
      this.presetsList.presets[presetKey].settings[streamId].encodeCount = settings?.encode?.length;
      console.log('[Diff][SETTINGS] ', diffSettings);
      console.log('[Diff][StreamID] ', streamId);
      this.updatePreset(this.presetsList.presets[presetKey]);
    }
  }

  rewriteSettingNameTemplate(settings: StreamSettings, presetId: string): void {
    const preset = this.getPresetSync(presetId);
    if (preset) {
      const encodersCount = settings.encode.length;
      settings.allowSpaces = preset.nameTemplates.allowSpaces;
      settings.sameTemplatesForAllEncoders = preset.nameTemplates.sameTemplatesForAllEncoders;
      settings.channels.forEach(channel =>
        channel.imageCapture.filenameTemplate = this.formatNameTemplateField(preset.nameTemplates.imageCaptureFilenameTemplate, preset)
      );
      settings.nameTemplates = preset.nameTemplates.nameTemplates
        .slice(0, encodersCount)
        .map(template => this.formatNameTemplate(template, preset));
    }
  }

  rewriteNameTemplate(preset: Preset, streamId: string): void {
    const encodersCount = preset.defaultSetting.encode.length;
    preset.defaultSetting.allowSpaces = preset.nameTemplates.allowSpaces;
    preset.defaultSetting.sameTemplatesForAllEncoders = preset.nameTemplates.sameTemplatesForAllEncoders;
    preset.defaultSetting.channels.forEach(channel =>
      channel.imageCapture.filenameTemplate = this.formatNameTemplateField(preset.nameTemplates.imageCaptureFilenameTemplate, preset)
    );
    preset.defaultSetting.nameTemplates = preset.nameTemplates.nameTemplates
      .slice(0, encodersCount)
      .map(template => this.formatNameTemplate(template, preset));

    // Update settings
    if (preset.settings[streamId]) {
      preset.settings[streamId].allowSpaces = preset.defaultSetting.allowSpaces;
      preset.settings[streamId].sameTemplatesForAllEncoders = preset.defaultSetting.sameTemplatesForAllEncoders;
      preset.settings[streamId].nameTemplates = preset.defaultSetting.nameTemplates;
    }
  }

  formatNameTemplate(nameTemplate: StreamSettingsNameTemplates, preset: Preset): StreamSettingsNameTemplates {
    const template = Object.assign({}, nameTemplate);
    template.fileName = this.formatNameTemplateField(template.fileName, preset);
    template.primaryPath = this.formatNameTemplateField(template.primaryPath, preset);
    template.secondaryPath = this.formatNameTemplateField(template.secondaryPath, preset);
    template.tapeId = this.formatNameTemplateField(template.tapeId, preset);
    return template;
  }

  formatNameTemplateField(field: string, preset: Preset): string {
    let formattedField = field.slice();
    formattedField = formattedField.replace('%P', preset.name);
    formattedField = formattedField.replace('%1', preset.userList[0]?.selected?.name ?? '');
    formattedField = formattedField.replace('%2', preset.userList[1]?.selected?.name ?? '');
    formattedField = formattedField.replace('%3', preset.userList[2]?.selected?.name ?? '');
    formattedField = formattedField.replace('%4', preset.userList[3]?.selected?.name ?? '');
    formattedField = formattedField.replace('%5', preset.userList[4]?.selected?.name ?? '');
    formattedField = formattedField.replace('%6', preset.userList[5]?.selected?.name ?? '');
    formattedField = formattedField.replace('%7', preset.userList[6]?.selected?.name ?? '');
    formattedField = formattedField.replace('%8', preset.userList[7]?.selected?.name ?? '');
    formattedField = formattedField.replace('%9', preset.userList[8]?.selected?.name ?? '');
    formattedField = formattedField.replace('%0', preset.userList[9]?.selected?.name ?? '');
    for (const wildcard of this.globalList) {
      formattedField = formattedField.replace(wildcard.value, wildcard.selected?.name ?? '');
    }
    return formattedField;
  }

  updateAudioRoutingData(settings: StreamSettings, diffSettings: Partial<StreamSettings>): void {
    // Fix problem with Audio Routing
    if (diffSettings && diffSettings?.channels) {
      for (let [index, channel] of diffSettings?.channels?.entries()) {
        if (channel?.audioRouting) {
          for (let [indexEncoder, audioRouting] of channel?.audioRouting?.entries()) {
            if (diffSettings?.channels[index]?.audioRouting[indexEncoder]?.audioRoutingData) {
              diffSettings.channels[index].audioRouting[indexEncoder].audioRoutingData = 
              settings.channels[index].audioRouting[indexEncoder].audioRoutingData.map(value => {
                if (value == null || (value && value[0] == null)) {
                  return ["silent", 0];
                }
                return value;
              });
            }
          }
        }
      }
    }
  }

  /**
   * User List
   */

  getUserLists(): Observable<UserList[]> {
    return this.getCurrentPreset().pipe(
      map((preset: Preset) => preset?.userList)
    );
  }

  addUserList(list: UserList): void {
    const preset = this.selectedPreset;
    preset.userList.push(list);
    this.updatePreset(preset);
  }

  updateUserList(list: UserList): void {
    const preset = this.selectedPreset;
    const index = preset.userList.findIndex(item => item.id === list.id);
    if (index !== -1) {
      preset.userList[index] = list;
      this.updatePreset(preset);
    }
  }

  deleteUserList(list: UserList): void {
    const preset = this.selectedPreset;
    const index = preset.userList.findIndex(item => item.id === list.id);
    if (index !== -1) {
      preset.userList.splice(index, 1);
      this.updatePreset(preset);
    }
  }

  selectItemToUserList(item: UserListItem, listId: string): void {
    const preset = this.selectedPreset;
    const listIndex = preset.userList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    preset.userList[listIndex].selected = item;
    this.setNeedUpdatePresetNameTemplate(preset);
  }

  addItemToUserList(item: UserListItem, listId: string): void {
    const preset = this.selectedPreset;
    const listIndex = preset.userList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    preset.userList[listIndex].items.push(item);
    if (preset.userList[listIndex].items.length === 1) {
      preset.userList[listIndex].selected = item;
      this.setNeedUpdatePresetNameTemplate(preset);
    } else {
      this.updatePreset(preset);
    }
  }

  updateItemToUserList(item: UserListItem, listId: string): void {
    const preset = this.selectedPreset;
    const listIndex = preset.userList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    const itemIndex = preset.userList[listIndex].items.findIndex(value => value.id === item.id);
    if (itemIndex !== -1) {
      preset.userList[listIndex].items[itemIndex] = item;
      if (preset.userList[listIndex].selected.id === item.id) {
        preset.userList[listIndex].selected = item;
        this.setNeedUpdatePresetNameTemplate(preset);
      } else {
        this.updatePreset(preset);
      }
    }
  }

  deleteItemFromUserList(item: UserListItem, listId: string): void {
    const preset = this.selectedPreset;
    const listIndex = preset.userList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    const itemIndex = preset.userList[listIndex].items.findIndex(value => value.id === item.id);
    if (itemIndex !== -1) {
      if (preset.userList[listIndex].selected?.id === item.id) {
        preset.userList[listIndex].selected = null;
      }
      preset.userList[listIndex].items.splice(itemIndex, 1);
      if (preset.userList[listIndex].items.length > 0) {
        preset.userList[listIndex].selected = preset.userList[listIndex].items[0];
      }
      this.setNeedUpdatePresetNameTemplate(preset);
    }
  }

  exportUserList(list: UserList): Observable<Error> {
    const result = new Subject<Error>();
    if (this.nodeService.isElectron) {
      const currentWindow = this.electronService.remote.getCurrentWindow();
      this.electronService.remote.dialog
        .showSaveDialog(currentWindow, { defaultPath: list.name, showsTagField: false, properties: ['createDirectory']})
        .then(data => {
          if (!data.filePath) {
            result.complete();
            return;
          }
          const folder = data.filePath;
          const exportPath = folder + '.csv';
          const fileContent = list.items.map(item => item.name).join('\n');
          this.nodeService.fs.writeFile(
            exportPath,
            fileContent,
            (writeError: Error) => {
              if (writeError) {
                const error = new Error(`User list '${exportPath}' not export: ` + writeError);
                console.error(error.message);
                this.notificationService.error(error.message, `User list export`);
                result.next(error);
                result.complete();
              }
              console.log(`User list '${exportPath}' export`);
              this.notificationService.success('User list exported successfully');
              result.next(null);
              result.complete();
            }
          );
        })
        .catch(() => {});
    } else {
      const filename = list.name + '.csv';
      const fileContent = list.items.map(item => item.name).join('\n');
      const file = new Blob([fileContent], {type: 'text/plain'});
      this.nodeService.downloadFile(file, filename);
    }
    return result.asObservable();
  }

  importUserList(listId: string, file?): Observable<Error> {
    const result = new Subject<Error>();
    this.importList((items: UserListItem[], error: Error) => {
      // User cancel selection File
      if (!items && !error) {
        result.complete();
        return;
      }
      // Update user list
      if (items) {
        const list = this.selectedPreset.userList.find(item => item.id === listId);
        list.items = items;
        this.updateUserList(list);
      }
      // Finish with result
      result.next(error);
      result.complete();
    }, file);
    return result.asObservable();
  }

  importGlobalList(listId: string, file?): Observable<Error> {
    const result = new Subject<Error>();
    this.importList((items: UserListItem[], error: Error) => {
      // User cancel selection File
      if (!items && !error) {
        result.complete();
        return;
      }
      // Update global list
      if (items) {
        const list = this.globalList.find(item => item.id === listId);
        list.items = items;
        this.updateGlobalList(list);
      }
      // Finish with result
      result.next(error);
      result.complete();
    }, file);
    return result.asObservable();
  }

  importList(callback: (items: UserListItem[], error: Error) => void, file?): void {
    const complete = (items: UserListItem[], errorMessage?: string, filePaths?: string) => {
      if (errorMessage) {
        const error = new Error(errorMessage);
        console.error(error);
        this.notificationService.error(error.message, `User list import`);
        callback(null, error);
      } else {
        console.log(`User list '${filePaths}' import`);
        this.notificationService.success('User list imported successfully');
        callback(items, null);
      }
    };

    if (this.nodeService.isElectron) {
      const currentWindow = this.electronService.remote.getCurrentWindow();
      this.electronService.remote.dialog
        .showOpenDialog(currentWindow, { properties: ['openFile']})
        .then(data => {
          if (!data.filePaths.length) {
            callback(null, null);
            return;
          }
          const filePaths = data.filePaths[0];
          this.nodeService.fs.readFile(filePaths, 'utf8', (readError, data) => {
            if (readError) {
              complete(null, `User list '${filePaths}' not import: ` + readError);
              return;
            };
            if (typeof data === 'string' || data instanceof String) {
              let items = data.length
                ? data.split('\n').map(item => {
                  const normalizeItem = this.filenameNormalizePipe.transform(item);
                  return new UserListItem(normalizeItem);
                })
                : [];
              complete(items, null, filePaths);
            } else {
              complete(null, `User list '${filePaths}' can't parse String`);
            }
          });
        })
        .catch(() => {});
    } else {
      if (!file) {
        callback(null, null);
        return;
      }
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (readEvent) => {
        const data = readEvent.target.result as string;
        let items = data.length
          ? data.split('\n').map(item => {
            const normalizeItem = this.filenameNormalizePipe.transform(item);
            return new UserListItem(normalizeItem);
          })
          : [];
        complete(items, null, 'fileList');
      };
    }
  }

  /**
   * Global List
   */

  updateGlobalList(list: UserList): void {
    const index = this.globalList.findIndex(item => item.id === list.id);
    if (index !== -1) {
      this.globalList[index] = list;
      this.stateService.updateState({globalList: this.globalList});
    }
  }

  selectItemToGlobalList(item: UserListItem, listId: string): void {
    const listIndex = this.globalList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    this.globalList[listIndex].selected = item;
    this.stateService.updateState({globalList: this.globalList});
    this.needUpdateAllPresetNameTemplate();
  }

  addItemToGlobalList(item: UserListItem, listId: string): void {
    const listIndex = this.globalList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    this.globalList[listIndex].items.push(item);
    this.stateService.updateState({globalList: this.globalList});
    if (this.globalList[listIndex].items.length === 1) {
      this.globalList[listIndex].selected = item;
      this.needUpdateAllPresetNameTemplate();
    }
  }

  updateItemToGlobalList(item: UserListItem, listId: string): void {
    const listIndex = this.globalList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    const itemIndex = this.globalList[listIndex].items.findIndex(value => value.id === item.id);
    if (itemIndex !== -1) {
      this.globalList[listIndex].items[itemIndex] = item;
      this.stateService.updateState({globalList: this.globalList});
    }
    if (this.globalList[listIndex].selected.id === item.id) {
      this.needUpdateAllPresetNameTemplate();
    }
  }

  deleteItemFromGlobalList(item: UserListItem, listId: string): void {
    const listIndex = this.globalList.findIndex(value => value.id === listId);
    if (listIndex === -1) {
      return;
    }
    const itemIndex = this.globalList[listIndex].items.findIndex(value => value.id === item.id);
    if (itemIndex !== -1) {
      if (this.globalList[listIndex].selected?.id === item.id) {
        this.globalList[listIndex].selected = null;
      }
      this.globalList[listIndex].items.splice(itemIndex, 1);
      if (this.globalList[listIndex].items.length > 0) {
        this.globalList[listIndex].selected = this.globalList[listIndex].items[0];
      }
      this.stateService.updateState({globalList: this.globalList});
      this.needUpdateAllPresetNameTemplate();
    }
  }

  /**
   * XML tags
   */

  getXmlTags(): Observable<XmlTag[]> {
    return this.getCurrentPreset().pipe(
      map((preset: Preset) => preset?.xmlTags)
    );
  }

  addXmlTag(xmlTag: XmlTag): void {
    const preset = this.selectedPreset;
    if (!preset) {
      return;
    }
    preset.xmlTags.push(xmlTag);
    this.updatePreset(preset);
  }

  deleteXmlTag(xmlTag: XmlTag): void {
    const preset = this.selectedPreset;
    const index = preset.xmlTags.findIndex(item => item.id === xmlTag.id);
    if (index !== -1) {
      preset.xmlTags.splice(index, 1);
      this.updatePreset(preset);
    }
  }

}
