import { Component, OnDestroy, OnInit } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

// Services
import { DeckService } from '@modules/deck/services/deck.service';

// Components
import { AddDeckComponent } from '@modules/deck/components/add-deck/add-deck.component';

// Types
import { Deck } from '@modules/deck/types/deck';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-deck-list',
  templateUrl: './deck-list.component.html',
  styleUrls: ['./deck-list.component.less']
})
export class DeckListComponent implements OnInit, OnDestroy {

  // Public
  public decks: Deck[] = [];

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<DeckListComponent>,
    private dialog: MatDialog,
    private deckService: DeckService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.deckService.getDecks()
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.alive)
      )
      .subscribe(decks => this.decks = decks);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }

  addDeck(): void {
    this.dialog.open(AddDeckComponent, { disableClose: true });
  }

}
