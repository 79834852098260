<!-- Input type -->
<div class="settings-option" [class.disabled]="disabled" [class.inherit]="(!useDefault && highlightInherit) || forceHighlight">
  <div class="preset-settings-title-big">{{ title }}</div>
  <div class="multi-tile">
    <app-select [options]="options"
                [selected]="value"
                (selectedChange)="selectOption($event.value)"
                [placeholder]="placeholder"
                [highlighted]="(!useDefault && highlightInherit) || forceHighlight"
                class="full-tile-select"></app-select>
  </div>
</div>
