import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

// Types
import { StreamOnlineStatus } from '@modules/stream/types/stream-online-status';

@Component({
  selector: 'app-online-status',
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.less']
})
export class OnlineStatusComponent implements OnInit, OnChanges {

  // Inputs
  @Input() value: boolean | StreamOnlineStatus;
  @Input() size: number = 6;

  // Public
  public onlineValue: StreamOnlineStatus = 'offline';

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      if (typeof this.value === 'boolean') {
        this.onlineValue = this.value === true ? 'online' : 'offline';
      } else {
        this.onlineValue = this.value;
      }
    }
  }

}
