import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, startWith, take, takeUntil, filter, throttleTime } from 'rxjs/operators';

// Service
import { Rs422Service } from '@modules/rs422/services/rs422.service';
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { Stream } from '@modules/stream/types/stream';
import { StreamStatus } from '@modules/stream/types/stream-status';
import { RS422Info } from '@modules/rs422/types/rs422-info';
import { RS422Timecodes } from '@modules/rs422/types/rs422-timecodes';
import { RS422ScrollActions } from '@modules/rs422/types/rs422-scroll-actions';
import { RS422ScrollMode, RS422Settings } from '@modules/rs422/types/rs422-settings';

@Component({
  selector: 'app-rs422-control',
  templateUrl: './rs422-control.component.html',
  styleUrls: ['./rs422-control.component.less']
})
export class Rs422ControlComponent implements OnInit, OnDestroy {

  // ViewChild
  @ViewChild('timecode', { static: false }) timecodeRef: ElementRef;
  @ViewChild('timecodeIn', { static: false }) timecodeInRef: ElementRef;
  @ViewChild('timecodeOut', { static: false }) timecodeOutRef: ElementRef;

  // Inputs
  @Input() stream: Stream;
  @Input() actions: RS422ScrollActions;
  @Input() settings: RS422Settings;
  @Input() stopPropagation = true;

  @Output() settingsChange = new EventEmitter<RS422Settings>();

  // Public
  public info: RS422Info;
  public timecodes: RS422Timecodes;
  public streamStatus: StreamStatus;
  public popoverClose = new Subject<void>();
  public cueTcValue: string;
  public inTcValue: string;
  public outTcValue: string;
  public inTcEdit: boolean = false;
  public outTcEdit: boolean = false;

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    public elementRef: ElementRef,
    private rs422Service: Rs422Service,
    private streamService: StreamService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Info
    this.rs422Service.getInfo(this.stream)
      .pipe(take(1))
      .subscribe(info => {
        this.info = info;
      });
    this.rs422Service.remoteStatus(this.stream)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(info => {
        this.info = info;
      });
    // Get Timecodes
    this.rs422Service.getTimecodes(this.stream)
      .pipe(take(1))
      .subscribe(timecodes => {
        this.timecodes = timecodes;
      });
    this.rs422Service.remoteTimecodes(this.stream)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(timecodes => {
        this.timecodes = timecodes;
        if (this.timecodes) {
          this.timecodeRef.nativeElement.innerHTML = this.timecodes?.remoteTimecode || '00:00:00:00';
          this.timecodeInRef.nativeElement.innerHTML = this.timecodes?.remoteInTimecodePreset ? (this.timecodes?.remoteInTimecode || '00:00:00:00') : '--:--:--:--';
          this.timecodeOutRef.nativeElement.innerHTML = this.timecodes?.remoteOutTimecodePreset ? (this.timecodes?.remoteOutTimecode || '00:00:00:00') : '--:--:--:--';
          if (!this.inTcEdit && this.timecodes?.remoteInTimecodePreset) {
            this.inTcValue = this.timecodes?.remoteInTimecode;
          }
          if (!this.outTcEdit && this.timecodes?.remoteOutTimecodePreset) {
            this.outTcValue = this.timecodes?.remoteOutTimecode;
          }
        }
      });
    // Get Stream Status
    this.streamService.getStatus(this.stream)
      .pipe(
        startWith(null),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive)
      )
      .subscribe(status => this.streamStatus = status);

    // Actions
    this.actions.var
      .pipe(
        filter(speed => this.settings?.scrollMode === 'var'),
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(speed => {
        if (speed >= 0) {
          this.rs422Service.varForward(this.stream, speed);
        } else {
          this.rs422Service.varReverse(this.stream, -speed);
        }
      });
    this.actions.shuttle
      .pipe(
        filter(speed => this.settings?.scrollMode === 'shuttle'),
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(speed => {
        if (speed >= 0) {
          this.rs422Service.shuttleForward(this.stream, speed);
        } else {
          this.rs422Service.shuttleReverse(this.stream, -speed);
        }
      });
    this.actions.jog
      .pipe(
        filter(speed => this.settings?.scrollMode === 'jog'),
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(speed => {
        if (speed >= 0) {
          this.rs422Service.jogForward(this.stream, speed);
        } else {
          this.rs422Service.jogReverse(this.stream, -speed);
        }
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
    this.popoverClose.next();
    this.popoverClose.complete();
  }

  /**
   * Record
   */

  startRecord(): void {
    if (this.info?.cinedeckAuto && !this.timecodes?.remoteInTimecodePreset) {
      return;
    }
    if (this.stream) {
      if (!this.info?.cinedeckAuto && this.settings.servo) {
        this.rs422Service.recordServo(this.stream);
      } else {
        this.streamService.record(this.stream);
      }
    }
  }

  stopRecord(): void {
    if (this.stream) {
      this.streamService.stopRecord(this.stream);
    }
    if (!this.info?.cinedeckAuto && this.settings.servo) {
      this.stop();
    }
  }

  /**
   * Methods Network
   */

  play(): void {
    this.rs422Service.play(this.stream);
    this.changeScrollMode(null);
  }

  playReverse(): void {
    this.rs422Service.playReverse(this.stream);
    this.changeScrollMode(null);
  }
 
  stop(): void {
    this.rs422Service.stop(this.stream);
  }

  record(): void {
    this.rs422Service.record(this.stream);
  }

  setInTimecode(timecode?: string): void {
    if (timecode || this.timecodes?.remoteTimecode) {
      this.rs422Service.setInTimecode(this.stream, timecode || this.timecodes.remoteTimecode);
    }
  }

  setOutTimecode(timecode?: string): void {
    if (timecode || this.timecodes?.remoteTimecode) {
      this.rs422Service.setOutTimecode(this.stream, timecode || this.timecodes.remoteTimecode);
    }
  }

  cueToTimecodeOffset(offset: number): void {
    this.rs422Service.cueToTimecode(this.stream, null, offset);
  }

  cueToTimecode(timecode: string): void {
    this.rs422Service.cueToTimecode(this.stream, timecode);
  }

  fastForward(): void {
    this.rs422Service.fastForward(this.stream);
  }

  rewind(): void {
    this.rs422Service.rewind(this.stream);
  }

  review(): void {
    this.rs422Service.review(this.stream);
  }

  preview(): void {
    this.rs422Service.preview(this.stream);
  }

  autoEdit(): void {
    this.rs422Service.autoEdit(this.stream, !this.info?.autoEditStatus);
  }

  standby(): void {
    this.rs422Service.standby(this.stream, !this.info?.standbyStatus);
  }

  preroll(): void {
    this.rs422Service.preroll(this.stream);
  }

  eject(): void {
    this.rs422Service.eject(this.stream);
  }

  autoMode(): void {
    this.rs422Service.autoMode(this.stream, !this.info?.cinedeckAuto);
  }

  /**
   * Methods
   */

  openFile(): void {
    if (this.streamStatus?.playback?.haveFileToOpen && this.streamStatus?.playback?.isUnloaded) {
      this.streamService.openFile(this.stream);
    }
  }

  moveToInTimecode(): void {
    if (this.timecodes?.remoteInTimecodePreset && this.timecodes?.remoteInTimecode) {
      this.cueToTimecode(this.timecodes.remoteInTimecode);
    }
  }

  moveToOutTimecode(): void {
    if (this.timecodes?.remoteOutTimecodePreset && this.timecodes?.remoteOutTimecode) {
      this.cueToTimecode(this.timecodes.remoteOutTimecode);
    }
  }

  resetInOutPoints(): void {
    this.rs422Service.inReset(this.stream);
    this.rs422Service.outReset(this.stream);
  }

  closePopover(): void {
    this.popoverClose.next();
  }

  changeScrollMode(mode: RS422ScrollMode): void {
    this.settings.scrollMode = this.settings.scrollMode !== mode ? mode : null;
    this.settingsChange.emit(this.settings);
  }

  changeStatusView(): void {
    this.settings.showStatus = !this.settings.showStatus;
    this.settingsChange.emit(this.settings);
  }

  changeServo(): void {
    this.settings.servo = !this.settings.servo;
    this.settingsChange.emit(this.settings);
  }

  clickStopPropagation(event: MouseEvent): void {
    if (this.stopPropagation) {
      event?.stopPropagation();
      event?.preventDefault();
    }
  }

}
