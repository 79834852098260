import { Option } from "@modules/elements/types/option";

export class PresetSettingsOptions {
  inputTypes: Option<string>[] = [
    {value: 'none', title: 'None'},
    {value: 'sdi', title: 'SDI'},
    {value: 'ndi', title: 'NDI'},
    {value: 'srt_listener', title: 'SRT Listener'},
    {value: 'srt_caller', title: 'SRT Caller'},
  ];
  inputSources: Option<string>[] = [ // do not have at settings now
    {value: 'BNC1', title: 'BNC1'},
    {value: 'BNC2', title: 'BNC2'},
    {value: 'BNC3', title: 'BNC3'},
    {value: 'BNC4', title: 'BNC4'},
  ];
  audioSources: Option<string>[] = [  // StreamSettingsInput -> activeAudioInput
    {value: 'off', title: 'Off'},
    {value: 'sdi_2ch', title: 'SDI 2ch'},
    {value: 'sdi_8ch', title: 'SDI 8ch'},
    {value: 'sdi_16ch', title: 'SDI'},
    {value: 'balanced', title: 'Balanced'},
    {value: 'aes_2ch', title: 'AES 2ch'},
    {value: 'aes_4ch', title: 'AES 2ch'},
    {value: 'aes_8ch', title: 'AES 8ch'},
    {value: 'analog', title: 'Analog'},
    {value: 'ndi', title: 'NDI'},
    {value: 'srt', title: 'SRT'},
    {value: 'asio', title: 'ASIO'},
  ];
  referenceSources: Option<string>[] = [ // StreamSettingsInput -> activeSyncSource
    {value: 'genlock', title: 'BNC'},
    {value: 'sdi_1', title: 'SDI Input 1'},
    {value: 'sdi_2', title: 'SDI Input 2'},
    {value: 'sdi_3', title: 'SDI Input 3'},
    {value: 'sdi_4', title: 'SDI Input 4'},
    {value: 'self', title: 'Self'},
    {value: 'free_running', title: 'Free Running'},
    {value: 'interlock', title: 'Interlock'}
  ];
  resolutions: Option<string>[] = [
    {value: '4k', title: '4K'},
    {value: 'uhd', title: 'UHD'},
    {value: '2k', title: '2K'},
    {value: '1080i', title: '1080i'},
    {value: '1080p', title: '1080p'},
    {value: '720p', title: '720p'},
    {value: 'ntsc-p', title: 'NTSCp'},
    {value: 'pal-p', title: 'PALp'},
    {value: 'ntsc', title: 'NTSC'},
    {value: 'pal', title: 'PAL'},
  ];
  framerates: Option<string>[] = [ // StreamSettingsInput -> activeFrameRate
    {value: '23.98', title: '23.98'},
    {value: '24', title: '24'},
    {value: '25', title: '25'},
    {value: '29.97', title: '29.97'},
    {value: '30', title: '30'},
    {value: '50', title: '50'},
    {value: '59.94', title: '59.94'},
    {value: '60', title: '60'},
  ];
  colorSpaces: Option<string>[] = [ // StreamSettingsInput -> activePixelFormat
    {value: 'yuv8', title: 'YUV8'},
    {value: 'yuv10', title: 'YUV10'},
    {value: 'rgb10', title: 'RGB10'},
    {value: 'rgb12', title: 'RGB12'},
  ];
  rgbRange: Option<number>[] = [ // StreamSettingsInput -> inputRange | outputRange
    {value: 0, title: 'CGR'},
    {value: 1, title: 'SMPTE'},
  ];
  levelA: Option<boolean>[] = [ // StreamSettingsChannel[index] -> isYuvLevelA
    {value: true, title: 'Level A'},
    {value: false, title: 'Level B'},
  ];
  range: Option<number>[] = [ // do not have at settings now
    {value: 0, title: 'N/A'},
  ];
  hdr: Option<boolean>[] = [
    {value: false, title: 'Off'},
    {value: true, title: 'On'}
  ];
  hdrType: Option<{hdrTransferPQ: boolean, hdrMatrixConst: boolean}>[] = [
    {value: {hdrTransferPQ: true, hdrMatrixConst: true}, title: 'PQ constant'},
    {value: {hdrTransferPQ: true, hdrMatrixConst: false}, title: 'PQ non-constant'},
    {value: {hdrTransferPQ: false, hdrMatrixConst: true}, title: 'HLG constant'},
    {value: {hdrTransferPQ: false, hdrMatrixConst: false}, title: 'HLG non-constant'},
  ];
  frameDropBehaviours: Option<string>[] = [ // StreamSettingsInput -> frameDropBehavior
    {value: 'ignore', title: 'Ignore'},
    {value: 'stop', title: 'Stop'},
  ];
  inputLossBehaviours: Option<number>[] = [ // one field for all channels. storeing in prefs.ini 
    {value: 0, title: 'Stop'},
    {value: 1, title: 'Replace withw/black frame'},
    {value: 2, title: 'Replace w/last good frame'}
  ];
  videoDelays: Option<number>[] = [ // do not preset in settings
    {value: 0, title: 'Off'},
    {value: 1, title: 'On'}
  ];
  audioDelaysLocked: Option<number>[] = [ // do not reset in settings
    {value: 1, title: 'Off'},
    {value: 0, title: 'On'},
  ];
  aspectRatioOverrides: Option<string>[] = [ // StreamSettingsInput -> aspectRatioString
    {value: 'auto', title: 'None'},
    {value: '1', title: '1:1'},
    {value: '0.9091', title: '10:11'},
    {value: '1.109', title: '59:54'},
    {value: '4:3', title: '4:3'},
    {value: '1.2121', title: '40:33'},
    {value: '1.4587', title: '118:81'},
    {value: '1.5', title: '15:10'},
    {value: '2', title: '2:1'},
    {value: '16:9', title: '16:9'},
    {value: '2.35', title: 'Anamorphic 2.35'}
  ];
  outputUpconverts: Option<number>[] = [ // StreamSettingsChannel -> upconvertMode
    {value: 0, title: 'No'},
    {value: 1, title: 'Anamorphic'},
    {value: 2, title: 'Pillar'}
  ];
  m444Outputs: Option<string>[] = [ // StreamSettingsChannel -> _444output
    {value: 'single_link', title: 'Convert to YUV'},
    {value: 'dual_link_a', title: 'Dual Link 1.5G RGB 10/12'},
    {value: 'dual_link_b', title: 'Level B 3G RGB 10/12'}
  ];

  encoderCount: Option<number>[] = [
    {value: 2, title: '2'},
    {value: 3, title: '3'},
    {value: 4, title: '4'},
    {value: 8, title: '8'},
  ];

  encoderCodec: Option<string>[] = [ // StreamSettingsEncode -> activeCodec
    {value: 'invalid', title: 'Invalid'},
    {value: 'uncomp', title: 'UNCOMP'},
    {value: 'cineform', title: 'CineForm'},
    {value: 'dnxhd', title: 'DNxHD'},
    {value: 'dnxhr', title: 'DNxHR'},
    {value: 'prores', title: 'ProRes'},
    {value: 'jfif', title: 'JFIF'},
    {value: 'cineform_mux_3d', title: 'CineForm3D'},
    {value: 'xdcam', title: 'XDCAM'},
    {value: 'avcintra', title: 'AVCINTRA'},
    {value: 'xavc', title: 'XAVC'},
    {value: 'h264', title: 'H264 cpu'},
    {value: 'h264_gpu', title: 'H264 GPU'},
    {value: 'dvcprohd', title: 'DVCProHD'},
    {value: 'dpx', title: 'DPX'},
    {value: 'mpeg_imx', title: 'MPEG-IMX'},
    {value: 'j2k', title: 'J2K'},
    {value: 'j2k_gpu', title: 'J2K GPU'},
    {value: 'arri', title: 'ARRI'},
    {value: 'no_video', title: 'NO VIDEO'},
    {value: 'prores_sd', title: 'ProRes SD'},
    {value: 'dnx_half', title: 'DNX HALF'},
    {value: 'dnx_quar', title: 'DNxHR QUARTER RES'},
    {value: 'ts', title: 'TS'},
  ];

  encoderQuality: Option<string>[] = [ // StreamSettingsEncode -> activeQuality, text get from CinedeckUtils.cpp
    {value: 'invalid', title: 'Invalid'},
    {value: 'cfhd_low', title: 'Low'},
    {value: 'cfhd_med', title: 'Medium'},
    {value: 'cfhd_high', title: 'High'},
    {value: 'cfhd_fs1', title: 'Film Scan 1'},
    {value: 'cfhd_fs2', title: 'Film Scan 2'},
    {value: 'cfhd_keying', title: 'Keying'},
    {value: 'cfhd_uncomp', title: 'Uncomp'},
    {value: 'prores_proxy', title: 'Proxy'},
    {value: 'prores_lt', title: 'LT'},
    {value: 'prores_normal', title: 'Normal'},
    {value: 'prores_hq', title: 'HQ'},
    {value: 'prores_hd_4k_hq', title: '2K -> 4K'},
    {value: 'prores_hd_uhd_hq', title: 'HD -> UHD'},
    {value: 'prores_4444', title: '4444'},
    {value: 'prores_xq', title: 'XQ'},
    {value: 'dnx36', title: 'DNX36'},
    {value: 'dnx45', title: 'DNX45'},
    {value: 'dnx60', title: 'DNX60'},
    {value: 'dnx75', title: 'DNX75'},
    {value: 'dnx90', title: 'DNX90'},
    {value: 'dnx110', title: 'DNX110'},
    {value: 'dnx115', title: 'DNX115'},
    {value: 'dnx120', title: 'DNX120'},
    {value: 'dnx145', title: 'DNX145'},
    {value: 'dnx175', title: 'DNX175'},
    {value: 'dnx185', title: 'DNX185'},
    {value: 'dnx220', title: 'DNX220'},
    {value: 'dnx240', title: 'DNX240'},
    {value: 'dnx290', title: 'DNX290'},
    {value: 'dnx90x', title: 'DNX90x'},
    {value: 'dnx110x', title: 'DNX110x'},
    {value: 'dnx175x', title: 'DNX175x'},
    {value: 'dnx185x', title: 'DNX185x'},
    {value: 'dnx220x', title: 'DNX220x'},
    {value: 'dnx240x', title: 'DNX240x'},
    {value: 'dnx444', title: 'DNx444'},
    {value: 'dnx440x', title: 'DNX440x'},
    {value: 'dnx36yuv10', title: 'DNX36'},
    {value: 'dnx45yuv10', title: 'DNX45'},
    {value: 'dnxlb', title: 'LB'},
    {value: 'dnxsq', title: 'SQ'},
    {value: 'dnxhq', title: 'HQ'},
    {value: 'dnxhqx', title: 'HQX'},
    {value: 'jfif_10_to_1m', title: '10:1m'},
    {value: 'jfif_15_to_1s', title: '15:1s'},
    {value: 'jfif_2_to_1', title: '2:1'},
    {value: 'jfif_8_to_1m', title: '8:1m'},
    {value: 'jfif_14_to_1', title: '14:1'},
    {value: 'jfif_20_to_1', title: '20:1'},
    {value: 'jfif_10_to_1', title: '10:1'},
    {value: 'jfif_28_to_1', title: '28:1'},
    {value: 'xdcam_hd_422_1920', title: 'HD 50'},
    {value: 'xdcam_hd_422_1280', title: 'HD 50'},
    {value: 'xdcam_ex_1920', title: 'EX'},
    {value: 'xdcam_ex_1440', title: 'EX-1440'},
    {value: 'xdcam_ex_1280', title: 'EX'},
    {value: 'xdcam_25_1440', title: 'CBR-25'},
    {value: 'avcintra_50', title: 'CLASS 50'},
    {value: 'avcintra_100', title: 'CLASS 100'},
    {value: 'xavc_cbg100', title: 'CBG 100'},
    {value: 'xavc_cbg300', title: 'CBG 300'},
    {value: 'xavc_cbg480', title: 'CBG 480'},
    {value: 'dvcpro_25', title: '25'},
    {value: 'dvcpro_50', title: '50'},
    {value: 'dvcpro_hd', title: '100'},
    {value: 'h264_avid800_fullhd', title: 'AVID800 480x270 800kbps'},
    {value: 'h264_avid800_hd', title: 'AVID800 320x180 800kbps'},
    {value: 'h264_avid2000_hd', title: 'AVID2000 320x360 2000kbps'},
    {value: 'h264_avid2000_fullhd', title: 'AVID2000 480x270 2000kbps'},
    {value: 'h264_avid1500_sd', title: 'AVID1500 352x240 1500kbps'},
    {value: 'h264_avid800_sd', title: 'AVID800 352x240 800kbps'},
    {value: 'h264_ipod', title: 'iPod 320x180 350kbps'},
    {value: 'h264_ipad', title: 'iPad 640x360 900kbps'},
    {value: 'h264_natgeo_fcp', title: 'FCP 350 320x180 350kbps'},
    {value: 'h264_main_proxy_1000', title: 'MAIN PROXY 1/2 res 1000kbps'},
    {value: 'h264_main_proxy_2000', title: 'MAIN PROXY 1/2 res 2000kbps'},
    {value: 'h264_main_proxy_3000', title: 'MAIN PROXY 1/2 res 3000kbps'},
    {value: 'h264_main_proxy_4000', title: 'MAIN PROXY 1/2 res 4000kbps'},
    {value: 'h264_main_proxy_5000', title: 'MAIN PROXY 1/2 res 5000kbps'},
    {value: 'h264_main_proxy_6000', title: 'MAIN PROXY 1/2 res 6000kbps'},
    {value: 'h264_main_proxy_6000s', title: '*NICK PROXY* 1/2 res 6000kbps'},
    {value: 'h264_pix', title: 'PIX 1280x720 2500kbps'},
    {value: 'h264_pix_25p', title: 'PIX 25P from 50P 1280x720 2500kbps'},
    {value: 'h264_dax', title: 'DAX 640x360 800kbps'},
    {value: 'h264_dax_2800', title: 'DAX 2800 640x360 2800kbps'},
    {value: 'h264_full_sd', title: 'FULL SD Pal or NTSC 1500kbps'},
    {value: 'h264_full_sd_3500', title: 'FULL SD_3500 Pal or NTSC 3500kbps'},
    {value: 'h264_full_sd_5000', title: 'FULL SD_5000 Pal or NTSC 5000kbps'},
    {value: 'h264_full_sd_6000', title: 'FULL SD_6000 Pal or NTSC 6000kbps'},
    {value: 'h264_anamorphic_full_sd', title: 'ANA FULL SD Pal or NTSC 1500kbps'},
    {value: 'h264_anamorphic_full_sd_3500', title: 'ANA FULL SD_3500 Pal or NTSC 3500kbps'},
    {value: 'h264_anamorphic_full_sd_6000', title: 'ANA FULL SD_6000 Pal or NTSC 6000kbps'},
    {value: 'pronology', title: '*PRONOLOGY* 640x360 1500kbps'},
    {value: 'fntech', title: '*FNTECH* 640x360 1000kbps'},
    {value: 'fntech2', title: '*FNTECH2* 640x360 400kbps'},
    {value: 'sd_864', title: 'SD 486 864x486 2000kbps'},
    {value: 'pal_854_480', title: 'SD 854x480 854x480 2000kbps'},
    {value: 'pal_720_576', title: 'PAL 720x576 720x576 1500kbps'},
    {value: 'sd_16x9', title: '*GP SD 16x9* 720x404 400kbps'},
    {value: 'sd_486', title: 'SD 720x486 720x486 800kbps'},
    {value: 'sd_480_4x3', title: 'SD 480x360 480x360 1000kbps'},
    {value: 'h264_nasa_50', title: '*NASA* 50 50000kbps'},
    {value: 'h264_nasa_25', title: '*NASA* 25 25000kbps'},
    {value: 'h264_rbre', title: '*RBRE* 852x480 1200kbps'},
    {value: 'h264_rbfd', title: '*RBFD* 1080p/720p 13000kbps'},
    {value: 'fhd_4000', title: 'FULL HD 1080p/720p 4000kbps'},
    {value: 'fhd_5000', title: 'FULL HD 1080p/720p 5000kbps'},
    {value: 'fhd_6000', title: 'FULL HD 1080p/720p 6000kbps'},
    {value: 'fhd_7000', title: 'FULL HD 1080p/720p 7000kbps'},
    {value: 'fhd_8000', title: 'FULL HD 1080p/720p 8000kbps'},
    {value: 'fhd_9000', title: 'FULL HD 1080p/720p 9000kbps'},
    {value: 'h264_idc', title: '*IDC* 1280x720 1000kbps'},
    {value: 'h264_gp720p1', title: '*GP 720p* 1280x720 3300kbps'},
    {value: 'h264_gp720p2', title: '*GP 720p* 1280x720 6000kbps'},
    {value: 'h264_hd10000', title: '"FULL HD 1080p/720p 10000kbps'},
    {value: 'h264_fhd_15000', title: 'FULL HD 1080p/720p 15000kbps'},
    {value: 'h264_fhd_20000', title: 'FULL HD 1080p/720p 20000kbps'},
    {value: 'h264_fhd_2500', title: 'FULL HD 2K/1080p/720p 25000kbps'},
    {value: 'h264_fhd_30000', title: 'FULL HD 2K/1080p/720p 30000kbps'},
    {value: 'h264_fhd_40000', title: 'FULL HD 2K/1080p/720p 40000kbps'},
    {value: 'h264_pix', title: 'PIX 1280x720 2500kbps'},
    {value: 'h264_pix_25p', title: 'PIX 25P from 50P 1280x720 2500kbps'},
    {value: 'h264_720p_3300', title: '*GP 720p* 1280x720 3300kbps'},
    {value: 'h264_720p_4000', title: '720p proxy 1280x720 6000kbps'},
    {value: 'h264_720p_5000', title: '720p proxy 1280x720 5000kbps'},
    {value: 'h264_720p_6000', title: '720p proxy 1280x720 6000kbps'},
    {value: 'h264_720p_7600', title: '*GP 720p* 1280x720 7600kbps'},
    {value: 'h264_720p_9000', title: '720p proxy 1280x720 9000kbps'},
    {value: 'h264_720p_10000', title: '720p proxy 1280x720 10000kbps'},
    {value: 'h264_720p_15000', title: '720p proxy 1280x720 15000kbps'},
    {value: 'h264_720p_20000', title: '720p proxy 1280x720 20000kbps'},
    {value: 'h264_720p_25000', title: '720p proxy 1280x720 25000kbps'},
    {value: 'imx_30', title: '30'},
    {value: 'imx_40', title: '40'},
    {value: 'imx_50', title: '50'},
    {value: 'uncomp', title: 'Fixed'},
    {value: 'no_video', title: 'NO VIDEO'},
    {value: 'j2k_lossless', title: 'J2K Lossless (high quality)'},
    {value: 'j2k_50mbps', title: 'SD 50mbps'},
    {value: 'j2k_120mbps', title: 'HBO (24p-25p / 120mbps)'},
    {value: 'j2k_160mbps', title: 'HBO (2997p-5994i / 160mbps)'},
    {value: 'j2k_200mbps', title: 'J2K 200mbps'},
    {value: 'j2k_250mbps', title: 'J2K 250mbps'},
    {value: 'j2k_500mbps', title: 'J2K 500mbps'},
    {value: 'ts', title: 'TS'},
  ];

  encoderPadded: Option<boolean>[] = [
    {value: false, title: 'Unpadded'},
    {value: true, title: 'Padded'}
  ];

  encoderCBR: Option<boolean>[] = [
    {value: false, title: 'VBR'},
    {value: true, title: 'CBR'}
  ];

  encoderFieldWrapped: Option<boolean>[] = [
    {value: true, title: 'Field Wrapped'},
    {value: false, title: 'Frame Wrapped'}
  ];
  
  encoderForceBT709: Option<boolean>[] = [
    {value: true, title: 'BT709'},
    {value: false, title: 'BT601'}
  ];
  
  encoderFieldShift: Option<boolean>[] = [
    {value: true, title: 'Enabled'},
    {value: false, title: 'Disabled'}
  ];

  encoderFirstField: Option<boolean>[] = [
    {value: true, title: 'Top'},
    {value: false, title: 'Bottom'}
  ];

  encoderMxfClipFolders: Option<boolean>[] = [
    {value: true, title: 'On'},
    {value: false, title: 'Off'}
  ];

  encoderForceProgressive: Option<boolean>[] = [
    {value: true, title: 'On'},
    {value: false, title: 'Off'}
  ];
  encoderForceInterlaced: Option<boolean>[] = [
    {value: true, title: 'On'},
    {value: false, title: 'Off'}
  ];

  encoderFrameSizeType: Option<string>[] = [
    {value: 'normal', title: '2K'},
    {value: 'letterbox', title: 'Letterbox'},
    {value: 'cropped', title: 'Cropped'}
  ];

  encoderWrapper: Option<string>[] = [ // StreamSettingsEncode -> activeWrapper
    {value: 'invalid', title: 'Invalid'},
    {value: 'mov', title: 'MOV'},
    {value: 'mov_mono', title: 'MOV MONO'},
    {value: 'mov_discrete', title: 'MOV DISCRETE'},
    {value: 'mov_interleaved', title: 'MOV Interleaved'},
    {value: 'mov_wav', title: 'MOV+WAV MONO'},
    {value: 'mov_2_51', title: 'MOV Stereo+5.1'},
    {value: 'mxf_interplay', title: 'opAtom MXF (AMT)'},
    {value: 'avid_mxf', title: 'AVID MXF'},
    {value: 'op1a_mxf', title: 'MXF OP1a'},
    {value: 'op1a_stereo', title: 'MXF OP1a Stereo Pairs'},
    {value: 'op1a_interleaved', title: 'MXF OP1a Interleaved'},
    {value: 'mp4', title: 'MP4'},
    {value: 'p2', title: 'P2 MXF'},
    {value: 'dpx', title: 'Full Range DPX'},
    {value: 'dpx_bc', title: 'Head/Video Range DPX'},
    {value: 'avi', title: 'AVI'},
    {value: 'gdi', title: 'IMAGE'},
    {value: 'dpp', title: 'MXF OP1a DPP'},
    {value: 'as02', title: 'MXF AS-02'},
    {value: 'ts', title: 'TS'},
  ];

  encoderTcSource: Option<string>[] = [ // tcSource
    {value: 'gen', title: 'Generator'},
    {value: 'sdi', title: 'SDI'},
    {value: 'ndi', title: 'NDI'},
    {value: 'srt', title: 'SRT'},
    {value: 'extltc', title: 'Ext LTC'},
    {value: 'adrienne', title: 'Adrienne LTC'},
    {value: 'trigger_timer', title: 'Trigger Timer'},
    {value: 'rs422', title: 'RS422'},
    {value: 'playback', title: 'Playback'},
    {value: 'playlist', title: 'Playlist'},
    {value: 'irig_systime', title: 'IRIG Systime'},
    {value: 'irig_vanc', title: 'IRIG Vanc'},
    {value: 'irig_clip', title: 'IRIG Clip'},
  ];

  encoderSegmentRecord: Option<string>[] = [ // StreamSettingsEncode -> segmentMode
    {value: 'no', title: 'Off'},
    {value: 'increment', title: 'Increment'},
    {value: 'manual', title: 'Manual Break'},
    {value: 'tc_break', title: 'TC Break'},
  ];

  encoderSegmentLimitMode: Option<boolean>[] = [ // StreamSettingsEncode -> isSegmentLimitMode
    {value: false, title: 'No Limit'},
    {value: true, title: 'Count Limit'},
  ];

  encoderCountLimit: Option<string>[] = [
    {value: '', title: ''},
  ];

  encoderAudioCodec: Option<string>[] = [
    {value: 'pcm', title: 'PCM'},
  ];

  encoderAudioCodecH264: Option<string>[] = [
    {value: 'aac', title: 'AAC'},
    {value: 'pcm', title: 'PCM'},
  ];

  encoderAudioTrackType: Option<string>[] = [
    {value: 'mono', title: 'Mono '},
    {value: 'discrete', title: 'Discrete'},
    {value: 'stereo', title: 'Stereo '},
    {value: 'interleaved', title: 'Interleaved'},
    {value: 'mixed', title: 'Mixed'},
  ];

  encoderAudioFrequency: Option<number>[] = [
    {value: 0, title: '48KHz'},
  ];

  encoderAudioBitDepth: Option<number>[] = [
    {value: 16, title: '16bit'},
    {value: 24, title: '24bit'},
  ];

  encoderAudioChannelLayout: Option<number>[] = [
    {value: 0, title: 'SMPTE'},
  ];

  encoderEndianType: Option<boolean>[] = [
    {value: true, title: 'Big'},
    {value: false, title: 'Little'},
  ];

  encoderCaptions: Option<string>[] = [ // StreamSettingsEncode -> captionsMode
    {value: 'no', title: 'Off'},
    {value: 'standard', title: 'Standard (Line 9 CC Line 11 AFD)'},
    {value: 'hbo', title: 'HBO spec (Line 18 CC/AFD)'},
    {value: 'analog_to_digital', title: 'Extract (SD Analog to Digital CC)'},
    {value: 'vbi_dump', title: 'VBI Dump'},
  ];

  encoderSrtCaptions: Option<string>[] = [
    {value: 'CC 1', title: 'CC 1'},
    {value: 'CC 3', title: 'CC 3'},
    {value: 'Service 1', title: 'Service 1'},
    {value: 'Service 2', title: 'Service 2'},
  ];

  encoderCaptionsSidecar: Option<string>[] = [
    {value: '', title: ''},
  ];

  encoderBurns: Option<boolean>[] = [
    {value: true, title: 'Yes'},
    {value: false, title: 'No'},
  ];

  encoderMulticam: Option<string>[] = [
    {value: 'editshare', title: 'Editshare'},
  ];

  encoderAspectRatio: Option<boolean>[] = [ // StreamSettingsEncode -> anamorphic
    {value: false, title: 'None'},
    {value: true, title: '16:9'}
  ];

  encoderXML: Option<string>[] = [ // StreamSettingsEncode -> generateXMLMode
    {value: 'off', title: 'Off'},
    {value: 'msg', title: 'MSG XML'},
    {value: 'cinedeck', title: 'Cinedeck XML'},
    {value: 'as-11', title: 'AS-11 sidecar'},
    {value: 'marintek', title: 'Marintek'},
    {value: 'nasa', title: 'NASA'},
  ];

  encoderActionAfterRecord: Option<string>[] = [ // StreamSettingsEncode -> actionAfterRecord
    {value: 'off', title: 'Off'},
    {value: 'copy_file', title: 'Copy File'},
  ];

  writeMode: Option<string>[] = [
    {value: 'invalid', title: 'Invalid'},
    {value: 'single', title: 'Single'},
    {value: 'redundant', title: 'Redundant'},
    {value: 'rollover', title: 'Rollover'},
  ];

  loggerExportFile: Option<string>[] = [
    {value: '', title: 'Off'},
    {value: 'avid', title: 'AVID Media Composer'},
    {value: 'davinci', title: 'Davinci Resolve'},
    {value: 'premiere', title: 'Adobe Premiere Pro'},
    // {value: 'finalcut', title: 'Final Cut Pro'},
  ];

  recordControl: Option<string>[] = [
    {value: 'no', title: 'Manual'},
    {value: 'device', title: 'External Device via SDI'},
    {value: 'edl', title: 'EDL'},
    {value: 'input_rec_run', title: 'Input Rec-run TC'},
  ];

  recordTcTriggerDevice: Option<string>[] = [
    {value: 'panasonic', title: 'Panasonic'},
    {value: 'red', title: 'RED'},
    {value: 'arri', title: 'ARRI'},
    {value: 'canon', title: 'Canon'},
  ];

  ltcSources: Option<number>[] = [
    {value: 0, title: 'BreakoutHeader'},
    {value: 1, title: 'GenlockBNC'},
    {value: 2, title: 'Interlock'},
    {value: 3, title: 'StemPort'},
  ];

  genTCType: Option<string>[] = [
    {value: 'free_run', title: 'FreeRun'},
    {value: 'rec_run', title: 'RecRun'},
    {value: 'per_take', title: 'PerTake'},
    {value: 'telecine', title: 'Telecine'},
    {value: 'avid', title: 'Avid'},
  ];

  genTCDropFlag: Option<boolean>[] = [
    {value: true, title: 'DF'},
    {value: false, title: 'NDF'},
  ];

  rs422Mode: Option<number>[] = [
    {value: 0, title: 'Off'},
    {value: 1, title: 'Recorder'},
    {value: 2, title: 'Player'},
    {value: 3, title: 'VDCP Player'},
  ];

  jogMode: Option<boolean>[] = [
    {value: true, title: 'Standard'},
    {value: false, title: 'Time-based'},
  ];

  jogSensitive: Option<number>[] = [
    {value: 1, title: '1'},
    {value: 2, title: '2'},
    {value: 3, title: '3'},
    {value: 4, title: '4'},
    {value: 5, title: '5'},
  ];

  deviceUIMode: Option<string>[] = [
    {value: 'normal', title: 'Normal'},
    {value: '3G_rgb', title: '3G RGB'},
    {value: 'single', title: 'Single'},
    {value: '3d', title: '3D'},
    {value: '4k', title: '4K'},
    {value: 'high_fps', title: 'High fps'},
    {value: 'duallink', title: 'Duallink'},
    {value: '4i1o_h264', title: '4i1o H264'},
    {value: 'three_encoders', title: 'Three Encoders'},
    {value: '8_inputs', title: '8 Inputs'},
    {value: 'high_fps', title: 'High fps'},
    {value: 'aja_1ch', title: 'AJA 1ch'},
    {value: 'aja_4ch', title: 'AJA 4ch'},
    {value: 'aja_8ch', title: 'AJA 8ch'},
    {value: '2i2o_3g_rgb', title: '2i2o 3G RGB'},
    {value: 'one_4k_out', title: 'One 4K out'},
    {value: 'UHD_1i1o', title: 'UHD 1i1o'},
    {value: '6i2o', title: '6i2o'},
    {value: '4and1und', title: '4and1und'},
    {value: 'alpha_output', title: '0i 1out + 1alpha YUV 1.5G'},
  ];

  onOffOptions: Option<boolean>[] = [ // do not reset in settings
    {value: true, title: 'On'},
    {value: false, title: 'Off'},
  ];

  videoEncoderProfileOptions:  Option<string>[] = [
    {value: 'baseline', title: 'Baseline'},
    {value: 'main', title: 'Main'},
    {value: 'heigh', title: 'High'}
  ];

  videoEncoderBitrateOptions: Option<number>[] = [
    {value: 1000000, title: '1 Mbps'},
    {value: 2000000, title: '2 Mbps'},
    {value: 2500000, title: '2.5 Mbps'},
    {value: 3300000, title: '3.3 Mbps'},
    {value: 4000000, title: '4 Mbps'},
    {value: 5000000, title: '5 Mbps'},
    {value: 6000000, title: '6 Mbps'},
    {value: 8000000, title: '8 Mbps'},
    {value: 10000000, title: '10 Mbps'},
    {value: 15000000, title: '15 Mbps'},
    {value: 20000000, title: '20 Mbps'},
    {value: 25000000, title: '25 Mbps'},
    {value: 40000000, title: '40 Mbps'},
    {value: 50000000, title: '50 Mbps'},
    {value: 60000000, title: '60 Mbps'},
    {value: 70000000, title: '70 Mbps'},
    {value: 80000000, title: '80 Mbps'},
    {value: 90000000, title: '90 Mbps'},
    {value: 100000000, title: '100 Mbps'},
  ];

  audioEncoderBitrateOptions: Option<number>[] = [
    {value: 128000, title: '128 kbits'},
    {value: 160000, title: '160 kbits'},
    {value: 192000, title: '192 kbits'},
    {value: 224000, title: '224 kbits'},
    {value: 256000, title: '256 kbits'},
    {value: 320000, title: '320 kbits'},
    {value: 384000, title: '384 kbits'},
    {value: 448000, title: '448 kbits'},
    {value: 512000, title: '512 kbits'},
    {value: 640000, title: '640 kbits'},
    {value: 768000, title: '768 kbits'},
    {value: 896000, title: '896 kbits'},
    {value: 1024000, title: '1024 kbits'},
  ];

  constructor() {}
}
