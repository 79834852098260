import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { Stream } from '@modules/stream/types/stream';
import { EncoderRecordQueue } from '@modules/stream/types/encoder-record-queue';
import { StreamSettings } from '@modules/stream/types/stream-settings';


@Component({
  selector: 'app-encoder-record-queue',
  templateUrl: './encoder-record-queue.component.html',
  styleUrls: ['./encoder-record-queue.component.less']
})
export class EncoderRecordQueueComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() stream: Stream;
  @Input() settings: StreamSettings;

  // Public
  public encoderRecordQueue: EncoderRecordQueue[] = [];

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Encoder frame queue
    this.streamService.recordQueueUpdated(this.stream)
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(recordQueue => {
        this.encoderRecordQueue = recordQueue.filter((item, index) => {
          if (this.settings && this.settings?.encode[index]) {
            return this.settings.encode[index].enabled;
          }
          return true;
        });
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

}
