import { Subject } from "rxjs";

export class JogSpeedometer {

  // Public
  public forwardSpeedChanged = new Subject<number>();
  public reverseSpeedChanged = new Subject<number>();

  // Private
  private position = 0;
  private steps = 10;
  private timerInterval = 100;
  private timerId: number;
  private events: number = 0;

  /**
   * Public
   */

  setPosition(event: MouseEvent, rect): void {
    // Points
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const np = this.positionToStep(x / rect.width, y / rect.height);
    if (np + 1 === this.position || this.position === 0 && np === this.steps - 1) {
      this.moveRight();
      this.position = np;
    } else if (np === this.position + 1 || np === 0 && this.position === this.steps - 1) {
      this.moveLeft();
      this.position = np;
    }
  }

  /**
   * Private
   */

  moveLeft(): void {
    this.startTimer();
    this.events = Math.min(this.events, 0);
    this.events--;
  }

  moveRight(): void {
    this.startTimer();
    this.events = Math.max(this.events, 0);
    this.events++;
  }

  stop(): void {
    this.stopTimer();
    this.events = 0;
  }

  
  positionToStep(x: number, y: number): number {
    const angle = Math.atan2(2.0 * y - 1.0, 2.0 * (-x) + 1.0) + Math.PI;
    const stepAngle = Math.PI * 2.0 / this.steps;
    return Math.floor(angle / stepAngle);
  }

  private eventsToSpeed(events: number): number {
    switch (events) {
    case 1:
      return 0.25;
    case 2:
      return 0.5;
    case 3:
      return 1;
    case 4:
      return 2;
    case 5:
      return 4;
    case 6:
      return 8;
    case 7:
      return 12;
    case 8:
      return 16;
    case 0:
      return 0;
    default:
      return 20;
    }
  }

  /**
   * Timers
   */

  startTimer(): void {
    if (this.timerId) {
      this.stopTimer();
    }
    this.timerId = window.setTimeout(() => this.timerEvent(), this.timerInterval);
  }

  stopTimer(): void {
    window.clearTimeout(this.timerId);
    this.timerId = null;
  }

  timerEvent(): void {
    if (this.events == 0) {
      stop();
      return;
    }

    if (this.events > 0) {
      this.forwardSpeedChanged.next(this.eventsToSpeed(this.events));
    } else {
      this.reverseSpeedChanged.next(this.eventsToSpeed(-this.events));
    }

    this.events = 0;
  }


}
