<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Copy input’s settings to other inputs that share a preset </div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="preset-settings-copy-content">

    <!-- Inputs -->
    <div class="preset-inputs list-content">
      <div
        *ngFor="let item of streams"
        class="tag"
        [class.selected]="selectedStreams.includes(item.id)"
        (click)="selectInput(item)"
      >
        {{ item.name }}
      </div>
      <div *ngIf="!streams.length">No other Inputs for current preset</div>
    </div>

    <!-- Setting -->
    <div class="preset-settings">

      <!-- Encoders -->
      <mat-checkbox class="checkbox" [checked]="settingCopy.primaryEncoder" (change)="updateSettings('primaryEncoder')" disableRipple>Primary encoder</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.secondaryEncoder" (change)="updateSettings('secondaryEncoder')" disableRipple>Secondary Encoder</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.tertiaryEncoder" (change)="updateSettings('tertiaryEncoder')" disableRipple>Tertiary Encoder</mat-checkbox>
      <div class="break"></div>

      <mat-checkbox class="checkbox" [checked]="settingCopy.inputType" (change)="updateSettings('inputType')" disableRipple>Input Type</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.audioSource" (change)="updateSettings('audioSource')" disableRipple>Audio Source</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.srtOutput" (change)="updateSettings('srtOutput')" disableRipple>SRT Output</mat-checkbox>
      <div class="break"></div>

      <mat-checkbox class="checkbox" [checked]="settingCopy.resolution" (change)="updateSettings('resolution')" disableRipple>Resolution</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.frameRate" (change)="updateSettings('frameRate')" disableRipple>Frame Rate</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.colorFormat" (change)="updateSettings('colorFormat')" disableRipple>Color format</mat-checkbox>
      <div class="break"></div>

      <mat-checkbox class="checkbox" [checked]="settingCopy.audioInputRouting" (change)="updateSettings('audioInputRouting')" disableRipple>Audio Input Routing</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.audioOutputRouting" (change)="updateSettings('audioOutputRouting')" disableRipple>Audio Output Routing</mat-checkbox>
      <div class="break"></div>

      <mat-checkbox class="checkbox" [checked]="settingCopy.codec" (change)="updateSettings('codec')" disableRipple>Codec</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.quality" (change)="updateSettings('quality')" disableRipple>Quality</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.wrapper" (change)="updateSettings('wrapper')" disableRipple>Wrapper</mat-checkbox>
      <div class="break"></div>

      <mat-checkbox class="checkbox" [checked]="settingCopy.recordDestinations" (change)="updateSettings('recordDestinations')" disableRipple>Record Destinations</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.outputOverlays" (change)="updateSettings('outputOverlays')" disableRipple>Output overlays</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.burns" (change)="updateSettings('burns')" disableRipple>Burns</mat-checkbox>
      <div class="break"></div>

      <mat-checkbox class="checkbox" [checked]="settingCopy.allSettings" (change)="updateSettings('allSettings')" disableRipple>All settings</mat-checkbox>
      <mat-checkbox class="checkbox" [checked]="settingCopy.bestEffort" (change)="updateSettings('bestEffort')" disableRipple>Best effort (flag settings that cannot be copied)</mat-checkbox>

    </div>

  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="close()">Cancel</div>
  <div class="button" (click)="save()" [class.disabled]="!selectedStreams.length">Copy</div>
</div>
