import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

// Types
import { Clip } from '@modules/deck/types/clip';
import { Timecode } from '@modules/elements/types/timecode';
import { StreamStatus } from '@modules/stream/types/stream-status';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-channel-timecode',
  templateUrl: './channel-timecode.component.html',
  styleUrls: ['./channel-timecode.component.less']
})
export class ChannelTimecodeComponent implements OnInit, AfterViewInit, OnChanges {

  // ViewChild
  @ViewChild('timecode') timecodeRef: ElementRef;
  @ViewChild('timecodeInputElement') timecodeInputRef: ElementRef;

  // Input
  @Input() stream: Stream;
  @Input() status: StreamStatus;
  @Input() clip: Clip;
  @Input() record = false;

  // Output
  @Output() jump = new EventEmitter<{tc?: string, frame?: number, offset?: number}>();

  // Public
  public timecode: string;  // Timecode string expected as HH:MM:SS:FF or HH:MM:SS;FF or HH:MM:SS.SSSS
  public timecodeInput: string;
  public placeholder = '00:00:00:00';
  public mask = '00:00:00:00';
  public canEdit = false;
  public editing = false;
  public error: Error;

  // Private
  private tcRegex = /^(\d\d):(\d\d):(\d\d)(:|;|\.)(\d{2,4})$/;
  private irig = false;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('clip' in changes) {
      this.updatePlaceholderAndMask();
    }
    this.canEdit = this.status?.playback?.isLoaded && !!this.clip;
    if (!this.canEdit) {
      this.endEdit();
    }
  }

  /**
   * Methods
   */

  startEdit(): void {
    if (!this.canEdit) {
      return;
    }
    if (!this.editing) {
      this.timecodeInput = this.timecode;
    }
    this.editing = true;
    setTimeout(() => this.timecodeInputRef.nativeElement.focus(), 50);
  }

  endEdit(): void {
    this.editing = false;
    setTimeout(() => {
      if (this.timecodeInputRef) {
        this.timecodeRef.nativeElement.innerHTML = this.timecodeInputRef || '00:00:00:00'
      }
    }, 10);
  }

  jumpTC(timecode: string): void {
    if (!timecode?.length || this.error) {
      return;
    }
    const tc = new Timecode(timecode, this.clip.timebase, !!this.clip.drop);
    let frame = tc.frameCount - this.clip.startTimecodeFrames;
    frame = Math.min(Math.max(frame, 0), (this.clip.durationFrames - 1));
    this.jump.emit({ frame });
  }

  setValue(timecode: string): void {
    this.timecode = timecode;
    if (this.timecodeRef) {
      this.timecodeRef.nativeElement.innerHTML = this.timecode || '00:00:00:00';
    }
  }

  validate(value: string): boolean {
    const result = this.tcRegex.exec(value);
    if (result === null) {
      this.error = new Error('Invalid Timecode');
      this.error.name = 'invalid';
      return false;
    }
    if (result) {
      try {
        const timecode = new Timecode(value, this.clip.timebase, !!this.clip.drop);
      } catch (error) {
        this.error = error;
        this.error.name = 'cant_parse';
        return false;
      }
    }
    this.error = null;
    return true;
  }

  updatePlaceholderAndMask(): void {
    if (this.irig) {
      this.placeholder = '00:00:00.0000';
    } else if (this.clip?.drop > 0) {
      this.placeholder = '00:00:00;00';
    } else {
      this.placeholder = '00:00:00:00';
    }
    this.mask = this.placeholder;
  }

  nextFrame(): void {
    if (!this.validate(this.timecodeInput)) { return; }
    let timecode = new Timecode(this.timecodeInput, this.clip.timebase, !!this.clip.drop);
    timecode = timecode.add(1);
    this.timecodeInput = timecode.toString();
  }
  
  prevFrame(): void {
    if (!this.validate(this.timecodeInput)) { return; }
    let timecode = new Timecode(this.timecodeInput, this.clip.timebase, !!this.clip.drop);
    timecode = timecode.add(-1);
    this.timecodeInput = timecode.toString();
  }

  /**
   * Actions
   */

  save(): void {
    this.jumpTC(this.timecodeInput);
    this.close();
  }

  close(): void {
    this.endEdit();
  }

}
