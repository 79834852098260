import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

// Services
import { MatDialog } from '@angular/material/dialog';
import { ChannelService } from '@modules/channel/services/channel.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { AlertService } from '@modules/elements/services/alert.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { LayoutService } from '@modules/layout/services/layout.service';

// Components
import { ChannelsSettingsComponent } from '../channels-settings/channels-settings.component';
import { AddDeckComponent } from '@modules/deck/components/add-deck/add-deck.component';

// Types
import { Channel } from '@modules/channel/types/channel';
import { ChannelsGrid } from '@modules/channel/types/channels-grid';
import { Preset } from '@modules/preset/types/preset';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-channels-grid',
  templateUrl: './channels-grid.component.html',
  styleUrls: ['./channels-grid.component.less']
})
export class ChannelsGridComponent implements OnInit, OnDestroy {

  // Public
  public locked = false;
  public channels: Channel[];
  public selectedChannels: Channel[];
  public channelsGrid: ChannelsGrid;
  public popoverClose = new Subject<void>();
  public fullscreenChannel: Channel;

  // Private
  private alive = new Subject<void>();
  private selectedPreset = new BehaviorSubject<Preset>(null);

  /**
   * Constructor
   */

  constructor(
    private dialog: MatDialog,
    private channelService: ChannelService,
    private streamService: StreamService,
    private deckService: DeckService,
    private alertService: AlertService,
    private presetService: PresetService,
    private layoutService: LayoutService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Channels
    this.channelService.getChannels()
      .pipe(takeUntil(this.alive))
      .subscribe(channels => this.channels = channels);

    // Selected channels
    this.channelService.getSelectedChannels()
      .pipe(takeUntil(this.alive))
      .subscribe(selectedChannels => this.selectedChannels = selectedChannels);

    // Fullscreen channel
    this.channelService.getFullscreenChannel()
      .pipe(takeUntil(this.alive))
      .subscribe(channel => this.fullscreenChannel = channel);

    // Grid
    this.channelService.getChannelsGrid()
      .pipe(takeUntil(this.alive))
      .subscribe(channelsGrid => this.channelsGrid = channelsGrid);

    // Get selected preset
    this.presetService.getCurrentPreset()
      .pipe(takeUntil(this.alive))
      .subscribe(this.selectedPreset);

    this.showAddDeckIfNeeded();
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  showAddDeckIfNeeded(): void {
    this.channelService.getChannels()
      .pipe(
        delay(1000),
        filter(channels => channels.every(channel => channel.streamId === undefined || channel.streamId === null)),
        switchMap(() => this.deckService.getDecks()),
        filter(decks => decks.length === 0),
        switchMap(() => this.streamService.getStreams()),
        filter(streams => streams.length === 0),
        take(1),
        switchMap(() => this.alertService.show(
          'Connect to Deck',
          'To get started, you need to connect to Cinedeck Deck',
          ['Cancel', 'Connect'],
          0
        )),
        filter(response => response === 1),
        switchMap(() => this.dialog.open(AddDeckComponent, {data: {addAllInputs: true }, disableClose: true }).afterClosed()),
        filter(result => result?.streams && result?.streams?.length),
        map(result => result.streams),
        switchMap(streams => this.alertService.show(
            'Assign Channels',
            'Do you want to add new streams to channels',
            ['Cancel', 'Assign'],
            0
          ).pipe(
            filter(response => response === 1),
            map(() => streams)
          )
        )
      )
      .subscribe((streams: Stream[]) => {
        streams.forEach((stream, index) => {
          const channel = this.channels[index];
          if (channel && stream) {
            this.setChannel(stream, channel);
          }
        });
      });
  }

  setChannel(stream: Stream, channel: Channel): void {
    // this.channelService.updateChannel({ ...channel, streamId: stream.id  });
    this.channelService.assignStreamToChannel(channel, stream.id)
        .pipe(take(1), filter(result => result))
        .subscribe(() => {
          const presetId = stream.presetId || this.selectedPreset.value?.id;
          this.streamService.setPreset(presetId, stream.id);
          const preset = this.presetService.getPresetSync(presetId);
          if (preset) {
            this.presetService.selectPreset(preset);
          }
        });
  }

  /**
   * Actions
   */

  openSettings(): void {
    this.dialog.open(ChannelsSettingsComponent, { autoFocus: false, disableClose: true });
  }

  selectChannel(channel: Channel, event: MouseEvent): void {
    this.channelService.selectChannels([ channel ], event.shiftKey);
  }

  lock(): void {
    this.locked = !this.locked;
  }

  changedGrid(): void {
    this.popoverClose.next();
  }

  showChannelList(): void {
    this.channelService.selectChannels([]);
    this.layoutService.getLayout()
      .pipe(
        take(1)
      )
      .subscribe(layout => {
        layout.channelList = true;
        this.layoutService.updateLayout(layout);
      });
  }

}
