import { Component, OnInit } from '@angular/core';

// Services
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { UserService } from '@modules/users/services/user.service';

// Components
import { PresetSettingsBaseComponent } from '../preset-settings-base/preset-settings-base.component';

@Component({
  selector: 'app-preset-settings-project',
  templateUrl: './preset-settings-project.component.html',
  styleUrls: ['./preset-settings-project.component.less']
})
export class PresetSettingsProjectComponent extends PresetSettingsBaseComponent implements OnInit {

  /**
   * Constructor
   */

  constructor(
    protected presetSettingsService: PresetSettingsService,
    userService: UserService
  ) {
    super(presetSettingsService, userService);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

}
