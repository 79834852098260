<div class="preset-settings-project-content">

  <!-- Header -->
  <div class="preset-settings-header">
    <div class="label">Editshare Flow Setup</div>
  </div>

  <!-- Disabled Status -->
  <div class="settings-status-text" *ngIf="recording">Settings unavailable during recording</div>
  <div class="settings-status-text" *ngIf="!deckOnline">Settings are not available if there is no connection</div>
  <div class="settings-status-text" *ngIf="preset && !preset?.isValid()">
    <mat-icon svgIcon="warning" class="icon error"></mat-icon>
    Selected preset is from a prior version: please delete and create a new one.
  </div>

  <!-- Enabled -->
  <div class="avid-enabled" *ngIf="settings?.editShareSettings">
    <mat-checkbox class="checkbox" [disabled]="disabled" [(ngModel)]="settings.editShareSettings.enabled" (ngModelChange)="updateSettings()" [class.inherit]="!isDefaultSetting('editShareSettings.enabled')" disableRipple>Enabled</mat-checkbox>
  </div>

  <!-- EditShare Setup -->
  <div class="avid-setup-content" *ngIf="settings?.editShareSettings">

    <!-- Form -->
    <div class="avid-form">
      
      <!-- IP Address -->
      <div class="field" [class.disabled]="disabled || !settings.editShareSettings.enabled"  [class.inherit]="!isDefaultSetting('editShareSettings.ipAddress')">
        <div class="label">IP Address</div>
        <app-input-settings class="input-field" [(value)]="settings.editShareSettings.ipAddress" (valueChange)="updateSettings()"></app-input-settings>
      </div>
      
      <!-- Port -->
      <div class="field" [class.disabled]="disabled || !settings.editShareSettings.enabled"  [class.inherit]="!isDefaultSetting('editShareSettings.port')">
        <div class="label">Port</div>
        <app-input-settings class="input-field" type="number" [(value)]="settings.editShareSettings.port" (valueChange)="updateSettings()"></app-input-settings>
      </div>

      <!-- User Name -->
      <div class="field" [class.disabled]="disabled || !settings.editShareSettings.enabled" [class.inherit]="!isDefaultSetting('editShareSettings.username')">
        <div class="label">Login</div>
        <app-input-settings class="input-field" [(value)]="settings.editShareSettings.username" (valueChange)="updateSettings()"></app-input-settings>
      </div>

      <!-- Password -->
      <div class="field" [class.disabled]="disabled || !settings.editShareSettings.enabled" [class.inherit]="!isDefaultSetting('editShareSettings.password')">
        <div class="label">Password</div>
        <app-input-settings class="input-field" [(value)]="settings.editShareSettings.password" (valueChange)="updateSettings()"></app-input-settings>
      </div>

      <!-- Multicam -->
      <div class="field" [class.disabled]="disabled || !settings.editShareSettings.enabled">
        <mat-checkbox class="checkbox" [disabled]="disabled" [(ngModel)]="settings.editShareSettings.multicamEnabled" (ngModelChange)="updateSettings()" [class.inherit]="!isDefaultSetting('editShareSettings.multicamEnabled')" disableRipple>multicam ingest project</mat-checkbox>
      </div>

    </div>
  </div>
</div>
