import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Services
import { MatIconRegistry } from '@angular/material/icon';
import { ElectronService } from 'ngx-electron';
import { NodeService } from '@modules/node/services/node.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  /**
   * Constructor
   */

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private electronService: ElectronService,
    private nodeService: NodeService,
  ) {
  }

  /**
   * Methods
   */

  setup(): void {
    this.updateApplicationTitle();
    this.updateApplicationMenu();
    this.registerMaterialIcons();
  }

  updateApplicationTitle(): void {
    // Window Title
    if (this.electronService.isElectronApp) {
      const app = this.electronService.remote.app;
      const window = this.electronService.remote.getCurrentWindow();
      window.setTitle(app.getName() + ' ' + app.getVersion());
    }
  }

  updateApplicationMenu(): void {
    if (this.electronService.isElectronApp && this.electronService.isWindows) {
      const app = this.electronService.remote.app;
      const customTitlebar = this.nodeService.customTitlebar;
      const titlebar = new customTitlebar.Titlebar({
        backgroundColor: customTitlebar.Color.fromHex('#222'),
        itemBackgroundColor: new customTitlebar.Color(new customTitlebar.RGBA(255, 255, 255, .1)),
        menuPosition: 'bottom',
        shadow: false,
        titleHorizontalAlignment: 'left',
        icon: './assets/favicon/favicon.png'
      });

      titlebar.updateTitle(app.getName() + ' ' + app.getVersion());
    }
  }

  registerMaterialIcons(): void {
    // Icons
    /* tslint:disable:max-line-length */
    this.matIconRegistry.addSvgIcon('break', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/break.svg'));
    this.matIconRegistry.addSvgIcon('channel-grid', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/channel-grid.svg'));
    this.matIconRegistry.addSvgIcon('channel-list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/channel-list.svg'));
    this.matIconRegistry.addSvgIcon('chat-info', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/chat-info.svg'));
    this.matIconRegistry.addSvgIcon('close', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/close.svg'));
    this.matIconRegistry.addSvgIcon('clock', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/clock.svg'));
    this.matIconRegistry.addSvgIcon('cloud-network', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cloud-network.svg'));
    this.matIconRegistry.addSvgIcon('collapse', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/collapse.svg'));
    this.matIconRegistry.addSvgIcon('disk', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/disk.svg'));
    this.matIconRegistry.addSvgIcon('dots-vertical', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dots-vertical.svg'));
    this.matIconRegistry.addSvgIcon('drag-vertical', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/drag-vertical.svg'));
    this.matIconRegistry.addSvgIcon('file-network', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/file-network.svg'));
    this.matIconRegistry.addSvgIcon('file', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/file.svg'));
    this.matIconRegistry.addSvgIcon('folder', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/folder.svg'));
    this.matIconRegistry.addSvgIcon('folder-open', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/folder-open.svg'));
    this.matIconRegistry.addSvgIcon('folder-close', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/folder-close.svg'));
    this.matIconRegistry.addSvgIcon('forward-end', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward-end.svg'));
    this.matIconRegistry.addSvgIcon('forward-next', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward-next.svg'));
    this.matIconRegistry.addSvgIcon('forward-out-point', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward-out-point.svg'));
    this.matIconRegistry.addSvgIcon('forward', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward.svg'));
    this.matIconRegistry.addSvgIcon('fullscreen', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fullscreen.svg'));
    this.matIconRegistry.addSvgIcon('fullscreen2', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fullscreen2.svg'));
    this.matIconRegistry.addSvgIcon('graph-bar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/graph-bar.svg'));
    this.matIconRegistry.addSvgIcon('grid', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/grid.svg'));
    this.matIconRegistry.addSvgIcon('hard-disk', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/hard-disk.svg'));
    this.matIconRegistry.addSvgIcon('image-slash', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/image-slash.svg'));
    this.matIconRegistry.addSvgIcon('info-circle', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/info-circle.svg'));
    this.matIconRegistry.addSvgIcon('keyboard-timecode', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/keyboard-timecode.svg'));
    this.matIconRegistry.addSvgIcon('link', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/link.svg'));
    this.matIconRegistry.addSvgIcon('lock', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/lock.svg'));
    this.matIconRegistry.addSvgIcon('loop', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/loop.svg'));
    this.matIconRegistry.addSvgIcon('logger', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/logger.svg'));
    this.matIconRegistry.addSvgIcon('minus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/minus.svg'));
    this.matIconRegistry.addSvgIcon('minus-circle', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/minus-circle.svg'));
    this.matIconRegistry.addSvgIcon('out-point', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/out-point.svg'));
    this.matIconRegistry.addSvgIcon('pause', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pause.svg'));
    this.matIconRegistry.addSvgIcon('play', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/play.svg'));
    this.matIconRegistry.addSvgIcon('playlist-next-pause', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/playlist-next-pause.svg'));
    this.matIconRegistry.addSvgIcon('playlist-next-play', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/playlist-next-play.svg'));
    this.matIconRegistry.addSvgIcon('playlist-prev-pause', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/playlist-prev-pause.svg'));
    this.matIconRegistry.addSvgIcon('playlist-prev-play', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/playlist-prev-play.svg'));
    this.matIconRegistry.addSvgIcon('plus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus.svg'));
    this.matIconRegistry.addSvgIcon('plus-circle', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus-circle.svg'));
    this.matIconRegistry.addSvgIcon('record', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/record.svg'));
    this.matIconRegistry.addSvgIcon('reload', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/reload.svg'));
    this.matIconRegistry.addSvgIcon('remove-points', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/remove-points.svg'));
    this.matIconRegistry.addSvgIcon('server', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/server.svg'));
    this.matIconRegistry.addSvgIcon('settings-sliders', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/settings-sliders.svg'));
    this.matIconRegistry.addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/settings.svg'));
    this.matIconRegistry.addSvgIcon('stop', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/stop.svg'));
    this.matIconRegistry.addSvgIcon('switch-button', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/switch-button.svg'));
    this.matIconRegistry.addSvgIcon('unlock', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/unlock.svg'));
    this.matIconRegistry.addSvgIcon('video', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/video.svg'));
    this.matIconRegistry.addSvgIcon('warning', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/warning.svg'));
    this.matIconRegistry.addSvgIcon('arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow.svg'));
    this.matIconRegistry.addSvgIcon('trash', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/trash.svg'));
    this.matIconRegistry.addSvgIcon('user', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/user.svg'));
    // Toolbar
    this.matIconRegistry.addSvgIcon('toolbar-attachment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-attachment.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-book', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-book.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-control', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-control.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-input', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-input.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-list.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-meters', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-meters.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-playback', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-playback.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-project', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-project.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-schedule', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-schedule.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-search', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-search.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-slider', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-slider.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-tags', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-tags.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-template', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-template.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-user', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-user.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-volume', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-volume.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-volume-mute-l', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-volume-mute-l.svg'));
    this.matIconRegistry.addSvgIcon('toolbar-volume-mute-r', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/toolbar-volume-mute-r.svg'));
    // Image
    this.matIconRegistry.addSvgIcon('smpte-color-bars', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/smpte-color-bars.svg'));
    /* tslint:enable:max-line-length */
  }

}
