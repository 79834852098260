<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">{{preset?.name}} Settings: {{ stream?.name || 'Base Settings' }}</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="preset-settings-content">

    <!-- Inputs -->
    <div class="preset-inputs list-content">
      <div class="tag" *ngIf="preset && preset?.isValid()" [class.selected]="!stream?.id" (click)="selectInput(null)">Base Settings</div>
      <div
        *ngFor="let item of streams"
        class="tag"
        [class.selected]="item?.id === stream?.id"
        (click)="selectInput(item)"
      >
        <app-online-status class="online-status" [value]="getOnlineStatus(item) | async"></app-online-status>
        {{ getName(item) }} <span class="settings-changed" *ngIf="preset?.settings[item?.id] && objectKeys(preset?.settings[item?.id]).length">*</span>
      </div>
    </div>

    <mat-tab-group *ngIf="!playbackOnly" animationDuration="0ms" [(selectedIndex)]="selectedTab">
      <!-- Input -->
      <mat-tab label="Input">
        <app-preset-settings-input *ngIf="selectedTab === 0" [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-input>
      </mat-tab>

      <!-- Output -->
      <mat-tab label="Output">
        <app-preset-settings-output *ngIf="selectedTab === 1" [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-output>
      </mat-tab>

      <!-- Encoders -->
      <mat-tab *ngFor="let encoder of settings?.encode; let index = index" [label]="encoder?.profile + ' encoder'">
        <app-preset-settings-encoder *ngIf="selectedTab === (2 + index)" [encoderIndex]="index" [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-encoder>
      </mat-tab>

      <!-- Timecode and Automation -->
      <mat-tab label="Timecode and Automation">
        <app-preset-settings-timecode *ngIf="selectedTab === (2 + settings?.encode?.length)" [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-timecode>
      </mat-tab>

      <!-- EditShare -->
      <mat-tab label="Editshare">
        <app-preset-settings-editshare *ngIf="selectedTab === (3 + settings?.encode?.length)" [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-editshare>
      </mat-tab>

      <!-- Avid -->
      <mat-tab label="Avid">
        <app-preset-settings-project *ngIf="selectedTab === (4 + settings?.encode?.length)" [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-project>
      </mat-tab>

      <!-- Output Overlays -->
      <mat-tab label="Output Overlays">
        <app-preset-settings-output-overlays *ngIf="selectedTab === (5 + settings?.encode?.length)" [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-output-overlays>
      </mat-tab>
    </mat-tab-group>

    <!-- Playback Settings (Channel output only) -->
    <mat-tab-group *ngIf="playbackOnly" animationDuration="0ms">
      <!-- Timecode and Automation -->
      <mat-tab label="Timecode and Automation">
        <app-preset-settings-timecode [stream]="stream" [preset]="preset" [settings]="settings" [streamSettings]="streamSettings" [settingsOptions]="settingsOptions" [recording]="recording" [deckOnline]="deckOnline"></app-preset-settings-timecode>
      </mat-tab>
    </mat-tab-group>

    <!-- Bottom buttons -->
    <div class="action-buttons">
      <!-- Reset settings -->
      <div *ngIf="stream" class="button" [class.disabled]="!deckOnline" (click)="resetCurrentTab()">Reset</div>
      <div *ngIf="stream" class="button" [class.disabled]="!deckOnline" (click)="resetAllSettings()">Reset All</div>
      <!-- Copy settings -->
      <div *ngIf="stream" class="button" [class.disabled]="!deckOnline" (click)="openSettingCopyModal()">Copy Settings</div>
    </div>

  </div>
</div>
