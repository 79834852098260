<div class="layout-content" *ngIf="(user | async)">

  <!-- Toolbar -->
  <app-toolbar class="toolbar"></app-toolbar>

  <!-- Main content -->
  <div class="mail-content">

    <!-- Left panel -->
    <app-left-panel class="left-panel" *ngIf="layout.presets || layout.userLists || layout.globalLists"></app-left-panel>

    <!-- Center panel -->
    <app-center-panel class="center-panel"></app-center-panel>
  
    <!-- Right panel -->
    <app-right-panel class="right-panel" *ngIf="layout.inputs || layout.templates || layout.recordSettings || (layout.playlist && showPlaylist)"></app-right-panel>
  
  </div>

</div>

<div class="layout-login" *ngIf="!(user | async)">
  <app-user-login class="user-login"></app-user-login>
</div>

