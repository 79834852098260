<!-- Disabled Status -->
<div class="settings-status-text" *ngIf="recording">Settings unavailable during recording</div>
<div class="settings-status-text" *ngIf="!deckOnline">Settings are not available if there is no connection</div>
<div class="settings-status-text" *ngIf="preset && !preset?.isValid()">
  <mat-icon svgIcon="warning" class="icon error"></mat-icon>
  Selected preset is from a prior version: please delete and create a new one.
</div>

<div class="settings-container">

  <!-- Upconvert File Output to Input Resolution -->
  <app-preset-settings-option title="Upconvert File Output to Input Resolution" [key]="'channels[' + deckChannel + '].upconvertMode'" [options]="settingsOptions.outputUpconverts" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

  <!-- 444 Output -->
  <app-preset-settings-option title="444 Output" [key]="'channels[' + deckChannel + ']._444output'" [options]="settingsOptions.m444Outputs" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

</div>

<!-- Header -->
<div class="preset-settings-header">
  <div class="title">Audio Output Routing (SDI, AES)</div>
</div>
<!-- Playout Audio channel -->
<div class="audio-channels-list" [class.disabled]="disabled">
  <div class="preset-settings-title-big audio-channels-list-title">Playout</div>
  <div *ngFor="let channel of settings?.channels[deckChannel]?.audioOutput?.data; let i = index" class="grid-tile" [class.disabled]="!channel?.enabled || !playoutEnabled">
    <div class="preset-settings-title-small audio-channel-title">{{i + 1}}</div>
    <app-select class="audio-select" [options]="playoutAudioChannelsOptions" [selected]="getAudioOutputOption(channel, playoutAudioChannelsOptions)" (selectedChange)="selectAudioOutput($event, i)"></app-select>
  </div>
</div>
<!-- Playthrough Audio channel -->
<div class="audio-channels-list" [class.disabled]="disabled">
  <div class="preset-settings-title-big audio-channels-list-title">Playthrough</div>
  <div *ngFor="let channel of settings?.channels[deckChannel]?.audioPlaythroug?.data; let i = index" class="grid-tile" [class.disabled]="!channel?.enabled || (i >= playthroughCount)">
    <div class="preset-settings-title-small audio-channel-title">{{i + 1}}</div>
    <app-select class="audio-select" [options]="playthroughAudioChannelsOptions" [selected]="getAudioOutputOption(channel, playthroughAudioChannelsOptions)" (selectedChange)="selectAudioPlaythroug($event, i)"></app-select>
  </div>
</div>

<!-- Header -->
<div class="preset-settings-header" *ngIf="settings?.output && false">
  <div class="title">SRT Playout</div>
  <mat-slide-toggle class="toggle" [(ngModel)]="settings.output.srtPlayoutEnable" (ngModelChange)="updateSettings()" [disabled]="recording || !deckOnline || !allowedSettingsUpdate"></mat-slide-toggle>
</div>
<!-- SRT Output -->
<div class="srt-output" *ngIf="settings?.output && false" [class.disabled]="disabled || !settings?.output?.srtPlayoutEnable">

  <!-- Encoder -->
  <div class="encoder">
    
    <!-- Video -->
    <div class="row">

      <!-- Passphrase -->
      <div class="settings-option" [class.disabled]="disabled">
        <div class="preset-settings-title-big">SRT Passphrase</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" [(value)]="settings?.output.srtListenerPassphrase" (valueChange)="updateSrtPassphrase()" [validation]="validateSrtPassphrase"></app-input-settings>
        </div>
      </div>

      <!-- Video profile -->
      <app-preset-settings-option
        title="Video profile"
        key="output.videoEncoderProfile"
        [options]="settingsOptions.videoEncoderProfileOptions"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>

      <!-- Video bitrate -->
      <app-preset-settings-option
        title="Video bitrate"
        key="output.videoEncoderBitrate"
        [options]="settingsOptions.videoEncoderBitrateOptions"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>

      <!-- Video keyFrameDistance -->
      <app-preset-settings-option
        title="Video key frame distance"
        key="output.videoEncoderKeyFrameDistance"
        [options]="videoEncoderKeyFrameDistanceOptions"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>
    </div>

    <!-- Audio -->
    <div class="row">

      <!-- Audio bitrate -->
      <app-preset-settings-option
        title="Audio bitrate"
        key="output.audioEncoderBitrate"
        [options]="settingsOptions.audioEncoderBitrateOptions"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>

      <!-- Audio channelCount -->
      <app-preset-settings-option
        title="Audio channel count"
        key="output.audioEncoderChannelCount"
        [options]="audioEncoderChannelCountOptions"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>

      <!-- Audio channelCount -->
      <app-preset-settings-option
        title="Audio channels in track"
        key="output.audioEncoderChannelsInTrack"
        [options]="audioEncoderChannelsInTrackOptions"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>

      <!-- Warning -->
      <div class="audio-warning" *ngIf="audioTrackWarning">
        <mat-icon svgIcon="warning" class="icon"></mat-icon>
        Drop track more then 8
      </div>
    </div>  
    
    <!-- Port -->
    <div class="row">
      <div class="port" *ngIf="stream">Port: <span class="value">{{ 9060 +  deckChannel}}</span></div>
    </div>

  </div>
</div>
