import { UniqueID } from '@modules/elements/types/unique-id';
import { Channel } from './channel';

export class ChannelsGroup {
  id: string;
  name: string;
  channelIds: string[];

  constructor(name: string, channels: Channel[]) {
    this.id = UniqueID.generate();
    this.name = name;
    this.channelIds = channels.map(channel => channel.id);
  }
}
