import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Modules
import { ElementsModule } from '@modules/elements/elements.module';

// Services
import { DeckService } from './services/deck.service';

// Components
import { AddDeckComponent } from './components/add-deck/add-deck.component';
import { DeckListComponent } from './components/deck-list/deck-list.component';
import { DeckItemComponent } from './components/deck-item/deck-item.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    ElementsModule,

    // Material
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
  ],
  declarations: [
    AddDeckComponent,
    DeckListComponent,
    DeckItemComponent
  ],
  exports: [
    AddDeckComponent,
    DeckListComponent
  ],
  providers: [DeckService]
})
export class DeckModule { }
