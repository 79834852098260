import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject, Observable, merge } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeckService } from '@modules/deck/services/deck.service';
import { StreamService } from '@modules/stream/services/stream.service';

// Components
import { AddDeckComponent } from '@modules/deck/components/add-deck/add-deck.component';
import { DeckListComponent } from '@modules/deck/components/deck-list/deck-list.component';

// Types
import { Deck } from '@modules/deck/types/deck';
import { Option } from '@modules/elements/types/option';
import { Stream } from '@modules/stream/types/stream';
import { StreamOnlineStatus } from '@modules/stream/types/stream-online-status';

@Component({
  selector: 'app-stream-settings',
  templateUrl: './stream-settings.component.html',
  styleUrls: ['./stream-settings.component.less']
})
export class StreamSettingsComponent implements OnInit, OnDestroy {

  // Public
  public streams: Stream[];
  public decks: Deck[];
  public decksOptions: Option<string>[] = [new Option(null, '--')];
  public inputsOptions: {[id: string]: Option<number>[]} = {};
  public inputsDisabledOptions: {[id: string]: Option<number>[]} = {};

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<StreamSettingsComponent>,
    private dialog: MatDialog,
    private deckService: DeckService,
    private streamService: StreamService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.streamService.getStreams()
      .pipe(takeUntil(this.alive))
      .subscribe(streams => {
        this.streams = [...streams, ...new Array(15)];
        this.updateInputDisabledOptions();
      });
    this.deckService.getDecks()
      .pipe(
        tap(decks => {
          this.decks = decks;
          this.decksOptions = [new Option(null, '--')];
          this.decksOptions.push(...this.decks.map(item => new Option(item.id, `${item.name} (${item.address})`)));
        }),
        switchMap(decks => merge(...decks.map(deck => this.deckService.getChannels(deck).pipe(map(channels => ({deck, channels})))))),
        takeUntil(this.alive),
      )
      .subscribe(({deck, channels}) => {
        const options = channels.map(channel => new Option(channel.index, channel.name));
        options.splice(0, 0, new Option(null, '--'));
        this.inputsOptions[deck.id] = options;
        this.updateInputDisabledOptions();
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  updateInputDisabledOptions(): void {
    this.inputsDisabledOptions = {};
    for (let deckId in this.inputsOptions) {
      const inputs = this.streams.filter(stream => stream?.deckId === deckId).map(stream => stream?.deckChannel);
      this.inputsDisabledOptions[deckId] = this.inputsOptions[deckId].filter(input => inputs.includes(input.value) && input.value !== null);
    }
  }

  getOptionForDeck(deckId: string): Option<string> {
    const deck = this.decksOptions.find(item => item.value === deckId);
    if (deck) {
      return deck;
    }
    return new Option(null, '--');
  }

  getOptionForInputs(stream: Stream): Option<number> {
    return this.inputsOptions[stream?.deckId]?.find(option => option.value === stream.deckChannel) ?? new Option(null, '--');
  }

  getDeckName(deckId: string): string {
    const deck = this.decks.find(item => item.id === deckId);
    if (deck) {
      return deck.name;
    }
    return null;
  }

  getOnlineStatus(stream: Stream): Observable<StreamOnlineStatus> {
    return this.streamService.getInputOnlineStatus(stream)
      .pipe(
        takeUntil(this.alive)
      );
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }

  addDeck(): void {
    this.dialog.open(AddDeckComponent, { disableClose: true });
  }

  openDeckList(): void {
    this.dialog.open(DeckListComponent, { disableClose: true, autoFocus: false });
  }

  selectDeck(option: Option<string>, stream: Stream): void {
    if (stream) {
      stream.deckId = option.value;
      if (stream.deckId === null) {
        this.streamService.deleteStream(stream);
      } else {
        this.streamService.updateStream(stream);
      }
    } else {
      const newStream = new Stream(option.value);
      this.streamService.addStream(newStream);
    }
  }

  selectInput(option: Option<number>, stream: Stream): void {
    const isNullValue = option.value === null || option.value === undefined;
    stream.deckChannel = option.value;
    stream.deckChannelName = isNullValue ? null : option.title;
    if (!stream.name && !isNullValue) {
      stream.name = this.getDeckName(stream.deckId) + ' - ' + option.title;
    } else if (isNullValue) {
      stream.name = null;
    }
    this.streamService.updateStream(stream);
  }

}
