import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';

@Component({
  selector: 'app-audio-levels',
  templateUrl: './audio-levels.component.html',
  styleUrls: ['./audio-levels.component.less']
})
export class AudioLevelsComponent implements OnInit, OnChanges, OnDestroy {

  // View Children
  @ViewChildren('level') levels: QueryList<ElementRef>;

  // Inputs
  @Input() audioLevels: Array<{ peak: number, rm: number }>;
  @Input() stream: Stream;
  @Input() settings: StreamSettings;
  @Input() selected: number;
  @Input() isPlayback: boolean;
  @Input() encoderIndex: number = 0;

  // Output
  @Output() selectedChange = new EventEmitter<number>();

  // Publics
  public audioPairs: Array<{value: number, title: string}>[];
  public levelItems = new Array(24);

  // Private
  private alive = new Subject<void>();
  private maxAudioLevel = 60;

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    public elementRef: ElementRef,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('stream' in changes || 'settings' in changes || 'isPlayback' in changes) {
      if (this.validateChanges(changes)) {
        this.updateAudioPairs();
      }
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  validateChanges(changes: SimpleChanges): boolean {
    let changed = false;
    if ('stream' in changes) {
      changed = changed || !isEqual(changes.stream.currentValue?.id, changes.stream.previousValue?.id);
    }
    if ('settings' in changes) {
      const currSettings = changes.settings.currentValue;
      const prevSettings = changes.settings.previousValue;
      const deckChannel = this.stream ? this.stream?.deckChannel : 0;
      changed = changed ||
        !isEqual(currSettings?.input.activeAudioInput, prevSettings?.input.activeAudioInput) ||
        !isEqual(currSettings?.channels[deckChannel]?.audioOutput?.data, prevSettings?.channels[deckChannel]?.audioOutput?.data) ||
        !isEqual(currSettings?.channels[deckChannel]?.audioRouting[this.encoderIndex]?.audioRoutingData, prevSettings?.channels[deckChannel]?.audioRouting[this.encoderIndex]?.audioRoutingData);
    }
    if ('isPlayback' in changes) {
      changed = changed || !isEqual(changes.isPlayback.currentValue, changes.isPlayback.previousValue);
    }
    return changed;
  }

  updateAudioPairs(): void {
    // Update Audio Pairs
    if (this.stream && this.settings) {
      if (this.isPlayback) {
        this.updatePlaybackAudioPairs();
      } else {
        this.updateStreamAudioPairs();
      }
    } else if (!this.stream && this.settings) {
      this.updateStreamAudioPairs();
    } else {
      this.audioPairs = [];
    }
    // Set Default selected if needed
    if (this.stream && (this.stream.audioPair === null || this.stream.audioPair === undefined)) {
      this.select(0);
    }
  }

  updateStreamAudioPairs(): void {
    this.audioPairs = [];
    // No display audio pairs when input audio source 'off'
    const audioSourceOff = this.settings?.input.activeAudioInput === 'off';
    // Create audio pairs from encoder audio routing
    const deckChannel = this.stream ? this.stream?.deckChannel : 0;
    const audioRoutingData = this.settings?.channels[deckChannel]?.audioRouting[this.encoderIndex]?.audioRoutingData || [];
    const audioRouting = audioRoutingData?.map(channel => {
      if (audioSourceOff) {
        return {value: -1, title: 'S'}
      }
      switch (channel[0]) {
        case 'silent':
          return {value: -1, title: 'S'};
        case 'tone_generator':
          return {value: -1, title: 'T'};
        case 'digital':
          return {value: channel[1], title: channel[1] + 1 + ''};
        default:
          break;
      }
    });
    
    for (let index = 0; index < audioRouting.length; index = index + 2) {
      const pair = (index + 1) === audioRouting.length ? [audioRouting[index]] : [audioRouting[index], audioRouting[index + 1]];
      this.audioPairs.push(pair);
    }
    if (this.audioPairs.length < this.selected) {
      this.select(0);
    }
    
  }

  updatePlaybackAudioPairs(): void {
    const deckChannel = this.stream ? this.stream?.deckChannel : 0;
    this.streamService.getSettingsAudioOutputRoutingChannelsCount(this.stream)
      .pipe(takeUntil(this.alive))
      .subscribe(audioChannelsCount => {
        this.audioPairs = [];
        const audioOutput = this.settings.channels[deckChannel].audioOutput.data
          .slice(0, audioChannelsCount.playout)
          .map(channel => ({value: channel.value, title: channel.value >= 0 ? (channel.value + 1) + '' : '-'}));
        for (let index = 0; index < audioOutput.length; index = index + 2) {
          this.audioPairs.push([audioOutput[index], audioOutput[index + 1]]);
        }
        if (this.audioPairs.length < this.selected) {
          this.select(0);
        }
      });
  }

  setAudioLevel(audioLevels: Array<{ peak: number, rm: number }>): void {
    this.audioLevels = audioLevels;
    const levelItemsCount = this.levelItems.length - 1;
    if (this.levels && this.levels.length > 0) {
      this.levels.forEach((level, levelIndex) => {
        const channel = this.audioPairs[Math.floor(levelIndex/2)][levelIndex % 2];
        const audioLevel = channel?.value < 0 ? null : audioLevels[channel?.value];
        if (audioLevel) {
          const rm = Math.round((audioLevel.rm / this.maxAudioLevel) * levelItemsCount);
          const peak = Math.round((audioLevel.peak / this.maxAudioLevel) * levelItemsCount);
          for (let index = 0; index < level.nativeElement.children.length; index++) {
            const item = level.nativeElement.children.item(index);
            item.style.opacity = index <= rm ?
              1 : index == peak ?
              1 : index > rm && index < peak ?
              0.3 : 0;
          }
        } else {
          for (let index = 0; index < level.nativeElement.children.length; index++) {
            const item = level.nativeElement.children.item(index);
            item.style.opacity = 0;
          }
        }
      });
    }
    
    
  }

  /**
   * Actions
   */

  select(pair: number): void {
    this.selected = pair;
    this.selectedChange.emit(pair);
  }

}
