import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';

// Services
import { PresetService } from '@modules/preset/services/preset.service';
import { StateService } from '@modules/settings/services/state.service';

// Types
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Wildcard } from '@modules/preset/types/wildcard';

@Component({
  selector: 'app-preset-name-template',
  templateUrl: './preset-name-template.component.html',
  styleUrls: ['./preset-name-template.component.less']
})
export class PresetNameTemplateComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() title: string;
  @Input() showSample = false;
  @Input() showInput = false;
  @Input() nameTemplate: string;
  @Input() disabled = false;
  @Input() draggable = false;
  @Input() isPathTemplate = false;
  @Input() defaultNameSeparator: string = 'none';

  // Output
  @Output() nameTemplateChange = new EventEmitter<string>();

  // Public
  public items: Wildcard[] = [];
  public sample: string = '';
  public entered = false;

  // Private
  private alive = new Subject();
  private wildcards: Wildcard[] = Wildcard.getList().reduce((result, items) => result.concat(items), []);
  private separators: Wildcard[] = Wildcard.getNameSeparators().concat(Wildcard.getPathSeparators());
  private removeNearSeparator = true;

  /**
   * Constructor
   */

  constructor(
    private presetService: PresetService,
    private stateService: StateService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Replace user list
    combineLatest([
      this.presetService.getCurrentPreset().pipe(map(preset => preset.userList)),
      this.stateService.getState().pipe(map(state => state.globalList))
    ])
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(([userList, globalList]) => {
        // User lists
        const userListWildcard = [];
        const userListIds = ['%1', '%2', '%3', '%4', '%5', '%6', '%7', '%8', '%9', '%0'];
        this.wildcards = this.wildcards.filter(item => !userListIds.includes(item.value));
        userList.forEach(item => userListWildcard.push(new Wildcard(item.name, item.value, item.color + '80')));
        this.wildcards.push(...userListWildcard);
        // Global lists
        const globalListWildcard = [];
        const globalListIds = globalList.map(item => item.value);
        this.wildcards = this.wildcards.filter(item => !globalListIds.includes(item.value));
        globalList.forEach(item => globalListWildcard.push(new Wildcard(item.name, item.value, item.color + '80')));
        this.wildcards.push(...globalListWildcard);

        this.parseNameTemplate();
      });
    // Remove near Separator
    this.stateService.getState()
      .pipe(
        map(state => state.removeNearSeparator),
        takeUntil(this.alive),
      )
      .subscribe(removeNearSeparator => this.removeNearSeparator = removeNearSeparator);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('nameTemplate' in changes) {
      this.parseNameTemplate();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  findWildcar(value: string): Wildcard {
    return this.wildcards.find(item => item.value === value) ?? this.separators.find(item => item.value === value);
  }

  parseNameTemplate(): void {
    const template = this.nameTemplate.split('');
    const items = [];
    const separatorsValue = (this.isPathTemplate ? Wildcard.getPathSeparators() : Wildcard.getNameSeparators()).map(item => item.value);
    let text = '';
    for (let character of template) {
      if ((character === '%' || separatorsValue.includes(character)) && text.length) {
        items.push(new Wildcard(text, text, '#27384D'));
        text = '';
      }
      text += character;
      const item = this.findWildcar(text);
      if (item) {
        items.push(item);
        text = '';
      } else if (character === '\\' || character === '\\\\' || character === '/' || character === '//') {
        items.push(this.findWildcar('\\'));
        text = '';
      }
    }
    if (text.length) {
      items.push(new Wildcard(text, text, '#27384D'));
    }
    this.items = items;
    this.sample = this.items.reduce((name, item) => name + item.value, '');
  }

  updateNameTemplate(): void {
    this.sample = this.items.reduce((name, item) => name + item.value, '');
    this.nameTemplate = this.items.reduce((name, item) => name + item.value, '');
    this.nameTemplateChange.emit(this.nameTemplate);
  }

  isSeparator(wildcard: Wildcard): boolean {
    return !!this.separators.find(item => item.value === wildcard.value);
  }

  addSeparatorIfNeeded(index: number, prev: boolean): boolean {
    if (index < 0 || index >= this.items.length) {
      return false;
    }
    const item = this.items[index];
    const insertIndex = index + (prev ? 1 : 0);
    if (item && !this.isSeparator(item)) {
      if (this.isPathTemplate) {
        this.items.splice(insertIndex, 0, this.findWildcar('\\'));
        return true;
      } else if (this.defaultNameSeparator !== 'none') {
        let separator: Wildcard;
        switch (this.defaultNameSeparator) {
          case 'underscore':
            separator = this.findWildcar('_');
            break;
          case 'dash':
            separator = this.findWildcar('-');
            break;
          case 'space':
            separator = this.findWildcar(' ');
            break;
          default:
            break;
        }
        this.items.splice(insertIndex, 0, separator);
        return true;
      }
    }
    return false;
  }

  changeNameTemplate(value: string): void {
    if (value?.length) {
      this.parseNameTemplate();
    }
  }

  changedNameTemplate(value: string): void {
    if (!this.nameTemplate?.length && this.isPathTemplate) {
      this.nameTemplate = '\\';
      this.parseNameTemplate();
    }
    this.nameTemplateChange.emit(this.nameTemplate);
  }

  /**
   * Actions
   */

  deleteItem(index: number): void {
    this.items.splice(index, 1);
    // Remove near separator if needed
    if (this.removeNearSeparator && index > 0 && index === this.items.length && this.isSeparator(this.items[index - 1])) {
      this.items.splice(index - 1, 1);
    } else if (this.removeNearSeparator && index > 0 && index < this.items.length && this.isSeparator(this.items[index]) && this.isSeparator(this.items[index - 1])) {
      this.items.splice(index, 1);
    }
    if (!this.items?.length && this.isPathTemplate) {
      this.items = Wildcard.getPathSeparators();
    }
    this.updateNameTemplate();
  }

  /**
   * DnD
   */

  drop(event: CdkDragDrop<string[]>): void {
    this.entered = false;
    if (event.previousContainer === event.container) {
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    } else {
      const wildcard: Wildcard = event.item.data;
      this.items.splice(event.currentIndex, 0, wildcard);
      if (!this.isSeparator(wildcard)) {
        const createdSeparator = this.addSeparatorIfNeeded(event.currentIndex - 1, true);
        this.addSeparatorIfNeeded(event.currentIndex + (createdSeparator ? 2 : 1), false);
      }
    }
    this.updateNameTemplate();
  }

}
