import { Injectable } from '@angular/core';

// Services
import { ElectronService } from 'ngx-electron';

// Services

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  // Public
  public isElectron = false;

  // NodeJS dependencies
  public path?;
  public fs?;
  public os?;
  public dns?;
  public chokidar?;
  public customTitlebar?;

  // CdkCore
  public cdkCore?;

  /**
   * Constructor
   */

  constructor(
    private electronService: ElectronService
  ) {
    this.isElectron = this.electronService.isElectronApp;
    if (this.isElectron) {

      // Init NodeJS dependencies
      this.path = (window as any)?.require('path');
      this.fs = (window as any).require('fs');
      this.os = (window as any)?.require('os');
      this.dns = (window as any).require('dns');
      this.chokidar = (window as any).require('chokidar');
      this.customTitlebar = (window as any).require('custom-electron-titlebar');

      // Get and create log folder if neede
      const logPath = this.path.join(this.os.homedir(), 'Documents', 'Cinedeck Client', 'logs');
      this.fs.mkdirSync(logPath, {recursive: true});

      // Create CdkCore
      // this.cdkCore = (window as any).require('cdkcore').cdkCore(this.path.join(logPath, `log ${new Date().toISOString()}.txt`));
    } else {
      this.path = require('path-browserify');
      this.path.win32 = this.path;
    }
  }

  /**
   * Methods
   */

  setup(): void {
    console.log('[NodeService] Setup');
  }

  openLink(url: string): void {
    this.electronService.isElectronApp
      ? this.electronService.shell.openExternal(url)
      : window.open(url, "_blank");
  }

  downloadJson(json: Object, filename: string): void {
    const jsonFile = new Blob([JSON.stringify(json)], {type: 'application/json'});
    this.downloadFile(jsonFile, filename + '.json');
  }

  downloadFile(content: Blob | MediaSource, filename: string): void {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(content);
    link.download = filename;
    link.click();
  }
  

}
