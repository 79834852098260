<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Clip manager</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="dialog-content">
  <div class="clip-manager-content">

    <!-- Directories -->
    <app-directory-tree class="directory-tree" [stream]="stream" (selectedPathChange)="selectFolder($event)"></app-directory-tree>


    <!-- Main -->
    <div class="clips-manager">

      <!-- Search -->
      <div class="search-field">
        <input class="input" [(ngModel)]="search" (ngModelChange)="applyFilter()" placeholder="Search...">
      </div>

      <!-- Clips -->
      <div class="file-manager-content">

        <table mat-table [dataSource]="dataSource" matSort >
        
          <!-- Name -->
          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell class="name-cell" *matCellDef="let element" [innerHTML]="element.name | highlightSearch: search" [matTooltip]="element.name" matTooltipShowDelay="1000"></td>
          </ng-container>
    
          <!-- Folder -->
          <ng-container matColumnDef="folder">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Folder </th>
            <td mat-cell class="folder-cell" *matCellDef="let element" [innerHTML]="element.folder | highlightSearch: search"></td>
          </ng-container>
    
          <!-- Date -->
    
          <!-- Start TC -->
          <ng-container matColumnDef="startTimecode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Start </th>
            <td mat-cell *matCellDef="let element"> {{element.startTimecode}} </td>
          </ng-container>
    
          <!-- Duration TC -->
          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
            <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
          </ng-container>
    
          <!-- Codec -->
          <ng-container matColumnDef="codec">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Codec </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.codec | highlightSearch: search"></td>
          </ng-container>
    
          <!-- Wrapper -->
          <ng-container matColumnDef="wrapper">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Wrapper </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.wrapper | highlightSearch: search"></td>
          </ng-container>
    
          <!-- Quality -->
          <ng-container matColumnDef="quality">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Quality </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.quality | highlightSearch: search"></td>
          </ng-container>
    
          <!-- Resolution -->
          <ng-container matColumnDef="resolution">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Resolution </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.resolution | highlightSearch: search"></td>
          </ng-container>
    
          <!-- Framerate -->
          <ng-container matColumnDef="fps">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Framerate </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.fps | highlightSearch: search"></td>
          </ng-container>
    
          <!-- Audio -->
          <ng-container matColumnDef="audioChannels">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Audio </th>
            <td mat-cell *matCellDef="let element"> {{element.audioChannels}} channels</td>
          </ng-container>
        
          <!-- Rows -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
          <tr
            mat-row
            (appClick)="selectFile($event, row)"
            (appDblClick)="openFile($event, row)"
            [class.row-selected]="selection.isSelected(row)"
            [class.row-in-playlist]="openedClips | includesBy: row.id"
            [class.row-current-play]="currentOpenClip === row.id"
            *matRowDef="let row; columns: displayedColumns;"
            [contextMenu]="inputMenu"
            [contextMenuSubject]="row"
            appDraggable
            [appDraggableData]="{data: row, type: 'clip'}"
            [appDraggablePlaceholder]="dragPlaceholder"
          >
            <!-- Drag placeholder -->
            <ng-template #dragPlaceholder>
              <div class="drag-clip">
                <div class="thumbnail">
                  <img [src]="thumbnailUrl + row.id" alt="" class="image">
                </div>
                <div class="name">{{ row.name }}</div>
              </div>
            </ng-template>
          </tr>
        </table>
    
        <!-- Error -->
        <div class="message error" *ngIf="error" (click)="error = null">
          <div class="title">Error</div>
          <div class="description">Note: {{ error?.error?.message}}</div>
        </div>
    
      </div>

    </div>

    <!-- Playlists -->
    <app-playlist-manager class="playlist-manager" [stream]="stream"></app-playlist-manager>

  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="addClips()" [class.disabled]="status?.info?.scanInProgress">{{status?.info?.scanInProgress ? 'Scaning...' : 'Add Media'}}</div>
  <div class="button" (click)="scanPlaylist()" [class.disabled]="status?.info?.scanPlaylistInProgress">{{status?.info?.scanPlaylistInProgress ? 'Scaning...' : 'Scan Playlist'}}</div>
  <div class="button" (click)="createPlaylist()" [class.disabled]="error || selection.isEmpty() || errors.invalidCreatePlaylist">Create Playlist</div>
  <div class="button add-clip-button" (click)="addToPlaylist()" [class.disabled]="error || selection.isEmpty() || errors.invalidAddPlaylist">Add to Playlist</div>
  <div class="button" (click)="select()" [class.disabled]="error || selection.isEmpty()">Select</div>
</div>

<!-- Context menu -->
<context-menu #inputMenu>
  <ng-template contextMenuItem let-item [visible]="!errors.invalidCreatePlaylist" (execute)="createPlaylist($event.item)">Create Playlist</ng-template>
  <ng-template contextMenuItem let-item [visible]="!errors.invalidAddPlaylist" (execute)="addToPlaylist($event.item)">Add to Playlist</ng-template>
</context-menu>
