import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { MatDialog } from '@angular/material/dialog';
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { UserService } from '@modules/users/services/user.service';

// Components
import { PresetSettingsComponent } from '../preset-settings/preset-settings.component';

// Types
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { PresetService } from '@modules/preset/services/preset.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { Preset } from '@modules/preset/types/preset';

@Component({
  selector: 'app-preset-record-settings',
  templateUrl: './preset-record-settings.component.html',
  styleUrls: ['./preset-record-settings.component.less']
})
export class PresetRecordSettingsComponent implements OnInit, OnDestroy {

  // Public
  public locked = false;
  public allowed: boolean;
  public stream: Stream;
  public settings: StreamSettings;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private dialog: MatDialog,
    private channelService: ChannelService,
    private streamService: StreamService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    userService: UserService
  ) {
      userService
        .getCurrentPermission('editPresetSettings')
        .pipe(takeUntil(this.alive))
        .subscribe(allowed => this.allowed = allowed);
    }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.channelService.getSelectedChannels()
      .pipe(
        map(channels => channels.length === 1 ? channels[0] : null),
        switchMap(channel => this.streamService.getStream(channel?.streamId)),
        tap(stream => this.stream = stream),
        switchMap(stream => this.presetService.getPreset(stream?.presetId)),
        map((preset: Preset) => preset?.getSetting(this.stream?.id)),
        takeUntil(this.alive),
      )
      .subscribe(settings => this.settings = settings);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  getTitle(value: string|number, key: string): string {
    return this.presetSettingsService.getTitle(value, key);
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  openSettings(): void {
    this.streamService.disablePreviews();
    this.dialog.open(PresetSettingsComponent, {data: {stream: this.stream, presetId: this.stream.presetId}, disableClose: true})
      .afterClosed()
      .pipe(
        delay(1500),
        takeUntil(this.alive)
      )
      .subscribe(() => this.streamService.enablePreviews());
  }

}
