<div class="playlist-player-content" (document:keydown.alt)="altSelected = true" (document:keyup.alt)="altSelected = false">

  <!-- Header -->
  <div class="list-header">
    <div class="timecodes">
      <!-- In Point -->
      <div class="detail-timecode border-left in-out-point">
        <div class="title" [class.selected]="playlistClip?.in && playlistClip?.in !== TimecodeNull">IN</div>
        <div #inPointTC
          class="timecode selecteble"
          popover
          [popoverContent]="inPointPopover"
          popoverPlacement="bottom"
          popoverTrigger="click"
          [popoverShowUntil]="popoverClose"
        >--:--:--:--</div>
        <div *ngIf="playlistClip?.in && playlistClip?.in !== TimecodeNull" class="button-close" (click)="removeInPoint()">
          <mat-icon svgIcon="close" class="icon"></mat-icon>
        </div>
      </div>
      <!-- Elapsed -->
      <div class="detail-timecode border-left">
        <div class="title">Elapsed</div>
        <div class="timecode" #elapsedTimecode>00:00:00:00</div>
      </div>
      <!-- TC -->
      <div class="clip-timecode" #timecode>00:00:00:00</div>
      <!-- Remain -->
      <div class="detail-timecode border-right">
        <div class="title">Remain</div>
        <div class="timecode" #remainTimecode>00:00:00:00</div>
      </div>
      <!-- Out Point -->
      <div class="detail-timecode border-right in-out-point">
        <div class="title" [class.selected]="playlistClip?.out && playlistClip?.out !== TimecodeNull">OUT</div>
        <div #outPointTC
          class="timecode selecteble"
          popover
          [popoverContent]="outPointPopover"
          popoverPlacement="bottom"
          popoverTrigger="click"
          [popoverShowUntil]="popoverClose"
        >--:--:--:--</div>
        <div *ngIf="playlistClip?.out && playlistClip?.out !== TimecodeNull" class="button-close" (click)="removeOutPoint()">
          <mat-icon svgIcon="close" class="icon"></mat-icon>
        </div>
      </div>
    </div>
    <!-- <div class="actions">
      <mat-icon svgIcon="settings-sliders" class="action-icon" [class.disabled]="false"></mat-icon>
    </div> -->
  </div>

  <!-- Player -->
  <div class="playlist-player">
    
    <!-- Preview -->
    <div class="player-preview" appRs422Scroll [appRs422ScrollActions]="playbackScrollActions">
      <app-stream
        [streamId]="stream?.id"
        (timecodeChange)="setTimecode($event)"
        (audioLevels)="setAudioLevel($event)"
      ></app-stream>
      <!-- Border -->
      <div *ngIf="false" class="border"></div>
    </div>

    <!-- Open Playlist -->
    <div class="preview-black-background" *ngIf="!(status?.playback?.openPlaylistName === playlist.name && status?.playback?.isLoaded)">
      <div class="open-playlist" (click)="playPlaylist()">
        <mat-icon svgIcon="play" class="icon play-icon"></mat-icon>
        <div class="title">Open Playlist</div>
      </div>
    </div>

    <!-- Playback progress -->
    <app-player-progress-bar
      *ngIf="clip"
      class="playback-progress"
      [stream]="stream"
      [clip]="clip"
      [inPoint]="playlistClip?.in"
      [outPoint]="playlistClip?.out"
      (jump)="jump($event)"
    ></app-player-progress-bar>

    <!-- Controls -->
    <div class="player-controls actions" [class.disabled]="!openCurrentPlaylist">
      <!-- TC -->
      <!-- <div class="actions-left">
      </div> -->

      <!-- Actions -->
      <div class="actions-center">
        <!-- Row 1 -->
        <div class="action" [class.selected]="playbackScrollMode === 'var'" (click)="changeScrollMode('var')">
          Var
        </div>
        <div class="action" [class.selected]="false" [class.disabled]="true">
          Preroll
        </div>
        <div class="action" [class.selected]="false" (click)="goToPrevAndPause()">
          <mat-icon svgIcon="playlist-prev-pause" class="icon"></mat-icon>
        </div>
        <div class="action" [class.selected]="false" (click)="goToStart()">
          <mat-icon svgIcon="forward-out-point" class="icon rotate"></mat-icon>
        </div>
        <div class="action" [class.selected]="status?.playback?.isRewind" (click)="rewind()">
          <mat-icon svgIcon="forward" class="icon rotate"></mat-icon>
        </div>
        <div class="action" [class.selected]="status?.playback?.isPlayingReverse" (click)="reverse()">
          <mat-icon svgIcon="play" class="icon rotate"></mat-icon>
        </div>
        <div class="action pause" [class.selected]="status?.playback?.isPaused" (click)="pause()">
          <mat-icon svgIcon="pause" class="icon"></mat-icon>
        </div>
        <div class="action" [class.selected]="status?.playback?.isPlayingForward" (click)="play()">
          <mat-icon svgIcon="play" class="icon"></mat-icon>
        </div>
        <div class="action" [class.selected]="status?.playback?.isFastForward" (click)="fastForward()">
          <mat-icon svgIcon="forward" class="icon"></mat-icon>
        </div>
        <div class="action" [class.selected]="false" (click)="goToEnd()">
          <mat-icon svgIcon="forward-out-point" class="icon"></mat-icon>
        </div>
        <div class="action" [class.selected]="false" (click)="goToNextAndPause()">
          <mat-icon svgIcon="playlist-next-pause" class="icon"></mat-icon>
        </div>
        <!-- Row 2 -->
        <div class="action" [class.selected]="playbackScrollMode === 'jog'" (click)="changeScrollMode('jog')">
          Jog
        </div>
        <div class="action" [class.selected]="playbackScrollMode === 'shuttle'" (click)="changeScrollMode('shuttle')">
          Shuttle
        </div>
        <div class="action" [class.selected]="false" (click)="goToPrevAndPlay()">
          <mat-icon svgIcon="playlist-prev-play" class="icon"></mat-icon>
        </div>
        <div *ngIf="!altSelected" class="action" [class.selected]="false" (click)="setInPoint()" matTooltip="I">
          <mat-icon svgIcon="out-point" class="icon rotate"></mat-icon>
        </div>
        <div *ngIf="altSelected" class="action" [class.selected]="false" (click)="removeInPoint()" [class.disabled]="!playlistClip?.in || playlistClip?.in === TimecodeNull" matTooltip="Ctrl + Shift + I">
          <mat-icon svgIcon="out-point" class="icon rotate strikethrough"></mat-icon>
        </div>
        <div class="action" [class.selected]="false" (click)="jump({offset: -10})">
          -10
        </div>
        <div class="action" [class.selected]="false" (click)="jump({offset: -1})">
          -1
        </div>
        <div
          class="action"
          [class.selected]="false"
          popover
          [popoverContent]="cueToPopover"
          popoverPlacement="top"
          popoverTrigger="click"
          [popoverShowUntil]="popoverClose"
        >
          Cue To
        </div>
        <div class="action" [class.selected]="false" (click)="jump({offset: 1})">
          +1
        </div>
        <div class="action" [class.selected]="false" (click)="jump({offset: 10})">
          +10
        </div>
        <div *ngIf="!altSelected" class="action" [class.selected]="false" (click)="setOutPoint()" matTooltip="O">
          <mat-icon svgIcon="out-point" class="icon" [class.strikethrough]="altSelected"></mat-icon>
        </div>
        <div *ngIf="altSelected" class="action" [class.selected]="false" (click)="removeOutPoint()" [class.disabled]="!playlistClip?.out || playlistClip?.out === TimecodeNull" matTooltip="Ctrl + Shift + O">
          <mat-icon svgIcon="out-point" class="icon strikethrough"></mat-icon>
        </div>
        <div class="action" [class.selected]="false" (click)="goToNextAndPlay()">
          <mat-icon svgIcon="playlist-next-play" class="icon"></mat-icon>
        </div>
      </div>

    </div>

  </div>

</div>

<!-- Popover Cue to -->
<ng-template #inPointPopover>
  <app-timecode-input-popover [clip]="clip" [timecode]="currentTimecode" [popoverClose]="popoverClose" buttonTitle="Set" (jump)="setInPoint($event)"></app-timecode-input-popover>
</ng-template>

<ng-template #outPointPopover>
  <app-timecode-input-popover [clip]="clip" [timecode]="currentTimecode" [popoverClose]="popoverClose" buttonTitle="Set" (jump)="setOutPoint($event)"></app-timecode-input-popover>
</ng-template>

<ng-template #cueToPopover>
  <app-timecode-input-popover [clip]="clip" [timecode]="currentTimecode" [popoverClose]="popoverClose" (jump)="jump($event)"></app-timecode-input-popover>
</ng-template>