import { Project } from '@modules/project/types/project';
import { Layout } from '@modules/layout/types/layout';
import { Deck } from '@modules/deck/types/deck';
import { Stream } from '@modules/stream/types/stream';
import { Preset } from '@modules/preset/types/preset';
import { Channel } from '@modules/channel/types/channel';
import { ChannelsGroup } from '@modules/channel/types/channels-group';
import { Volume } from '@modules/project/types/volume';
import { UsersList, DEFAULT_USERS } from '@modules/users/types/user';
import { Schedule } from '@modules/schedule/types/schedule';
import { ChannelsGrid } from '@modules/channel/types/channels-grid';
import { UserList } from '@modules/preset/types/user-list';

export class GlobalState {
  // Public
  public project?: Project;
  public layout?: Layout;
  public channels?: Channel[];
  public channelsList?: Channel[];
  public selectedChannelsIds?: string[];
  public fullscreenChannelId?: string;
  public channelsGroups?: ChannelsGroup[];
  public channelsGrid?: ChannelsGrid;
  public presets?: Preset[];
  public presetsFolder?: string;
  public decks?: Deck[];
  public streams?: Stream[];
  public selectedPresetId?: string;
  public removeNearSeparator?: boolean;
  public volume?: Volume;
  public users?: UsersList;
  public schedules?: Schedule[];
  public globalList?: UserList[];

  /**
   * Constructor
   */
  constructor(jsonObject: object = {}) {
    this.project = jsonObject['project'] ?? new Project();
    this.layout = jsonObject['layout'] ?? new Layout();
    this.channels = jsonObject['channels'] ?? [new Channel(), new Channel(), new Channel(), new Channel()];
    this.channelsList = jsonObject['channelsList'] ?? [];
    this.selectedChannelsIds = jsonObject['selectedChannelsIds'] ?? [];
    this.fullscreenChannelId = jsonObject['fullscreenChannelId'];
    this.channelsGroups = jsonObject['channelsGroups'] ?? [];
    this.channelsGrid = jsonObject['channelsGrid'] ?? new ChannelsGrid(2, 2);
    this.presets = jsonObject['presets'] ?? [Preset.emptyPreset('Default')];
    this.presetsFolder = jsonObject['presetsFolder'] ?? '{{home}}/Documents/Cinedeck Client/presets/';
    this.decks = jsonObject['decks'] ?? [];
    this.streams = jsonObject['streams'] ?? [];
    this.selectedPresetId = jsonObject['selectedPresetId'];
    this.removeNearSeparator = jsonObject['removeNearSeparator'] ?? true;
    this.volume = jsonObject['volume'] ?? new Volume();
    this.users = {};
    for (const userName of Object.keys(DEFAULT_USERS)) {
      const savedUser = (jsonObject['users'] && jsonObject['users'][userName]) || {};
      if (userName === 'guest') {
        this.users[userName] = DEFAULT_USERS[userName];
      } else {
        this.users[userName] = {
          name: DEFAULT_USERS[userName].name,
          displayName: DEFAULT_USERS[userName].displayName,
          password: savedUser.password ?? DEFAULT_USERS[userName].password,
          permissions: {
            ...DEFAULT_USERS[userName].permissions,
            ...(savedUser.permissions || {})
          }
        };
      }
    }
    this.schedules = jsonObject['schedules'] ?? [];
    this.globalList = jsonObject['globalList'] ?? UserList.defaultGlobalList();

  }
}
