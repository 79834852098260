<div
  class="playlist-item"
  [class.selected]="selected"
  [class.played]="played"
  [class.current-play]="currentPlay"
  [class.error]="!clip.name"
>
  <div class="clip-info">
    <div *ngIf="clip?.name" class="index">{{ index + 1 }}</div>
    <mat-icon *ngIf="!clip?.name" svgIcon="warning" class="icon icon-error" matTooltip="File not found"></mat-icon>
    <div class="thumbnail">
      <img [src]="thumbnailUrl + clip.id + '&random=' + noCache" alt="" class="image">
    </div>
    <div class="info">
      <div class="clip-info">
        <div
          class="name"
          [class.error]="!clip?.name"
          popover
          [popoverContent]="fileInfoPopover"
          popoverPlacement="top"
          popoverTrigger="click"
          [popoverOffsetY]="-5"
          [popoverShowUntil]="popoverClose"
        >
          {{ clip?.name || clip?.filename }}
        </div>
        <mat-icon svgIcon="dots-vertical" class="icon icon-settings" (click)="contextMenu($event)"></mat-icon>
      </div>

      <div class="timecodes">
        <!-- Start -->
        <div class="timecode">
          <div class="title">Start</div>
          <div class="value">{{ clip.playlistStartTimecode || '00:00:00:00' }}</div>
        </div>
        <!-- Duration -->
        <div class="timecode">
          <div class="title">Duration</div>
          <div class="value">{{ clip.duration || '00:00:00:00' }}</div>
        </div>
        <!-- In Point -->
        <div class="timecode" [class.disabled]="!clip.inTimecode" *ngIf="clip.inTimecode || clip.outTimecode">
          <div class="title">In Point</div>
          <div class="value" *ngIf="clip.inTimecode"> {{ clip.inTimecode }}</div>
          <div class="value" *ngIf="!clip.inTimecode"> --:--:--:-- </div>
        </div>
        <!-- Out Point -->
        <div class="timecode" [class.disabled]="!clip.outTimecode" *ngIf="clip.inTimecode || clip.outTimecode">
          <div class="title">Out Point</div>
          <div class="value" *ngIf="clip.outTimecode"> {{ clip.outTimecode }}</div>
          <div class="value" *ngIf="!clip.outTimecode"> --:--:--:-- </div>
        </div>
      </div>
    </div>
  </div>

  <div class="markers" *ngIf="marker">
    <mat-icon svgIcon="logger" class="icon"></mat-icon>
    <div class="text" *ngIf="marker?.comment?.length">{{ marker?.comment }}</div>
    <div class="placeholder" *ngIf="!marker?.comment?.length">Empty</div>
  </div>

  <!-- Drag Handler -->
  <div class="drag-handler">
    <ng-content></ng-content>
    <mat-icon svgIcon="drag-vertical" class="icon"></mat-icon>
  </div>

</div>


<!-- Popover File Info -->
<ng-template #fileInfoPopover>
  <app-playlist-clip-info [clip]="clip" (click)="popoverClose.next()"></app-playlist-clip-info>
</ng-template>