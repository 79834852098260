import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@modules/users/services/user.service';

// Components
import { PresetSettingsBaseComponent } from '../preset-settings-base/preset-settings-base.component';
import { PresetSettingsAudioRoutingComponent } from '../preset-settings-audio-routing/preset-settings-audio-routing.component';
import { PresetSettingsBurnsComponent } from '../preset-settings-burns/preset-settings-burns.component';
import { FileManagerComponent } from '@modules/file-manager/components/file-manager/file-manager.component';

// Types
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';
import { Option } from '@modules/elements/types/option';
import { DeckDrive } from '@modules/deck/types/deck-status';
import { Timecode } from '@modules/elements/types/timecode';

@Component({
  selector: 'app-preset-settings-encoder',
  templateUrl: './preset-settings-encoder.component.html',
  styleUrls: ['./preset-settings-encoder.component.less']
})
export class PresetSettingsEncoderComponent extends PresetSettingsBaseComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() encoderIndex = 0;

  // Public
  public audioChannels = [];
  public audioChannelsCountOption = new Option(0, '0');
  public audioChannelsCountOptions: Option<number>[] = [this.audioChannelsCountOption];
  public filtredSettingsOptions: PresetSettingsOptions;
  public drivesOptions: Option<string>[] = [];
  public dropFrame: boolean;
  public timebase: number;
  public isDefaultAudioChannelCountSetting = false;
  public inputInterlaced = false;
  public errors = {
    notSamePresetDiskPrimary: false,
    notSamePresetDiskSecondary: false,
  };

  // Private
  private drives: DeckDrive[];
  private requestSettingsOptions = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    protected presetSettingsService: PresetSettingsService,
    protected streamService: StreamService,
    private deckService: DeckService,
    private dialog: MatDialog,
    userService: UserService
  ) {
    super(presetSettingsService, userService);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if ('settingsOptions' in changes) {
      this.filtredSettingsOptions = JSON.parse(JSON.stringify(this.settingsOptions));
    }
    if ('stream' in changes || 'settingsOptions' in changes || 'settings') {
      this.updateSettingsOptions();
      this.updateAudioRouting();
    }
    this.disabled = this.checkDisabled();
  }

  ngOnDestroy(): void {
    this.requestSettingsOptions.next();
    this.requestSettingsOptions.complete();
    super.ngOnDestroy();
  }

  /**
   * Methods
   */

  protected checkDisabled(): boolean {
    return this.recording || !this.deckOnline || !this.allowedSettingsUpdate || !this.settings?.encode[this.encoderIndex]?.enabled || (this.preset && !this.preset.isValid());
  }

  updateSelect(key: string, value: number | string | boolean): void {
    super.updateSelect(key, value);
    this.updateSettingsOptions();
  }

  updateActiveAudioCodecH264(value: string): void {
    this.settings.encode[this.encoderIndex].activeAudioCodec = value;
    this.settings.encode[this.encoderIndex].h264DefaultAudioCodec = value;
    this.updateSettings();
  }

  updateSegment(value: string): void {
    if (this.encoderIndex === 0) {
      this.settings.encode.forEach((encode, index) => {
        if (this.settings.encode[index].segmentMode !== 'no') {
          this.settings.encode[index].segmentMode = value;
        }
      });
    }
    this.settings.encode[this.encoderIndex].segmentMode = value;
    this.updateSettings();
  }

  updateSettingsOptions(): void {
    this.requestSettingsOptions.next();

    // Segment Mode
    this.filtredSettingsOptions.encoderSegmentRecord = this.settingsOptions.encoderSegmentRecord.filter(item =>
      this.encoderIndex === 0 || item.value === 'no' || item.value === this.settings?.encode[0]?.segmentMode
    );

    // Codecs
    this.streamService.getSettingsEncoderCodecs(this.stream, this.encoderIndex, this.settings)
      .pipe(takeUntil(this.requestSettingsOptions))
      .subscribe(codecs => this.filtredSettingsOptions.encoderCodec = this.settingsOptions.encoderCodec.filter(
        item => codecs.includes(item.value))
      );

    // Wrapper
    this.streamService.getSettingsEncoderWrapper(this.stream, this.encoderIndex, this.settings)
      .pipe(takeUntil(this.requestSettingsOptions))
      .subscribe(wrappers => this.filtredSettingsOptions.encoderWrapper = this.settingsOptions.encoderWrapper.filter(
        item => wrappers.includes(item.value))
      );

    // Qualities
    this.streamService.getSettingsEncoderQualities(this.stream, this.encoderIndex, this.settings)
      .pipe(takeUntil(this.requestSettingsOptions))
      .subscribe(qualities => this.filtredSettingsOptions.encoderQuality = this.settingsOptions.encoderQuality.filter(
        item => qualities.includes(item.value))
      );

    // TC Sources
    this.streamService.getInputs(this.stream)
      .pipe(
        map(inputs => {
          const inputType = this.settings?.input?.activeVideoInput;
          return inputs[inputType]?.tcSources;
        }),
        takeUntil(this.requestSettingsOptions),
      )
      .subscribe(tcSources => this.filtredSettingsOptions.encoderTcSource = this.settingsOptions.encoderTcSource.filter(
        item => tcSources.includes(item.value))
      );

    // Frame Size Type
    this.filtredSettingsOptions.encoderFrameSizeType = this.settingsOptions.encoderFrameSizeType.filter(item => {
      if (item.value === 'letterbox') {
        item.title = this.getFrameSizeScaleNameSuffix() + ' Letterbox';
        return this.settings.encode[this.encoderIndex].frameSizeScale > 1 && this.encoderIndex !== 0 && this.settings.input.activeResolution !== 'uhd';
      }
      if (item.value === 'cropped') {
        item.title = this.getFrameSizeScaleNameSuffix() + ((this.encoderIndex !== 0 && this.settings.input.activeResolution !== 'uhd')? ' Cropped' : '');
        return this.settings.encode[this.encoderIndex].frameSizeScale > 1 && ((this.encoderIndex === 0 && this.settings.input.activeResolution === 'uhd') || this.encoderIndex !== 0);
      }
      if (item.value === 'normal') {
        item.title = this.settings.encode[this.encoderIndex].frameSizeScale + 'K';
        return this.settings.encode[this.encoderIndex].frameSizeScale > 1 && this.settings.input.activeResolution !== 'uhd';
      }
      return true;
    });

    // Get Deck info
    const deck = this.deckService.getDeckSync(this.stream?.deckId);
    // Drives
    this.deckService.getStatus(deck)
      .pipe(
        filter(status => !!status),
        map(status => status.drives),
        map(drives => {
          this.drives = drives;
          const drivesOptions = drives.map(drive => new Option(drive.position, (drive.driveLetter.toUpperCase() + ': ' + drive.label)));
          drivesOptions.splice(0, 0, new Option('', 'None'));
          drivesOptions.splice(1, 0, new Option('pathOverride', 'Path override'));
          return drivesOptions;
        }),
        distinctUntilChanged(isEqual),
        takeUntil(this.requestSettingsOptions),
      )
      .subscribe(drivesOptions => this.drivesOptions = drivesOptions);
    
    // Timebase & Drop Frame
    this.deckService.getStatus(deck)
      .pipe(
        filter(status => !!status),
        map(status => status.inputs[this.stream?.deckChannel]?.info),
        distinctUntilChanged(isEqual),
        takeUntil(this.requestSettingsOptions),
      )
      .subscribe(info => {
        this.dropFrame = info?.dropFrame;
        this.timebase =  info?.timebase;
      });
    
    // Write mode
    if (this.filtredSettingsOptions.writeMode.length > 3) {
      this.filtredSettingsOptions.writeMode.shift();
      this.filtredSettingsOptions.writeMode.pop();
    }

    // Interlaced
    this.inputInterlaced = Timecode.isInterlaced(+this.settings?.input?.activeFrameRate, this.settings?.input?.activeResolution);
  }

  updateAudioRouting(): void {
    const deckChannel = this.stream ? this.stream?.deckChannel : this.deckChannel;
    const inputType = this.settings?.input?.activeVideoInput;
    let maxAudioChannelCount = inputType === 'aes' ? 8 : inputType === 'sdi' ? 16 : 32; // aes - 8, sdi - 16, ndi - 32
    if (this.settings?.input?.activeAudioInput === 'asio') {
      maxAudioChannelCount = this.settings?.channels[deckChannel]?.audioRouting[this.encoderIndex]?.audioChannelLevels?.length || 32;
    }
    this.audioChannels = [];
    this.audioChannelsCountOptions = [new Option(0, '0')];
    const audioRoutingData = this.settings?.channels[deckChannel]?.audioRouting[this.encoderIndex]?.audioRoutingData;
    const defaultAudioRoutingData = this.preset.defaultSetting?.channels[0]?.audioRouting[this.encoderIndex]?.audioRoutingData;
    for (let index = 0; index < maxAudioChannelCount; index++) {
      let value = index + 1 + '';
      if (index < audioRoutingData?.length && audioRoutingData[index] != null) {
        const channel = audioRoutingData[index];
        switch (channel[0]) {
          case 'silent':
            value = 'S';
            break;
          case 'tone_generator':
            value = 'T';
            break;
          case 'digital':
            value = channel[1] + 1 + '';
            break;
          default:
            break;
        }
      }
      this.audioChannelsCountOptions.push(new Option(index + 1, index + 1 + ''));
      this.audioChannels.push({
        value,
        enabled: index < audioRoutingData?.length,
        overriden: index < audioRoutingData?.length && (!defaultAudioRoutingData || index >= defaultAudioRoutingData?.length ||
          !(audioRoutingData[index][0] === defaultAudioRoutingData[index][0] &&
            (audioRoutingData[index][0] !== 'silent'
            ? audioRoutingData[index][1] === defaultAudioRoutingData[index][1]
            : true)
          )),
        overridenCount: index < audioRoutingData?.length && index >= defaultAudioRoutingData?.length
      });
      }
    this.audioChannelsCountOption = new Option(audioRoutingData?.length ?? 0, (audioRoutingData?.length ?? 0) + '');
    this.isDefaultAudioChannelCountSetting = this.streamSettings ? audioRoutingData?.length === defaultAudioRoutingData?.length : true;
  }

  updateLoggerMarkersExportFile(value: string): void {
    this.settings.loggerSettings.exportFile = value; 
    this.settings.loggerSettings.enabled = value.length > 0; 
    this.updateSettings();
  }

  validateDisks(): void {
    this.errors.notSamePresetDiskPrimary = false;
    this.errors.notSamePresetDiskPrimary = false;
    if (this.stream && this.settings && this.drives) {
      const drivePositions = this.drives.map(drive => drive.position);
      // Primary
      const primaryPos = this.preset.defaultSetting?.channels[0]?.driveAssignments[this.encoderIndex]?.activePrimaryDrivePos;
      if (this.settings.sameDiskAsPreset && primaryPos?.length && !drivePositions.includes(primaryPos)) {
        this.errors.notSamePresetDiskPrimary = true;
      }
      // Secondary
      const secondaryPos = this.preset.defaultSetting?.channels[0]?.driveAssignments[this.encoderIndex]?.activeSecondaryDrivePos;
      if (this.settings.sameDiskAsPreset && secondaryPos?.length && !drivePositions.includes(secondaryPos)) {
        this.errors.notSamePresetDiskSecondary = true;
      }
    }
  }

  getFrameSizeScaleNameSuffix(): string {
    const scale = this.settings.encode[this.encoderIndex].frameSizeScale;
    switch (scale) {
      case 4:
        return 'Ultra HD';
      case 2:
        return 'HD';
      default:
        return '';
    }
  }

  /**
   * Actions
   */

  updateTcSource(value: string): void {
    this.settings.automation.tcSource = value;
    this.settings.tcSource = value;
    this.updateSettings();
  }

  openBurns(): void {
    this.dialog.open(PresetSettingsBurnsComponent, {data: {stream: this.stream, presetId: this.preset.id, encoderIndex: this.encoderIndex}, disableClose: true});
  }

  openAudioRouting(): void {
    this.dialog.open(PresetSettingsAudioRoutingComponent, {data: {stream: this.stream, presetId: this.preset.id, encoderIndex: this.encoderIndex}, disableClose: true});
  }

  selectAudioChannelCount(option: Option<number>): void {
    const count = option.value;
    const deckChannel = this.stream ? this.stream?.deckChannel : this.deckChannel;
    const audioRoutingData = this.settings?.channels[deckChannel]?.audioRouting[this.encoderIndex]?.audioRoutingData;
    if (audioRoutingData) {
      if (audioRoutingData.length > count) {
        const diff = audioRoutingData.length - count;
        audioRoutingData.splice(audioRoutingData.length - diff, diff);
      }
      if (audioRoutingData.length < count) {
        for (let index = audioRoutingData.length; index < count; index++) {
          audioRoutingData.push(['digital', index]);
        }
      }
      this.settings.channels[deckChannel].audioRouting[this.encoderIndex].audioRoutingData = audioRoutingData;
      this.updateSettings();
    }
  }

  selectWriteMode(value: string): void {
    this.settings.channels[this.deckChannel].driveAssignments[this.encoderIndex].activeWriteMode = value;
    this.updateSettings();
  }

  selectPrimaryDrive(option: Option<string>): void {
    if (option.value === 'pathOverride') {
      if (this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].primaryPath.length) {
        this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overridePrimaryPath = true;
      } else {
        this.selectPrimatyPathOverride();
      }
    } else {
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overridePrimaryPath = false;
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activePrimaryDrivePos = option.value;
    }
    this.updateDrives();
    this.updateSettings();
  }

  selectSecondaryDrive(option: Option<string>): void {
    if (option.value === 'pathOverride') {
      if (this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].secondaryPath.length) {
        this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overrideSecondaryPath = true;
      } else {
        this.selectSecondaryPathOverride();
      }
    } else {
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overrideSecondaryPath = false;
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activeSecondaryDrivePos = option.value;
    }
    this.updateDrives();
    this.updateSettings();
  }

  selectSameDiskAsPrimary(): void {
    this.updateDrives();
    this.updateSettings();
  }

  selectSameDiskForPreset(): void {
    if (this.settings.sameDiskAsPreset) {
      this.updatePresetDrives();
    }
    this.updateDrives();
    this.updateSettings();
  }

  updatePresetDrives(): void {
    for (const settingsId in this.preset.settings) {
      if (this.preset.settings[settingsId].sameDiskAsPreset) {
        this.preset.settings[settingsId]?.channels?.forEach((channel, channelIndex) => {
          this.preset.settings[settingsId]?.channels[channelIndex]?.driveAssignments?.forEach((drive, driveIndex) => {
            if (drive && driveIndex < this.preset.defaultSetting?.channels[0]?.driveAssignments?.length) {
              drive.activePrimaryDrivePos = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activePrimaryDrivePos || '';
              drive.activePrimaryOverrideDrivePos = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activePrimaryOverrideDrivePos || '';
              drive.activeSecondaryDrivePos = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activeSecondaryDrivePos || '';
              drive.activeSecondaryOverrideDrivePos = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activeSecondaryOverrideDrivePos || '';
              drive.overridePrimaryPath = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].overridePrimaryPath;
              drive.overrideSecondaryPath = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].overrideSecondaryPath;
              drive.primaryPath = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].primaryPath;
              drive.secondaryPath = this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].secondaryPath;
            }
          });
        });
      }
    }
    this.updatePresetSettings();
  }

  updateDrives(): void {
    const sameDiskAsPrimary = this.settings.encode[this.encoderIndex].sameDiskAsPrimary;
    const primaryEncoderDrive = this.settings?.channels[this.stream?.deckChannel]?.driveAssignments[0];
    if ((sameDiskAsPrimary || this.encoderIndex === 0) && primaryEncoderDrive) {
      this.settings?.channels[this.stream?.deckChannel]?.driveAssignments.forEach((drive, index) => {
        if (this.settings.encode[index].sameDiskAsPrimary) {
          drive.activePrimaryDrivePos = primaryEncoderDrive.activePrimaryDrivePos;
          drive.activeSecondaryDrivePos = primaryEncoderDrive.activeSecondaryDrivePos;
          drive.overridePrimaryPath = primaryEncoderDrive.overridePrimaryPath;
          drive.overrideSecondaryPath = primaryEncoderDrive.overrideSecondaryPath;
          drive.primaryPath = primaryEncoderDrive.primaryPath;
          drive.secondaryPath = primaryEncoderDrive.secondaryPath;
          drive.activePrimaryOverrideDrivePos = primaryEncoderDrive.activePrimaryOverrideDrivePos;
          drive.activeSecondaryOverrideDrivePos = primaryEncoderDrive.activeSecondaryOverrideDrivePos;
        }
      });
    }
    if (this.settings.sameDiskAsPreset) {
      this.preset.defaultSetting.channels[0].driveAssignments.forEach((drive, driveIndex) => {
        if (driveIndex < this.settings?.channels[this.stream?.deckChannel]?.driveAssignments.length) {
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activePrimaryDrivePos = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].activePrimaryDrivePos;
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activePrimaryOverrideDrivePos = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].activePrimaryOverrideDrivePos;
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activeSecondaryDrivePos = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].activeSecondaryDrivePos;
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].activeSecondaryOverrideDrivePos = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].activeSecondaryOverrideDrivePos;
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].overridePrimaryPath = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].overridePrimaryPath;
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].overrideSecondaryPath = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].overrideSecondaryPath;
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].primaryPath = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].primaryPath;
          this.preset.defaultSetting.channels[0].driveAssignments[driveIndex].secondaryPath = this.settings.channels[this.stream?.deckChannel].driveAssignments[driveIndex].secondaryPath;
        }
      });
      this.updatePresetDrives();
    }
  }

  selectPrimatyPathOverride(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true, path: this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].primaryPath}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overridePrimaryPath = true;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].primaryPath = folderPath;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activePrimaryOverrideDrivePos = this.presetSettingsService.getDriverPosition(folderPath, this.drives);
          this.updateDrives();
          this.updateSettings();
        }
      });
  }

  selectSecondaryPathOverride(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true, path: this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].secondaryPath}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overrideSecondaryPath = true;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].secondaryPath = folderPath;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activeSecondaryOverrideDrivePos = this.presetSettingsService.getDriverPosition(folderPath, this.drives);
          this.updateDrives();
          this.updateSettings();
        }
      });
  }

  selectXMLPathOverride(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true, path: this.settings.encode[this.encoderIndex].XMLPath}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.encode[this.encoderIndex].XMLPath = folderPath;
          this.updateSettings();
        }
      });
  }

  selectAAFPathOverride(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true, path: this.settings.encode[this.encoderIndex].AAFPath}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.encode[this.encoderIndex].AAFPath = folderPath;
          this.updateSettings();
        }
      });
  }

  selectCopyRecordedFilePath(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true, path: this.settings.encode[this.encoderIndex].copyRecordedFilePath}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.encode[this.encoderIndex].copyRecordedFilePath = folderPath;
          this.updateSettings();
        }
      });
  }

  selectLutPath(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: false, onlyFile: true, singleSelect: true, fileExt: ['lut', 'cube'], path: this.settings.channels[this.deckChannel].lutBurn[this.encoderIndex].file}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.channels[this.deckChannel].lutBurn[this.encoderIndex].file = folderPath;
          this.updateSettings();
        }
      });
  }

}
