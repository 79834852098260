import { Component, Input, OnInit } from '@angular/core';

// Types
import { Playlist } from '@modules/playlist-editor/types/playlist';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-playlist-timeline',
  templateUrl: './playlist-timeline.component.html',
  styleUrls: ['./playlist-timeline.component.less']
})
export class PlaylistTimelineComponent implements OnInit {

  // Inputs
  @Input() stream: Stream;
  @Input() playlist: Playlist;
  @Input() selected: string;

  // Private
  public rulerLine = new Array(1000);

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

}
