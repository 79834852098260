<div class="preset-name-template-content" [class.disabled]="disabled">
  <div *ngIf="title" class="title">{{ title }}</div>
  <div
    class="name-list"
    cdkDropList
    [cdkDropListData]="items"
    [cdkDropListDisabled]="!draggable"
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)"
    (cdkDropListEntered)="entered = true"
    (cdkDropListExited)="entered = false"
  >

    <!-- Item -->
    <div
      *ngFor="let item of items; let index = index"
      class="name-item"
      [style.backgroundColor]="item.color"
      tabindex="1"
      cdkDrag
      cdkDragLockAxis="x"
      [cdkDragDisabled]="!draggable"
      [contextMenu]="itemMenu"
      [contextMenuSubject]="index"
      (keydown.backspace)="deleteItem(index)"
      (keydown.delete)="deleteItem(index)"
    >{{ item.title }}</div>

    <!-- Placeholder -->
    <div class="name-placeholder" *ngIf="!items.length">{{ entered ? '' : 'Empty' }}</div>

  </div>
  <div *ngIf="showInput" class="name-template">
    <input class="input" [(ngModel)]="nameTemplate" (ngModelChange)="changeNameTemplate($event)" (blur)="changedNameTemplate($event)">
  </div>
  <div *ngIf="showSample" class="sample">{{ sample }}</div>
</div>

<!-- Context menu -->
<context-menu #itemMenu>
  <ng-template contextMenuItem let-item (execute)="deleteItem($event.item)">Delete</ng-template>
</context-menu>
