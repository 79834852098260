import { Component, Inject, OnInit } from '@angular/core';

// Components
import { FileManagerComponent } from '@modules/file-manager/components/file-manager/file-manager.component';

// Types
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-playlist-name-dialog',
  templateUrl: './playlist-name-dialog.component.html',
  styleUrls: ['./playlist-name-dialog.component.less']
})
export class PlaylistNameDialogComponent implements OnInit {

  // Public
  public fileName: string;
  public rename = false;
  public filePath: string;
  public stream: Stream;

  /**
   * Constructor
   */

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PlaylistNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {fileName: string, stream?: Stream, filePath?: string},
  ) { }

  /**
   * Component lifecycle
   */
 
  ngOnInit(): void {
    if (this.data?.fileName) {
      this.rename = true;
      this.fileName = this.data.fileName;
    }
    this.stream = this.data.stream;
    this.filePath = this.data.filePath;
  }

  /**
   * Actions
   */

  selectFilePath(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          this.filePath = result?.value[0];
        }
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  create(): void {
    if (!this.fileName?.length || !this.filePath?.length) { return; }
    this.dialogRef.close({fileName: this.fileName, filePath: this.filePath});
  }

}
