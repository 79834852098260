<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Decks</div>
    <div class="actions">
      <mat-icon svgIcon="plus" class="action-icon" (click)="addDeck()"></mat-icon>
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="deck-list-content">

    <div class="deck-list">
      <app-deck-item class="deck" *ngFor="let deck of decks" [deck]="deck"></app-deck-item>

      <!-- Placeholder -->
      <div class="placeholder" *ngIf="!decks?.length">Desk list empty</div>
    </div>

  </div>
</div>
