import { StreamSettingsNameTemplates } from "@modules/stream/types/stream-settings";

export class PresetNameTemplates {
  nameTemplates: StreamSettingsNameTemplates[];
  secondaryPathSame: boolean[];
  imageCaptureFilenameTemplate: string;
  sameTemplatesForAllEncoders: boolean;
  allowSpaces: boolean;

  constructor(data: {
    nameTemplates: StreamSettingsNameTemplates[],
    secondaryPathSame: boolean[],
    imageCaptureFilenameTemplate: string,
    sameTemplatesForAllEncoders: boolean,
    allowSpaces: boolean,
  }) {
    this.nameTemplates = data.nameTemplates;
    this.imageCaptureFilenameTemplate = data.imageCaptureFilenameTemplate;
    this.sameTemplatesForAllEncoders = data.sameTemplatesForAllEncoders;
    this.allowSpaces = data.allowSpaces;
    this.secondaryPathSame = data?.secondaryPathSame ?? PresetNameTemplates.default().secondaryPathSame;
  }

  static default(): PresetNameTemplates {
    return new PresetNameTemplates({
      nameTemplates: [
        PresetNameTemplates.defaultNameTemplate(),
        PresetNameTemplates.defaultNameTemplate(),
        PresetNameTemplates.defaultNameTemplate(),
        PresetNameTemplates.defaultNameTemplate(),
        PresetNameTemplates.defaultNameTemplate(),
        PresetNameTemplates.defaultNameTemplate(),
        PresetNameTemplates.defaultNameTemplate(),
        PresetNameTemplates.defaultNameTemplate()
      ],
      secondaryPathSame: [false, false, false, false, false, false, false, false],
      imageCaptureFilenameTemplate: '%P_%I_%C',
      sameTemplatesForAllEncoders: true,
      allowSpaces: true
    });
  }

  private static defaultNameTemplate(): StreamSettingsNameTemplates {
    return {
      fileName: '%P_%I_%E_%t',
      isPrimaryEdlSuffix: true,
      isPrimarySegSuffix: true,
      isSecondaryEdlSuffix: true,
      isSecondarySegSuffix: true,
      primaryEdlSuffix: '_edl',
      primaryPath: '\\%P\\%I\\%E',
      primarySegSuffix: '_seg',
      secondaryEdlSuffix: '_edl',
      secondaryPath: '\\%P\\%I\\%E',
      secondarySegSuffix: '_seg',
      tapeId: '%P_%I'
    };
  }
}
