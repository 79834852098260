import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Modules
import { PopoverModule } from '@modules/popover/popover.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { DeckModule } from '@modules/deck/deck.module';
import { StreamModule } from '@modules/stream/stream.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FileManagerModule } from '@modules/file-manager/file-manager.module';
import { Rs422Module } from '@modules/rs422/rs422.module';
import { PlaylistEditorModule } from '@modules/playlist-editor/playlist-editor.module';
import { MarkerModule } from '@modules/marker/marker.module';

// External modules
import { NgxMaskModule, IConfig } from 'ngx-mask'

// Components
import { AudioLevelsComponent } from '../channel/components/audio-levels/audio-levels.component';
import { ChannelsGridComponent } from './components/channels-grid/channels-grid.component';
import { ChannelsGroupsComponent } from './components/channels-groups/channels-groups.component';
import { ChannelsSettingsComponent } from './components/channels-settings/channels-settings.component';
import { ChannelsGridOptionsComponent } from './components/channels-grid-options/channels-grid-options.component';
import { ChannelComponent } from './components/channel/channel.component';
import { ChannelDisksComponent } from './components/channel-disks/channel-disks.component';
import { ChannelLayoutSettingsComponent } from './components/channel-layout-settings/channel-layout-settings.component';
import { ChannelErrorsDialogComponent } from './components/channel-errors-dialog/channel-errors-dialog.component';
import { ChannelTimecodeComponent } from './components/channel-timecode/channel-timecode.component';
import { EncoderRecordQueueComponent } from './components/encoder-record-queue/encoder-record-queue.component';
import { ChannelsListComponent } from './components/channels-list/channels-list.component';
import { ChannelsListItemComponent } from './components/channels-list-item/channels-list-item.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
    ElementsModule,
    DeckModule,
    StreamModule,
    DragNDropModule,
    FileManagerModule,
    Rs422Module,
    PlaylistEditorModule,
    MarkerModule,

    // External
    NgxMaskModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatGridListModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatMenuModule,
    DragDropModule,
  ],
  declarations: [
    AudioLevelsComponent,
    ChannelsGridComponent,
    ChannelsGroupsComponent,
    ChannelsSettingsComponent,
    ChannelsGridOptionsComponent,
    ChannelComponent,
    ChannelDisksComponent,
    ChannelLayoutSettingsComponent,
    ChannelErrorsDialogComponent,
    ChannelTimecodeComponent,
    EncoderRecordQueueComponent,
    ChannelsListComponent,
    ChannelsListItemComponent,
  ],
  exports: [
    AudioLevelsComponent,
    ChannelsGridComponent,
    ChannelsListComponent,
    ChannelsGroupsComponent,
    ChannelsSettingsComponent,
  ]
})
export class ChannelModule { }
