import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

// Modules
import { CoreModule } from '@modules/core/core.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { ChannelModule } from '@modules/channel/channel.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { PresetModule } from '@modules/preset/preset.module';
import { ProjectModule } from '@modules/project/project.module';
import { SettingsModule } from '@modules/settings/settings.module';
import { StreamModule } from '@modules/stream/stream.module';
import { ScheduleModule } from '@modules/schedule/schedule.module';
import { UsersModule } from '@modules/users/users.module';
import { PlaylistEditorModule } from '@modules/playlist-editor/playlist-editor.module';

// Components
import { LayoutComponent } from './components/layout/layout.component';
import { LeftPanelComponent } from './components/left-panel/left-panel.component';
import { RightPanelComponent } from './components/right-panel/right-panel.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { CenterPanelComponent } from './components/center-panel/center-panel.component';



@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ElementsModule,
    ChannelModule,
    PopoverModule,
    PresetModule,
    ProjectModule,
    SettingsModule,
    StreamModule,
    ScheduleModule,
    UsersModule,
    PlaylistEditorModule,

    // Material
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [
    LayoutComponent,
    LeftPanelComponent,
    RightPanelComponent,
    ToolbarComponent,
    CenterPanelComponent
  ],
  exports: [
    LayoutComponent,
    LeftPanelComponent,
    RightPanelComponent
  ],
})
export class LayoutModule { }
