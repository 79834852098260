import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-disc-progress',
  templateUrl: './disc-progress.component.html',
  styleUrls: ['./disc-progress.component.less']
})
export class DiscProgressComponent implements OnInit {

  // Inputs
  @Input() total: number;  // Bytes
  @Input() free: number;   // Bytes
  @Input() redSize = 10737418240; // 10Gb in bytes
  @Input() yellowPercentProgress = 0.20;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

}
