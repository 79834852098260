<div class="channels-grid-options-content">
  <div class="grid-options">
    <div
      *ngFor="let option of options"
      class="option"
      [class.selected]="isSelected(option)"
      (click)="selectOption(option)"
    >
      <div class="grid-rect">
        <mat-grid-list class="grid-list" [cols]="option?.column" rowHeight="fit" class="grid-list">
          <mat-grid-tile class="grid-item" *ngFor="let size of option.size" [colspan]="size.cols" [rowspan]="size.rows"></mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</div>
