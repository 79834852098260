import {
  Directive, Input, OnDestroy, Output, EventEmitter, OnInit, Renderer2, ElementRef, NgZone, TemplateRef, OnChanges, SimpleChanges
} from '@angular/core';

// Services
import { DragNDropService } from '../services/drag-n-drop.service';

// RxJS
import { EMPTY, Observable, Subject } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';

// Types
import { DragData } from '../types/drag-data';
import { DraggableEvent } from '../types/draggable-event';

@Directive({
  selector: '[appDroppable]',
})
export class DroppableDirective implements OnInit, OnDestroy, OnChanges {

  // Private
  private alive: Subject<void> = new Subject();
  private isDragging = false;
  private uniqueID: symbol;
  private dragData: DragData;
  private afterDropShowUntil: Subject<void> = new Subject();

  // Inputs
  @Input() appDroppableHoverClass: string;
  @Input() appDroppablePredicate: (data: DragData) => boolean;
  @Input() appDroppableZIndex = 0;
  @Input() appDroppableAfterDropTemplate: TemplateRef<any>;
  @Input() appDroppableShowUntil: Observable<void>;

  // Outputs
  @Output() appDroppableDrop = new EventEmitter<DraggableEvent>();
  @Output() appDroppableEnter = new EventEmitter<DraggableEvent>();
  @Output() appDroppableLeave = new EventEmitter<DraggableEvent>();
  @Output() appDroppableOver = new EventEmitter<DraggableEvent>();

  /**
   * Constructor
   */

  constructor(
    private dragNDropService: DragNDropService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private ngZone: NgZone,
  ) {
    this.uniqueID = Symbol();
    this.appDroppablePredicate = () => true;
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.dragNDropService.getDraggingDataChanges()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((data: DragData) => {
        this.dragData = data;
        this.isDragging = !!data;

        if (this.isDragging) {
          this.dragNDropService.registerDroppableArea(
            this.uniqueID,
            this.elementRef.nativeElement,
            this.appDroppableZIndex,
            this.appDroppableAfterDropTemplate
          );
        } else {
          this.dragNDropService.unregisterDroppableArea(this.uniqueID);
        }
      });

    this.dragNDropService.getDragEnter(this.uniqueID)
      .pipe(
        filter(() => this.appDroppablePredicate(this.dragData)),
        takeUntil(this.alive)
      )
      .subscribe((event: DraggableEvent) => {
        this.ngZone.run(() => {
          this.renderer.addClass(this.elementRef.nativeElement, this.appDroppableHoverClass);
          if (this.dragData) {
            event.dragData = this.dragData;
            this.appDroppableEnter.emit(event);
          }
        });
      });

    this.dragNDropService.getDragLeave(this.uniqueID)
      .pipe(
        filter(() => this.appDroppablePredicate(this.dragData)),
        takeUntil(this.alive)
      )
      .subscribe((event: DraggableEvent) => {
        this.ngZone.run(() => {
          this.renderer.removeClass(this.elementRef.nativeElement, this.appDroppableHoverClass);
          if (this.dragData) {
            event.dragData = this.dragData;
            this.appDroppableLeave.emit(event);
          }
        });
      });

    this.dragNDropService.getDragOver(this.uniqueID)
      .pipe(
        filter(() => this.appDroppablePredicate(this.dragData)),
        takeUntil(this.alive)
      )
      .subscribe((event: DraggableEvent) => {
        event.dragData = this.dragData;
        this.appDroppableOver.emit(event);
      });

    this.dragNDropService.getDrop(this.uniqueID)
      .pipe(
        filter(() => this.appDroppablePredicate(this.dragData)),
        takeUntil(this.alive)
      )
      .subscribe((event: DraggableEvent) => {
        event.dragData = this.dragData;
        this.appDroppableDrop.emit(event);
      });

    this.afterDropShowUntil
      .pipe(
        switchMap(() => this.appDroppableShowUntil || EMPTY),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.dragNDropService.setDragging(null);
      });
    this.afterDropShowUntil.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('appDroppableShowUntil' in changes) {
      this.afterDropShowUntil.next();
    }
  }

  ngOnDestroy(): void {
    this.dragNDropService.unregisterDroppableArea(this.uniqueID);
    this.alive.next();
    this.alive.complete();
  }
}
