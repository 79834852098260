import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

// Types
import { Clip } from '@modules/deck/types/clip';
import { Timecode } from '@modules/elements/types/timecode';

@Component({
  selector: 'app-timecode-input-popover',
  templateUrl: './timecode-input-popover.component.html',
  styleUrls: ['./timecode-input-popover.component.less']
})
export class TimecodeInputPopoverComponent implements OnInit, AfterViewInit, OnChanges {

  // ViewChild
  @ViewChild('timecodeInput') timecodeInputRef: ElementRef;

  // Input
  @Input() clip: Clip;
  @Input() timecode: string;  // Timecode string expected as HH:MM:SS:FF or HH:MM:SS;FF or HH:MM:SS.SSSS
  @Input() placeholder = '00:00:00:00';
  @Input() popoverClose: Subject<void>;
  @Input() irig = false;
  @Input() buttonTitle = 'Cue to';

  // Output
  @Output() jump = new EventEmitter<{tc?: string, frame?: number, offset?: number}>();

  // Public
  public error: Error;
  public mask = '00:00:00:00';
  public editing = false;

  // Private
  private tcRegex = /^(\d\d):(\d\d):(\d\d)(:|;|\.)(\d{2,4})$/;
  private setInitValue = false;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.timecodeInputRef.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('clip' in changes) {
      this.updatePlaceholderAndMask();
    }
  }

  /**
   * Methods
   */

  jumpTC(timecode: string): void {
    if (!timecode?.length || this.error) {
      return;
    }
    const tc = new Timecode(timecode, this.clip.timebase, !!this.clip.drop);
    let frame = tc.frameCount - this.clip.startTimecodeFrames;
    frame = Math.min(Math.max(frame, 0), (this.clip.durationFrames - 1));
    this.jump.emit({ frame });
  }

  setValue(timecode: string): void {
    if (!this.setInitValue) {
      this.timecode = timecode;
      this.setInitValue = true;
    }
  }

  validate(value: string): boolean {
    const result = this.tcRegex.exec(value);
    if (result === null) {
      this.error = new Error('Invalid Timecode');
      this.error.name = 'invalid';
      return false;
    }
    if (result) {
      try {
        const timecode = new Timecode(value, this.clip.timebase, !!this.clip.drop);
      } catch (error) {
        this.error = error;
        this.error.name = 'cant_parse';
        return false;
      }
    }
    this.error = null;
    return true;
  }

  updatePlaceholderAndMask(): void {
    if (this.irig) {
      this.placeholder = '00:00:00.0000';
    } else if (this.clip?.drop > 0) {
      this.placeholder = '00:00:00;00';
    } else {
      this.placeholder = '00:00:00:00';
    }
    this.mask = this.placeholder;
  }

  nextFrame(): void {
    if (!this.validate(this.timecode)) { return; }
    let timecode = new Timecode(this.timecode, this.clip.timebase, !!this.clip.drop);
    timecode = timecode.add(1);
    this.timecode = timecode.toString();
    this.jumpTC(this.timecode);
  }
  
  prevFrame(): void {
    if (!this.validate(this.timecode)) { return; }
    let timecode = new Timecode(this.timecode, this.clip.timebase, !!this.clip.drop);
    timecode = timecode.add(-1);
    this.timecode = timecode.toString();
    this.jumpTC(this.timecode);
  }

  /**
   * Actions
   */

  save(): void {
    if (!this.timecode?.length || this.error) {
      return;
    }
    this.jumpTC(this.timecode);
    this.popoverClose?.next();
  }

  close(): void {
    this.popoverClose?.next();
  }

}
