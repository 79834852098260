import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Timecode } from '@modules/elements/types/timecode';

@Component({
  selector: 'app-input-timecode',
  templateUrl: './input-timecode.component.html',
  styleUrls: ['./input-timecode.component.less']
})
export class InputTimecodeComponent implements OnInit, OnChanges {

  // Inputs
  @Input() value: number | string;
  @Input() dropFrame: boolean = false;
  @Input() fps: number = 24;
  @Input() resolution: string;
  @Input() negative: boolean = false;

  // Output
  @Output() valueChange = new EventEmitter<number | string>();
  @Output() blur = new EventEmitter<number | string>();
  @Output() negativeChange = new EventEmitter<boolean>();

  // Public
  public inputValue: string;
  public error = false;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes || 'dropFrame' in changes || 'fps' in changes || 'negative' in changes) {
      this.updateValue();
    }
  }

  /**
   * Methods
   */

  updateValue(): void {
    try {
      let fps = (this.fps !== undefined && this.fps !== null) ? +this.fps : 30;
      let dropFrame = this.dropFrame;
      if (dropFrame == null) {
        dropFrame = Timecode.isDropFrame(fps);
      }
      if (this.resolution) {
        const interlaced = Timecode.isInterlaced(fps, this.resolution);
        fps = Timecode.getTimebase(fps, interlaced);
      }
      const timecode = new Timecode(this.value, fps, dropFrame);
      this.inputValue = this.negative ? '-' + timecode.toString() : timecode.toString();
      this.error = false;
    } catch (e) {
      this.error = true;
    }
  }

  /**
   * Actions
   */

  updateInputValue(value: string): void {
    try {
      this.inputValue = value;
      const negative = value.startsWith('-');
      let fps = +this.fps;
      let dropFrame = this.dropFrame;
      if (dropFrame == null) {
        dropFrame = Timecode.isDropFrame(fps);
      }
      if (this.resolution) {
        const interlaced = Timecode.isInterlaced(fps, this.resolution);
        fps = Timecode.getTimebase(fps, interlaced);
      }
      const timecode = new Timecode(negative ? value.substring(1) : value, fps, dropFrame);
      this.error = false;
      if (typeof this.value == 'string') {
        this.value = timecode.toString();
      } else {
        this.value = timecode.frameCount;
      }

      this.negative = negative;
      this.valueChange.emit(this.value);
      this.negativeChange.emit(negative);
    } catch (e) {
      this.error = true;
    }
  }

  blueInputValue(): void {
    this.blur.emit(this.value);
  }

}
