<div class="preset-record-settings-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Record Settings{{stream ? '- ' + stream.name : ''}}</div>
    <div class="actions">
      <mat-icon svgIcon="settings-sliders" class="action-icon" (click)="openSettings()" [class.disabled]="!stream"></mat-icon>
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" (click)="lock()" [class.disabled]="!stream || !allowed"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon" [class.disabled]="!stream"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content" *ngIf="stream">

    <!-- Input -->
    <div class="settings-section" [class.disabled]="!allowed || locked">
      <div class="settings-header">Input Settings - {{ stream?.name }}</div>
      <div class="settings-content">

        <!-- Options -->
        <div class="settings-options">

          <!-- Resolution -->
          <div class="option input-option">
            <div class="title">Resolution</div>
            <div class="value">{{ getTitle(settings?.input.activeResolution, 'resolutions') }}</div>
          </div>

          <!-- Frame Rate -->
          <div class="option input-option">
            <div class="title">Frame Rate</div>
            <div class="value">{{ getTitle(settings?.input.activeFrameRate, 'framerates') }}</div>
          </div>

          <!-- Color Format -->
          <div class="option input-option">
            <div class="title">Color Format</div>
            <div class="value">{{ getTitle(settings?.input.activePixelFormat, 'colorSpaces') }}</div>
          </div>

          <!-- Range -->
          <div class="option input-option">
            <div class="title">Range</div>
            <div class="value" *ngIf="!(settings?.input?.activePixelFormat === 'rgb10' || settings?.input?.activePixelFormat === 'rgb12')">
              N/A
            </div>
            <div class="value" *ngIf="settings?.input?.activePixelFormat === 'rgb10' || settings?.input?.activePixelFormat === 'rgb12'">
              {{ getTitle(settings?.channels[stream?.deckChannel]?.inputRange, 'rgbRange') }}
            </div>
          </div>

          <div class="break"></div>

          <!-- Input Type -->
          <div class="option input-option">
            <div class="title">Input Type</div>
            <div class="value">{{ getTitle(settings?.input.activeVideoInput, 'inputTypes') }}</div>
          </div>

          <!-- Input Source -->
          <div class="option input-option">
            <div class="title">Input Source</div>
            <div class="value">BNC{{ stream?.deckChannel + 1}}</div>
          </div>

          <!-- Audio Source -->
          <div class="option input-option">
            <div class="title">Audio Source</div>
            <div class="value">{{ getTitle(settings?.input.activeAudioInput, 'audioSources') }}</div>
          </div>

          <!-- Ref Source -->
          <div class="option input-option">
            <div class="title">Ref Source</div>
            <div class="value">{{ getTitle(settings?.input.activeSyncSource, 'referenceSources') }}</div>
          </div>

          <!-- Frame Drop -->
          <div class="option input-option">
            <div class="title">Frame Drop</div>
            <div class="value">{{ getTitle(settings?.input.frameDropBehavior, 'frameDropBehaviours') }}</div>
          </div>

          <!-- Input Loss -->
          <div class="option input-option">
            <div class="title">Input Loss</div>
            <div class="value">{{ getTitle(settings?.input.signalLossBehavior, 'inputLossBehaviours') }}</div>
          </div>

          <!-- Video Delay -->
          <div class="option input-option">
            <div class="title">Video Delay</div>
            <div class="value">Off</div>
          </div>

          <!-- Audio Delay -->
          <div class="option input-option">
            <div class="title">Audio Delay</div>
            <div class="value">Off</div>
          </div>

        </div>

      </div>
    </div>

    <!-- Output -->
    <div class="settings-section" [class.disabled]="!allowed || locked">
      <div class="settings-header">Output settings - {{ stream?.name }}</div>
      <div class="settings-content">

        <!-- Options -->
        <div class="settings-options">

          <!-- Playout Upconvert to Input Resolution -->
          <div class="option output-option">
            <div class="title">Playout Upconvert to Input Resolution</div>
            <div class="value">{{ getTitle(settings?.channels[stream?.deckChannel]?.upconvertMode, 'outputUpconverts') }}</div>
          </div>

          <!-- RGB Output Transport -->
          <div class="option output-option">
            <div class="title">RGB Output Transport</div>
            <div class="value">{{ getTitle(settings?.channels[stream?.deckChannel]?._444output, 'm444Outputs') }}</div>
          </div>

          <!-- Override Aspect Ratio -->
          <div class="option output-option">
            <div class="title">Override Aspect Ratio</div>
            <div class="value">{{ getTitle(settings?.input.aspectRatioString, 'aspectRatioOverrides') }}</div>
          </div>

        </div>

      </div>
    </div>

    <!-- Encoders -->
    <div class="settings-section" [class.disabled]="!allowed || locked || !encoder?.enabled" *ngFor="let encoder of settings?.encode; let index = index">
      <div class="settings-header">{{ encoder?.profile }} Encoder Settings{{ encoder?.enabled ? '' : ' (Disabled)' }} - {{ stream?.name }}</div>
      <div class="settings-content">
        <app-preset-record-encoder-settings [stream]="stream" [settings]="settings" [encoderIndex]="index"></app-preset-record-encoder-settings>
      </div>
    </div>

  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!stream">
    <div class="text">Select a <b>Channel</b> first to start working with <b>Record Settings</b></div>
  </div>

</div>
