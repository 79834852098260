import { publishReplay } from 'rxjs/operators';
import { ConnectableObservable, Observable } from 'rxjs';

export function warmUpObservable(target: object, key: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor {
  const origin = descriptor.value;

  descriptor.value = function <T>(...args: any[]): Observable<T> {
    const hotObservable = publishReplay<T>(1)(origin.apply(this, args)) as ConnectableObservable<T>;
    hotObservable.connect();
    return hotObservable as Observable<T>;
  };

  return descriptor;
}
