<div class="player-progress-bar" *ngIf="clip">
  <!-- Progress -->
  <div
    #progress
    class="progress-bar"
    (click)="selectProgress($event)"
    (mouseenter)="showHoverPosition = true"
    (mouseleave)="showHoverPosition = false"
    (mousemove)="hoverProgress($event)"
  >
    <!-- In/Out point progress -->
    <div #clipProgress *ngIf="inPoint || outPoint" class="clip-progress-bar"></div>
    <!-- In/Out Points -->
    <div #inPointPosition class="in-point" *ngIf="inPoint"></div>
    <div #outPointPosition class="out-point" *ngIf="outPoint"></div>
    <!-- Hover -->
    <div #hoverPosition class="hover-position" *ngIf="showHoverPosition && !hoverCurrentPosition && !hoverMarker && !dragCurrentPosition"></div>
    <div #hoverTC class="hover-timecode" *ngIf="(showHoverPosition && !hoverCurrentPosition && !hoverMarker) || dragCurrentPosition">00:00:00:00</div>
    <!-- Current position -->
    <div
      #currentPosition
      class="progress-position"
      [class.drag]="dragCurrentPosition"
      (mouseenter)="hoverCurrentPosition = true"
      (mouseleave)="hoverCurrentPosition = false"
      (appMoveElementStart)="dragStartPlaybackPosition($event)"
      (appMoveElement)="dragMovePlaybackPosition($event)"
      (appMoveElementEnd)="dragEndPlaybackPosition($event)"
    ></div>
    <ng-container *ngIf="showMarkers">
      <app-marker-progress-bar-item
        (mouseenter)="hoverMarker = true"
        (mouseleave)="hoverMarker = false"
        *ngFor="let marker of markers"
        [clip]="clip"
        [marker]="marker"
        (click)="jumpToMarker(marker, $event)"
      ></app-marker-progress-bar-item>
    </ng-container>
  </div>
  <!-- TC -->
  <div class="start-timecode" *ngIf="clip?.startTimecode" (click)="jumpTo(0)">{{ clip?.startTimecode }}</div>
  <div class="end-timecode" *ngIf="clip?.endTimecode" (click)="jumpTo(clip.durationFrames - 1)">{{ clip?.endTimecode }}</div>
</div>
