<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">{{settings?.encode[encoderIndex]?.profile}} Audio Routing</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="preset-settings-audio-routing-content">

    <div class="audio-routing" *ngIf="settings">
      <!-- Labels -->
      <div class="labels">
        <div class="title">File Channel</div>
        <div class="title">Input Channel</div>
      </div>
      <!-- Channels -->
      <div class="channels">
          <div class="channel" *ngFor="let channel of audioRoutingData; let index = index">
            <div class="file-channel">{{ index + 1 }}</div>
            <app-select class="audio-select" [options]="audioChannelOptions" [selected]="getSelectedOption(channel)" (selectedChange)="selectOption($event, index)"></app-select>
          </div>
      </div>
    </div>

  </div>
</div>
