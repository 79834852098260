<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Preset Settings</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="preset-settings-content">

    <!-- Change Folder -->
    <div class="change-folder">
      <div class="option">
        <div class="label">Presets Folder</div>
        <div class="input" (click)="selectFolder()" [title]="presetsFolder">{{ presetsFolder }}</div>
        <div class="button" (click)="selectFolder()">Select</div>
      </div>
    </div>

  </div>
</div>
