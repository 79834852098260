import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-item-popover',
  templateUrl: './create-item-popover.component.html',
  styleUrls: ['./create-item-popover.component.less']
})
export class CreateItemPopoverComponent implements OnInit, AfterViewInit {

  // Input
  @Input() name: string;
  @Input() emptyName: boolean = false;
  @Input() placeholder = 'Enter name...';
  @Input() popoverClose: Subject<void>;
  @Input() excludeNames: string[] = [];

  // Output
  @Output() readonly created = new EventEmitter<string>();

  // ViewChild
  @ViewChild('nameInput') nameInput: ElementRef;

  // Public
  public error: Error;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.nameInput.nativeElement.focus();
  }

  /**
   * Actions
   */

  validate(value: string): void {
    this.error = this.excludeNames.map(name => name.toLowerCase()).includes(value.toLowerCase()) ? new Error('Name already exists') : null;
  }

  /**
   * Actions
   */

  save(): void {
    if (!this.emptyName && !this.name?.length || this.error) {
      return;
    }
    this.created.emit(this.name);
    this.popoverClose?.next();
  }

  close(): void {
    this.popoverClose?.next();
  }

}
