import { Component, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ElectronService } from 'ngx-electron';
import { StateService } from '@modules/settings/services/state.service';
import { NodeService } from '@modules/node/services/node.service';

// Types
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-preset-change-folder',
  templateUrl: './preset-change-folder.component.html',
  styleUrls: ['./preset-change-folder.component.less']
})
export class PresetChangeFolderComponent implements OnInit {

  // Public
  public presetsFolder: string;

  // Private
  private alive = new Subject();
  private openFolderDialog = false;

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<PresetChangeFolderComponent>,
    private electronService: ElectronService,
    private stateService: StateService,
    private nodeService: NodeService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.stateService.getState()
      .pipe(
        map(state => state.presetsFolder.replace('{{home}}', this.nodeService.os.homedir())),
        takeUntil(this.alive)
      )
      .subscribe(presetsFolder => this.presetsFolder = presetsFolder);
  }

  /**
   * Methods
   */

  selectFolder() {
    if (!this.openFolderDialog) {
      this.openFolderDialog = true;
      const currentWindow = this.electronService.remote.getCurrentWindow();
      this.electronService.remote.dialog
        .showOpenDialog(currentWindow, { defaultPath: this.presetsFolder, properties: ['openDirectory']})
        .then(data => {
          this.openFolderDialog = false;
          if (!data.filePaths.length) {
            return;
          }
          this.stateService.updateState({ presetsFolder: data.filePaths[0] });
        })
        .catch(() => {
          this.openFolderDialog = false;
        });
    }
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }
}
