<div class="tag-list-content">

  <!-- Header -->
  <div class="list-header" [style.backgroundColor]="list.color + '80'">
    <app-input-inline class="title" placeholder="Type here..." [value]="list.name" (valueChange)="changeListName($event)"></app-input-inline>
    <div class="actions">
      <mat-icon svgIcon="minus" class="action-icon" (click)="deleteTag(selected)"></mat-icon>
      <mat-icon svgIcon="plus" class="action-icon" #addIcon (click)="showCreatePopover(createPopover, addIcon)"></mat-icon>
      <mat-icon *ngIf="showSettings" class="action-icon" [matMenuTriggerFor]="menu">more_vert</mat-icon>
    </div>
  </div>

  <!-- Menu -->
  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item (click)="exportList()">Export</button>
    <button mat-menu-item *ngIf="!isWeb" (click)="importList()">Import</button>
    <label mat-menu-item *ngIf="isWeb" for="importFile">Import</label>
    <input *ngIf="isWeb" type="file" id="importFile" (change)="importListFile($event)" hidden>
    <div class="mat-divider"></div>
    <button mat-menu-item (click)="deleteAllTags()">Remove all</button>
  </mat-menu>

  <!-- Content -->
  <div class="list-content" *ngIf="list?.items?.length">
    <div
      *ngFor="let item of list.items; trackBy: itemIds"
      class="tag"
      [class.editing]="item.id === editing?.id"
      [class.selected]="item.id === selected?.id"
      [style.backgroundColor]="item.id === selected?.id ? list.color : '#252525'"
      [contextMenu]="inputMenu"
      [contextMenuSubject]="item"
      (appClick)="selectTag(item)"
    >
      <app-input-inline
        class="tag-input-inline"
        placeholder="Empty"
        [value]="item.name"
        [editing]="item.id === editing?.id"
        (valueChange)="changeTagName($event, item)"
        (editingChange)="tagEditingChange($event, item)"
      ></app-input-inline>
    </div>
  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!list?.items?.length">
    <div class="text">Empty</div>
  </div>

</div>

<!-- Popover Create -->
<ng-template #createPopover>
  <app-create-item-popover [emptyName]="true" [popoverClose]="popoverClose" [excludeNames]="itemNames" (created)="createTag($event)"></app-create-item-popover>
</ng-template>

<!-- Context menu -->
<context-menu #inputMenu>
  <ng-template contextMenuItem let-item [visible]="isTagSelected" (execute)="selectTag($event.item)">Select</ng-template>
  <ng-template contextMenuItem let-item (execute)="renameTag($event.item)">Rename</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem let-item (execute)="deleteTag($event.item)">Delete</ng-template>
</context-menu>
