import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { ChannelService } from '@modules/channel/services/channel.service';

// Types
import { ChannelsGrid } from '@modules/channel/types/channels-grid';

@Component({
  selector: 'app-channels-grid-options',
  templateUrl: './channels-grid-options.component.html',
  styleUrls: ['./channels-grid-options.component.less']
})
export class ChannelsGridOptionsComponent implements OnInit, OnDestroy {

  // Outputs
  @Output() valueChanged = new EventEmitter<ChannelsGrid>();

  // Public
  public channelsGrid: ChannelsGrid;
  public options = ChannelsGrid.options();

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private channelService: ChannelService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.channelService.getChannelsGrid()
      .pipe(takeUntil(this.alive))
      .subscribe(channelsGrid => this.channelsGrid = channelsGrid);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  isSelected(option: ChannelsGrid): boolean {
    return isEqual(JSON.stringify(option), JSON.stringify(this.channelsGrid));
  }

  /**
   * Action
   */

  selectOption(option: ChannelsGrid): void {
    if (this.isSelected(option)) {
      return;
    }
    this.valueChanged.emit(option);
    this.channelService.setChannelsGrid(option);
  }

}
