<div class="player-progress-bar" *ngIf="playlist">
  <!-- Progress -->
  <div
    #progress
    class="progress-bar"
    (click)="selectProgress($event)"
    (mouseenter)="showHoverPosition = true"
    (mouseleave)="showHoverPosition = false"
    (mousemove)="hoverProgress($event)"
  >
    <!-- Clips -->
    <div #clipsPositions *ngFor="let clip of clips" class="clip-position"></div>
    <!-- In/Out point progress -->
    <div #clipProgress *ngIf="inPoint || outPoint" class="clip-progress-bar"></div>
    <!-- In/Out Points -->
    <!-- <div #inPointPosition class="in-point" *ngIf="inPoint"></div>
    <div #outPointPosition class="out-point" *ngIf="outPoint"></div> -->
    <!-- Hover -->
    <div #hoverPosition class="hover-position" *ngIf="showHoverPosition && !hoverCurrentPosition && !dragCurrentPosition"></div>
    <div #hoverTC class="hover-timecode" *ngIf="(showHoverPosition && !hoverCurrentPosition) || dragCurrentPosition">00:00:00:00</div>
    <!-- Current position -->
    <div
      #currentPosition
      class="progress-position"
      [class.drag]="dragCurrentPosition"
      (mouseenter)="hoverCurrentPosition = true"
      (mouseleave)="hoverCurrentPosition = false"
      (appMoveElementStart)="dragStartPlaybackPosition($event)"
      (appMoveElement)="dragMovePlaybackPosition($event)"
      (appMoveElementEnd)="dragEndPlaybackPosition($event)"
    ></div>
  </div>
  <!-- TC -->
  <div class="start-timecode" (click)="jumpTo(0)">{{ startTimecode }}</div>
  <div class="end-timecode" (click)="jumpTo(playlist?.duration - 1)">{{ endTimecode }}</div>
</div>
