<div class="toolbar-content">

  <!-- Presets -->
  <div class="toolbar-button" [class.selected]="layout.presets" (click)="toggleView('presets')">
    <mat-icon svgIcon="settings-sliders" class="icon rotate-90"></mat-icon>
    <div class="text">Presets</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- User Lists -->
  <div class="toolbar-button" [class.selected]="layout.userLists" (click)="toggleView('userLists')">
    <mat-icon svgIcon="toolbar-list" class="icon"></mat-icon>
    <div class="text">Local Lists</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Global Lists -->
  <div class="toolbar-button" [class.selected]="layout.globalLists" (click)="toggleView('globalLists')">
    <mat-icon svgIcon="toolbar-list" class="icon"></mat-icon>
    <div class="text">Global Lists</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- XML tags -->
  <!-- <div class="toolbar-button" [class.selected]="layout.xmlTags" (click)="toggleView('xmlTags')">
    <mat-icon svgIcon="toolbar-tags" class="icon"></mat-icon>
    <div class="text">Tags</div>
  </div>
  <div class="toolbar-divider"></div> -->

  <!-- Groups -->
  <div class="toolbar-button" [class.selected]="layout.inputGroups" (click)="toggleView('inputGroups')">
    <mat-icon svgIcon="toolbar-attachment" class="icon"></mat-icon>
    <div class="text">Groups</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Group Control -->
  <div class="toolbar-button" [class.selected]="layout.recordControl" (click)="toggleView('recordControl')">
    <mat-icon svgIcon="toolbar-control" class="icon"></mat-icon>
    <div class="text">Group Control</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Scheduling -->
  <div class="toolbar-button" [class.selected]="layout.schedule" (click)="toggleView('schedule')">
    <mat-icon svgIcon="toolbar-schedule" class="icon"></mat-icon>
    <div class="text">Scheduling</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Meters -->
  <div class="toolbar-button" [class.selected]="layout.meters" (click)="toggleView('meters')">
    <mat-icon svgIcon="toolbar-meters" class="icon"></mat-icon>
    <div class="text">Overlays</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Channel Status -->
  <!-- <div class="toolbar-button" [class.selected]="layout.channelStatus" (click)="toggleView('channelStatus')">
    <mat-icon svgIcon="toolbar-project" class="icon"></mat-icon>
    <div class="text">Channel Status</div>
  </div>
  <div class="toolbar-divider"></div> -->

  <!-- Space -->
  <div class="toolbar-space"></div>

  <!-- Volume -->
  <div
    class="toolbar-button"
    [class.selected]="layout.volume"
    popover
    [popoverContent]="volumePopover"
    popoverPlacement="bottom"
    popoverTrigger="click"
    [popoverOffsetY]="-5"
  >
    <mat-icon svgIcon="toolbar-volume" class="icon"></mat-icon>
    <div class="text">Volume</div>
  </div>

  <!-- Space -->
  <div class="toolbar-space"></div>
  <div class="toolbar-divider"></div>

  <!-- Help -->
  <div class="toolbar-button" [class.selected]="true" (click)="openHelp()">
    <mat-icon svgIcon="toolbar-search" class="icon"></mat-icon>
    <div class="text">Help</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Manual -->
  <div class="toolbar-button" [class.selected]="true" (click)="openManual()">
    <mat-icon svgIcon="toolbar-book" class="icon"></mat-icon>
    <div class="text">Manual</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Users -->
  <div
    class="toolbar-button"
    [class.selected]="layout.users"
    popover
    [popoverContent]="userPopover"
    popoverPlacement="bottom"
    popoverTrigger="click"
    [popoverOffsetY]="-5"
  >
    <mat-icon svgIcon="toolbar-user" class="icon"></mat-icon>
    <div class="text">{{ user?.displayName }}</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Playlist -->
  <div class="toolbar-button" [class.selected]="layout.playlist" (click)="toggleView('playlist')">
    <mat-icon svgIcon="toolbar-list" class="icon"></mat-icon>
    <div class="text">Playlist</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Inputs -->
  <div class="toolbar-button" [class.selected]="layout.inputs" (click)="toggleView('inputs')">
    <mat-icon svgIcon="toolbar-input" class="icon"></mat-icon>
    <div class="text">Inputs</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Templates -->
  <div class="toolbar-button" [class.selected]="layout.templates" (click)="toggleView('templates')">
    <mat-icon svgIcon="toolbar-template" class="icon"></mat-icon>
    <div class="text">Templates</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Record Settings -->
  <div class="toolbar-button" [class.selected]="layout.recordSettings" (click)="toggleView('recordSettings')">
    <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
    <div class="text">Record Settings</div>
  </div>
  <div class="toolbar-divider"></div>

  <!-- Settings -->
  <div class="toolbar-button" [class.selected]="true" (click)="openProjectSettings()">
    <mat-icon svgIcon="settings" class="icon"></mat-icon>
  </div>

</div>


<!-- Volume Popover -->
<ng-template #volumePopover>
  <app-volume-popover></app-volume-popover>
</ng-template>

<!-- User Popover -->
<ng-template #userPopover>
  <div class="user-popover">
    <div
      class="toolbar-button option"
      (click)="openUserPermissions()"
      *ngIf="user?.permissions?.changePassowrd || user?.name === 'root' || user?.name === 'admin'"
    >User Settings</div>
    <div class="toolbar-button option" (click)="logout()">Logout</div>
  </div>
</ng-template>
