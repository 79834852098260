<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Playlist file name</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="playlist-name-dialog-content">

    <div class="playlist-name-input">
      <!-- Input -->
      <input class="input" placeholder="Enter playlist name..." [(ngModel)]="fileName" (keydown.enter)="create()" (keydown.esc)="close()">
      <!-- Folder path button -->
      <div class="button">
        <mat-icon svgIcon="folder" class="icon" (click)="selectFilePath()"></mat-icon>
      </div>
    </div>

    <!-- Folder path -->
    <div *ngIf="filePath" class="folder-path">
      <div class="title">Folder: </div>
      <div class="value">{{ filePath }}</div>
    </div>

  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="create()" [class.disabled]="!fileName?.length || !filePath?.length">{{ rename ? 'Save' : 'Create' }}</div>
  <div class="button" (click)="close()">Cancel</div>
</div>
