import { Injectable } from '@angular/core';

// RxJS
import { BehaviorSubject, combineLatest, iif, interval, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, delay, delayWhen, distinctUntilChanged, filter, map, mergeMap, retryWhen, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';

// Lodash
import { isEqual, cloneDeep, has } from 'lodash-es';

// Decorators
import { warmUpObservable } from '@decorators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { NetworkService } from '@modules/core/services/network.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { NotificationService } from '@modules/elements/services/notification.service';
import { AlertService } from '@modules/elements/services/alert.service';

// Types
import { Stream } from '../types/stream';
import { GlobalState } from '@modules/settings/types/global-state';
import { StreamSettings, StreamSettingsEditshare } from '../types/stream-settings';
import { StreamStatus, StreamStatusPlaybackLoop } from '../types/stream-status';
import { Deck } from '@modules/deck/types/deck';
import { Clip } from '@modules/deck/types/clip';
import { StreamInputs } from '../types/stream-inputs';
import { DeckStatus } from '@modules/deck/types/deck-status';
import { DeskEmulation } from '@modules/preset/types/desk-emulation';
import { RecordingFilename } from '../types/recording-filename';
import { Preset } from '@modules/preset/types/preset';
import { Timecode } from '@modules/elements/types/timecode';
import { EncoderRecordQueue } from '@modules/stream/types/encoder-record-queue';
import { DeckError } from '@modules/deck/types/deck-error';
import { ChannelError } from '@modules/channel/types/channel-error';
import { CharOutStatus } from '../types/charout-status';
import { EncoderInfo } from '../types/encoder-info';
import { StreamOnlineStatus } from '@modules/stream/types/stream-online-status';

// JSON
import SettingsParams from '../../../../assets/settings/settings-param.json';
import { StreamTimecode } from '../types/stream-timecode';


@Injectable({
  providedIn: 'root'
})
export class StreamService {

  // Public
  public gangRecordToleranceTime = 3; // 3 seconds
  public streamSettingsParams = SettingsParams;
  public previewsDisabled = new BehaviorSubject<boolean>(false);

  // Private
  private streams: Observable<Stream[]>;
  private streamsValue: Stream[];
  private domParser = new DOMParser();
  private errors = new Subject<DeckError>();
  private needReloadStreamSettings = new Subject<string>();
  private cleanErrors = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private networkService: NetworkService,
    private deckService: DeckService,
    private presetService: PresetService,
    private channelService: ChannelService,
    private notificationService: NotificationService,
    private alertService: AlertService,
  ) {
    // Get Streams
    this.streams = this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.streams)
      );
    this.streams.subscribe(streams => this.streamsValue = streams);

    // Update settings
    this.channelService.getChannels()
      .pipe(
        map(channels => channels.filter(channel => channel.streamId).map(channel => channel.streamId)),
        distinctUntilChanged(),
        switchMap(streamIds => combineLatest(streamIds.map(id => this.getStream(id)))),
        switchMap(streams => merge(
          ...streams.map(stream =>
            merge(
              this.needReloadStreamSettings.asObservable().pipe(filter(streamId => stream?.id === streamId)),
              this.projectUpdated(stream)
            ).pipe(map(_ => (stream)))
          )
        )),
        mergeMap(stream => this.getSettings(stream).pipe(map(settings => ({stream, settings})))),
        filter(({stream, settings}) => !!stream && !!stream?.presetId && !!settings)
      )
      .subscribe(({stream, settings}) => this.presetService.updateStreamSettings(stream.presetId, stream.id, settings));

    // Update selected preset in select channel
    this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.selectedChannelsIds),
        filter(selectedChannelsIds => selectedChannelsIds && selectedChannelsIds?.length === 1),
        map(selectedChannelsIds => selectedChannelsIds[0]),
        distinctUntilChanged(isEqual),
        debounceTime(100),
        switchMap(channelId => this.channelService.getChannel(channelId).pipe(take(1))),
        map(channel => this.streamsValue.find(stream => stream.id === channel?.streamId)),
        filter(stream => !!stream),
        map(stream => this.presetService.getPresetSync(stream.presetId)),
        filter((preset: Preset) => !!preset),
      )
      .subscribe((preset: Preset) => this.presetService.selectPreset(preset));

    // Update settings when deck come Online
    this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.decks),
        distinctUntilChanged(isEqual),
        // Notify when deck change to Online
        switchMap((decks: Deck[]) => 
          merge(
            ...decks.map(deck => this.deckService.getOnlineStatus(deck).pipe(filter(isOnline => isOnline), map(isOnline => deck)))
          )
        ),
        // Get Streams in channels
        switchMap(deck => 
          this.getStreams()
            .pipe(
              switchMap(streams => this.channelService.getChannels().pipe(map(channels => ({channels, streams})), take(1))),
              map(({channels, streams}) => streams.filter(stream => stream.deckId === deck.id && channels.some(channel => channel.streamId === stream.id))),
              take(1)
            )
        ),
        // Wait first start when presets not read yet
        delayWhen(() => this.presetService.getPresets().pipe(filter(presets => presets.length > 0), delay(300)))
      )
      .subscribe(streams => {
        // Update settings for streams
        for (const stream of streams) {
          const preset = this.presetService.getPresetSync(stream.presetId);
          const settings = preset?.getSetting(stream?.id);
          if (stream && settings) {
            this.updateSettings(stream, settings);
          }
        }
      });
  }

  /**
   * Status
   */

  getStatus(stream: Stream): Observable<StreamStatus> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.getStatus(deck)
      .pipe(
        map((status: DeckStatus) => status?.inputs[stream?.deckChannel]),
      );
  }

  getOnlineStatus(stream: Stream): Observable<boolean> {
    if (!stream || !stream.deckId) {
      return of(false);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.getOnlineStatus(deck);
  }

  getInputOnlineStatus(stream: Stream): Observable<StreamOnlineStatus> {
    if (!stream || !stream.deckId) {
      return of('offline');
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return combineLatest([this.deckService.getChannelsSubject(deck), this.deckService.getOnlineStatus(deck)])
      .pipe(
        distinctUntilChanged(isEqual),
        map(([channels, isOnline]) => {
          if (!isOnline) {
            return 'offline';
          }
          if (channels && channels?.length && !!channels?.find(channel => channel?.index === stream?.deckChannel)) {
            return 'online';
          }
          return 'unavailable';
        })
      );
  }

  isOnline(stream: Stream): boolean {
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.isOnline(deck);
  }

  isInputOnline(stream: Stream): boolean {
    const deck = this.deckService.getDeckSync(stream.deckId);
    const isOnline = this.deckService.isOnline(deck);
    const channels = this.deckService.getChannelsSubjectSync(deck);
    return isOnline && (channels && channels?.length && !!channels?.find(channel => channel?.index === stream?.deckChannel));
  }

  /**
   * Streams
   */

  getStreams(): Observable<Stream[]> {
    return this.streams;
  }

  getStream(id: string): Observable<Stream> {
    return this.getStreams()
      .pipe(
        map(streams => streams.find(stream => stream.id === id))
      );
  }

  addStream(stream: Stream): void {
    const streams = this.streamsValue;
    streams.push(stream);
    this.stateService.updateState({streams});
  }

  updateStream(stream: Stream): void {
    const streams = this.streamsValue;
    const index = streams.findIndex(item => item.id === stream.id);
    if (index !== -1) {
      streams[index] = stream;
      this.stateService.updateState({streams});
    }
  }

  deleteStream(stream: Stream): void {
    const streams = this.streamsValue;
    const index = streams.indexOf(stream);
    if (index !== -1) {
      streams.splice(index, 1);
      this.stateService.updateState({streams});
    }
  }

  deleteStreamsForDeck(deckId: string): void {
    const channels = this.channelService.getAllChannelsSync();
    const streams = this.streamsValue.filter(stream => {
      if (stream.deckId === deckId) {
        channels.forEach(channel => {
          if (channel.streamId === stream.id) {
            const updatedChannel = cloneDeep(channel);
            this.channelService.updateChannel(updatedChannel);
          }
        });
      }
      return stream.deckId !== deckId;
    });
    this.stateService.updateState({streams});
  }

  /**
   * WebSocket Events
   */

  getTimecode(stream: Stream): Observable<StreamTimecode> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.getTimecodes(deck)
      .pipe(
        filter(timecodes => !!timecodes),
        map(timecodes => timecodes[stream?.deckChannel]),
      );
  }

  getCurrentTimecode(stream: Stream): StreamTimecode {
    if (!stream) {
      return null;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    const timecodes = this.deckService.getCurrentTimecodes(deck);
    return timecodes && timecodes[stream?.deckChannel];
  }

  projectUpdated(stream: Stream): Observable<void> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.projectUpdated(deck)
      .pipe(
        filter(({input}) => input === stream.deckChannel),
        map(_ => null)
      );
  }

  ndiInputsUpdated(stream: Stream): Observable<void> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.ndiInputsUpdated(deck)
      .pipe(
        filter(({input}) => input === stream.deckChannel),
        map(_ => null)
      );
  }

  inputSettingsUpdated(stream: Stream): Observable<void> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.inputSettingsUpdated(deck)
      .pipe(
        filter(({input}) => input === stream.deckChannel),
        map(_ => null)
      );
  }

  segmentBreak(stream: Stream): Observable<void> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.segmentBreak(deck)
      .pipe(
        filter(({input}) => input === stream.deckChannel),
        map(_ => null)
      );
  }

  recordQueueUpdated(stream: Stream): Observable<EncoderRecordQueue[]> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.recordQueueUpdated(deck)
      .pipe(
        map(data => data[stream.deckChannel].encoders),
      );
  }

  filenameChanged(stream: Stream): Observable<boolean> {
    if (!stream) {
      return of(false);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.filenameChanged(deck)
      .pipe(
        filter(({input}) => input === stream.deckChannel),
        map(_ => true)
      );
  }

  error(stream: Stream): Observable<ChannelError> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return merge(
      this.deckService.error(deck),
      this.errors
    )
      .pipe(
        filter(error => error.input === stream.deckChannel),
        filter(error => {
          return !error?.message?.toLowerCase()?.includes('frame replay');
        }),
        map(error => new ChannelError(error))
      );
  }

  public addError(error: DeckError): void {
    this.errors.next(error);
  }

  /**
   * Preset
   */

  setPreset(presetId: string, streamId: string, behaviorAlert: 'always' | 'server' | 'client' = 'always'): void {
    // Get and check Preset & Stream
    const stream = this.streamsValue.find(item => item.id === streamId);
    const preset = this.presetService.getPresetSync(presetId);
    if (!stream || !preset) { return; }

    // Add stream to Preset settings if needed
    if (!has(preset.settings, stream.id)) {
      of(behaviorAlert)
        .pipe(
          switchMap(behaviorAlert => {
            if (behaviorAlert !== 'always') {
              return of(behaviorAlert === 'client' ? 0 : 1);
            }
            return this.alertService.show(
              `Assign new settings to Channel (${stream?.deckChannelName})`,
              'Do you want to force overwrite settings on the server?',
              ['Use Server', 'Use Preset'],
              0
            );
          })
        )
        .subscribe(response => {
          // Use Preset
          if (response === 1) {
            console.log('[PRESET][Use Preset]');
            preset.settings[stream.id] = {};
            this.presetService.updatePreset(preset);
            this.setPreset(presetId, streamId);
          // Use Server
          } else if (response === 0) {
            console.log('[PRESET][Use Server]');
            this.getSettings(stream).subscribe(settings => {
              preset.settings[stream.id] = {};
              this.presetService.updatePreset(preset);
              stream.presetId = presetId;
              this.updateStream(stream);
              this.presetService.updateStreamSettings(presetId, streamId, settings)
            });
          }
        });
      return;
    }

    // Assign Preset.id to Stream
    if (stream.presetId !== presetId) {
      stream.presetId = presetId;
      this.updateStream(stream);
    }
    // Update Stream Settings
    const settings = preset?.getSetting(streamId);
    this.presetService.rewriteSettingNameTemplate(settings, presetId);
    this.updateSettings(stream, settings)
      .subscribe(() => {
        stream.presetId = presetId;
        this.updateStream(stream);
      });
  }

  /**
   * Inputs
   */

  getInputs(stream: Stream): Observable<StreamInputs> {
    if (!stream || !this.isOnline(stream)) {
      return of(StreamInputs.default());
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getChannelInputs(Deck.getAddress(deck), stream.deckChannel);
  }

  disablePreviews(): void {
    this.previewsDisabled.next(true);
  }

  enablePreviews(): void {
    this.previewsDisabled.next(false);
  }

  /**
   * Settings
   */

  reloadSettings(streamId: string): void {
    this.needReloadStreamSettings.next(streamId);
  }

  getSettings(stream: Stream): Observable<StreamSettings> {
    if (!stream || !this.isOnline(stream)) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getSettings(Deck.getAddress(deck), stream.deckChannel);
  }

  @warmUpObservable
  updateSettings(stream: Stream, settings: StreamSettings): Observable<void> {
    if (!this.isInputOnline(stream)) {
      return of();
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.fixBreakSettingsField(settings);
    return this.networkService.updateSettings(Deck.getAddress(deck), stream.deckChannel, settings)
      .pipe(
        retryWhen(error => error.pipe(
          concatMap((e, i) => 
            iif(() => i > 1, throwError(e),
              this.getSettings(stream)
                .pipe(
                  tap(serverSettings => this.presetService.updateStreamSettings(stream.presetId, stream.id, serverSettings))
                )
            )
          )
        )),
        catchError(error => {
          if (error) {
            this.notificationService.warning(`'${stream?.deckChannelName}' update settings error: ` + error?.error?.message, `${deck.name} (${deck.address})`);
          }
          return of(null);
        })
      );
  }

  autoDectectSignal(stream: Stream): Observable<void> {
    if (!stream || !this.isOnline(stream)) {
      return of();
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.autoDectectSignal(Deck.getAddress(deck), stream.deckChannel);
  }

  refreshInput(stream: Stream): Observable<void> {
    if (!stream || !this.isOnline(stream)) {
      return of();
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.refreshInput(Deck.getAddress(deck), stream.deckChannel);
  }

  getSettingsInputFps(stream: Stream, settings: StreamSettings): Observable<string[]> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      const fps = this.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution];
      return of(Object.keys(fps));
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    const resolution = settings.input.activeResolution;
    const videoInput = settings.input.activeVideoInput;
    return this.networkService.getSettingsInputFps(Deck.getAddress(deck), stream.deckChannel, resolution, videoInput);
  }

  getSettingsEncoderCodecs(stream: Stream, encoderIndex: number, settings: StreamSettings): Observable<string[]> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      const codecs = this.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution][settings.input.activeFrameRate][settings.input.activePixelFormat];
      return of(Object.keys(codecs ?? {}));
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getSettingsEncoderCodecs(Deck.getAddress(deck), stream.deckChannel, encoderIndex);
  }

  getSettingsEncoderWrapper(stream: Stream, encoderIndex: number, settings: StreamSettings): Observable<string[]> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      const qualities = this.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution][settings.input.activeFrameRate][settings.input.activePixelFormat][settings.encode[encoderIndex].activeCodec];
      const qualitiesKeys = Object.keys(qualities ?? {});
      const wrappers = (qualitiesKeys.length === 1 && qualitiesKeys[0] === 'UNSUPPORTED')
        ? qualities[qualitiesKeys[0]]
        : qualities[settings.encode[encoderIndex].activeQuality];
      return of(Object.keys(wrappers ?? {}));
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    const codec = settings.encode[encoderIndex].activeCodec;
    const quality = settings.encode[encoderIndex].activeQuality;
    return this.networkService.getSettingsEncoderWrapper(Deck.getAddress(deck), stream.deckChannel, encoderIndex, codec, quality);
  }

  getSettingsEncoderQualities(stream: Stream, encoderIndex: number, settings: StreamSettings): Observable<string[]> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      const qualities = this.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution][settings.input.activeFrameRate][settings.input.activePixelFormat][settings.encode[encoderIndex].activeCodec];
      return of(Object.keys(qualities ?? {}));
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    const codec = settings.encode[encoderIndex].activeCodec;
    return this.networkService.getSettingsEncoderQualities(Deck.getAddress(deck), stream.deckChannel, encoderIndex, codec);
  }

  getSettingsRecordControlTCSources(stream: Stream): Observable<string[]> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      return of([]);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getSettingsRecordControlTCSources(Deck.getAddress(deck), stream.deckChannel);
  }

  getSettingsLtcSources(stream: Stream): Observable<number[]> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      return of([]);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getSettingsLtcSources(Deck.getAddress(deck), stream.deckChannel);
  }

  getSettingsAudioOutputRoutingChannelsCount(stream: Stream): Observable<{playout: number, playthrough: number}> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      return of({playout: 0, playthrough: 0});
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getSettingsAudioOutputRoutingChannelsCount(Deck.getAddress(deck), stream.deckChannel);
  }

  getSettingsDeskEmulation(stream: Stream): Observable<DeskEmulation[]> {
    if (!stream || !stream?.deckId || !this.isOnline(stream)) {
      return of([]);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getSettingsDeskEmulation(Deck.getAddress(deck), stream.deckChannel);
  }

  getRecordingFilenames(stream: Stream): Observable<RecordingFilename[]> {
    if (!stream || !this.isOnline(stream)) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getRecordingFilenames(Deck.getAddress(deck), stream.deckChannel);
  }

  getEncodersInfo(stream: Stream): Observable<EncoderInfo[]> {
    if (!stream || !this.isOnline(stream)) {
      return of([]);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getEncodersInfo(Deck.getAddress(deck), stream.deckChannel);
  }

  getCharOut(stream: Stream): Observable<CharOutStatus> {
    if (!stream || !this.isOnline(stream)) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.getCharOut(Deck.getAddress(deck), stream.deckChannel);
  }

  @warmUpObservable
  updateCharOut(stream: Stream, charOut: CharOutStatus): Observable<any> {
    if (!stream || !this.isOnline(stream)) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.updateCharOut(Deck.getAddress(deck), stream.deckChannel, charOut);
  }

  /**
   * Playback
   */

  play(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.play(Deck.getAddress(deck), stream.deckChannel);
  }

  reverse(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.reverse(Deck.getAddress(deck), stream.deckChannel);
  }

  fastForward(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.fastForward(Deck.getAddress(deck), stream.deckChannel);
  }

  rewind(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.rewind(Deck.getAddress(deck), stream.deckChannel);
  }

  pause(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.pause(Deck.getAddress(deck), stream.deckChannel);
  }

  goToStart(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToStart(Deck.getAddress(deck), stream.deckChannel);
  }

  goToEnd(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToEnd(Deck.getAddress(deck), stream.deckChannel);
  }

  setInPoint(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.setInPoint(Deck.getAddress(deck), stream.deckChannel);
  }

  setOutPoint(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.setOutPoint(Deck.getAddress(deck), stream.deckChannel);
  }

  jump(stream: Stream, value: {tc?: string, frame?: number, offset?: number}): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.jump(Deck.getAddress(deck), stream.deckChannel, value);
  }

  goToNextClip(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToNextClip(Deck.getAddress(deck), stream.deckChannel);
  }

  goToPrevClip(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToPrevClip(Deck.getAddress(deck), stream.deckChannel);
  }

  hideStopOverlay(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.hideStopOverlay(Deck.getAddress(deck), stream.deckChannel);
  }

  loopPlayback(stream: Stream, mode: StreamStatusPlaybackLoop): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.loopPlayback(Deck.getAddress(deck), stream.deckChannel, mode);
  }

  goToNextAndPause(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToNextAndPause(Deck.getAddress(deck), stream.deckChannel);
  }

  goToNextAndPlay(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToNextAndPlay(Deck.getAddress(deck), stream.deckChannel);
  }

  goToPrevAndPause(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToPrevAndPause(Deck.getAddress(deck), stream.deckChannel);
  }

  goToPrevAndPlay(stream: Stream): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToPrevAndPlay(Deck.getAddress(deck), stream.deckChannel);
  }

  goToClipByIndexAndPause(stream: Stream, clipIndex: number): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.goToClipByIndexAndPause(Deck.getAddress(deck), stream.deckChannel, clipIndex);
  }

  /**
   * Playback group (gang)
   */

  getGangStreamsSync(stream: Stream): Stream[] {
    const streamChannel = this.channelService.getChannelsSync().find((value) => value?.streamId === stream.id);
    if (streamChannel && !streamChannel.streamSettings.gang) {
      return [stream];
    }
    const channels = this.channelService.getChannelsSync().filter(channel => {
      return channel?.streamSettings?.gang && (this.streamsValue.find(value => value.id === channel.streamId))?.deckId === stream.deckId;
    });
    return channels.map(channel => this.streamsValue.find(value => value.id === channel.streamId)).filter(value => !!value);
  }

  getGangStreams(stream: Stream): Observable<Stream[]> {
    if (!stream) {
      return of([]);
    }
    return combineLatest([
      this.streams,
      this.channelService.getChannels()
        .pipe(
          map(channels => channels.filter(channel => channel?.streamSettings?.gang))
        )
    ])
      .pipe(
        map(([streams, channels]) => {          
          const gangChannels = channels.filter(channel => streams.find(value => value.id === channel.streamId)?.deckId === stream?.deckId);
          return gangChannels.map(channel => streams.find(value => value.id === channel.streamId)).filter(value => !!value);
        })
      );
  }

  private isSameDeck(streams: Stream[]): boolean {
    if (!streams?.length) {
      return false;
    }
    return streams.every((value, _, array) => array[0].deckId === value.deckId);
  }

  gangPlay(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangPlay(Deck.getAddress(deck), channels);
  }

  gangReverse(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangReverse(Deck.getAddress(deck), channels);
  }

  gangPause(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangPause(Deck.getAddress(deck), channels);
  }

  gangFastForward(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangFastForward(Deck.getAddress(deck), channels);
  }

  gangRewind(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangRewind(Deck.getAddress(deck), channels);
  }

  gangGoToStart(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangGoToStart(Deck.getAddress(deck), channels);
  }

  gangGoToEnd(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangGoToEnd(Deck.getAddress(deck), channels);
  }

  gangSetInPoint(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangSetInPoint(Deck.getAddress(deck), channels);
  }

  gangSetOutPoint(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangSetOutPoint(Deck.getAddress(deck), channels);
  }

  @warmUpObservable
  gangJump(streams: Stream[], value: {tc?: string, frame?: number, offset?: number}): Observable<void> {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    return this.networkService.gangJump(Deck.getAddress(deck), channels, value)
      .pipe(
        catchError(error => {
          if (error) {
            this.notificationService.warning(`Cue to: ` + error?.error?.message, `${deck.name} (${deck.address})`);
          }
          return of(null);
        })
      );
  }

  gangGoToNextClip(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangGoToNextClip(Deck.getAddress(deck), channels);
  }

  gangGoToPrevClip(streams: Stream[]): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangGoToPrevClip(Deck.getAddress(deck), channels);
  }

  /**
   * Playback scroll forward/reverse
   */

  // speed (from 0.0 to 100.0) on remote device
  jogForward(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.jogForward(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  jogReverse(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.jogReverse(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  varForward(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.varForward(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  varReverse(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.varReverse(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  shuttleForward(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.shuttleForward(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  shuttleReverse(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.shuttleReverse(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  /**
   * Clips
   */

  getClips(stream: Stream): Observable<Clip[]> {
    if (!stream || !this.isOnline(stream)) {
      return of([]);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.clips(Deck.getAddress(deck));
  }

  @warmUpObservable
  openFile(stream: Stream, clipIds?: number[]): Observable<void> {
    if (!stream || !this.isOnline(stream)) {
      return of();
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.openFile(Deck.getAddress(deck), stream.deckChannel, clipIds)
      .pipe(
        catchError(error => {
          if (error) {
            this.notificationService.warning(`'${stream?.deckChannelName}' open file error: ` + error?.error?.message, `${deck.name} (${deck.address})`);
          }
          return of(null);
        })
      );
  }

  @warmUpObservable
  ejectFile(stream: Stream): Observable<void> {
    if (!stream || !this.isOnline(stream)) {
      return of();
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.eject(Deck.getAddress(deck), stream.deckChannel);
  }

  /**
   * Record
   */

  @warmUpObservable
  record(stream: Stream, timecode?: string, captureId?: number): Observable<void> {
    if (!stream || !this.isOnline(stream)) {
      return of();
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.record(Deck.getAddress(deck), stream.deckChannel, timecode, captureId)
      .pipe(
        catchError(error => {
          if (error) {
            const recordError = DeckError.create(error?.error?.message, stream.deckChannel);
            this.addError(recordError);
          }
          return of(null);
        })
      );
  }

  stopRecord(stream: Stream, timecode?: string): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.stopRecord(Deck.getAddress(deck), stream.deckChannel, timecode);
  }

  breakRecord(stream: Stream, timecode?: string): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.breakRecord(Deck.getAddress(deck), stream.deckChannel, timecode);
  }

  pauseRecord(stream: Stream, timecode?: string): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.pauseRecord(Deck.getAddress(deck), stream.deckChannel, timecode);
  }

  resumeRecord(stream: Stream, timecode?: string): void {
    if (!stream || !this.isOnline(stream)) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.resumeRecord(Deck.getAddress(deck), stream.deckChannel, timecode);
  }

  /**
   * Record group in same Deck (gang)
   */

  @warmUpObservable
  gangRecord(streams: Stream[], timecode?: string): Observable<void> {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    return this.editshareCreateCapture(streams)
      .pipe(
        switchMap((captureId => this.getRecordTimecodeFromStreams(streams).pipe(map(timecodes => ({timecodes, captureId}))))),
        map(({timecodes, captureId}) => {
          const tc = timecode || (timecodes && timecodes[streams[0].id])
          return ({tc, captureId});
        }),
        switchMap((({tc, captureId}) => this.networkService.gangRecord(Deck.getAddress(deck), channels, tc, captureId))),
        catchError(error => {
          if (error) {
            streams.forEach(stream => {
              const recordError = DeckError.create(error?.error?.message, stream.deckChannel);
              this.addError(recordError);
            });
          }
          return of(null);
        })
      );
  }

  gangStopRecord(streams: Stream[], timecode?: string): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangStopRecord(Deck.getAddress(deck), channels, timecode);
  }

  gangBreakRecord(streams: Stream[], timecode?: string): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangBreakRecord(Deck.getAddress(deck), channels, timecode);
  }

  gangPauseRecord(streams: Stream[], timecode?: string): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangPauseRecord(Deck.getAddress(deck), channels, timecode);
  }

  gangResumeRecord(streams: Stream[], timecode?: string): void {
    if (!streams || !this.isSameDeck(streams) || !this.isOnline(streams[0])) {
      return;
    }
    const deck = this.deckService.getDeckSync(streams[0].deckId);
    const channels = streams.map(stream => stream.deckChannel);
    this.networkService.gangResumeRecord(Deck.getAddress(deck), channels, timecode);
  }

  /**
   * Group Record
   */

  private getRecordTimecodeFromStreams(streams: Stream[]): Observable<{[id: string]: string}> {
    const timecodes: {timecode: string, stream: Stream, tcSource: string, sameTC: boolean}[] = [];
    for (const stream of streams) {
      const preset = this.presetService.getPresetSync(stream?.presetId);
      const settings = preset?.getSetting(stream?.id);
      const tcSource = settings?.automation?.manualParams?.source;
      const streamTC = this.getCurrentTimecode(stream);
      const timecode = streamTC[tcSource]?.timecode;
      if (timecode) {
        timecodes.push({timecode, stream, tcSource, sameTC: false});
      }
    }
    const result: {[id: string]: string} = {};
    let resultTC: Timecode;
    let resultTCSource: string;
    for (const { timecode, stream, tcSource } of timecodes) {
      // Get Info
      const preset = this.presetService.getPresetSync(stream?.presetId);
      const settings = preset?.getSetting(stream?.id);
      if (!settings) {
        return of(null);
      }

      // Logs
      console.log(`[Gang Record][Info] TC=${timecode} TcSource=${tcSource} Stream=${stream?.deckChannel}(${stream?.deckChannelName})`);

      // Get Timecode and add 3 seconds
      let tc: Timecode;
      try {
        const fps = +settings?.input?.activeFrameRate;
        const timebase = Timecode.getTimebase(fps, Timecode.isInterlaced(fps, settings?.input?.activeResolution));
        const toleranceTime = fps * this.gangRecordToleranceTime; // 3 seconds
        tc = new Timecode(timecode, fps);
        tc = tc.add(toleranceTime, false, 0);        
      } catch (error) {
        console.error('[Gang Record][Error] ', error);
        console.warn('[Gang Record][Error][TC] ', timecode);
        console.warn('[Gang Record][Error][TcSource] ', tcSource);
        console.warn('[Gang Record][Error][Stream] ', stream);
        return of(null);
      }

      // Set Result TC
      if (!resultTC && tc) {
        resultTC = tc;
        resultTCSource = tcSource;
      }
      // Add TC and validate
      if (resultTC?.frameRate === tc?.frameRate && resultTC?.dropFrame === tc?.dropFrame && resultTCSource === tcSource && !tc?.irig) {
        result[stream.id] = resultTC.toString();
      } else {
        return of(null);
      }
    }
    // Stream count !== Timecodes count
    if (streams.length !== Object.keys(result).length) {
      return of(null);
    }
    // Timecode not changed in list
    const sameTC = timecodes.filter(tc => tc.sameTC === true);
    if (sameTC.length > 0) {
      const result = new Subject<{[id: string]: string}>();
      const channels = sameTC.map(value => value.stream.deckChannelName).join(', ');
      this.alertService.show(
        'Timecode source does not change, continue anyway?',
        'Channels on which TC does not change: ' + channels,
        ['Cancel', 'Continue'],
        0
      )
        .subscribe(response => {
          if (response === 1) {
            result.next(null);
          }
          result.complete();
        });
      return result.asObservable();
    } else {
      return of(result);
    }
  }

  @warmUpObservable
  groupRecord(streams: Stream[]): void {
    this.editshareCreateCapture(streams)
      .pipe(
        switchMap((captureId => this.getRecordTimecodeFromStreams(streams).pipe(map(timecodes => ({timecodes, captureId})))))
      )
      .subscribe(({timecodes, captureId}) => {
        if (timecodes && Object.keys(timecodes)?.length && streams.length > 1) {
          // Success
          this.notificationService.success('Gang recording started');
        } else if (streams.length > 1) {
          // Error
          this.notificationService.warning('Channels in recording group not synchronized', 'Gang record');
        }
        for (const stream of streams) {
          const timecode = timecodes && timecodes[stream.id];
          this.record(stream, timecode, captureId);
        }
      });
  }

  groupStopRecord(streams: Stream[]): void {
    this.getRecordTimecodeFromStreams(streams).subscribe(timecodes => {
      for (const stream of streams) {
        const timecode = timecodes && timecodes[stream.id];
        this.stopRecord(stream, timecode);
      }
    });
  }

  groupBreakRecord(streams: Stream[]): void {
    this.getRecordTimecodeFromStreams(streams).subscribe(timecodes => {
      for (const stream of streams) {
        const timecode = timecodes && timecodes[stream.id];
        this.breakRecord(stream, timecode);
      }
    });
  }

  groupPauseRecord(streams: Stream[]): void {
    this.getRecordTimecodeFromStreams(streams).subscribe(timecodes => {
      for (const stream of streams) {
        const timecode = timecodes && timecodes[stream.id];
        this.pauseRecord(stream, timecode);
      }
    });
  }

  groupResumeRecord(streams: Stream[]): void {
    this.getRecordTimecodeFromStreams(streams).subscribe(timecodes => {
      for (const stream of streams) {
        const timecode = timecodes && timecodes[stream.id];
        this.resumeRecord(stream, timecode);
      }
    });
  }

  /**
   * Editshare
   */

  @warmUpObservable
  editshareCreateCapture(streams: Stream[]): Observable<number> {
    let settings: StreamSettingsEditshare;
    let deck: Deck;
    streams.forEach(stream => {
      const streamSettings = this.presetService.getPresetSync(stream?.presetId)?.getSetting(stream?.id);
      if (streamSettings?.editShareSettings?.enabled && streamSettings?.editShareSettings?.multicamEnabled) {
        settings = streamSettings.editShareSettings;
        deck = this.deckService.getDeckSync(stream?.deckId);
      }
    });
    if (!settings) {
      return of(null);
    }
    return this.networkService.editShareCreateCaptureId(Deck.getAddress(deck), settings);
  }

  /**
   * Errors
   */

  getCleanErrors(): Observable<void> {
    return this.cleanErrors.asObservable();
  }

  setCleanErrors(): void {
    this.cleanErrors.next();
  }

  /**
   * Methods
   */

  fixBreakSettingsField(settings: StreamSettings): StreamSettings {
    if (settings?.input?.breakSettings) {
      settings.input.breakSettings = settings.input.breakSettings.map(value => value == null ? false : value);
    }
    if (settings?.encode?.length) {
      settings.encode.forEach((encode, index) => {
        if (encode.activeQuality === 'UNSUPPORTED') {
          settings.encode[index].activeQuality = 'invalid';
        }
      });
    }
    return settings;
  }

  parseTimecodeFromXml(xml: string, tcSource: string): string {
    const doc = this.domParser.parseFromString(xml, 'text/xml');
    const timecodesElement = doc?.getElementsByTagName('timecodes')[0];
    const timecode = timecodesElement?.getElementsByTagName(tcSource)[0]?.innerHTML;
    return timecode;
  }
  
  validateSameTimecodes(xmlMetadatas: string[], tcSource: string): boolean {
    const timecodes = xmlMetadatas.map(xml => this.parseTimecodeFromXml(xml, tcSource)).filter(tc => !!tc);
    return timecodes.length > 1 && timecodes?.every((value, _, array) => value === array[0]);
  }

}
