<mat-form-field appearance="outline" floatLabel="never" class="form-field" [class.disabled]="disabled" [class.highlighted]="highlighted">
  <mat-select
    [multiple]="multiple"
    disableOptionCentering
    panelClass="dropdown-panel"
    [placeholder]="placeholder"
    [compareWith]="compareOptions"
    [value]="selected"
    (valueChange)="updateSelected($event)"
    (openedChange)="openedChange($event)"
    class="select-option"
  >
    <mat-option *ngFor="let option of options" [value]="option" [disabled]="disabledOptions && disabledOptions.length > 0 && isDisabled(option)">
      <p class="select-option">{{option.title}}</p>
    </mat-option>
  </mat-select>
  <mat-icon matSuffix svgIcon="arrow"></mat-icon>
</mat-form-field>
