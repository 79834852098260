import { Pipe, PipeTransform } from '@angular/core';

// Lodash
import { isEqual, transform, isObject } from 'lodash-es';

@Pipe({
  name: 'difference'
})
export class DifferencePipe implements PipeTransform {

  /**
   * Deep diff between two object, using lodash
   * @param  {Object} object Object compared
   * @param  {Object} base   Object to compare with
   * @return {Object}        Return a new object who represent the diff
   */
  transform(object: Object, base: Object): Object {
    function changes(object, base) {
      return transform(object, function(result, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  }

}
