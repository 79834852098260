<div class="playlist-manager-content">
  <!-- Title -->
  <div class="title-header">
    <div class="title">Playlists</div>
  </div>
  <!-- Playlists -->
  <div class="playlists" *ngIf="playlists?.length">

    <div
      class="playlist"
      *ngFor="let playlist of playlists"
      [class.selected]="selection.isSelected(playlist)"
      [class.current-play]="status?.playback?.openPlaylistName === playlist.name && status?.playback?.isLoaded"
      [class.drag-over]="dndOverPlaylist === playlist.name"
      [class.error]="dndOverPlaylist === playlist.name && invalidDropFile"
      (appClick)="selectPlaylist($event, playlist)"
      (appDblClick)="editPlaylist($event, playlist)"
      [contextMenu]="inputMenu"
      [contextMenuSubject]="playlist"
      appDroppable
      [appDroppableZIndex]="10"
      (appDroppableEnter)="dndEnter($event, playlist)"
      (appDroppableLeave)="dndLeave($event, playlist)"
      (appDroppableOver)="dndDragOver($event, playlist)"
      (appDroppableDrop)="dndDrop($event, playlist)"
      (drop)="systemUploadDropedFiles($event, playlist)"
      (dragenter)="systemDndEnter($event, playlist)"
      (dragleave)="systemDndLeave($event, playlist)"
      (dragover)="systemDndOver($event, playlist)"
    >
      <div class="title">{{ playlist.name }}</div>
      <div class="clips">({{ playlist.clips?.length }})</div>
      <div class="actions">
        <mat-icon svgIcon="play" class="icon play-icon" [class.selected]="status?.playback?.openPlaylistName === playlist.name && status?.playback?.isLoaded" (click)="playPlaylist(playlist)" matTooltip="Play" matTooltipShowDelay="1000"></mat-icon>
        <mat-icon svgIcon="settings-sliders" class="icon" (click)="editPlaylist(null, playlist)" matTooltip="Edit" matTooltipShowDelay="1000"></mat-icon>
      </div>
    </div>

    <div class="empty" *ngIf="!playlists?.length">No Playlist</div>
  </div>
</div>

<!-- Context menu -->
<context-menu #inputMenu>
  <ng-template contextMenuItem let-item [enabled]="isPlaylistOpenFunc" (execute)="playPlaylist($event.item)">Play Playlist</ng-template>
  <ng-template contextMenuItem let-item (execute)="editPlaylist(null, $event.item)">Edit Playlist</ng-template>
  <ng-template contextMenuItem let-item [enabled]="isPlaylistOpenFunc" (execute)="renamePlaylist($event.item)">Rename</ng-template>
  <ng-template contextMenuItem let-item [enabled]="isPlaylistOpenFunc" (execute)="deletePlaylist($event.item)">Delete</ng-template>
</context-menu>
