<div class="playlist-timeline-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Timeline</div>
    <div class="actions">
      <mat-icon svgIcon="settings-sliders" class="action-icon" [class.disabled]="false"></mat-icon>
    </div>
  </div>

  <!-- Player -->
  <div class="playlist-timeline">
    <!-- Content -->
    <div class="playlist-content"></div>

    <!-- Ruler -->
    <div class="ruler">
      <div class="line" *ngFor="let line of rulerLine"></div>
    </div>
  </div>

</div>
