import { UniqueID } from '@modules/elements/types/unique-id';

export class XmlTag {
  id: string;
  name: string;
  color: string;
  system: boolean;

  constructor(name: string, color: string, system: boolean = false) {
    this.id = UniqueID.generate();
    this.name = name;
    this.color = color;
    this.system = system;
  }
}
