import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';

// RxJS
import { map, takeUntil } from 'rxjs/operators';

// Services
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { UserService } from '@modules/users/services/user.service';

// Components
import { PresetSettingsBaseComponent } from '../preset-settings-base/preset-settings-base.component';

// Types
import { Option } from '@modules/elements/types/option';
import { StreamSettingsChannelAudioOutput } from '@modules/stream/types/stream-settings';

@Component({
  selector: 'app-preset-settings-output',
  templateUrl: './preset-settings-output.component.html',
  styleUrls: ['./preset-settings-output.component.less']
})
export class PresetSettingsOutputComponent extends PresetSettingsBaseComponent implements OnInit, OnChanges {

  // Public
  public playoutAudioChannelsOptions: Option<number>[] = [{value: -1, title: 'Off'}];
  public playthroughAudioChannelsOptions: Option<number>[] = [{value: -1, title: 'Off'}];
  public playoutEnabled = false;
  public playoutCount: number = 0;
  public playthroughCount: number = 0;
  public audioEncoderChannelCountOptions: Option<number>[] = [];
  public audioEncoderChannelsInTrackOptions: Option<number>[] = [];
  public videoEncoderKeyFrameDistanceOptions: Option<number>[] = [];
  public validateSrtPassphrase = (value: string): Error | null => {
    if (value && value.length > 0 && value.length < 10) {
      console.log('[Validation][Error]');
      return new Error('minimum 10 symbols');
    }
    console.log('[Validation][Okay]');
    return null;
  };
  public audioTrackWarning = false;

  /**
   * Constructor
   */

  constructor(
    protected presetSettingsService: PresetSettingsService,
    private streamService: StreamService,
    userService: UserService
  ) {
    super(presetSettingsService, userService);
    // Set audioEncoderChannelCountOptions
    for (let index = 0; index <= 32; index++) {
      this.audioEncoderChannelCountOptions.push(new Option(index, index + '')); 
    }
    for (let index = 1; index <= 8; index++) {
      this.audioEncoderChannelsInTrackOptions.push(new Option(index, index + '')); 
    }
    for (let index = 1; index <= 72; index++) {
      this.videoEncoderKeyFrameDistanceOptions.push(new Option(index, index + '')); 
    }
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    super.ngOnInit();

    // Get Status
    this.streamService.getStatus(this.stream)
      .pipe(
        map(status => status?.playback?.isLoaded),
        takeUntil(this.alive)
      )
      .subscribe(isLoaded => this.playoutEnabled = isLoaded);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if ('settings' in changes || 'stream' in changes) {
      this.updateOptions();
    }
    this.validateSrtAudioTracks();
  }

  /**
   * Methods
   */

  updateOptions(): void {
    this.streamService.getSettingsAudioOutputRoutingChannelsCount(this.stream)
      .pipe(takeUntil(this.alive))
      .subscribe(audioChannelsCount => {
        this.playoutCount = audioChannelsCount.playout;
        this.playthroughCount = audioChannelsCount.playthrough;
        this.playoutAudioChannelsOptions = [{value: -1, title: 'Off'}];
        this.playthroughAudioChannelsOptions = [{value: -1, title: 'Off'}];
        for (let i = 0; i < this.playoutCount; ++i) {
          this.playoutAudioChannelsOptions.push({value: i, title: (i + 1).toString()});
        }
        for (let i = 0; i < this.playthroughCount; ++i) {
          this.playthroughAudioChannelsOptions.push({value: i, title: (i + 1).toString()});
        }
      });
  }

  validateSrtAudioTracks(): void {
    this.audioTrackWarning = Math.ceil(this.settings.output.audioEncoderChannelCount / this.settings.output.audioEncoderChannelsInTrack) > 8;
  }

  updateSrtPassphrase(): void {
    if (!!this.validateSrtPassphrase(this.settings?.output?.srtListenerPassphrase)) {
      return;
    }
    this.updateSettings();
  }

  updateSelect(key: string, value: string | number | boolean): void {
    super.updateSelect(key, value);
    this.validateSrtAudioTracks();
  }

  getAudioOutputOption(audioOutput: StreamSettingsChannelAudioOutput, options: Option<number>[]): Option<number> {
    return options.find(option => option?.value === audioOutput?.value) || {value: -1, title: 'Off'};
  }

  selectAudioOutput(option: Option<number>, index: number): void {
    this.settings.channels[this.stream.deckChannel].audioOutput.data[index] = {enabled: true, value: option.value};
    this.updateSettings();
  }

  selectAudioPlaythroug(option: Option<number>, index: number): void {
    this.settings.channels[this.stream.deckChannel].audioPlaythroug.data[index] = {enabled: true, value: option.value};
    this.updateSettings();
  }

}
