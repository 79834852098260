export class Timecode {

  // Public
  public dropFrame: boolean;
  public frameRate: number;
  public frameCount: number;

  public hours: number;
  public minutes: number;
  public seconds: number;
  public frames: number;
  
  public irig: boolean;
  public millisecond: number;
  public msLength: number;

  // Private
  private frameRates = ['16', '18', '23.976', '24', '25', '29.97', '30', '47.952', '48', '50', '59.94', '60'];

  static getTimebase(fps: number, interlaced: boolean): number {
    switch (fps) {
      case 23.98:
      case 24:
        return 24;
      case 25:
        return 25;
      case 50:
        return (interlaced)? 25 : 50;
      case 59.94:
      case 60:
        return (interlaced)? 30 : 60;
      case 29.97:
      case 30:
        return 30;
      default: -1
    }
  }

  static isInterlaced(fps: number, resolution: string): boolean {
    return (resolution === 'ntsc' && !(fps === 29.94 || fps === 24)) || resolution === 'pal' || resolution === '1080i';
  }

  static isDropFrame(fps: number) {
    return fps === 29.97 || fps === 59.94 || fps === 23.98;
  }

  /**
   * Constructor
   */

  constructor(timeCode: number | string | Date | Timecode, frameRate: number, dropFrame?: boolean) {

    // Get frame rate
    if (typeof frameRate === 'undefined') {
      this.frameRate = 29.97;
    } else if (typeof frameRate === 'number' && frameRate>0) {
      this.frameRate = frameRate;
    } else {
      throw new Error('Number expected as framerate');
    }
    if (
      this.frameRate !== 23.97 && this.frameRate !== 23.98 && this.frameRate !== 24 && this.frameRate !== 25 && this.frameRate !== 29.97 &&
      this.frameRate !== 30 && this.frameRate !== 50 && this.frameRate !== 59.94 && this.frameRate !== 60
    ) {
      throw new Error('Unsupported framerate');
    }

    // If we are passed dropFrame, we need to use it
    if (typeof dropFrame === 'boolean') {
      this.dropFrame = dropFrame;
    } else if (typeof timeCode !== 'string') {
      this.dropFrame = (this.frameRate === 29.97 || this.frameRate === 59.94 || this.frameRate === 23.97); // by default, assume DF for 29.97 and 59.94, NDF otherwise
    }

    // Now either get the frame count, string or datetime        
    if (typeof timeCode === 'number') {
        this.frameCount = Math.round(timeCode);
        this.frameCountToTimeCode();
    } else if (typeof timeCode === 'string') {
      // pick it apart
      const parts = timeCode.match('^(\\d\\d):(\\d\\d):(\\d\\d)(:|;|\\.)(\\d{2,4})$');
      if (!parts) {
        throw new Error("Timecode string expected as HH:MM:SS:FF or HH:MM:SS;FF or HH:MM:SS.SSSS");
      }
      this.hours = parseInt(parts[1]);
      this.minutes = parseInt(parts[2]);
      this.seconds = parseInt(parts[3]);
      // do not override input parameters
      if (typeof dropFrame !== 'boolean') {
        this.dropFrame = parts[4] !== ':';
      }
      this.irig = parts[4] === '.';
      this.frames = parseInt(parts[5]);
      if (frameRate > 30) {
        this.frames *= 2;
      }
      if (this.irig) {
        this.frames = 0;
        this.millisecond = parseInt(parts[5]);
        this.msLength = parts[5].length;
      }
      this.timeCodeToFrameCount();
    } else if (typeof timeCode === 'object' && timeCode instanceof Date) {
      const midnight = new Date(timeCode.getFullYear(), timeCode.getMonth(), timeCode.getDate(),0,0,0);
      const midnight_tz = midnight.getTimezoneOffset() * 60 * 1000;
      const timecode_tz = timeCode.getTimezoneOffset() * 60 * 1000;
      this.frameCount = Math.round(((timeCode.getTime() - midnight.getTime() + (midnight_tz - timecode_tz))*this.frameRate)/1000);
      this.frameCountToTimeCode();
    } else if (typeof timeCode === 'object' && typeof (timeCode.hours) != 'undefined') {
      if (!frameRate && timeCode.frameRate) {
        this.frameRate = timeCode.frameRate;
      }
      if (typeof dropFrame !== 'boolean' && typeof timeCode.dropFrame === 'boolean') {
        this.dropFrame = timeCode.dropFrame;
      }
      this.hours = timeCode.hours;
      this.minutes = timeCode.minutes;
      this.seconds = timeCode.seconds;
      this.frames = timeCode.frames;
      this.irig = timeCode.irig;
      this.millisecond = timeCode.millisecond;
      this.msLength = timeCode.msLength;
      this.timeCodeToFrameCount();
    } else if (typeof timeCode === 'undefined') {
      this.frameCount = 0;
    } else {
      throw new Error('Timecode() constructor expects a number, timecode string, or Date()');
    }

    this.validate(timeCode);
  }

  /**
   * Methods
   */

  private validate(timeCode: number | string | Date | Timecode): void {
    // make sure the numbers make sense for IRIG
    if (this.irig && (this.hours > 99 || this.minutes > 59 || this.seconds > 59)) {
      throw new Error("Invalid timecode" + JSON.stringify(timeCode));
    }

    // Make sure dropFrame is only for 29.97 & 59.94
    // if (this.dropFrame && this.frameRate!==23.97 && this.frameRate!==23.98 && this.frameRate!==29.97 && this.frameRate!==59.94) {
    //   throw new Error('Drop frame is only supported for 23.97, 29.97, and 59.94 fps');
    // }

    // make sure the numbers make sense
    if (!this.irig && (this.hours > 99 || this.minutes > 59 || this.seconds > 59 || this.frames >= this.frameRate ||
        (this.dropFrame && this.seconds === 0 && this.minutes % 10 && this.frames < 2 * (this.frameRate / 29.97)))) {
      throw new Error("Invalid timecode" + JSON.stringify(timeCode));
    }
  }

  private frameCountToTimeCode(): void {
    let fc = this.frameCount;
    // adjust for dropFrame
    if (this.dropFrame) {
      const df = this.frameRate===29.97 ? 2 : 4; // 59.94 skips 4 frames
      const d = Math.floor(this.frameCount / (17982*df/2));
      let m = this.frameCount % (17982*df/2);
      if (m < df) {
        m = m + df;
      }
      fc += 9*df*d + df*Math.floor((m-df)/(1798*df/2));
    }
    const fps = Math.round(this.frameRate);
    this.frames = fc % fps;
    this.seconds = Math.floor(fc/fps) % 60;
    this.minutes = Math.floor(fc/(fps*60)) % 60;
    this.hours = Math.floor(fc/(fps*3600)) % 24;
    if (this.irig) {
      this.frames = 0;
      const msMaxValue = +('1' + this.leftPad(0,4));
      this.millisecond = Math.round(((fc % fps) / fps) * msMaxValue);
    }
  }

  private timeCodeToFrameCount(): void {
    // Calc frameCount for IRIG
    if (this.irig) {
      this.frameCount = (this.hours*3600 + this.minutes*60 + this.seconds) * Math.round(this.frameRate) +
        Math.round((+('0.' + this.leftPad(this.frames, this.msLength))) * this.frameRate)
      return;
    }
    // Calc frameCount
    this.frameCount = (this.hours*3600 + this.minutes*60 + this.seconds) * Math.round(this.frameRate) + this.frames;
    // adjust for dropFrame
    if (this.dropFrame) {
      const totalMinutes = this.hours*60 + this.minutes;
      const df = (this.frameRate === 29.97 || this.frameRate === 30) ? 2 : 4;
      this.frameCount -= df * (totalMinutes - Math.floor(totalMinutes / 10));    
    }
  }

  private leftPad(number: number, targetLength: number): string {
    let output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
  }

  toString(format?): string {
    let frames = this.frames;
    if (this.frameRate > 30) {
      frames = Math.floor(frames / 2);
    }
    let field = '';
    if (typeof format === 'string') {
      if (format === 'field') {
          if (this.frameRate <= 30) {
            field = '.0';
          } else {
            field = '.'.concat((this.frameCount%2).toString());
          }
      } else {
        throw new Error('Unsupported string format');
      }
    }
    return "".concat(
      this.hours < 10 ? '0' : '',
      this.hours.toString(),
      ':',
      this.minutes < 10 ? '0' : '',
      this.minutes.toString(),
      ':',
      this.seconds < 10 ? '0' : '',
      this.seconds.toString(),
      this.irig ? '.' : this.dropFrame ? ';' : ':',
      this.irig ? this.leftPad(this.millisecond, this.msLength) : this.leftPad(frames, 2),
      field
    );
  }

  // TODO: Remove
  valueOf() {
    return this.frameCount;
  }

  add(timeCode, negative: boolean = false, rollOverMaxHours = 0) {
    if (typeof timeCode === 'number') {
      let newFrameCount = this.frameCount + Math.round(timeCode) * (negative?-1:1);
      if (newFrameCount < 0 && rollOverMaxHours > 0) {
        newFrameCount = (Math.round(this.frameRate*86400)) + newFrameCount;
        if (((newFrameCount / this.frameRate) / 3600) > rollOverMaxHours) {
          throw new Error('Rollover arithmetic exceeds max permitted');
        }
      }
      if (newFrameCount < 0) {
        throw new Error("Negative timecodes not supported");
      }
      this.frameCount = newFrameCount;
    } else {
      if (!(timeCode instanceof Timecode)) {
        timeCode = new Timecode(timeCode, this.frameRate, this.dropFrame);
      }
      return this.add(timeCode.frameCount, negative, rollOverMaxHours);
    }
    this.frameCount = this.frameCount % (Math.round(this.frameRate*86400)); // wraparound 24h
    this.frameCountToTimeCode();
    return this;
  }

  subtract(timeCode, rollOverMaxHours) {
    return this.add(timeCode, true, rollOverMaxHours);
  }

  toDate() {
    const ms = this.frameCount / this.frameRate * 1000;
    const midnight = new Date();
    midnight.setHours(0);
    midnight.setMinutes(0);
    midnight.setSeconds(0);
    midnight.setMilliseconds(0);

    const d = new Date( midnight.valueOf() + ms );
    const midnight_tz = midnight.getTimezoneOffset() * 60 * 1000;
    const timecode_tz = d.getTimezoneOffset() * 60 * 1000;
    return new Date( midnight.valueOf() + ms + (timecode_tz - midnight_tz));
  }

}
