import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { delayedRetry } from '../types/delayed-retry';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { StreamSettings, StreamSettingsEditshare } from '@modules/stream/types/stream-settings';
import { Clip } from '@modules/deck/types/clip';
import { StreamInputs } from '@modules/stream/types/stream-inputs';
import { DeckChannel } from '@modules/deck/types/deck-channel';
import { DeskEmulation } from '@modules/preset/types/desk-emulation';
import { RecordingFilename } from '@modules/stream/types/recording-filename';
import { File } from '@modules/file-manager/types/file';
import { RS422Info } from '@modules/rs422/types/rs422-info';
import { RS422Timecodes } from '@modules/rs422/types/rs422-timecodes';
import { EncoderInfo } from '@modules/stream/types/encoder-info';
import { CharOutStatus } from '@modules/stream/types/charout-status';
import { StreamStatusPlaybackLoop } from '@modules/stream/types/stream-status';
import { RS422PlaybackMode } from '@modules/rs422/types/rs422-settings';
import { EditshareCapture } from '@modules/stream/types/editshare-capture';
import { Playlist } from '@modules/playlist-editor/types/playlist';
import { Marker } from '@modules/marker/types/marker';
import { Group, GroupValue } from '@modules/template/types/group';
import { Template } from '@modules/template/types/template';


@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  /**
   * Static methods
   */

  static handleObserverError(error: Error): Observable<never> {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Channels
   */

  getChannels(deck: string): Observable<DeckChannel[]> {
    return this.http.get<{channels: DeckChannel[]}>(`${deck}/channels`)
      .pipe(
        map(({channels}) => channels),
        catchError(error => of([]))
      );
  }

  getChannelInputs(deck: string, channel: number): Observable<StreamInputs> {
    return this.http.get<StreamInputs>(`${deck}/channel/${channel}/inputs`)
      .pipe(
        catchError(error => of(null))
      );
  }

  /**
   * Settings
   */

  getSettings(deck: string, channel: number): Observable<StreamSettings> {
    return this.http.get<StreamSettings>(`${deck}/channel/${channel}/settings`)
      .pipe(
        delayedRetry(50, 5),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateSettings(deck: string, channel: number, project: StreamSettings): Observable<void> {
    return this.http.post<{status: string}>(`${deck}/channel/${channel}/settings`, project)
      .pipe(
        map(response => null),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  autoDectectSignal(deck: string, channel: number): Observable<void> {
    return this.http.post<{status: string}>(`${deck}/channel/${channel}/auto_detect`, {})
      .pipe(
        map(response => null),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  refreshInput(deck: string, channel: number): Observable<void> {
    return this.http.post<{status: string}>(`${deck}/channel/${channel}/refresh_input`, {})
      .pipe(
        map(response => null),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsInputFps(deck: string, channel: number, resolutionId: string, videoInputId: string): Observable<string[]> {
    return this.http.get<{framerates: string[]}>(`${deck}/channel/${channel}/fps/${resolutionId}/${videoInputId}`)
      .pipe(
        map(response => response.framerates),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsEncoderCodecs(deck: string, channel: number, encoderIndex: number): Observable<string[]> {
    return this.http.get<{codecs: string[]}>(`${deck}/channel/${channel}/codecs/${encoderIndex}`)
      .pipe(
        map(response => response.codecs),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsEncoderWrapper(deck: string, channel: number, encoderIndex: number, codecId: string, qualityId: string): Observable<string[]> {
    return this.http.get<{wrappers: string[]}>(`${deck}/channel/${channel}/wrappers/${encoderIndex}/${codecId}/${qualityId}/info`)
      .pipe(
        map(response => response.wrappers),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsEncoderQualities(deck: string, channel: number, encoderIndex: number, codecId: string): Observable<string[]> {
    return this.http.get<{qualities: string[]}>(`${deck}/channel/${channel}/codecs/${encoderIndex}/${codecId}/info`)
      .pipe(
        map(response => response.qualities),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsRecordControlTCSources(deck: string, channel: number): Observable<string[]> {
    return this.http.get<{sources: string[]}>(`${deck}/channel/${channel}/record_control_tc_sources`)
      .pipe(
        map(response => response.sources),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsLtcSources(deck: string, channel: number): Observable<number[]> {
    return this.http.get<{ltcSources: number[]}>(`${deck}/channel/${channel}/ltc_sources`)
      .pipe(
        map(response => response.ltcSources),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsAudioOutputRoutingChannelsCount(deck: string, channel: number): Observable<{playout: number, playthrough: number}> {
    return this.http.get<{playout: number, playthrough: number}>(`${deck}/channel/${channel}/audio_output_routing`)
      .pipe(
        map(response => response),
        catchError(NetworkService.handleObserverError)
      );
  }

  getSettingsDeskEmulation(deck: string, channel: number): Observable<DeskEmulation[]> {
    return this.http.get<{desks: DeskEmulation[]}>(`${deck}/channel/${channel}/desk_emulation`)
      .pipe(
        map(response => response.desks),
        catchError(NetworkService.handleObserverError)
      );
  }

  getRecordingFilenames(deck: string, channel: number): Observable<RecordingFilename[]> {
    return this.http.get<{filenames: RecordingFilename[]}>(`${deck}/channel/${channel}/recording_filenames`)
      .pipe(
        map(response => response.filenames),
        catchError(error => of([]))
      );
  }

  getEncodersInfo(deck: string, channel: number): Observable<EncoderInfo[]> {
    return this.http.get<{encoders: EncoderInfo[]}>(`${deck}/channel/${channel}/encoders_info`)
      .pipe(
        map(response => response.encoders),
        catchError(NetworkService.handleObserverError)
      );
  }

  getCharOut(deck: string, channel: number): Observable<CharOutStatus> {
    return this.http.get<CharOutStatus>(`${deck}/channel/${channel}/charout`)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  updateCharOut(deck: string, channel: number, charOut: CharOutStatus): Observable<any> {
    return this.http.post<void>(`${deck}/channel/${channel}/charout`, charOut)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateFileOverwrite(deck: string, fileOverwrite: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/file_overwrite`, { fileOverwrite })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Playback
   */

  clips(deck: string): Observable<Clip[]> {
    return this.http.get<{clips: Clip[]}>(`${deck}/clips`)
      .pipe(
        map(({clips}) => clips),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  play(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/play`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  reverse(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/reverse`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  pause(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/pause`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  fastForward(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/fast_forward`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rewind(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/rewind`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToStart(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_start`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToEnd(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_end`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  setInPoint(deck: string, channel: number): Observable<void> {
    // Without param – set current timecode
    // tc - string timecode "00:00:00:00"
    // or
    // frame - number or frame
    return this.http.post<void>(`${deck}/channel/${channel}/set_in_point`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  setOutPoint(deck: string, channel: number): Observable<void> {
    // Without param – set current timecode
    // tc - string timecode "00:00:00:00"
    // or
    // frame - number or frame
    return this.http.post<void>(`${deck}/channel/${channel}/set_out_point`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  jump(deck: string, channel: number, value: {tc?: string, frame?: number, offset?: number}): Observable<void> {
    // tc - string timecode "00:00:00:00"
    // or
    // frame - number (jump to frame)
    // or
    // offset - number (like 10 or -10)
    const params = {};
    if (value.tc) {
      params['tc'] = value.tc;
    } else if ('frame' in value) {
      params['frame'] = value.frame;
    } else if ('offset' in value) {
      params['offset'] = value.offset;
    }
    return this.http.post<void>(`${deck}/channel/${channel}/jump`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  openFile(deck: string, channel: number, clipIds?: number[]): Observable<void> {
    const params = clipIds?.length > 0
      ? (clipIds.length === 1 ? {clipId: clipIds[0]} : {clips: clipIds})
      : {};
    return this.http.post<void>(`${deck}/channel/${channel}/open_file`, params);
  }

  @warmUpObservable
  eject(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/eject`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToNextClip(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_next_clip`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToPrevClip(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_prev_clip`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  hideStopOverlay(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/hide_stop_overlay`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  loopPlayback(deck: string, channel: number, mode: StreamStatusPlaybackLoop): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/loop_playback`, { mode })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToNextAndPause(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_next_and_pause`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToNextAndPlay(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_next_and_play`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToPrevAndPause(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_prev_and_pause`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToPrevAndPlay(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_to_prev_and_play`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  goToClipByIndexAndPause(deck: string, channel: number, index): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/go_clip_by_index_and_pause`, { index })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Playback scroll forward/reverse
   */

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  jogForward(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/jog_forward`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  jogReverse(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/jog_reverse`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  varForward(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/var_forward`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  varReverse(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/var_reverse`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  shuttleForward(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/shuttle_forward`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  shuttleReverse(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/shuttle_reverse`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Playback gang (group)
   */
  
  @warmUpObservable
  gangPlay(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/play`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangReverse(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/reverse`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangPause(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/pause`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangFastForward(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/fast_forward`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangRewind(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/rewind`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangGoToStart(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/go_to_start`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangGoToEnd(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/go_to_end`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangSetInPoint(deck: string, channels: number[]): Observable<void> {
    // Without param – set current timecode
    // tc - string timecode "00:00:00:00"
    // or
    // frame - number or frame
    return this.http.post<void>(`${deck}/set_in_point`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangSetOutPoint(deck: string, channels: number[]): Observable<void> {
    // Without param – set current timecode
    // tc - string timecode "00:00:00:00"
    // or
    // frame - number or frame
    return this.http.post<void>(`${deck}/set_out_point`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangJump(deck: string, channels: number[], value: {tc?: string, frame?: number, offset?: number}): Observable<void> {
    // tc - string timecode "00:00:00:00"
    // or
    // frame - number (jump to frame)
    // or
    // offset - number (like 10 or -10)
    const params = {channels};
    if (value.tc) {
      params['tc'] = value.tc;
    } else if ('frame' in value) {
      params['frame'] = value.frame;
    } else if ('offset' in value) {
      params['offset'] = value.offset;
    }
    return this.http.post<void>(`${deck}/jump`, params);
  }

  @warmUpObservable
  gangGoToNextClip(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/go_to_next_clip`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  gangGoToPrevClip(deck: string, channels: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/go_to_prev_clip`, {channels})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Record
   */

  private getParamsFromTimecode(timecode: string, channels?: number[]): any {
    const params = {};
    if (channels) {
      params['channels'] = channels;
    }
    if (timecode) {
      params['inPoint'] = timecode;
      params['crashRecord'] = true;
    }
    return params;
  }

  @warmUpObservable
  record(deck: string, channel: number, timecode?: string, captureId?: number): Observable<void> {
    const params = {};
    if (timecode) {
      params['segments'] = [{
        inPoint: timecode
      }];
      params['crashRecord'] = true;
    }
    if (captureId) {
      params['captureID'] = captureId;
      params['source'] = 'SDI' + (channel + 1);
    }
    console.log(`[RECORD][${deck}][${channel}] timecode = ${timecode} params = `, params);
    return this.http.post<void>(`${deck}/channel/${channel}/record`, params);
  }


  @warmUpObservable
  stopRecord(deck: string, channel: number, timecode?: string): Observable<void> {
    const params = this.getParamsFromTimecode(timecode);
    return this.http.post<void>(`${deck}/channel/${channel}/stop`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  breakRecord(deck: string, channel: number, timecode?: string): Observable<void> {
    const params = this.getParamsFromTimecode(timecode);
    return this.http.post<void>(`${deck}/channel/${channel}/break_rec`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  pauseRecord(deck: string, channel: number, timecode?: string): Observable<void> {
    const params = this.getParamsFromTimecode(timecode);
    return this.http.post<void>(`${deck}/channel/${channel}/pause_rec`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  resumeRecord(deck: string, channel: number, timecode?: string): Observable<void> {
    const params = this.getParamsFromTimecode(timecode);
    return this.http.post<void>(`${deck}/channel/${channel}/resume_rec`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Record group (gang)
   */

   @warmUpObservable
   gangRecord(deck: string, channels: number[], timecode?: string, captureId?: number): Observable<void> {
     const params = {channels};
     if (timecode) {
       params['segments'] = [{
         inPoint: timecode
       }];
       params['crashRecord'] = true;
     }
     if (captureId) {
      params['captureID'] = captureId;
      params['source'] = 'SDI' + (channels[0] + 1);
    }
    console.log(`[GANG][RECORD][${deck}][${channels}] timecode = ${timecode} params = `, params);
     return this.http.post<void>(`${deck}/record`, params);
   }
 
 
   @warmUpObservable
   gangStopRecord(deck: string, channels: number[], timecode?: string): Observable<void> {
     const params = this.getParamsFromTimecode(timecode, channels);
     return this.http.post<void>(`${deck}/stop`, params)
       .pipe(
         catchError(NetworkService.handleObserverError)
       );
   }
 
   @warmUpObservable
   gangBreakRecord(deck: string, channels: number[], timecode?: string): Observable<void> {
     const params = this.getParamsFromTimecode(timecode, channels);
     return this.http.post<void>(`${deck}/break_rec`, params)
       .pipe(
         catchError(NetworkService.handleObserverError)
       );
   }
 
   @warmUpObservable
   gangPauseRecord(deck: string, channels: number[], timecode?: string): Observable<void> {
     const params = this.getParamsFromTimecode(timecode, channels);
     return this.http.post<void>(`${deck}/pause_rec`, params)
       .pipe(
         catchError(NetworkService.handleObserverError)
       );
   }
 
   @warmUpObservable
   gangResumeRecord(deck: string, channels: number[], timecode?: string): Observable<void> {
     const params = this.getParamsFromTimecode(timecode, channels);
     return this.http.post<void>(`${deck}/resume_rec`, params)
       .pipe(
         catchError(NetworkService.handleObserverError)
       );
   }

  /**
   * Deck Control
   */

  @warmUpObservable
  restart(deck: string): Observable<void> {
    return this.http.post<void>(`${deck}/restart`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  exit(deck: string): Observable<void> {
    return this.http.post<void>(`${deck}/exit`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  restartDevice(deck: string): Observable<void> {
    return this.http.post<void>(`${deck}/restartDevice`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  shutdownDevice(deck: string): Observable<void> {
    return this.http.post<void>(`${deck}/shutdownDevice`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteFileDatabase(deck: string): Observable<void> {
    return this.http.delete<void>(`${deck}/file_database`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteSettings(deck: string): Observable<void> {
    return this.http.delete<void>(`${deck}/settings`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Deck UI Mode
   */

  getUiMode(deck: string): Observable<string> {
    return this.http.get<{mode: string}>(`${deck}/mode`)
      .pipe(
        map(({mode}) => mode),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateUiMode(deck: string, mode: string): Observable<void> {
    return this.http.post<void>(`${deck}/mode`, { mode })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  getUiModes(deck: string): Observable<string[]> {
    return this.http.get<{modes: string[]}>(`${deck}/modes`)
      .pipe(
        map(({modes}) => modes),
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Files
   */

  getFiles(deck: string, path: string): Observable<File[]> {
    return this.http.get<{files: File[]}>(`${deck}/files`, {params: { path }})
      .pipe(
        map(({files}) => files)
      );
  }

  getIndexedFiles(deck: string, path?: string[]): Observable<Clip[]> {
    let params = {};
    if (path && path.length) {
      params['path'] = path;
    }
    return this.http.get<{clips: Clip[]}>(`${deck}/indexed_files`, { params: params })
      .pipe(
        map(({clips}) => clips),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  postIndexedFiles(deck: string, files: string[]): Observable<any> {
    return this.http.post<void>(`${deck}/indexed_files`, { files })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  getClip(deck: string, path: string): Observable<Clip> {
    return this.http.get<Clip>(`${deck}/clip`, {params: { path } })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  createDir(deck: string, path: string): Observable<any> {
    return this.http.post<void>(`${deck}/dir`, {path})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateDir(deck: string, oldPath: string, newPath: string): Observable<any> {
    return this.http.post<void>(`${deck}/dir`, {oldPath, newPath})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteDir(deck: string, path: string): Observable<any> {
    return this.http.delete<void>(`${deck}/dir`, {params: { path }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateFile(deck: string, oldPath: string, newPath: string): Observable<any> {
    return this.http.post<void>(`${deck}/file`, {oldPath, newPath})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteFile(deck: string, path: string): Observable<any> {
    return this.http.delete<void>(`${deck}/file`, {params: { path }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Playlist
   */

  @warmUpObservable
  createPlaylist(deck: string, playlist: Playlist): Observable<Playlist> {
    return this.http.post<Playlist>(`${deck}/playlist`, { playlist })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  createPlaylistByClips(deck: string, name: string, clips: string[], drive?: string, path?: string): Observable<Playlist> {
    const params = { name, clips };
    if (drive) {
      params['drive'] = drive;
    }
    if (path) {
      params['path'] = path;
    }
    return this.http.post<Playlist>(`${deck}/playlist_by_clips`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updatePlaylist(deck: string, playlist: Playlist): Observable<any> {
    return this.http.put<void>(`${deck}/playlist`, { playlist, name: playlist.name })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deletePlaylist(deck: string, playlist: Playlist): Observable<any> {
    return this.http.delete<void>(`${deck}/playlist`, {params: {id: playlist.name}})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }
 
  getPlaylist(deck: string, name: string): Observable<Playlist> {
    // use 'id' from 'name' playlist or 'path' from playlist path
    return this.http.get<any>(`${deck}/playlist`, {params: { id: name } })
      .pipe(
        map(response => {
          return ({...response.playlist, drive: response.drive, path: response.path});
        }),
        catchError(NetworkService.handleObserverError)
      );
  }

  getPlaylists(deck: string): Observable<Playlist[]> {
    return this.http.get<any>(`${deck}/playlists`)
      .pipe(
        map(response => {
          return response.map(item => ({...item.playlist, drive: item.drive, path: item.path}));
        }),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  openPlaylist(deck: string, channel: number, name: string, editMode: boolean): Observable<void> {
    // use 'id' from 'name' playlist or 'path' from playlist path
    return this.http.post<void>(`${deck}/channel/${channel}/open_playlist`, { id: name, editMode });
  }

  @warmUpObservable
  updatePlaylistBehavior(deck: string, channel: number, playlistCueBehavior: string, playlistPlayBehavior: string): Observable<void> {
    return this.http.post<void>(`${deck}/channel/${channel}/playlist_behavior`, { playlistCueBehavior, playlistPlayBehavior })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  renamePlaylist(deck: string, oldFilepath: string, newFilepath: string): Observable<void> {
    return this.http.post<void>(`${deck}/playlist/rename`, { oldFilepath, newFilepath })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  scanPlaylist(deck: string, path: string): Observable<void> {
    return this.http.post<void>(`${deck}/playlist/scan`, { path })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * RS422
   */

  getRemoteMode(deck: string, channel: number): Observable<number> {
    return this.http.get<{mode: number}>(`${deck}/channel/${channel}/remote_mode`)
      .pipe(
        map(({mode}) => mode),
        catchError(error => of(0))
      );
  }

  @warmUpObservable
  updateRemoteMode(deck: string, channel: number, mode: number): Observable<any> {
    return this.http.post<void>(`${deck}/channel/${channel}/remote_mode`, { mode })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateRemotePlaybackMode(deck: string, channel: number, mode: RS422PlaybackMode): Observable<any> {
    return this.http.post<void>(`${deck}/channel/${channel}/remote_playback_mode`, { mode })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  rss422Info(deck: string, channel: number): Observable<RS422Info> {
    return this.http.get<RS422Info>(`${deck}/rs422/${channel}/info`)
      .pipe(
        catchError(error => of(null))
      );
  }

  rss422Timecodes(deck: string, channel: number): Observable<RS422Timecodes> {
    return this.http.get<RS422Timecodes>(`${deck}/rs422/${channel}/timecodes`)
      .pipe(
        catchError(error => of(null))
      );
  }

  @warmUpObservable
  rss422Play(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/play`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422PlayReverse(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/play_reverse`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Stop(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/stop`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Record(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/record`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422RecordServo(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/servo_rec`, {});
  }

  @warmUpObservable
  rss422InAudioEntry(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/in_audio_entry`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422OutAudioEntry(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/out_audio_entry`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422InEntry(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/in_entry`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422OutEntry(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/out_entry`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422SetInTimecode(deck: string, channel: number, timecode: string): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/set_in_timecode`, { tc: timecode })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422SetOutTimecode(deck: string, channel: number, timecode: string): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/set_out_timecode`, { tc: timecode })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422InReset(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/in_reset`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422OutReset(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/out_reset`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422AudioInReset(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/audio_in_reset`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422AudioOutReset(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/audio_out_reset`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422CueToTimecode(deck: string, channel: number, timecode: string, offset?: number): Observable<void> {
    let params: {[param: string]: string |number} = { tc: timecode };
    if (offset != null) {
      params = { offset };
    }
    return this.http.post<void>(`${deck}/rs422/${channel}/cue_to_timecode`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  rss422JogForward(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/jog_forward`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  rss422JogReverse(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/jog_reverse`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  rss422VarForward(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/var_forward`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  rss422VarReverse(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/var_reverse`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  rss422ShuttleForward(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/shuttle_forward`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  // speed (from 0.0 to 100.0) on remote device
  @warmUpObservable
  rss422ShuttleReverse(deck: string, channel: number, speed: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/shuttle_reverse`, { speed })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422FastForward(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/fast_forward`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Rewind(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/rewind`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Review(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/review`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Preview(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/preview`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422AutoEdit(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/auto_edit`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Standby(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/standby`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422SetPrerollTime(deck: string, channel: number, seconds: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/set_preroll_time`, { seconds })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422GetPrerollTime(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/get_preroll_time`, { })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Preroll(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/preroll`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422Eject(deck: string, channel: number): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/eject`, {})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422RemoteEE(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/remote_ee`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422AutoMode(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/auto_mode`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422VitcBypass(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/vitc_bypass`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422AssambleEdit(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/assamble_edit`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422InsertVideo(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/insert_video`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422InsertTc(deck: string, channel: number, onoff: boolean): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/insert_tc`, { onoff })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  rss422InsertAudio(deck: string, channel: number, audioChannelsToInsert: number[]): Observable<void> {
    return this.http.post<void>(`${deck}/rs422/${channel}/insert_audio`, { audioChannelsToInsert })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Editshare
   */

  @warmUpObservable
  editShareCreateCaptureId(deck: string, settings: StreamSettingsEditshare): Observable<number> {
    const address = `${settings.ipAddress}:${settings.port}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa(`${settings.username}:${settings.password}`)
      })
    };
    const params = {
      "project": "NewProjectName",
      "tape": "Tape1"
    };
    return this.http.post<{capture_id: number}>(`${deck}/editshare/capture_id`, params, httpOptions)
      .pipe(
        map(respose => respose.capture_id),
		    catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * NDI
   */

  getNdiIPs(deck: string): Observable<string[]> {
    return this.http.get<{ips: string[]}>(`${deck}/ndi_ips`)
      .pipe(
        map(({ips}) => ips),
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateNdiIPs(deck: string, ips: string[]): Observable<string[]> {
    return this.http.post<{ips: string[]}>(`${deck}/ndi_ips`, { ips })
      .pipe(
        map(({ips}) => ips),
        catchError(NetworkService.handleObserverError)
      );
  }

  editSharePing(deck: string): Observable<any> {
    return this.http.get<any>(`${deck}/edishare/ping`)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Ping
   */

  ping(deck: string): Observable<any> {
    return this.http.get<any>(`${deck}/ping`)
      .pipe(
		catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  changeNdiIPs(deck: string, ips: string[]): Observable<string[]> {
    return this.http.post<{ips: string[]}>(`${deck}/ndi_ips`, { ips })
      .pipe(
        map(({ips}) => ips),
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Logger (Template)
   */

  getTemplates(deck: string): Observable<Template[]> {
    return this.http.get<Template[]>(`${deck}/logger/templates`)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  getTemplate(deck: string, id: string): Observable<Template> {
    return this.http.get<Template>(`${deck}/logger/template`, {params: { id }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateTemplate(deck: string, template: Template): Observable<any> {
    return this.http.put<void>(`${deck}/logger/template`, template )
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  createTemplate(deck: string, name: string): Observable<any> {
    return this.http.post<void>(`${deck}/logger/template`, { name })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteTemplate(deck: string, id: string): Observable<void> {
    return this.http.delete<void>(`${deck}/logger/template`, { params: { id } })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Logger (Group)
   */

  getGroups(deck: string, templateId: string): Observable<Group[]> {
    return this.http.get<Group[]>(`${deck}/logger/groups`, {params: { templateId }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  getGroup(deck: string, id: string): Observable<Group> {
    return this.http.get<Group>(`${deck}/logger/group`, {params: { id }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateGroup(deck: string, group: Group): Observable<any> {
    return this.http.put<void>(`${deck}/logger/group`, group )
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  createGroup(deck: string, name: string, templateId: string): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${deck}/logger/group`, { name, templateId })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteGroup(deck: string, id: string): Observable<void> {
    return this.http.delete<void>(`${deck}/logger/group`, { params: { id } })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Logger (Value)
   */

  getValues(deck: string, groupId: string): Observable<GroupValue[]> {
    return this.http.get<GroupValue[]>(`${deck}/logger/values`, {params: { groupId }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  getValue(deck: string, id: string): Observable<GroupValue> {
    return this.http.get<GroupValue>(`${deck}/logger/value`, {params: { id }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateValue(deck: string, value: GroupValue): Observable<any> {
    return this.http.put<void>(`${deck}/logger/value`, value )
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  createValue(deck: string, name: string, groupId: string): Observable<any> {
    return this.http.post<void>(`${deck}/logger/value`, { name, groupId })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteValue(deck: string, id: string): Observable<void> {
    return this.http.delete<void>(`${deck}/logger/value`, { params: { id } })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  /**
   * Logger (Markers)
   */

  getMarkers(deck: string, fileId: number[]): Observable<Marker[]> {
    return this.http.get<Marker[]>(`${deck}/logger/markers`, {params: { fileId: fileId as any[] }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  getMarker(deck: string, markerId: number): Observable<Marker> {
    return this.http.get<Marker[]>(`${deck}/logger/markers`, {params: { markerId: markerId as any }})
      .pipe(
        map(result => result[0]),
        catchError(NetworkService.handleObserverError)
      );
  }

  getMarkersAndValues(deck: string, fileId: string): Observable<Marker[]> {
    return this.http.get<Marker[]>(`${deck}/logger/markers_and_values`, {params: { id: fileId }})
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateMarker(deck: string, marker: Marker): Observable<any> {
    return this.http.put<void>(`${deck}/logger/marker`, marker )
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  updateMarkersAndValues(deck: string, marker: Marker): Observable<any> {
    return this.http.put<void>(`${deck}/logger/markers_and_values`, marker )
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  createMarker(deck: string, marker: Marker): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${deck}/logger/marker`, marker)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  createMarkersAndValues(deck: string, marker: Marker): Observable<any> {
    return this.http.post<void>(`${deck}/logger/markers_and_values`, marker)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteMarker(deck: string, id: string): Observable<void> {
    return this.http.delete<void>(`${deck}/logger/marker`, { params: { id } })
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }
  
  @warmUpObservable
  uploadMarker(deck: string, clipId: number, ext: string, data: string, fileName?: string): Observable<any> {
    const params = {
      clipId,
      ext,
      data
    };
    if (fileName) {
      params['fileName'] = fileName;
    }
    return this.http.post<void>(`${deck}/logger/upload_marker`, params)
      .pipe(
        catchError(NetworkService.handleObserverError)
      );
  }


}
