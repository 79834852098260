<div class="deck">
  <!-- Info -->
  <div class="info" >
    <app-online-status class="online-status" [value]="isOnline"></app-online-status>
    <div class="deck-name">
      <input class="name inline-input" placeholder="Enter Deck Name..." [ngModel]="deck?.name" (blur)="updatedName($event.target.value)">
      <input class="address inline-input" placeholder="Enter IP Address..." [ngModel]="deck?.address" (blur)="updatedAddress($event.target.value)">
      <div class="ssl" [class.on]="deck?.ssl" (click)="updateSSL(!deck?.ssl)">SSL</div>
    </div>
    <div class="info-actions">
      <mat-icon svgIcon="folder" class="action-icon" [class.disabled]="!isOnline" (click)="openFileManager()"></mat-icon>
      <mat-icon svgIcon="close" class="action-icon" (click)="deleteDeck()"></mat-icon>
    </div>
  </div>

  <!-- Actions -->
  <div class="actions" *ngIf="isOnline">
    <!-- <div class="row">
      <div class="label">Reset data:</div>
      <div class="button" (click)="deleteSettings()">Reset all pref. to Default</div>
      <div class="button" (click)="deleteFileDatabase()">Purge Clip DB</div>
    </div> -->
    <div class="row">
      <div class="label">Application:</div>
      <div class="button" (click)="restartApp()">Restart server app</div>
      <div class="button" (click)="exitApp()">Exit server app</div>
    </div>
    <div class="row">
      <div class="label">System:</div>
      <div class="button" (click)="restartDevice()">Restart deck</div>
      <div class="button" (click)="shutdownDevice()">Shutdown deck</div>
    </div>
    <div class="row">
      <div class="label">NDI:</div>
      <div class="button" (click)="openNdiIpsSettings()">Source IP</div>
    </div>
    <!-- <div class="row">
      <div class="label">UI Mode:</div>
      <app-select class="select" [options]="uiModeOptions" [selected]="'normal'" (selectedChange)="changeUiMode($event)" placeholder="N/A"></app-select>
    </div> -->
  </div>

  <!-- Disks -->
  <div class="disks" *ngIf="status && isOnline">
    <div class="drive" *ngFor="let drive of drives">
      <div class="drive-name">{{ drive?.driveLetter | uppercase }}:</div>
      <app-disc-progress class="drive-progress" [total]="drive?.totalBytes" [free]="drive?.availBytes"></app-disc-progress>
    </div>
  </div>

  <!-- Inputs -->
  <div class="inputs" *ngIf="isOnline">
    <div class="deck-input" *ngFor="let channel of channels">
      <div class="online-status" [class.online]="true" [class.record]="status?.inputs[channel.index]?.record?.record" [class.pauseRecord]="status?.inputs[channel.index]?.record?.pauseRecord"></div>
      <div class="name">{{ channel.name }}</div>
    </div>
  </div>

  <!-- Settings -->
  <div class="settings" *ngIf="isOnline">
    <div class="settings-title">Preferences</div>
    <div class="settings-content">
      <div class="option">
        <div class="title">Overwrite existing file</div>
        <app-select class="select" [options]="fileOverwriteOptions" [selected]="preferences.fileOverwrite" (selectedChange)="updateFileOverwrite($event.value)" placeholder="N/A"></app-select>
      </div>
    </div>
  </div>

  <!-- Version -->
  <div class="version">{{ status?.deckInfo?.version }}</div>

</div>
