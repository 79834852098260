import { Permissions } from './permissions';

export type UserNames = 'root' | 'admin' | 'user' | 'guest';

export class User {
  name: UserNames;
  displayName: string;
  password: string;
  permissions: Permissions;
}

export type UsersList = { [ name in UserNames ]?: User };

export const DEFAULT_USERS = {
  root: {
    name: 'root',
    displayName: 'Superuser',
    password: null,
    permissions: {
      changePassowrd: true,
      configureInputSources: true,
      controlRecord: true,
      editPresetSettings: true,
      editPathTemplates: true,
      editDiskDestination: true,
      editLists: true,
      editTags: true,
      editSelectionsGroups: true,
      addScheduledRecords: true,
      editScheduledRecords: true,
      deleteRecordedFiles: true
    }
  },
  admin: {
    name: 'admin',
    displayName: 'Admin',
    password: null,
    permissions: {
      changePassowrd: true,
      configureInputSources: true,
      controlRecord: true,
      editPresetSettings: true,
      editPathTemplates: true,
      editDiskDestination: true,
      editLists: true,
      editTags: true,
      editSelectionsGroups: true,
      addScheduledRecords: true,
      editScheduledRecords: true,
      deleteRecordedFiles: true
    }
  },
  user: {
    name: 'user',
    displayName: 'Operator',
    password: null,
    permissions: {
      changePassowrd: true,
      configureInputSources: true,
      controlRecord: true,
      editPresetSettings: true,
      editPathTemplates: true,
      editDiskDestination: true,
      editLists: true,
      editTags: true,
      editSelectionsGroups: true,
      addScheduledRecords: true,
      editScheduledRecords: true,
      deleteRecordedFiles: true
    }
  },
  guest: {
    name: 'guest',
    displayName: 'Guest',
    password: null,
    permissions: {
      changePassowrd: false,
      configureInputSources: true,
      controlRecord: false,
      editPresetSettings: false,
      editPathTemplates: false,
      editDiskDestination: false,
      editLists: false,
      editTags: false,
      editSelectionsGroups: false,
      addScheduledRecords: false,
      editScheduledRecords: false,
      deleteRecordedFiles: false
    }
  }
} as UsersList;
