export class DeckError {
  code: number;
  data: string;
  encoderSpec: string;
  input: number;
  message: string;
  type: string;

  static create(message: string, input: number, option?: {code?: number, data?: string, encoderSpec?: string, type?: string}): DeckError {
    const error = new DeckError;
    error.message = message;
    error.input = input;
    error.code = option?.code;
    error.data = option?.data;
    error.encoderSpec = option?.encoderSpec;
    error.type = option?.type;
    return error;
  }
}
