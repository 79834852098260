import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { LayoutService } from '@modules/layout/services/layout.service';
import { UserService } from '@modules/users/services/user.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { Layout } from '@modules/layout/types/layout';
import { User } from '@modules/users/types/user';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit, OnDestroy {

  // Public
  public layout: Layout;
  public user: Observable<User>;
  public showPlaylist = false;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private layoutService: LayoutService,
    private userService: UserService,
    private channelService: ChannelService,
    private streamService: StreamService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Layout
    this.layoutService.getLayout()
      .pipe(takeUntil(this.alive))
      .subscribe(layout => this.layout = layout);
    // Get User
    this.user = this.userService.getCurrentUser();
    // Get Playlist status
    this.channelService.getSelectedChannels()
      .pipe(
        filter(channels => {
          if (channels.length !== 1) {
            this.showPlaylist = false;
          }
          return channels.length === 1;
        }),
        map(channels => channels[0]),
        switchMap(channel => this.streamService.getStream(channel.streamId)),
        switchMap(stream => combineLatest([this.streamService.getStatus(stream), this.layoutService.getLayout().pipe(map(layout => layout.playlist))])),
        map(([status, isLayoutPlaylist]) => status?.playback?.isPlaylistOpenFile && isLayoutPlaylist),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(status => this.showPlaylist = status);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}
