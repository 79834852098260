<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">File manager</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="file-manager-content">

    <!-- Path -->
    <div class="current-path" *ngIf="currentPath.value.length > 0">{{ currentPath | async | path }}</div>

    <!-- Disks -->
    <div class="file-list" *ngIf="!(currentPath | async).length">
      <div class="file"
        *ngFor="let disk of drives"
        [class.selected]="disk === selectedDisk"
        (appClick)="selectDisk(disk)"
        (appDblClick)="changeDisk(disk)"
      >
        <mat-icon svgIcon="disk" class="icon disk"></mat-icon>
        <div class="title">{{ disk.driveLetter | uppercase }}: {{ disk.label }}</div>
        <div class="size">{{ disk.availBytes | fileSize }} / {{ disk.totalBytes | fileSize }}</div>
      </div>
    </div>

    <!-- File lists -->
    <div class="file-list" *ngIf="(currentPath | async).length > 0">
      <div class="file" (appDblClick)="changeDirUp()">
        <mat-icon svgIcon="folder" class="icon"></mat-icon>
        <div class="title">..</div>
      </div>
      <div
        *ngFor="let file of files"
        class="file"
        [class.selected]="selection.isSelected(file)"
        (appClick)="selectFile($event, file)"
        (appDblClick)="changeDir(file)"
      >
        <mat-icon [svgIcon]="file.isDir ? 'folder' : 'file'" class="icon"></mat-icon>
        <div class="title">{{ file.filename }}</div>
        <div class="size">{{ file.fileSize === 0 ? '--' : (file.fileSize | fileSize) }}</div>
      </div>
    </div>

    <!-- Error -->
    <div class="message error" *ngIf="error" (click)="error = null">
      <div class="title">Error</div>
      <div class="description">Note: {{ error?.error?.message}}</div>
    </div>

  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="select()" [class.disabled]="error || (!currentPath.value.length && !selectedDisk) || (data?.onlyFile && data?.singleSelect && (selection?.selected?.length !== 1 || (selection?.selected?.length === 1 && selection?.selected[0]?.isDir)))">Select</div>
</div>
