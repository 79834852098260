import { Component, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

// Type
import { Volume } from '@modules/project/types/volume';

@Component({
  selector: 'app-volume-popover',
  templateUrl: './volume-popover.component.html',
  styleUrls: ['./volume-popover.component.less']
})
export class VolumePopoverComponent implements OnInit {

  // Public
  public volume: Volume;

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.stateService.getState()
      .pipe(takeUntil(this.alive))
      .subscribe(state => this.volume = state.volume);
  }

  /**
   * Methods
   */

  volumeUp(): void {
    this.volume.value = Math.min(this.volume.value + 10, 100);
    this.stateService.updateState({volume: this.volume});
  }

  volumeDown(): void {
    this.volume.value = Math.max(this.volume.value - 10, 0);
    this.stateService.updateState({volume: this.volume});
  }

  volumeChanged(value: number): void {
    this.volume.value = value;
    this.stateService.updateState({volume: this.volume});
  }

  muteLeft(): void {
    this.volume.muteLeft = !this.volume.muteLeft;
    this.stateService.updateState({volume: this.volume});
  }

  muteRight(): void {
    this.volume.muteRight = !this.volume.muteRight;
    this.stateService.updateState({volume: this.volume});
  }


}
