// Core
import { Component, OnInit } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { combineLatest, forkJoin, of, Subject, timer } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

// Services
import { ChannelService } from '@modules/channel/services/channel.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { UserService } from '@modules/users/services/user.service';
import { LayoutService } from '@modules/layout/services/layout.service';

// Types
import { Channel } from '@modules/channel/types/channel';
import { Stream } from '@modules/stream/types/stream';
import { StreamStatus } from '@modules/stream/types/stream-status';

@Component({
  selector: 'app-group-control',
  templateUrl: './group-control.component.html',
  styleUrls: ['./group-control.component.less']
})
export class GroupControlComponent implements OnInit {

  // Public
  public record = true;
  public state: 'neutral' | 'record' | 'playback' | 'ambiguous' = 'neutral';
  public allowedControlRecord: boolean;
  public locked = false;
  public haveFileToOpen = false;
  public groupAction = {
    record: false,
    stopRecord: false,
    breakRecord: false,
    pauseRecord: false,
    resumeRecord: false,
  };
  public sameDeck = false;
  public isWaitingForRecord = false;

  // Private
  private allChannels: Channel[];
  private selectedStreams: { stream: Stream, status: StreamStatus }[];
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private channelService: ChannelService,
    private streamService: StreamService,
    private layoutService: LayoutService,
    userService: UserService
  ) {
    userService
      .getCurrentPermission('controlRecord')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowedControlRecord = allowed);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.layoutService.getLayout()
      .pipe(
        map(layout => layout.channelList),
        distinctUntilChanged(isEqual),
        switchMap(isChannelList =>
          isChannelList
            ? this.channelService.getChannelsList()
            : this.channelService.getChannels()
        ),
        takeUntil(this.alive)
      )
      .subscribe(channels => this.allChannels = channels);

    this.channelService.getSelectedChannels()
      .pipe(
        switchMap(channels => {
          const channelsStreams = channels
            .filter(channel => channel.streamId)
            .map(channel =>
              this.streamService
                .getStream(channel.streamId)
                .pipe(
                  switchMap(stream =>
                    this.streamService.getStatus(stream)
                      .pipe(
                        distinctUntilChanged((previous, next) => JSON.stringify(previous) === JSON.stringify(next)),
                        map(status => ({ stream, status }))
                      )
                  )
                )
            );
          return channelsStreams.length ? combineLatest(channelsStreams) : of([]);
        }),
        takeUntil(this.alive)
      )
      .subscribe(streams => {
        this.selectedStreams = streams;

        // Select all streams from same Deck
        this.sameDeck = this.selectedStreams?.length > 1 && this.selectedStreams.every((value, _, array) => array[0].stream.deckId === value.stream.deckId);

        // Detecting current controls state
        let state: 'neutral' | 'record' | 'playback' | 'ambiguous';
        for (const stream of streams) {
          let streamState: 'neutral' | 'record' | 'playback';
          if ((stream?.status?.playback?.isUnloaded || (!stream?.status?.playback && stream?.status?.record)) && stream.status.record?.record) {
            streamState = 'record';
          } else if (stream.status.playback?.isLoaded) {
            streamState = 'playback';
          } else {
            streamState = 'neutral';
          }

          state = state || streamState;

          if (state !== streamState) {
            state = 'ambiguous';
            break;
          }
        }

        this.state = state || 'neutral';

        // Posible to open file
        this.haveFileToOpen = streams.filter(stream => stream?.status?.playback?.haveFileToOpen).length > 0;
        
        // Waiting for Rec
        this.isWaitingForRecord = streams.some(stream => stream?.status?.record?.isWaitingForRecord);
      });
  }

  /**
   * Actions
   */

  selectAll(): void {
    this.channelService.selectChannels(this.allChannels);
  }

  clearAll(): void {
    this.channelService.selectChannels([]);
  }

  lock(): void {
    this.locked = !this.locked;
  }

  /**
   * Record
   */

  startRecord(): void {
    const streams = this.selectedStreams
      .filter(stream => (stream?.status?.playback?.isUnloaded || (!stream?.status?.playback && stream?.status?.record)) && !stream?.status?.record?.record)
      .map(stream => stream.stream);
    if (streams.length) {
      if (streams.length > 1) {
        this.updateGroupAction('record');
      }
      this.streamService.groupRecord(streams);
    }
  }

  stopRecord(): void {
    const streams = this.selectedStreams
      .filter(stream => stream.status.record.record || stream.status.record.isWaitingForRecord)
      .map(stream => stream.stream);
    if (streams.length) {
      if (streams.length > 1) {
        this.updateGroupAction('stopRecord');
      }
      this.streamService.groupStopRecord(streams);
    }
  }

  breakRecord(): void {
    const streams = this.selectedStreams
      .filter(stream => stream.status.record.record)
      .map(stream => stream.stream);
    if (streams.length) {
      if (streams.length > 1) {
        this.updateGroupAction('breakRecord');
      }
      this.streamService.groupBreakRecord(streams);
    }
  }

  pauseRecord(): void {
    const streams = this.selectedStreams
      .filter(stream => stream.status.record.record && !stream.status.record.pauseRecord)
      .map(stream => stream.stream);
    if (streams.length) {
      if (streams.length > 1) {
        this.updateGroupAction('pauseRecord');
      }
      this.streamService.groupPauseRecord(streams);
    }
  }

  resumeRecord(): void {
    const streams = this.selectedStreams
      .filter(stream => stream.status.record.record && stream.status.record.pauseRecord)
      .map(stream => stream.stream);
    if (streams.length) {
      if (streams.length > 1) {
        this.updateGroupAction('resumeRecord');
      }
      this.streamService.groupResumeRecord(streams);
    }
  }

  updateGroupAction(field: string): void {
    this.groupAction[field] = true;
    timer(this.streamService.gangRecordToleranceTime * 1000)
      .pipe(take(1))
      .subscribe(() => this.groupAction[field] = false);
  }

  /**
   * Playback
   */

  private getPlaybackStreams(): Stream[][] {
    const streams = this.selectedStreams
      .filter(stream => stream.status.playback.isLoaded)
      .map(stream => stream.stream);
    let result = [];

    let openStreams = [];
    for (const stream of streams) {
      if ((openStreams.findIndex(item => item.id === stream.id)) < 0) {
        const gangStreams = this.streamService.getGangStreamsSync(stream);
        if (gangStreams && gangStreams.length > 1) {
          result.push(gangStreams);
          openStreams.push(...gangStreams);
        } else {
          result.push([stream]);
          openStreams.push(stream);
        }
      }
    }
    const syncNoGang = result.every(value => value.length === 1);
    if (this.sameDeck && syncNoGang) {
      return [streams];
    }
    return result;
  }

  private getOpenStreams(): Stream[][] {
    const streams = this.selectedStreams
      .filter(stream => !stream?.status?.record?.record && stream?.status?.playback?.haveFileToOpen)
      .map(stream => stream.stream);
    let result = [];
    let openStreams = [];
    for (const stream of streams) {
      if ((openStreams.findIndex(item => item.id === stream.id)) < 0) {
        const gangStreams = this.streamService.getGangStreamsSync(stream);
        if (gangStreams && gangStreams.length > 1) {
          result.push(gangStreams);
          openStreams.push(...gangStreams);
        } else {
          result.push([stream]);
          openStreams.push(stream);
        }
      }
    }
    const syncNoGang = result.every(value => value.length === 1);
    if (this.sameDeck && syncNoGang) {
      return [streams];
    }
    return result;
  }

  private isAutoPlayback(streams: Stream[][]): boolean {
    return streams
      .reduce((accumulator, array) => {
        return [...accumulator, ...array];
      }, [])
      .map(stream => this.allChannels.find(channel => channel?.streamId === stream?.id))
      .filter(channel => !!channel)
      .every(channel => channel.layoutSettings.autoPlaybackLoadingFile);
  }

  openFile(): void {
    const openStreams = this.getOpenStreams();
    for (const streams of openStreams) {
      if (streams.length > 1) {
        forkJoin([streams.map(stream => this.streamService.openFile(stream))])
          .pipe(
            delay(1000),
            filter(() => this.isAutoPlayback(openStreams)),
            takeUntil(this.alive),
          )
          .subscribe(() => this.play());
      } else {
        this.streamService.openFile(streams[0])
          .pipe(
            filter(() => this.isAutoPlayback(openStreams)),
            takeUntil(this.alive),
          )
          .subscribe(() => this.play());
      }
    }
  }

  play(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      (streams.length > 1)
        ? this.streamService.gangPlay(streams)
        : this.streamService.play(streams[0]);
    }
  }

  reverse(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      (streams.length > 1)
        ? this.streamService.gangReverse(streams)
        : this.streamService.reverse(streams[0]);
    }
  }

  fastForward(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      (streams.length > 1)
        ? this.streamService.gangFastForward(streams)
        : this.streamService.fastForward(streams[0]);
    }
  }

  rewind(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      (streams.length > 1)
        ? this.streamService.gangRewind(streams)
        : this.streamService.rewind(streams[0]);
    }
  }

  pause(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      (streams.length > 1)
        ? this.streamService.gangPause(streams)
        : this.streamService.pause(streams[0]);
    }
  }

  goToStart(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      (streams.length > 1)
        ? this.streamService.gangGoToStart(streams)
        : this.streamService.goToStart(streams[0]);
    }
  }

  goToEnd(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      (streams.length > 1)
        ? this.streamService.gangGoToEnd(streams)
        : this.streamService.goToEnd(streams[0]);
    }
  }

  ejectFile(): void {
    const playbackStreams = this.getPlaybackStreams();
    for (const streams of playbackStreams) {
      streams.forEach(stream => this.streamService.ejectFile(stream));
    }
  }

}
