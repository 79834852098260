import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { LayoutService } from '@modules/layout/services/layout.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { Layout } from '@modules/layout/types/layout';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.less']
})
export class RightPanelComponent implements OnInit, OnDestroy {

  // Public
  public layout: Layout;
  public showPlaylist = false;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private layoutService: LayoutService,
    private channelService: ChannelService,
    private streamService: StreamService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Layout
    this.layoutService.getLayout()
      .pipe(takeUntil(this.alive))
      .subscribe(layout => this.layout = layout);
    // Channel with Playlist
    this.channelService.getSelectedChannels()
      .pipe(
        filter(channels => {
          if (channels.length !== 1) {
            this.showPlaylist = false;
          }
          return channels.length === 1;
        }),
        map(channels => channels[0]),
        switchMap(channel => this.streamService.getStream(channel.streamId)),
        switchMap(stream => combineLatest([this.streamService.getStatus(stream), this.layoutService.getLayout().pipe(map(layout => layout.playlist))])),
        map(([status, isLayoutPlaylist]) => isLayoutPlaylist && status?.playback?.isPlaylistOpenFile && status?.playback?.isLoaded),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive)
      )
      .subscribe(status => this.showPlaylist = status);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

}
