import { UniqueID } from '@modules/elements/types/unique-id';

// Lodash
import { cloneDeep } from 'lodash-es';

export type ScheduleAction = 'record';
export type ScheduleInterval = 'once' | 'repeat';

export class Schedule {
  id: string;
  enabled: boolean;
  name: string;
  user: string;
  presetId: string;
  streamId: string;
  groupId: string;
  startDate: Date;
  endDate: Date;
  action: ScheduleAction;
  interval: ScheduleInterval;
  rrule: string;

  constructor(name?: string) {
    this.id = UniqueID.generate();
    this.name = name;
    this.action = 'record';
    this.interval = 'once';
    this.enabled = true;
    this.presetId = null;
  }

  static cloneWithNewId(schedule: Schedule): Schedule {
    const cloneSchedule = cloneDeep(schedule);
    cloneSchedule.id = UniqueID.generate();
    return cloneSchedule;
  }
}
