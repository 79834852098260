<div class="left-panel-content">

  <!-- Presets -->
  <app-preset-list *ngIf="layout.presets" class="presets"></app-preset-list>

  <!-- User Lists -->
  <app-user-tag-list *ngIf="layout.userLists" class="user-lists"></app-user-tag-list>

  <!-- Global Lists -->
  <app-global-tag-list *ngIf="layout.globalLists" class="user-lists"></app-global-tag-list>

  <!-- XML tags -->
  <!-- <app-xml-tag-list *ngIf="layout.xmlTags" class="xml-tags"></app-xml-tag-list> -->

</div>
