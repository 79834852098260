import { Injectable, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DOCUMENT } from "@angular/common";

// Types
type Options = {
  element: any;
  description: string | undefined;
  keys: string;
}

@Injectable({
  providedIn: 'root'
})
export class HotkeysService {

  // Public
  public hotkeys = new Map();
  public defaults: Partial<Options> = {
    element: this.document
  }

  /**
   * Constructor
   */

  constructor(
    private eventManager: EventManager,
    @Inject(DOCUMENT) private document: Document
  ) { }

  /**
   * Methods
   */

  addShortcut(options: Partial<Options>) {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    merged.description && this.hotkeys.set(merged.keys, merged.description);

    return new Observable(observer => {
      const handler = (e) => {
        console.log('[Hotkey]', merged.description || `Presed ${merged.keys} (No Description)`)
        e.preventDefault()
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      return () => {
        dispose();
        this.hotkeys.delete(merged.keys);
      };
    })
  }

}