import { Component, OnInit } from '@angular/core';

// Types
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-channels-settings',
  templateUrl: './channels-settings.component.html',
  styleUrls: ['./channels-settings.component.less']
})
export class ChannelsSettingsComponent implements OnInit {

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<ChannelsSettingsComponent>,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }

}
