import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';

// Modules
import { PopoverModule } from '@modules/popover/popover.module';
import { ElementsModule } from '@modules/elements/elements.module';

// Directives
import { Rs422ScrollDirective } from '../rs422/directives/rs422-scroll.directive';

// Components
import { Rs422ControlComponent } from '../rs422/components/rs422-control/rs422-control.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
    ElementsModule,


    // Material
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
  ],
  declarations: [
    Rs422ControlComponent,
    Rs422ScrollDirective,
  ],
  exports: [
    Rs422ControlComponent,
    Rs422ScrollDirective,
  ]
})
export class Rs422Module { }
