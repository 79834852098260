<div class="volume-popover-content">

  <!-- Volume -->
  <div class="volume">
    <mat-icon svgIcon="minus-circle" class="button-icon" (click)="volumeDown()"></mat-icon>
    <mat-slider class="volume-slider" [max]="100" [min]="0" [step]="1" [value]="volume.value" (change)="volumeChanged($event.value)"></mat-slider>
    <mat-icon svgIcon="plus-circle" class="button-icon" (click)="volumeUp()"></mat-icon>
  </div>

  <!-- Mute -->
  <!-- <div class="mute"> -->
    <!-- Left -->
    <!-- <div class="toolbar-button" [class.selected]="volume.muteLeft" (click)="muteLeft()">
      <mat-icon svgIcon="toolbar-volume-mute-l" class="icon"></mat-icon>
      <div class="text">Mute</div>
    </div> -->
    <!-- Right -->
    <!-- <div class="toolbar-button" [class.selected]="volume.muteRight" (click)="muteRight()">
      <mat-icon svgIcon="toolbar-volume-mute-r" class="icon"></mat-icon>
      <div class="text">Mute</div>
    </div>
  </div> -->

</div>
