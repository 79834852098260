<div class="preset-settings-output-overlays">

  <!-- Disabled Status -->
  <div class="settings-status-text" *ngIf="recording">Settings unavailable during recording</div>
  <div class="settings-status-text" *ngIf="!deckOnline">Settings are not available if there is no connection</div>
  <div class="settings-status-text" *ngIf="preset && !preset?.isValid()">
    <mat-icon svgIcon="warning" class="icon error"></mat-icon>
    Selected preset is from a prior version: please delete and create a new one.
  </div>

  <!-- Enable charOut -->
  <div class="preset-settings-output-overlays-enable">
    <mat-checkbox class="checkbox" [disabled]="!status" [checked]="status?.capture" (change)="enableCharOutCapture($event)" disableRipple>CharOut Capture</mat-checkbox>
    <mat-checkbox class="checkbox" [disabled]="!status" [checked]="status?.playback" (change)="enableCharOutPayback($event)" disableRipple>CharOut Payback</mat-checkbox>
  </div>
  
  <div class="preset-settings-output-overlays-content">
  
    <!-- Options -->
    <div class="overlay-options">
  
      <!-- Status -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.timecode')">
        <div class="label">Status</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.timecode.statusEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'timecode'" (click)="charOutEditField('timecode')">Edit</div>
      </div>
  
      <!-- Border -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.borderStatus')">
        <div class="label">Border</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.borderStatus.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'borderStatus'" (click)="charOutEditField('borderStatus')">Edit</div>
      </div>
  
      <!-- Record Dot -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.redDotStatus')">
        <div class="label">Record Dot</div>
        <mat-checkbox class="checkbox" [disabled]="false"  [(ngModel)]="settings.channels[deckChannel].charOut.redDotStatus.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
      </div>
  
      <!-- Timecode -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.timecode')">
        <div class="label">Timecode</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.timecode.timecodeEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'timecode'" (click)="charOutEditField('timecode')">Edit</div>
      </div>
  
      <!-- Timecode Source -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.captureTcSource')">
        <div class="label">Timecode Source</div>
        <app-select class="select" [options]="filtredSettingsOptions?.encoderTcSource" [selected]="settings.channels[deckChannel].charOut.captureTcSource" (selectedChange)="updateTimecodeSource($event.value)" [highlighted]="!isDefaultSetting('channels[' + deckChannel + '].charOut.captureTcSource')"></app-select>
      </div>
  
      <!-- Encoder Name -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.timecode')">
        <div class="label">Encoder Name</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.timecode.encNameEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'timecode'" (click)="charOutEditField('timecode')">Edit</div>
      </div>
  
      <!-- File Name -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.fileName')">
        <div class="label">File Name</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.fileName.fileNameEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'fileName'" (click)="charOutEditField('fileName')">Edit</div>
      </div>
  
      <!-- Codec -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.codec')">
        <div class="label">Codec</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.codec.codecEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'codec'" (click)="charOutEditField('codec')">Edit</div>
      </div>
  
      <!-- Quality -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.quality')">
        <div class="label">Quality</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.quality.qualityEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'quality'" (click)="charOutEditField('quality')">Edit</div>
      </div>
  
      <!-- User Text -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.userText')">
        <div class="label">User Text</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.userText.textEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'userText'" (click)="charOutEditField('userText')">Edit</div>
      </div>
  
      <!-- Primary Audio -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.audioLevelsMaster')">
        <div class="label">Primary Audio</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.audioLevelsMaster.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'audioLevelsMaster'" (click)="charOutEditField('audioLevelsMaster')">Edit</div>
      </div>
  
      <!-- Proxy Audio -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.audioLevelsProxy')">
        <div class="label">Proxy Audio</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.audioLevelsProxy.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'audioLevelsProxy'" (click)="charOutEditField('audioLevelsProxy')">Edit</div>
      </div>
  
      <!-- Encode/Write Queues -->
      <!-- <div class="field">
        <div class="label">Encode/Write Queues</div>
        <mat-checkbox class="checkbox" [disabled]="false" [ngModel]="false" disableRipple></mat-checkbox>
      </div> -->
  
      <!-- Snap -->
      <div class="field end">
        <div class="label">Snap</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].charOut.snap" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
      </div>
  
    </div>
  
    <!-- Editor -->
    <div class="overlay-info">
  
      <!-- Preview -->
      <div class="overlay-preview">
  
        <!-- Image -->
        <mat-icon svgIcon="smpte-color-bars" class="smpte-image"></mat-icon>
  
        <!-- Elements -->
        <div class="elements">
  
          <!-- Audio levels (Master) -->
          <app-audio-levels
            #audioLevelsPrimary
            *ngIf="settings.channels[deckChannel].charOut.audioLevelsMaster.enabled"
            class="overlay-audio-element"
            [ngStyle]="getAudioLevelsStyles('audioLevelsMaster')"
            [class.disabled]="false"
            [isPlayback]="false"
            [stream]="stream"
            [settings]="settings"
            [selected]="stream?.audioPair"
            cdkDrag
            cdkDragBoundary=".overlay-preview"
            (cdkDragEnded)="dragEnded($event, 'audioLevelsMaster')"
          ></app-audio-levels>
  
          <!-- Audio levels (Proxy) -->
          <app-audio-levels
            #audioLevelsProxy
            *ngIf="settings.channels[deckChannel].charOut.audioLevelsProxy.enabled"
            class="overlay-audio-element"
            [ngStyle]="getAudioLevelsStyles('audioLevelsProxy')"
            [class.disabled]="false"
            [isPlayback]="false"
            [stream]="stream"
            [settings]="settings"
            [selected]="stream?.audioPair"
            [encoderIndex]="1"
            cdkDrag
            cdkDragBoundary=".overlay-preview"
            (cdkDragEnded)="dragEnded($event, 'audioLevelsProxy')"
          ></app-audio-levels>
  
          <!-- Timecode -->
          <div
            class="overlay-element"
            *ngIf="settings.channels[deckChannel].charOut.timecode.timecodeEnabled || settings.channels[deckChannel].charOut.timecode.encNameEnabled"
            [ngStyle]="getStyles('timecode')"
            cdkDrag
            cdkDragBoundary=".overlay-preview"
            (cdkDragEnded)="dragEnded($event, 'timecode')"
          >
            <!-- Encoder Name -->
            <div *ngIf="settings.channels[deckChannel].charOut.timecode.encNameEnabled" class="encoder-name">V{{ deckChannel + 1 }}</div>
            <!-- Status -->
            <div *ngIf="settings.channels[deckChannel].charOut.timecode.statusEnabled" class="encoder-name">{{ '&lt;STATUS&gt;' }}</div>
            <!-- TC -->
            <div *ngIf="settings.channels[deckChannel].charOut.timecode.timecodeEnabled">00:00:00:00</div>
          </div>
  
          <!-- File Name -->
          <div
            class="overlay-element"
            *ngIf="settings.channels[deckChannel].charOut.fileName.fileNameEnabled"
            [ngStyle]="getStyles('fileName')"
            cdkDrag
            cdkDragBoundary=".overlay-preview"
            (cdkDragEnded)="dragEnded($event, 'fileName')"
          >
            {{ filename }}
          </div>
  
          <!-- Codec -->
          <div
            class="overlay-element"
            *ngIf="settings.channels[deckChannel].charOut.codec.codecEnabled"
            [ngStyle]="getStyles('codec')"
            cdkDrag
            cdkDragBoundary=".overlay-preview"
            (cdkDragEnded)="dragEnded($event, 'codec')"
          >
            {{ getTitle(settings?.encode[0]?.activeCodec, 'encoderCodec') || '&lt;Codec&gt;' }}
          </div>
  
          <!-- Quality -->
          <div
            class="overlay-element"
            *ngIf="settings.channels[deckChannel].charOut.quality.qualityEnabled"
            [ngStyle]="getStyles('quality')"
            cdkDrag
            cdkDragBoundary=".overlay-preview"
            (cdkDragEnded)="dragEnded($event, 'quality')"
          >
          {{ getTitle(settings?.encode[0].activeQuality, 'encoderQuality') || '&lt;Quality&gt;' }}
          </div>
  
          <!-- User Text -->
          <div
            class="overlay-element"
            *ngIf="settings.channels[deckChannel].charOut.userText.textEnabled"
            [ngStyle]="getStyles('userText')"
            cdkDrag
            cdkDragBoundary=".overlay-preview"
            (cdkDragEnded)="dragEnded($event, 'userText')"
          >
            {{ settings.channels[deckChannel].charOut.userText.text }}
          </div>

          <!-- Border -->
          <div class="preview-border" [ngStyle]="{'border-color': argbToRgba(settings.channels[this.deckChannel].charOut.idleBorderColors.backColor)}" *ngIf="settings.channels[deckChannel].charOut.borderStatus.enabled"></div>
  
        </div>
  
      </div>
  
      <!-- Edit -->
      <div class="overlay-editor" *ngIf="editField && editField.length">
  
        <!-- Settings -->
        <div class="overlay-settings" *ngIf="editField !== 'borderStatus'">
  
          <!-- Text Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.' + editField + '.textColor')">
            <div class="label">Text Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].charOut[editField].textColor" (colorChange)="updateColor($event, 'textColor')"></app-color-select>          
          </div>
  
          <!-- Text Size -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.' + editField + '.fontSize')">
            <div class="label">Text Size</div>
            <input class="input" type="number" min="8" max="72" [(ngModel)]="settings.channels[deckChannel].charOut[editField].fontSize" (blur)="updateSettings()">
          </div>
  
          <!-- Block Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.' + editField + '.backColor')">
            <div class="label">Block Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].charOut[editField].backColor" (colorChange)="updateColor($event, 'backColor')"></app-color-select>
          </div>
  
          <!-- Block Width -->
          <div class="settings-option size" *ngIf="editField !== 'audioLevelsMaster' && editField !== 'audioLevelsPlayback' && editField !== 'audioLevelsProxy'" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.' + editField + '.width')">
            <div class="label">Block Width</div>
            <input class="input" type="number" [(ngModel)]="settings.channels[deckChannel].charOut[editField].width" (blur)="updateSettings()">
          </div>
  
          <!-- Block Height -->
          <div class="settings-option size" *ngIf="editField !== 'audioLevelsMaster' && editField !== 'audioLevelsPlayback' && editField !== 'audioLevelsProxy'" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.' + editField + '.height')">
            <div class="label">Block Height</div>
            <input class="input" type="number" [(ngModel)]="settings.channels[deckChannel].charOut[editField].height" (blur)="updateSettings()">
          </div>
  
          <!-- Block Opacity -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.' + editField + '.opacity')">
            <div class="label">Block Opacity</div>
            <input class="input" type="number" [(ngModel)]="settings.channels[deckChannel].charOut[editField].opacity" (blur)="updateSettings()">
          </div>
  
          <!-- Text -->
          <div class="settings-option user-text" *ngIf="editField === 'userText'" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.' + editField + '.text')">
            <div class="label">Text</div>
            <input class="input" [(ngModel)]="settings.channels[deckChannel].charOut[editField].text" (blur)="updateSettings()">
          </div>
  
        </div>


        <!-- Settings Border -->
        <div class="overlay-settings" *ngIf="editField === 'borderStatus'">

          <!-- Record Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.recBorderColors.backColor')">
            <div class="label">Record Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].charOut.recBorderColors.backColor" (colorChange)="updateBorderColor($event, 'recBorderColors', 'backColor')"></app-color-select>          
          </div>

          <!-- Record Text Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.recBorderColors.textColor')">
            <div class="label">Record Text Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].charOut.recBorderColors.textColor" (colorChange)="updateBorderColor($event, 'recBorderColors', 'textColor')"></app-color-select>          
          </div>

          <!-- Idle Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.idleBorderColors.backColor')">
            <div class="label">Idle Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].charOut.idleBorderColors.backColor" (colorChange)="updateBorderColor($event, 'idleBorderColors', 'backColor')"></app-color-select>          
          </div>

          <!-- Idle Text Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].charOut.idleBorderColors.textColor')">
            <div class="label">Idle Text Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].charOut.idleBorderColors.textColor" (colorChange)="updateBorderColor($event, 'idleBorderColors', 'textColor')"></app-color-select>          
          </div>

        </div>
  
      </div>
  
    </div>
  
  </div>

</div>
