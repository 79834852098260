import { Component, Inject, OnInit } from '@angular/core';

// RxJS
import { combineLatest, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

// Lodash
import { isEqual, has } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Option } from '@modules/elements/types/option';
import { Preset } from '@modules/preset/types/preset';
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';

@Component({
  selector: 'app-preset-settings-burns',
  templateUrl: './preset-settings-burns.component.html',
  styleUrls: ['./preset-settings-burns.component.less']
})
export class PresetSettingsBurnsComponent implements OnInit {

  // Public
  public stream: Stream;
  public preset: Preset;
  public settings: StreamSettings;
  public encoderIndex: number;
  public deckChannel: number;
  public settingsOptions: PresetSettingsOptions; 
  public editField: string;
  public positionOptions: Option<string>[] = [
    new Option('TopLeft', 'Top Left'),
    new Option('Top', 'Top Center'),
    new Option('TopRight', 'Top Right'),
    new Option('Left', 'Center Left'),
    new Option('Center', 'Center'),
    new Option('Right', 'Center Right'),
    new Option('BottomLeft', 'Bottom Left'),
    new Option('Bottom', 'Bottom Center'),
    new Option('BottomRight', 'Bottom Right'),
  ];
  public fields: string[] = [
    'encoderTimecodeElement',
    'systemTimeAndDateElement',
    'GPSElement',
    'frameNumberElement',
    'filenameElement',
    'codecElement',
    'qualityElement',
    'userTextElement',
  ];

  // Private
  private alive = new Subject();
  private streamSettings: Partial<StreamSettings>;

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    public dialogRef: MatDialogRef<PresetSettingsBurnsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      stream: Stream,
      presetId: string,
      encoderIndex: number,
    },
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get info from Data
    this.stream = this.data.stream;
    this.encoderIndex = this.data.encoderIndex;

    // Get Stream, Preset, Settings, Settings Options, TC Sources
    this.streamService.getStream(this.data.stream?.id)
      .pipe(
        tap(stream => this.stream = stream),
        switchMap(stream => 
          combineLatest([
            this.presetService.getPreset(stream?.presetId || this.data.presetId),
            this.streamService.projectUpdated(stream).pipe(startWith(0))
          ]).pipe(map(([preset, _]) => preset))
        ),
        map(preset => {
          this.preset = preset;
          this.deckChannel = this.stream?.deckChannel ?? 0;
          const settings = this.preset?.getSetting(this.stream?.id);
          const currentSettings = JSON.parse(JSON.stringify(settings));
          this.streamSettings = this.preset?.settings[this.stream?.id];
          if (!isEqual(currentSettings, this.settings)) {
            this.settings = currentSettings;
          }
          return this.stream;
        }),
        switchMap(stream => 
          combineLatest([
            this.streamService.getInputs(this.stream),
            this.presetSettingsService.getSettingsOptions()
          ])
        ),
        takeUntil(this.alive),
      )
      .subscribe(([inputs, options]) => {
        this.settingsOptions = options;
        const inputType = this.settings?.input?.activeVideoInput;
        const tcSources = inputs[inputType]?.tcSources;
        this.settingsOptions.encoderTcSource = options.encoderTcSource.filter(item => tcSources.includes(item.value));
      });
  }

  /**
   * Methods
   */

  argbToRgba(color: string): string {
    return color.replace(/#(..)(......)/, '#$2$1');
  }

  rgbaToArgb(color: string): string {
    return color.replace(/#(......)/, '#ff$1');
  }

  getStyles(field: string): any {
    const burnIn = this.settings.channels[this.deckChannel].burnIn[this.encoderIndex][field];
    const safe = (100 - burnIn.safe) / 2;
    const position = burnIn.position;
    const result = {'background-color': this.argbToRgba(burnIn.blockColor)};
    if (position === 'TopLeft' || position === 'Top' || position === 'TopRight') {
      result['top.%'] = safe;
    }
    if (position === 'BottomLeft' || position === 'Bottom' || position === 'BottomRight') {
      result['bottom.%'] = safe;
    }
    if (position === 'TopLeft' || position === 'Left' || position === 'BottomLeft') {
      result['left.%'] = safe;
    }
    if (position === 'TopRight' || position === 'Right' || position === 'BottomRight') {
      result['right.%'] = safe;
    }
    return result;
  }

  isDefaultSetting(key: string): boolean {
    return this.streamSettings ? !has(this.streamSettings, key) : true;
  }

  /**
   * Action
   */

  updateSettings(): void {
    if (this.stream) {
      this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
    } else {
      this.presetSettingsService.updateDefaultSetting(this.preset.id, this.settings);
    }
  }

  updateEditField(field: string): void {
    this.editField = field;
  }

  updateTimecodeSource(value: string): void {
    this.settings.channels[this.deckChannel].burnIn[this.encoderIndex].tcSource = value;
    this.updateSettings();
  }

  updatePosition(value: string): void {
    this.settings.channels[this.deckChannel].burnIn[this.encoderIndex][this.editField].position = value;
    this.updateSettings();
  }

  updateColor(color: string, field: string): void {
    this.settings.channels[this.deckChannel].burnIn[this.encoderIndex][this.editField][field] = color;
    this.updateSettings();
  }

  close(): void {
    this.dialogRef.close();
  }

}
