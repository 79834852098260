import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { DeckService } from '@modules/deck/services/deck.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { AlertService } from '@modules/elements/services/alert.service';
import { ScheduleService } from '@modules/schedule/services/schedule.service';

// Components
import { FileManagerComponent } from '@modules/file-manager/components/file-manager/file-manager.component';
import { PresetSettingsNdiSourceComponent } from '@modules/preset/components/preset-settings/preset-settings-ndi-source/preset-settings-ndi-source.component';

// Types
import { MatDialog } from '@angular/material/dialog';
import { Deck } from '@modules/deck/types/deck';
import { DeckChannel } from '@modules/deck/types/deck-channel';
import { DeckDrive, DeckStatus } from '@modules/deck/types/deck-status';
import { Option } from '@modules/elements/types/option';
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-deck-item',
  templateUrl: './deck-item.component.html',
  styleUrls: ['./deck-item.component.less']
})
export class DeckItemComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() deck: Deck;

  // Public
  public status: DeckStatus;
  public channels: DeckChannel[] = [];
  public drives: DeckDrive[] = [];
  public isOnline = false;
  public uiMode: string;
  public uiModeOptions: Option<string>[];
  public fileOverwriteOptions: Option<number>[] = [
    new Option(1, 'Yes'),
    new Option(0, 'No'),
  ];
  public preferences = {
    fileOverwrite: 0
  };

  // Private
  private alive = new Subject();

  constructor(
    private dialog: MatDialog,
    private deckService: DeckService,
    private streamService: StreamService,
    private alertService: AlertService,
    private scheduleService: ScheduleService,
  ) { }

  ngOnInit(): void {
    // Get Status
    this.deckService.getStatus(this.deck)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.alive)
      )
      .subscribe(status => this.status = status);

    // Get Preferences
    this.deckService.getStatus(this.deck)
      .pipe(
        map(status => status?.deckInfo?.fileOverwrite),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive)
      )
      .subscribe(fileOverwrite => this.preferences.fileOverwrite = fileOverwrite);
    
    // Get Drives
    this.deckService.getDrives(this.deck)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(drives => this.drives = drives);

    // Get Online status
    this.deckService.getOnlineStatus(this.deck)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(isOnline => this.isOnline = isOnline);

    // UI Mode
    this.deckService.getMode(this.deck)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(mode => this.uiMode = mode);
    this.deckService.getModes(this.deck)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(modes => {
        const options = new PresetSettingsOptions().deviceUIMode;
        this.uiModeOptions = options.filter(option => modes.includes(option.value));
      });

    this.deckService.getOnlineStatus(this.deck)
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap(_ => this.deckService.getChannels(this.deck)),
        takeUntil(this.alive)
      )
      .subscribe(channels => this.channels = channels);

  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  /**
   * Actions
   */

  deleteDeck(): void {
    this.alertService.show(
      'Removing Deck from Cinedeck Client',
      'Deck and all Inputs and schedules record will be removed',
      ['Cancel', 'Remove'],
      0
    )
      .subscribe(response => {
        if (response === 1) {
          this.scheduleService.deleteScheduleForDeck(this.deck.id);
          this.streamService.deleteStreamsForDeck(this.deck.id);
          this.deckService.deleteDeck(this.deck);
        }
      });
  }

  updatedName(name: string): void {
    // Validate
    if (!name.length || name === this.deck.address) { return; }
    // Update Name
    this.deck.name = name;
    this.deckService.updateDeck(this.deck);
  }

  updatedAddress(address: string): void {
    // Validate
    if (!address.length || address === this.deck.address) { return; }
    // Update Address
    this.deck.address = address;
    this.deckService.disconnect(this.deck);
    this.deckService.updateDeck(this.deck);
  }

  updateSSL(value: boolean): void {
    this.deck.ssl = value;
    this.deckService.disconnect(this.deck);
    this.deckService.updateDeck(this.deck);
  }

  openFileManager(): void {
    this.dialog.open(FileManagerComponent, {data: {deckId: this.deck.id }, disableClose: true, autoFocus: false});
  }

  deleteSettings(): void {
    this.alertService.show(
      'Reset all Preferences to Default',
      'Cinedeck server app will be restarted. Do you wish to continue?',
      ['Cancel', 'Reset'],
      0
    )
      .subscribe(response => {
        if (response === 1) {
          this.deckService.deleteSettings(this.deck);
        }
      });
  }

  deleteFileDatabase(): void {
    this.alertService.show(
      'Are you sure you want to purge all clip records from database',
      'Cinedeck server app will be restarted. Do you wish to continue?',
      ['Cancel', 'Reset'],
      0
    )
      .subscribe(response => {
        if (response === 1) {
          this.deckService.deleteFileDatabase(this.deck);
        }
      });
  }

  restartApp(): void {
    this.deckService.restart(this.deck);
  }

  exitApp(): void {
    this.deckService.exit(this.deck);
  }

  restartDevice(): void {
    this.deckService.restartDevice(this.deck);
  }

  shutdownDevice(): void {
    this.deckService.shutdownDevice(this.deck);
  }

  changeUiMode(option: Option<string>): void {
    this.alertService.show(
      'Change UI Mode',
      'To change UI Mode, you need to restart the device',
      ['Cancel', 'Restart'],
      0
    )
      .subscribe(response => {
        if (response === 1) {
          const lastValue = Object.assign('', this.uiMode);
          this.uiMode = option.value;
          this.deckService.updateMode(this.deck, this.uiMode)
            .pipe(
              catchError(error => {
                if (error) {
                  this.uiMode = lastValue;
                  this.alertService.show('Change UI Mode', 'UI Mode cannot be changed while recording is in progress');
                }
                return of();
              })
            )
            .subscribe();
        }
      });
  }

  updateFileOverwrite(value: number): void {
    this.deckService.updateFileOverwrite(this.deck, value === 1);
  }

  openNdiIpsSettings(): void {
    this.dialog.open(PresetSettingsNdiSourceComponent, {data: {deckId: this.deck.id}, disableClose: true, autoFocus: false, restoreFocus: false});
  }

}
