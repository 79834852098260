import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

// External modules
import { ContextMenuModule } from 'ngx-contextmenu';

// Modules
import { PopoverModule } from '@modules/popover/popover.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { DeckModule } from '@modules/deck/deck.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';

// Components
import { StreamComponent } from './components/stream/stream.component';
import { StreamListComponent } from './components/stream-list/stream-list.component';
import { StreamSettingsComponent } from './components/stream-settings/stream-settings.component';
import { GroupControlComponent } from './components/group-control/group-control.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
    ElementsModule,
    DeckModule,
    DragNDropModule,

    // External
    ContextMenuModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatGridListModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
  ],
  declarations: [
    StreamComponent,
    StreamListComponent,
    StreamSettingsComponent,
    GroupControlComponent
  ],
  exports: [
    StreamComponent,
    StreamListComponent,
    GroupControlComponent
  ],
})
export class StreamModule { }
