import StreamInputsDefault from '../../../../assets/settings/stream-inputs.json';

export class StreamInputs {
  aes: StreamInputInfo;
  cc: StreamInputInfo;
  dante: StreamInputInfo;
  ndi: StreamInputNDI;
  realtech: StreamInputInfo;
  ref: StreamInputInfo;
  sdi: StreamInputSDI;
  smpt2110: StreamInputInfo;
  srt_listener: StreamInputSRT;
  srt_caller: StreamInputSRT;
  usbtc: StreamInputInfo;
  vanccc: StreamInputInfo;
  videoInputs: string[];  // ["sdi", "ndi", "srt_listener", "srt_caller"]
  analogAudioInputs: string[];
  asioAudioInputs: string[];
  streaming: boolean;

  static default(): StreamInputs {
    return StreamInputsDefault as StreamInputs;
  }
}

export class StreamInputInfo {
  enabled: boolean;
  streaming: boolean;
}

export class StreamInputNDI extends StreamInputInfo {
  inputs: string[];       // ["CINEDECK (Cinedeck_output_0)", "CINEDECK (Cinedeck_output_1)", "CINEDECK (Cinedeck_output_2)", "PC13-PC (VLC)"]
  audioInputs: string[];  // ["off", "sdi_16ch"]
  inputSync: string[];    // ["genlock", "sdi_1", "sdi_2", "sdi_3", "sdi_4", "free_running", "interlock"]
  pixelFormats: string[]; // ["yuv8", "yuv10", "rgb10"]
  resolutions: string[];  // ["2k", "1080p", "1080i", "720p", "pal", "pal-p", "ntsc", "ntsc-p"]
  connected: boolean;
}

export class StreamInputSDI extends StreamInputInfo {
  audioInputs: string[];  // ["off", "sdi_16ch"]
  inputSync: string[];    // ["genlock", "sdi_1", "sdi_2", "sdi_3", "sdi_4", "free_running", "interlock"]
  pixelFormats: string[]; // ["yuv8", "yuv10", "rgb10"]
  resolutions: string[];  // ["2k", "1080p", "1080i", "720p", "pal", "pal-p", "ntsc", "ntsc-p"]
}

export class StreamInputSRT extends StreamInputInfo {
  audioInputs: string[];  // ["off", "srt"]
  inputSync: string[];    // ["self"]
  pixelFormats: string[]; // ["yuv8", "yuv10", "rgb10"]
  resolutions: string[];  // ["4k", "uhd", "2k", "1080p", "1080i", "720p", "pal", "pal-p", "ntsc", "ntsc-p"]
  tcSources: string[];    // ["sdi", "ndi", "srt", "extltc", "irig_systime", "irig_vanc", "gen", "srt"]
  connected: boolean;
}
