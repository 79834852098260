import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { UserService } from '../services/user.service';

// Types
import { PermissionKey } from '../types/permissions';

@Pipe({
  name: 'checkPermission'
})
export class CheckPermissionPipe implements PipeTransform {

  constructor(private userService: UserService) {};

  transform(permission: PermissionKey): Observable<boolean> {
    return this.userService.getCurrentPermission(permission);
  }

}
