import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
  NgZone,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { AnimationTriggerMetadata, trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import {
  CdkOverlayOrigin,
  CdkConnectedOverlay,
  ConnectionPositionPair,
  ScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { ESCAPE } from '@angular/cdk/keycodes';

// RxJS
import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil, distinct } from 'rxjs/operators';

// Types
import { PopoverPlacement } from '../../types/placement';
import { Bounding } from '../../types/bounding';
import { BoundingShift } from '../../types/boundingShift';

const zoomBigMotion: AnimationTriggerMetadata = trigger('zoomBigMotion', [
  transition('void => active', [
    style({ opacity: 0 }),
    animate(
      '0.2s cubic-bezier(0.08, 0.82, 0.17, 1)',
      style({ opacity: 1 })
    )
  ]),
  transition('active => void', [
    style({ opacity: 1 }),
    animate(
      '0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
      style({ opacity: 0 })
    )
  ])
]);

const TOLLERANCE = 10;

// default behaviour can be overwritten by creating Input with custom reposition strategy
const DEFAULT_FALLBACK_PLACEMENTS: {[key in PopoverPlacement]: PopoverPlacement[]} = {
  left: ['mouseLeft', 'right', 'mouseRight'],
  right: ['mouseRight', 'left', 'mouseLeft'],
  top: ['bottom'],
  bottom: ['top'],
  mouseLeft: ['mouseRight'],
  mouseRight: ['mouseLeft'],
  mouseTop: [],
  mouseBottom: []
};

@Component({
  selector: 'popover',
  animations: [zoomBigMotion],
  templateUrl: './popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  styleUrls: ['./popover.component.less']
})
export class PopoverComponent implements OnChanges, OnDestroy, OnInit {

  // ViewChildren
  @ViewChild('overlay', { static: true }) overlay: CdkConnectedOverlay;
  @ViewChild('popoverContainer', { static: false }) popoverContainer: ElementRef;
  @ViewChild('arrow', { static: false }) arrow: ElementRef;
  @ViewChild('arrowShadow', { static: false }) arrowShadow: ElementRef;

  // Private
  private alive: Subject<void> = new Subject();
  private mouseMoveSubscription: Subscription;
  private eventPageX: number;
  private eventPageY: number;
  private visible = false;

  // Public
  public overlayOrigin: CdkOverlayOrigin;
  public overlayVisible = new BehaviorSubject<boolean>(false);
  public scrollStrategy: ScrollStrategy;
  public overlayPosition: ConnectionPositionPair = new ConnectionPositionPair(
    { originX: 'start', originY: 'top' },
    { overlayX: 'center', overlayY: 'top' }
  );

  // Inputs
  @Input() content: TemplateRef<void>;
  @Input() placement: PopoverPlacement = 'top';
  @Input() innerShadow: boolean;
  @Input() allowedOutsideSelectorsClick: string;
  @Input() popoverArrow: boolean;
  @Input() popoverOffsetX = 0;
  @Input() popoverOffsetY = 0;
  @Input() trackMouse: boolean;
  @Input() fallbackPlacements: PopoverPlacement[];
  @Input() originRef: ElementRef;

  // Outputs
  @Output() readonly visibleChange = new EventEmitter<boolean>();
  @Output() readonly mouseLeave = new EventEmitter<MouseEvent>();
  @Output() outsideClick = new EventEmitter();
  @Output() error = new EventEmitter();

  /**
   * Constructor
   */

  constructor(
    private renderer: Renderer2,
    private ngZone: NgZone,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {
    this.scrollStrategy = this.scrollStrategyOptions.reposition();
  }

  /**
   * Component lifecycle
   */

  ngOnChanges(changes: SimpleChanges): void {
    if ('trackMouse' in changes) {
      this.configureTrackMouse();
    }
  }

  ngOnInit() {
    this.configureTrackMouse();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleOutsideClick() {
    if (this.visible) {
      this.outsideClick.emit();
    }
  }

  handleMouseLeave(event: MouseEvent) {
    this.mouseLeave.emit(event);
  }

  protected isContentEmpty(): boolean {
    const isContentEmpty = this.content instanceof TemplateRef ?
      false :
      !(this.content === null || this.content === undefined);

    return isContentEmpty;
  }

  setOverlayOrigin(origin: CdkOverlayOrigin): void {
    this.overlayOrigin = origin;
  }

  afterVisibilityAnimation(e: AnimationEvent): void {
    if (e.toState === 'void') {
      this.visibleChange.emit(false);
      this.visible = false;
    }
    if (e.toState === 'active') {
      this.visibleChange.emit(true);
      this.visible = true;
    }
  }

  show(event: MouseEvent): void {
    if (!this.isContentEmpty()) {
      this.overlayVisible.next(true);
    }

    if (event) {
      this.eventPageX = event.pageX;
      this.eventPageY = event.pageY;
    }
  }

  hide(): void {
    this.overlayVisible.next(false);
  }

  updatePosition(eventX = this.eventPageX, eventY = this.eventPageY) {
    if (!this.overlay || !this.overlay.overlayRef) { return; }

    const originBounding = this.getBounding(this.originRef.nativeElement);

    // Sometimes on slow machines it happens that origin component is already destroyed
    // somethimes it happens because of scroll inertia effect
    // and no signal emitted. This code is just to reasure
    // if (!document.contains(this.originRef.nativeElement)) too slow
    if (originBounding.height === 0 || originBounding.width === 0) {
      this.error.emit();
    }

    // Apply Offsets
    if ((this.popoverOffsetX && this.popoverOffsetX !== 0) || (this.popoverOffsetY && this.popoverOffsetY !== 0)) {
      const oldOverlayBounding = this.getBounding(this.overlay.overlayRef.overlayElement);
      this.applyBoundingShift(
        this.overlay.overlayRef.overlayElement,
        {
          left: oldOverlayBounding.left + this.popoverOffsetX,
          top: oldOverlayBounding.top + this.popoverOffsetY,
        });
    }

    const overlayBounding = this.getBounding(this.overlay.overlayRef.overlayElement);
    const contentBounding = this.getBounding(this.popoverContainer.nativeElement);
    const arrowBounding = this.getBounding(this.arrow && this.arrow.nativeElement);

    const [contentBoundingShift, arrowBoundingShift] = this.reposition(
      this.placement,
      overlayBounding,
      contentBounding,
      arrowBounding,
      originBounding,
      eventX,
      eventY,
      DEFAULT_FALLBACK_PLACEMENTS[this.placement]
    );

    this.applyBoundingShift(this.popoverContainer.nativeElement, contentBoundingShift);
    this.applyBoundingShift(this.arrow && this.arrow.nativeElement, arrowBoundingShift);
    this.applyBoundingShift(this.arrowShadow && this.arrowShadow.nativeElement, arrowBoundingShift);
    if (this.arrow && this.placement === 'top') {
      this.renderer.setStyle(this.arrow.nativeElement, 'transform', `rotate(225deg)`);
    }
  }

  handleKeydown(event) {
    if (event.keyCode === ESCAPE) {
      this.outsideClick.emit();
    }
  }

  subscribeToMouseMove() {
    if (this.mouseMoveSubscription) { return; }

    this.ngZone.runOutsideAngular(() => {
      this.mouseMoveSubscription = fromEvent(document, 'mousemove')
        .pipe(
          takeUntil(this.alive),
          distinct((event: MouseEvent) => `${event.pageY}x${event.pageX}`)
        )
        .subscribe((event: MouseEvent) => {
          this.updatePosition(event.pageX, event.pageY);
        });
    });
  }

  unsubscribeFromMouseMove() {
    if (this.mouseMoveSubscription) {
      this.mouseMoveSubscription.unsubscribe();
    }
  }

  configureTrackMouse() {
    if (this.trackMouse) {
      this.subscribeToMouseMove();
    } else {
      this.unsubscribeFromMouseMove();
    }
  }

  /**
   * Helpers
   */

  reposition (
    placement: PopoverPlacement,
    overlay: Bounding,
    content: Bounding,
    arrow: Bounding,
    origin: Bounding,
    eventX: number,
    eventY: number,
    fallbackPlacements: PopoverPlacement[],
  ): [BoundingShift, BoundingShift] {
    const [fallbackPlacement, ...nextFallbackPlacements] = fallbackPlacements;

    const halfArrow = arrow.width / 2; // Assume arrow width and height are equal
    const originHalfHeight = origin.height / 2;
    const eventXShift = eventX ? eventX - origin.left : 0;
    const eventYShift = eventY ? eventY - origin.top : 0;
    const contentFullHeight = halfArrow + TOLLERANCE + content.height;
    const contentFullWidth = halfArrow + TOLLERANCE + content.width;

    switch (placement) {
      case 'right':
        if (fallbackPlacement && origin.right < contentFullWidth) { break; }
        return [
          {
            left: origin.width + halfArrow + TOLLERANCE,
            top: this.repositionVertical(content, origin)
          },
          {
            left: origin.width + TOLLERANCE + 2,
            top: originHalfHeight - halfArrow
          }
        ];
      case 'mouseRight':
        if (!eventX || !eventY) {
          return this.reposition('right', overlay, content, arrow, origin, eventX, eventY, fallbackPlacements);
        }
        if (fallbackPlacement && origin.right + origin.width - eventXShift < contentFullWidth) { break; }
        return [
          {
            left: eventXShift + halfArrow + TOLLERANCE,
            top: this.repositionVertical(content, origin, eventYShift - originHalfHeight)
          },
          {
            left: eventXShift + TOLLERANCE + 2,
            top: eventYShift - halfArrow
          }
        ];
      case 'left':
        if (fallbackPlacement && content.left < contentFullWidth) { break; }
        return [
          {
            left: - contentFullWidth - 3,
            top: this.repositionVertical(content, origin)
          },
          {
            left: -TOLLERANCE - arrow.width,
            top: originHalfHeight - halfArrow
          }
        ];
      case 'mouseLeft':
        if (!eventX || !eventY) {
          return this.reposition('left', overlay, content, arrow, origin, eventX, eventY, fallbackPlacements);
        }
        if (fallbackPlacement && eventX < contentFullWidth) { break; }
        return [
          {
            left: eventXShift - contentFullWidth,
            top: this.repositionVertical(content, origin, eventYShift - originHalfHeight)
          },
          {
            left: eventXShift - arrow.width - TOLLERANCE + 3,
            top: eventYShift - halfArrow
          }
        ];
      case 'top':
        if (fallbackPlacement && origin.top < contentFullHeight) { break; }
        return [
          {
            left: this.repositionHorizontal(content, origin),
            top: -contentFullHeight,
          },
          {
            left: origin.width / 2 - halfArrow,
            top: -arrow.height - TOLLERANCE + 2
          }
        ];
      case 'bottom':
        if (fallbackPlacement && origin.bottom < contentFullHeight) { break; }
        return [
          {
            left: this.repositionHorizontal(content, origin),
            top: halfArrow + TOLLERANCE + origin.height,
          },
          {
            left: origin.width / 2 - halfArrow,
            top: origin.height + TOLLERANCE + 2
          }
        ];
      default:
        return [{ left: 0, top: 0 }, { left: 0, top: 0 }];
    }

    return this.reposition(fallbackPlacement, overlay, content, arrow, origin, eventX, eventY, nextFallbackPlacements);
  }

  getBounding(element: HTMLElement): Bounding {
    if (!element) {
      return {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: 0,
        width: 0,
      };
    }

    const nativeBounding = element.getBoundingClientRect();

    return {
      top: nativeBounding.top,
      bottom: window.innerHeight - nativeBounding.top - nativeBounding.height,
      left: nativeBounding.left,
      right: window.innerWidth - nativeBounding.left - nativeBounding.width,
      height: nativeBounding.height,
      width: nativeBounding.width,
    };
  }

  applyBoundingShift(element: HTMLElement, bounding: BoundingShift) {
    if (!element) { return; }
    this.renderer.setStyle(element, 'top', `${ bounding.top }px`);
    this.renderer.setStyle(element, 'left', `${ bounding.left }px`);
  }

  repositionVertical(bounding: Bounding, originBounding, shift = 0): number {
    const center = bounding.height / 2 - originBounding.height / 2;

    return bounding.top - center + shift < 0
      ? -bounding.top + TOLLERANCE
      : bounding.bottom + center - shift < 0
        ? bounding.bottom - TOLLERANCE
        : -center + shift;
  }

  repositionHorizontal(bounding: Bounding, originBounding, shift = 0): number {
    const center = bounding.width / 2 - originBounding.width / 2;

    return bounding.left - center + shift < 0
      ? -bounding.left + TOLLERANCE
      : bounding.right + center - shift < 0
        ? bounding.right - TOLLERANCE
        : -center + shift;
  }
}
