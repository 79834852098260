<div class="preset-settings-encoder-content">

  <!-- Options -->
  <div class="encoder-options">

    <!-- Disabled Status -->
    <div class="settings-status-text" *ngIf="recording">Settings unavailable during recording</div>
    <div class="settings-status-text" *ngIf="!deckOnline">Settings are not available if there is no connection</div>
    <div class="settings-status-text" *ngIf="preset && !preset?.isValid()">
      <mat-icon svgIcon="warning" class="icon error"></mat-icon>
      Selected preset is from a prior version: please delete and create a new one.
    </div>

    <!-- 0 -->
    <div class="row" *ngIf="settings && settings?.encode[encoderIndex]">
      <div class=""></div>
      <mat-checkbox class="checkbox" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].enabled')" [disabled]="recording || !deckOnline || !allowedSettingsUpdate" [(ngModel)]="settings.encode[encoderIndex].enabled" (ngModelChange)="updateSettings()" disableRipple>Enabled</mat-checkbox>
    </div>

    <!-- 1 -->
    <div class="row">

      <!-- Codec -->
      <app-preset-settings-option title="Codec" [key]="'encode[' + encoderIndex + '].activeCodec'" [options]="filtredSettingsOptions.encoderCodec" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Quality -->
      <app-preset-settings-option class="settings-option-quality" title="Quality" [key]="'encode[' + encoderIndex + '].activeQuality'" [options]="filtredSettingsOptions.encoderQuality" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)" placeholder="N/A"></app-preset-settings-option>

      <!-- H264 Profiles Editor -->
      <!-- <div class="settings-option" [class.disabled]="disabled || true">
        <p class="preset-settings-title-big">H264 Profiles Editor</p>
        <div class="multi-tile">
          <div class="button tile-button">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div> -->

      <!-- Padded/Unpadded -->
      <app-preset-settings-option
        *ngIf="settings?.encode[encoderIndex]?.activeCodec === 'h264_gpu' || settings?.encode[encoderIndex]?.activeCodec === 'prores'"
        title="Padded"
        [forceHighlight]="!isDefaultSetting('encode[' + encoderIndex + '].activeCodec')"
        [key]="'encode[' + encoderIndex + '].isCBR'"
        [options]="filtredSettingsOptions.encoderPadded"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- Field Wrapped -->
      <app-preset-settings-option
        *ngIf="settings?.encode[encoderIndex]?.activeCodec === 'j2k_gpu' && inputInterlaced"
        title="Field Wrapped"
        [key]="'encode[' + encoderIndex + '].fieldWrapped'"
        [options]="filtredSettingsOptions.encoderFieldWrapped"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- forceBT709 -->
      <app-preset-settings-option
        *ngIf="settings?.encode[encoderIndex]?.activeCodec === 'mpeg_imx'"
        title="Color preset"
        [key]="'encode[' + encoderIndex + '].forceBT709'"
        [options]="filtredSettingsOptions.encoderForceBT709"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- CBR/VBR -->
      <app-preset-settings-option
        *ngIf="(settings?.encode[encoderIndex]?.activeCodec === 'j2k' || settings?.encode[encoderIndex]?.activeCodec === 'j2k_gpu') && settings?.encode[encoderIndex]?.activeQuality !== 'j2k_lossless'"
        title="Bitrate mode"
        [forceHighlight]="!isDefaultSetting('encode[' + encoderIndex + '].activeCodec')"
        [key]="'encode[' + encoderIndex + '].isCBR'"
        [options]="filtredSettingsOptions.encoderCBR"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- Field Invert -->
      <app-preset-settings-option
        *ngIf="inputInterlaced"
        title="Field Invert"
        [key]="'encode[' + encoderIndex + '].fieldShift'"
        [options]="filtredSettingsOptions.encoderFieldShift"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- First Field -->
      <app-preset-settings-option
        *ngIf="inputInterlaced"
        title="First Field"
        [key]="'encode[' + encoderIndex + '].isTopFirst'"
        [options]="filtredSettingsOptions.encoderFirstField"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>
      
      <!-- Force progressive -->
      <app-preset-settings-option
        *ngIf="inputInterlaced"
        title="Force progressive"
        [key]="'encode[' + encoderIndex + '].forceProgressive'"
        [options]="filtredSettingsOptions.encoderForceProgressive"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- Force interlaced -->
      <app-preset-settings-option
        *ngIf="settings.input.activeResolution === '1080p'"
        title="Force interlaced"
        [key]="'encode[' + encoderIndex + '].forceInterlaced'"
        [options]="filtredSettingsOptions.encoderForceInterlaced"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>
      
      <!-- Frame Size Type -->
      <app-preset-settings-option
        *ngIf="settings?.encode[encoderIndex]?.frameSizeScale > 1"
        title="Frame Size Type"
        [key]="'encode[' + encoderIndex + '].frameSizeType'"
        [options]="filtredSettingsOptions.encoderFrameSizeType"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- Wrapper -->
      <app-preset-settings-option title="Wrapper" [key]="'encode[' + encoderIndex + '].activeWrapper'" [options]="filtredSettingsOptions.encoderWrapper" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Max MOV Clip Duration -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].maxH264Duration')">
        <div class="preset-settings-title-big">Max MOV Clip Duration</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" [(value)]="settings?.channels[deckChannel].maxH264Duration" (valueChange)="updateSettings()"></app-input-settings>
          <!-- channel[0].maxH264Duration -->
          <!-- min: 00:01 max: 99:59 -->
        </div>
      </div>

      <!-- TC Source -->
      <app-preset-settings-option title="TC Source" key="automation.tcSource" [options]="filtredSettingsOptions.encoderTcSource" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || encoderIndex > 0" (selectedChange)="updateTcSource($event.value)"></app-preset-settings-option>

    </div>

    <!-- 2 -->
    <div class="row">

      <!-- Segment Record -->
      <app-preset-settings-option title="Segment Record" [key]="'encode[' + encoderIndex + '].segmentMode'" [options]="filtredSettingsOptions.encoderSegmentRecord" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSegment($event.value)" matTooltip="Setting in Primaty Encoder" [matTooltipDisabled]="disabled || encoderIndex === 0"></app-preset-settings-option>

      <!-- Duration -->
      <div class="settings-option" [class.disabled]="disabled || settings?.encode[encoderIndex]?.segmentMode !== 'increment' || encoderIndex > 0" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].segmentDuration')" matTooltip="Setting in Primaty Encoder" [matTooltipDisabled]="disabled || encoderIndex === 0">
        <div class="preset-settings-title-big">Duration</div>
        <div class="multi-tile">
          <input class="input full-tile-select"[(ngModel)]="settings?.encode[encoderIndex].segmentDuration" (blur)="updateSettings()" placeholder="--:--:--;--">
          <!-- <app-input-timecode
            class="full-tile-select"
            [fps]="settings?.input.activeFrameRate"
            [dropFrame]="stream ? dropFrame : settings?.input.dropFrame"
            [resolution]="settings?.input.activeResolution"
            [(value)]="settings?.encode[encoderIndex].segmentDuration"
            (blur)="updateSettings()"
          ></app-input-timecode> -->
        </div>
      </div>

      <!-- Segment Limit -->
      <app-preset-settings-option title="Segment Limit" [key]="'encode[' + encoderIndex + '].isSegmentLimitMode'" [options]="filtredSettingsOptions.encoderSegmentLimitMode" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || settings?.encode[encoderIndex]?.segmentMode !== 'increment' || encoderIndex > 0" (selectedChange)="updateSelect($event.key, $event.value)" matTooltip="Setting in Primaty Encoder" [matTooltipDisabled]="disabled || encoderIndex === 0"></app-preset-settings-option>

      <!-- Count Limit -->
      <div class="settings-option" [class.disabled]="disabled || settings?.encode[encoderIndex]?.segmentMode !== 'increment' || !settings?.encode[encoderIndex].isSegmentLimitMode || encoderIndex > 0" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].segmentLimit')" matTooltip="Setting in Primaty Encoder" [matTooltipDisabled]="disabled || encoderIndex === 0">
        <div class="preset-settings-title-big">Count Limit</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" type="number" [(value)]="settings?.encode[encoderIndex].segmentLimit" (valueChange)="updateSettings()"></app-input-settings>
        </div>
      </div>

      <!-- Segment Count -->
      <div class="settings-option" [class.disabled]="disabled || true" matTooltip="Setting in Primaty Encoder" [matTooltipDisabled]="disabled || encoderIndex === 0">
        <div class="preset-settings-title-big">Segment Count</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" placeholder="--" (blur)="updateSettings()">
        </div>
      </div>

      <!-- Encoder Name -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].encoderName')">
        <div class="preset-settings-title-big">Encoder Name</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" [(value)]="settings?.encode[encoderIndex].encoderName" (valueChange)="updateSettings()"></app-input-settings>
        </div>
      </div>

      <!-- Record TC offset -->
      <div class="settings-option timecode-settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].recTCOffsetString')">
        <div class="preset-settings-title-big">Record TC offset</div>
        <div class="multi-tile">
          <mat-checkbox class="checkbox" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].recTCOffsetEnabled')" [disabled]="disabled" [(ngModel)]="settings?.channels[deckChannel].recTCOffsetEnabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
          <app-input-timecode
            class="almost-full-tile-select"
            [class.disabled]="disabled || !settings?.channels[deckChannel].recTCOffsetEnabled"
            [fps]="settings?.input.activeFrameRate"
            [dropFrame]="settings?.input.dropFrame"
            [resolution]="settings?.input.activeResolution"
            [(value)]="settings?.channels[deckChannel].recTCOffsetString"
            [(negative)]="settings?.channels[deckChannel].recTCOffsetNegative"
            (blur)="updateSettings()"
            ></app-input-timecode>
        </div>
      </div>

    </div>

    <!-- 3 -->
    <div class="row">

      <!-- Audio Channel Count -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultAudioChannelCountSetting">
        <div class="preset-settings-title-big">Audio Channel Count</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="audioChannelsCountOptions" [selected]="audioChannelsCountOption" (selectedChange)="selectAudioChannelCount($event)" [highlighted]="!isDefaultAudioChannelCountSetting"></app-select>
        </div>
      </div>

      <!-- Audio Codec -->
      <app-preset-settings-option
        *ngIf="!(
          (settings?.encode[encoderIndex]?.activeCodec === 'h264_gpu' ||
          settings?.encode[encoderIndex]?.activeCodec === 'h264' ||
          settings?.encode[encoderIndex]?.activeCodec === 'ts') &&
          (settings?.encode[encoderIndex]?.activeWrapper === 'mov' ||
          settings?.encode[encoderIndex]?.activeWrapper === 'mov_2_51' ||
          settings?.encode[encoderIndex]?.activeWrapper === 'mp4')
        )"
        title="Audio Codec" [key]="'encode[' + encoderIndex + '].activeAudioCodec'" [options]="filtredSettingsOptions.encoderAudioCodec" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>
      <app-preset-settings-option
        *ngIf="(
          (settings?.encode[encoderIndex]?.activeCodec === 'h264_gpu' ||
          settings?.encode[encoderIndex]?.activeCodec === 'h264' ||
          settings?.encode[encoderIndex]?.activeCodec === 'ts') &&
          (settings?.encode[encoderIndex]?.activeWrapper === 'mov' ||
          settings?.encode[encoderIndex]?.activeWrapper === 'mov_2_51' ||
          settings?.encode[encoderIndex]?.activeWrapper === 'mp4')
        )"
        title="Audio Codec" [key]="'encode[' + encoderIndex + '].h264DefaultAudioCodec'" [options]="filtredSettingsOptions.encoderAudioCodecH264" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateActiveAudioCodecH264($event.value)"
      ></app-preset-settings-option>

      <!-- Audio Tracks Type -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">Audio Tracks Type</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.encoderAudioTrackType" [selected]="{value: 'mono', title: 'Mono '}"></app-select>
        </div>
      </div>

      <!-- Audio Frequency -->
      <div class="settings-option" [class.disabled]="disabled">
        <div class="preset-settings-title-big">Audio Frequency</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.encoderAudioFrequency" [selected]="{value: 0, title: '48KHz'}"></app-select>
        </div>
      </div>

      <!-- Audio Bit Depth -->
      <app-preset-settings-option title="Audio Bit Depth" [key]="'channels[' + deckChannel + '].audioRouting[' + encoderIndex + '].audioFrequencyModel.currentBitDepth'" [options]="filtredSettingsOptions.encoderAudioBitDepth" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Audio Channel Layout -->
      <div class="settings-option" [class.disabled]="disabled">
        <div class="preset-settings-title-big">Audio Channel Layout</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.encoderAudioChannelLayout" [selected]="{value: 0, title: 'SMPTE'}"></app-select>
        </div>
      </div>

      <!-- Endian Type -->
      <app-preset-settings-option title="Endian Type" [key]="'encode[' + encoderIndex + '].bigEndianType'" [options]="filtredSettingsOptions.encoderEndianType" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

    </div>

    <!-- 4 -->
    <div class="row">

      <!-- Audio Routing -->
      <div class="settings-option audio-routing-option" [class.disabled]="disabled">
        <div class="preset-settings-title-big">Audio Routing</div>
        <div class="multi-tile">
          <div class="button tile-button" (click)="openAudioRouting()">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>

      <div class="audio-routing">
        <div class="audio-channel" *ngFor="let channel of audioChannels; let index = index" [class.disabled]="!channel.enabled" [class.highlighted]="channel.overriden">
          <div class="name" [class.highlighted]="channel.overridenCount">{{ index + 1 }}</div>
          <div class="audio-input-channel">{{ index + 1 }}</div>
          <div class="audio-route">{{ channel.enabled ? channel.value : '-' }}</div>
        </div>
      </div>

    </div>

    <!-- 5 -->
    <div class="row">

      <!-- Logger Markers -->
      <app-preset-settings-option title="Logger Markers Export File" [key]="'loggerSettings.exportFile'" [options]="filtredSettingsOptions.loggerExportFile" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateLoggerMarkersExportFile($event.value)"></app-preset-settings-option>

      <!-- XML -->
      <app-preset-settings-option title="XML" [key]="'encode[' + encoderIndex + '].generateXMLMode'" [options]="filtredSettingsOptions.encoderXML" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- XML Profile Editor -->
      <div class="settings-option multi" [class.disabled]="disabled || settings?.encode[encoderIndex]?.generateXMLMode === 'off'" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].overrideXMLPath')">
        <div class="preset-settings-title-big">XML Profile Editor</div>
        <div class="multi-tile">
          <app-select [options]="filtredSettingsOptions.onOffOptions"
                      [selected]="settings?.encode[encoderIndex]?.overrideXMLPath"
                      (selectedChange)="updateSelect('encode[' + encoderIndex + '].overrideXMLPath', $event.value)"
                      class="half-tile-select"></app-select>
          <div class="button multi-tile-button" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].XMLPath')" [class.disabled]="disabled || !settings?.encode[encoderIndex]?.overrideXMLPath || !stream" (click)="selectXMLPathOverride()">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>

      <!-- MXF Clip Folders -->
      <app-preset-settings-option
        title="MXF Clip Folders"
        [key]="'encode[' + encoderIndex + '].useMxfClipFolders'"
        [options]="filtredSettingsOptions.encoderMxfClipFolders"
        [settings]="settings"
        [streamSettings]="streamSettings"
        [disabled]="disabled || settings?.encode[encoderIndex]?.activeWrapper !== 'avid_mxf'"
        (selectedChange)="updateSelect($event.key, $event.value)"
        placeholder="N/A"
      ></app-preset-settings-option>

      <!-- OpAtom Clip Folders -->
      <div class="settings-option multi" [class.disabled]="disabled || settings?.encode[encoderIndex]?.activeWrapper !== 'avid_mxf'" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].overrideAAFPath')">
        <div class="preset-settings-title-big">OpAtom Clip Folders</div>
        <div class="multi-tile">
          <app-select [options]="filtredSettingsOptions.onOffOptions"
                      [selected]="settings?.encode[encoderIndex]?.overrideAAFPath"
                      (selectedChange)="updateSelect('encode[' + encoderIndex + '].overrideAAFPath', $event.value)"
                      class="half-tile-select"></app-select>
          <div class="button multi-tile-button" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].AAFPath')" [class.disabled]="disabled || !settings?.encode[encoderIndex]?.overrideAAFPath || !stream" (click)="selectAAFPathOverride()">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>

      <!-- Override paths -->
      <div class="settings-text setting-paths" [class.disabled]="disabled">
        <!-- XML Path -->
        <div class="override-path" *ngIf="settings.encode[encoderIndex].overrideXMLPath">
          <b>XML Path:</b> {{ settings.encode[encoderIndex].XMLPath }}
        </div>
        <!-- AAF Path -->
        <div class="override-path" *ngIf="settings.encode[encoderIndex].overrideAAFPath">
          <b>AAF Path:</b>{{ settings.encode[encoderIndex].AAFPath }}
        </div>
      </div>

    </div>

    <!-- 6 -->
    <div class="row">

      <!-- Captions -->
      <app-preset-settings-option title="Captions" [key]="'encode[' + encoderIndex + '].captionsMode'" [options]="filtredSettingsOptions.encoderCaptions" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- CC1 to SCC -->
      <app-preset-settings-option title="CC1 to SCC" [key]="'encode[' + encoderIndex + '].sccField1'" [options]="filtredSettingsOptions.onOffOptions" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || settings?.encode[encoderIndex]?.captionsMode === 'no'" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- CC1 to SCC -->
      <app-preset-settings-option title="CC3 to SCC" [key]="'encode[' + encoderIndex + '].sccField2'" [options]="filtredSettingsOptions.onOffOptions" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || settings?.encode[encoderIndex]?.captionsMode === 'no'" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- CC Line -->
      <div class="settings-option" [class.disabled]="disabled || settings?.encode[encoderIndex]?.captionsMode !== 'analog_to_digital'" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].CCLine')">
        <div class="preset-settings-title-big">CC Line</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" type="number" [(value)]="settings?.encode[encoderIndex].CCLine" (valueChange)="updateSettings()"></app-input-settings>
        </div>
      </div>

      <!-- Captions Sidecar -->
      <div class="settings-option" [class.disabled]="disabled || settings?.encode[encoderIndex]?.captionsMode === 'no'">
        <div class="preset-settings-title-big">SRT Captions</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [multiple]="true" [options]="filtredSettingsOptions.encoderSrtCaptions" [selected]="settings?.encode[encoderIndex]?.srtCC" (selectedChange)="updateSelect('encode[' + encoderIndex + '].srtCC', $event)" placeholder="None"></app-select>
        </div>
      </div>

      <!-- Captions Zero Based -->
      <app-preset-settings-option title="TC starts from zero" [key]="'encode[' + encoderIndex + '].zeroBasedSrtCC'" [options]="filtredSettingsOptions.onOffOptions" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || settings?.encode[encoderIndex]?.captionsMode === 'no' || !settings?.encode[encoderIndex]?.srtCC?.length" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

    </div>

    <!-- 7 -->
    <div class="row">

      <!-- Aspect Ratio -->
      <app-preset-settings-option *ngIf="(settings.input.activeResolution === 'pal' || settings.input.activeResolution === 'ntsc') && settings.input.aspectRatioString === '16:9'" title="Aspect Ratio Override" [key]="'encode[' + encoderIndex + '].anamorphic'" [options]="filtredSettingsOptions.encoderAspectRatio" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Encoder Burns -->
      <div class="settings-option burns" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].enabled')">
        <div class="preset-settings-title-big">Encoder Burns</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.encoderBurns" [selected]="settings?.channels[deckChannel]?.burnIn[encoderIndex]?.enabled" (selectedChange)="updateSelect('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].enabled', $event.value)" [highlighted]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].enabled')"></app-select>
          <div class="button tile-button" [class.disabled]="!settings?.channels[deckChannel]?.burnIn[encoderIndex]?.enabled" (click)="openBurns()">configure</div>
        </div>
      </div>

      <!-- LUT -->
      <div class="settings-option multi" [class.disabled]="disabled || encoderIndex === 0" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].lutBurn[' + encoderIndex + '].enabled')">
        <div class="preset-settings-title-big">LUT</div>
        <div class="multi-tile">
          <app-select [options]="filtredSettingsOptions.onOffOptions"
                      [selected]="settings?.channels[deckChannel]?.lutBurn[encoderIndex]?.enabled"
                      (selectedChange)="updateSelect('channels[' + deckChannel + '].lutBurn[' + encoderIndex + '].enabled', $event.value)"
                      [highlighted]="!isDefaultSetting('channels[' + deckChannel + '].lutBurn[' + encoderIndex + '].enabled')"
                      class="half-tile-select"></app-select>
          <div class="button multi-tile-button" [class.disabled]="disabled || !settings?.channels[deckChannel]?.lutBurn[encoderIndex]?.enabled || !stream" (click)="selectLutPath()" [matTooltip]="settings?.channels[deckChannel]?.lutBurn[encoderIndex]?.file" [matTooltipDisabled]="disabled || encoderIndex === 0 || !settings?.channels[deckChannel]?.lutBurn[encoderIndex]?.file?.length">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>

      <!-- Multicam -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">Multicam clip</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.encoderMulticam" [selected]="{value: 'editshare', title: 'Editshare'}"></app-select>
        </div>
      </div>

      <!-- Multicam proxy checkbox -->
      <div class="settings-option" [class.disabled]="disabled || true" *ngIf="encoderIndex === 0">
        <div class="preset-settings-title-big"></div>
        <div class="multi-tile">
          <mat-checkbox class="checkbox" [ngModel]="true" (ngModelChange)="updateSettings()" disableRipple>Include proxy</mat-checkbox>
        </div>
      </div>

      <!-- Action After Record -->
      <div class="settings-option multi large" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].actionAfterRecord')">
        <div class="preset-settings-title-big">Action After Record</div>
        <div class="multi-tile">
          <app-select [options]="filtredSettingsOptions.encoderActionAfterRecord"
                      [selected]="settings?.encode[encoderIndex]?.actionAfterRecord"
                      (selectedChange)="updateSelect('encode[' + encoderIndex + '].actionAfterRecord', $event.value)"
                      [highlighted]="!isDefaultSetting('encode[' + encoderIndex + '].actionAfterRecord')"
                      class="half-tile-select"></app-select>
          <div class="button multi-tile-button" [class.disabled]="disabled || (settings?.encode[encoderIndex]?.actionAfterRecord === 'off') || !stream" (click)="selectCopyRecordedFilePath()">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>
      <!-- Copy Recorded File Path -->
      <div class="settings-text setting-paths one-line" *ngIf="settings.encode[encoderIndex].copyRecordedFilePath?.length" [class.disabled]="(settings?.encode[encoderIndex]?.actionAfterRecord === 'off') || disabled">
        <div class="override-path">
          <b>Path:</b> {{ settings.encode[encoderIndex].copyRecordedFilePath }}
        </div>
      </div>

    </div>

  </div>

  <!-- Header -->
  <div class="preset-settings-header">
    <div class="label">Record Destinations</div>
  </div>

  <!-- Record Destinations -->
  <div class="record-destinations">

    <div class="row">
      <!-- Write type -->
      <app-preset-settings-option class="settings-option-auto-width" title="Write type" [key]="'channels[' + deckChannel + '].driveAssignments[' + encoderIndex + '].activeWriteMode'" [options]="filtredSettingsOptions.writeMode" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="selectWriteMode($event.value)" placeholder="Invalid"></app-preset-settings-option>
      <!-- Use primary encoder disk -->
      <mat-checkbox class="checkbox" *ngIf="settings?.encode[encoderIndex] && encoderIndex > 0" [class.inherit]="!isDefaultSetting('encode[' + encoderIndex + '].sameDiskAsPrimary')" [disabled]="disabled" [(ngModel)]="settings.encode[encoderIndex].sameDiskAsPrimary" (ngModelChange)="selectSameDiskAsPrimary()" disableRipple>Match proxy disk destination to primary</mat-checkbox>
    </div>

    <!-- Disks -->
    <div class="disks-list" [class.selected]="settings?.sameDiskAsPreset">

      <!-- Same disk for Preset -->
      <div class="title">
        <mat-checkbox class="checkbox" [disabled]="disabled" [(ngModel)]="settings.sameDiskAsPreset" (ngModelChange)="selectSameDiskForPreset()" [class.inherit]="!isDefaultSetting('sameDiskAsPreset')" disableRipple>Use same drive for all inputs assigned to '{{ preset.name }}' preset</mat-checkbox>
      </div>

      <!-- Primary -->
      <div class="row" [class.disabled]="disabled || (encoderIndex > 0 && settings.encode[encoderIndex].sameDiskAsPrimary) || !stream">
        <div class="label">Primary</div>
        <app-select
          class="select"
          placeholder="None"
          [options]="drivesOptions"
          [selected]="
            settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.overridePrimaryPath
              ? 'pathOverride'
              : settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.activePrimaryDrivePos
          "
          (selectedChange)="selectPrimaryDrive($event)"
        ></app-select>
        <div class="label second">Path Override</div>
        <div class="button" (click)="selectPrimatyPathOverride()">select</div>
        <div class="path-override" *ngIf="settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.overridePrimaryPath">{{ settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.primaryPath | path }}</div>
      </div>
      <!-- Error -->
      <div class="message-error" *ngIf="errors.notSamePresetDiskPrimary">Cant use same Preset's drive (Not Found)</div>

      <!-- Redundant -->
      <div class="row" [class.disabled]="disabled || !(settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.activeWriteMode === 'redundant' || settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.activeWriteMode === 'rollover') || (encoderIndex > 0 && settings.encode[encoderIndex].sameDiskAsPrimary) || !stream">
        <div class="label">Redundant</div>
        <app-select
          class="select"
          placeholder="None"
          [options]="drivesOptions | exclude: settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.activePrimaryDrivePos: 'value'"
          [selected]="
            settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.overrideSecondaryPath
              ? 'pathOverride'
              : settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.activeSecondaryDrivePos
          "
          (selectedChange)="selectSecondaryDrive($event)"
        ></app-select>
        <div class="label second">Path Override</div>
        <div class="button" (click)="selectSecondaryPathOverride()">select</div>
        <div class="path-override" *ngIf="settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.overrideSecondaryPath">{{ settings?.channels[deckChannel]?.driveAssignments[encoderIndex]?.secondaryPath | path }}</div>
      </div>
      <!-- Error -->
      <div class="message-error" *ngIf="errors.notSamePresetDiskSecondary">Cant use same Preset's drive (Not Found)</div>

    </div>

  </div>

</div>
