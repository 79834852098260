import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { PresetService } from '@modules/preset/services/preset.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { UserService } from '@modules/users/services/user.service';

// Types
import { XmlTag } from '@modules/preset/types/xml-tag';

@Component({
  selector: 'app-xml-tag-list',
  templateUrl: './xml-tag-list.component.html',
  styleUrls: ['./xml-tag-list.component.less']
})
export class XmlTagListComponent implements OnInit, OnDestroy {

  // Public
  public tags: XmlTag[];
  public selected: XmlTag;
  public locked = false;
  public allowed: boolean;

  // Private
  private alive = new Subject();
  private popoverClose = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private presetService: PresetService,
    private popoverService: PopoverService,
    userService: UserService
  ) {
    userService
      .getCurrentPermission('editTags')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowed = allowed);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.presetService.getXmlTags()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(tags => this.tags = tags);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  selectTag(tag: XmlTag): void {
    this.selected = this.selected?.id === tag.id ? null : tag;
  }

  createTag(name: string): void {
    const tag = new XmlTag(name, '#2D13CA');
    this.presetService.addXmlTag(tag);
  }

  deleteTag(tag: XmlTag): void {
    if (!tag?.id) {
      return;
    }
    this.presetService.deleteXmlTag(tag);
  }

  showCreatePopover(template: TemplateRef<any>, iconElement: any): void {
    this.popoverService.create(
      iconElement['_elementRef'],
      {
        content: template,
        placement: 'top',
        trigger: 'click',
        innerShadow: false,
        arrow: true,
        popoverOffsetY: 0,
        showUntil: this.popoverClose
      }
    );
  }

}
