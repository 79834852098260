import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

// Lodash
import { get, has, isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Types
import { Preset } from '@modules/preset/types/preset';
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preset-settings-audio-delays',
  templateUrl: './preset-settings-audio-delays.component.html',
  styleUrls: ['./preset-settings-audio-delays.component.less']
})
export class PresetSettingsAudioDelaysComponent implements OnInit {

  @ViewChildren('inputAudioDelay') inputsAudioDelay: QueryList<ElementRef>;

  // Public
  public stream: Stream;
  public preset: Preset;
  public settings: StreamSettings;

  // Private
  private alive = new Subject();
  private streamSettings: Partial<StreamSettings>;

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<PresetSettingsAudioDelaysComponent>,
    private streamService: StreamService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: {stream?: Stream, presetId?: string},
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Stream and Settings
    this.streamService.getStream(this.data.stream?.id)
      .pipe(
        takeUntil(this.alive),
        map(stream => this.stream = stream),
        switchMap(stream => this.presetService.getPreset(stream?.presetId || this.data.presetId)),
        takeUntil(this.alive),
      )
      .subscribe(preset => {
        this.preset = preset;
        this.settings = this.preset.getSetting(this.stream?.id);
        this.streamSettings = this.preset?.settings[this.stream?.id];
      });
  }

  /**
   * Methods
   */

   isDefaultSetting(key: string): boolean {
    return this.streamSettings
      ? !has(this.streamSettings, key) ||
        !(has(this.streamSettings, key) && isEqual(get(this.streamSettings, key), get(this.settings, key)))
      : true;
  }

  /**
   * Action
   */

  updateSettings(index: number, event: any): void { 
    event?.preventDefault();
    event?.stopPropagation();
    let value = +event.target?.value;
    // Validation
    if(!Number.isInteger(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }
    if (value > 1000) {
      value = 1000;
    }
    
    // Update and Save
    this.settings.input.audioDelays[index] = value;

    // Update Inputs (Fix)
    setTimeout(() => {
      this.inputsAudioDelay.forEach((element, elementIndex) => {
        element.nativeElement.value = this.settings.input.audioDelays[elementIndex];
      });
    }, 0);

    // Update Settings
    if (this.stream) {
      this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
    } else {
      this.presetSettingsService.updateDefaultSetting(this.preset.id, this.settings);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
