import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

// RxJS
import { BehaviorSubject } from 'rxjs';
import { delay, filter, map, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { UserService } from '@modules/users/services/user.service';

// Components
import { PresetSettingsBaseComponent } from '../preset-settings-base/preset-settings-base.component';
import { PresetSettingsAudioDelaysComponent } from '../preset-settings-audio-delays/preset-settings-audio-delays.component';

// Types
import { StreamInputInfo, StreamInputs } from '@modules/stream/types/stream-inputs';
import { Option } from '@modules/elements/types/option';
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';
import { Preset } from '@modules/preset/types/preset';
import { MatDialog } from '@angular/material/dialog';
import { PresetSettingsNdiSourceComponent } from '../preset-settings-ndi-source/preset-settings-ndi-source.component';


@Component({
  selector: 'app-preset-settings-input',
  templateUrl: './preset-settings-input.component.html',
  styleUrls: ['./preset-settings-input.component.less']
})
export class PresetSettingsInputComponent extends PresetSettingsBaseComponent implements OnInit, OnChanges {

  // Publics
  public inputsOptions: StreamInputInfo[];
  public inputs: StreamInputs;
  public inputSources: Option<string>[];
  public streamSources: Option<string>[] = [];
  public analogAudioSources: Option<string>[] = [];
  public asioAudioSources: Option<string>[] = [];
  public filtredSettingsOptions: PresetSettingsOptions;

  // Private
  private inputsOrder = [
    {key: 'sdi', name: 'SDI Video'},
    {key: 'ndi', name: 'NDI Video'},
    {key: 'srt_listener', name: 'SRT Listener'},
    {key: 'srt_caller', name: 'SRT Caller'},
    {key: 'smpt2110', name: 'SMPTE 2110'},
    {key: 'dante', name: 'Dante Audio'},
    {key: 'realtech', name: 'Realtek'},
    {key: 'aes', name: 'AES'},
    {key: 'cc', name: 'Closed Captions'},
    {key: 'vanccc', name: 'VANC Subtitles'},
    {key: 'ref', name: 'Reference'},
    {key: 'usbtc', name: 'USB TC'},
  ];
  private availableAspectRation = [
    'auto', '4:3', '16:9'
  ];
  private updateStream = new BehaviorSubject<void>(null);

  /**
   * Constructor
   */

  constructor(
    protected presetSettingsService: PresetSettingsService,
    protected streamService: StreamService,
    private dialog: MatDialog,
    userService: UserService
  ) {
    super(presetSettingsService, userService);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    super.ngOnInit();
    this.getSettings();

    // Inputs updates
    this.updateStream
      .pipe(
        switchMap(() => this.streamService.inputSettingsUpdated(this.stream)),
        takeUntil(this.alive),
      )
      .subscribe(() => this.getInputs());
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if ('stream' in changes) {
      this.getInputs();
      this.updateStream.next();

      // Input Sources
      this.inputSources = [{value: 'BNC' + (this.deckChannel + 1), title: 'BNC' + (this.deckChannel + 1)}];
    }
    if ('stream' in changes || 'settingsOptions' in changes || 'settings' in changes) {      
      this.filtredSettingsOptions = JSON.parse(JSON.stringify(this.settingsOptions));
      this.updateFps();
      this.updateSettingsOptions();
    }
  }

  /**
   * Methods
   */

  // Override method PresetSettingsBaseComponent
  updateSelect(key: string, value: number|string|boolean): void {
    // Update outputRange if set new inputRange
    if (key === `channels[${this.deckChannel}].inputRange`) {
      super.updateSelect(`channels[${this.deckChannel}].outputRange`, value);
    }
    // Update settings
    const settings = this.presetSettingsService.updateSettings(this.stream, this.preset.id, this.settings, key, value);
    this.settings = JSON.parse(JSON.stringify(settings));
    if (!this.stream) {
      this.updateFps();
      this.updateSettingsOptions();
    }
  }

  updateFps(): void {
    this.streamService.getSettingsInputFps(this.stream, this.settings)
      .pipe(takeUntil(this.alive))
      .subscribe(fps => {
        this.filtredSettingsOptions.framerates = this.settingsOptions.framerates.filter(item => fps.includes(item.value));
      });
  }

  updateSettingsOptions(): void {
    const fileds = [
      {optionKey: 'audioSources', inputKey: 'audioInputs'},
      {optionKey: 'referenceSources', inputKey: 'inputSync'},
      {optionKey: 'colorSpaces', inputKey: 'pixelFormats'},
      {optionKey: 'resolutions', inputKey: 'resolutions'},
    ];
    if (this.inputs && this.settings && this.settingsOptions && this.filtredSettingsOptions) {      
      const inputType = this.settings.input.activeVideoInput;
      for (const {optionKey, inputKey} of fileds) {
        this.filtredSettingsOptions[optionKey] = this.settingsOptions[optionKey].filter(
          item => this.inputs[inputType][inputKey].includes(item.value)
        );
      }
      this.filtredSettingsOptions.inputTypes = this.settingsOptions.inputTypes.filter(item => this.inputs.videoInputs.includes(item.value));
      this.filtredSettingsOptions.aspectRatioOverrides = this.settingsOptions.aspectRatioOverrides.filter(
        item => this.availableAspectRation.includes(item.value)
      );
      this.streamSources = this.inputs.ndi.inputs.map(input => new Option<string>(input, input));
      this.streamSources.splice(0, 0, new Option('', '--'));
      this.analogAudioSources = this.inputs?.analogAudioInputs?.map(input => new Option<string>(input, input)) || [];
      this.asioAudioSources = this.inputs?.asioAudioInputs?.map(input => new Option<string>(input, input)) || [];
    }
  }

  updateHDRType(value: {hdrTransferPQ: boolean, hdrMatrixConst: boolean}): void {
    this.updateSelect('input.hdrTransferPQ', value.hdrTransferPQ);
    this.updateSelect('input.hdrMatrixConst', value.hdrMatrixConst);
  }

  getSettings(): void {
    this.streamService.getSettings(this.stream)
      .pipe(
        filter(settings => !!settings),
        takeUntil(this.alive)
      )
      .subscribe(settings => {
        this.settings = settings;
        this.updateSettings();
      });
  }

  getInputs(): void {
    // Get Inputs
    this.streamService.getInputs(this.stream)
      .pipe(
        takeUntil(this.alive),
        map(inputs => {
          this.inputs = inputs;
          const result = [];
          for (const inputName of this.inputsOrder) {
            if (inputs && inputs[inputName.key]) {
              result.push({...{name: inputName.name}, ...inputs[inputName.key]})
            }
          }
          this.updateSettingsOptions();
          return result;
        }),
      )
      .subscribe(inputs => this.inputsOptions = inputs);
  }

  /**
   * Actions
   */

  autoDetectSignal(): void {
    this.streamService.autoDectectSignal(this.stream)
      .pipe(
        takeUntil(this.alive),
        delay(400)
      )
      .subscribe(() => {
        this.getSettings();
        this.getInputs();
        this.updateFps();
      });
  }

  refreshInput(): void {
    this.streamService.refreshInput(this.stream)
      .pipe(
        takeUntil(this.alive),
        delay(400)
      )
      .subscribe(() => {
        this.getSettings();
        this.getInputs();
        this.updateFps();
      });
  }

  openAudioDelays(): void {
    this.dialog.open(
      PresetSettingsAudioDelaysComponent,
      {data: {stream: this.stream, presetId: this.preset.id}, disableClose: true, autoFocus: false, restoreFocus: false});
  }

  openNdiIpsSettings(): void {
    this.dialog.open(PresetSettingsNdiSourceComponent, {data: {deckId: this.stream.deckId}, disableClose: true, autoFocus: false, restoreFocus: false});
  }

}
