import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// Lodash
import { get, has } from 'lodash-es';

// RxJS
import { Subject } from 'rxjs';

// Services
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Types
import { Preset } from '@modules/preset/types/preset';
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { UserService } from '@modules/users/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-preset-settings-base',
  templateUrl: './preset-settings-base.component.html',
  styleUrls: ['./preset-settings-base.component.less']
})
export class PresetSettingsBaseComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() stream: Stream;
  @Input() preset: Preset;
  @Input() settings: StreamSettings;
  @Input() streamSettings: Partial<StreamSettings>;
  @Input() settingsOptions: PresetSettingsOptions;
  @Input() recording = false;
  @Input() deckOnline = false;

  // Public
  public disabled = false;
  public allowedSettingsUpdate: boolean;
  public deckChannel = 0;

  // Private
  protected alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    protected presetSettingsService: PresetSettingsService,
    userService: UserService
  ) {
    userService.getCurrentPermission('editPresetSettings')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => {
        this.allowedSettingsUpdate = allowed;
        this.disabled = this.checkDisabled();
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('recording' in changes || 'deckOnline' in changes) {
      this.disabled = this.checkDisabled();
    }
    if ('stream' in changes) {
      this.deckChannel = this.stream?.deckChannel ?? 0;
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  protected checkDisabled(): boolean {
    return this.recording || !this.deckOnline || !this.allowedSettingsUpdate || (this.preset && !this.preset.isValid());
  }

  /**
   * Actions
   */

  updateSelect(key: string, value: number | string | boolean): void {
    const settings = this.presetSettingsService.updateSettings(this.stream, this.preset.id, this.settings, key, value);
    this.settings = JSON.parse(JSON.stringify(settings));
  }

  updateSettings(): void {
    if (this.stream) {
      this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
    } else {
      this.presetSettingsService.updateDefaultSetting(this.preset.id, this.settings);
    }
  }

  updatePresetSettings(): void {
    if (this.preset) {
      this.presetSettingsService.updateDefaultSetting(this.preset.id, this.preset.defaultSetting);
    }
  }

  isDefaultSetting(key: string): boolean {
    return this.streamSettings ? !has(this.streamSettings, key) : true;
  }

}
