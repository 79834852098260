import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

// Services
import { PresetService } from '@modules/preset/services/preset.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { UserService } from '@modules/users/services/user.service';
import { NodeService } from '@modules/node/services/node.service';

// Components
import { PresetChangeFolderComponent } from '../preset-change-folder/preset-change-folder.component';
import { PresetSettingsComponent } from '../preset-settings/preset-settings.component';

// Types
import { Preset } from '@modules/preset/types/preset';
import { MatDialog } from '@angular/material/dialog';
import { Channel } from '@modules/channel/types/channel';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';

@Component({
  selector: 'app-preset-list',
  templateUrl: './preset-list.component.html',
  styleUrls: ['./preset-list.component.less']
})
export class PresetListComponent implements OnInit, OnDestroy {

  // Public
  public presets: Preset[];
  public selected: Preset;
  public editing: Preset;
  public locked = false;
  public allowed: boolean;
  public allowedChangePresetFolder: boolean;
  public channels: Channel[];
  public popoverClose = new Subject<void>();
  public presetNames: string[];
  public isWeb = false;

  public isValidPresetFunc = (item: Preset): boolean => {
    return item.isValid();
  };

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private presetService: PresetService,
    private popoverService: PopoverService,
    private channelService: ChannelService,
    private streamService: StreamService,
    private nodeService: NodeService,
    private dialog: MatDialog,
    private contextMenuService: ContextMenuService,
    userService: UserService
  ) {
    // Permisions
    userService
      .getCurrentPermission('editPresetSettings')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowed = allowed);
    userService
      .getCurrentPermission('editDiskDestination')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowedChangePresetFolder = allowed);
    this.isWeb = !this.nodeService.isElectron;
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.presetService.getPresets()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(presets => {
        this.presets = presets;
        this.presetNames = this.presets.map(preset => preset.name);
      });
    this.presetService.getCurrentPreset()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(presets => {
        this.selected = presets;
      });
    // Get Channels
    this.channelService.getChannels()
      .pipe(takeUntil(this.alive))
      .subscribe(channels => this.channels = channels);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  selectPreset(preset: Preset): void {
    this.presetService.selectPreset(preset);
  }

  createPreset(name: string): void {
    const preset = Preset.emptyPreset(name);
    this.presetService.addPreset(preset);
  }

  deletePreset(preset: Preset): void {
    if (!preset?.id) {
      return;
    }
    this.presetService.deletePreset(preset);
  }

  showPreset(preset: Preset): void {
    this.presetService.showPresetInFinder(preset);
  }

  showCreatePopover(template: TemplateRef<any>, iconElement: any): void {
    this.popoverService.create(
      iconElement['_elementRef'],
      {
        content: template,
        placement: 'bottom',
        trigger: 'click',
        innerShadow: false,
        arrow: true,
        popoverOffsetY: 0,
        showUntil: this.popoverClose
      }
    );
  }

  setChannel(preset: Preset, channel: Channel): void {
    if (channel.streamId) {
      this.streamService.setPreset(preset.id, channel.streamId);
      this.presetService.selectPreset(preset);
    }
  }

  openPreset(preset: Preset, event?: MouseEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
    this.streamService.disablePreviews();
    this.dialog
      .open(PresetSettingsComponent, {data: {presetId: preset.id}, disableClose: true, autoFocus: false})
      .afterClosed()
      .pipe(
        delay(1500),
        takeUntil(this.alive)
      )
      .subscribe(() => this.streamService.enablePreviews());
  }

  openSettings(): void {
    this.dialog.open(PresetChangeFolderComponent, { autoFocus: false, disableClose: true });
  }

  saveAs(preset: Preset): void {
    this.presetService.savePreset(preset);
  }

  renamePreset(preset: Preset): void {
    this.editing = preset;
  }

  changePresetName(name: string, preset: Preset): void {
    this.presetService.renamePreset(preset, name);
  }

  presetEditingChange(editing: boolean, preset: Preset): void {
    this.editing = editing ? preset : null;
  }

  reloadPresets():void {
    this.presetService.reloadPresets();
  }

  openContextMenu($event: MouseEvent, contextMenu: ContextMenuComponent, item: any): void {
    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: contextMenu,
      event: $event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

}
