import { Component, Input, OnInit } from '@angular/core';

// Types
import { Clip } from '@modules/deck/types/clip';

@Component({
  selector: 'app-playlist-clip-info',
  templateUrl: './playlist-clip-info.component.html',
  styleUrls: ['./playlist-clip-info.component.less']
})
export class PlaylistClipInfoComponent implements OnInit {

  // Inputs
  @Input() clip: Clip;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

}
