// Common
import { Injectable, NgZone, TemplateRef } from '@angular/core';

// RxJS
import { Observable, Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';

// Types
import { DragData } from '../types/drag-data';
import { DroppableArea } from '../types/droppable-area';
import { Coordinates } from '../types/coordinates';
import { DraggableEvent } from '../types/draggable-event';

@Injectable()
export class DragNDropService {

  // Private
  private dataThread = new BehaviorSubject<DragData>(null);
  private droppableAreas: DroppableArea[] = [];
  private enteredThread = new BehaviorSubject<DraggableEvent>(null);
  private leavedThread = new Subject<DraggableEvent>();
  private overThread = new Subject<DraggableEvent>();
  private droppedThread = new Subject<DraggableEvent>();
  private droppedOutsideThread = new Subject<void>();
  private controlledDropThread = new BehaviorSubject<TemplateRef<any>>(null);
  private currentPlaceholderElement = new BehaviorSubject<HTMLElement>(null);
  private repositionThread = new Subject<boolean>();

  constructor(
    private ngZone: NgZone
  ) {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'mouseup')
        .pipe(
          filter(() => !!this.dataThread.value && !this.controlledDropThread.value),
        )
        .subscribe((event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          this.ngZone.run(() => this.emitDrop(event.pageX, event.pageY));
        });
    });
  }

  setDragging(dragData: DragData) {
    this.dataThread.next(dragData);
  }

  getDraggingDataChanges(): Observable<DragData> {
    return this.dataThread
      .asObservable()
      .pipe(
        distinctUntilChanged(),
      );
  }

  registerDroppableArea(
    key: symbol,
    element: HTMLElement,
    zIndex: number,
    afterDropTemplate: TemplateRef<any>
  ) {
    if (!this.droppableAreas.some(area => area.key === key)) {

      const { left: x, top: y, width, height } = element.getBoundingClientRect();

      const coordinates = this.getAreaCoordinates({x, width, y, height}, element.parentElement);

      if (coordinates) {
        this.droppableAreas.push({
          key,
          entered: false,
          x: coordinates.x,
          y: coordinates.y,
          width: coordinates.width,
          height: coordinates.height,
          zIndex,
          afterDropTemplate
        });
      }
    }
  }

  unregisterDroppableArea(key: symbol) {
    const index = this.droppableAreas.findIndex(area => area.key === key);

    if (index >= 0) {
      this.droppableAreas.splice(index, 1);
    }
  }

  checkDroppableAreas(x: number, y: number): void {
    this.droppableAreas.forEach((area: DroppableArea) => {
      if (
        area.x < x && area.x + area.width > x &&
        area.y < y && area.y + area.height > y
      ) {
        if (!area.entered) {
          area.entered = true;
          this.enteredThread.next(new DraggableEvent({x, y}, area));
        } else {
          // Move
          this.overThread.next(new DraggableEvent({x, y}, area));
        }
      } else if (area.entered) {
        area.entered = false;
        this.leavedThread.next(new DraggableEvent({x, y}, area));
      }
    });
    this.checkNearAreas(x, y);
  }

  emitDrop(x: number, y: number) {
    let dropOutside = true;

    const enteredDroppableAreas = this.droppableAreas
      .filter((area: DroppableArea) => area.entered);

    enteredDroppableAreas.forEach((area: DroppableArea) => {
      this.leavedThread.next(new DraggableEvent({x, y}, area));
      area.entered = false;
      dropOutside = false;
    });

    const dropArea = enteredDroppableAreas
      .reduce((topMostArea: DroppableArea, area: DroppableArea) => (
        !topMostArea || area.zIndex > topMostArea.zIndex ? area : topMostArea
      ), null);

    if (dropArea) {
      this.droppedThread.next(new DraggableEvent({x, y}, dropArea));
    }

    if (dropOutside) {
      this.droppedOutsideThread.next();
      this.repositionThread.next(false);
    } else if (dropArea.afterDropTemplate) {
      this.setControlledDrop(dropArea.afterDropTemplate);
    } else {
      this.dataThread.next(null);
    }
  }

  getDragEnter(key: symbol): Observable<DraggableEvent> {
    return this.enteredThread
      .asObservable()
      .pipe(
        filter((event: DraggableEvent) => key === event?.dropArea?.key)
      );
  }

  getAllDragEnter(): Observable<DraggableEvent> {
    return this.enteredThread.asObservable();
  }

  getDragLeave(key: symbol): Observable<DraggableEvent> {
    return this.leavedThread
      .asObservable()
      .pipe(
        filter((event: DraggableEvent) => key === event?.dropArea?.key)
      );
  }

  getAllDragLeave(): Observable<DraggableEvent> {
    return this.leavedThread.asObservable();
  }
  
  getDragOver(key: symbol): Observable<DraggableEvent> {
    return this.overThread
      .asObservable()
      .pipe(
        filter((event: DraggableEvent) => key === event?.dropArea?.key)
      );
  }

  getDrop(key: symbol): Observable<DraggableEvent> {
    return this.droppedThread
      .asObservable()
      .pipe(
        filter((event: DraggableEvent) => key === event?.dropArea?.key)
      );
  }

  getDropOutside(): Observable<void> {
    return this.droppedOutsideThread
      .asObservable()
      .pipe(
        map(() => null)
      );
  }

  getControlledDrop(): Observable<TemplateRef<any>> {
    return this.controlledDropThread.asObservable();
  }

  setControlledDrop(template: TemplateRef<any>) {
    this.controlledDropThread.next(template);
  }

  setCurrentPlaceholder(placeholder: HTMLElement) {
    this.currentPlaceholderElement.next(placeholder);
  }

  getRepositionThread(): Observable<boolean> {
    return this.repositionThread
      .pipe(
        distinctUntilChanged(),
      );
  }

  checkNearAreas(x: number, y: number) {
    if (!this.currentPlaceholderElement) {
      return;
    }

    const { width: placeholderWidth, height: placeholderHeight } = this.currentPlaceholderElement.value.getBoundingClientRect();
    const tolerance = 15;

    this.repositionThread.next(
      this.droppableAreas.some((area: DroppableArea) => (
        area.x - tolerance < x &&
        area.x + area.width + tolerance > x &&
        area.y - tolerance < y &&
        area.y + area.height + tolerance > y &&
        (
          area.width < placeholderWidth ||
          area.height < placeholderHeight
        )
      ))
    );
  }

  getAreaCoordinates(coordinates: Coordinates, parent: HTMLElement): Coordinates {
    if (!parent) {
      return coordinates;
    }

    const { x, y, width, height } = coordinates;
    const newCoordinates = { x, y, height, width };

    if (parent.scrollHeight > parent.clientHeight) {
      const { height: parentHeight, top: parentTop } = parent.getBoundingClientRect();

      if (y < parentTop) {
        if (y + height > parentTop) { // partially hidden
          newCoordinates.y = parentTop;
          newCoordinates.height = y + height - parentTop;
        } else {
          return null;
        }
      } else if (y + height > parentTop + parentHeight) {
        if (y < parentTop + parentHeight) { // partially hidden
          newCoordinates.height = parentTop + parentHeight - y;
        } else {
          return null;
        }
      }
    }

    return this.getAreaCoordinates(newCoordinates, parent.parentElement);
  }
}
