import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { NodeService } from './services/node.service';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    NodeService,
  ],
})
export class NodeModule { }
