<div
  class="color-select"
  [style.backgroundColor]="currentColor"
  popover
  [popoverContent]="colorPopover"
  popoverPlacement="top"
  popoverTrigger="click"
  [popoverOffsetY]="0"
></div>

<!-- Color Picker Popover -->
<ng-template #colorPopover>
  <color-sketch [color]="currentColor" (onChangeComplete)="colorChanged($event)"></color-sketch>
</ng-template>
