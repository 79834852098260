import { UniqueID } from '@modules/elements/types/unique-id';

export class Stream {
  id: string;
  deckId: string;
  presetId: string;
  name: string;
  deckChannel: number;
  deckChannelName: string;
  audioPair: number = 0;

  constructor(deckId: string, name?: string, deckChannel?: number, presetId: string = null) {
    this.id = UniqueID.generate();
    this.deckId = deckId;
    this.name = name;
    this.deckChannel = deckChannel;
    this.presetId = presetId;
  }

}
