import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { NetworkService } from './services/network.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    NetworkService,
  ],
})
export class CoreModule { }
