import { Pipe, PipeTransform } from '@angular/core';
import { RRule, RRuleSet, rrulestr, Frequency } from 'rrule';

@Pipe({
  name: 'rrule'
})
export class RrulePipe implements PipeTransform {

  transform(rruleString: string, action?: 'text' | 'date' | 'frequency' | 'weekdays'): RRule | string | Date {
    const rrule = rrulestr(rruleString);
    if (action && action === 'text') {
      return rrule.toText();
    } else if (action && action === 'date') {
      return rrule.after(new Date());
    } else if (action && action === 'frequency') {
      switch (rrule.origOptions.freq) {
        case Frequency.YEARLY: return 'Yearly';
        case Frequency.MONTHLY: return 'Monthly';
        case Frequency.WEEKLY: return 'Weekly';
        case Frequency.DAILY: return 'Daily';
        case Frequency.HOURLY: return 'Hourly';
        case Frequency.MINUTELY: return 'Minutely';
        case Frequency.SECONDLY: return 'Secondly';
        default:
          break;
      }
    } else if (action && action === 'weekdays') {
      const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return rrule.options.byweekday.sort().map(week => weekdays[week]).map(week => week.slice(0, 2).toUpperCase()).join(', ');
    }
    return rrule;
  }

}
