<div class="disc-progress-content">
  <div
    class="progress-bar"
    [class.yellow]="(free / total) < yellowPercentProgress"
    [class.red]="free < redSize"
    [style.width.%]="100 - (free / total) * 100"
  ></div>
  <div class="progress-title" *ngIf="free && total">{{ (free / total) | percent }} Free - {{ free | fileSize }} Remain ({{ total | fileSize }})</div>
  <div class="progress-title" *ngIf="!free || !total">N/A</div>
</div>
