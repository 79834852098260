import { Injectable } from '@angular/core';

// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

// Lodash
import set from 'lodash-es/set';
import has from 'lodash-es/has';

// Services
import { PresetService } from './preset.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { NodeService } from '@modules/node/services/node.service';

// Types
import { PresetSettingsOptions } from '../types/preset-settings-options';
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { Preset } from '../types/preset';
import { InputSettingsCopy } from '../types/input-settings-copy';
import { DeckDrive } from '@modules/deck/types/deck-status';

@Injectable({
  providedIn: 'root'
})
export class PresetSettingsService {

  // Private
  private settingsOptions = new BehaviorSubject<PresetSettingsOptions>(new PresetSettingsOptions());

  /**
   * Constructor
   */

  constructor(
    private presetService: PresetService,
    private streamService: StreamService,
    private channelService: ChannelService,
    private nodeService: NodeService,
  ) {
    // Update template for renamed preset
    this.presetService.getNeedUpdatePresetNameTemplate()
      .subscribe(preset => this.updatePresetNameTemplate(preset));
  }

  /**
   * Methods
   */

  getSettingsOptions(): Observable<PresetSettingsOptions> {
    return this.settingsOptions.asObservable();
  }

  updateSettings(stream: Stream, presetId: string, settings: StreamSettings, key: string, value): StreamSettings {
    // Update settings
    if (settings) {
      set(settings, key, value);
    }
    if (stream) {
      this.updateStreamSettings(stream, settings);
    } else if (presetId) {
      this.updateDefaultSetting(presetId, settings);
    }
    return settings;
  }

  updateDefaultSetting(presetId: string, settings: StreamSettings): void {
    this.updateSettingsValuesIfNeeded(settings);
    this.updateDefaultSettingsValuesIfNeeded(settings);
    this.presetService.updateDefaultSettings(presetId, settings);
  }

  updateStreamSettings(stream: Stream, settings: StreamSettings): Observable<void> {
    if (stream) {
      this.updateSettingsValuesIfNeeded(settings);
      const preset = this.presetService.getPresetSync(stream.presetId);
      this.presetService.updateStreamSettings(stream.presetId, stream.id, settings);
      return this.streamService.updateSettings(stream, settings);      
    }
  }

  updatePresetNameTemplate(preset: Preset): void {
    for (const key in preset.settings) {
      if (preset.settings.hasOwnProperty(key) && preset.settings[key]) {
        this.presetService.rewriteNameTemplate(preset, key);
      }
    }
    this.presetService.updatePreset(preset);
    this.channelService.getChannels()
      .pipe(
        take(1),
        map(channels => channels.map(channel => channel.streamId).filter(id => !!id && Object.keys(preset.settings).includes(id))),
        filter(streamIds => streamIds.length > 0),
        switchMap(streamIds =>
          this.streamService.getStreams()
            .pipe(
              map(streams => streams.filter(stream => streamIds.includes(stream.id)))
            )
        ),
        take(1),
      )
      .subscribe(streams =>
        streams.map(stream => {
          if (preset.settings[stream.id] && stream.presetId === preset.id) {
            this.streamService.updateSettings(stream, preset.getSetting(stream.id));
          }
        })
      );
  }

  copySettings(fromStream: Stream, streams: Stream[], settingsCopy: InputSettingsCopy): void {
    const preset = this.presetService.getPresetSync(fromStream.presetId);
    const generalSettings = preset.getSetting(fromStream.id);
    streams.forEach(stream => {
      const settings = preset.getSetting(stream.id);
      if (settingsCopy.inputType) {
        settings.input.activeVideoInput = generalSettings.input.activeVideoInput;
        settings.input.ndiStreamSrc = generalSettings.input.ndiStreamSrc;
      }
      if (settingsCopy.audioSource) {
        settings.input.activeAudioInput = generalSettings.input.activeAudioInput;
        settings.input.audioDelays = generalSettings.input.audioDelays;
        settings.audioDelaysLocked = generalSettings.audioDelaysLocked;
        settings.input.asioAudioSource = generalSettings.input.asioAudioSource;
      }
      if (settingsCopy.resolution) {
        settings.input.activeResolution = generalSettings.input.activeResolution;
      }
      if (settingsCopy.frameRate) {
        settings.input.activeFrameRate = generalSettings.input.activeFrameRate;
      }
      if (settingsCopy.colorFormat) {
        settings.input.activePixelFormat = generalSettings.input.activePixelFormat;
        settings.input.hdr = generalSettings.input.hdr;
        settings.input.hdrMatrixConst = generalSettings.input.hdrMatrixConst;
        settings.input.hdrTransferPQ = generalSettings.input.hdrTransferPQ;
        settings.channels[stream.deckChannel].isYuvLevelA = generalSettings.channels[fromStream.deckChannel].isYuvLevelA;
        settings.channels[stream.deckChannel].inputRange = generalSettings.channels[fromStream.deckChannel].inputRange;
      }
      if (settingsCopy.audioOutputRouting) {
        settings.channels[stream.deckChannel].audioOutput = generalSettings.channels[fromStream.deckChannel].audioOutput;
        settings.channels[stream.deckChannel].audioPlaythroug = generalSettings.channels[fromStream.deckChannel].audioPlaythroug;
      }
      if (settingsCopy.srtOutput) {
        settings.output = generalSettings.output;
      }
      if (settingsCopy.outputOverlays) {
        settings.channels[stream.deckChannel].charOut = generalSettings.channels[fromStream.deckChannel].charOut;
      }
      if (settingsCopy.recordDestinations) {
        settings.sameDiskAsPreset = generalSettings.sameDiskAsPreset;
      }
      if (settingsCopy.burns) {
        settings.channels[stream.deckChannel].burnIn = generalSettings.channels[fromStream.deckChannel].burnIn;
      }
      if (settingsCopy.allSettings) {
        settings.tcSource = generalSettings.tcSource;
        settings.input.frameDropBehavior = generalSettings.input.frameDropBehavior;
        settings.input.signalLossBehavior = generalSettings.input.signalLossBehavior;
        settings.input.aspectRatioString = generalSettings.input.aspectRatioString;
        settings.automation = generalSettings.automation;
        settings.channels[stream.deckChannel].ltcSource = generalSettings.channels[fromStream.deckChannel].ltcSource;
        settings.channels[stream.deckChannel].upconvertMode = generalSettings.channels[fromStream.deckChannel].upconvertMode;
        settings.channels[stream.deckChannel]._444output = generalSettings.channels[fromStream.deckChannel]._444output;
        settings.channels[stream.deckChannel].maxH264Duration = generalSettings.channels[fromStream.deckChannel].maxH264Duration;
        settings.channels[stream.deckChannel].recTCOffsetEnabled = generalSettings.channels[fromStream.deckChannel].recTCOffsetEnabled;
        settings.channels[stream.deckChannel].recTCOffsetString = generalSettings.channels[fromStream.deckChannel].recTCOffsetString;
        settings.channels[stream.deckChannel].recTCOffsetNegative = generalSettings.channels[fromStream.deckChannel].recTCOffsetNegative;
        settings.channels[stream.deckChannel].preroll = generalSettings.channels[fromStream.deckChannel].preroll;
        settings.channels[stream.deckChannel].remoteCtrl.jogModeStandard = generalSettings.channels[fromStream.deckChannel].remoteCtrl.jogModeStandard;
        settings.channels[stream.deckChannel].remoteCtrl.jogSensitive = generalSettings.channels[fromStream.deckChannel].remoteCtrl.jogSensitive;
        settings.channels[stream.deckChannel].useGOPTimecode = generalSettings.channels[fromStream.deckChannel].useGOPTimecode;
        settings.interplaSettings = generalSettings.interplaSettings;
        settings.editShareSettings = generalSettings.editShareSettings;
      }
      const updateEncoderSettings = (
        toSettings: StreamSettings,
        fromSettings: StreamSettings,
        encoderIndex: number,
        toDeckChannel: number,
        fromDeckChannel: number
      ) => {
        if (toSettings.encode.length <= encoderIndex || fromSettings.encode.length <= encoderIndex) {
          return;
        }
        if (settingsCopy.audioInputRouting) {
          toSettings.channels[toDeckChannel].audioRouting[encoderIndex].audioRoutingData = fromSettings.channels[fromDeckChannel].audioRouting[encoderIndex].audioRoutingData;
        }
        if (settingsCopy.codec) {
          toSettings.encode[encoderIndex].activeCodec = fromSettings.encode[encoderIndex].activeCodec;
          toSettings.encode[encoderIndex].activeAudioCodec = fromSettings.encode[encoderIndex].activeAudioCodec;
          toSettings.encode[encoderIndex].h264DefaultAudioCodec = fromSettings.encode[encoderIndex].h264DefaultAudioCodec;
        }
        if (settingsCopy.quality) {
          toSettings.encode[encoderIndex].activeQuality = fromSettings.encode[encoderIndex].activeQuality;
        }
        if (settingsCopy.wrapper) {
          toSettings.encode[encoderIndex].activeWrapper = fromSettings.encode[encoderIndex].activeWrapper;
          toSettings.encode[encoderIndex].fieldWrapped = fromSettings.encode[encoderIndex].fieldWrapped;
          toSettings.encode[encoderIndex].fieldShift = fromSettings.encode[encoderIndex].fieldShift;
          toSettings.encode[encoderIndex].forceBT709 = fromSettings.encode[encoderIndex].forceBT709;
          toSettings.encode[encoderIndex].isCBR = fromSettings.encode[encoderIndex].isCBR;
          toSettings.encode[encoderIndex].isTopFirst = fromSettings.encode[encoderIndex].isTopFirst;
        }
        if (settingsCopy.recordDestinations) {
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].activeWriteMode = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].activeWriteMode;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].activePrimaryDrivePos = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].activePrimaryDrivePos;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].activeSecondaryDrivePos = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].activeSecondaryDrivePos;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].activePrimaryOverrideDrivePos = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].activePrimaryOverrideDrivePos;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].activeSecondaryOverrideDrivePos = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].activeSecondaryOverrideDrivePos;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].overridePrimaryPath = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].overridePrimaryPath;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].overrideSecondaryPath = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].overrideSecondaryPath;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].primaryPath = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].primaryPath;
          toSettings.channels[toDeckChannel].driveAssignments[encoderIndex].secondaryPath = fromSettings.channels[fromDeckChannel].driveAssignments[encoderIndex].secondaryPath;
        }
        if (settingsCopy.allSettings) {
          toSettings.encode[encoderIndex].enabled = fromSettings.encode[encoderIndex].enabled;
          toSettings.encode[encoderIndex].segmentMode = fromSettings.encode[encoderIndex].segmentMode;
          toSettings.encode[encoderIndex].segmentDuration = fromSettings.encode[encoderIndex].segmentDuration;
          toSettings.encode[encoderIndex].isSegmentLimitMode = fromSettings.encode[encoderIndex].isSegmentLimitMode;
          toSettings.encode[encoderIndex].segmentLimit = fromSettings.encode[encoderIndex].segmentLimit;
          toSettings.encode[encoderIndex].encoderName = fromSettings.encode[encoderIndex].encoderName;
          toSettings.encode[encoderIndex].bigEndianType = fromSettings.encode[encoderIndex].bigEndianType;
          toSettings.channels[toDeckChannel].audioRouting[encoderIndex].audioFrequencyModel.currentBitDepth = fromSettings.channels[fromDeckChannel].audioRouting[encoderIndex].audioFrequencyModel.currentBitDepth;
          toSettings.encode[encoderIndex].anamorphic = fromSettings.encode[encoderIndex].anamorphic;
          toSettings.encode[encoderIndex].generateXMLMode = fromSettings.encode[encoderIndex].generateXMLMode;
          toSettings.encode[encoderIndex].overrideXMLPath = fromSettings.encode[encoderIndex].overrideXMLPath;
          toSettings.encode[encoderIndex].XMLPath = fromSettings.encode[encoderIndex].XMLPath;
          toSettings.encode[encoderIndex].useMxfClipFolders = fromSettings.encode[encoderIndex].useMxfClipFolders;
          toSettings.encode[encoderIndex].overrideAAFPath = fromSettings.encode[encoderIndex].overrideAAFPath;
          toSettings.encode[encoderIndex].AAFPath = fromSettings.encode[encoderIndex].AAFPath;
          toSettings.encode[encoderIndex].overrideKMLPath = fromSettings.encode[encoderIndex].overrideKMLPath;
          toSettings.channels[toDeckChannel].burnIn[encoderIndex] = fromSettings.channels[fromDeckChannel].burnIn[encoderIndex];
          toSettings.encode[encoderIndex].captionsMode = fromSettings.encode[encoderIndex].captionsMode;
          toSettings.encode[encoderIndex].sccField1 = fromSettings.encode[encoderIndex].sccField1;
          toSettings.encode[encoderIndex].sccField2 = fromSettings.encode[encoderIndex].sccField2;
          toSettings.encode[encoderIndex].CCLine = fromSettings.encode[encoderIndex].CCLine;
          toSettings.encode[encoderIndex].srtCC = fromSettings.encode[encoderIndex].srtCC;
          toSettings.encode[encoderIndex].zeroBasedSrtCC = fromSettings.encode[encoderIndex].zeroBasedSrtCC;
        }
      };
      if (settingsCopy.primaryEncoder) {
        updateEncoderSettings(settings, generalSettings, 0, stream.deckChannel, fromStream.deckChannel);
      }
      if (settingsCopy.secondaryEncoder) {
        updateEncoderSettings(settings, generalSettings, 1, stream.deckChannel, fromStream.deckChannel);
      }
      if (settingsCopy.tertiaryEncoder) {
        updateEncoderSettings(settings, generalSettings, 2, stream.deckChannel, fromStream.deckChannel);
      }
      this.updateStreamSettings(stream, settings);
    });
  }

  updateSettingsValuesIfNeeded(settings: StreamSettings): StreamSettings {
    for (let index = 1; index < settings.encode.length; index++) {
      if (settings.encode[index].segmentMode !== 'no') {
        settings.encode[index].segmentMode = settings.encode[0].segmentMode;
      }
      settings.encode[index].segmentDuration = settings.encode[0].segmentDuration;
      settings.encode[index].isSegmentLimitMode = settings.encode[0].isSegmentLimitMode;
      settings.encode[index].segmentLimit = settings.encode[0].segmentLimit;
    }
    return settings;
  }

  updateDefaultSettingsValuesIfNeeded(settings: StreamSettings): StreamSettings {
    // Video input (SDI, NDI, SRT, none)
    const activeVideoInputKeys = Object.keys(this.streamService.streamSettingsParams);
    if (!activeVideoInputKeys.includes(settings.input.activeVideoInput)) {
      settings.input.activeVideoInput = activeVideoInputKeys[0];
    }
    // Resolution (1080p, 4k, 720p, PAL etc)
    const activeResolutionKeys = Object.keys(this.streamService.streamSettingsParams[settings.input.activeVideoInput].video);
    if (!activeResolutionKeys.includes(settings.input.activeResolution)) {
      settings.input.activeResolution = activeResolutionKeys[0];
    }
    // FPS (23.98, 24, 25, 50 etc)
    const activeFrameRateKeys = Object.keys(this.streamService.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution]);
    if (!activeFrameRateKeys.includes(settings.input.activeFrameRate)) {
      settings.input.activeFrameRate = activeFrameRateKeys[0];
    }
    // Colors (RGB10, YUV8, YUV10 etc)
    const activePixelFormatKeys = Object.keys(this.streamService.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution][settings.input.activeFrameRate]);
    if (!activePixelFormatKeys.includes(settings.input.activePixelFormat)) {
      settings.input.activePixelFormat = activePixelFormatKeys[0];
    }
    // Codecs (ProRes, H264, DNxHR etc)
    const activeCodecKeys = Object.keys(this.streamService.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution][settings.input.activeFrameRate][settings.input.activePixelFormat]);
    for (let encoderIndex = 0; encoderIndex < settings.encode.length; encoderIndex++) {
      if (!activeCodecKeys.includes(settings.encode[encoderIndex].activeCodec)) {
        settings.encode[encoderIndex].activeCodec = activeCodecKeys[0];
      }
      // Quality
      const activeQualityKeys = Object.keys(this.streamService.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution][settings.input.activeFrameRate][settings.input.activePixelFormat][settings.encode[encoderIndex].activeCodec]);
      if (!activeQualityKeys.includes(settings.encode[encoderIndex].activeQuality)) {
        settings.encode[encoderIndex].activeQuality = activeQualityKeys[0];
      }
      // Wrapper
      const activeWrapperKeys = Object.keys(this.streamService.streamSettingsParams[settings.input.activeVideoInput].video[settings.input.activeResolution][settings.input.activeFrameRate][settings.input.activePixelFormat][settings.encode[encoderIndex].activeCodec][settings.encode[encoderIndex].activeQuality]);
      if (!activeWrapperKeys.includes(settings.encode[encoderIndex].activeWrapper)) {
        settings.encode[encoderIndex].activeWrapper = activeWrapperKeys[0];
      }
    }
    // Audio Input (sdi, ndi, srt, off)
    const activeAudioInputKeys = Object.keys(this.streamService.streamSettingsParams[settings.input.activeVideoInput].audio);
    if (!activeAudioInputKeys.includes(settings.input.activeAudioInput)) {
      if (settings.input.activeVideoInput === 'sdi' && activeAudioInputKeys.includes('sdi_16ch')) {
        settings.input.activeAudioInput = 'sdi_16ch';
      } else if (settings.input.activeVideoInput === 'ndi' && activeAudioInputKeys.includes('ndi')) {
        settings.input.activeAudioInput = 'ndi';
      } else if (settings.input.activeVideoInput === 'srt_listener' || settings.input.activeVideoInput === 'srt_caller' && activeAudioInputKeys.includes('srt')) {
        settings.input.activeAudioInput = 'srt';
      } else {
        settings.input.activeAudioInput = 'off';
      }
    }
    // HDR
    if (settings.input.activePixelFormat === 'yuv8') {
      settings.input.hdr = false;
    }
    // Copy channel from index 0 to other
    const channel = settings.channels[0];
    settings.channels = settings.channels.fill(channel);

    // Return
    return settings;
  }

  getTitle(value: string|number, key: string): string {
    const options = this.settingsOptions.value;
    if (!has(options, key)) {
      return 'N/A';
    }
    const option = options[key].find(item => item.value === value);
    return option ? option.title : 'N/A';
  }

  getDriverPosition(folderPath: string, drivers: DeckDrive[]): string {
    const parsePath = this.nodeService.path.win32.parse(folderPath);
    const drive = drivers.find(drive => drive.rootPath?.toLowerCase() === parsePath.root?.toLowerCase());
    return drive?.position || '';
  }

}
