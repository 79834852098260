import { Injectable } from '@angular/core';

// Services
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  /**
   * Constructor
   */

  constructor(
    private toastrService: ToastrService
  ) { }

  /**
   * Methods
   */

  error(message: string, title?: string): void {
    this.toastrService.error(message, title, {
      positionClass: 'toast-bottom-right',
      disableTimeOut: true,
      closeButton: true,
    });
  }

  warning(message: string, title?: string, timeout: boolean = true): void {
    this.toastrService.warning(message, title, {
      positionClass: 'toast-bottom-right',
      disableTimeOut: !timeout,
      closeButton: true,
    });
  }

  success(message: string, title?: string, timeout: boolean = true): void {
    this.toastrService.success(message, title, {
      positionClass: 'toast-bottom-right',
      disableTimeOut: !timeout,
      closeButton: true,
    });
  }

}
