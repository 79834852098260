import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// RxJS
import { of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { delayedRetry } from '@modules/core/types/delayed-retry';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';
import { NodeService } from '@modules/node/services/node.service';
import { NotificationService } from '@modules/elements/services/notification.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';

// Components
import { PlaylistEditorComponent } from '../playlist-editor/playlist-editor.component';

// Types
import { Playlist, PlaylistClip, TimecodeNull } from '@modules/playlist-editor/types/playlist';
import { Stream } from '@modules/stream/types/stream';
import { Clip } from '@modules/deck/types/clip';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';
import { Deck } from '@modules/deck/types/deck';
import { StreamStatus } from '@modules/stream/types/stream-status';
import { Option } from '@modules/elements/types/option';
import { Timecode } from '@modules/elements/types/timecode';
import { MatDialog } from '@angular/material/dialog';
import { DraggableEvent } from '@modules/drag-n-drop/types/draggable-event';

@Component({
  selector: 'app-playlist-files',
  templateUrl: './playlist-files.component.html',
  styleUrls: ['./playlist-files.component.less']
})
export class PlaylistFilesComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() stream: Stream;
  @Input() playlist: Playlist;
  @Input() isModal = false;

  // Public
  public clips: any[] = [];
  public selection = new SelectionModel<PlaylistClip>(true, []);
  public clipInfo: Clip;
  public status: StreamStatus;
  public thumbnailUrl: string;
  public playlistBehaviour = {
    cue: [
      new Option('end_of_current', 'End of current'),
      new Option('start_of_next', 'Start of next'),
    ],
    play: [
      new Option('stop_at_current', 'Stop at current'),
      new Option('stop_at_next', 'Stop at next'),
      new Option('continuous', 'Continuous'),
    ],
  };
  public isValidDeleteClipFunc = (item: Clip): boolean => {
    return this.playlist?.clips?.length > 1;
  };

  public isValidOpenClipFunc = (item: Clip): boolean => {
    return this.status?.playback?.openPlaylistName === this.playlist.name && this.status?.playback?.isLoaded;
  };
  public dropPlaceholder = false;
  public invalidDropFile = false;

  // Private
  private alive = new Subject<void>();
  private streamUpdated = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private fileManagerService: FileManagerService,
    private streamService: StreamService,
    private deckService: DeckService,
    private playlistService: PlaylistService,
    private nodeService: NodeService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private contextMenuService: ContextMenuService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('playlist' in changes) {
      this.updateClips();
    }
    if ('stream' in changes && !isEqual(changes.stream.currentValue?.id, changes.stream.previousValue?.id)) {
      this.updateStream();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
    this.streamUpdated.next();
    this.streamUpdated.complete();
  }

  /**
   * Methods
   */

  updateStream(): void {
    this.streamUpdated.next();
    // Thumbnail
    const deck = this.deckService.getDeckSync(this.stream.deckId);
    this.thumbnailUrl = Deck.getAddress(deck) + '/thumbnail?id=';
    // Get current open file
    this.streamService.getStatus(this.stream)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.streamUpdated),
      )
      .subscribe(status => this.status = status);
    // Update Playlist
    this.playlistService.playlistsChanged(this.stream.deckId)
      .pipe(
        switchMap(() => this.playlistService.getPlaylist(this.stream.deckId, this.playlist.name)),
        takeUntil(this.streamUpdated),
      )
      .subscribe(playlist => {
        this.playlist = playlist;
        this.updateClips();
      });
  }

  updateClips(): void {
    if (!this.clips) {
      this.clips = this.playlist.clips;
    }
    const deckId = this.stream.deckId;
    const paths = this.playlist.clips.map(clip => clip.filename);
    this.fileManagerService.getIndexedFiles(deckId, paths)
      .pipe(
        map(files => {
          const clips = [];
          this.playlist.clips.forEach(playlistClip => {
            const clip = files.find(file => {
              const filename = this.nodeService.path.win32.normalize(file?.drivePath + file?.dirPath + file?.name);
              return playlistClip.filename === filename;
            });
            if (clip) {
              const duration = clips.reduce((sum, item) => sum + (+item.durationFrames), 0);
              const interlaced = Timecode.isInterlaced(+clip.fps, clip.resolution);
              const timebase = Timecode.getTimebase(+clip.fps, interlaced);
              const playlistStartTimecode = new Timecode(duration, timebase, clip.drop === 1);
              const inTimecode = playlistClip.in === TimecodeNull ? null : new Timecode(+playlistClip.in, timebase, clip.drop === 1).toString();
              const outTimecode = playlistClip.out === TimecodeNull ? null : new Timecode(+playlistClip.out, timebase, clip.drop === 1).toString();
              const result = {
                ...playlistClip,
                ...clip,
                playlistStartTimecode: playlistStartTimecode.toString(),
                inTimecode,
                outTimecode
              };
              clips.push(result);
            } else {
              clips.push(playlistClip);
            }
          });
          return clips;
        }),
        takeUntil(this.alive)
      )
      .subscribe(result => {
        console.log('[PLAYLIST][INDEX_FILES] ', result)
        this.clips = result;
      });
  }

  /**
   * Actions
   */

  playPlaylist(): void {
    if ((this.status?.playback?.openPlaylistName === this.playlist.name) && this.status?.playback?.isLoaded) {
      return;
    }
    this.streamService.ejectFile(this.stream)
        .subscribe(() => this.playlistService.playPlaylist(this.stream, this.playlist, true));
  }

  selectClip(clip: Clip): void {
    this.streamService.openFile(this.stream, [clip.id]);
  }

  deleteFile(clip: any): void {
    const index = this.playlist.clips.findIndex(item => item.filename === clip.filename && item.position === clip.position);
    if (index !== -1) {
      this.playlist.clips.splice(index, 1);
      this.playlist.clips.forEach((clip, index) => clip.position = index + '');
      this.playlistService.updatePlaylist(this.stream.deckId, this.playlist);
    }
  }

  updatePlaylistCueBehavior(value: string): void {
    this.playlistService.updatePlaylistBehavior(this.stream, value, this.status.playback.playlistPlayBehavior);
  }

  updatePlaylistPlayBehavior(value: string): void {
    this.playlistService.updatePlaylistBehavior(this.stream, this.status.playback.playlistCueBehavior, value);
  }

  jumpToClip(clip: any): void {
    this.streamService.goToClipByIndexAndPause(this.stream, clip.position);
  }

  editPlaylist(): void {
    this.dialog.open(PlaylistEditorComponent, {data: {streamId: this.stream?.id, playlist: this.playlist}, autoFocus: false, disableClose: true});
  }

  openContextMenu($event: MouseEvent, contextMenu: ContextMenuComponent, item: any): void {
    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: contextMenu,
      event: $event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  /**
   * Actions
   */

  drop(event: CdkDragDrop<any[]>) {
    const previousIndex = this.clips.findIndex(row => row === event.item.data);
    moveItemInArray(this.clips, previousIndex, event.currentIndex);
    moveItemInArray(this.playlist.clips, previousIndex, event.currentIndex);
    this.clips = this.clips.slice();
    this.playlist.clips.forEach((clip, index) => clip.position = index + '');
    this.playlistService.updatePlaylist(this.stream.deckId, this.playlist);
  }

  /**
   * Drag and drop
   */


  appDndDrop(event: DraggableEvent): void {
    this.dropPlaceholder = false;
    if (event.dragData && event.dragData.type === 'clip' && event.dragData.data && !this.invalidDropFile) {
      this.playlistService.addFileToPlaylist(this.stream.deckId, [event.dragData.data], this.playlist);
    }
    this.invalidDropFile = false;
  }

  /**
   * Drag and drop
   */

  dndEnter(event: DragEvent | DraggableEvent): void {
    this.dropPlaceholder = true;
    this.invalidDropFile = true;
    const dndEvent = event as DraggableEvent;
    if (dndEvent?.dragData && dndEvent?.dragData?.type === 'clip' && dndEvent?.dragData?.data) {
      const clips = [dndEvent.dragData.data];
      this.invalidDropFile = !this.playlistService.validateClipsForPlaylist(clips, this.playlist);
    }
  }

  dndLeave(event: DragEvent | DraggableEvent): void {
    this.dropPlaceholder = false;
    this.invalidDropFile = false;
  }

  dndOver(event: DragEvent) {
    this.dropPlaceholder = true;
    event.stopPropagation();
    event.preventDefault();
  }

  uploadDropedFiles(event: DragEvent): void {
    this.dropPlaceholder = false;
    this.invalidDropFile = false;
    let filesPaths = this.fileManagerService.importDropedFiles(event);
    event.stopPropagation();
    event.preventDefault();
    if (!filesPaths?.length) {
      return;
    }

    this.fileManagerService.postIndexedFiles(this.stream.deckId, filesPaths)
      .pipe(
        switchMap(() => 
          this.fileManagerService.getIndexedFiles(this.stream.deckId, filesPaths)
            .pipe(
              map(clips => {
                if (!clips.length) {
                  throw new Error("Clips not found");
                }
                return clips;
              }),
              delayedRetry(100, 5),
            )
        ),
        filter(clips => {
          const valid = this.playlistService.validateClipsForPlaylist(clips, this.playlist);
          if (!valid) {
            this.notificationService.error(`fps, resolution and color space must match`, `Can't add file(s) to playlist`);
          }
          return valid;
        }),
        switchMap(clips => this.playlistService.addFileToPlaylist(this.stream.deckId, clips, this.playlist)),
        catchError(error => {
          this.notificationService.error(error?.error?.message, `Can't add file(s) to playlist`);
          return of(null);
        }),
      )
      .subscribe((clips) => console.log('[PlaylistFiles] Added files: ', clips));
  }

}
