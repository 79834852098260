import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Common modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// App Modules
import { LayoutModule } from './modules/layout/layout.module';
import { SettingsModule } from './modules/settings/settings.module';
import { NodeModule } from './modules/node/node.module';

// Components
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // App
    LayoutModule,
    SettingsModule,
    NodeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
