import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { of, Subject } from 'rxjs';
import { catchError, delay, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { NotificationService } from '@modules/elements/services/notification.service';

// Components
import { PlaylistEditorComponent } from '../playlist-editor/playlist-editor.component';
import { PlaylistNameDialogComponent } from '../playlist-name-dialog/playlist-name-dialog.component';

// Types
import { Playlist } from '@modules/playlist-editor/types/playlist';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Stream } from '@modules/stream/types/stream';
import { StreamStatus } from '@modules/stream/types/stream-status';
import { DraggableEvent } from '@modules/drag-n-drop/types/draggable-event';
import { delayedRetry } from '@modules/core/types/delayed-retry';
import { ChannelService } from '@modules/channel/services/channel.service';
import { Channel } from '@modules/channel/types/channel';

@Component({
  selector: 'app-playlist-manager',
  templateUrl: './playlist-manager.component.html',
  styleUrls: ['./playlist-manager.component.less']
})
export class PlaylistManagerComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() stream: Stream;

  // Public
  public playlists: Playlist[] = [];
  public status: StreamStatus;
  public channel: Channel;
  public selection = new SelectionModel<Playlist>(false, []);
  public dndOverPlaylist: string;
  public invalidDropFile = false;
  public isPlaylistOpenFunc = (item: Playlist): boolean => {
    return !(this.status?.playback?.openPlaylistName === item.name && this.status?.playback?.isLoaded);
  };

  // Private
  private alive = new Subject<void>();
  private openedPlaylistEditDialog = false;

  /**
   * Constructor
   */

  constructor(
    private playlistService: PlaylistService,
    private streamService: StreamService,
    private fileManagerService: FileManagerService,
    private notificationService: NotificationService,
    private channelService: ChannelService,
    private dialog: MatDialog,
  ) { }

  /**
   * Component lifecycle
   */
 
  ngOnInit(): void {
    this.streamService.getStatus(this.stream)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(status => this.status = status);

    // Get Playlist
    this.playlistService.playlistsChanged(this.stream.deckId)
      .pipe(
        delay(1500), // HOTFIX: Server return old data when fire event 'playlistsChanged' and ask immediately
        startWith(''),
        switchMap(() => this.playlistService.getPlaylists(this.stream.deckId)),
        takeUntil(this.alive)
      )
      .subscribe(playlists => {
        console.log('[PLAYLISTS] ', playlists);
        this.playlists = playlists.reverse();
      });

    // Channel
    this.channelService.findChannelFromStream(this.stream.id)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(channel => this.channel = channel);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  playPlaylist(playlist: Playlist): void {
    this.streamService.ejectFile(this.stream)
      .subscribe(() => {
        this.playlistService.playPlaylist(this.stream, playlist, false);
        if (!!this.channel?.layoutSettings?.autoPlaybackLoadingFile) {
          setTimeout(() => this.streamService.play(this.stream), 1000);
        }
      });
  }

  selectPlaylist(event: MouseEvent, playlist: Playlist): void {
    if (!event?.shiftKey) {
      this.selection.clear();
    }
    this.selection.toggle(playlist);
  }

  editPlaylist(event: MouseEvent, playlist: Playlist): void {
    if (this.openedPlaylistEditDialog) {
      return;
    }
    event?.preventDefault();
    event?.stopPropagation();
    this.openedPlaylistEditDialog = true;
    this.dialog.open(PlaylistEditorComponent, {data: {streamId: this.stream?.id, playlist: playlist}, autoFocus: false, disableClose: true, hasBackdrop: false})
      .afterClosed()
      .subscribe(result => this.openedPlaylistEditDialog = false);
  }

  deletePlaylist(playlist: Playlist): void {
    this.playlistService.deletePlaylist(this.stream.deckId, playlist);
  }

  renamePlaylist(playlist: Playlist): void {
    const filePath = playlist.drive + playlist.path;
    this.dialog.open(PlaylistNameDialogComponent, {data: {fileName: playlist.name, stream: this.stream, filePath}, disableClose: true, autoFocus: true})
      .afterClosed()
      .subscribe(result => {
        if (result?.fileName && (playlist.name !== result?.fileName || filePath !== result?.filePath)) {
          const lastFilePathChar = result?.filePath.substring(result?.filePath.length - 1);
          const oldFilepath = playlist.drive + playlist.path + playlist.name + '.cpl';
          const newFilepath = result?.filePath + (lastFilePathChar === '\\' ? '' : '\\') + result?.fileName + '.cpl';
          this.playlistService.renamePlaylist(this.stream.deckId, oldFilepath, newFilepath);
        }
      });
  }

  /**
   * Drag and drop
   */

  dndEnter(event: DraggableEvent, playlist: Playlist): void {
    this.dndOverPlaylist = playlist.name;
    this.invalidDropFile = false;
    if (event?.dragData && event?.dragData?.type === 'clip' && event?.dragData?.data) {
      const clips = [event.dragData.data];
      this.invalidDropFile = !this.playlistService.validateClipsForPlaylist(clips, playlist);
    }
  }

  dndLeave(event: DraggableEvent, playlist: Playlist): void {
    if (this.dndOverPlaylist === playlist.name) {
      this.dndOverPlaylist = null;
      this.invalidDropFile = false;
    }
  }

  dndDragOver(event: DraggableEvent, playlist: Playlist): void {
  }

  dndDrop(event: DraggableEvent, playlist: Playlist): void {
    if (this.dndOverPlaylist === playlist.name) {
      this.dndOverPlaylist = null;
      this.invalidDropFile = false;
    }
    if (event.dragData && event.dragData.type === 'clip' && event.dragData.data && !this.invalidDropFile) {
      this.playlistService.addFileToPlaylist(this.stream.deckId, [event.dragData.data], playlist);
    }
  }

  /**
   * System files Drag and drop
   */

  systemDndEnter(event: DragEvent, playlist: Playlist): void {
    this.dndOverPlaylist = playlist.name;
  }

  systemDndLeave(event: DragEvent, playlist: Playlist): void {
    if (this.dndOverPlaylist === playlist.name) {
      this.dndOverPlaylist = null;
    }
  }

  systemDndOver(event: DragEvent, playlist: Playlist) {
    this.dndOverPlaylist = playlist.name;
    event.stopPropagation();
    event.preventDefault();
  }

  systemUploadDropedFiles(event: DragEvent, playlist: Playlist): void {
    this.dndOverPlaylist = null;
    let filesPaths = this.fileManagerService.importDropedFiles(event);
    event.stopPropagation();
    event.preventDefault();
    if (!filesPaths?.length) {
      return;
    }

    this.fileManagerService.postIndexedFiles(this.stream.deckId, filesPaths)
      .pipe(
        switchMap(() => 
          this.fileManagerService.getIndexedFiles(this.stream.deckId, filesPaths)
            .pipe(
              map(clips => {
                if (!clips.length) {
                  throw new Error("Clips not found");
                }
                return clips;
              }),
              delayedRetry(100, 5),
            )
        ),
        filter(clips => {
          const valid = this.playlistService.validateClipsForPlaylist(clips, playlist);
          if (!valid) {
            this.notificationService.error(`fps, resolution and color space must match`, `Can't add file(s) to playlist`);
          }
          return valid;
        }),
        switchMap(clips => this.playlistService.addFileToPlaylist(this.stream.deckId, clips, playlist)),
        catchError(error => {
          this.notificationService.error(error?.error?.message, `Can't add file(s) to playlist`);
          return of(null);
        }),
      )
      .subscribe((clips) => console.log('[PlaylistFiles] Added files: ', clips));
  }

}
