<input
  #input
  class="input input-settings"
  [class.error]="error"
  [type]="type"
  [placeholder]="placeholder"
  [(ngModel)]="inputValue"
  (ngModelChange)="updateInputValue($event)"
  (focus)="startEdit()"
  (blur)="endEdit()"
  (keydown.enter)="blur()"
  (keydown.escape)="blur()"
>
<!-- Error -->
<div class="error-message" *ngIf="error">{{ error.message }}</div>
