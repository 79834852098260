<div class="preset-settings-input-content">
  <div class="inputs-options" *ngIf="settings">

    <!-- Disabled Status -->
    <div class="settings-status-text" *ngIf="recording">Settings unavailable during recording</div>
    <div class="settings-status-text" *ngIf="!deckOnline">Settings are not available if there is no connection</div>
    <div class="settings-status-text" *ngIf="preset && !preset?.isValid()">
      <mat-icon svgIcon="warning" class="icon error"></mat-icon>
      Selected preset is from a prior version: please delete and create a new one.
    </div>

    <!-- 1 -->
    <div class="row">

      <!-- Input type -->
      <app-preset-settings-option title="Input type" key="input.activeVideoInput" [options]="filtredSettingsOptions.inputTypes" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Input source -->
      <!-- For SDI only -->
      <app-preset-settings-option *ngIf="settings?.input?.activeVideoInput === 'sdi'" title="Input source" key="inputSources" [selected]="inputSources[0]" [options]="inputSources" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || !stream" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Stream source -->
      <!-- For NDI only -->
      <app-preset-settings-option *ngIf="settings?.input?.activeVideoInput === 'ndi'" title="Stream source" key="input.ndiStreamSrc" [options]="streamSources" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || !stream" (selectedChange)="updateSelect($event.key, $event.value)" placeholder="None"></app-preset-settings-option>

      <!-- Stream source -->
      <!-- For NDI only -->
      <div *ngIf="settings?.input?.activeVideoInput === 'ndi'" class="settings-option" [class.disabled]="disabled || !stream">
        <p class="preset-settings-title-big">NDI Source IP</p>
        <div class="multi-tile">
          <div class="button full-tile-select" (click)="openNdiIpsSettings()">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>

      <!-- SRT Port -->
      <!-- For SRT Listener only -->
      <div class="settings-option" *ngIf="settings?.input?.activeVideoInput === 'srt_listener'" [class.disabled]="disabled || !stream">
        <div class="preset-settings-title-big">SRT Port</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" type="number" [(value)]="settings?.channels[deckChannel].srtPort" (valueChange)="updateSettings()"></app-input-settings>
        </div>
      </div>

      <!-- SRT Address -->
      <!-- For SRT Caller only -->
      <div class="settings-option long" *ngIf="settings?.input?.activeVideoInput === 'srt_caller'" [class.disabled]="disabled || !stream">
        <div class="preset-settings-title-big">SRT Address</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" [(value)]="settings?.channels[deckChannel].srtReceiverUrl" (valueChange)="updateSettings()" placeholder="Example: srt://127.0.0.1:9000"></app-input-settings>
        </div>
      </div>

      <!-- SRT Passphrase -->
      <!-- For SRT only -->
      <div class="settings-option" *ngIf="settings?.input?.activeVideoInput === 'srt_listener' || settings?.input?.activeVideoInput === 'srt_caller'" [class.disabled]="disabled || !stream">
        <div class="preset-settings-title-big">SRT Passphrase</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" [(value)]="settings?.channels[deckChannel].srtPassphrase" (valueChange)="updateSettings()"></app-input-settings>
        </div>
      </div>

      <!-- Audio source -->
      <app-preset-settings-option title="Audio source" key="input.activeAudioInput" [options]="filtredSettingsOptions.audioSources" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Analog Audio source -->
      <app-preset-settings-option *ngIf="settings?.input?.activeAudioInput === 'analog'" title="Analog source" key="input.activeAnalogAudioSource" [options]="analogAudioSources" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Asio Audio source -->
      <app-preset-settings-option *ngIf="settings?.input?.activeAudioInput === 'asio'" title="ASIO source" key="input.asioAudioSource" [options]="asioAudioSources" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || !stream" (selectedChange)="updateSelect($event.key, $event.value)" placeholder="None"></app-preset-settings-option>

      <!-- Reference source -->
      <!-- Filter by inputs -> inputSync -->
      <app-preset-settings-option title="Reference source" [key]="'channels[' + deckChannel + '].activeSyncSource'" [options]="filtredSettingsOptions.referenceSources" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Auto-Detect -->
      <div class="settings-option" [class.disabled]="disabled || !stream">
        <div class="preset-settings-title-big"></div>
        <div class="multi-tile">
          <div class="button full-tile-select" (click)="autoDetectSignal()">Auto-Detect</div>
        </div>
      </div>

    </div>


    <!-- 2 -->
    <div class="row">

      <!-- Resolution -->
      <app-preset-settings-option title="Resolution" key="input.activeResolution" [options]="filtredSettingsOptions.resolutions" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Framerate -->
      <app-preset-settings-option title="Framerate" key="input.activeFrameRate" [options]="filtredSettingsOptions.framerates" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Color format -->
      <app-preset-settings-option title="Color format" key="input.activePixelFormat" [options]="filtredSettingsOptions.colorSpaces" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>
      
      <!-- 3G Level -->
      <app-preset-settings-option title="3G Level" [key]="'channels[' + deckChannel + '].isYuvLevelA'" [options]="filtredSettingsOptions.levelA" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || !(settings?.input?.activePixelFormat === 'yuv8' || settings?.input?.activePixelFormat === 'yuv10')" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Range -->
      <app-preset-settings-option
        *ngIf="!(settings?.input?.activePixelFormat === 'rgb10' || settings?.input?.activePixelFormat === 'rgb12')"
        title="Range" key="range" [selected]="{value: 0, title: 'N/A'}" [options]="filtredSettingsOptions.range" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || true" (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>
      <app-preset-settings-option
        *ngIf="settings?.input?.activePixelFormat === 'rgb10' || settings?.input?.activePixelFormat === 'rgb12'"
        title="Range" [key]="'channels[' + deckChannel + '].inputRange'" [options]="filtredSettingsOptions.rgbRange" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"
      ></app-preset-settings-option>

      <!-- HDR Settings -->
      <app-preset-settings-option title="HDR" key="input.hdr" [options]="filtredSettingsOptions.hdr" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || settings?.input?.activePixelFormat === 'yuv8'" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>
      
      <!-- HDR Type -->
      <div class="settings-option" [class.disabled]="disabled || !settings?.input.hdr" [class.inherit]="!(isDefaultSetting('input.hdrTransferPQ') && isDefaultSetting('input.hdrMatrixConst'))">
        <div class="preset-settings-title-big">HDR Type</div>
        <div class="multi-tile">
          <app-select
            class="full-tile-select"
            [highlighted]="!(isDefaultSetting('input.hdrTransferPQ') && isDefaultSetting('input.hdrMatrixConst'))"
            [options]="filtredSettingsOptions.hdrType"
            [selected]="{hdrTransferPQ: settings?.input.hdrTransferPQ, hdrMatrixConst: settings?.input.hdrMatrixConst}"
            (selectedChange)="updateHDRType($event.value)"
          ></app-select>
        </div>
      </div>

    </div>


    <!-- 3 -->
    <div class="row">

      <!-- Frame Drop Behaviour -->
      <app-preset-settings-option title="Frame Drop Behaviour" key="input.frameDropBehavior" [options]="filtredSettingsOptions.frameDropBehaviours" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>
      
      <!-- Input Loss Behaviour -->
      <app-preset-settings-option title="Input Loss Behaviour" key="input.signalLossBehavior" [options]="filtredSettingsOptions.inputLossBehaviours" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Video delay -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">Video delay</div>
        <div class="multi-tile">
          <app-select [options]="filtredSettingsOptions.videoDelays"
                      [selected]="{value: 0, title: 'Off'}"
                      (selectedChange)="updateSelect('videoDelays', $event.value)"
                      class="half-tile-select"></app-select>
          <div class="button tile-button">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>

      <!-- Audio delay -->
      <!-- channels -> audioRouting -> audioChannelDelays -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('input.audioDelays')">
        <div class="preset-settings-title-big">Audio delay</div>
        <div class="multi-tile">
          <app-select [options]="filtredSettingsOptions.audioDelaysLocked"
                      [selected]="settings?.audioDelaysLocked ? 1 : 0"
                      (selectedChange)="updateAudioDelayLocked($event.value)"
                      class="half-tile-select"></app-select>
          <div class="button tile-button" [class.inherit]="!isDefaultSetting('input.audioDelays')" [class.disabled]="disabled || settings?.audioDelaysLocked" (click)="openAudioDelays()">
            <mat-icon svgIcon="settings-sliders" class="icon"></mat-icon>
          </div>
        </div>
      </div>

      <!-- Aspect Ratio -->
      <app-preset-settings-option *ngIf="settings?.input.activeResolution === 'pal' || settings?.input.activeResolution === 'pal-p' || settings?.input.activeResolution === 'ntsc' || settings?.input.activeResolution === 'ntsc-p'" title="Aspect Ratio Override" key="input.aspectRatioString" [options]="filtredSettingsOptions.aspectRatioOverrides" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

    </div>

    <!-- 4 -->
    <div class="row">

      <!-- Input Name -->
      <div class="settings-option" [class.disabled]="disabled || !stream" *ngIf="stream">
        <div class="preset-settings-title-big">Input Name</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" [(value)]="settings?.channels[deckChannel].inputName" (valueChange)="updateSettings()"></app-input-settings>
        </div>
      </div>

      <!-- Input Label -->
      <div class="settings-option" [class.disabled]="disabled || !stream" *ngIf="stream">
        <div class="preset-settings-title-big">Input Label</div>
        <div class="multi-tile">
          <app-input-settings class="full-tile-select" [(value)]="settings?.channels[deckChannel].inputLabel" (valueChange)="updateSettings()"></app-input-settings>
        </div>
      </div>

    </div>

    <!-- 5 -->
    <div class="row" *ngIf="stream && settings?.input?.activeVideoInput === 'srt_listener' || settings?.input?.activeVideoInput === 'srt_caller'">

      <!-- Refresh Inputs -->
      <div class="settings-option auto-width" [class.disabled]="!deckOnline">
        <div class="preset-settings-title-big"></div>
        <div class="multi-tile">
          <div class="button full-tile-select" (click)="refreshInput()">Refresh Input</div>
        </div>
      </div>

    </div>

  </div>

</div>

<!-- Header -->
<div class="preset-settings-header" *ngIf="inputsOptions?.length && stream">
  <div class="label">Detected signals</div>
</div>

<!-- Stream list -->
<div class="signals-list" *ngIf="inputsOptions?.length && stream">
  <div *ngFor="let input of inputsOptions" class="signal-item">
    <div class="preset-settings-title-big">{{ input.name}}</div>
    <div class="signal-rect" [ngClass]="{'available': input.enabled, 'connected': input.enabled && !!input.connected, 'present': input.streaming && input.enabled}"></div>
  </div>
</div>
