import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exclude'
})
export class ExcludePipe implements PipeTransform {

  transform(array: any[] | string[], value: object | string, key?: string): any[] {
    return array.filter(
      (element: object | string) => element && ((key ? element[key] : element) !== value)
    );
  }

}
