import { UniqueID } from '@modules/elements/types/unique-id';

// Env
import { environment } from '@environment';

export class Deck {
  id: string;
  address: string;
  name: string;
  port: number = environment.apiPort;
  credentials?: {
    username: string;
    password: string;
  };
  ssl: boolean = false;

  constructor(address?: string, name?: string, ssl: boolean = false) {
    this.id = UniqueID.generate();
    this.address = address;
    this.name = name;
    this.ssl = ssl;
  }

  static getAddress(deck: Deck): string {
    return (deck.ssl ? 'https' : 'http') + '://' + deck.address + (deck.port ? (':' + deck.port) : '');
  }

  static getWebsocketAddress(deck: Deck): string {
    return (deck.ssl ? 'wss' : 'ws') + '://' + deck.address + (deck.port ? (':' + deck.port) : '');
  }

}
