<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">{{ playlist?.name || 'Playlist Editor' }}</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="playlist-editor-content">

    <div class="playlist-layout">

      <!-- Player -->
      <app-playlist-player class="playlist-player" [stream]="stream" [playlist]="playlist"></app-playlist-player>

      <!-- Files -->
      <app-playlist-files class="playlist-files" [stream]="stream" [playlist]="playlist" [isModal]="true"></app-playlist-files>

    </div>

    <!-- Timeline -->
    <!-- <app-playlist-timeline></app-playlist-timeline> -->

  </div>
</div>
