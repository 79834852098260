import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { DeckService } from '@modules/deck/services/deck.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { NetworkService } from '@modules/core/services/network.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Deck } from '@modules/deck/types/deck';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DeckSocket } from '@modules/deck/types/deck-socket';
import { DeckInfo, DeckStatus } from '@modules/deck/types/deck-status';
import { DeckChannel } from '@modules/deck/types/deck-channel';
import { Stream } from '@modules/stream/types/stream';

// Env
import { environment } from '@environment';

@Component({
  selector: 'app-add-deck',
  templateUrl: './add-deck.component.html',
  styleUrls: ['./add-deck.component.less']
})
export class AddDeckComponent implements OnInit, OnDestroy {

  // Public
  public deck = new Deck();
  public deckInfo: DeckInfo;
  public error: Error;
  public defaultPort = true;
  public onlineStatus: boolean;
  public status: DeckStatus;
  public channels: DeckChannel[] = [];
  public addAllInputs = false;

  // Private
  private alive = new Subject<void>();
  private checkConnection = new Subject<Deck>();
  private checkStatus = new Subject<void>();
  private deckSocket: DeckSocket;
  private saving = false;
  private decks: Deck[] = [];

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<AddDeckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {addAllInputs: boolean},
    private deckService: DeckService,
    private streamService: StreamService,
    private networkService: NetworkService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Set Properties
    if (this.data && 'addAllInputs' in this.data) {
      this.addAllInputs = this.data.addAllInputs;
    }
    // Update data
    this.checkConnection
      .pipe(
        debounceTime(1000),
        map(deck => {
          if (this.deckSocket) {
            this.deckSocket.disconnect();
            this.deckSocket = null;
            this.deckInfo = null;
            this.checkStatus.next();
          }
          if (this.deck?.address?.length) {
            this.deckSocket = new DeckSocket(deck);
            this.deckSocket.on<DeckStatus>('status')
              .pipe(takeUntil(this.checkStatus))
              .subscribe(status => {
                this.status = status;
                if (status?.deckInfo) {
                  this.deckInfo = status.deckInfo; 
                  this.deck.name = this.deck.name?.length ? this.deck.name : this.deckInfo.name;
                  this.validate();
                }
              });
            this.deckSocket.connected()
              .pipe(
                filter(isOnline => isOnline),
                switchMap(() => this.networkService.getChannels(Deck.getAddress(deck))),
                takeUntil(this.checkStatus)
              )
              .subscribe(channels => this.channels = channels);
          }
          return this.deckSocket;
        }),
        switchMap(socket => {
          if (!socket) {
            return of(false);
          }
          return socket.connected();
        }),
        takeUntil(this.alive),
      )
      .subscribe(isOnline => {
        this.onlineStatus = isOnline;
        if (!isOnline && !this.saving && (this.deck.name === this.deckInfo?.name)) {
          this.deck.name = null;
        }
      });

      // Get Decks
      this.deckService.getDecks()
        .pipe(takeUntil(this.alive))
        .subscribe(decks => {
          this.decks = decks || [];
          this.validate();
        });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
    this.checkStatus.next();
    this.checkStatus.complete();
  }

  /**
   * Error
   */

  validate(): void {
    const sameDeck = this.decks.find(deck => deck?.id === this.deckInfo?.id || (deck?.address === this.deck?.address && deck?.port === this.deck?.port));
    if (sameDeck) {
      this.error = new Error(`Already exist "${sameDeck.name}"`);
      return;
    }
    this.error = null;
  }

  /**
   * Actions
   */

  updateName(event: string): void {
    this.deck.name = event;
  }

  updateAddress(event: string): void {
    this.deck.address = event;
    this.checkConnection.next(this.deck);
  }

  updateDefaultPort(value: MatCheckboxChange): void {
    this.defaultPort = value.checked;
    this.deck.port = this.defaultPort ? environment.apiPort + (this.deck.ssl ? -1 : 0) : null;
    this.checkConnection.next(this.deck);
  }

  updatePort(event: string): void {
    this.deck.port = +event;
    this.checkConnection.next(this.deck);
  }

  updateSSL(value: MatCheckboxChange): void {
    this.deck.ssl = value.checked;
    if (this.defaultPort) {
      this.deck.port = environment.apiPort + (this.deck.ssl ? -1 : 0);
    }
    this.checkConnection.next(this.deck);
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.saving = true;
    this.alive.next();
    if (this.deckInfo?.id) {
      this.deck.id = this.deckInfo.id;
    }
    if (this.deckInfo?.name.length && !this.deck.name.length) {
      this.deck.name = this.deckInfo.name;
    }
    this.deckService.addDeck(this.deck);
    const streams = [];
    if (this.addAllInputs) {
      for (const channel of this.channels) {
        const stream = new Stream(this.deck.id);
        stream.name = this.deck.name + ' - ' + channel.name;
        stream.deckChannel = channel.index;
        stream.deckChannelName = channel.name;
        streams.push(stream);
        this.streamService.addStream(stream);
      }
    }
    this.dialogRef.close({deck: this.deck, streams});
  }

  updatedAddress(): void {
    this.checkConnection.next(this.deck);
  }

}
