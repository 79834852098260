<div class="stream-content"
     (mouseenter)="mouseEnter()"
     (mouseleave)="mouseLeave()"
     appDroppable
     (appDroppableEnter)="dndEnter($event)"
     (appDroppableLeave)="dndLeave($event)"
     (appDroppableOver)="dndDragOver($event)"
     (appDroppableDrop)="dndDrop($event)">

  <!-- Stream -->
  <div class="stream" [class.hide]="!stream" appRs422Scroll [appRs422ScrollActions]="rs422ScrollActions">
    <!-- Playback -->
    <app-stream
      #streamComponent
      [streamId]="stream?.id"
      [audioPair]="stream?.audioPair"
      [volume]="volume"
      (timecodeChange)="setTimecode($event)"
      (audioLevels)="setAudioLevel($event)"
      (appClick)="hideStopOverlay(stream)"
    ></app-stream>

    <!-- Record boarder -->
    <div *ngIf="status?.record?.record || status?.record?.isWaitingForRecord" class="record-border" [class.selected]="selected" [class.blink]="status?.record?.pauseRecord || status?.record?.isWaitingForRecord"></div>

    <!-- Select boarder -->
    <div *ngIf="selected" class="select-border"></div>

    <!-- Controls -->
    <div class="controls">
      <!-- Channel Info -->
      <div #channelInfo *ngIf="(isMouseOver && channel?.layoutSettings?.timecode !== 'never') || (layout.meters && channel?.layoutSettings?.timecode === 'always')" class="channel-info" [class.record]="status?.record?.record" [class.disabled]="disabled">
        <div class="info">
          <div class="name">
            <app-online-status class="online-status" [value]="onlineStatus"></app-online-status>
            {{ deck?.name }} - {{ stream?.deckChannelName }}
          </div>
          <div class="schedule">
            <mat-icon svgIcon="clock" class="icon"></mat-icon>
            <div *ngIf="!schedule">No Scheduled Records</div>
            <div *ngIf="schedule">Scheduled at {{ (schedule.interval === 'once' ? schedule.startDate : (schedule.rrule | rrule: 'date')) | date: 'mediumDate' }}</div>
          </div>
        </div>
        <!-- Timecodes -->
        <app-channel-timecode class="timecode" [stream]="stream" [status]="status" [clip]="clip" (jump)="jump($event)" [record]="status?.record?.record"></app-channel-timecode>
        <div class="timecode-bottom" #timecodeElapsed>--:--:--</div>
        <div class="timecode-type" *ngIf="settings && stream && status?.playback?.isUnloaded">
          <div class="timecode-type-value">{{ settings?.tcSource }}</div>
        </div>
        <!-- Timecodes Elapsed/Remain -->
        <div class="timecode-playback left" *ngIf="status?.playback?.isPlaylistOpenFile && playlist && clip">
          <div class="title">Elapsed</div>
          <div class="timecode-value" #elapsedTimecode>00:00:00:00</div>
        </div>
        <div class="timecode-playback right" *ngIf="status?.playback?.isPlaylistOpenFile && playlist && clip">
          <div class="title">Remain</div>
          <div class="timecode-value" #remainTimecode>00:00:00:00</div>
        </div>
        <!-- Address -->
        <div class="address">
          {{ deck?.address }}
          <!-- Record Info -->
          <div class="resolution" *ngIf="settings?.input && !clip">
            {{ getSettingsTitle(settings?.input.activeResolution, 'resolutions') }} / 
            {{ getSettingsTitle(settings?.input.activeFrameRate, 'framerates') }} / 
            {{ getSettingsTitle(settings?.input.activePixelFormat, 'colorSpaces') }}
          </div>
          <!-- Clip Info -->
          <div class="resolution" *ngIf="settings?.input && clip">
            {{ clip?.resolution }} / 
            {{ clip?.fps }} / 
            {{ clip?.pixelFormat }}
          </div>
          <div class="only-tag" *ngIf="status && !!status?.record && !status?.playback">INPUT ONLY</div>
          <div class="only-tag" *ngIf="status && !status?.record && !!status?.playback">OUTPUT ONLY</div>
          <div class="only-tag" *ngIf="settings?.editShareSettings?.enabled && settings?.editShareSettings?.multicamEnabled">MULTICAM</div>
        </div>
        <div class="schedule-time" *ngIf="!schedule">Will record at: --:--:--</div>
        <div class="schedule-time" *ngIf="schedule">Will record at: {{ (schedule.interval === 'once' ? schedule.startDate : (schedule.rrule | rrule: 'date')) | date: 'H:mm' }}</div>
      </div>

      <!-- Status Info -->
      <div class="input-status" *ngIf="project?.debug">
        <div class="channel-info-status">
          <div class="title">Info</div>
          <div class="row"><b>Channel ID: </b> <span class="text-select">{{ channel?.id }}</span></div>
          <div class="row"><b>Stream ID: </b> <span class="text-select">{{ stream?.id }}</span></div>
          <div class="row"><b>Preset ID: </b> <span class="text-select">{{ preset?.id }}</span></div>
          <div class="row"><b>Deck ID: </b> <span class="text-select">{{ deck?.id }}</span></div>
        </div>
        <div class="info-block">
          <!-- Video -->
          <div class="video-preview">
            <div class="title">
              <app-online-status class="online-status" [value]="!!streamComponent.preview?.preview?.player?.ws"></app-online-status>
              <div>Video</div>
            </div>
            <div class="row"><b>Created:</b> {{ streamComponent.preview?.preview?.player?.info?.videoCreated | date:'hh:mm:ss.SSS' }} ({{ streamComponent.preview?.preview?.player?.info?.videoCreated | dateAgo }})</div>
            <div class="row"><b>Closed:</b> {{ streamComponent.preview?.preview?.player?.info?.videoClosed | date:'hh:mm:ss.SSS' }}</div>
          </div>
          <!-- Audio -->
          <div class="audio-preview">
            <div class="title">
              <app-online-status class="online-status" [value]="!!streamComponent.preview?.preview?.player?.audioWs"></app-online-status>
              <div>Audio</div>
            </div>
            <div class="row"><b>Created:</b> {{ streamComponent.preview?.preview?.player?.info?.audioCreated | date:'hh:mm:ss.SSS' }} ({{ streamComponent.preview?.preview?.player?.info?.audioCreated | dateAgo }})</div>
            <div class="row"><b>Closed:</b> {{ streamComponent.preview?.preview?.player?.info?.audioClosed | date:'hh:mm:ss.SSS' }} (connection count: {{ streamComponent.preview?.preview?.player?.info?.audioReconnectionCount }})</div>
          </div>
        </div>
        
        <div class="status-info">
          <div class="status-time">{{ deckStatus?.deckInfo?.timestemp }}</div>
          <pre class="status-record">{{ status?.record | json }}{{ status?.info | json }}</pre>
          <pre class="status-record">{{ status?.playback | json }}</pre>
        </div>
      </div>

      <!-- Input name -->
      <div
        *ngIf="(isMouseOver && channel?.layoutSettings?.input !== 'never') || (layout.meters && channel?.layoutSettings?.input === 'always')"
        class="input-label left"
        [class.record]="status?.record?.record"
        [class.disabled]="disabled"
      >{{ settings?.channels[stream.deckChannel].inputName || 'none'}}</div>

      <!-- Preset -->
      <div
        *ngIf="(isMouseOver && channel?.layoutSettings?.preset !== 'never') || (layout.meters && channel?.layoutSettings?.preset === 'always')"
        class="input-label right"
        [class.record]="status?.record?.record"
        [class.error]="stream?.presetId && !preset || !preset?.isValid()"
        [class.disabled]="disabled"
        matTooltip="Selected preset is from a prior version: please delete and create a new one."
        [matTooltipDisabled]="preset?.isValid()"
      >
        <mat-icon *ngIf="(stream?.presetId && !preset) || (preset && !preset?.isValid())" svgIcon="warning" class="icon"></mat-icon>
        {{ preset?.name || (stream?.presetId && 'Not Found') }}
      </div>

      <!-- Encoder Record Queue -->
      <app-encoder-record-queue
        class="encoder-record-queue"
        [style.bottom.px]="streamSettingsBottomOffset + 8"
        *ngIf="status?.record?.record && ((isMouseOver && channel?.layoutSettings?.encoderRecordQueue !== 'never') || (layout.meters && channel?.layoutSettings?.encoderRecordQueue === 'always')) && stream && online"
        [stream]="stream"
        [settings]="settings"
      ></app-encoder-record-queue>

      <!-- Settings -->
      <div *ngIf="isMouseOver" class="stream-settings">
        <mat-icon [svgIcon]="isFullscreen ? 'collapse' : 'fullscreen'" class="action-icon" [class.disabled]="disabled" (click)="toggleFullscreen()"></mat-icon>
        <mat-icon *ngIf="status?.playback" svgIcon="video" class="action-icon" [class.disabled]="disabled || !online" (click)="openFileManager($event)"></mat-icon>
        <mat-icon svgIcon="switch-button" class="action-icon" [class.disabled]="disabled" (click)="openLayoutSettings($event)"></mat-icon>
        <!-- <mat-icon svgIcon="settings" class="action-icon"></mat-icon> -->
        <!-- <mat-icon svgIcon="unlock" class="action-icon" [class.disabled]="disabled || !online"></mat-icon> -->
        <mat-icon svgIcon="settings-sliders" class="action-icon" [class.disabled]="!settings || disabled" (click)="openSettings($event)"></mat-icon>
        <mat-icon svgIcon="trash" class="action-icon" [class.disabled]="!allowedRemove || disabled" (click)="removeStream($event)"></mat-icon>
      </div>

      <!-- Record -->
      <div class="record-actions" [class.disabled]="!allowedControlRecord || disabled" *ngIf="((isMouseOver && channel?.layoutSettings?.recordButtons !== 'never') || (layout.meters && channel?.layoutSettings?.recordButtons === 'always')) && stream && (status?.playback?.isUnloaded || !status?.playback) && !status?.info.isPlaybackMode && online && !rs422 && status?.record && !settings?.output?.srtPlayoutEnable">
        <div class="button-action record" *ngIf="status?.record?.record && !status?.record?.pauseRecord" (click)="pauseRecord()">
          <mat-icon svgIcon="pause" class="icon"></mat-icon>
        </div>
        <div class="button-action record" *ngIf="status?.record?.record && status?.record?.pauseRecord" (click)="resumeRecord()">
          <mat-icon svgIcon="record" class="icon"></mat-icon>
        </div>
        <div class="button-action record" *ngIf="status?.record?.record" (click)="breakRecord()">
          <mat-icon svgIcon="break" class="icon"></mat-icon>
        </div>
        <div class="button-action record" *ngIf="!status?.record?.record" (click)="startRecord()">
          <mat-icon svgIcon="record" class="icon"></mat-icon>
        </div>
        <div class="button-action" *ngIf="status?.record?.record || status?.record?.isWaitingForRecord" (click)="stopRecord()">
          <mat-icon svgIcon="stop" class="icon"></mat-icon>
        </div>
      </div>

      <!-- Playback progress -->
      <app-player-progress-bar
        *ngIf="!playlist && clip && ((isMouseOver && channel?.layoutSettings?.playbackButtons !== 'never') || (layout.meters && channel?.layoutSettings?.playbackButtons === 'always')) && stream && status?.playback?.isLoaded && status?.info.isPlaybackMode && online && (!rs422 || (rs422 && !status?.info?.isRemotePlaybackMode)) && status?.playback"
        class="playback-progress"
        [stream]="stream"
        [clip]="clip"
        [showMarkers]="channel?.layoutSettings?.markerProgressBar === 'always'"
        (jump)="jump($event)"
      ></app-player-progress-bar>
      <!-- Playlist progress -->
      <app-playlist-progress-bar
        *ngIf="status?.playback?.isPlaylistOpenFile && playlist && ((isMouseOver && channel?.layoutSettings?.playbackButtons !== 'never') || (layout.meters && channel?.layoutSettings?.playbackButtons === 'always')) && stream && status?.playback?.isLoaded && status?.info.isPlaybackMode && online && (!rs422 || (rs422 && !status?.info?.isRemotePlaybackMode)) && status?.playback"
        class="playback-progress"
        [stream]="stream"
        [playlist]="playlist"
        (jump)="jump($event)"
      ></app-playlist-progress-bar>

      <!-- Playback actions -->
      <div class="playback-actions" [class.disabled]="!allowedControlRecord || disabled" *ngIf="((isMouseOver && channel?.layoutSettings?.playbackButtons !== 'never') || (layout.meters && channel?.layoutSettings?.playbackButtons === 'always')) && stream && status?.playback?.isLoaded && status?.info.isPlaybackMode && online && (!rs422 || (rs422 && !status?.info?.isRemotePlaybackMode)) && status?.playback">
        <div #cueToButton class="button-action player other" (click)="openTimecodePopover(cueToPopover)">
          Cue to
        </div>
        <div class="button-action player" *ngIf="status?.playback?.isPlaylistOpenFile && status?.playback?.isPaused" (click)="goToPrevAndPause()">
          <mat-icon svgIcon="playlist-prev-pause" class="icon next-clip"></mat-icon>
        </div>
        <div class="button-action player" *ngIf="status?.playback?.isPlaylistOpenFile && !status?.playback?.isPaused" (click)="goToPrevAndPlay()">
          <mat-icon svgIcon="playlist-prev-play" class="icon next-clip"></mat-icon>
        </div>
        <div class="button-action player" *ngIf="status?.playback?.openedClips?.length > 1" (click)="goToPrevClip()">
          <mat-icon svgIcon="forward-next" class="icon rotate next-clip"></mat-icon>
        </div>
        <div class="button-action player" (click)="goToStart()">
          <mat-icon svgIcon="forward-end" class="icon rotate"></mat-icon>
        </div>
        <div class="button-action player" [class.selected]="status?.playback?.isRewind" (click)="rewind()">
          <mat-icon svgIcon="forward" class="icon rotate"></mat-icon>
        </div>
        <div class="button-action player" [class.selected]="status?.playback?.isPlayingReverse" (click)="reverse()">
          <mat-icon svgIcon="play" class="icon rotate"></mat-icon>
        </div>
        <div class="button-action player" [class.selected]="status?.playback?.isPaused" (click)="pause()">
          <mat-icon svgIcon="pause" class="icon"></mat-icon>
        </div>
        <div class="button-action player" [class.selected]="status?.playback?.isPlayingForward" (click)="play()">
          <mat-icon svgIcon="play" class="icon"></mat-icon>
        </div>
        <div class="button-action player" [class.selected]="status?.playback?.isFastForward" (click)="fastForward()">
          <mat-icon svgIcon="forward" class="icon"></mat-icon>
        </div>
        <div class="button-action player" (click)="goToEnd()">
          <mat-icon svgIcon="forward-end" class="icon"></mat-icon>
        </div>
        <div class="button-action player" *ngIf="status?.playback?.openedClips?.length > 1" (click)="goToNextClip()">
          <mat-icon svgIcon="forward-next" class="icon next-clip"></mat-icon>
        </div>
        <div class="button-action player" *ngIf="status?.playback?.isPlaylistOpenFile && status?.playback?.isPaused" (click)="goToNextAndPause()">
          <mat-icon svgIcon="playlist-next-pause" class="icon next-clip"></mat-icon>
        </div>
        <div class="button-action player" *ngIf="status?.playback?.isPlaylistOpenFile && !status?.playback?.isPaused" (click)="goToNextAndPlay()">
          <mat-icon svgIcon="playlist-next-play" class="icon next-clip"></mat-icon>
        </div>
        <div class="button-action player other" [class.selected]="status?.playback?.loopPlayback !== 'off'" *ngIf="!!status?.playback?.loopPlayback" (click)="loopPlayback()">
          <mat-icon svgIcon="loop" class="icon"></mat-icon>
        </div>
      </div>

      <!-- Playback Countdown -->
      <div #playbackCountdown class="playback-countdown" *ngIf="((isMouseOver && channel?.layoutSettings?.playbackCountdown !== 'never') || (layout.meters && channel?.layoutSettings?.playbackCountdown === 'always')) && stream && status?.playback?.isLoaded && status?.info.isPlaybackMode && online && status?.playback && status?.playback?.isPlaylistOpenFile && playlist && clip">00:00</div>

      <!-- Play button -->
      <div class="button-player play" [style.bottom.px]="streamSettingsBottomOffset + 8" *ngIf="((isMouseOver && channel?.layoutSettings?.recordButtons !== 'never') || (layout.meters && channel?.layoutSettings?.recordButtons === 'always')) && stream && status?.playback?.isUnloaded && !status?.record?.record && status?.playback?.haveFileToOpen && online && (!rs422 || (rs422 && !status?.info?.isRemotePlaybackMode)) && status?.playback" (click)="openFile()">
        <mat-icon svgIcon="play" class="icon"></mat-icon>
      </div>
      <div
        class="button-player eject"
        [style.bottom.px]="streamSettingsBottomOffset + 8"
        [style.left.px]="50"
        [class.disabled]="disabled"
        *ngIf="isMouseOver && online && !noPreview && (status?.info.isPlaybackMode && !status?.playback?.isLoaded) && status?.playback"
        (click)="ejectFile()"
      >Eject</div>

      <!-- Playback File Name -->
      <div class="playback-filename" *ngIf="((isMouseOver && channel?.layoutSettings?.playbackFilename !== 'never') || (layout.meters && channel?.layoutSettings?.playbackFilename === 'always')) && stream && status?.playback?.isLoaded && online && !rs422">
        {{ status?.playback?.filename }}
      </div>

      <!-- Playback File Eject -->
      <div class="button-player eject" [class.disabled]="disabled" *ngIf="((isMouseOver && channel?.layoutSettings?.playbackButtons !== 'never') || (layout.meters && channel?.layoutSettings?.playbackButtons === 'always')) && stream && status?.playback?.isLoaded && online && (!rs422 || (rs422 && !status?.info?.isRemotePlaybackMode))" (click)="ejectFile()">Eject</div>

      <!-- Segment break message -->
      <div class="record-message" *ngIf="savingSegment">
        Saving segment and starting a new one
      </div>

      <!-- Record system message -->
      <div class="record-message" *ngIf="status?.record?.record && status?.record?.isStoppingRecording" (appClick)="hideStopOverlay(stream)">
        Stopping record and closing file
      </div>

      <!-- Marker message -->
      <div class="marker-message" *ngIf="marker?.comment?.length && online && ((isMouseOver && channel?.layoutSettings?.markerComment !== 'never') || (layout.meters && channel?.layoutSettings?.markerComment === 'always'))">
        <mat-icon svgIcon="logger" class="icon"></mat-icon>
        <div class="text">{{ marker?.comment }}</div>
      </div>

      <!-- Gang -->
      <div
        class="gang-label"
        [class.on]="channel?.streamSettings?.gang"
        *ngIf="(isMouseOver && channel?.layoutSettings?.timecode !== 'never') || (layout.meters && channel?.layoutSettings?.timecode === 'always')"
        (click)="toggleGang($event)"
        [matTooltip]="gangTooltip"
        matTooltipPosition="below"
        matTooltipShowDelay="1000"
        [matTooltipDisabled]="!channel?.streamSettings?.gang"
      >
        <span>Gang</span>
        <mat-icon #gangMenuButton svgIcon="dots-vertical" class="icon" (click)="openGangMenu($event)" [matMenuTriggerFor]="gangMenu"></mat-icon>
      </div>
      <!-- Menu -->
      <mat-menu #gangMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="gangAllChannels(true)">Gang all for Deck</button>
        <button mat-menu-item (click)="gangAllChannels(false)">Unselect all for deck</button>
      </mat-menu>

      <!-- Disk and file name -->
      <app-channel-disks
        *ngIf="((isMouseOver && channel?.layoutSettings?.disks !== 'never') || (layout.meters && channel?.layoutSettings?.disks === 'always')) && stream && status?.playback?.isUnloaded && online"
        class="disks"
        [class.disabled]="disabled"
        [style.width]="channelDisksWidth"
        [style.bottom.px]="8"
        [stream]="stream"
        [channel]="channel"
      ></app-channel-disks>

      <!-- RS422 -->
      <app-rs422-control
        *ngIf="((isMouseOver && channel?.layoutSettings?.rs422Buttons !== 'never') || (layout.meters && channel?.layoutSettings?.rs422Buttons === 'always')) && stream && online && rs422 && status?.info?.isRemotePlaybackMode"
        class="rs422"
        [style.width]="channelDisksWidth"
        [style.bottom.px]="streamSettingsBottomOffset + 8"
        [stream]="stream"
        [actions]="rs422ScrollActions"
        [stopPropagation]="multiSelected"
        [settings]="channel.rs422Settings"
        (settingsChange)="updateRS422Settings($event)"
      ></app-rs422-control>

      <!-- Errors -->
      <div #errorsIcon class="errors" *ngIf="errors.value.length" (click)="openErrorsDialog($event)">
        <mat-icon svgIcon="warning" class="icon"></mat-icon>
        <div class="title">{{ errors.value.length }}</div>
        <mat-icon #errorMenuButton svgIcon="dots-vertical" class="menu-icon" (click)="openErrorMenu($event)" [matMenuTriggerFor]="errorMenu"></mat-icon>
      </div>
      <div #errorMessage class="error-message" *ngIf="displayLastError" (click)="displayLastError = null">
        <div class="message">{{ displayLastError.date | date:'shortTime'}}  <b>{{ displayLastError.message }}</b></div>
      </div>
      <!-- Menu -->
      <mat-menu #errorMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="cleanErrors()">Clear errors</button>
        <button mat-menu-item (click)="cleanAllErrors()">Clear All errors</button>
      </mat-menu>

    </div>

    <!-- No preview -->
    <div *ngIf="online && noPreview" class="disconnect" [class.disabled]="disabled">
      <mat-icon svgIcon="image-slash" class="icon"></mat-icon>
      <div class="title">No preview</div>
    </div>

    <!-- No Input -->
    <div *ngIf="online && (!status?.info.isPlaybackMode && inputs && !inputs.streaming)" class="disconnect" [class.disabled]="disabled">
      <div class="title">No input</div>
    </div>

    <!-- No Selected File -->
    <div *ngIf="online && (status?.info.isPlaybackMode && (!status?.playback?.isLoaded || status?.playback?.noSelectedFile))" class="disconnect" [class.disabled]="disabled">
      <div class="title">No selected file</div>
    </div>

    <!-- No preview -->
    <div *ngIf="!online" class="disconnect" [class.disabled]="disabled">
      <div class="title" [class.disabled]="disabled">Disconnect</div>
      <mat-icon svgIcon="warning" class="icon" [class.disabled]="disabled"></mat-icon>
    </div>

    <!-- Audio levels -->
    <app-audio-levels
      *ngIf="online && ((isMouseOver && channel?.layoutSettings?.audioLevels !== 'never') || (layout.meters && channel?.layoutSettings?.audioLevels === 'always'))"
      class="audio-levels"
      [class.disabled]="disabled"
      [isPlayback]="status?.playback?.isLoaded"
      [stream]="stream"
      [settings]="settings"
      [selected]="stream?.audioPair"
      (selectedChange)="changeAudioPair($event)"
    ></app-audio-levels>

  </div>

  <!-- No connection -->
  <div *ngIf="!stream" class="disconnect">
    <div class="title" [class.disabled]="disabled">No input assigned</div>

    <!-- Select boarder -->
    <div *ngIf="selected" class="select-border"></div>
  </div>

  <!-- Drop area -->
  <div class="drop-area" *ngIf="dropArea.show">
    <div class="title" *ngIf="dropArea.type === 'stream'">Assign Input Source here</div>
    <div class="title" *ngIf="dropArea.type === 'preset'">Assign Preset here</div>
  </div>

</div>

<!-- Popover Cue to -->
<ng-template #cueToPopover>
  <app-timecode-input-popover [clip]="clip" [popoverClose]="popoverClose" (jump)="jump($event)"></app-timecode-input-popover>
</ng-template>
