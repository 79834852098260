<div
  class="record-control-content"
  [class.neutral]="state === 'neutral'"
  [class.record]="state === 'record'"
  [class.playback]="state === 'playback'"
  [class.ambiguous]="state === 'ambiguous'"
>
  <!-- Header -->
  <div class="list-header">
    <div class="title">Group Control</div>
    <div class="actions">
      <mat-icon [svgIcon]="(!allowedControlRecord || locked) ? 'lock' : 'unlock'" class="action-icon" matTooltip="Lock UI" matTooltipPosition="above" (click)="lock()"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content">
    <!-- Left buttons -->
    <div class="select-actions">
      <div class="button-select" (click)="selectAll()">Select All</div>
      <div class="button-select" (click)="clearAll()">Clear Selection</div>
    </div>

    <!-- Neutral -->
    <div class="neutral-actions" [class.disabled]="!allowedControlRecord || locked" *ngIf="state === 'neutral'">
      <div class="button-action record">
        <mat-icon svgIcon="record" class="icon" (click)="startRecord()"></mat-icon>
      </div>
      <div class="button-action stop" *ngIf="isWaitingForRecord">
        <mat-icon svgIcon="stop" class="icon" (click)="stopRecord()"></mat-icon>
      </div>
      <div class="button-action play" *ngIf="haveFileToOpen">
        <mat-icon svgIcon="play" class="icon" (click)="openFile()"></mat-icon>
      </div>
    </div>

    <!-- Record -->
    <div class="record-actions" [class.disabled]="!allowedControlRecord || locked" *ngIf="state === 'record'">
      <div class="button-action pause" [class.active]="groupAction.pauseRecord">
        <mat-icon svgIcon="pause" class="icon" (click)="pauseRecord()"></mat-icon>
      </div>
      <div class="button-action break" [class.active]="groupAction.breakRecord">
        <mat-icon svgIcon="break" class="icon" (click)="breakRecord()"></mat-icon>
      </div>
      <div class="button-action record" [class.active]="groupAction.resumeRecord">
        <mat-icon svgIcon="record" class="icon" (click)="resumeRecord()"></mat-icon>
      </div>
      <div class="button-action stop" [class.active]="groupAction.stopRecord">
        <mat-icon svgIcon="stop" class="icon" (click)="stopRecord()"></mat-icon>
      </div>
    </div>

    <!-- Playback buttons -->
    <div class="playback-actions" [class.disabled]="!allowedControlRecord || locked" *ngIf="state === 'playback'">
      <div class="button-action">
        <mat-icon svgIcon="forward-end" class="icon rotate" (click)="goToStart()"></mat-icon>
      </div>
      <div class="button-action">
        <mat-icon svgIcon="forward" class="icon rotate" (click)="rewind()"></mat-icon>
      </div>
      <div class="button-action">
        <mat-icon svgIcon="play" class="icon rotate" (click)="reverse()"></mat-icon>
      </div>
      <div class="button-action pause">
        <mat-icon svgIcon="pause" class="icon" (click)="pause()"></mat-icon>
      </div>
      <div class="button-action">
        <mat-icon svgIcon="play" class="icon" (click)="play()"></mat-icon>
      </div>
      <div class="button-action">
        <mat-icon svgIcon="forward" class="icon" (click)="fastForward()"></mat-icon>
      </div>
      <div class="button-action">
        <mat-icon svgIcon="forward-end" class="icon" (click)="goToEnd()"></mat-icon>
      </div>
    </div>

    <div class="ambiguous-actions" *ngIf="state === 'ambiguous'">
      Selected channels are in different states.<br />Group controls require that all channels within a group are in the same state, eg Play, Idle, or Recording
    </div>

    <!-- Right buttons -->
    <div class="select-actions">
      <!-- Eject -->
      <div class="playback-actions" [class.disabled]="!allowedControlRecord || locked" *ngIf="state === 'playback'">
        <div class="button-action" (click)="ejectFile()">Eject</div>
      </div>
    </div>

    <!-- Gang -->
    <div class="gang-label" *ngIf="sameDeck">Sync</div>

  </div>
</div>
