import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

// RxJS
import { map, takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual, has } from 'lodash-es';

// Services
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { UserService } from '@modules/users/services/user.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { NodeService } from '@modules/node/services/node.service';

// Components
import { PresetSettingsBaseComponent } from '../preset-settings-base/preset-settings-base.component';
import { AudioLevelsComponent } from '@modules/channel/components/audio-levels/audio-levels.component';

// Types
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Resolution } from '@modules/stream/types/resolution';
import { CharOutStatus } from '@modules/stream/types/charout-status';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-preset-settings-output-overlays',
  templateUrl: './preset-settings-output-overlays.component.html',
  styleUrls: ['./preset-settings-output-overlays.component.less']
})
export class PresetSettingsOutputOverlaysComponent extends PresetSettingsBaseComponent implements OnInit, OnChanges {

  // View Child
  @ViewChild('audioLevelsPrimary', { static: false }) audioLevelsPrimary: AudioLevelsComponent;
  @ViewChild('audioLevelsProxy', { static: false }) audioLevelsProxy: AudioLevelsComponent;

  // Public
  public editField: string;
  public deckChannel: number = 0;
  public filtredSettingsOptions: PresetSettingsOptions;
  public filename: string = '<File Name>';
  public resolution: Resolution;
  public status: CharOutStatus;

  /**
   * Constructor
   */

  constructor(
    protected presetSettingsService: PresetSettingsService,
    protected streamService: StreamService,
    userService: UserService,
    protected nodeService: NodeService,
  ) {
    super(presetSettingsService, userService);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    console.log('[CharOut] ', this.settings.channels[this.deckChannel].charOut);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if ('stream' in changes || 'settingsOptions' in changes || 'settings') {
      this.deckChannel = this.stream?.deckChannel ?? 0;
      this.filtredSettingsOptions = JSON.parse(JSON.stringify(this.settingsOptions));
      this.updateElementsSizes();
      this.updateSettingsOptions();
    }
  }

  /**
   * Methods
   */

  argbToRgba(color: string): string {
    return color.replace(/#(..)(......)/, '#$2$1');
  }

  rgbaToArgb(color: string): string {
    return color.replace(/#(......)/, '#ff$1');
  }

  getStyles(field: string): any {
    const aspectWidth = 640 / (this.resolution?.width || 1920);
    const aspectHeight = 360 / (this.resolution?.height || 1080);
    const overlay = this.settings.channels[this.deckChannel].charOut[field];
    let backgroundColor = this.argbToRgba(overlay.backColor);
    let textColor = this.argbToRgba(overlay.textColor);
    if (field === 'timecode' && this.settings.channels[this.deckChannel].charOut.borderStatus.enabled) {
      backgroundColor = this.argbToRgba(this.settings.channels[this.deckChannel].charOut.idleBorderColors.backColor)
      textColor = this.argbToRgba(this.settings.channels[this.deckChannel].charOut.idleBorderColors.textColor)
    }
    const result = {
      'background-color': backgroundColor,
      'color': textColor,
      'font-size.px': overlay.fontSize / 2,
      'opacity': (overlay.opacity / 100),
      'left.%': overlay.x * 100,
      'top.%': overlay.y * 100,
      'width.px': overlay.width * aspectWidth,
      'height.px': overlay.height * aspectHeight
    };
    return result;
  }

  getAudioLevelsStyles(field: string): any {
    const overlay = this.settings.channels[this.deckChannel].charOut[field];
    const result = {
      // 'background-color': this.argbToRgba(overlay.backColor),
      // 'color': this.argbToRgba(overlay.textColor),
      'opacity': (overlay.opacity / 100),
      'left.%': overlay.x * 100,
      'top.%': overlay.y * 100,
    };
    return result;
  }

  getTitle(value: string|number, key: string): string {
    return this.presetSettingsService.getTitle(value, key);
  }

  isDefaultSetting(key: string): boolean {
    return this.streamSettings ? !has(this.streamSettings, key) : true;
  }

  updateSettingsOptions(): void {
    // Resolution
    this.resolution = Resolution.getVideoResolution(this.settings.input.activeResolution);
    // TC Sources
    this.streamService.getInputs(this.stream)
      .pipe(
        map(inputs => {
          const inputType = this.settings?.input?.activeVideoInput;
          return inputs[inputType]?.tcSources;
        }),
        takeUntil(this.alive),
      )
      .subscribe(tcSources => this.filtredSettingsOptions.encoderTcSource = this.settingsOptions.encoderTcSource.filter(
        item => tcSources.includes(item.value))
      );
    // Filename
    this.streamService.getRecordingFilenames(this.stream)
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(filenames => {
        const filename = filenames && filenames.length ? (filenames[0]?.primary || '') : '';
        const name = this.nodeService.path.basename(filename);
        this.filename = name || '<File Name>';
      });
    // Enable status
    this.streamService.getCharOut(this.stream)
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(status => this.status = status);
    
    // Audio level
    // const defaultLevels = [{peak: 0, rm: 0}, {peak: 0, rm: 0}];
    // if (this.audioLevelsPrimary) {
    //   this.audioLevelsPrimary.setAudioLevel(defaultLevels);
    // }
    // if (this.audioLevelsProxy) {
    //   this.audioLevelsProxy.setAudioLevel(defaultLevels);
    // }
  }

  updateElementsSizes(): void {
    let needUpdate = false;
    const fields = ['codec', 'fileName', 'gps', 'quality', 'timecode', 'userText'];
    for (const field of fields) {
      const overlay = this.settings.channels[this.deckChannel].charOut[field];
      const minHeight = overlay.fontSize * 2;
      if (overlay.height < minHeight) {
        this.settings.channels[this.deckChannel].charOut[field].height = minHeight;
        needUpdate = true;
      }
      if (overlay.width === 0) {
        this.settings.channels[this.deckChannel].charOut[field].width = minHeight;
      }
      if (!this.stream && overlay.width <= minHeight) {
        if (field === 'timecode') {
          this.settings.channels[this.deckChannel].charOut[field].width = 1000;
        }
        if (field === 'codec') {
          this.settings.channels[this.deckChannel].charOut[field].width = 200;
        }
        if (field === 'quality') {
          this.settings.channels[this.deckChannel].charOut[field].width = 200;
        }
        if (field === 'fileName') {
          this.settings.channels[this.deckChannel].charOut[field].width = 800;
        }
      }
    }
    if (needUpdate) {
      this.updateSettings();
    }
  }

  /**
   * Action
   */

  charOutEditField(field: string): void {
    this.editField = field;
  }

  updateTimecodeSource(value: string): void {
    this.settings.channels[this.deckChannel].charOut.captureTcSource = value;
    this.updateSettings();
  }

  updatePosition(value: string): void {
    this.settings.channels[this.deckChannel].charOut[this.editField].position = value;
    this.updateSettings();
  }

  updateColor(color: string, field: string): void {
    this.settings.channels[this.deckChannel].charOut[this.editField][field] = color;
    this.updateSettings();
  }

  updateBorderColor(color: string, editField: string, field: string): void {
    this.settings.channels[this.deckChannel].charOut[editField][field] = color;
    this.updateSettings();
  }

  enableCharOutCapture(value: MatCheckboxChange): void {
    this.status.capture = value.checked;
    this.streamService.updateCharOut(this.stream, this.status);
  }

  enableCharOutPayback(value: MatCheckboxChange): void {
    this.status.playback = value.checked;
    this.streamService.updateCharOut(this.stream, this.status);
  }

  /**
   * DnD
   */

  dragEnded(event: CdkDragEnd, field: string): void {
    const source = event.source.element.nativeElement;
    const position = event.source.getFreeDragPosition();
    const top = (source.offsetTop + position.y) / 360;
    const left = (source.offsetLeft + position.x) / 640;
    this.settings.channels[this.deckChannel].charOut[field].x = left;
    this.settings.channels[this.deckChannel].charOut[field].y = top;
    event.source.reset();
    this.updateSettings();
  }

}
