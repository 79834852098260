import { Subject } from "rxjs";

export class RS422ScrollActions {
  // Actions
  shuttle: Subject<number>; // Speed -20..20 (when negative value – reverse payback)
  var: Subject<number>; // Speed -2.2..2.2 (when negative value – reverse payback)
  jog: Subject<number>; // Speed -20..20 (when negative value – reverse payback)
  // Mouse
  mouseStart: Subject<void>;
  mouseStop: Subject<void>;
  mouseMoveStop: Subject<void>;

  constructor() {
    this.shuttle = new Subject<number>();
    this.var = new Subject<number>();
    this.jog = new Subject<number>();
    this.mouseStart = new Subject<void>();
    this.mouseStop = new Subject<void>();
    this.mouseMoveStop = new Subject<void>();
  }
}
