<!-- Players -->
<div class="center-panel-content">

  <!-- Channels -->
  <app-channels-grid *ngIf="!layout.channelList" class="channels"></app-channels-grid>
  <app-channels-list *ngIf="layout.channelList" class="channels"></app-channels-list>

  <!-- Schedule -->
  <app-schedule-list class="schedule" *ngIf="layout.schedule"></app-schedule-list>

  <div class="input-controls" *ngIf="layout.inputGroups || layout.recordControl">
    <!-- Input Groups -->
    <app-channels-groups *ngIf="layout.inputGroups" class="control"></app-channels-groups>

    <!-- Group Control -->
    <app-group-control *ngIf="layout.recordControl" class="control"></app-group-control>

  </div>


</div>
