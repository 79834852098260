import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Services
import { UserService } from './services/user.service';

// Componetnts
import { UserLoginComponent } from './components/user-login/user-login.component';
import { UsersPermissionsComponent } from './components/users-permissions/users-permissions.component';
import { CheckPermissionPipe } from './pipes/check-permission.pipe';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,

     // Material
     MatIconModule,
     MatTooltipModule,
     MatDialogModule,
     MatCheckboxModule,
  ],
  declarations: [
    UserLoginComponent,
    UsersPermissionsComponent,
    CheckPermissionPipe,
  ],
  exports: [
    UserLoginComponent,
    UsersPermissionsComponent,
    CheckPermissionPipe,
  ],
  providers: [
    UserService,
  ],
})
export class UsersModule { }
