import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

// Services
import { DeckService } from '@modules/deck/services/deck.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Components
import { FileManagerComponent } from '@modules/file-manager/components/file-manager/file-manager.component';

// Types
import { Stream } from '@modules/stream/types/stream';
import { StreamSettings, StreamSettingsEncode } from '@modules/stream/types/stream-settings';
import { DeckDrive } from '@modules/deck/types/deck-status';
import { Option } from '@modules/elements/types/option';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-preset-record-encoder-settings',
  templateUrl: './preset-record-encoder-settings.component.html',
  styleUrls: ['./preset-record-encoder-settings.component.less']
})
export class PresetRecordEncoderSettingsComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() stream: Stream;
  @Input() settings: StreamSettings;
  @Input() encoderIndex = 0;

  // Public
  public encoder: StreamSettingsEncode;
  public drives: DeckDrive[];
  public primaryDeckDrive: DeckDrive;
  public secondaryDeckDrive: DeckDrive;
  public drivesOptions: Option<string>[] = [];

  // Private
  private alive = new Subject();
  private getDeckStatus = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private deckService: DeckService,
    private presetSettingsService: PresetSettingsService,
    private dialog: MatDialog,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('stream' in changes || 'encoderIndex' in changes) {
      this.getInfo();
    }
    if ('settings' in changes || 'encoderIndex' in changes) {
      this.encoder = this.settings?.encode[this.encoderIndex];
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  getInfo(): void {
    this.getDeckStatus.next();

    // Get Deck info
    const deck = this.deckService.getDeckSync(this.stream.deckId);
    this.deckService.getDrives(deck)
      .pipe(
        filter(drives => !!drives),
        map(drives => {
          this.drives = drives;
          const primatyPos = this.settings?.channels[this.stream?.deckChannel]?.driveAssignments[this.encoderIndex]?.activePrimaryDrivePos;
          const secondatyPos =
            this.settings?.channels[this.stream?.deckChannel]?.driveAssignments[this.encoderIndex]?.activeSecondaryDrivePos;
          this.primaryDeckDrive = this.drives.find(drive => drive.position === primatyPos);
          this.secondaryDeckDrive = this.drives.find(drive => drive.position === secondatyPos);
          const drivesOptions = drives.map(drive => new Option(drive.position, (drive.driveLetter.toUpperCase() + ': ' + drive.label)));
          drivesOptions.splice(0, 0, new Option('', 'None'));
          drivesOptions.splice(1, 0, new Option('pathOverride', 'Path override'));
          return drivesOptions;
        }),
        distinctUntilChanged(isEqual),
        takeUntil(this.getDeckStatus)
      )
      .subscribe(drivesOptions => this.drivesOptions = drivesOptions);
  }

  getEncodeTitle(encoderKey: string|number, optionsKey: string): string {
    const value = this.settings?.encode[this.encoderIndex][encoderKey];
    return this.presetSettingsService.getTitle(value, optionsKey);
   }

  getTitle(value: string|number, key: string): string {
    return this.presetSettingsService.getTitle(value, key);
  }

  getAudioChannelCount(): string {
    return String(this.settings?.channels[this.stream?.deckChannel]?.audioRouting[this.encoderIndex]?.audioRoutingData?.length) ?? 'N/A';
  }

  /**
   * Actions
   */

  selectPrimaryDrive(option: Option<string>): void {
    if (option.value === 'pathOverride') {
      if (this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].primaryPath.length) {
        this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overridePrimaryPath = true;
      } else {
        this.selectPrimatyPathOverride();
      }
    } else {
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overridePrimaryPath = false;
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activePrimaryDrivePos = option.value;
    }
    this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
  }

  selectSecondaryDrive(option: Option<string>): void {
    if (option.value === 'pathOverride') {
      if (this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].secondaryPath.length) {
        this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overrideSecondaryPath = true;
      } else {
        this.selectSecondaryPathOverride();
      }
    } else {
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overrideSecondaryPath = false;
      this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activeSecondaryDrivePos = option.value;
    }
    this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
  }

  selectPrimatyPathOverride(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true, path: this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].primaryPath}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overridePrimaryPath = true;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].primaryPath = folderPath;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activePrimaryOverrideDrivePos = this.presetSettingsService.getDriverPosition(folderPath, this.drives);
          this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
        }
      });
  }

  selectSecondaryPathOverride(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true, path: this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].secondaryPath}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].overrideSecondaryPath = true;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].secondaryPath = folderPath;
          this.settings.channels[this.stream.deckChannel].driveAssignments[this.encoderIndex].activeSecondaryOverrideDrivePos = this.presetSettingsService.getDriverPosition(folderPath, this.drives);
          this.presetSettingsService.updateStreamSettings(this.stream, this.settings);
        }
      });
  }

}
