import { Component, Inject, Input, OnInit } from '@angular/core';

// RxJS
import { BehaviorSubject } from 'rxjs';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChannelError } from '@modules/channel/types/channel-error';

@Component({
  selector: 'app-channel-errors-dialog',
  templateUrl: './channel-errors-dialog.component.html',
  styleUrls: ['./channel-errors-dialog.component.less']
})
export class ChannelErrorsDialogComponent implements OnInit {

  // Inputs
  @Input() errors: BehaviorSubject<ChannelError[]>;

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<ChannelErrorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      errors: BehaviorSubject<ChannelError[]>
    },
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.errors = this.data.errors;
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }

  clear(): void {
    this.errors.next([]);
  }

}
