<!-- Playback -->
<div
  class="playback"
>
<!-- [class.hide]="noPreview || (inputs && !inputs.streaming && !status?.playback?.isLoaded) || !online" -->
  <!-- Web Preview -->
  <div #webPreview class="preview video" width="640" height="360" workers="true" webgl="auto"></div>
</div>


<!-- No preview -->
<!-- <div *ngIf="online && noPreview" class="disconnect" [class.disabled]="disabled">
  <mat-icon svgIcon="image-slash" class="icon"></mat-icon>
  <div class="title">No preview</div>
</div> -->

<!-- No Input -->
<!-- <div *ngIf="online && (!status?.info.isPlaybackMode && inputs && !inputs.streaming)" class="disconnect" [class.disabled]="disabled">
  <div class="title">No input</div>
</div> -->

<!-- No Selected File -->
<!-- <div *ngIf="online && (status?.info.isPlaybackMode && !status?.playback?.isLoaded)" class="disconnect" [class.disabled]="disabled">
  <div class="title">No selected file</div>
</div> -->

<!-- No preview -->
<!-- <div *ngIf="!online" class="disconnect" [class.disabled]="disabled">
  <div class="title" [class.disabled]="disabled">Disconnect</div>
  <mat-icon svgIcon="warning" class="icon" [class.disabled]="disabled"></mat-icon>
</div> -->

<!-- No connection -->
<!-- <div *ngIf="!stream" class="disconnect">
  <div class="title" [class.disabled]="disabled">No input assigned</div>
</div> -->
