<div
  class="marker-progress-bar-item-content"
  popover
  [popoverContent]="markerPopover"
  popoverPlacement="top"
  popoverTrigger="hover"
  [popoverOffsetY]="0"
></div>

<!-- Marker Popover -->
<ng-template #markerPopover>
  <div class="marker-popover">
    <mat-icon svgIcon="logger" class="icon"></mat-icon>
    <div class="text" *ngIf="marker?.comment?.length">{{ marker?.comment }}</div>
    <div class="placeholder" *ngIf="!marker?.comment?.length">Empty</div>
  </div>
</ng-template>