<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">NDI Source IP</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="preset-settings-ndi-source-content">

    <!-- List -->
    <div class="ndi-ip-list">
      <!-- IPs -->
      <div class="ndi-ip" *ngFor="let ip of addresses; let index = index">
        <input class="input" placeholder="Enter IP Address..." [ngModel]="ip" (ngModelChange)="updateAddress($event, index)" (blur)="updatedAddress()">
        <div class="button button-icon" (click)="deleteAddress(index)">
          <mat-icon svgIcon="trash" class="icon"></mat-icon>
        </div>
      </div>
      <!-- New -->
      <div class="ndi-ip">
        <input class="input" placeholder="Enter IP Address..." [(ngModel)]="newAddress">
        <div class="button" [class.disabled]="!newAddress?.length" (click)="addAddress()">Add</div>
      </div>
    </div>

  </div>
</div>
