import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { PresetService } from '@modules/preset/services/preset.service';
import { UserService } from '@modules/users/services/user.service';
import { StateService } from '@modules/settings/services/state.service';
import { AlertService } from '@modules/elements/services/alert.service';

// Types
import { Layout } from '@modules/layout/types/layout';
import { UserList, UserListItem } from '@modules/preset/types/user-list';
import { Option } from '@modules/elements/types/option';

@Component({
  selector: 'app-global-tag-list',
  templateUrl: './global-tag-list.component.html',
  styleUrls: ['./global-tag-list.component.less']
})
export class GlobalTagListComponent implements OnInit, OnDestroy {

  // Public
  public lists: UserList[];
  public locked = false;
  public allowed: boolean;
  public layout: Layout;
  public userListCountOptions: Option<number>[] = Array.from(new Array(6), (item, index) => new Option(index + 1, index + 1 + ''));
  public popoverClose = new Subject<void>();

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private presetService: PresetService,
    private stateService: StateService,
    private alertService: AlertService,
    userService: UserService
  ) {
    userService
      .getCurrentPermission('editLists')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowed = allowed);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.stateService.getState()
      .pipe(
        map(state => state.globalList),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive)
      )
      .subscribe(lists => this.lists = lists);
    this.stateService.getState()
      .pipe(
        map(state => state?.layout),
        takeUntil(this.alive)
      )
      .subscribe(layout => {
        this.layout = layout;
        if (!this.layout.globalListCount) {
          this.layout.globalListCount = 4;
        }
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  updateUserListCount(option: Option<number>): void {
    this.layout.globalListCount = option.value;
    this.stateService.updateState({layout: this.layout});
    this.popoverClose.next();
  }

  lock(): void {
    this.locked = !this.locked;
  }

  selectTag(list: UserList, item: UserListItem): void {
    this.presetService.selectItemToGlobalList(item, list.id);
  }

  createTag(list: UserList, item: UserListItem): void {
    this.presetService.addItemToGlobalList(item, list.id);
  }

  updateTag(list: UserList, item: UserListItem): void {
    this.presetService.updateItemToGlobalList(item, list.id);
  }

  deleteTag(list: UserList, item: UserListItem): void {
    this.presetService.deleteItemFromGlobalList(item, list.id);
  }

  updateList(list: UserList): void {
    this.presetService.updateGlobalList(list);
  }

  exportList(list: UserList): void {
    this.presetService.exportUserList(list);
  }

  importList(event: {list: UserList, file}): void {
    const list = event.list;
    if (list.items.length > 0) {
      this.alertService.show(
        'Import Global list',
        'Import user list will override current list items',
        ['Cancel', 'Update'],
        0
      )
        .subscribe(response => {
          if (response === 1) {
            this.presetService.importGlobalList(list.id, event.file);
          }
        });
    } else {
      this.presetService.importGlobalList(list.id, event.file);
    }
  }

}
