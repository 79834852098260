import { Component, Input, OnInit, ElementRef, ViewChild, OnDestroy, SimpleChanges, OnChanges, DoCheck, TemplateRef } from '@angular/core';

// RxJS
import { BehaviorSubject, combineLatest, forkJoin, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil, tap, map, publishBehavior, retryWhen, delay, take, mergeMap, startWith } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { AlertService } from '@modules/elements/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { StateService } from '@modules/settings/services/state.service';
import { LayoutService } from '@modules/layout/services/layout.service';
import { UserService } from '@modules/users/services/user.service';
import { ScheduleService } from '@modules/schedule/services/schedule.service';
import { Rs422Service } from '@modules/rs422/services/rs422.service';
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';
import { ProjectService } from '@modules/project/services/project.service';

// Components
import { PresetSettingsComponent } from '@modules/preset/components/preset-settings/preset-settings.component';
import { AudioLevelsComponent } from '@modules/channel/components/audio-levels/audio-levels.component';
import { ChannelDisksComponent } from '../channel-disks/channel-disks.component';
import { ChannelLayoutSettingsComponent } from '../channel-layout-settings/channel-layout-settings.component';
import { ClipManagerComponent } from '@modules/file-manager/components/clip-manager/clip-manager.component';
import { ChannelErrorsDialogComponent } from '../channel-errors-dialog/channel-errors-dialog.component';
import { PlayerProgressBarComponent } from '@modules/elements/components/player-progress-bar/player-progress-bar.component';
import { TimecodeInputPopoverComponent } from '@modules/elements/components/timecode-input-popover/timecode-input-popover.component';
import { StreamComponent } from '@modules/stream/components/stream/stream.component';
import { ChannelTimecodeComponent } from '../channel-timecode/channel-timecode.component';
import { Rs422ControlComponent } from '@modules/rs422/components/rs422-control/rs422-control.component';
import { PlaylistProgressBarComponent } from '@modules/playlist-editor/components/playlist-progress-bar/playlist-progress-bar.component';

// Types
import { DraggableEvent } from '@modules/drag-n-drop/types/draggable-event';
import { Stream } from '@modules/stream/types/stream';
import { Channel } from '@modules/channel/types/channel';
import { Deck } from '@modules/deck/types/deck';
import { Preset } from '@modules/preset/types/preset';
import { StreamSettings } from '@modules/stream/types/stream-settings';
import { StreamStatus, StreamStatusPlaybackLoop } from '@modules/stream/types/stream-status';
import { StreamInputs } from '@modules/stream/types/stream-inputs';
import { Volume } from '@modules/project/types/volume';
import { Layout } from '@modules/layout/types/layout';
import { Schedule } from '@modules/schedule/types/schedule';
import { RS422ScrollActions } from '@modules/rs422/types/rs422-scroll-actions';
import { RS422Settings } from '@modules/rs422/types/rs422-settings';
import { ChannelError } from '@modules/channel/types/channel-error';
import { MatMenuTrigger } from '@angular/material/menu';
import { Clip } from '@modules/deck/types/clip';
import { StreamOnlineStatus } from '@modules/stream/types/stream-online-status';
import { Project } from '@modules/project/types/project';
import { DeckStatus } from '@modules/deck/types/deck-status';
import { Playlist, TimecodeNull } from '@modules/playlist-editor/types/playlist';
import { Marker } from '@modules/marker/types/marker';
import { Timecode } from '@modules/elements/types/timecode';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.less']
})
export class ChannelComponent implements OnInit , OnChanges, OnDestroy, DoCheck {

  // ViewChild
  @ViewChild(ChannelTimecodeComponent, { static: false }) timecodeRef: ChannelTimecodeComponent;
  @ViewChild('timecodeElapsed', { static: false }) timecodeElapsedRef: ElementRef;
  @ViewChild('elapsedTimecode', { static: false }) elapsedTimecodeRef: ElementRef;
  @ViewChild('remainTimecode', { static: false }) remainTimecodeRef: ElementRef;
  @ViewChild('playbackCountdown', { static: false }) playbackCountdownRef: ElementRef;
  @ViewChild(AudioLevelsComponent, { static: false }) audioLevels: AudioLevelsComponent;
  @ViewChild(ChannelDisksComponent, { static: false }) disksComponent: ChannelDisksComponent;
  @ViewChild('channelInfo', { static: false }) channelInfoRef: ElementRef;
  @ViewChild('errorsIcon', { static: false }) errorsIconRef: ElementRef;
  @ViewChild('errorMessage', { static: false }) errorMessageRef: ElementRef;
  @ViewChild('gangMenuButton', { read: MatMenuTrigger, static: false}) gangMenuTrigger: MatMenuTrigger;
  @ViewChild('errorMenuButton', { read: MatMenuTrigger, static: false}) errorMenuTrigger: MatMenuTrigger;
  @ViewChild(PlayerProgressBarComponent, { static: false }) playbackProgressBar: PlayerProgressBarComponent;
  @ViewChild(PlaylistProgressBarComponent, { static: false }) playlistProgressBar: PlaylistProgressBarComponent;
  @ViewChild('cueToButton', { static: false }) cueToButtonRef: ElementRef;
  @ViewChild(TimecodeInputPopoverComponent, { static: false }) cueToPopover: TimecodeInputPopoverComponent;
  @ViewChild(Rs422ControlComponent, { static: false }) rs422ControlComponent: Rs422ControlComponent;
  @ViewChild('streamComponent', { static: false }) streamComponentRef: StreamComponent;

  // Inputs
  @Input() channel: Channel;
  @Input() selected = false;
  @Input() disabled = false;
  @Input() multiSelected = false;
  @Input() fullscreen = false;

  // Public
  public preset: Preset;
  public stream: Stream;
  public deck: Deck;
  public settings: StreamSettings;
  public selectedPreset = new BehaviorSubject<Preset>(null);
  public isMouseOver = false;
  public dropArea = {
    show: false,
    type: 'stream' // 'stream' or 'preset'
  };
  public status: StreamStatus;
  public noPreview = false;
  public inputs: StreamInputs;
  public layout: Layout;
  public allowedRemove: boolean;
  public allowedControlRecord: boolean;
  public schedule: Schedule;
  public streamSettingsBottomOffset = 8;
  public rs422BottomOffset = 40;
  public channelDisksWidth = 'calc(100% - 8px)';
  public savingSegment: boolean;
  public online: boolean = false;
  public onlineStatus: StreamOnlineStatus = 'offline';
  public rs422: boolean = false;
  public rs422ScrollActions = new RS422ScrollActions();
  public errors: BehaviorSubject<ChannelError[]> = new BehaviorSubject<ChannelError[]>([]);
  public displayLastError: ChannelError;
  public gangStreams: Stream[] = [];
  public gangTooltip: string;
  public clip: Clip;
  public timecode: string;
  public volume: Volume;
  public isFullscreen = false;
  public project: Project;
  public deckStatus: DeckStatus;
  public playlist: Playlist;
  public marker: Marker;

  // Private
  private channelChanges = new BehaviorSubject<Channel>(null);
  private alive = new Subject<void>();
  private popoverClose = new Subject<void>();
  private openingMenu = false;

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef,
    private streamService: StreamService,
    private deckService: DeckService,
    private presetService: PresetService,
    private channelService: ChannelService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private presetSettingsService: PresetSettingsService,
    private stateService: StateService,
    private layoutService: LayoutService,
    private scheduleService: ScheduleService,
    private rs422Service: Rs422Service,
    private fileManagerService: FileManagerService,
    private popoverService: PopoverService,
    private projectService: ProjectService,
    private playlistService: PlaylistService,
    userService: UserService,
  ) {
    // Getting stream for current channel
    const streamChanges = publishBehavior<Stream>(null)(
      this.channelChanges
        .pipe(
          distinctUntilChanged((previous, current) => previous?.streamId === current.streamId),
          tap(() => {
            this.stream = null;
            this.deck = null;
            this.preset = null;
            this.settings = null;
            this.status = null;
            this.inputs = null;
            this.errors.next([]);
          }),
          filter(channel => !!channel?.streamId),
          switchMap(channel => this.streamService.getStream(channel.streamId)),
          map(stream => {
            if (stream && (stream.deckChannel !== null && stream.deckChannel !== undefined) && stream.deckId) {
              return stream;
            }
            return null;
          }),
          distinctUntilChanged((previous, current) => (
            previous?.deckChannel === current?.deckChannel &&
            previous?.deckChannelName === current?.deckChannelName &&
            previous?.deckId === current?.deckId &&
            previous?.id === current?.id &&
            previous?.name === current?.name &&
            this.preset?.id === current?.presetId
          )),
          takeUntil(this.alive)
        )
    );
    streamChanges.connect();

    // Get Stream
    streamChanges
      .pipe(takeUntil(this.alive))
      .subscribe(stream => this.stream = stream);

    // Getting required info
    const getInfo = streamChanges
      .pipe(
        switchMap(stream => combineLatest([
          of(stream),
          this.deckService.getDeck(stream?.deckId),
          this.presetService.getPreset(stream?.presetId),
          this.streamService.getStatus(stream).pipe(startWith(null), distinctUntilChanged(isEqual)),
          this.scheduleService.getNearestScheduleForStream(stream),
          this.streamService.inputSettingsUpdated(stream)
            .pipe(startWith(null))
        ])),
        takeUntil(this.alive)
      );
    getInfo
      .subscribe(([stream, deck, preset, status, schedule, _]) => {
        this.deck = deck;
        this.preset = preset;
        this.settings = preset?.getSetting(stream?.id);
        this.status = status;
        this.schedule = schedule;
      });
    getInfo
      .pipe(
        mergeMap(result => this.streamService.getInputs(result[0])),
        retryWhen(errors => errors.pipe(delay(500))),
        takeUntil(this.alive)
      )
      .subscribe(inputs => this.inputs = inputs);
    getInfo
      .pipe(
        switchMap(result => this.rs422Service.modeChanged(result[0]).pipe(startWith(null), map(_ => result))),
        switchMap(result => this.rs422Service.getRemoteMode(result[0])),
        takeUntil(this.alive)
      )
      .subscribe(mode => this.rs422 = mode === 1);
    getInfo
      .pipe(
        switchMap(([stream, deck, preset, status, schedule, _]) => {
          const filePath = (status as StreamStatus)?.playback?.filename;
          if (this.status?.playback?.isLoaded && this.status?.info.isPlaybackMode && (!this.rs422 || (this.rs422 && !this.status?.info?.isRemotePlaybackMode)) && filePath) {
            return this.fileManagerService.getClip(deck.id, filePath);
          }
          return of(null);
        }),
        takeUntil(this.alive)
      )
      .subscribe(clip => this.clip = clip);
    const getPlaylist = getInfo
      .pipe(
        switchMap(result => {
          if (result[1]?.id) {
            return this.playlistService.playlistsChanged(result[1]?.id).pipe(startWith(null), map(_ => result))
          }
          return of(result);
        }),
        switchMap(([stream, deck, preset, status, schedule, _]) => {
          const playlistName = (status as StreamStatus)?.playback?.openPlaylistName;
          if (this.status?.playback?.isPlaylistOpenFile && this.status?.playback?.isLoaded && this.status?.info.isPlaybackMode && (!this.rs422 || (this.rs422 && !this.status?.info?.isRemotePlaybackMode)) && playlistName) {
            return this.playlistService.getPlaylist(deck.id, playlistName);
          }
          return of(null);
        }),
        takeUntil(this.alive)
      );
    getPlaylist.subscribe(playlist => this.playlist = playlist);

    // Get Marker
    getPlaylist
      .pipe(
        map(playlist => {
          if (playlist && this.status && playlist?.name !== this.status?.playback?.openPlaylistName) {
            return null;
          }
          const playlistClip = playlist?.clips[this.status?.playback?.clipPosition];
          return playlistClip?.markersId ? +playlistClip?.markersId : null;
        }),
        map(markerId => ({markerId, deckId: this.stream?.deckId})),
        distinctUntilChanged(isEqual),
        filter(({markerId, deckId}) => {
          if (!markerId || !deckId) {
            this.marker = null;
            return false;
          }
          return true;
        }),
        switchMap(({markerId, deckId}) => {
          const deck = this.deckService.getDeckSync(deckId);
          return this.deckService.loggerDBChanged(deck)
            .pipe(
              startWith(<string>null),
              map(() => markerId)
            )
        }),
        switchMap(markersId => this.playlistService.getMarker(this.stream?.deckId, markersId)),
        takeUntil(this.alive)
      )
      .subscribe(marker => this.marker = marker);

    // Get online status
    streamChanges
      .pipe(
        switchMap(stream => this.streamService.getInputOnlineStatus(stream)),
        takeUntil(this.alive)
      )
      .subscribe(onlineStatus => {
        this.onlineStatus = onlineStatus;
        this.online = onlineStatus === 'online';
      });

    streamChanges
      .pipe(
        filter(stream => !!stream),
        switchMap(stream => this.streamService.segmentBreak(stream)),
        tap(() => this.savingSegment = true),
        delay(1000),
        tap(() => this.savingSegment = false),
        takeUntil(this.alive),
      )
      .subscribe();

    // Volume management
    combineLatest([
      this.channelChanges,
      this.channelService.getSelectedChannels(),
      this.stateService.getState().pipe(map(state => state.volume)),
      this.stateService.getState().pipe(map(state => state.fullscreenChannelId)),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([currentChannel, selectedChannels, volume, fullscreenChannelId]) => {
        this.volume = { ...volume };
        if (
          selectedChannels?.length !== 1 ||
          !selectedChannels?.find(channel => channel?.id === currentChannel?.id) ||
          ((currentChannel?.id === fullscreenChannelId) && !this.fullscreen)
        ) {
          this.volume.value = 0;
          this.volume.muteLeft = true;
          this.volume.muteRight = true;
        }
      });

    // Fullscreen
    combineLatest([
      this.channelChanges,
      this.stateService.getState().pipe(map(state => state.fullscreenChannelId))
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([currentChannel, fullscreenChannelId]) => {
        this.isFullscreen = currentChannel?.id === fullscreenChannelId;
      });

    // Get Errors
    streamChanges
      .pipe(
        switchMap(stream => this.streamService.error(stream)),
        filter(error => !!error),
        takeUntil(this.alive)
      )
      .subscribe(error => {
        const errors = this.errors.value;
        errors.push(error);
        this.errors.next(errors);
      });

    // Show last error message
    this.errors
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(errors => {
        this.displayLastError = errors[errors.length - 1] || null;
        this.updateErrorsPosition();
      });

    // Clear All Errors
    this.streamService.getCleanErrors()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.cleanErrors());

    // Get selected preset
    this.presetService.getCurrentPreset()
      .pipe(takeUntil(this.alive))
      .subscribe(this.selectedPreset);

    // Layout Changes
    this.layoutService.getLayout()
      .pipe(takeUntil(this.alive))
      .subscribe(layout => this.layout = layout);

    userService
      .getCurrentPermission('configureInputSources')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowedRemove = allowed);

    userService
      .getCurrentPermission('controlRecord')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowedControlRecord = allowed);

    // Gang Streams
    streamChanges
      .pipe(
        switchMap(stream => this.streamService.getGangStreams(stream)),
        takeUntil(this.alive)
      )
      .subscribe(streams => {
        this.gangStreams = streams;
        this.gangTooltip = this.gangStreams.length < 2 ? 'No other inputs' : 'Gang inputs:\r\n' + this.gangStreams
          .filter(stream => stream?.id !== this.stream?.id)
          .map(stream => stream.name)
          .join('\r\n');
      });

    // Get Project
    this.projectService.getProject()
      .pipe(takeUntil(this.alive))
      .subscribe(project => this.project = project);

    // Get Deck Status
    getInfo
      .pipe(
        map(([stream, deck, preset, status, schedule, _]) => deck),
        distinctUntilChanged(isEqual),
        switchMap(deck => this.deckService.getStatus(deck)),
        takeUntil(this.alive)
      )
      .subscribe(deckStatus => this.deckStatus = deckStatus);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('channel' in changes) {
      this.channelChanges.next(this.channel);
    }
  }

  ngDoCheck(): void {
    const audioLevelOffset = (this.audioLevels?.elementRef?.nativeElement?.offsetWidth + 16 || 0) + 8;
    this.streamSettingsBottomOffset = (this.disksComponent?.elementRef?.nativeElement?.offsetHeight || 0) + 8;
    this.rs422BottomOffset = Math.max(((this.disksComponent?.elementRef?.nativeElement?.offsetHeight || 0) + 8), 40);
    this.channelDisksWidth = `calc(100% - ${audioLevelOffset}px)`;
    this.updateErrorsPosition();
    // RS422 Calc
    if (this.rs422ControlComponent?.elementRef?.nativeElement) {
      const rs422Width = this.elementRef.nativeElement.offsetWidth - audioLevelOffset;
      const scale = rs422Width / 650;
      this.rs422ControlComponent.elementRef.nativeElement.style.transform = `scale(${scale < 1 ? scale : 1})`;
      this.rs422ControlComponent.elementRef.nativeElement.style.left = (scale < 1 ? ((rs422Width - 650)/2) : 0) + 'px';
      this.rs422ControlComponent.elementRef.nativeElement.style.bottom = this.rs422BottomOffset + 'px';
    }
  }

  ngOnDestroy(): void {
    // this.renderer.destroy();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Preview
   */

  setTimecode(timecodes): void {
    if (timecodes && (this.timecodeRef || this.timecodeElapsedRef || this.playbackProgressBar || this.playlistProgressBar)) {
      const playbackTc = timecodes.playback;
      const playlistTc = timecodes.playlist;
      // Get Timecode
      if (playlistTc) {
        this.timecode = playlistTc;
      } else if (playbackTc) {
        this.timecode = playbackTc;
      } else {
        this.timecode = timecodes[this.settings?.tcSource];
      }
      // Timecode
      if (this.timecodeRef && this.timecode) {
        this.timecodeRef.setValue(this.timecode);
      }
      // Timecode Elapsed
      if (this.timecodeElapsedRef) {
        this.timecodeElapsedRef.nativeElement.innerHTML = timecodes.elapsed || '00:00:00:00';
      }
      // Progress bar
      if (this.playbackProgressBar && playbackTc) {
        this.playbackProgressBar.setValue(this.timecode);
      }
      // Playlist progress bar
      if (this.playlistProgressBar && timecodes.elapsed) {
        this.playlistProgressBar.setValue(timecodes.elapsed);
      }
      // Cue to
      if (this.cueToPopover && (playbackTc || playlistTc)) {
        this.cueToPopover.setValue(this.timecode);
      }
      if (((this.remainTimecodeRef && this.elapsedTimecodeRef) || this.playbackCountdownRef) && playbackTc && this.playlist && this.clip) {
        // Get Timebase
        const interlaced = Timecode.isInterlaced(+this.playlist.frameRate, this.playlist.resolution);
        const timebase = +this.playlist.frameRate;// Timecode.getTimebase(+this.playlist.frameRate, interlaced);
        const drop = +this.playlist.drop === 1;
        // Get Start/End TC
        const playlistClip = this.playlist.clips[this.status.playback.clipPosition];
        const startTC = playlistClip.in !== TimecodeNull ? +playlistClip.in : this.clip.startTimecodeFrames;
        const endTC = playlistClip.out !== TimecodeNull ? +playlistClip.out : this.clip.endTimecodeFrames;
        // Calc remain / elapsed TC
        const currentClipTimecode = new Timecode(playbackTc, timebase, drop);
        const remainDuration = endTC - currentClipTimecode.frameCount;
        const elapsedDuration = currentClipTimecode.frameCount - startTC;
        const remainTC = new Timecode(remainDuration, timebase, drop);
        const elapsedTC = new Timecode(elapsedDuration, timebase, drop);
        let remainTimecode = remainDuration < 0 ? null : remainTC.toString();
        let elapsedTimecode = elapsedDuration < 0 ? null : elapsedTC.toString();
        // Set TC to control
        if (this.remainTimecodeRef) {
          this.remainTimecodeRef.nativeElement.innerHTML = remainTimecode || '00:00:00:00';
        }
        if (this.elapsedTimecodeRef) {
          this.elapsedTimecodeRef.nativeElement.innerHTML = elapsedTimecode || '00:00:00:00';
        }
        if (this.playbackCountdownRef) {
          const secondsDigits = remainTC.seconds;
          const minutesDigits = remainTC.minutes + (remainTC.hours * 60);
          const seconds = secondsDigits.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
          const minutes = minutesDigits.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
          this.playbackCountdownRef.nativeElement.innerHTML = remainDuration >= 0 ? `${minutes}:${seconds}` : '00:00';
          this.playbackCountdownRef.nativeElement.style.color = (remainDuration < 0 || (minutesDigits === 0 && secondsDigits < 10)) ? 'red' : 'white';
        }
      }
    }
  }
  
  setAudioLevel(levels): void {
    if (this.audioLevels && levels) {
      this.audioLevels.setAudioLevel(levels);
    }
  }

  /**
   * Methods
   */

  updateErrorsPosition(): void {
    if (this.errorsIconRef) {
      this.errorsIconRef.nativeElement.style.top = (this.channelInfoRef?.nativeElement ? 56 : 8) + 'px';
    }
    if (this.errorMessageRef) {
      this.errorMessageRef.nativeElement.style.top = (this.channelInfoRef?.nativeElement ? 56 : 8) + 'px';
      this.errorMessageRef.nativeElement.style.left = (this.errorsIconRef.nativeElement?.offsetWidth + 15) + 'px';
    }
  }

  // @TODO: Remove usage of function in template!!!
  getSettingsTitle(value: string|number, key: string): string {
    return this.presetSettingsService.getTitle(value, key);
  }

  /**
   * Record
   */

  startRecord(): void {
    if (this.stream) {
      (this.gangStreams.length > 1)
        ? this.streamService.groupRecord(this.gangStreams)
        : this.streamService.record(this.stream);
    }
  }

  stopRecord(): void {
    if (this.stream) {
      (this.gangStreams.length > 1)
        ? this.streamService.groupStopRecord(this.gangStreams)
        : this.streamService.stopRecord(this.stream);
    }
  }

  breakRecord(): void {
    if (this.stream) {
      (this.gangStreams.length > 1)
        ? this.streamService.groupBreakRecord(this.gangStreams)
        : this.streamService.breakRecord(this.stream);
    }
  }

  pauseRecord(): void {
    if (this.stream) {
      (this.gangStreams.length > 1)
        ? this.streamService.groupPauseRecord(this.gangStreams)
        : this.streamService.pauseRecord(this.stream);
    }
  }

  resumeRecord(): void {
    if (this.stream) {
      (this.gangStreams.length > 1)
        ? this.streamService.groupResumeRecord(this.gangStreams)
        : this.streamService.resumeRecord(this.stream);
    }
  }

  /**
   * Playback
   */

  openFile(): void {
    if (this.gangStreams.length > 1) {
      forkJoin([this.gangStreams.map(stream => this.streamService.openFile(stream))])
        .pipe(
          filter(() => !!this.channel?.layoutSettings?.autoPlaybackLoadingFile),
          delay(1000),
          takeUntil(this.alive),
        )
        .subscribe(() => this.play());
    } else {
      this.streamService.openFile(this.stream)
        .pipe(
          filter(() => !!this.channel?.layoutSettings?.autoPlaybackLoadingFile),
          takeUntil(this.alive),
        )
        .subscribe(() => this.play());
    }
  }

  ejectFile(): void {
    // Switch to RS422
    if (this.rs422 && !this?.status?.info?.isRemotePlaybackMode) {
      this.streamService.ejectFile(this.stream);
      return;
    }
    // Eject
    this.streamService.ejectFile(this.stream);
    if (this.gangStreams.length > 1) {
      this.gangStreams.forEach(stream => this.streamService.ejectFile(stream));
    }
  }

  play(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangPlay(this.gangStreams)
      : this.streamService.play(this.stream);
  }

  reverse(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangReverse(this.gangStreams)
      : this.streamService.reverse(this.stream);
  }

  fastForward(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangFastForward(this.gangStreams)
      : this.streamService.fastForward(this.stream);
  }

  rewind(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangRewind(this.gangStreams)
      : this.streamService.rewind(this.stream);
  }

  pause(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangPause(this.gangStreams)
      : this.streamService.pause(this.stream);
  }

  goToStart(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangGoToStart(this.gangStreams)
      : this.streamService.goToStart(this.stream);
  }

  goToEnd(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangGoToEnd(this.gangStreams)
      : this.streamService.goToEnd(this.stream);
  }

  setInPoint(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangSetInPoint(this.gangStreams)
      : this.streamService.setInPoint(this.stream);
  }

  setOutPoint(): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangSetOutPoint(this.gangStreams)
      : this.streamService.setOutPoint(this.stream);
  }

  jump(value: {tc?: string, frame?: number, offset?: number}): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangJump(this.gangStreams, value)
      : this.streamService.jump(this.stream, value);
  }

  goToNextClip(stream: Stream): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangGoToNextClip(this.gangStreams)
      : this.streamService.goToNextClip(this.stream);
  }

  goToPrevClip(stream: Stream): void {
    (this.gangStreams.length > 1)
      ? this.streamService.gangGoToPrevClip(this.gangStreams)
      : this.streamService.goToPrevClip(this.stream);
  }

  hideStopOverlay(stream: Stream): void {
    this.streamService.hideStopOverlay(this.stream);
  }

  loopPlayback(): void {
    const value: StreamStatusPlaybackLoop = this.status.playback.loopPlayback === 'off' ? 'continuous' : 'off';
    this.streamService.loopPlayback(this.stream, value);
    if (this.gangStreams.length > 1) {
      this.gangStreams.forEach(stream => this.streamService.loopPlayback(stream, value));
    }
  }

  goToNextAndPlay(): void {
    this.streamService.goToNextAndPlay(this.stream);
  }

  goToNextAndPause(): void {
    this.streamService.goToNextAndPause(this.stream);
  }

  goToPrevAndPlay(): void {
    this.streamService.goToPrevAndPlay(this.stream);
  }

  goToPrevAndPause(): void {
    this.streamService.goToPrevAndPause(this.stream);
  }

  /**
   * Actions
   */

  mouseEnter(): void {
    this.isMouseOver = true;
  }

  mouseLeave(): void {
    // When opening MatMenu Timecode component hide and menu dont open (Gang Button)
    if (this.openingMenu) {
      return;
    }
    this.isMouseOver = false;
  }

  openSettings(event: MouseEvent): void {
    this.streamService.disablePreviews();
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    this.dialog
      .open(PresetSettingsComponent, {data: {stream: this.stream, presetId: this.stream.presetId}, disableClose: true, autoFocus: false})
      .afterClosed()
      .pipe(
        delay(1500),
        takeUntil(this.alive)
      )
      .subscribe(() => this.streamService.enablePreviews());
  }

  openLayoutSettings(event: MouseEvent): void {
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    this.dialog.open(ChannelLayoutSettingsComponent, {data: {channel: this.channel}, disableClose: true});
  }

  openFileManager(event: MouseEvent): void {
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    this.dialog.open(ClipManagerComponent, {data: {stream: this.stream}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value) {
          const clipIds = result.value;
          if (this.status.playback.isLoaded && !isEqual(this.status.playback.openedClips, clipIds)) {
            this.streamService.ejectFile(this.stream)
              .pipe(
                switchMap(() => this.streamService.openFile(this.stream, clipIds)),
                filter(() => !!this.channel?.layoutSettings?.autoPlaybackLoadingFile),
                takeUntil(this.alive),
              )
              .subscribe(() => this.play());
          } else {
            this.streamService.openFile(this.stream, clipIds)
              .pipe(
                filter(() => !!this.channel?.layoutSettings?.autoPlaybackLoadingFile),
                takeUntil(this.alive),
              )
              .subscribe(() => this.play());
          }
        }
      });
  }

  removeStream(event: MouseEvent): void {
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    this.alertService.show(
      'Removing Input from Channel',
      'Input will be removed from the channel but this has no effect on the input\'s state. (eg recording or playback will continue without interuption.)',
      ['Cancel', 'Remove'],
      0
    )
      .subscribe(response => {
        if (response === 1) {
          this.channelService.updateChannel({ ...this.channel, streamId: null });
        }
      });
  }

  changeAudioPair(pair: number): void {
    this.stream.audioPair = pair;
    this.streamService.updateStream(this.stream);
    // TODO: Update Volume
  }

  toggleAudioLevel(event: MouseEvent): void {
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    this.channel.audioLevels = !this.channel.audioLevels;
    this.channelService.updateChannel(this.channel);
  }

  updateRS422Settings(settings: RS422Settings): void {
    this.channel.rs422Settings = settings;
    this.channelService.updateChannel(this.channel);
  }

  openGangMenu(event: MouseEvent): void {
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    this.openingMenu = true;
    this.gangMenuTrigger.openMenu();
    setTimeout(() => this.openingMenu = false, 300);
  }

  toggleGang(event: MouseEvent): void {
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    if (event && event?.shiftKey) {
      this.gangAllChannels(!this.channel.streamSettings.gang);
    } else {
      this.channel.streamSettings.gang = !this.channel.streamSettings.gang;
      this.channelService.updateChannel(this.channel);
    }
  }

  gangAllChannels(select: boolean): void {
    this.streamService.getStreams()
      .pipe(
        map(streams => streams
          .filter(stream => stream?.deckId === this.stream?.deckId)
          .map(stream => stream.id)
        ),
        switchMap(streamsIds => this.channelService.getChannels()
          .pipe(
            map(channels => channels.filter(channel => streamsIds.includes(channel.streamId)))
          )
        ),
        take(1),
      )
      .subscribe(channels => {
        channels.forEach(channel => {
          channel.streamSettings.gang = select;
          this.channelService.updateChannel(channel);
        });
      });
      
  }
  
  openTimecodePopover(template: TemplateRef<any>): void {
    this.popoverService.create(
      this.cueToButtonRef,
      {
        content: template,
        placement: 'top',
        trigger: 'click',
        innerShadow: false,
        arrow: true,
        popoverOffsetY: 0,
        showUntil: this.popoverClose
      }
    );
  }

  toggleFullscreen(): void {
    if (this.isFullscreen) {
      this.channelService.removeFullscreenChannel();
    } else {
      this.channelService.setFullscreenChannel(this.channel);
    }
  }

  /**
   * Errors
   */

  openErrorMenu(event: MouseEvent): void {
    if (!this.multiSelected) {
      this.channelService.selectChannels([ this.channel ], event.shiftKey);
    }
    event?.preventDefault();
    event?.stopPropagation();
    this.openingMenu = true;
    this.errorMenuTrigger.openMenu();
    setTimeout(() => this.openingMenu = false, 300);
  }

  openErrorsDialog(event?: MouseEvent): void {
    if (this.multiSelected) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    if (event && event?.shiftKey && event?.altKey) {
      this.cleanAllErrors();
    } else if (event && event?.altKey) {
      this.cleanErrors();
    } else {
      this.dialog.open(ChannelErrorsDialogComponent, {data: {errors: this.errors}, disableClose: true});
    }
  }

  cleanErrors(): void {
    this.errors.next([]);
  }

  cleanAllErrors(): void {
    this.streamService.setCleanErrors();
  }

  /**
   * Drag and drop
   */

  dndEnter(event: DraggableEvent): void {
    this.dropArea.show = true;
    this.dropArea.type = event.dragData.type;
  }

  dndLeave(event: DraggableEvent): void {
    this.dropArea.show = false;
  }

  dndDragOver(event: DraggableEvent): void {
  }

  dndDrop(event: DraggableEvent): void {
    this.dropArea.show = false;
    if (event.dragData.type === 'stream') {
      const stream: Stream = event.dragData.data;
      this.channelService.assignStreamToChannel(this.channel, stream.id)
        .pipe(take(1), filter(result => result))
        .subscribe(() => {
          const presetId = stream.presetId || this.selectedPreset.value?.id;
          this.streamService.setPreset(presetId, stream.id);
          const preset = this.presetService.getPresetSync(presetId);
          if (preset) {
            this.presetService.selectPreset(preset);
          }
        });
    }
    if (event.dragData.type === 'preset' && this.channel.streamId) {
      const preset: Preset = event.dragData.data;
      this.streamService.setPreset(preset.id, this.channel.streamId);
      this.presetService.selectPreset(preset);
    }
  }

}
