<div class="schedule-list-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Scheduled Records</div>
    <div class="actions">
      <mat-icon svgIcon="plus" class="action-icon" [class.disabled]="locked || !allowed" (click)="createSchedule()"></mat-icon>
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" [class.disabled]="!allowed" (click)="lock()"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content" *ngIf="schedules.length">

    <!-- Table -->
    <div class="schedule-table">

      <!-- Header -->
      <div class="header" [class.disabled]="!allowed || locked">
        <div class="title user">User</div>
        <div class="title inputs">Input name or Group</div>
        <div class="title preset">Preset</div>
        <div class="title action">Action</div>
        <div class="title interval">Interval</div>
        <div class="title date">Date</div>
        <div class="title start-time">Start time</div>
        <div class="title end-time">End time</div>
        <div class="title next-run">Next Run</div>
        <div class="title settings"></div>
      </div>

      <!-- Content -->
      <div class="content">
        <div
          class="row"
          [class.blink]="isInProgress(schedule)"
          *ngFor="let schedule of schedules; let index = index"
          [contextMenu]="scheduleMenu"
          [contextMenuSubject]="schedule"
        >
          <div class="user" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div>{{ getName(schedule.user) | async }}</div>
          </div>
          <div class="inputs" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item">{{ getInput(schedule) | async }}</div>
          </div>
          <div class="preset" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item" [class.error]="!(getPresetValidation(schedule.presetId) | async)">{{ getPreset(schedule.presetId) | async }}</div>
          </div>
          <div class="action" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item">{{ schedule.action | titlecase }}</div>
          </div>
          <div class="interval" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item">{{ schedule.interval | titlecase }}{{ schedule.interval === 'repeat' ? ' ' + (schedule.rrule | rrule: 'frequency') : ''}}</div>
          </div>
          <div class="date" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item" fittext [maxFontSize]="14">
              <span *ngIf="schedule.interval === 'once'">{{ (schedule.startDate || schedule.endDate) | date: 'mediumDate' }}</span>
              <span *ngIf="schedule.interval === 'repeat' && (schedule.rrule | rrule).origOptions?.freq === 1">{{ schedule.rrule | rrule: 'date' | date: 'dd' }}</span>
              <span *ngIf="schedule.interval === 'repeat' && (schedule.rrule | rrule).origOptions?.freq === 2">{{ schedule.rrule | rrule: 'weekdays' }}</span>
              <span *ngIf="schedule.interval === 'repeat' && (schedule.rrule | rrule).origOptions?.freq === 3">{{ 'Everyday' }}</span>
            </div>
          </div>
          <div class="start-time" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item" *ngIf="schedule.startDate">{{ schedule.startDate | date: 'H:mm' }}</div>
          </div>
          <div class="end-time" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item" *ngIf="schedule.endDate">{{ schedule.endDate | date: 'H:mm' }}</div>
          </div>
          <div class="next-run" [class.disabled]="isCompleted(schedule) || !allowed || locked">
            <div class="item">{{ (schedule.interval === 'once' ? (schedule.startDate || schedule.endDate) : (schedule.rrule | rrule: 'date')) | date: 'mediumDate' }}</div>
          </div>
          <div class="settings" [class.disabled]="!allowed || locked || !allowedEdit">
            <mat-icon svgIcon="settings" class="settings-icon" (click)="editSchedule(schedule)"></mat-icon>
          </div>
        </div>
      </div>

    </div>


  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!schedules.length">
    <div class="text">No scheduled actions</div>
  </div>

</div>

<!-- Context menu -->
<context-menu #scheduleMenu [disabled]="!allowed || locked || !allowedEdit">
  <ng-template contextMenuItem let-item (execute)="editSchedule($event.item)">Edit</ng-template>
  <ng-template contextMenuItem let-item (execute)="duplicateSchedule($event.item)">Duplicate</ng-template>
  <ng-template contextMenuItem let-item (execute)="deleteSchedule($event.item)">Delete</ng-template>
</context-menu>
