<div class="stream-groups-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Selection Groups</div>
    <div class="actions">
      <mat-icon svgIcon="minus" class="action-icon" [class.disabled]="locked || !allowed" (click)="deleteGroup(selected)"></mat-icon>
      <mat-icon
        svgIcon="plus"
        class="action-icon"
        [class.disabled]="locked || !allowed"
        popover
        [popoverContent]="createPopover"
        popoverPlacement="bottom"
        popoverTrigger="click"
        [popoverDisabled]="!selectedChannels.length"
        [popoverShowUntil]="popoverClose"
      ></mat-icon>
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" [class.disabled]="!allowed" (click)="lock()"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content" *ngIf="groups?.length">
    <div *ngFor="let group of groups" class="tag" [class.selected]="group?.id === selected?.id" [class.disabled]="locked" (click)="select(group)">{{ group.name }}</div>
  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!groups?.length">
    <div class="text">Click on <b>Plus</b> icon to add <b>Group</b></div>
  </div>

</div>

<!-- Popover Create -->
<ng-template #createPopover>
  <app-create-item-popover [popoverClose]="popoverClose" [excludeNames]="groupsNames" (created)="createGroup($event)"></app-create-item-popover>
</ng-template>
