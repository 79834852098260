import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { StreamService } from '@modules/stream/services/stream.service';

// Types
import { SelectionModel } from '@angular/cdk/collections';
import { Clip } from '@modules/deck/types/clip';
import { Stream } from '@modules/stream/types/stream';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NodeService } from '@modules/node/services/node.service';

interface PathNode {
  name: string;
  path: string;
  children?: PathNode[];
}

@Component({
  selector: 'app-directory-tree',
  templateUrl: './directory-tree.component.html',
  styleUrls: ['./directory-tree.component.less']
})
export class DirectoryTreeComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() stream: Stream;
  @Input() selectedPath: string;

  @Output() selectedPathChange = new EventEmitter<string>();

  // Public
  public clips: Clip[] = [];
  public selection = new SelectionModel<PathNode>(false, []);
  public treeControl = new NestedTreeControl<PathNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<PathNode>();
  public hasChild = (_: number, node: PathNode) => !!node.children && node.children.length > 0;


  // Private
  private alive = new Subject();
  private getClips = new Subject();

  /**
   * Constructor
   */

  constructor(
    private fileManagerService: FileManagerService,
    private deckService: DeckService,
    private streamService: StreamService,
    private nodeService: NodeService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get clips
    this.getClips
      .pipe(
        switchMap(() => this.fileManagerService.getIndexedFiles(this.stream.deckId)),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(clips => {
        this.clips = clips;
        this.transformClipsToDirTree();
      });

    // Handle new clips
    const deck = this.deckService.getDeckSync(this.stream.deckId);
    this.deckService.clipAdded(deck)
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(_ => this.getClips.next());
    this.getClips.next();
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  transformClipsToDirTree(): void {
    const paths = this.clips.map(clip => this.nodeService.path.win32.normalize(clip?.drivePath + clip?.dirPath));
    let result: PathNode[] = [];
    let level = {result};
    paths.forEach(path => {
      let filePath = '';
      path.split('\\').filter(name => name?.length > 0).reduce((r, name, i, a) => {
        filePath = filePath + name + '\\';
        if(!r[name]) {
          r[name] = {result: []};
          r.result.push({name, path: filePath, children: r[name].result})
        }
        return r[name];
      }, level);
    });

    this.dataSource.data = result;
  }

  getLevel(data, node: PathNode) {
    let path = data.find(branch => {
      return this.treeControl
        .getDescendants(branch)
        .some(n => isEqual(n, node));
    });
    return path ? this.getLevel(path.children, node) + 1 : 0 ; 
  }

  /**
   * Actions
   */

  selectFolder(node: PathNode): void {
    this.selection.select(node);
    this.selectedPathChange.emit(node.path);
  }

  unselectFolder(): void {
    this.selection.clear();
    this.selectedPathChange.emit('');
  }

}
