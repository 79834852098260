import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { DeckService } from '@modules/deck/services/deck.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Deck } from '@modules/deck/types/deck';

@Component({
  selector: 'app-preset-settings-ndi-source',
  templateUrl: './preset-settings-ndi-source.component.html',
  styleUrls: ['./preset-settings-ndi-source.component.less']
})
export class PresetSettingsNdiSourceComponent implements OnInit, OnDestroy {

  // Public
  public deck: Deck;
  public addresses: string[];
  public newAddress: string;

  // Private
  private alive = new Subject();
  private updateIps = new BehaviorSubject<void>(null);

  /**
   * Constructor
   */

  constructor(
    public deckService: DeckService,
    public dialogRef: MatDialogRef<PresetSettingsNdiSourceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {deckId: string},
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    combineLatest([this.deckService.getDeck(this.data.deckId), this.updateIps.asObservable()])
      .pipe(
        map(([deck, _]) => deck),
        tap(deck => this.deck = deck),
        switchMap(deck => this.deckService.getNdiIPs(deck)),
        takeUntil(this.alive)
      )
      .subscribe(addresses => this.addresses = addresses);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  updateAddress(event?, index?: number): void {
    if (!event && (index === undefined || index === null)) {
      this.deckService.updateNdiIPs(this.deck, this.addresses).subscribe(() => this.updateIps.next());
      return;
    }
    this.addresses[index] = event;
  }

  addAddress(): void {
    if (!this.newAddress?.length) {
      return;
    }
    this.addresses.push(this.newAddress + '');
    this.deckService.updateNdiIPs(this.deck, this.addresses).subscribe(() => this.updateIps.next());;
    this.newAddress = '';
  }

  deleteAddress(index: number): void {
    this.addresses.splice(index, 1);
    this.deckService.updateNdiIPs(this.deck, this.addresses).subscribe(() => this.updateIps.next());;
  }

  close(): void {
    this.dialogRef.close();
  }
}
