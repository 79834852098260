import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


// External modules
import { ContextMenuModule } from 'ngx-contextmenu';

// Modules
import { ElementsModule } from '@modules/elements/elements.module';
import { StreamModule } from '@modules/stream/stream.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { Rs422Module } from '@modules/rs422/rs422.module';
import { MarkerModule } from '@modules/marker/marker.module';

// Components
import { PlaylistEditorComponent } from './components/playlist-editor/playlist-editor.component';
import { PlaylistFilesComponent } from './components/playlist-files/playlist-files.component';
import { PlaylistTimelineComponent } from './components/playlist-timeline/playlist-timeline.component';
import { PlaylistPlayerComponent } from './components/playlist-player/playlist-player.component';
import { PlaylistManagerComponent } from './components/playlist-manager/playlist-manager.component';
import { PlaylistNameDialogComponent } from './components/playlist-name-dialog/playlist-name-dialog.component';
import { PlaylistClipInfoComponent } from './components/playlist-clip-info/playlist-clip-info.component';
import { PlaylistChannelComponent } from './components/playlist-channel/playlist-channel.component';
import { PlaylistProgressBarComponent } from './components/playlist-progress-bar/playlist-progress-bar.component';
import { PlaylistFilesItemComponent } from './components/playlist-files-item/playlist-files-item.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ElementsModule,
    StreamModule,
    PopoverModule,
    DragNDropModule,
    Rs422Module,
    MarkerModule,

    // External
    ContextMenuModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    DragDropModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    PlaylistEditorComponent,
    PlaylistFilesComponent,
    PlaylistTimelineComponent,
    PlaylistPlayerComponent,
    PlaylistManagerComponent,
    PlaylistNameDialogComponent,
    PlaylistClipInfoComponent,
    PlaylistChannelComponent,
    PlaylistProgressBarComponent,
    PlaylistFilesItemComponent,
  ],
  exports: [
    PlaylistEditorComponent,
    PlaylistManagerComponent,
    PlaylistNameDialogComponent,
    PlaylistFilesComponent,
    PlaylistChannelComponent,
    PlaylistProgressBarComponent,
  ]
})
export class PlaylistEditorModule { }
