<div class="channel-timecode-content" [class.can-edit]="canEdit" [class.editing]="editing" [class.record]="record">
  <!-- Static -->
  <div *ngIf="!editing" class="timecode" (click)="startEdit()" #timecode>{{ timecodeInput || '00:00:00:00'}}</div>
  <!-- Input -->
  <input
    *ngIf="editing"
    #timecodeInputElement
    [dropSpecialCharacters]="false"
    [specialCharacters]="[':', ';', '.']"
    [mask]="mask"
    class="timecode-input"
    [placeholder]="placeholder"
    [(ngModel)]="timecodeInput"
    (ngModelChange)="validate($event)"
    (keydown.enter)="save()"
    (keydown.esc)="close()"
    (focusout)="close()"
    (keydown.arrowup)="nextFrame()"
    (keydown.arrowdown)="prevFrame()"
  >
</div>
