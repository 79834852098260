import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';

// External modules
import { ContextMenuModule } from 'ngx-contextmenu';

// Modules
import { ElementsModule } from '@modules/elements/elements.module';

// Services
import { ProjectService } from './services/project.service';
import { SettingsModule } from '@modules/settings/settings.module';
import { PopoverModule } from '../popover/popover.module';
import { PresetModule } from '@modules/preset/preset.module';

// Components
import { TagListComponent } from './components/tag-list/tag-list.component';
import { UserTagListComponent } from './components/user-tag-list/user-tag-list.component';
import { XmlTagListComponent } from './components/xml-tag-list/xml-tag-list.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { GlobalTagListComponent } from './components/global-tag-list/global-tag-list.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ElementsModule,
    SettingsModule,
    PopoverModule,
    PresetModule,

    // External
    ContextMenuModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    MatCheckboxModule,
  ],
  declarations: [
    TagListComponent,
    UserTagListComponent,
    XmlTagListComponent,
    ProjectSettingsComponent,
    GlobalTagListComponent,
  ],
  exports: [
    TagListComponent,
    UserTagListComponent,
    XmlTagListComponent,
    GlobalTagListComponent,
  ],
  providers: [
    ProjectService
  ],
})
export class ProjectModule { }
