<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Encoder Burns Setup</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>

  <div class="preset-settings-burns-content">

    <!-- Options -->
    <div class="overlay-options">
  
      <!-- Encoder Timecode -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].encoderTimecodeElement')">
        <div class="label">Encoder Timecode</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].encoderTimecodeElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'encoderTimecodeElement'" (click)="updateEditField('encoderTimecodeElement')">Edit</div>
      </div>
  
      <!-- Timecode Source -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].tcSource')">
        <div class="label">Timecode Source</div>
        <app-select class="select" [options]="settingsOptions?.encoderTcSource" [selected]="settings.channels[deckChannel].burnIn[encoderIndex].tcSource" (selectedChange)="updateTimecodeSource($event.value)" [highlighted]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].tcSource')"></app-select>
      </div>
  
      <!-- System Time & Date -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].systemTimeAndDateElement')">
        <div class="label">System Time & Date</div>
        <mat-checkbox class="checkbox" [disabled]="false"  [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].systemTimeAndDateElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'systemTimeAndDateElement'" (click)="updateEditField('systemTimeAndDateElement')">Edit</div>
      </div>

      <!-- GPS -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].GPSElement')">
        <div class="label">GPS</div>
        <mat-checkbox class="checkbox" [disabled]="false"  [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].GPSElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'GPSElement'" (click)="updateEditField('GPSElement')">Edit</div>
      </div>
  
      <!-- Frame Number -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].frameNumberElement')">
        <div class="label">Frame Number</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].frameNumberElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'frameNumberElement'" (click)="updateEditField('frameNumberElement')">Edit</div>
      </div>
  
      <!-- File Name -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].filenameElement')">
        <div class="label">File Name</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].filenameElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'filenameElement'" (click)="updateEditField('filenameElement')">Edit</div>
      </div>
  
      <!-- Codec -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].codecElement')">
        <div class="label">Codec</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].codecElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'codecElement'" (click)="updateEditField('codecElement')">Edit</div>
      </div>
  
      <!-- Quality -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].qualityElement')">
        <div class="label">Quality</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].qualityElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'qualityElement'" (click)="updateEditField('qualityElement')">Edit</div>
      </div>
  
      <!-- User Text -->
      <div class="field" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].userTextElement')">
        <div class="label">User Text</div>
        <mat-checkbox class="checkbox" [disabled]="false" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex].userTextElement.enabled" (ngModelChange)="updateSettings()" disableRipple></mat-checkbox>
        <div class="button" [class.selected]="editField === 'userTextElement'" (click)="updateEditField('userTextElement')">Edit</div>
      </div>
  
    </div>
  
    <!-- Editor -->
    <div class="overlay-info">
  
      <!-- Preview -->
      <div class="overlay-preview">
  
        <!-- Image -->
        <mat-icon svgIcon="smpte-color-bars" class="smpte-image"></mat-icon>

        <!-- Elements -->
        <ng-container *ngFor="let field of fields">
          <div
            class="burn-element"
            *ngIf="settings.channels[deckChannel].burnIn[encoderIndex][field].enabled"
            [ngClass]="[settings.channels[deckChannel].burnIn[encoderIndex][field].position]"
            [ngStyle]="getStyles(field)"
          >
            <div
              class="text"
              [style.color]="argbToRgba(settings.channels[deckChannel].burnIn[encoderIndex][field].textColor)"
              [style.fontSize.px]="settings.channels[deckChannel].burnIn[encoderIndex][field].size / 2"
            >
              {{ settings.channels[deckChannel].burnIn[encoderIndex][field].text }}
            </div>
          </div>
        </ng-container>
  
      </div>
  
      <!-- Edit -->
      <div class="overlay-editor">
  
        <!-- Settings -->
        <div class="overlay-settings" *ngIf="editField && editField.length">
  
          <!-- Text Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].' + editField + '.textColor')">
            <div class="label">Text Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].burnIn[encoderIndex][editField].textColor" (colorChange)="updateColor($event, 'textColor')"></app-color-select>          
          </div>
  
          <!-- Text Size -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].' + editField + '.size')">
            <div class="label">Text Size</div>
            <input class="input" type="number" min="12" max="72" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex][editField].size" (blur)="updateSettings()">
          </div>
  
          <!-- Block Color -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].' + editField + '.blockColor')">
            <div class="label">Block Color</div>
            <app-color-select [argbConvert]="true" [color]="settings.channels[deckChannel].burnIn[encoderIndex][editField].blockColor" (colorChange)="updateColor($event, 'blockColor')"></app-color-select>
          </div>

          <!-- Position -->
          <div class="settings-option position" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].' + editField + '.position')">
            <div class="label">Position</div>
            <app-select class="select" [options]="positionOptions" [selected]="settings.channels[deckChannel].burnIn[encoderIndex][editField].position" (selectedChange)="updatePosition($event.value)" [highlighted]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].' + editField + '.position')"></app-select>
          </div>
  
          <!-- Safe area -->
          <div class="settings-option" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].' + editField + '.safe')">
            <div class="label">Safe area</div>
            <input class="input" type="number" min="80" max="99" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex][editField].safe" (blur)="updateSettings()">
          </div>

          <!-- Opacity -->
          <!-- <div class="settings-option">
            <div class="label">Opacity</div>
            <input class="input" type="number" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex][editField].opacity" (blur)="updateSettings()">
          </div> -->

          <!-- Text -->
          <div class="settings-option user-text" *ngIf="editField === 'userTextElement'" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].burnIn[' + encoderIndex + '].' + editField + '.text')">
            <div class="label">Text</div>
            <input class="input" [(ngModel)]="settings.channels[deckChannel].burnIn[encoderIndex][editField].text" (blur)="updateSettings()">
          </div>
  
        </div>

        <!-- Placeholder -->
        <div class="overlay-placeholder" *ngIf="!editField?.length">
          <div>Please select element for start editing</div>
        </div>
  
      </div>
  
    </div>
  
  </div>


</div>
