import { Injectable } from '@angular/core';

// RxJS
import { Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

// Decorators
import { warmUpObservable } from '@decorators';

// Services
import { NetworkService } from '@modules/core/services/network.service';
import { DeckService } from '@modules/deck/services/deck.service';

// Types
import { Stream } from '@modules/stream/types/stream';
import { Deck } from '@modules/deck/types/deck';
import { RS422Info } from '../types/rs422-info';
import { RS422Timecodes } from '../types/rs422-timecodes';
import { RS422PlaybackMode } from '../types/rs422-settings';
import { DeckError } from '@modules/deck/types/deck-error';
import { StreamService } from '@modules/stream/services/stream.service';

@Injectable({
  providedIn: 'root'
})
export class Rs422Service {

  /**
   * Constructor
   */

  constructor(
    private networkService: NetworkService,
    private deckService: DeckService,
    private streamService: StreamService
  ) { }

  /**
   * WebSocket Events
   */

   remoteStatus(stream: Stream): Observable<RS422Info> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.remoteStatus(deck)
      .pipe(
        filter(result => result.index === stream.deckChannel),
        map(result => result.status)
      );
  }

  remoteTimecodes(stream: Stream): Observable<RS422Timecodes> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.remoteTimecodes(deck)
      .pipe(
        filter(timecodes => (timecodes as any).index === stream.deckChannel)
      );
  }

  modeChanged(stream: Stream): Observable<number> {
    if (!stream) {
      return of(null);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.deckService.rs422ModeChanged(deck)
      .pipe(
        filter(result => result.index === stream.deckChannel),
        map(result => result.mode),
      );
  }

  /**
   * Methods
   */

  getRemoteMode(stream: Stream): Observable<number> {
    if (!stream) {
      return of(0);
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) {
      return of(0);
    }
    return this.networkService.getRemoteMode(Deck.getAddress(deck), stream.deckChannel);
  }

  updateRemoteMode(stream: Stream, mode: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.updateRemoteMode(Deck.getAddress(deck), stream.deckChannel, mode);
  }

  updateRemotePlaybackMode(stream: Stream, mode: RS422PlaybackMode): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.updateRemotePlaybackMode(Deck.getAddress(deck), stream.deckChannel, mode);
  }
 
  getInfo(stream: Stream): Observable<RS422Info> {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return of(null); }
    return this.networkService.rss422Info(Deck.getAddress(deck), stream.deckChannel);
  }

  getTimecodes(stream: Stream): Observable<RS422Timecodes> {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return of(null); }
    return this.networkService.rss422Timecodes(Deck.getAddress(deck), stream.deckChannel);
  }

  play(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Play(Deck.getAddress(deck), stream.deckChannel);
  }

  playReverse(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422PlayReverse(Deck.getAddress(deck), stream.deckChannel);
  }
 
  stop(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Stop(Deck.getAddress(deck), stream.deckChannel);
  }

  record(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Record(Deck.getAddress(deck), stream.deckChannel);
  }

  @warmUpObservable
  recordServo(stream: Stream): Observable<void> {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    return this.networkService.rss422RecordServo(Deck.getAddress(deck), stream.deckChannel)
      .pipe(
        catchError(error => {
          if (error) {
            const recordError = DeckError.create(error?.error?.message, stream.deckChannel);
            this.streamService.addError(recordError);
          }
          return of(null);
        })
      );
  }

  inAudioEntry(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422InAudioEntry(Deck.getAddress(deck), stream.deckChannel);
  }

  outAudioEntry(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422OutAudioEntry(Deck.getAddress(deck), stream.deckChannel);
  }

  inEntry(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422InEntry(Deck.getAddress(deck), stream.deckChannel);
  }

  outEntry(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422OutEntry(Deck.getAddress(deck), stream.deckChannel);
  }

  setInTimecode(stream: Stream, timecode: string): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422SetInTimecode(Deck.getAddress(deck), stream.deckChannel, timecode);
  }

  setOutTimecode(stream: Stream, timecode: string): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422SetOutTimecode(Deck.getAddress(deck), stream.deckChannel, timecode);
  }

  inReset(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422InReset(Deck.getAddress(deck), stream.deckChannel);
  }

  outReset(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422OutReset(Deck.getAddress(deck), stream.deckChannel);
  }

  audioInReset(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422AudioInReset(Deck.getAddress(deck), stream.deckChannel);
  }

  audioOutReset(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422AudioOutReset(Deck.getAddress(deck), stream.deckChannel);
  }

  cueToTimecode(stream: Stream, timecode: string, offset?: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422CueToTimecode(Deck.getAddress(deck), stream.deckChannel, timecode, offset);
  }

  // speed (from 0.0 to 100.0) on remote device
  jogForward(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422JogForward(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  jogReverse(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422JogReverse(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  varForward(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422VarForward(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  varReverse(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422VarReverse(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  shuttleForward(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422ShuttleForward(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  // speed (from 0.0 to 100.0) on remote device
  shuttleReverse(stream: Stream, speed: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422ShuttleReverse(Deck.getAddress(deck), stream.deckChannel, speed);
  }

  fastForward(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422FastForward(Deck.getAddress(deck), stream.deckChannel);
  }

  rewind(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Rewind(Deck.getAddress(deck), stream.deckChannel);
  }

  review(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Review(Deck.getAddress(deck), stream.deckChannel);
  }

  preview(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Preview(Deck.getAddress(deck), stream.deckChannel);
  }

  autoEdit(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422AutoEdit(Deck.getAddress(deck), stream.deckChannel, on);
  }

  standby(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Standby(Deck.getAddress(deck), stream.deckChannel, on);
  }

  setPrerollTime(stream: Stream, seconds: number): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422SetPrerollTime(Deck.getAddress(deck), stream.deckChannel, seconds);
  }

  getPrerollTime(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422GetPrerollTime(Deck.getAddress(deck), stream.deckChannel);
  }

  preroll(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Preroll(Deck.getAddress(deck), stream.deckChannel);
  }

  eject(stream: Stream): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422Eject(Deck.getAddress(deck), stream.deckChannel);
  }

  remoteEE(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422RemoteEE(Deck.getAddress(deck), stream.deckChannel, on);
  }

  autoMode(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422AutoMode(Deck.getAddress(deck), stream.deckChannel, on);
  }

  vitcBypass(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422VitcBypass(Deck.getAddress(deck), stream.deckChannel, on);
  }

  assambleEdit(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422AssambleEdit(Deck.getAddress(deck), stream.deckChannel, on);
  }

  insertVideo(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422InsertVideo(Deck.getAddress(deck), stream.deckChannel, on);
  }

  insertTc(stream: Stream, on: boolean): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422InsertTc(Deck.getAddress(deck), stream.deckChannel, on);
  }

  insertAudio(stream: Stream, audioChannelsToInsert: number[]): void {
    const deck = this.deckService.getDeckSync(stream.deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    this.networkService.rss422InsertAudio(Deck.getAddress(deck), stream.deckChannel, audioChannelsToInsert);
  }

}
