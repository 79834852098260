<div class="timecode-input-content">
  <!-- Input -->
  <input
    #timecodeInput
    [dropSpecialCharacters]="false"
    [specialCharacters]="[':', ';', '.']"
    [mask]="mask"
    class="input"
    [class.error]="!editing && error"
    [placeholder]="placeholder"
    [(ngModel)]="timecode"
    (ngModelChange)="validate($event)"
    (focus)="editing = true"
    (focusout)="editing = false"
    (keydown.enter)="jumpTC(timecode)"
    (keydown.esc)="close()"
    (keydown.arrowup)="nextFrame()"
    (keydown.arrowdown)="prevFrame()"
    [matTooltip]="error?.message"
    [matTooltipDisabled]="editing || !error"
  >
  <!-- Save -->
  <div class="button" (click)="save()" [class.disabled]="!timecode?.length || error">{{ buttonTitle }}</div>
</div>
