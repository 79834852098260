import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Service
import { ProjectService } from '@modules/project/services/project.service';
import { NodeService } from '@modules/node/services/node.service';

// Types
import { MatDialogRef } from '@angular/material/dialog';
import { Project } from '@modules/project/types/project';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.less']
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {

  // Public
  public alertMessage = {
    exportSuccess: false,
    importSuccess: false,
    exportError: null,
    importError: null,
  };
  public isWeb = false;
  public project: Project;

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private projectService: ProjectService,
    private nodeService: NodeService,
    public dialogRef: MatDialogRef<ProjectSettingsComponent>,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.isWeb = !this.nodeService.isElectron;
    this.projectService.getProject()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(project => this.project = project);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  exportProjectSettings(): void {
    this.projectService.exportSettings()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(error => {
        this.alertMessage.exportSuccess = !error;
        this.alertMessage.exportError = error;
      });
  }

  importProjectSettings(): void {
    this.projectService.importSettings()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(error => {
        this.alertMessage.importSuccess = !error;
        this.alertMessage.importError = error;
      });
  }

  importProjectSettingFile(event): void {
    const file = event.target?.files[0];
    if (file) {
      this.projectService.importSettingFile(file);
      this.alertMessage.importSuccess = true;
    }
  }

  checkDebug(value: boolean): void {
    this.project.debug = value;
    this.projectService.updateProject(this.project);
  }

  close(): void {
    this.dialogRef.close();
  }

}
