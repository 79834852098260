<div class="preset-settings-timecode-content">

  <!-- Options -->
  <div class="timecode-options">

    <!-- Disabled Status -->
    <div class="settings-status-text" *ngIf="recording">Settings unavailable during recording</div>
    <div class="settings-status-text" *ngIf="!deckOnline">Settings are not available if there is no connection</div>
    <div class="settings-status-text" *ngIf="preset && !preset?.isValid()">
      <mat-icon svgIcon="warning" class="icon error"></mat-icon>
      Selected preset is from a prior version: please delete and create a new one.
    </div>

    <!-- 1 -->
    <div class="row">

      <!-- Record Control -->
      <app-preset-settings-option title="Record Control" key="automation.trigger" [options]="filtredSettingsOptions.recordControl" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Record TC Trigger -->
      <!-- Manual - Like encoder TC Source (manualParams -> source) -->
      <app-preset-settings-option *ngIf="settings?.automation.trigger === 'no'" title="Record TC Trigger" key="automation.manualParams.source" [options]="filtredSettingsOptions.encoderTcSource" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>
      <!-- External Device -->
      <app-preset-settings-option *ngIf="settings?.automation.trigger === 'device'" title="Device" key="automation.deviceParams.source" [options]="filtredSettingsOptions.recordTcTriggerDevice" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>
      <!-- EDL - Like encoder TC Source (edlParams -> source)  -->
      <app-preset-settings-option *ngIf="settings?.automation.trigger === 'edl'" title="Trigger TC Source" key="automation.edlParams.source" [options]="filtredSettingsOptions.encoderTcSource" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>
      <!-- Input rec runs - (recRunParams -> frames  min:4 max:15 step: 1) -->
      <div *ngIf="settings?.automation.trigger === 'input_rec_run'" class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('automation.recRunParams.frames')">
        <div class="preset-settings-title-big">Wait frames</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" min="4" max="15" [(ngModel)]="settings?.automation.recRunParams.frames" (blur)="updateSettings()">
        </div>
      </div>

      <!-- LTC Source -->
      <app-preset-settings-option title="LTC Source" [key]="'channels[' + deckChannel + '].ltcSource'" [options]="filtredSettingsOptions.ltcSources" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || !stream" [highlightInherit]="false" (selectedChange)="updateLtcSources($event.value)" placeholder="None"></app-preset-settings-option>

      <!-- USB TC Reader Mode -->
      <!-- Disabled -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">USB TC Reader Mode</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.inputTypes" [selected]="null" (selectedChange)="updateSelect('inputTypes', $event.value)" placeholder="None"></app-select>
        </div>
      </div>

      <!-- UI Display TC Source -->
      <!-- Options Same like Record TC Source -->
      <app-preset-settings-option title="UI Display TC Source" key="tcSource" [options]="filtredSettingsOptions.encoderTcSource" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

    </div>

    <!-- 2 -->
    <div class="row">

      <!-- Gen TC Mode -->
      <app-preset-settings-option title="Gen TC Mode" key="automation.genTCType" [options]="filtredSettingsOptions.genTCType" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Preset TC value -->
      <!-- automation.genTCSource -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">Preset TC value</div>
        <div class="multi-tile">
          <input class="input full-tile-select" placeholder="--:--:--;--" (blur)="updateSettings()">
        </div>
      </div>

      <!-- TC Counter Reset -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">TC Counter Reset</div>
        <div class="multi-tile">
          <div class="button tile-button">Reset</div>
        </div>
      </div>

      <!-- DF/ NDF -->
      <app-preset-settings-option title="DF/ NDF" key="automation.genTCDropFlag" [options]="filtredSettingsOptions.genTCDropFlag" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled || !(settings?.input?.activeFrameRate === '29.97' || settings?.input?.activeFrameRate === '59.94')" (selectedChange)="updateSelect($event.key, $event.value)" matTooltip="Only available for '29.97' and '59.94' fps" [matTooltipDisabled]="disabled || (settings?.input?.activeFrameRate === '29.97' || settings?.input?.activeFrameRate === '59.94')"></app-preset-settings-option>

      <!-- Capture Delay -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">Capture Delay</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.inputTypes" [selected]="null" (selectedChange)="updateSelect('inputTypes', $event.value)" placeholder="None"></app-select>
        </div>
      </div>

      <!-- Checkbox -->
      <!-- automation.proTools -->
      <div class="settings-text">
        <mat-checkbox class="checkbox" [class.inherit]="!isDefaultSetting('automation.proTools')" [disabled]="disabled" [(ngModel)]="settings?.automation.proTools" (ngModelChange)="updateSettings()" disableRipple>ProTools</mat-checkbox>
      </div>

    </div>

    <!-- 3 -->
    <div class="row">

      <!-- SDI Offset -->
      <!-- automation.sdiOffset -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('automation.sdiOffset')">
        <div class="preset-settings-title-big">SDI Offset</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" [(ngModel)]="settings?.automation.sdiOffset" (blur)="updateSettings()">
        </div>
      </div>

      <!-- NDI Offset -->
      <!-- automation.ndiOffset -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('automation.ndiOffset')">
        <div class="preset-settings-title-big">NDI Offset</div>
        <div class="multi-tile"> 
          <input class="input full-tile-select" type="number" [(ngModel)]="settings?.automation.ndiOffset" (blur)="updateSettings()">
        </div>
      </div>

      <!-- Gen TC Offset -->
      <!-- automation.genTcOffset -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('automation.genTcOffset')">
        <div class="preset-settings-title-big">Gen TC Offset</div>
        <div class="multi-tile"> 
          <input class="input full-tile-select" type="number" [(ngModel)]="settings?.automation.genTcOffset" (blur)="updateSettings()">
        </div>
      </div>

      <!-- LTC Offset -->
      <!-- automation.extLtc1Offset & automation.extLtc2Offset -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('automation.extLtc1Offset')">
        <div class="preset-settings-title-big">LTC Offset</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" [(ngModel)]="settings?.automation.extLtc1Offset" (blur)="updateSettings()">
        </div>
      </div>

      <!-- USB LTC Offset -->
      <!-- ? -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('automation.extLtc2Offset')">
        <div class="preset-settings-title-big">USB LTC Offset</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" [(ngModel)]="settings?.automation.extLtc2Offset" (blur)="updateSettings()">
        </div>
      </div>

      <!-- SDI TC Offset -->
      <!-- ? -->
      <div class="settings-option" [class.disabled]="disabled || true">
        <div class="preset-settings-title-big">SDI TC Offset</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" [ngModel]="0" (blur)="updateSettings()">
        </div>
      </div>

      <!-- IRIG Offset -->
      <!-- irigOffset -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('automation.irigOffset')">
        <div class="preset-settings-title-big">IRIG Offset</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" [(ngModel)]="settings?.automation.irigOffset" (blur)="updateSettings()">
        </div>
      </div>

    </div>

    <!-- 4 -->
    <div class="row">

      <!-- RS422 COM Port -->
      <!-- channels[index].comPort (min: 1 max: 256 ) -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].comPort')">
        <div class="preset-settings-title-big">RS422 COM Port</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" min="1" max="256" [(ngModel)]="settings?.channels[deckChannel].comPort" (blur)="updateSettings()">
        </div>
      </div>

      <!-- RS422 Mode -->
      <div class="settings-option" [class.disabled]="disabled || !stream">
        <div class="preset-settings-title-big">RS422 Mode</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="filtredSettingsOptions.rs422Mode" [selected]="rs422" (selectedChange)="changeRS422($event.value)"></app-select>
        </div>
      </div>

      <!-- Deck Emulation -->
      <div class="settings-option deck-emulation" [class.disabled]="disabled || !stream">
        <div class="preset-settings-title-big">Deck Emulation</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="deskEmulationOptions" [selected]="getDeskEmulationOption(settings?.currentDeskEmulation)" (selectedChange)="updateSelect('currentDeskEmulation', $event.value)" placeholder="None"></app-select>
        </div>
      </div>

      <!-- Preroll (Seconds) -->
      <!-- channels[index].preroll (min: 0 max: 59 default: 5) -->
      <div class="settings-option" [class.disabled]="disabled" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].preroll')">
        <div class="preset-settings-title-big">Preroll (Seconds)</div>
        <div class="multi-tile">
          <input class="input full-tile-select" type="number" min="0" max="59" [(ngModel)]="settings?.channels[deckChannel].preroll" (blur)="updateSettings()">
        </div>
      </div>

      <!-- Jog Mode -->
      <!-- channels[index].remoteCtrl.jogModeStandard -->
      <!-- true - Standard -->
      <!-- false - Time-based -->
      <app-preset-settings-option title="Jog Mode" [key]="'channels[' + deckChannel + '].remoteCtrl.jogModeStandard'" [options]="filtredSettingsOptions.jogMode" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

      <!-- Sensitivity -->
      <!-- channels[index].remoteCtrl.jogSensitive (min: 1 max: 5) -->
      <app-preset-settings-option title="Sensitivity" [key]="'channels[' + deckChannel + '].remoteCtrl.jogSensitive'" [options]="filtredSettingsOptions.jogSensitive" [settings]="settings" [streamSettings]="streamSettings" [disabled]="disabled" (selectedChange)="updateSelect($event.key, $event.value)"></app-preset-settings-option>

    </div>

    <!-- 5 -->
    <div class="row">

      <!-- Checkbox -->
      <div class="settings-text">
        <mat-checkbox class="checkbox" [class.inherit]="!isDefaultSetting('channels[' + deckChannel + '].useGOPTimecode')" [disabled]="disabled" [(ngModel)]="settings?.channels[deckChannel].useGOPTimecode" (ngModelChange)="updateSettings()" disableRipple>Use GOP Timecode for XDCAM</mat-checkbox>
      </div>

      <!-- Checkbox -->
      <!-- automation.showIrigDateEnabled -->
      <div class="settings-text">
        <mat-checkbox class="checkbox" [class.inherit]="!isDefaultSetting('automation.showIrigDateEnabled')" [disabled]="disabled" [(ngModel)]="settings?.automation.showIrigDateEnabled" (ngModelChange)="updateSettings()" disableRipple>Show IRIG Date in UI TC Display</mat-checkbox>
      </div>

      <!-- Checkbox -->
      <!-- automation.forceRP188 -->
      <div class="settings-text">
        <mat-checkbox class="checkbox" [class.inherit]="!isDefaultSetting('automation.forceRP188')" [disabled]="disabled" [(ngModel)]="settings?.automation.forceRP188" (ngModelChange)="updateSettings()" disableRipple>Force Read SDI RP188 TC</mat-checkbox>
      </div>

    </div>

  </div>

</div>
