import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { UserService } from '@modules/users/services/user.service';

// Types
import { ChannelsGroup } from '@modules/channel/types/channels-group';
import { Channel } from '@modules/channel/types/channel';

@Component({
  selector: 'app-channels-groups',
  templateUrl: './channels-groups.component.html',
  styleUrls: ['./channels-groups.component.less']
})
export class ChannelsGroupsComponent implements OnInit, OnDestroy {

  // Public
  public groups: ChannelsGroup[];
  public selected: ChannelsGroup;
  public locked = false;
  public allowed: boolean;
  public selectedChannels: Channel[];
  public popoverClose = new Subject<void>();
  public groupsNames: string[] = [];

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private channelsService: ChannelService,
    userService: UserService
  ) {
      userService
        .getCurrentPermission('editSelectionsGroups')
        .pipe(takeUntil(this.alive))
        .subscribe(allowed => this.allowed = allowed);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.channelsService.selectChannels([]);
    this.channelsService.getChannelsGroups()
      .pipe(takeUntil(this.alive))
      .subscribe(groups => {
        this.groups = groups;
        this.groupsNames = this.groups.map(group => group.name);
      });

    this.channelsService.getSelectedChannels()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(channels => {
        this.selectedChannels = channels;
        if (this.selected && (
          this.selected.channelIds.length !== this.selectedChannels.length ||
          this.selectedChannels.filter(channel => !this.selected.channelIds.includes(channel.id)).length
        )) {
          this.selected = null;
        }
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  select(group: ChannelsGroup): void {
    this.selected = this.selected === group ? null : group;
    this.channelsService.selectChannels(group.channelIds.map(channelId => ({ id: channelId })));
  }

  createGroup(name: string): void {
    if (!this.selectedChannels?.length) {
      return;
    }
    this.stateService.updateState({
      channelsGroups: [
        ...this.groups,
        new ChannelsGroup(name, this.selectedChannels)
      ]
    });
  }

  deleteGroup(group: ChannelsGroup): void {
    if (!group) {
      return;
    }
    this.stateService.updateState({
      channelsGroups: this.groups.filter(channelsGroup => channelsGroup.id !== group.id)
    });
  }

}
