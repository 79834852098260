import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// Services
import { LayoutService } from '@modules/layout/services/layout.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@modules/users/services/user.service';
import { NodeService } from '@modules/node/services/node.service';

// Components
import { UsersPermissionsComponent } from '@modules/users/components/users-permissions/users-permissions.component';
import { ProjectSettingsComponent } from '@modules/project/components/project-settings/project-settings.component';

// Types
import { Layout } from '@modules/layout/types/layout';
import { User } from '@modules/users/types/user';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.less']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  // Public
  public selected = true;
  public layout: Layout;
  public user: User;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private userService: UserService,
    private nodeService: NodeService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Layout
    this.layoutService.getLayout()
      .pipe(
        tap(layout => {
          if (layout.globalLists === undefined || layout.globalLists === null) {
            layout.userLists = layout['lists'] ?? true;
            layout.globalLists = layout.userLists;
            this.layoutService.updateLayout(layout);
          }
        }),
        takeUntil(this.alive)
      )
      .subscribe(layout => this.layout = layout);
    // Get User
    this.userService.getCurrentUser()
      .pipe(takeUntil(this.alive))
      .subscribe(user => this.user = user);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  toggleView(view: string): void {
    this.layout[view] = !this.layout[view];
    this.layoutService.updateLayout(this.layout);
  }

  openUserPermissions(): void {
    this.dialog.open(UsersPermissionsComponent, { autoFocus: false, disableClose: true });
  }

  logout(): void {
    this.userService.logout();
  }

  openHelp(): void {
    this.nodeService.openLink('https://cinedeck.com/support/');
  }

  openManual(): void {
    this.nodeService.openLink('https://cinedeck.atlassian.net/wiki/spaces/C2C/overview')
  }

  openProjectSettings(): void {
    this.dialog.open(ProjectSettingsComponent, { autoFocus: false, disableClose: true });
  }

}
