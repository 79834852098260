import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { UserService } from '@modules/users/services/user.service';
import { Rs422Service } from '@modules/rs422/services/rs422.service';

// Components
import { PresetSettingsBaseComponent } from '../preset-settings-base/preset-settings-base.component';

// Types
import { PresetSettingsOptions } from '@modules/preset/types/preset-settings-options';
import { Option } from '@modules/elements/types/option';

@Component({
  selector: 'app-preset-settings-timecode',
  templateUrl: './preset-settings-timecode.component.html',
  styleUrls: ['./preset-settings-timecode.component.less']
})
export class PresetSettingsTimecodeComponent extends PresetSettingsBaseComponent implements OnInit, OnChanges, OnDestroy {

  // Public
  public filtredSettingsOptions: PresetSettingsOptions;
  public deskEmulationOptions: Option<number>[];
  public rs422: number = 0;

  // Private
  private requestSettingsOptions = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    protected presetSettingsService: PresetSettingsService,
    protected streamService: StreamService,
    private rs422Service: Rs422Service,
    userService: UserService
  ) {
    super(presetSettingsService, userService);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if ('stream' in changes || 'settingsOptions' in changes || 'settings') {
      this.filtredSettingsOptions = JSON.parse(JSON.stringify(this.settingsOptions));
      this.updateSettingsOptions();
    }
  }

  ngOnDestroy(): void {
    this.requestSettingsOptions.next();
    this.requestSettingsOptions.complete();
    super.ngOnDestroy();
  }

  /**
   * Methods
   */

  updateSettingsOptions(): void {
    this.requestSettingsOptions.next();

    // Record Control
    this.streamService.getSettingsRecordControlTCSources(this.stream)
      .pipe(takeUntil(this.requestSettingsOptions))
      .subscribe(sources => this.filtredSettingsOptions.recordTcTriggerDevice = this.settingsOptions.recordTcTriggerDevice.filter(item => sources.includes(item.value)));

    // LTC Sources
    this.streamService.getSettingsLtcSources(this.stream)
      .pipe(takeUntil(this.requestSettingsOptions))
      .subscribe(sources => this.filtredSettingsOptions.ltcSources = this.settingsOptions.ltcSources.filter(item => sources.includes(item.value)));

    // Desk Emulation
    this.streamService.getSettingsDeskEmulation(this.stream)
      .pipe(takeUntil(this.requestSettingsOptions))
      .subscribe(desks => this.deskEmulationOptions = desks.map(item => new Option(item.value, item.name)));

    // TC Sources
    this.streamService.getInputs(this.stream)
      .pipe(
        takeUntil(this.requestSettingsOptions),
        map(inputs => {
          const inputType = this.settings?.input?.activeVideoInput;
          return inputs[inputType]?.tcSources ?? [];
        }),
      )
      .subscribe(tcSources => this.filtredSettingsOptions.encoderTcSource = this.settingsOptions.encoderTcSource.filter(item => tcSources.includes(item.value)));
    
    // Get RS422
    this.rs422Service.getRemoteMode(this.stream)
      .pipe(
        takeUntil(this.requestSettingsOptions),
      )
      .subscribe(mode => this.rs422 = mode);
  }

  updateLtcSources(value: number): void {
    if (this.settings?.channels) {
      this.settings.channels.forEach(channel => {
        if (channel) {
          channel.ltcSource = value;
        }
      });
      this.updateSettings();
    }
  }

  getDeskEmulationOption(value: number): Option<number> {
    return this.deskEmulationOptions?.find(option => option.value === value);
  }
  
  changeRS422(value: number): void {
    this.rs422 = value;
    this.rs422Service.updateRemoteMode(this.stream, this.rs422);
    setTimeout(() => this.updateSettingsOptions(), 100);
  }

}
