<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Configure Input Sources</div>
    <div class="actions">
      <mat-icon svgIcon="plus" class="action-icon" (click)="addDeck()"></mat-icon>
      <mat-icon svgIcon="server" class="action-icon deck-list-icon" (click)="openDeckList()"></mat-icon>
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="stream-settings-content">

    <!-- Table -->
    <div class="streams-table">

      <!-- Header -->
      <div class="header">
        <div class="title status">Status</div>
        <div class="title deck">Deck</div>
        <div class="title port">Input ID</div>
        <div class="title stream-name">Input/Feed Name</div>
        <!-- <div class="title timecode">TC</div> -->
      </div>

      <!-- Content -->
      <div class="content">
        <div class="row" *ngFor="let stream of streams; let index = index">
          <!-- <div class="timecode">
            <mat-checkbox class="checkbox" [disabled]="!stream" [ngModel]="false" disableRipple></mat-checkbox>
          </div> -->
          <div class="status">
            <div *ngIf="stream && stream.deckChannelName && (getOnlineStatus(stream) | async) === 'online'" class="status-info">
              <div class="status-rect online"></div>
              <div class="text">{{ 'Online' }}</div>
            </div>
            <div *ngIf="stream && stream.deckChannelName && (getOnlineStatus(stream) | async) === 'offline'" class="status-info">
              <div class="status-rect offline"></div>
              <div class="text">{{ 'Offline' }}</div>
            </div>
            <div *ngIf="stream && stream.deckChannelName && (getOnlineStatus(stream) | async) === 'unavailable'" class="status-info">
              <div class="status-rect unavailable"></div>
              <div class="text">{{ 'N/A' }}</div>
            </div>
            <div *ngIf="!stream || !stream.deckChannelName" class="status-info">
              <div class="status-rect"></div>
              <div class="text">{{ '- -' }}</div>              
            </div>
          </div>
          <div class="deck">
            <app-select class="select" [options]="decksOptions" [selected]="getOptionForDeck(stream?.deckId)" (selectedChange)="selectDeck($event, stream)" [disabled]="index > 0 && !streams[index-1]"></app-select>
          </div>
          <div class="port">
            <app-select class="select" [options]="inputsOptions[stream?.deckId]" [selected]="getOptionForInputs(stream)" [disabledOptions]="inputsDisabledOptions[stream?.deckId]" (selectedChange)="selectInput($event, stream)" [disabled]="!stream"></app-select>
          </div>
          <div class="stream-name">
            <div class="input stream-name-input" *ngIf="stream?.deckId && stream?.deckChannelName" [class.disabled]="!stream">{{ getDeckName(stream?.deckId) + ' - ' +  stream?.deckChannelName}}</div>
            <div class="input stream-name-input" *ngIf="!(stream?.deckId && stream?.deckChannelName)" [class.disabled]="!stream">--</div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
