import { DroppableArea } from './droppable-area';
import { DragData } from './drag-data';

export class DraggableEvent {
  point?: { x: number, y: number };
  dropArea?: DroppableArea;
  dragData?: DragData;

  constructor(point?: { x: number, y: number }, dropArea?: DroppableArea, dragData?: DragData) {
    this.point = point;
    this.dropArea = dropArea;
    this.dragData = dragData;
  }

  getPointInsideArea(scrollOffsetX: number = 0, scrollOffsetY: number = 0): { x: number, y: number } {
    if (
      this.dropArea.x < this.point.x && this.dropArea.x + this.dropArea.width > this.point.x &&
      this.dropArea.y < this.point.y && this.dropArea.y + this.dropArea.height > this.point.y
    ) {
      const x = this.point.x - this.dropArea.x + scrollOffsetX;
      const y = this.point.y - this.dropArea.y + scrollOffsetY;
      return {x, y};
    }
    return null;
  }
}
