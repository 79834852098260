import { Injectable } from '@angular/core';

// RxJS
import { Observable, of } from 'rxjs';

// Services
import { NetworkService } from '@modules/core/services/network.service';
import { DeckService } from '@modules/deck/services/deck.service';

// Types
import { Deck } from '@modules/deck/types/deck';
import { File } from '../types/file';
import { Clip } from '@modules/deck/types/clip';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  /**
   * Constructor
   */

  constructor(
    private networkService: NetworkService,
    private deckService: DeckService
  ) { }

  /**
   * Methods
   */

  getFiles(deckId: string, path: string): Observable<File[]> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of([]);
    }
    return this.networkService.getFiles(Deck.getAddress(deck), path);
  }

  getIndexedFiles(deckId: string, paths?: string[]): Observable<Clip[]> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of([]);
    }
    return this.networkService.getIndexedFiles(Deck.getAddress(deck), paths);
  }

  postIndexedFiles(deckId: string, files: string[]): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.postIndexedFiles(Deck.getAddress(deck), files);
  }

  getClip(deckId: string, path: string): Observable<Clip> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.getClip(Deck.getAddress(deck), path);
  }

  createDir(deckId: string, path: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.createDir(Deck.getAddress(deck), path);
  }

  updateDir(deckId: string, oldPath: string, newPath: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.updateDir(Deck.getAddress(deck), oldPath, newPath);
  }

  deleteDir(deckId: string, path: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.deleteDir(Deck.getAddress(deck), path);
  }

  updateFile(deckId: string, oldPath: string, newPath: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.updateFile(Deck.getAddress(deck), oldPath, newPath);
  }

  deleteFile(deckId: string, path: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck || !this.deckService.isOnline(deck)) {
      return of(null);
    }
    return this.networkService.deleteFile(Deck.getAddress(deck), path);
  }

  /**
   * Helpers
   */

  importDropedFiles(event: DragEvent): string[] {
    if (event.dataTransfer && event.dataTransfer.files) {
      const files = event.dataTransfer.files;
      const paths = [];
      for (let i = 0; i < files.length; i++) {
        paths.push(files[i].path);
      }
      return paths;
    }
    return [];
  }


}
