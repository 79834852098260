import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

// Lodash
import { isEqual } from 'lodash-es';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { StreamService } from '@modules/stream/services/stream.service';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';
import { NodeService } from '@modules/node/services/node.service';

// Components
import { FileManagerComponent } from '../file-manager/file-manager.component';
import { PlaylistNameDialogComponent } from '@modules/playlist-editor/components/playlist-name-dialog/playlist-name-dialog.component';
import { PlaylistManagerComponent } from '@modules/playlist-editor/components/playlist-manager/playlist-manager.component';

// Types
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Stream } from '@modules/stream/types/stream';
import { SelectionModel } from '@angular/cdk/collections';
import { Clip } from '@modules/deck/types/clip';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Deck } from '@modules/deck/types/deck';
import { StreamStatus } from '@modules/stream/types/stream-status';
import { PathPipe } from '@modules/elements/pipes/path.pipe';


@Component({
  selector: 'app-clip-manager',
  templateUrl: './clip-manager.component.html',
  styleUrls: ['./clip-manager.component.less']
})
export class ClipManagerComponent implements OnInit, AfterViewInit, OnDestroy {

  // ViewChild 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PlaylistManagerComponent, { static: false }) playlistManagerComponent: PlaylistManagerComponent;

  // Public
  public stream: Stream;
  public clips: Clip[] = [];
  public openedClips: number[] = [];
  public currentOpenClip: number;
  public selection = new SelectionModel<Clip>(true, []);
  public error: Error;
  public displayedColumns = ['name', 'folder', 'startTimecode', 'duration', 'codec', 'wrapper', 'quality', 'resolution', 'fps', 'audioChannels'];
  public dataSource = new MatTableDataSource(this.clips);
  public status: StreamStatus;
  public folderPath = null;
  public thumbnailUrl: string;
  public search: string;
  public errors = {
    invalidCreatePlaylist: false,
    invalidAddPlaylist: false,
  }

  // Private
  private alive = new Subject();
  private getClips = new Subject();

  /**
   * Constructor
   */

  constructor(
    private fileManagerService: FileManagerService,
    private deckService: DeckService,
    private streamService: StreamService,
    private dialog: MatDialog,
    private playlistService: PlaylistService,
    private nodeService: NodeService,
    private pathPipe: PathPipe,
    public dialogRef: MatDialogRef<ClipManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      stream: Stream
    },
  ) {
    this.stream = data.stream;
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get clips
    this.getClips
      .pipe(
        switchMap(() => this.fileManagerService.getIndexedFiles(this.stream.deckId)),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(clips => {
        this.clips = clips;
        this.clips.forEach((clip, index) => {
          clip['folder'] = this.pathPipe.transform(clip.drivePath + clip.dirPath);
        });
        this.dataSource.data = this.clips;
      });

    // Handle new clips
    const deck = this.deckService.getDeckSync(this.stream.deckId);
    this.thumbnailUrl = Deck.getAddress(deck) + '/thumbnail?id=';
    this.deckService.clipAdded(deck)
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(_ => this.getClips.next());
    this.getClips.next();

    // Get clips in playlist
    this.streamService.getStatus(this.stream)
      .pipe(
        map(status => status.playback.openedClips),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(openedClips => this.openedClips = openedClips);

    // Get current open file
    this.streamService.getStatus(this.stream)
      .pipe(
        map(status => status.playback.currnetClip),
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(currentOpenClip => this.currentOpenClip = currentOpenClip);

    // Scan in progress
    this.streamService.getStatus(this.stream)
    .pipe(
      takeUntil(this.alive),
    )
    .subscribe(status => {
      this.status = status;
    });

    // Filter Table
    this.dataSource.filterPredicate = (clip: Clip, filterJson: string) => {
      // Params
      const filter = JSON.parse(filterJson);
      const folder = filter.folder;
      const search = filter.search?.toLocaleLowerCase();
      const clipPath = this.nodeService.path.win32.normalize(clip?.drivePath + clip?.dirPath);

      // Filter by Folder
      let matchFolder = !folder || !folder?.length;
      if (!matchFolder) {
        matchFolder = clipPath === folder;
      }

      // Filter by Search
      let matchSearch = !search || !search?.length;
      if (!matchSearch) {
        matchSearch =
          clipPath.toLocaleLowerCase().includes(search) ||
          clip.name.toLocaleLowerCase().includes(search) ||
          clip.codec.toLocaleLowerCase().includes(search) ||
          clip.wrapper.toLocaleLowerCase().includes(search) ||
          clip.quality.toLocaleLowerCase().includes(search) ||
          clip.resolution.toLocaleLowerCase().includes(search) ||
          clip.fps.toLocaleLowerCase().includes(search);
      }

      return matchFolder && matchSearch;
     };
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.playlistManagerComponent?.selection.changed.asObservable()
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.validateSelectedFiles());
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  validateSelectedFiles(): void {
    const clips = this.selection.selected;
    const playlist = this.playlistManagerComponent?.selection?.selected[0];
    this.errors.invalidCreatePlaylist = !clips?.length || !this.playlistService.validateClips(clips);
    this.errors.invalidAddPlaylist = this.errors.invalidCreatePlaylist || !playlist || !this.playlistService.validateClipsForPlaylist(clips, playlist);
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }

  selectFile(event: MouseEvent, clip: Clip): void {
    if (!event.shiftKey) {
      this.selection.clear();
    }
    this.selection.toggle(clip);
    this.validateSelectedFiles();
  }

  openFile(event: MouseEvent, clip: Clip): void {
    this.dialogRef.close({event: 'select', value: [clip.id]});
  }

  select(): void {
    const value = this.selection.selected.map(clip => clip.id);
    this.dialogRef.close({event: 'select', value});
  }

  addClips(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: false, singleSelect: false}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          this.fileManagerService.postIndexedFiles(this.stream.deckId, result.value);
        }
      });
  }

  scanPlaylist(): void {
    this.dialog.open(FileManagerComponent, {data: {stream: this.stream, onlyDir: true, singleSelect: true}, disableClose: true, autoFocus: false})
      .afterClosed()
      .subscribe(result => {
        if (result?.event === 'select' && result?.value?.length) {
          const folderPath = result?.value[0];
          this.playlistService.scanPlaylist(this.stream.deckId, folderPath);
        }
      });
  }

  createPlaylist(clip?: Clip): void {
    let clips = this.selection.selected;
    if (clip && !clips.includes(clip)) {
      clips = [clip];
    }
    this.dialog.open(PlaylistNameDialogComponent, {data: {stream: this.stream}, disableClose: true, autoFocus: true})
      .afterClosed()
      .subscribe(result => {
        if (result?.fileName) {
          let drive, folderPath: string;
          if (result?.filePath) {
            drive = result?.filePath.substring(0, 1);
            folderPath = result?.filePath.substring(2, result?.filePath?.length);
          }
          this.playlistService.createPlaylist(this.stream.deckId, clips, result?.fileName, drive, folderPath);
        }
      });
  }

  addToPlaylist(clip?: Clip): void {
    const clips = this.selection.selected;
    const playlist = this.playlistManagerComponent?.selection?.selected[0];
    if (clips && playlist) {
      this.playlistService.addFileToPlaylist(this.stream.deckId, clips, playlist);
    }
  }

  selectFolder(path: string): void {
    this.folderPath = path;
    this.applyFilter();
  }

  applyFilter(): void {
    const filter = {
      folder: this.folderPath,
      search: this.search
    };
    this.dataSource.filter = JSON.stringify(filter);
  }

}
