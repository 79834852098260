<div class="file-info-content">
  <!-- Name -->
  <div class="file-name">{{ clip?.name }}</div>

  <!-- Timeline -->
  <div class="separator"></div>
  <div class="file-timeline">
    <mat-icon class="timeline-icon rotate" svgIcon="forward-end"></mat-icon>
    <div class="timeline-text">{{ clip?.startTimecode }}</div>
    <mat-icon class="timeline-icon" svgIcon="forward-end"></mat-icon>
    <div class="timeline-text">{{ clip?.endTimecode }}</div>
    <mat-icon class="timeline-icon" svgIcon="clock"></mat-icon>
    <div class="timeline-text">{{ clip?.duration }}</div>
  </div>
  <div class="separator"></div>

  <!-- Info -->
  <div class="file-info">
    <div class="row">
      <div class="label">Resolution:</div>
      <div class="value">{{ clip?.resolution }}</div>
    </div>
    <div class="row">
      <div class="label">Frame Rate:</div>
      <div class="value">{{ clip?.fps }} fps</div>
    </div>
    <div class="row">
      <div class="label">Video Codec:</div>
      <div class="value">{{ clip?.codec }}</div>
    </div>
    <div class="row">
      <div class="label">Video Quality:</div>
      <div class="value">{{ clip?.quality }}</div>
    </div>
    <div class="row">
      <div class="label">Video Wrapper:</div>
      <div class="value">{{ clip?.wrapper }}</div>
    </div>
    <div class="row">
      <div class="label">Color space:</div>
      <div class="value">{{ clip?.pixelFormat }}</div>
    </div>
    <!-- <div class="row">
      <div class="label">Audio Codec:</div>
      <div class="value">{{ file?.info?.audioCodec }}</div>
    </div> -->
    <div class="row">
      <div class="label">Audio Channels:</div>
      <div class="value">{{ clip?.audioChannels }}ch</div>
    </div>
    <!-- <div class="row">
      <div class="label">Sampling rate:</div>
      <div class="value">{{ file?.info?.audioSampleRate / 1000 }} kHz</div>
    </div>
    <div class="row">
      <div class="label">Bit depth:</div>
      <div class="value">{{ file?.info?.audioBPS  }} bits</div>
    </div> -->
  </div>
</div>
