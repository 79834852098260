<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Channel Settings</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="channels-settings-content">

    <div class="layout-settings" *ngIf="channel?.layoutSettings">

      <!-- Timecode -->
      <div class="settings-option">
        <div class="title">Timecode</div>
        <app-select class="select" [options]="options" [selected]="channel.layoutSettings.timecode" (selectedChange)="updateLayout('timecode', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Input -->
      <div class="settings-option">
        <div class="title">Input</div>
        <app-select class="select" [options]="options" [selected]="channel.layoutSettings.input" (selectedChange)="updateLayout('input', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Preset -->
      <div class="settings-option">
        <div class="title">Preset</div>
        <app-select class="select" [options]="options" [selected]="channel.layoutSettings.preset" (selectedChange)="updateLayout('preset', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Audio Levels -->
      <div class="settings-option">
        <div class="title">Audio Levels</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.audioLevels" (selectedChange)="updateLayout('audioLevels', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Disks -->
      <div class="settings-option">
        <div class="title">Disks and paths</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.disks" (selectedChange)="updateLayout('disks', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Disks update time -->
      <div class="settings-option">
        <div class="title"></div>
        <app-select class="select" [options]="diskTimeOptions" [selected]="channel.layoutSettings.disksTimeUpdate" (selectedChange)="updateLayout('disksTimeUpdate', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Record controls -->
      <div class="settings-option">
        <div class="title">Record controls</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.recordButtons" (selectedChange)="updateLayout('recordButtons', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Playback controls -->
      <div class="settings-option">
        <div class="title">Playback controls</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.playbackButtons" (selectedChange)="updateLayout('playbackButtons', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Playback countdown -->
      <div class="settings-option">
        <div class="title">Playback countdown</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.playbackCountdown" (selectedChange)="updateLayout('playbackCountdown', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- RS422 controls -->
      <div class="settings-option">
        <div class="title">RS422 controls</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.rs422Buttons" (selectedChange)="updateLayout('rs422Buttons', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Playback file name -->
      <div class="settings-option">
        <div class="title">Playback file name</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.playbackFilename" (selectedChange)="updateLayout('playbackFilename', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Encoder record queue -->
      <div class="settings-option">
        <div class="title">Encoder record queue frames</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.encoderRecordQueue" (selectedChange)="updateLayout('encoderRecordQueue', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Marker comment -->
      <div class="settings-option">
        <div class="title">Marker comment</div>
        <app-select class="select" [options]="allOptions" [selected]="channel.layoutSettings.markerComment" (selectedChange)="updateLayout('markerComment', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Progress Bar Marker -->
      <div class="settings-option">
        <div class="title">Progress Bar Markers</div>
        <app-select class="select" [options]="partialOptions" [selected]="channel.layoutSettings.markerProgressBar" (selectedChange)="updateLayout('markerProgressBar', $event)" placeholder="N/A"></app-select>
      </div>

      <!-- Auto play after clip loading -->
      <div class="settings-option">
        <mat-checkbox class="checkbox" [checked]="channel.layoutSettings.autoPlaybackLoadingFile" (change)="updateField('autoPlaybackLoadingFile', $event.checked)" disableRipple>Auto play after clip loading</mat-checkbox>
      </div>

    </div>

  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="copyToAllChannels()" [class.disabled]="false">Use for All Channels</div>
</div>
