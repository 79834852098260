import { Injectable } from '@angular/core';

// RxJS
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { NodeService } from '@modules/node/services/node.service';
import { NetworkService } from '@modules/core/services/network.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { NotificationService } from '@modules/elements/services/notification.service';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { Playlist, PlaylistClip } from '../types/playlist';
import { Stream } from '@modules/stream/types/stream';
import { Clip } from '@modules/deck/types/clip';
import { Deck } from '@modules/deck/types/deck';
import { Marker } from '@modules/marker/types/marker';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  /**
   * Constructor
   */

  constructor(
    private networkService: NetworkService,
    private deckService: DeckService,
    private nodeService: NodeService,
    private notificationService: NotificationService,
  ) { }

  /**
   * Helpers
   */

  validateClips(clips: Clip[]): boolean {
    if (!clips.length) {
      return false;
    }
    const firstClip = clips[0];
    for (const clip of clips) {
      if (clip.fps !== firstClip.fps ||
          clip.pixelFormat.toLocaleLowerCase() !== firstClip.pixelFormat.toLocaleLowerCase() ||
          clip.resolution.toLocaleLowerCase() !== firstClip.resolution.toLocaleLowerCase() ||
          clip.drop !== firstClip.drop
      ) {
          return false;
      }
    }

    return true;
  }

  validateClipsForPlaylist(clips: Clip[], playlist: Playlist): boolean {
    if (!clips.length) {
      return false;
    }
    for (const clip of clips) {
      if (clip.fps !== playlist.frameRate ||
          clip.pixelFormat.toLocaleLowerCase() !== playlist.pixelFormat.toLocaleLowerCase() ||
          clip.resolution.toLocaleLowerCase() !== playlist.resolution.toLocaleLowerCase() ||
          +clip.drop !== +playlist.drop
      ) {
          return false;
      }
    }

    return true;
  }

  /**
   * WebSocket Events
   */

  playlistsChanged(deckId: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    return this.deckService.playlistsChanged(deck);
  }

  /**
   * Methods
   */

  getPlaylists(deckId: string): Observable<Playlist[]> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    return this.networkService.getPlaylists(Deck.getAddress(deck));
  }

  getPlaylist(deckId: string, playlistName: string): Observable<Playlist> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!deck) { return of(null); }
    if (!this.deckService.isOnline(deck)) { return; }
    return this.networkService.getPlaylist(Deck.getAddress(deck), playlistName);
  }

  createPlaylist(deckId: string, clips: Clip[], name: string, drive?: string, folderPath?: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!clips.length || !this.validateClips(clips) || !this.deckService.isOnline(deck)) {
      return;
    }

    const clipsPath: string[] = clips.map(clip => this.nodeService.path.win32.normalize(clip?.drivePath + clip?.dirPath + clip?.name));
    return this.networkService.createPlaylistByClips(Deck.getAddress(deck), name, clipsPath, drive, folderPath);
  }

  updatePlaylist(deckId: string, playlist: Playlist): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    return this.networkService.updatePlaylist(Deck.getAddress(deck), playlist);
  }

  addFileToPlaylist(deckId: string, clips: Clip[], playlist: Playlist): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    for (const clip of clips) {
      const filename = this.nodeService.path.win32.normalize(clip?.drivePath + clip?.dirPath + clip?.name);
      const playlistClip = PlaylistClip.create(clip, playlist.clips.length, filename);
      playlist.clips.push(playlistClip);
    }
    return this.networkService.updatePlaylist(Deck.getAddress(deck), playlist);
  }

  deletePlaylist(deckId: string, playlist: Playlist): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    return this.networkService.deletePlaylist(Deck.getAddress(deck), playlist);
  }

  renamePlaylist(deckId: string, oldFilepath: string, newFilepath: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    return this.networkService.renamePlaylist(Deck.getAddress(deck), oldFilepath, newFilepath);
  }

  scanPlaylist(deckId: string, path: string): Observable<any> {
    const deck = this.deckService.getDeckSync(deckId);
    if (!this.deckService.isOnline(deck)) { return; }
    return this.networkService.scanPlaylist(Deck.getAddress(deck), path);
  }

  @warmUpObservable
  playPlaylist(stream: Stream, playlist: Playlist, editMode: boolean): Observable<void> {
    if (!stream) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    return this.networkService.openPlaylist(Deck.getAddress(deck), stream.deckChannel, playlist.name, editMode)
      .pipe(
        catchError(error => {
          let message = error?.error?.message;
          try {
            const jsonError = JSON.parse(message);
            message = 'Cant found file(s)';
            if (jsonError?.invalidFiles) {
              message = message + ': ' + (jsonError?.invalidFiles as string[]).join(', ');
            }
          } catch(e) {
            console.error(e);
          }
          this.notificationService.error(message, `Can't open playlist`);
          return of(null);
        })
      );
  }

  updatePlaylistBehavior(stream: Stream, playlistCueBehavior: string, playlistPlayBehavior: string): void {
    if (!stream) {
      return;
    }
    const deck = this.deckService.getDeckSync(stream.deckId);
    this.networkService.updatePlaylistBehavior(Deck.getAddress(deck), stream.deckChannel, playlistCueBehavior, playlistPlayBehavior);
  }

  /**
   * Markers
   */

  getMarkers(deckId: string, fileIds: number[]): Observable<Marker[]> {
    const deck = this.deckService.getDeckSync(deckId);
    if (deck && !this.deckService.isOnline(deck) || !fileIds) { return of([]); }
    return this.networkService.getMarkers(Deck.getAddress(deck), fileIds);
  }

  getMarker(deckId: string, markerId: number): Observable<Marker> {
    const deck = this.deckService.getDeckSync(deckId);
    if (deck && !this.deckService.isOnline(deck) || !markerId) { return of(null); }
    return this.networkService.getMarker(Deck.getAddress(deck), markerId);
  }

}
