import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { map, takeUntil, throttleTime } from 'rxjs/operators';

// Services
import { FileManagerService } from '@modules/file-manager/services/file-manager.service';
import { NodeService } from '@modules/node/services/node.service';

// Types
import { Timecode } from '@modules/elements/types/timecode';
import { Clip } from '@modules/deck/types/clip';
import { Playlist, TimecodeNull } from '@modules/playlist-editor/types/playlist';
import { MoveElementEvent } from '@modules/elements/directives/move-element/move-element.directive';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-playlist-progress-bar',
  templateUrl: './playlist-progress-bar.component.html',
  styleUrls: ['./playlist-progress-bar.component.less']
})
export class PlaylistProgressBarComponent implements OnInit, OnChanges, OnDestroy {

  // ViewChild
  @ViewChild('progress', { static: false }) progressRef: ElementRef;
  @ViewChild('currentPosition', { static: false }) currentPositionRef: ElementRef;
  @ViewChild('hoverPosition', { static: false }) hoverPositionRef: ElementRef;
  @ViewChild('hoverTC', { static: false }) hoverTCRef: ElementRef;
  @ViewChild('inPointPosition', { static: false }) inPointPositionRef: ElementRef;
  @ViewChild('outPointPosition', { static: false }) outPointPositionRef: ElementRef;
  @ViewChild('clipProgress', { static: false }) clipProgressRef: ElementRef;
  @ViewChildren('clipsPositions') clipsPositions: QueryList<ElementRef>;

  // Input
  @Input() stream: Stream;
  @Input() playlist: Playlist;

  // Output
  @Output() jump = new EventEmitter<{tc?: string, frame?: number, offset?: number}>();

  // Public
  public currentFrame: number = 0;
  public duration: number = 1;
  public showHoverPosition = false;
  public hoverCurrentPosition = false;
  public dragCurrentPosition = false;
  public dragFromFrame: number;
  public inPoint: string | number = null;
  public outPoint: string | number = null;
  public startTimecode: string = '00:00:00:00';
  public endTimecode: string = '00:00:00:00';
  public clips: Clip[] = [];

  // Private
  private alive = new Subject<void>();
  private jumpToFrame = new Subject<number>();

  /**
   * Constructor
   */

  constructor(
    private fileManagerService: FileManagerService,
    private nodeService: NodeService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.jumpToFrame
      .pipe(
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(frame => {
        this.jump.emit({ frame });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('playlist' in changes) {
      this.duration = this.playlist?.duration ?? 1;
      this.startTimecode = new Timecode(+this.playlist?.startTcFrames, +this.playlist?.frameRate, !!this.playlist?.drop).toString();
      this.endTimecode = new Timecode(+this.playlist?.duration, +this.playlist?.frameRate, !!this.playlist?.drop).toString();
      this.updateClips();
    }
    if ('stream' in changes) {
      setTimeout(() => this.updateInOutPoints(), 100);
    }
    // if ('inPoint' in changes || 'outPoint' in changes) {
    //   this.inPoint = (this.inPoint && this.inPoint === TimecodeNull) ? null : +this.inPoint;
    //   this.outPoint = (this.outPoint && this.outPoint === TimecodeNull) ? null : +this.outPoint;
    //   setTimeout(() => this.updateInOutPoints(), 100);
    // }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  updateClips(): void {
    if (!this.stream?.deckId || !this.playlist) {
      this.clips = [];
      return;
    }
    const deckId = this.stream.deckId;
    const paths = this.playlist.clips.map(clip => clip.filename);
    this.fileManagerService.getIndexedFiles(deckId, paths)
      .pipe(
        map(files => {
          const clips = [];
          this.playlist.clips.forEach(playlistClip => {
            const clip = files.find(file => {
              const filename = this.nodeService.path.win32.normalize(file?.drivePath + file?.dirPath + file?.name);
              return playlistClip.filename === filename;
            });
            const duration = clips.reduce((sum, item) => sum + (+item.durationFrames), 0);
            const interlaced = Timecode.isInterlaced(+clip.fps, clip.resolution);
            const timebase = Timecode.getTimebase(+clip.fps, interlaced);
            const playlistStartTimecode = new Timecode(duration, timebase, clip.drop === 1);
            const result = clip ? ({...playlistClip, ...clip, playlistStartTimecode: playlistStartTimecode.toString()}) : playlistClip;
            clips.push(result);
          });
          return clips;
        }),
        takeUntil(this.alive)
      )
      .subscribe(result => {
        this.clips = result;
        setTimeout(() => this.updateInOutPoints(), 100);
      });
  }

  setValue(value: number | string): void {
    if (value !== null && value !== undefined && this.playlist) {
      try {
        const clip = this.clips[0];
        let timebase = +this.playlist.frameRate;
        if (clip) {
          const interlaced = Timecode.isInterlaced(+clip?.fps, clip.resolution);
          timebase = Timecode.getTimebase(+clip?.fps, interlaced);
        }
        const timecode = new Timecode(value, timebase, +this.playlist.drop === 1);
        this.currentFrame = timecode.frameCount - this.playlist.startTcFrames;
        if (this.currentPositionRef && !this.dragCurrentPosition) {
          this.currentPositionRef.nativeElement.style.left = ((this.currentFrame / this.duration) * 100) + '%';
        }
      } catch (error) {
        console.error('[ProgressBar] ', error);
      }
    }
  }

  getPositionAndTimecode(event: MouseEvent): ({x: number, timecode: Timecode, frame: number}) {
    const rect = this.progressRef.nativeElement.getBoundingClientRect();
    const x = Math.min(event.clientX - rect.left, rect.width);
    const persent = x / rect.width;
    let frames = Math.floor(this.duration * persent);
    frames = Math.min(Math.max(frames, 0), (+this.playlist.duration - 1));
    const clip = this.clips[0];
    let timebase = +this.playlist.frameRate;
    if (clip) {
      const interlaced = Timecode.isInterlaced(+clip?.fps, clip.resolution);
      timebase = Timecode.getTimebase(+clip?.fps, interlaced);
    }
    const timecode = new Timecode(+this.playlist.startTcFrames, timebase, +this.playlist.drop === 1);
    timecode.add(frames);
    return {x, timecode, frame: frames};
  }

  updateInOutPoints(): void {
    let frame = 0;
    this.clipsPositions.forEach((element, elementIndex) => {
      element.nativeElement.style.left = ((frame / this.duration) * 100) + '%';
      const playlistClip = this.playlist.clips[elementIndex];
      const clip = this.clips[elementIndex];
      const startFrame = playlistClip.in !== TimecodeNull ? +playlistClip.in : clip.startTimecodeFrames;
      const endFrame = playlistClip.out !== TimecodeNull ? +playlistClip.out : clip.endTimecodeFrames;
      const durationFrames = endFrame - startFrame;
      frame += durationFrames;
    });
    // // In Point
    // if (this.inPoint !== null && this.inPointPositionRef && this.clip) {
    //   const inPointCurrentFrame = +this.inPoint - this.clip.startTimecodeFrames;
    //   this.inPointPositionRef.nativeElement.style.left = ((inPointCurrentFrame / this.duration) * 100) + '%';
    // }
    // // Out Point
    // if (this.outPoint !== null && this.outPointPositionRef && this.clip) {
    //   const outPointCurrentFrame = +this.outPoint - this.clip.startTimecodeFrames;
    //   this.outPointPositionRef.nativeElement.style.left = ((outPointCurrentFrame / this.duration) * 100) + '%';
    // }
    // if (this.clipProgressRef) {
    //   const inPointCurrentFrame = Math.max(+this.inPoint - this.clip.startTimecodeFrames, 0);
    //   const clipFramesSize = (+this.outPoint || this.clip.endTimecodeFrames) - (+this.inPoint || this.clip.startTimecodeFrames);
    //   this.clipProgressRef.nativeElement.style.left = ((inPointCurrentFrame / this.duration) * 100) + '%';
    //   this.clipProgressRef.nativeElement.style.width = ((clipFramesSize / this.duration) * 100) + '%';
    // }
  }

  /**
   * Actions
   */

  selectProgress(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { frame } = this.getPositionAndTimecode(event);
    this.jump.emit({ frame });
  }

  hoverProgress(event): void {
    const { x, timecode } = this.getPositionAndTimecode(event);
    if (this.hoverPositionRef && x > 0) {
      this.hoverPositionRef.nativeElement.style.left = x + 'px';
    }
    if (this.hoverTCRef) {
      this.hoverTCRef.nativeElement.style.left = x + 'px';
      this.hoverTCRef.nativeElement.innerHTML = timecode.toString() ?? '00:00:00:00';
    }
  }

  /**
   * Drag and drop Playback position
   */

  dragStartPlaybackPosition(event: MoveElementEvent): void {
    this.dragCurrentPosition = true;
    this.dragFromFrame = this.currentFrame;
  }

  dragMovePlaybackPosition(event: MoveElementEvent): void {
    const diffPersent = event.difference.x / this.progressRef.nativeElement.offsetWidth;
    const diffFrame = Math.floor((this.duration - 1) * diffPersent);
    const frame = Math.max(0, Math.min(this.duration - 1, this.dragFromFrame + diffFrame));
    
    const timecode = new Timecode(+this.playlist?.startTcFrames, +this.playlist?.frameRate, +this.playlist.drop === 1);
    timecode.add(frame);
    if (this.currentPositionRef) {
      this.currentPositionRef.nativeElement.style.left = (frame / (this.duration - 1)) * 100 + '%';
    }
    if (this.hoverTCRef) {
      this.hoverTCRef.nativeElement.style.left = (frame / (this.duration - 1)) * 100 + '%';
      this.hoverTCRef.nativeElement.innerHTML = timecode.toString() ?? '00:00:00:00';
    }
    this.jumpToFrame.next(frame);
  }

  dragEndPlaybackPosition(event: MoveElementEvent): void {
    this.dragCurrentPosition = false;
  }

  jumpTo(frame: number) {
    if (frame >= 0 && frame < +this.playlist.duration) {
      this.jump.emit({ frame });
    }
  }

}
