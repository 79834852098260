// RxJS
import { interval, Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, share, takeWhile } from 'rxjs/operators';

// WebSocket
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';

// Types
import { Deck } from '@modules/deck/types/deck';

export class WsMessage {
  event: string;
  data: any;
}

export class DeckSocket {

  // Public
  public readonly deck: Deck;

  // Private
  private websocket: WebSocketSubject<WsMessage>;
  private connection = new BehaviorSubject<boolean>(false);
  private reconnection: Observable<number>;
  private reconnectInterval = 5000; // pause between connections
  private reconnectAttempts = 100000; // number of connection attempts
  private messages = new Subject<WsMessage>();
  private socketConnected: boolean;

  /**
   * Constructor
   */

  constructor(deck: Deck) {
    console.log('Creating connection for deck:', deck);
    this.deck = deck;

    // Reconnection
    this.connection
      .pipe(share(), distinctUntilChanged())
      .subscribe((isSocketConnected) => {
        console.log('[WebSocket][Connection changed]: ', isSocketConnected);
        this.socketConnected = isSocketConnected;

        if (!this.reconnection && typeof(isSocketConnected) === 'boolean' && !isSocketConnected) {
          this.reconnect();
        }
      });

    // Connect
    this.connect();
  }

  /**
   * Methods
   */

  connect(): void {
    // URL
    const url = `${Deck.getWebsocketAddress(this.deck)}/status`;

    // Config
    const config: WebSocketSubjectConfig<WsMessage> = {
      url,
      closeObserver: {
        next: (event: CloseEvent) => {
          console.log('[WebSocket][Close]');
          this.websocket = null;
          this.connection.next(false);
        }
      },
      openObserver: {
        next: (event: Event) => {
          console.log('[WebSocket][Open]');
          this.connection.next(true);
        }
      }
    };

    // Clean previous websocket if any
    this.websocket?.complete();

    // Socket
    this.websocket = new WebSocketSubject<WsMessage>(config);
    this.websocket.subscribe(
      (message) => {
        // console.log('[WebSocket][Message] ', message);
        this.messages.next(message);
      },
      (error: Event) => {
        console.log('[WebSocket][Error] ', error);
      });
  }

  reconnect(): void {
    this.reconnection = interval(this.reconnectInterval)
      .pipe(
        filter(() => {
          console.log(`[WebSocket][reconnection] Reconection attempt NOT filtered: ${!this.websocket}`);
          return !this.websocket || this.socketConnected;
        }),
        takeWhile((value, index) => {
          console.log(`[WebSocket][reconnection] Will do reconnect: ${index < this.reconnectAttempts && !this.socketConnected}`);
          return index < this.reconnectAttempts && !this.websocket;
        })
      );

    this.reconnection.subscribe(
      () => {
        console.log(`[WebSocket][Reconnection] Performing re-connect`);
        this.connect();
      },
      null,
      () => {
        console.log(`[WebSocket][Reconnection] reconnection is finished`);
        // Subject complete if reconnect attemts ending
        this.reconnection = null;

        if (!this.websocket) {
          this.messages.complete();
          this.connection.complete();
        }
      }
    );
  }

  disconnect(): void {
    this.websocket?.complete();
    this.websocket = null;
    this.reconnection = null;
    this.connection.next(false);
  }

  /**
   * Receive
   */

  on<T>(event: string): Observable<T> {
    if (event) {
      return this.messages.pipe(
        filter((message: WsMessage) => message.event === event),
        map((message: WsMessage) => message.data)
      );
    }
  }


  /**
   * Send
   */

  send(event: string, data: any = {}): void {
    if (event && this.socketConnected) {
      this.websocket.next(<any>JSON.stringify({ event, data }));
    } else {
      console.error('Send error!');
    }
  }

  isConnected(): boolean {
    return this.connection.value ?? false;
  }

  connected(): Observable<boolean> {
    return this.connection.asObservable();
  }

}
