export class Layout {
  presets: boolean;
  userLists: boolean;
  globalLists: boolean;
  xmlTags: boolean;
  recordControl: boolean;
  schedule: boolean;
  inputs: boolean;
  inputGroups: boolean;
  channelStatus: boolean;
  channelList: boolean;
  volume: boolean;
  users: boolean;
  templates: boolean;
  recordSettings: boolean;
  meters: boolean;
  playlist: boolean;
  userListCount: number;  // 1..10
  globalListCount: number;  // 1..10

  constructor() {
    this.presets = false;
    this.userLists = false;
    this.globalLists = false;
    this.xmlTags = false;
    this.recordControl = false;
    this.schedule = false;
    this.inputs = false;
    this.inputGroups = false;
    this.channelStatus = false;
    this.channelList = false;
    this.volume = true;
    this.users = true;
    this.templates = false;
    this.recordSettings = false;
    this.meters = true;
    this.playlist = true;
    this.userListCount = 6;
    this.globalListCount = 4;
  }
}
