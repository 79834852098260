import { Directive, Renderer2, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';

interface Point {
  x: number;
  y: number
}

export interface MoveElementEvent {
  original: Point;
  current: Point;
  difference: Point;
  originalEvent: MouseEvent;
}

@Directive({
  selector: '[appMoveElement], [appMoveElementStart], [appMoveElementEnd]'
})
export class MoveElementDirective {

  // Inputs
  @Input() applyClass = 'move';
  @Input('appMoveElementDisabled') disabled = false;

  // Outputs
  @Output('appMoveElementStart') moveStart = new EventEmitter<MoveElementEvent>();
  @Output('appMoveElement') move = new EventEmitter<MoveElementEvent>();
  @Output('appMoveElementEnd') moveEnd = new EventEmitter<MoveElementEvent>();

  // Private
  private mouseUpListener: () => void;
  private mouseMoveListener: () => void;
  private originalEvent: MouseEvent;

  /**
   * Component lifecycle
   */

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
  ) { }

  /**
   * Methods
   */

  @HostListener('mousedown', ['$event'])
  private onMouseDown(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    this.setOriginalData(event);

    this.moveStart.emit(this.generateValuesForEvent(event));

    this.mouseUpListener = this.renderer2.listen('document', 'mouseup', event => this.onMouseUp(event));
    this.mouseMoveListener = this.renderer2.listen('document', 'mousemove', event => this.onMouseMove(event));
    this.renderer2.addClass(this.elementRef.nativeElement, this.applyClass);
  }

  private onMouseUp(event: MouseEvent): void {
    const eventValues = this.generateValuesForEvent(event);
    this.move.emit(eventValues);
    this.mouseMoveListener();
    this.mouseUpListener();

    this.renderer2.removeClass(this.elementRef.nativeElement, this.applyClass);
    this.moveEnd.emit(eventValues);
  }

  private onMouseMove(event: MouseEvent): void {
    this.move.emit(this.generateValuesForEvent(event));
  }

  private setOriginalData(originalEvent: MouseEvent): void {
    this.originalEvent = originalEvent;
  }

  private generateValuesForEvent(event: MouseEvent): MoveElementEvent {
    const originalXValue = this.originalEvent.clientX;
    const originalYValue = this.originalEvent.clientY;

    const differenceXValue = event.clientX - originalXValue;
    const differenceYValue = event.clientY - originalYValue;

    return {
      originalEvent: this.originalEvent,
      original: { x: originalXValue, y: originalYValue },
      current: { x: event.clientX, y: event.clientY },
      difference: { x: differenceXValue, y: differenceYValue }
    };
  }

}
