<div class="channel-disks-content" (click)="changeShowView()">
  <!-- Disks -->
  <div class="disks" *ngIf="showAllView || !showFilename">
    <div class="drive" *ngFor="let data of info">
      <div class="encoder-name">{{ data.encoder }}</div>
      <div class="placeholder-name" *ngIf="!data.disk">No Data <span class="details">(no select disk for encoder)</span></div>
      <div class="drive-name" *ngIf="data.disk">{{ data.disk.driveLetter | uppercase }}:</div>
      <app-disc-progress class="drive-progress" *ngIf="data.disk" [total]="data?.disk?.totalBytes" [free]="data?.disk?.availBytes"></app-disc-progress>
    </div>
  </div>
  <!-- Names -->
  <div class="filenames" *ngIf="showAllView || showFilename">
    <div *ngFor="let data of info" class="filepath">
      <div class="encoder-name">{{ data.encoder }}</div>
      <div class="path-override" *ngIf="data.override">Override</div>
      <div class="recording-name" [title]="data.path">{{ data.disk && data.path || 'No Data' }}</div>
    </div>
  </div>
</div>
