import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filenameNormalize'
})
export class FilenameNormalizePipe implements PipeTransform {

  private illegalRe = /[\/\?<>\\:\*\|"]/g;
  private controlRe = /[\x00-\x1f\x80-\x9f]/g;
  private reservedRe = /^\.+$/;
  private windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
  private windowsTrailingRe = /[\. ]+$/;
  private windowsMaxSymbolsCount = 255;

  transform(filename: string, replacement: string = ''): string {
    const result = filename
      .replace(this.illegalRe, replacement)
      .replace(this.controlRe, replacement)
      .replace(this.reservedRe, replacement)
      .replace(this.windowsReservedRe, replacement)
      .replace(this.windowsTrailingRe, replacement);
    return result.substring(0, this.windowsMaxSymbolsCount);;
  }

}
