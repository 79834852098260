import { DeckError } from "@modules/deck/types/deck-error";
import { UniqueID } from '@modules/elements/types/unique-id';

export class ChannelError extends DeckError {
  id: string;
  date: Date;

  constructor(error: DeckError) {
    super();
    this.id = UniqueID.generate();
    this.date = new Date();
    for (const key of Object.keys(error)) {
      this[key] = error[key];
    }
  }
}
