<div class="audio-levels-content">

  <!-- Pair -->
  <div class="audio-pair" *ngFor="let pair of audioPairs; let index = index" (click)="select(index)">

    <!-- Levels -->
    <div class="audio-levels">

      <!-- Bar -->
      <div #level class="level" *ngFor="let channel of pair">
        <div
          *ngFor="let item of levelItems; let indexLevel = index"
          class="item"
          [class.yellow]="indexLevel > 17 && indexLevel < 20"
          [class.orange]="indexLevel > 19 && indexLevel < 22"
          [class.red]="indexLevel > 21"
        ></div>
      </div>

      <!-- Selected -->
      <div class="pair-selected" *ngIf="index === selected">
        <div class="top-left"></div>
        <div class="top-right"></div>
        <div class="bottom-left"></div>
        <div class="bottom-right"></div>
      </div>

    </div>

    <!-- Audio route -->
    <div class="audio-route">
      <div class="channel" *ngFor="let channel of pair">{{ channel?.title }}</div>
    </div>

  </div>

</div>
