import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Clip } from '@modules/deck/types/clip';
import { Marker } from '@modules/marker/types/marker';
import { Stream } from '@modules/stream/types/stream';

@Component({
  selector: 'app-marker-progress-bar-item',
  templateUrl: './marker-progress-bar-item.component.html',
  styleUrls: ['./marker-progress-bar-item.component.less']
})
export class MarkerProgressBarItemComponent implements OnInit, OnChanges {

  // Inputs
  // @Input() stream: Stream;
  @Input() clip: Clip;
  @Input() marker: Marker;

  /**
   * Constructor
   */

  constructor(
    public elementRef: ElementRef,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.updatePosition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('clip' in changes || 'marker' in changes) {
      this.updatePosition();
    }
  }

  /**
   * Methods
   */

  updatePosition(): void {
    const duration = this.clip?.durationFrames ?? 1;
    const inPointCurrentFrame = +this.marker.inPoint - this.clip.startTimecodeFrames;
    this.elementRef.nativeElement.style.left = ((inPointCurrentFrame / duration) * 100) + '%';
  }

}
