import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// External modules
import { ColorSketchModule } from 'ngx-color/sketch';
import { ContextMenuModule } from 'ngx-contextmenu';

// Modules
import { ElementsModule } from '@modules/elements/elements.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FileManagerModule } from '@modules/file-manager/file-manager.module';
import { ChannelModule } from '@modules/channel/channel.module';

// Component
import { PresetListComponent } from './components/preset-list/preset-list.component';
import { PresetNameComponent } from './components/preset-name/preset-name.component';
import { PresetNameSettingsComponent } from './components/preset-name-settings/preset-name-settings.component';
import { PresetNameTemplateComponent } from './components/preset-name-template/preset-name-template.component';
import { PresetSettingsComponent } from './components/preset-settings/preset-settings.component';
import { PresetSettingsInputComponent } from './components/preset-settings/preset-settings-input/preset-settings-input.component';
import { PresetSettingsOutputComponent } from './components/preset-settings/preset-settings-output/preset-settings-output.component';
import { PresetSettingsEncoderComponent } from './components/preset-settings/preset-settings-encoder/preset-settings-encoder.component';
import { PresetSettingsTimecodeComponent } from './components/preset-settings/preset-settings-timecode/preset-settings-timecode.component';
import { PresetSettingsProjectComponent } from './components/preset-settings/preset-settings-project/preset-settings-project.component';
import { PresetSettingsOutputOverlaysComponent } from './components/preset-settings/preset-settings-output-overlays/preset-settings-output-overlays.component';
import { PresetRecordSettingsComponent } from './components/preset-record-settings/preset-record-settings.component';
import { PresetSettingsBaseComponent } from './components/preset-settings/preset-settings-base/preset-settings-base.component';
import { PresetRecordEncoderSettingsComponent } from './components/preset-record-settings/preset-record-encoder-settings/preset-record-encoder-settings.component';
import { PresetSettingsAudioRoutingComponent } from './components/preset-settings/preset-settings-audio-routing/preset-settings-audio-routing.component';
import { PresetChangeFolderComponent } from './components/preset-change-folder/preset-change-folder.component';
import { PresetSettingsCopyComponent } from './components/preset-settings/preset-settings-copy/preset-settings-copy.component';
import { PresetSettingsOptionComponent } from './components/preset-settings/preset-settings-option/preset-settings-option.component';
import { PresetSettingsBurnsComponent } from './components/preset-settings/preset-settings-burns/preset-settings-burns.component';
import { PresetSettingsAudioDelaysComponent } from './components/preset-settings/preset-settings-audio-delays/preset-settings-audio-delays.component';
import { PresetSettingsEditshareComponent } from './components/preset-settings/preset-settings-editshare/preset-settings-editshare.component';
import { PresetSettingsNdiSourceComponent } from './components/preset-settings/preset-settings-ndi-source/preset-settings-ndi-source.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ElementsModule,
    DragNDropModule,
    FileManagerModule,
    ChannelModule,

    // External
    ColorSketchModule,
    ContextMenuModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatGridListModule,
    MatCheckboxModule,
    DragDropModule,
    MatSlideToggleModule,
  ],
  declarations: [
    PresetListComponent,
    PresetNameComponent,
    PresetNameSettingsComponent,
    PresetNameTemplateComponent,
    PresetSettingsComponent,
    PresetSettingsInputComponent,
    PresetSettingsOutputComponent,
    PresetSettingsEncoderComponent,
    PresetSettingsTimecodeComponent,
    PresetSettingsProjectComponent,
    PresetSettingsOutputOverlaysComponent,
    PresetRecordSettingsComponent,
    PresetSettingsBaseComponent,
    PresetRecordEncoderSettingsComponent,
    PresetSettingsAudioRoutingComponent,
    PresetChangeFolderComponent,
    PresetSettingsCopyComponent,
    PresetSettingsOptionComponent,
    PresetSettingsBurnsComponent,
    PresetSettingsAudioDelaysComponent,
    PresetSettingsEditshareComponent,
    PresetSettingsNdiSourceComponent,
  ],
  exports: [
    PresetListComponent,
    PresetNameComponent,
    PresetNameSettingsComponent,
    PresetSettingsComponent,
    PresetRecordSettingsComponent,
  ],
})
export class PresetModule { }
