<div class="stream-list-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Input Sources</div>
    <div class="actions">
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" [class.disabled]="!allowed" (click)="lock()"></mat-icon>
      <mat-icon svgIcon="settings" class="action-icon" [class.disabled]="!allowed" (click)="openSettings()"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content" *ngIf="streams?.length">
    <div
      *ngFor="let stream of streams"
      class="tag"
      [class.selected]="stream?.id === selected?.id"
      [class.disabled]="!allowed || locked"
      appDraggable
      [appDraggableData]="{data: stream, type: 'stream'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [contextMenu]="inputMenu"
      [contextMenuSubject]="stream"
    >
      <app-online-status class="online-status" [value]="getOnlineStatus(stream) | async"></app-online-status>
      {{ getName(stream) }}
      <mat-icon svgIcon="dots-vertical" class="icon icon-menu" (click)="openContextMenu($event, inputMenu, stream)"></mat-icon>

      <!-- Drag placeholder -->
      <ng-template #dragPlaceholder>
        <div class="drag-tag tag">{{ getName(stream) }}</div>
      </ng-template>
    </div>
  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!streams?.length">
    <div class="text">Click on <b>Settings</b> icon to add <b>Inputs</b></div>
  </div>

</div>

<!-- Context menu -->
<context-menu #inputMenu>
  <ng-template contextMenuItem [subMenu]="channelsMenu">Assign to channel</ng-template>
  <context-menu #channelsMenu>
    <ng-template *ngFor="let channel of channels; let index = index" contextMenuItem (execute)="setChannel($event.item, channel)">Channel {{ index + 1 }}</ng-template>
  </context-menu>
</context-menu>

