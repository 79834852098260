import { UniqueID } from '@modules/elements/types/unique-id';
import { StreamSettings, StreamSettingsEncode } from '@modules/stream/types/stream-settings';
import { UserList } from './user-list';
import { XmlTag } from './xml-tag';
import { PresetNameTemplates } from './preset-name-templates';

// Lodash
import { merge, has, isArray, isObject, cloneDeep } from 'lodash-es';

export class Preset {
  id: string;
  name: string;
  userList: UserList[];
  xmlTags: XmlTag[];
  defaultSetting: StreamSettings;
  settings: {[id: string]: Partial<StreamSettings>};
  nameTemplates: PresetNameTemplates;
  defaultNameSeparator: string; // 'underscore', 'dash', 'space', 'none'
  version: number;

  /**
   * Constructor
   */

  constructor(data: {
    id: string,
    name: string,
    userList?: UserList[],
    xmlTags?: XmlTag[],
    defaultSetting?: StreamSettings,
    settings?: {[id: string]: Partial<StreamSettings>},
    nameTemplates?: PresetNameTemplates,
    defaultNameSeparator: string,
    version: number
  }) {

    if (!data.id || !data.name) {
      throw new Error('id and name are required');
    }
    this.id = data.id;
    this.name = data.name;
    this.userList = (data.userList || []) as UserList[];
    this.xmlTags = (data.xmlTags || []) as XmlTag[];
    this.defaultSetting = data.defaultSetting || StreamSettings.defaultSettings();
    this.settings = data.settings || {};
    this.nameTemplates = data.nameTemplates ? new PresetNameTemplates(data.nameTemplates) : PresetNameTemplates.default();
    this.defaultNameSeparator = data.defaultNameSeparator || 'underscore';
    this.version = data.version;
    this.migration();
  }

  getSetting(streamId: string): StreamSettings {
    if (!streamId) {
      return this.defaultSetting;
    }

    // Get Settings
    const settings = this.settings[streamId];
    if (settings?.encode?.length) {
      settings.encode = settings.encode.map(encode => encode || ({} as StreamSettingsEncode));
    }
    const mergeSettings = Preset.mergeSettings(this.defaultSetting, settings);

    // Find null or undefine values and change to default
    function removeNulls(value, defaultValue, path = '') {
      for (let key in value) {
        const nextPath = path + (isArray(value) ? `[${key}]` : (path.length ? `.${key}` : `${key}`));
        if (value[key] === null || value[key] === undefined) {
          if (has(defaultValue, key)) {
            value[key] = defaultValue[key];
            // console.log(`[Preset][Merge][Update] '${nextPath}'`);
          } else {
            console.error(`[Preset][Merge][NotFound] Cant found value for path '${nextPath}'`);
          }
        } else if (isObject(value[key]) && has(defaultValue, key)) {
          removeNulls(value[key], defaultValue[key], nextPath);
        }
      }
    }
    removeNulls(mergeSettings, this.defaultSetting);

    try {
      // Fix problem with Audio Routing
      if (mergeSettings && mergeSettings.channels && mergeSettings.channels?.length) {
        for (let [index, channel] of mergeSettings.channels.entries()) {
          let channelAudioRouting = channel?.audioRouting;
          if (!channelAudioRouting && this.defaultSetting.channels[0]?.audioRouting) {
            channelAudioRouting = this.defaultSetting.channels[0]?.audioRouting;
          }
          if (settings && settings.channels && settings.channels.length && settings?.channels[index]?.audioRouting) {
            channelAudioRouting = settings.channels[index].audioRouting;
          }
          if (channelAudioRouting && channelAudioRouting.length) {
            for (let [indexEncoder, audioRouting] of channelAudioRouting.entries()) {
              if (!mergeSettings?.channels[index]?.audioRouting[indexEncoder]) {
                mergeSettings.channels[index].audioRouting[indexEncoder] = this.defaultSetting.channels[0]?.audioRouting[indexEncoder];
              }
              if (mergeSettings?.channels[index]?.audioRouting[indexEncoder]?.audioRoutingData) {
                if (has(settings, `channels[${index}].audioRouting[${indexEncoder}].audioRoutingData`)) {
                  mergeSettings.channels[index].audioRouting[indexEncoder].audioRoutingData = settings.channels[index].audioRouting[indexEncoder].audioRoutingData.map(value => value == null ? ["silent", 0] : value);
                } else {
                  mergeSettings.channels[index].audioRouting[indexEncoder].audioRoutingData = mergeSettings.channels[index].audioRouting[indexEncoder].audioRoutingData.map(value => value == null ? ["silent", 0] : value);
                }
              } else if (mergeSettings?.channels[index]?.audioRouting[indexEncoder]) {
                mergeSettings.channels[index].audioRouting[indexEncoder].audioRoutingData = this.defaultSetting.channels[0]?.audioRouting[indexEncoder].audioRoutingData.map(value => value == null ? ["silent", 0] : value);
              }
            }
          }
        }
      }
      // Fix toneSettings
      if (mergeSettings && mergeSettings.input && mergeSettings.input.toneSettings?.length) {
        mergeSettings.input.toneSettings = mergeSettings.input.toneSettings.map(value => value === null ? 0 : value);
      }

      // Cut encoders if needed
      if (settings?.encodeCount !== null && settings?.encodeCount !== undefined && mergeSettings?.encode?.length > settings?.encodeCount) {
        mergeSettings.encode = mergeSettings.encode.slice(0, settings.encodeCount);
        mergeSettings?.channels?.forEach((channel, index) => {
          mergeSettings.channels[index].audioRouting = mergeSettings.channels[index].audioRouting.slice(0, settings.encodeCount);
          mergeSettings.channels[index].burnIn = mergeSettings.channels[index].burnIn.slice(0, settings.encodeCount);
        });
        mergeSettings.nameTemplates = mergeSettings.nameTemplates.slice(0, settings.encodeCount);
      }

      // Fix default srtPort and comPort
      for (let index = 0; index < mergeSettings.channels.length; index++) {
        if (!has(settings, `channels[${index}].srtPort`)) {
          mergeSettings.channels[index].srtPort = this.defaultSetting.channels[0].srtPort + index;
        }
        if (!has(settings, `channels[${index}].comPort`)) {
          mergeSettings.channels[index].comPort = mergeSettings.channels[index].comPortDefault;
        }
      }

      // Audio Delays Locked
      if (has(settings, 'audioDelaysLocked')) {
        mergeSettings.audioDelaysLocked = settings.audioDelaysLocked;
      }
      if (!has(mergeSettings, 'audioDelaysLocked') || mergeSettings.audioDelaysLocked === undefined) {
        mergeSettings.audioDelaysLocked = false;
      }

    } catch (error) {
      console.log('[Error][AudioRouting][mergeSettings] ', mergeSettings);
      console.log('[Error][AudioRouting][streamId] ', streamId);
      console.log('[Error][AudioRouting][preset] ', this);
      console.error(error);
    }
    return mergeSettings;
  }

  isValid(): boolean {
    return this.version && this.version >= 2;
  }

  /**
   * Static methods
   */

  static emptyPreset(name: string): Preset {
    return new Preset({
      id: Preset.generateId(),
      name,
      userList: UserList.defaultUserList(),
      xmlTags: [],
      settings: {},
      nameTemplates: PresetNameTemplates.default(),
      defaultNameSeparator: 'underscore',
      version: 2,
    });
  }

  static generateId() : string {
    return UniqueID.generate();
  }

  static mergeSettings(defaultSetting: StreamSettings, streamSetting: Partial<StreamSettings>): StreamSettings {
    return merge({}, defaultSetting, streamSetting);
  }

  /**
   * Migration
   */

  private migration(): void {
    // Migration SRT
    if (!has(this.defaultSetting, 'channels[0].srtReceiverUrl')) {
      // Update Default Settings
      if (this.defaultSetting.input.activeVideoInput === 'srt') {
        this.defaultSetting.input.activeVideoInput = 'srt_listener';
      }
      if (!has(this.defaultSetting, 'channels[0].srtReceiverUrl')) {
        this.defaultSetting.channels[0].srtReceiverUrl = '';
      }
      if (!has(this.defaultSetting, 'channels[0].srtPassphrase')) {
        this.defaultSetting.channels[0].srtPassphrase = '';
      }
      if (!has(this.defaultSetting, 'channels[0].srtPort')) {
        this.defaultSetting.channels[0].srtPort = 9000;
      }
      // Update channel settings
      for (const streamId of Object.keys(this.settings)) {
        if (has(this.settings[streamId], 'input.activeVideoInput') && this.settings[streamId]?.input?.activeVideoInput === 'srt') {
          this.settings[streamId].input.activeVideoInput = 'srt_listener';
        }
      }
    }
    // Audio Delays Locked
    if (!has(this.defaultSetting, 'audioDelaysLocked')) {
      this.defaultSetting.audioDelaysLocked = false;
    }
    // New Fields
    for (let index = 0; index < this.defaultSetting.encode.length; index++) {
      if (!has(this.defaultSetting.encode[index], 'fieldWrapped')) {
        this.defaultSetting.encode[index].fieldWrapped = false;
      }
      if (!has(this.defaultSetting.encode[index], 'fieldShift')) {
        this.defaultSetting.encode[index].fieldShift = false;
      }
      if (!has(this.defaultSetting.encode[index], 'forceBT709')) {
        this.defaultSetting.encode[index].forceBT709 = false;
      }
      if (!has(this.defaultSetting.encode[index], 'activeAudioCodec')) {
        this.defaultSetting.encode[index].activeAudioCodec = 'pcm';
      }
      if (!has(this.defaultSetting.encode[index], 'h264DefaultAudioCodec')) {
        this.defaultSetting.encode[index].h264DefaultAudioCodec = 'pcm';
      }
    }
    if (!has(this.defaultSetting, 'sameDiskAsPreset')) {
      this.defaultSetting.sameDiskAsPreset = true;
    }
    // Migration encoder fields
    if (has(this.defaultSetting, 'encode[0].segmentDuration') && !this.isString(this.defaultSetting.encode[0].segmentDuration)
    ) {
      const dropFrame = this.defaultSetting.input.dropFrame;
      for (let encoderIndex = 0; encoderIndex < this.defaultSetting.encode.length; encoderIndex++) {
        if (has(this.defaultSetting, `encode[${encoderIndex}].segmentDuration`) && !this.isString(this.defaultSetting.encode[encoderIndex].segmentDuration)) {
          this.defaultSetting.encode[encoderIndex].segmentDuration = dropFrame ? '00:00:30;00' : '00:00:30:00';
        }
      }
      // Update channel settings
      for (const streamId of Object.keys(this.settings)) {
        const streamDropFrame = has(this.settings[streamId], 'input.dropFrame') ? this.settings[streamId].input.dropFrame : dropFrame;
        for (let encoderIndex = 0; encoderIndex < this.defaultSetting.encode.length; encoderIndex++) {
          if (has(this.settings[streamId], `encode[${encoderIndex}].segmentDuration`) && !this.isString(this.defaultSetting.encode[encoderIndex].segmentDuration)) {
            this.defaultSetting.encode[encoderIndex].segmentDuration = streamDropFrame ? '00:00:30;00' : '00:00:30:00';
          }
        }
      }
    }

    if (!has(this.defaultSetting, 'editShareSettings')) {
      this.defaultSetting.editShareSettings = {
        enabled: false,
        username: '',
        password: '',
        ipAddress: '',
        port: 0,
        multicamEnabled: false,
      };
    }

    // Output
    if (!has(this.defaultSetting, 'output')) {
      this.defaultSetting.output = StreamSettings.defaultSettings().output;
    }
  }

  isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
  }

}
