<div class="xml-tag-list-content">

  <!-- Header -->
  <div class="list-header shadow">
    <div class="title">XML Tags</div>
    <div class="actions">
      <mat-icon [svgIcon]="(locked || !allowed) ? 'lock' : 'unlock'" class="action-icon" (click)="lock()" [class.disabled]="!tags || !allowed"></mat-icon>
      <mat-icon svgIcon="fullscreen" class="action-icon" [class.disabled]="!tags"></mat-icon>
    </div>
  </div>

  <!-- List -->
  <div class="tags-content" [class.disabled]="!allowed || locked" *ngIf="tags">

    <!-- Users -->
    <div class="list-header tag-group-header">
      <div class="title">User</div>
      <div class="actions">
        <mat-icon svgIcon="minus" class="action-icon" (click)="deleteTag(selected)"></mat-icon>
        <mat-icon svgIcon="plus" class="action-icon" #addIcon (click)="showCreatePopover(createPopover, addIcon)"></mat-icon>
      </div>
    </div>
    <div class="list-content" *ngIf="tags.length">
      <div class="xml-tag" *ngFor="let tag of tags" [class.selected]="tag.id === selected?.id" (click)="selectTag(tag)">{{ tag.name }}</div>
    </div>

    <!-- Empty list -->
    <div class="placeholder-empty placeholder-list" *ngIf="!tags.length">
      <div class="text">Empty</div>
    </div>

  </div>

  <!-- Empty -->
  <div class="placeholder-empty" *ngIf="!tags">
    <div class="text">Select a <b>Preset</b> first to start working with <b>XML Tags</b></div>
  </div>

</div>

<!-- Popover Create -->
<ng-template #createPopover>
  <app-create-item-popover [popoverClose]="popoverClose" (created)="createTag($event)"></app-create-item-popover>
</ng-template>
