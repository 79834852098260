import SettingsDefault from '../../../../assets/settings/default.json';

/**
 * Project
 */

export class StreamSettings {
  allowSpaces: boolean;
  automation: StreamSettingsAutomation;
  channels: StreamSettingsChannel[];
  currentDeskEmulation: number;
  director: string;
  dp: string;
  editShareSettings: StreamSettingsEditshare;
  encode: StreamSettingsEncode[];
  input: StreamSettingsInput;
  interplaSettings: StreamSettingsInterplaSettings;
  location: string;
  logger: StreamSettingsLogger;
  loggerSettings: StreamSettingsLoggerSettings;
  nameTemplates: StreamSettingsNameTemplates[];
  output: StreamSettingsOutput;
  producer: string;
  sameTemplatesForAllEncoders: boolean;
  scenes: StreamSettingsScenes;
  subScenes: StreamSettingsScenes;
  systemKeywords: StreamSettingsKeywords;
  tcSource: string; // "sdi"
  userAudioLabels: StreamSettingsAudioLabel[];
  userKeywords: StreamSettingsKeywords;
  userLists: StreamSettingsUserList[];
  userLock: boolean;
  wildcards: StreamSettingsWildcard[];
  encodeCount?: number; // Virtual property (not available on Server)
  audioDelaysLocked?: boolean; // Virtual property (not available on Server)
  sameDiskAsPreset?: boolean; // Virtual property (not available on Server)

  static defaultSettings(): StreamSettings {
    return SettingsDefault as StreamSettings;
  }
}

/**
 * Automation
 */

export class StreamSettingsAutomation {
  avidOffset: number; // 2
	deviceParams: {
		source: string // "panasonic"
	};
  edlParams: {
    filepath: string; // "C:/Cinedeck_x64_VS2017/editlist.cdl"
    source: string; // "sdi"
  };
  extLtc1Offset: number;
  extLtc2Offset: number;
  forceRP188: boolean;
  genTCDropFlag: boolean;
  genTCPresetString: string; // "00:00:00:00"
  genTCSource: string; // "local"
  genTCType: string; // "free_run"
  genTcOffset: number; // 0
  irigOffset: number;
  manualParams: {
    source: string; // "sdi"
  };
  masterLtcOffset: number;
  ndiOffset: number; // 0
  proTools: boolean;
  recRunParams: {
    frames: number;
  };
  rs422Offset: number;
  rs422Params: {
    source: string; // "sdi"
  };
  scheduleParams: {
    filepath: string; // "C:/Cinedeck_x64_VS2017/schedule.sch"
    source: string; // "sdi"
  };
  sdiOffset: number;
  showIrigDateEnabled: boolean;
  tcSource: string; // "sdi"
  telecineOffset: number;
  trigger: string; // "no"
}

/**
 * Channel
 */

export class StreamSettingsAudioLabel {
  full: string;
  short: string;
}

export class StreamSettingsChannelAudioRouting {
  audioChannelDelays: number[]; // Array(32)
  audioChannelLevels: number[]; // Array(32)
  audioFrequencyModel: {
    currentBitDepth: number;
  };
  audioLabels: StreamSettingsAudioLabel[];
  audioRoutingData: Object[]; // [ ["silent", 0], ["digital", 1], ["tone_generator", 200], ["tone_generator", 1200], ... ]
}

export class StreamSettingsChannelBurnInValue {
  blockColor: string; // "#ff000000"
  enabled: boolean;
  frontOpacity: number; // 100
  opacity: number; // 100
  position: string; // "TopLeft"
  safe: number; // 95
  size: number; // 24
  text: string; // "GPS"
  textColor: string; // "#ffffffff"
}

export class StreamSettingsChannelBurnInValueShort {
  blockColor: string;
  enabled: boolean;
  filePath: string; // ""
  frontOpacity: number; // 100
  opacity: number;  // 0
  position: string; // "TopLeft"
  safe: number; // 95
  size: number; // 100
}

export class StreamSettingsChannelBurnIn {
  GPSElement: StreamSettingsChannelBurnInValue;
  clip: StreamSettingsChannelBurnInValueShort;
  codecElement: StreamSettingsChannelBurnInValue;
  enabled: boolean;
  encoderTimecodeElement: StreamSettingsChannelBurnInValue;
  filenameElement: StreamSettingsChannelBurnInValue;
  frameNumberElement: StreamSettingsChannelBurnInValue;
  imageSequence: StreamSettingsChannelBurnInValueShort;
  qualityElement: StreamSettingsChannelBurnInValue;
  stillImage: StreamSettingsChannelBurnInValueShort;
  systemTimeAndDateElement: StreamSettingsChannelBurnInValue;
  tcSource: string; // "sdi"
  userTextElement: StreamSettingsChannelBurnInValue; 
}

export class StreamSettingsChannelCharOutValue {
  backColor: string; // "#ff000000"
  enabled: boolean;
  fontSize: number; // 8
  height: number; // 190 (pixels size)
  opacity: number; // 0..100
  textColor: string; // "#ffffffff"
  width: number; // 0 (pixels size)
  x: number; // 0..1
  y: number; // 0..1
}

export class StreamSettingsChannelDriveAssignments {
  activePrimaryDrivePos: string; // "sata6g_a"
  activePrimaryOverrideDrivePos: string; // "sata6g_a"
  activeSecondaryDrivePos: string; // "sata6g_a"
  activeSecondaryOverrideDrivePos: string; // "sata6g_a"
  activeWriteMode: string; // "single"
  overridePrimaryPath: boolean;
  overrideSecondaryPath: boolean;
  primaryPath: string;
  secondaryPath: string;
}

export class StreamSettingsChannelAudioOutput {
  enabled: boolean;
  value: number;
}

export class StreamSettingsChannel {
    _444output: string; // "single_link"
    activeSyncSource: string; // "genlock"
    audioOutput: {data: StreamSettingsChannelAudioOutput[]};
    audioPlaythroug: {data: StreamSettingsChannelAudioOutput[]};
    audioRouting: StreamSettingsChannelAudioRouting[]; // Array({encoder count})
    burnIn: StreamSettingsChannelBurnIn[];
    charOut: {
        audioLevelsMaster: StreamSettingsChannelCharOutValue;
        audioLevelsPlayback: StreamSettingsChannelCharOutValue;
        audioLevelsProxy: StreamSettingsChannelCharOutValue;
        borderStatus: StreamSettingsChannelCharOutValue;
        captureTcSource: string; // "sdi"
        codec: {
            backColor: string; // "#ff000000",
            codecEnabled: boolean; // false,
            fontSize: number; // 21,
            height: number; // 0,
            opacity: number; // 100,
            textColor: string; // "#ffffffff",
            width: number; // 0,
            x: number; // 0.00145,
            y: number; // 0.77
        };
        fileName: {
            backColor: string; // "#ff000000",
            fileNameEnabled: boolean; // true,
            fontSize: number; // 21,
            height: number; // 0,
            opacity: number; // 100,
            textColor: string; // "#ffffffff",
            width: number; // 0,
            x: number; // 0.3488372093023256,
            y: number; // 0
        };
        gps: {
            backColor: string; // "#ff000000",
            fontSize: number; // 12,
            gpsEnabled: boolean; // false,
            height: number; // 0,
            opacity: number; // 100,
            textColor: string; // "#ffffffff",
            width: number; // 0,
            x: number; // 0,
            y: number; // 0
        };
        idleBorderColors: StreamSettingsChannelCharOutValue;
        playbackTcSource: string; // "playback"
        quality: {
            backColor: string; // "#ff000000",
            fontSize: number; // 21,
            height: number; // 0,
            opacity: number; // 100,
            qualityEnabled: boolean; // false,
            textColor: string; // "#ffffffff",
            width: number; // 0,
            x: number; // 0.01889,
            y: number; // 0.86
        };
        recBorderColors: StreamSettingsChannelCharOutValue;
        redDotStatus: StreamSettingsChannelCharOutValue;
        remTimecode: {
            backColor: string; // "#ff000000",
            fontSize: number; // 24,
            height: number; // 0,
            opacity: number; // 100,
            remTimecodeEnabled: boolean; // true,
            textColor: string; // "#ffffffff",
            width: number; // 0,
            x: number; // 0.3488372093023256,
            y: number; // 0.6
        };
        snap: boolean;
        timecode: {
            backColor: string; // "#ff000000",
            captionsEnabled: boolean; // true,
            encNameEnabled: boolean; // true,
            fontSize: number; // 22,
            height: number; // 0,
            opacity: number; // 100,
            statusEnabled: boolean; // true,
            textColor: string; // "#ffffffff",
            timecodeEnabled: boolean; // true,
            width: number; // 0,
            x: number; // 0.3488372093023256,
            y: number; // 0.8762886597938144
        };
        userText: {
            backColor: string; // "#ff000000",
            fontSize: number; // 12,
            height: number; // 0,
            opacity: number; // 100,
            text: string; // "",
            textColor: string; // "#ffffffff",
            textEnabled: boolean; // true,
            width: number; // 0,
            x: number; // 0.3488372093023256,
            y: number; // 0.3
        };
    };
    comPort: number;
    comPortDefault: number; // 9 + channel index
    driveAssignments: StreamSettingsChannelDriveAssignments[];
    imageCapture: {
      driveAssignments: StreamSettingsChannelDriveAssignments;
      filenameTemplate: string; //"%P_%I_%C",
      filepathTemplate: string; //"\\%P\\%I\\"
    };
    inputLabel: string; // "Label1"
    inputName: string; // "Input1"
    inputRange: number; // 0
    isYuvLevelA: boolean;
    ltcSource: number; // 0
    lutBurn: {
      enabled: boolean;
      file: string;
    }[];
    maxH264Duration: string; // "08:00"
    outputRange: number; // 1
    overlay: {
        aspect: {
          basicColor: string;
          enabled: boolean;
          opacity: number;
          userValue: number;
          value: number;
        };
        cc: {
          enabled: boolean;
          type: number;
        };
        grid: {
            basicColor: string;
            enabled: boolean;
            opacity: number;
            userValue: {
              height: number;
              width: number;
            };
            value: {
              height: number;
              width: number;
            }
        };
        letterbox: {
          basicColor: string;
          enabled: boolean;
          userValue: number;
          value: number;
        };
        safe: {
          basicColor: string;
          box: boolean;
          enabled: boolean;
          opacity: number;
          userValue: {
            height: number;
            width: number;
          };
          value: {
            height: number;
            width: number;
          };
        };
    };
    playlistCueBehavior: number; // CueEndOfCurrent = 1, CueStartOfNext = 2
    playlistPlayBehavior: number; // StopAtCurrent = 0, StopAtNext = 1, Continuous = 2
    preroll: number;
    recTCOffsetEnabled: boolean;
    recTCOffsetNegative: boolean;
    recTCOffsetString: string; // "00:00:00:00"
    remoteCtrl: {
        autoStatus: boolean;
        jogModeStandard: boolean;
        jogSensitive: number;
        masterTcOffset: number;
        masterTcOffsetEnabled: boolean;
        rs422Mode: string; // "off"
        slaveTcOffset: number;
        slaveTcOffsetEnabled: boolean;
        tcpIpMode: string; // "off"
    };
    srtPassphrase: string; // Password for SRT
    srtPort: number; // Default: 9000 + indexChannel
    srtReceiverUrl: string; // srt://127.0.0.1:9040
    upconvertMode: number;
    useGOPTimecode: boolean;
}

/**
 * Encode
 */

export class StreamSettingsEncode {
  AAFPath: string;
  CCLine: number; // 21
  DPPMetadata: {
    accessService: {
      captionLanguage: number;
      openCaption: number;
      signing: number;
      signingLanguage: number;
    };
    aditionalInfo: {
        completionDate: string;
        contactEmail: string;
        contactTelephone: string;
        language: number;
        programmeText: boolean;
        textlessElements: number;
        textlessElementsPresent: boolean;
    };
    audio: {
        audioComment: string;
        audioDescription: string; // "none"
        loudnessStandard: number; // 1
        primaryLanguage: number; // 0
        secondaryLanguage: number; // -1
        tertiaryLanguage: number; // -1
        trackLayout: number; // 0
    };
    descriptive: {
        copyrightYear: number; // 2014
        currentShim: number;
        distributor: string;
        episodeTitle: string; // "Episode Title"
        genre: string;
        originator: string; // "Originator"
        otherIdentifier: string;
        productNumber: string; // "Production Number"
        programmeTitle: string; // "Programme Title"
        seriesTitle: string; // "Series Title"
        synopsis: string; // "Synopsis"
        type: string;
    };
    timecodes: {
        firstPartNumber: number; // 1
        identifier: string; // "09:59:30:00"
        timecodes: any[];
    };
    video: {
        AFD: number; // 0
        PSE: number; // 0
        manufacturer: string; // "N\\A"
        model: string; // "N\\A"
        pictureRatio: number; // 0
        productPlacement: boolean;
        video3D: number; // 0
        videoComments: string;
    };
  };
  KMLInterval: number; // 1000
  KMLPath: string;
  XMLPath: string;
  actionAfterRecord: string; // "off" | "copy_file"
  activeAudioCodec: string; // "pcm"
  activeCodec: string; // "dnxhd"
  activeQuality: string; // "invalid"
  activeWrapper: string; // "avid_mxf"
  anamorphic: boolean;
  bigEndianType: boolean;
  captionsMode: string; // "no"
  copyRecordedFilePath: string; // path
  enabled: boolean;
  encoderName: string;
  ewcEnabled: boolean;
  fieldShift: boolean;
  fieldWrapped: boolean;
  forceBT709: boolean;
  forceProgressive: boolean;
  forceInterlaced: boolean;
  frameSizeType: string; // "normal"
  frameSizeScale: number;
  h264DefaultAudioCodec: string; // "pcm" | "aac"
  generateXMLMode: string; // "off"
  isCBR: boolean;
  isSegmentLimitMode: boolean;
  isTopFirst: boolean;
  overrideAAFPath: boolean;
  overrideKMLPath: boolean;
  overrideXMLPath: boolean;
  profile: string; // "Primary", "Proxy"
  recordMode: string; // "normal"
  sameDiskAsPrimary: boolean;
  sccField1: boolean;
  sccField2: boolean;
  segmentDuration: string; // "00:00:30:00"
  segmentLimit: number; // 0
  segmentMode: string; // "no"
  srtCC: string[]; // ["CC 1", "CC 3", "Service 1", "Service 2"]
  useMxfClipFolders: boolean;
  writeKMLMode: string; // "off"
  zeroBasedSrtCC: boolean;
}

/**
 * Input
 */

export class StreamSettingsInput {
  activeAnalogAudioSource: string; // "realtek"
  activeAudioInput: string; // "off"
  activeFrameRate: string; // "50"
  activePixelFormat: string; // "yuv8"
  activeResolution: string; // "pal"
  activeVideoInput: string; // "sdi"
  asioAudioSource: string;  // "Dante Virtual Soundcard (x64)"
  aspectRatioString: string; // "auto"
  audioDelays: number[]; // Array(64)
  breakSettings: boolean[]; // Array(64)
  dolbyEEnabled: boolean;
  dolbyEMask: number;
  dropFrame: boolean;
  duration: number;
  enabledSignalGenerator: boolean;
  enabledTone: boolean;
  frameDropBehavior: string; // "stop"
  frequency: number;
  hdr: boolean;
  hdrMatrixConst: boolean;
  hdrTransferPQ: boolean;
  ndiStreamSrc: string;
  muteSettings: boolean[]; // Array(64)
  picture: number;
  pictureFilePath: string;
  presetName: string;
  sequenceFilesPath: string;
  signalLossBehavior: number;
  toneSettings: number[]; // Array(64)
}

/**
 * Interpla Settings
 */

export class StreamSettingsInterplaSettings {
  enabled: boolean;
  ewcEnabled: boolean;
  headFrameNumber: number; // 0
  multiResProxy: boolean;
  password: string; // "Password"
  uri: string; // "Workgroup/path"
  url: string; // "http://localhost"
  username: string; // "User"
}

/**
 * Logger
 */

export class StreamSettingsLogger {
  osdErrors: boolean;
  osdWarnings: boolean;
  showErrors: boolean;
  showInfoMessages: boolean;
  showWarnings: boolean;
}

export class StreamSettingsLoggerSettings {
  enabled: boolean; // false
  exportFile: string; // '', 'avid', 'davinci', 'premiere', 'finalcut'
}

/**
 * Name Templates
 */

export class StreamSettingsNameTemplates {
  fileName: string; // "%P_%I_%E_%t"
  isPrimaryEdlSuffix: boolean;
  isPrimarySegSuffix: boolean;
  isSecondaryEdlSuffix: boolean;
  isSecondarySegSuffix: boolean;
  primaryEdlSuffix: string; // "_edl"
  primaryPath: string; // "\\%P\\%I\\%E"
  primarySegSuffix: string; // "_seg"
  secondaryEdlSuffix: string; // "_edl"
  secondaryPath: string; // "\\%P\\%I\\%E"
  secondarySegSuffix: string; // "_seg"
  tapeId: string; // "%P_%I"
}


/**
 * Scenes
 */

export class StreamSettingsScenes {
  currentIndex: number;
  scenes: {
    ext: boolean;
    mos: boolean;
    name: string; // "Scene1"
    night: boolean;
    roll: number;
    slate: number;
  }[];
}

/**
 * Keywords
 */

export class StreamSettingsKeywords {
  keywords: {
    keyword: string;
    selected: boolean;
  }[];
}

/**
 * User List
 */

export class StreamSettingsUserList {
  currentIndex: number;
  userList: any[];
}

/**
 * Wildcard
 */

export class StreamSettingsWildcard {
  expansion: string;
  title: string;
}

/**
 * Editshare
 */
export class StreamSettingsEditshare {
  enabled: boolean;
  username: string;
  password: string;
  ipAddress: string;
  port: number;
  multicamEnabled: boolean;
}


/**
 * Output
 */
export class StreamSettingsOutput {
  srtPlayoutEnable: boolean;
  srtListenerPassphrase: string;
  videoEncoderProfile: string; // 'baseline', 'main', 'heigh'
  videoEncoderBitrate: number; // 1000000..100000000 (UI use 1Mbs..100Mbs)
  videoEncoderKeyFrameDistance: number; // 1..72
  audioEncoderBitrate: number; // 128000...320000 (UI use 128Kbs...320Kbs)
  audioEncoderChannelCount: number; // 0..32
  audioEncoderChannelsInTrack: number; // 1..8
}

