import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Playlist } from '@modules/playlist-editor/types/playlist';
import { Stream } from '@modules/stream/types/stream';
import { StreamStatus } from '@modules/stream/types/stream-status';

@Component({
  selector: 'app-playlist-editor',
  templateUrl: './playlist-editor.component.html',
  styleUrls: ['./playlist-editor.component.less']
})
export class PlaylistEditorComponent implements OnInit, OnDestroy {

  // Public
  public stream: Stream;
  public playlist: Playlist;
  public status: StreamStatus;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    public streamService: StreamService,
    public playlistService: PlaylistService,
    public dialogRef: MatDialogRef<PlaylistEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {streamId: string, playlist: Playlist},
  ) {
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Stream
    this.streamService.getStream(this.data.streamId)
      .pipe(takeUntil(this.alive))
      .subscribe(stream => this.stream = stream);
    // Set Playlist
    this.playlist = this.data.playlist;
     // Check status and open in Edit Mode playlist
     this.streamService.getStatus(this.stream)
     .pipe(
       take(1)
     )
     .subscribe(status => {
      if (status?.playback?.openPlaylistName === this.playlist.name && status?.playback?.isLoaded) {
        this.streamService.ejectFile(this.stream)
          .subscribe(() => this.playlistService.playPlaylist(this.stream, this.playlist, true));
      }
     });
    // Status
    this.streamService.getStatus(this.stream)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.alive),
      )
      .subscribe(status => this.status = status);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  close(): void {
    if (this.status?.playback?.openPlaylistName === this.playlist.name && this.status?.playback?.isLoaded) {
      this.streamService.ejectFile(this.stream)
        .subscribe(() => this.playlistService.playPlaylist(this.stream, this.playlist, false));
    }
    this.dialogRef.close();
  }

}
