export class InputSettingsCopy {
  // Encoders
  primaryEncoder: boolean;
  secondaryEncoder: boolean;
  tertiaryEncoder: boolean;

  // Inputs
  inputType: boolean;
  audioSource: boolean;
  resolution: boolean;
  frameRate: boolean;
  colorFormat: boolean;
  audioInputRouting: boolean;
  audioOutputRouting: boolean;
  codec: boolean;
  quality: boolean;
  wrapper: boolean;
  recordDestinations: boolean;
  outputOverlays: boolean;
  allSettings: boolean;
  bestEffort: boolean;
  srtOutput: boolean;
  burns: boolean;

  constructor() {
    this.primaryEncoder = true;
    this.secondaryEncoder = true;
    this.tertiaryEncoder = true;
    this.inputType = true;
    this.audioSource = true;
    this.resolution = true;
    this.frameRate = true;
    this.colorFormat = true;
    this.audioInputRouting = true;
    this.audioOutputRouting = true;
    this.codec = true;
    this.quality = true;
    this.wrapper = true;
    this.recordDestinations = true;
    this.outputOverlays = true;
    this.allSettings = true;
    this.bestEffort = true;
    this.srtOutput = true;
    this.burns = true;
  }
}
