import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { UserService } from '@modules/users/services/user.service';

// Types
import { MatDialogRef } from '@angular/material/dialog';
import { PermissionKey, Permissions } from '@modules/users/types/permissions';
import { User, UserNames } from '@modules/users/types/user';

class PasswordFields {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-users-permissions',
  templateUrl: './users-permissions.component.html',
  styleUrls: ['./users-permissions.component.less']
})
export class UsersPermissionsComponent implements OnInit, OnDestroy {

  // Public
  public user: User;
  public permissions: Observable<Permissions>;
  public permissionsAdmin: Observable<Permissions>;
  public permissionsUser: Observable<Permissions>;
  public permissionOptions: {key: PermissionKey, title: string}[] = [
    {key: 'changePassowrd', title: 'Change User Passwords'},
    {key: 'configureInputSources', title: 'Configure Input Sources'},
    {key: 'controlRecord', title: 'Control Record'},
    {key: 'editPresetSettings', title: 'Edit Preset Settings'},
    {key: 'editPathTemplates', title: 'Edit Preset Naming & Path Templates'},
    {key: 'editDiskDestination', title: 'Edit Preset Disk Destinations'},
    {key: 'editLists', title: 'Edit List Contents'},
    // {key: 'editTags', title: 'Edit Tags/Keywords'},
    {key: 'editSelectionsGroups', title: 'Edit Selection Groups'},
    {key: 'addScheduledRecords', title: 'Add Scheduled Records'},
    {key: 'editScheduledRecords', title: 'Edit Scheduled Records'},
    // {key: 'deleteRecordedFiles', title: 'Delete Recording Files'},
  ];
  public currentUserPassword = new PasswordFields();
  public adminPassword = new PasswordFields();
  public userPassword = new PasswordFields();
  public guestPassword = new PasswordFields();
  public errors = {
    changePassword: false,
    changeAdminPassword: false,
    changeUserPassword: false,
    changeGuestPassword: false,
  };
  public saved = {
    changePassword: false,
    changeAdminPassword: false,
    changeUserPassword: false,
    changeGuestPassword: false,
  };

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<UsersPermissionsComponent>,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get user permissions
    this.userService.getCurrentUser()
      .pipe(takeUntil(this.alive))
      .subscribe(user => this.user = user);
    this.permissions = this.userService.getCurrentPermissions();
    this.permissionsAdmin = this.userService.getPermissions('admin');
    this.permissionsUser = this.userService.getPermissions('user');
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Action
   */

  close(): void {
    this.dialogRef.close();
  }

  updatePermission(user: UserNames, key: PermissionKey, value: boolean): void {
    this.userService.updatePermissions(user, {[key]: value} as any);
  }

  changePassword(user: UserNames, password: string, confirmPassword: string, field: string): void {
    if (password === confirmPassword) {
      this.userService.updatePassword(user, password);
      this.saved[field] = true;
      setTimeout(() => this.saved[field] = false, 1500);
    } else {
      this.errors[field] = true;
    }
  }

  resetError(field: string): void {
    this.errors[field] = false;
  }

}
