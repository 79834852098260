<div class="stream-grid-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Previews</div>
    <div class="actions">
      <mat-icon [svgIcon]="locked? 'lock' : 'unlock'" class="action-icon" (click)="lock()"></mat-icon>
      <mat-icon svgIcon="channel-grid" class="action-icon" (click)="showChannelGrid()"></mat-icon>
      <!-- <mat-icon svgIcon="fullscreen" class="action-icon"></mat-icon> -->
    </div>
  </div>

  <!-- Content -->
  <div
    class="channels-list-content"
    appDroppable
    (appDroppableEnter)="dndEnter($event)"
    (appDroppableLeave)="dndLeave($event)"
    (appDroppableOver)="dndDragOver($event)"
    (appDroppableDrop)="dndDrop($event)"
    cdkDropListGroup
  >
    <div class="channels-list" cdkDropList (cdkDropListDropped)="dndDropList($event)" cdkDragBoundary=".channels-list-content">
      <app-channels-list-item
        class="channel"
        *ngFor="let channel of channels; let index = index"
        [channel]="channel"
        [channelIndex]="index + 1"
        cdkDrag
      ></app-channels-list-item>
      <div class="new-stream" *ngIf="dropArea.show && dropArea.type === 'stream'">Add new stream</div>
    </div>
  </div>

  <!-- Placeholder -->
  <div class="placeholder" *ngIf="!channels?.length && !dropArea.show">
    <div class="title">Channel List Empty</div>
    <div class="description">Drag and Drop Input here</div>
  </div>

</div>

<!-- Fullscreen Channel -->
<div class="stream-grid-content fullscreen" *ngIf="fullscreenChannel">

  <!-- Content -->
  <div class="list-content">
    <app-channel
      class="stream"
      [selected]="false"
      [disabled]="locked"
      [channel]="fullscreenChannel"
      [fullscreen]="true"
    ></app-channel>
  </div>

</div>
