import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

// Lodash
import { get, has } from 'lodash-es';

// Types
import { Option } from '@modules/elements/types/option';
import { StreamSettings } from '@modules/stream/types/stream-settings';

type OptionValue = string | number | boolean;

@Component({
  selector: 'app-preset-settings-option',
  templateUrl: './preset-settings-option.component.html',
  styleUrls: ['./preset-settings-option.component.less']
})
export class PresetSettingsOptionComponent implements OnInit, OnChanges {

  // Inputs
  @Input() title: string;
  @Input() key: string;
  @Input() settings: StreamSettings;
  @Input() streamSettings: Partial<StreamSettings>;
  @Input() options: Option<OptionValue>[];
  @Input() selected: Option<OptionValue> | OptionValue;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() highlightInherit: boolean = true;
  @Input() forceHighlight: boolean = false;

  // Output
  @Output() selectedChange = new EventEmitter<{key: string, value: OptionValue}>();

  // Public
  public value: any;
  public useDefault: boolean = false;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('key' in changes || 'settings' in changes || 'streamSettings' in changes || 'options' in changes  || 'selected' in changes) {
      this.updateValue();
    }
  }

  /**
   * Methods
   */

  updateValue(): void {
    this.useDefault = this.streamSettings ? !has(this.streamSettings, this.key) : true;
    if (this.selected != null) {
      this.value = this.selected;
    } else {
      this.value = get((this.useDefault ? this.settings : this.streamSettings), this.key);
    }
  }

  selectOption(value: OptionValue): void {
    this.selectedChange.emit({key: this.key, value});
  }

}
