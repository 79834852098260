import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';

// External modules
import { ToastrModule } from 'ngx-toastr';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { ColorSketchModule } from 'ngx-color/sketch';
import { NgxMaskModule, IConfig } from 'ngx-mask'

// Modules
import { PopoverModule } from '@modules/popover/popover.module';

// Directives
import { ClickDirective } from './directives/click/click.directive';
import { TimeMaskDirective } from './directives/time-mask/time-mask.directive';
import { MoveElementDirective } from './directives/move-element/move-element.directive';

// Services
import { AlertService } from './services/alert.service';
import { NotificationService } from './services/notification.service';
import { HotkeysService } from './services/hotkeys.service';

// Pipes
import { FileSizePipe } from './pipes/file-size.pipe';
import { IncludesByPipe } from './pipes/includes-by.pipe';
import { RrulePipe } from './pipes/rrule.pipe';
import { PathPipe } from './pipes/path.pipe';
import { ExcludePipe } from './pipes/exclude.pipe';
import { DifferencePipe } from './pipes/difference.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { FilenameNormalizePipe } from './pipes/filename-normalize.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';

// Components
import { SelectComponent } from './components/select/select.component';
import { CreateItemPopoverComponent } from './components/create-item-popover/create-item-popover.component';
import { DiscProgressComponent } from './components/disc-progress/disc-progress.component';
import { VolumePopoverComponent } from './components/volume-popover/volume-popover.component';
import { InputInlineComponent } from './components/input-inline/input-inline.component';
import { InputSettingsComponent } from './components/input-settings/input-settings.component';
import { InputTimecodeComponent } from './components/input-timecode/input-timecode.component';
import { ColorSelectComponent } from './components/color-select/color-select.component';
import { OnlineStatusComponent } from './components/online-status/online-status.component';
import { PlayerProgressBarComponent } from './components/player-progress-bar/player-progress-bar.component';
import { TimecodeInputPopoverComponent } from './components/timecode-input-popover/timecode-input-popover.component';
import { MarkerProgressBarItemComponent } from './components/marker-progress-bar-item/marker-progress-bar-item.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,

    // External
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true,
      includeTitleDuplicates: true
    }),
    AutoSizeInputModule,
    ColorSketchModule,
    NgxMaskModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatSliderModule,
  ],
  declarations: [
    SelectComponent,
    CreateItemPopoverComponent,
    DiscProgressComponent,
    VolumePopoverComponent,
    InputInlineComponent,
    InputSettingsComponent,
    InputTimecodeComponent,
    FileSizePipe,
    IncludesByPipe,
    RrulePipe,
    PathPipe,
    ClickDirective,
    TimeMaskDirective,
    MoveElementDirective,
    ExcludePipe,
    DifferencePipe,
    ColorSelectComponent,
    FilenameNormalizePipe,
    OnlineStatusComponent,
    PlayerProgressBarComponent,
    TimecodeInputPopoverComponent,
    MarkerProgressBarItemComponent,
    DateAgoPipe,
    HighlightSearchPipe,
  ],
  exports: [
    SelectComponent,
    CreateItemPopoverComponent,
    DiscProgressComponent,
    VolumePopoverComponent,
    InputInlineComponent,
    InputSettingsComponent,
    InputTimecodeComponent,
    IncludesByPipe,
    FileSizePipe,
    RrulePipe,
    PathPipe,
    ExcludePipe,
    DifferencePipe,
    ClickDirective,
    TimeMaskDirective,
    MoveElementDirective,
    ColorSelectComponent,
    FilenameNormalizePipe,
    DateAgoPipe,
    HighlightSearchPipe,
    OnlineStatusComponent,
    PlayerProgressBarComponent,
    TimecodeInputPopoverComponent,
    MarkerProgressBarItemComponent,
  ],
  providers: [
    FileSizePipe,
    IncludesByPipe,
    RrulePipe,
    PathPipe,
    ExcludePipe,
    DifferencePipe,
    FilenameNormalizePipe,
    DateAgoPipe,
    HighlightSearchPipe,
    AlertService,
    NotificationService,
    HotkeysService,
  ],
})
export class ElementsModule { }
