import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { MatDialog } from '@angular/material/dialog';
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';
import { ChannelService } from '@modules/channel/services/channel.service';
import { UserService } from '@modules/users/services/user.service';

// Components
import { PresetNameSettingsComponent } from '../preset-name-settings/preset-name-settings.component';

// Types
import { Stream } from '@modules/stream/types/stream';
import { Preset } from '@modules/preset/types/preset';

@Component({
  selector: 'app-preset-name',
  templateUrl: './preset-name.component.html',
  styleUrls: ['./preset-name.component.less']
})
export class PresetNameComponent implements OnInit, OnDestroy {

  // Public
  public locked = false;
  public allowed: boolean;
  public stream: Stream;
  public preset: Preset;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private dialog: MatDialog,
    private channelService: ChannelService,
    private streamService: StreamService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    userService: UserService
  ) {
    userService
      .getCurrentPermission('editPathTemplates')
      .pipe(takeUntil(this.alive))
      .subscribe(allowed => this.allowed = allowed);
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    this.channelService.getSelectedChannels()
      .pipe(
        map(channels => channels.length === 1 ? channels[0] : null),
        switchMap(channel => this.streamService.getStream(channel?.streamId)),
        tap(stream => this.stream = stream),
        switchMap(stream => this.presetService.getPreset(stream?.presetId)),
        takeUntil(this.alive),
      )
      .subscribe(preset => {
        this.preset = preset;
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  openSettings(): void {
    this.dialog.open(PresetNameSettingsComponent, {data: {stream: this.stream}, disableClose: true});
  }

  updateSettings(): void {
    this.presetSettingsService.updatePresetNameTemplate(this.preset);
  }

  updateNameTemplate(nameTemplate: string, field: string, encoderIndex: number = 0): void {
    this.preset.nameTemplates.nameTemplates[encoderIndex][field] = nameTemplate;
    this.updateSettings();
  }

  updateSecondaryPathSame(encoderIndex: number): void {
    if (this.preset.nameTemplates.secondaryPathSame[encoderIndex]) {
      this.preset.nameTemplates.nameTemplates[encoderIndex].secondaryPath = this.preset.nameTemplates.nameTemplates[encoderIndex].primaryPath;
    }
    this.updateSettings();
  }

}
