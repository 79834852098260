<div class="preset-record-encoder-settings-content">

  <!-- Options -->
  <div class="settings-options">

    <!-- Codec -->
    <div class="option">
      <div class="title">Codec</div>
      <div class="value">{{ getEncodeTitle('activeCodec', 'encoderCodec') }}</div>
    </div>

    <!-- Quality -->
    <div class="option">
      <div class="title">Quality</div>
      <div class="value">{{ getEncodeTitle('activeQuality', 'encoderQuality') }}</div>
    </div>

    <!-- Wrapper -->
    <div class="option">
      <div class="title">Wrapper</div>
      <div class="value">{{ getEncodeTitle('activeWrapper', 'encoderWrapper') }}</div>
    </div>

    <!-- Audio Count -->
    <div class="option">
      <div class="title">Audio Count</div>
      <div class="value">{{ getAudioChannelCount() }}</div>
    </div>

    <div class="break"></div>

    <!-- TC Source -->
    <div class="option">
      <div class="title">TC Source</div>
      <div class="value">{{ getTitle(settings?.automation?.tcSource, 'encoderTcSource') }}</div>
    </div>

    <!-- CC -->
    <div class="option">
      <div class="title">CC</div>
      <div class="value">{{ getEncodeTitle('captionsMode', 'encoderCaptions') }}</div>
    </div>

    <!-- Burns -->
    <div class="option">
      <div class="title">Burns</div>
      <div class="value">Off</div>
    </div>

    <!-- Segment record -->
    <div class="option">
      <div class="title">Segment record</div>
      <div class="value">{{ getEncodeTitle('segmentMode', 'encoderSegmentRecord') }}</div>
    </div>

    <!-- Write Type -->
    <div class="option">
      <div class="title">Write Type</div>
      <div class="value">{{ getTitle(settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activeWriteMode, 'writeMode') }}</div>
    </div>

  </div>

  <!-- Primary -->
  <div class="encoder-info">

    <!-- Disk same like primary -->
    <div class="encoder-path" *ngIf="encoderIndex > 0 && settings.encode[encoderIndex].sameDiskAsPrimary">
      <div class="text">Match proxy disk destination to primary</div>
    </div>

    <!-- Path -->
    <div class="encoder-path">
      <div class="label">Primary</div>
      <app-select
        class="select"
        placeholder="None"
        [disabled]="encoderIndex > 0 && settings.encode[encoderIndex].sameDiskAsPrimary"
        [options]="drivesOptions"
        [selected]="
          settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.overridePrimaryPath
            ? 'pathOverride'
            : settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activePrimaryDrivePos
        "
        (selectedChange)="selectPrimaryDrive($event)"
      ></app-select>
      <div class="label second">Path Override</div>
      <div class="button" (click)="selectPrimatyPathOverride()">select</div>
    </div>

    <!-- Path Override -->
    <div class="path-override" *ngIf="settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.overridePrimaryPath"><b>Path override: </b>{{ settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.primaryPath | path }}</div>

    <!-- Disc space -->
    <div class="encoder-disc">
      <app-disc-progress *ngIf="primaryDeckDrive" [total]="primaryDeckDrive?.totalBytes" [free]="primaryDeckDrive?.availBytes"></app-disc-progress>
    </div>

  </div>

  <!-- Redundant -->
  <div class="encoder-info" *ngIf="settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activeWriteMode === 'redundant' || settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activeWriteMode === 'rollover'">

    <!-- Path -->
    <div class="encoder-path">
      <div class="label">Redundant</div>
      <app-select
        class="select"
        placeholder="None"
        [disabled]="!(settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activeWriteMode === 'redundant' || settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activeWriteMode === 'rollover') || (encoderIndex > 0 && settings.encode[encoderIndex].sameDiskAsPrimary)"
        [options]="drivesOptions | exclude: settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activePrimaryDrivePos: 'value'"
        [selected]="
          settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.overrideSecondaryPath
            ? 'pathOverride'
            : settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.activeSecondaryDrivePos
        "
        (selectedChange)="selectSecondaryDrive($event)"
      ></app-select>
      <div class="label second">Path Override</div>
      <div class="button" (click)="selectSecondaryPathOverride()">select</div>
    </div>

    <!-- Path Override -->
    <div class="path-override" *ngIf="settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.overrideSecondaryPath"><b>Path override: </b>{{ settings?.channels[stream?.deckChannel]?.driveAssignments[encoderIndex]?.secondaryPath | path }}</div>

    <!-- Disc space -->
    <div class="encoder-disc">
      <app-disc-progress *ngIf="secondaryDeckDrive" [total]="secondaryDeckDrive?.totalBytes" [free]="secondaryDeckDrive?.availBytes"></app-disc-progress>
    </div>

  </div>

</div>
