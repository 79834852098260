import { UniqueID } from '@modules/elements/types/unique-id';

export class UserList {
  id: string;
  name: string;
  color: string;
  value: string; // short name for name template (Example: %1, %2, etc)
  items: UserListItem[];
  selected: UserListItem;

  constructor(name: string, color: string, value: string, items: UserListItem[] = [], selected?: UserListItem) {
    this.id = UniqueID.generate();
    this.name = name;
    this.color = color;
    this.value = value;
    this.items = items;
    this.selected = selected;
  }

  static defaultUserList(): UserList[] {
    return [
      new UserList('List 1', '#732C2C', '%1'),
      new UserList('List 2', '#86632F', '%2'),
      new UserList('List 3', '#678132', '%3'),
      new UserList('List 4', '#2D777B', '%4'),
      new UserList('List 5', '#4836B2', '%5'),
      new UserList('List 6', '#9236B2', '%6'),
      new UserList('List 7', '#003399', '%7'),
      new UserList('List 8', '#808000', '%8'),
      new UserList('List 9', '#355e3b', '%9'),
      new UserList('List 10','#b5651d', '%0'),
    ];
  }

  static defaultGlobalList(): UserList[] {
    return [
      new UserList('Global 1', '#004714', '%A'),
      new UserList('Global 2', '#00611b', '%B'),
      new UserList('Global 3', '#007A22', '%F'),
      new UserList('Global 4', '#009429', '%J'),
      new UserList('Global 5', '#00ad30', '%K'),
      new UserList('Global 6', '#00c737', '%L'),
    ];
  }
}

export class UserListItem {
  id: string;
  name: string;

  constructor(name: string) {
    this.id = UniqueID.generate();
    this.name = name ?? '';
  }
}
