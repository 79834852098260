<div
  class="playlist-files-content"
  (drop)="uploadDropedFiles($event)"
  (dragenter)="dndEnter($event)"
  (dragleave)="dndLeave($event)"
  (dragover)="dndOver($event)"
  appDroppable
  [appDroppableZIndex]="10"
  (appDroppableEnter)="dndEnter($event)"
  (appDroppableLeave)="dndLeave($event)"
  (appDroppableDrop)="appDndDrop($event)"
>

  <!-- Header -->
  <div class="list-header">
    <div class="title">Clips</div>
    <div class="actions" *ngIf="status?.playback">
      <mat-icon *ngIf="isModal" svgIcon="play" class="icon play-icon" [class.selected]="status?.playback?.openPlaylistName === playlist.name && status?.playback?.isLoaded" (click)="playPlaylist()" matTooltip="Play" matTooltipShowDelay="1000"></mat-icon>
      <mat-icon *ngIf="!isModal" svgIcon="settings-sliders" class="icon" (click)="editPlaylist()" matTooltip="Edit" matTooltipShowDelay="1000"></mat-icon>
      <app-select class="select" [options]="playlistBehaviour.cue" [selected]="status.playback.playlistCueBehavior" (selectedChange)="updatePlaylistCueBehavior($event.value)" placeholder="N/A" matTooltip="Playlist cue behaviour"></app-select>
      <app-select class="select" [options]="playlistBehaviour.play" [selected]="status.playback.playlistPlayBehavior" (selectedChange)="updatePlaylistPlayBehavior($event.value)" placeholder="N/A" matTooltip="Playlist play behaviour"></app-select>
    </div>
  </div>

  <!-- Player -->
  <div class="playlist-files">

    <div class="file-list" cdkDropList [cdkDropListData]="clips" (cdkDropListDropped)="drop($event)">

      <app-playlist-files-item
        *ngFor="let clip of clips; let index = index"
        [stream]="stream"
        [clip]="clip"
        [index]="index"
        [thumbnailUrl]="thumbnailUrl"
        [selected]="selection.isSelected(clip)"
        [played]="status?.playback?.clipPosition > (+clip.position) && status?.playback?.openPlaylistName === playlist.name && status?.playback?.isLoaded"
        [currentPlay]="status?.playback?.clipPosition === (+clip.position) && status?.playback?.openPlaylistName === playlist.name && status?.playback?.isLoaded"
        cdkDrag
        cdkDragLockAxis="y"
        [cdkDragData]="clip"
        [contextMenu]="inputMenu"
        [contextMenuSubject]="clip"
        (appDblClick)="jumpToClip(clip)"
        (openContextMenu)="openContextMenu($event, inputMenu, clip)"
      >
        <div cdkDragHandle></div>
      </app-playlist-files-item>

    </div>

  </div>

  <!-- Drop placeholder -->
  <div *ngIf="dropPlaceholder" class="drop-placeholder" [class.error]="invalidDropFile">
    <div class="text" *ngIf="!invalidDropFile">Drop here</div>
    <div class="text" *ngIf="invalidDropFile">Can't add file(s)</div>
    <div class="description" *ngIf="invalidDropFile">(fps, resolution and color space must match)</div>
  </div>

</div>

<!-- Context menu -->
<context-menu #inputMenu>
  <ng-template contextMenuItem [visible]="!isModal" let-item (execute)="editPlaylist()">Edit Playlist</ng-template>
  <ng-template contextMenuItem [enabled]="isValidOpenClipFunc" let-item (execute)="jumpToClip($event.item)">Jump To Clip</ng-template>
  <ng-template contextMenuItem [enabled]="isValidDeleteClipFunc" let-item (execute)="deleteFile($event.item)">Delete</ng-template>
</context-menu>
