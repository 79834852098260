<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">User settings</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>

<div mat-dialog-content>

  <div class="users-permissions-content">

    <!-- Permissions -->
    <div class="permissions-section" *ngIf="user?.name === 'root' || user?.name === 'admin'">

      <!-- Admin -->
      <div class="settings-section" *ngIf="user?.name === 'root' && (permissionsAdmin | async) as premissions">
        <div class="settings-header">Administrator</div>
        <div class="settings-content">
          <div class="permisions">
            <mat-checkbox
              *ngFor="let value of permissionOptions"
              class="checkbox"
              [ngModel]="premissions[value.key]"
              (ngModelChange)="updatePermission('admin', value.key, $event)"
              disableRipple
            >{{ value.title }}</mat-checkbox>
          </div>
        </div>
      </div>

      <!-- User -->
      <div class="settings-section" *ngIf="(permissionsUser | async) as premissions">
        <div class="settings-header">Operator</div>
        <div class="settings-content">
          <div class="permisions">
            <mat-checkbox
              *ngFor="let value of permissionOptions"
              class="checkbox"
              [ngModel]="premissions[value.key]"
              (ngModelChange)="updatePermission('user', value.key, $event)"
              disableRipple
            >{{ value.title }}</mat-checkbox>
          </div>
        </div>
      </div>

    </div>

    <!-- Change Password -->
    <div class="settings-section" *ngIf="(permissions | async)['changePassowrd']">
      <div class="settings-header">Change Password ({{ user?.displayName }})</div>
      <div class="settings-content">
        <div class="change-password">

          <!-- Password -->
          <div class="field">
            <div class="label">New Password</div>
            <input class="input" type="password" [(ngModel)]="currentUserPassword.password" (ngModelChange)="resetError('changePassword')">
          </div>

          <!-- Confirm password -->
          <div class="field">
            <div class="label">Re-Enter password</div>
            <input class="input" type="password" [(ngModel)]="currentUserPassword.confirmPassword" (ngModelChange)="resetError('changePassword')" [class.error]="errors.changePassword">
          </div>

          <div class="button" [class.success]="saved.changePassword" (click)="changePassword(user?.name, currentUserPassword.password, currentUserPassword.confirmPassword, 'changePassword')">{{ saved.changePassword ? 'Saved' : 'Save'}}</div>

        </div>

        <!-- Error -->
        <div class="message-error" *ngIf="errors.changePassword">Passwords do not match</div>

      </div>
    </div>


    <!-- Edit Passwords -->
    <div class="settings-section" *ngIf="user?.name === 'root'">
      <div class="settings-header">Edit Passwords</div>
      <div class="settings-content">
        <div class="edit-password">

          <div class="first-labels">
            <div class="label">Passwords are Case Sensitive</div>
            <div class="label">Re-Enter Password</div>
          </div>

          <div class="field">
            <div class="label">Administrator</div>
            <input class="input" type="password" [(ngModel)]="adminPassword.password" (ngModelChange)="resetError('changeAdminPassword')">
            <input class="input" type="password" [(ngModel)]="adminPassword.confirmPassword" (ngModelChange)="resetError('changeAdminPassword')" [class.error]="errors.changeAdminPassword">
            <div class="button" [class.success]="saved.changeAdminPassword" (click)="changePassword('admin', adminPassword.password, adminPassword.confirmPassword, 'changeAdminPassword')">{{ saved.changeAdminPassword ? 'Saved' : 'Save'}}</div>
            <div class="message-error" *ngIf="errors.changeAdminPassword">Passwords do not match</div>
          </div>

          <div class="field">
            <div class="label">Operator</div>
            <input class="input" type="password" [(ngModel)]="userPassword.password" (ngModelChange)="resetError('changeUserPassword')">
            <input class="input" type="password" [(ngModel)]="userPassword.confirmPassword" (ngModelChange)="resetError('changeUserPassword')" [class.error]="errors.changeUserPassword">
            <div class="button" [class.success]="saved.changeUserPassword" (click)="changePassword('user', userPassword.password, userPassword.confirmPassword, 'changeUserPassword')">{{ saved.changeUserPassword ? 'Saved' : 'Save'}}</div>
            <div class="message-error" *ngIf="errors.changeUserPassword">Passwords do not match</div>
          </div>

          <div class="field">
            <div class="label">Guest</div>
            <input class="input" type="password" [(ngModel)]="guestPassword.password" (ngModelChange)="resetError('changeGuestPassword')">
            <input class="input" type="password" [(ngModel)]="guestPassword.confirmPassword" (ngModelChange)="resetError('changeGuestPassword')" [class.error]="errors.changeGuestPassword">
            <div class="button" [class.success]="saved.changeGuestPassword" (click)="changePassword('guest', guestPassword.password, guestPassword.confirmPassword, 'changeGuestPassword')">{{ saved.changeGuestPassword ? 'Saved' : 'Save'}}</div>
            <div class="message-error" *ngIf="errors.changeGuestPassword">Passwords do not match</div>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>
