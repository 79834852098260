<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">{{ editing ? 'Edit' : 'Create'}} Schedule</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="schedule-create-content">

    <div class="schedule-form">

      <!-- Input or Group -->
      <div class="settings-option stream">
        <div class="preset-settings-title-big">Input or Group</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="inputOptions" [selected]="streamId" (selectedChange)="updateInput($event)" [placeholder]="'None'"></app-select>
        </div>
      </div>

      <!-- Preset -->
      <div class="settings-option preset">
        <div class="preset-settings-title-big">Preset</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="presetsOptions" [selected]="schedule?.presetId" (selectedChange)="updatePreset($event)" [placeholder]="'None'"></app-select>
        </div>
      </div>

      <!-- Action -->
      <div class="settings-option">
        <div class="preset-settings-title-big">Action</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="actionsOptions" [selected]="schedule?.action" (selectedChange)="updateInput($event)"></app-select>
        </div>
      </div>

      <!-- Interval -->
      <div class="settings-option">
        <div class="preset-settings-title-big">Interval</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="intervalOptions" [selected]="schedule?.interval" (selectedChange)="updateInterval($event)"></app-select>
        </div>
      </div>

      <!-- Frequency -->
      <div class="settings-option" *ngIf="schedule?.interval === 'repeat'">
        <div class="preset-settings-title-big">Frequency</div>
        <div class="multi-tile">
          <app-select class="full-tile-select" [options]="frequencyOptions" [selected]="rrule?.options?.freq" (selectedChange)="updateFrequency($event)"></app-select>
        </div>
      </div>

      <!-- Weekdays -->
      <div class="settings-option settings-weekdays" *ngIf="schedule?.interval === 'repeat' && rrule?.origOptions?.freq === 2">
        <div class="preset-settings-title-big">Weekdays</div>
        <div class="multi-tile">
          <div class="weekdays">
            <div
              *ngFor="let day of weekdayOptions"
              class="day"
              [class.selected]="rrule?.origOptions?.byweekday | includesBy: day.value.weekday : 'weekday'"
              (click)="updateWeekday(day)"
            >{{ day.title | uppercase | slice:0:2 }}</div>
          </div>
        </div>
      </div>

      <!-- Date -->
      <div class="settings-option" *ngIf="schedule?.interval === 'once' || (schedule?.interval === 'repeat' && rrule?.origOptions?.freq === 1)">
        <div class="preset-settings-title-big">Date</div>
        <div class="multi-tile">
          <input class="input full-tile-select" [matDatepicker]="picker" [min]="minDate" [value]="schedule.startDate" (dateChange)="updateDate($event)"  (focus)="picker.open()" [placeholder]="'None'">
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>

      <!-- Start time -->
      <div class="settings-option time">
        <div class="preset-settings-title-big">Start time</div>
        <div class="multi-tile">
          <input class="input full-tile-select" [class.error]="error.noTime || error.startTime || error.pastTime" appTimeMask [(ngModel)]="startTime" (ngModelChange)="updateTime($event)">
        </div>
      </div>

      <!-- End time -->
      <div class="settings-option time">
        <div class="preset-settings-title-big">End time</div>
        <div class="multi-tile">
          <input class="input full-tile-select" [class.error]="error.noTime || error.startTime" appTimeMask [(ngModel)]="endTime" (ngModelChange)="updateTime($event)">
        </div>
      </div>

    </div>

    <!-- Errors -->
    <div class="error" *ngIf="error.startTime || error.intersectionTime || error.pastTime">
      <div *ngIf="error.noTime">Set start time or end time</div>
      <div *ngIf="error.startTime">Start time can't be later than end time</div>
      <div *ngIf="error.pastTime">Time can't be specified in the past</div>
      <div *ngIf="error.intersectionTime">Recording time overlaps with another event</div>
    </div>

  </div>
</div>
<div mat-dialog-actions class="mat-dialog-actions dialog-actions">
  <div class="button" (click)="close()">Cancel</div>
  <div class="button" (click)="save()" [class.disabled]="!validation()">Save</div>
</div>
