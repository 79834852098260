import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// RxJS
import { BehaviorSubject, combineLatest, Observable, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

// Lodash
import { isEqual } from 'lodash-es';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { DeckService } from '@modules/deck/services/deck.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { ChannelService } from '@modules/channel/services/channel.service';

// Types
import { Stream } from '@modules/stream/types/stream';
import { RecordingFilename } from '@modules/stream/types/recording-filename';
import { DeckDrive } from '@modules/deck/types/deck-status';
import { StreamSettings, StreamSettingsChannelDriveAssignments, StreamSettingsEncode } from '@modules/stream/types/stream-settings';
import { Channel } from '@modules/channel/types/channel';

class ChannelDiskInfo {
  encoder: string;
  disk: DeckDrive;
  path: string;
  override: boolean;

  constructor(encoder: string, disk: DeckDrive, path: string, override: boolean) {
    this.encoder = encoder;
    this.disk = disk;
    this.path = path;
    this.override = override;
  }
}

@Component({
  selector: 'app-channel-disks',
  templateUrl: './channel-disks.component.html',
  styleUrls: ['./channel-disks.component.less']
})
export class ChannelDisksComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() stream: Stream
  @Input() channel: Channel;

  // Public
  public drives: DeckDrive[] = [];
  public recordingFilenames: RecordingFilename[];
  public showFilename = true;
  public info: ChannelDiskInfo[] = [];
  public showAllView = false;

  // Private
  private isRecord = false;
  private alive = new Subject<void>();
  private updateInfo = new Subject<void>();
  private changeView = new BehaviorSubject<void>(null);

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    private deckService: DeckService,
    private presetService: PresetService,
    private channelService: ChannelService,
    public elementRef: ElementRef
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Change view time
    const timeUpdate = this.channelService.getChannel(this.channel.id)
      .pipe(
        map(channel => channel?.layoutSettings?.disksTimeUpdate ?? 10000),
        distinctUntilChanged(),
        takeUntil(this.alive)
      );

    // Update show file view
    combineLatest([this.changeView, timeUpdate])
      .pipe(
        tap(([_, time]) => this.showAllView = time < 0),
        switchMap(([_, time]) => timer(0, time > 0 ? time : null)),
        takeUntil(this.alive)
      )
      .subscribe(() => this.showFilename = !this.showFilename);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('stream' in changes) {
      this.getInfo();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
    this.updateInfo.next();
    this.updateInfo.complete();
  }

  /**
   * Methods
   */

  getInfo(): void {
    this.updateInfo.next();

    const recordingFilenamesObserver = combineLatest([
      this.presetService.getPreset(this.stream.presetId),
      this.streamService.projectUpdated(this.stream).pipe(startWith(0))
    ])
      .pipe(
        debounceTime(300),
        switchMap(() => this.streamService.getRecordingFilenames(this.stream)),
        takeUntil(this.updateInfo)
      )

    // Get Drive encoders
    const encodersObserver: Observable<StreamSettingsEncode[]> = this.presetService.getPreset(this.stream?.presetId)
      .pipe(
        map(preset => preset?.getSetting(this.stream?.id)),
        map((settings: StreamSettings) => settings?.encode),
        takeUntil(this.updateInfo)
      );

    // Get Drive encoders
    const driveAssignmentsObserver: Observable<StreamSettingsChannelDriveAssignments[]> = this.presetService.getPreset(this.stream?.presetId)
      .pipe(
        map(preset => preset?.getSetting(this.stream?.id)),
        map((settings: StreamSettings) => settings?.channels[this.stream?.deckChannel]?.driveAssignments),
        distinctUntilChanged(isEqual),
        takeUntil(this.updateInfo)
      );

    // Get disks info
    const deck = this.deckService.getDeckSync(this.stream?.deckId);
    const drivesObserver: Observable<DeckDrive[]> = this.deckService.getDrives(deck)
      .pipe(
        takeUntil(this.updateInfo)
      );

    // Update drives
    combineLatest([
      encodersObserver,
      drivesObserver,
      driveAssignmentsObserver,
      recordingFilenamesObserver
    ])
      .pipe(
        takeUntil(this.updateInfo),
        map(([encoders, drives, driveAssignments, filenames]) => {
          const info = [];
          encoders?.forEach((encoder, index) => {
            if (!encoder.enabled || !driveAssignments?.length) {
              return;
            }
            const driveAssignment = driveAssignments[index];
            const activePrimaryDrivePos = driveAssignment?.overridePrimaryPath
              ? driveAssignment?.activePrimaryOverrideDrivePos
              : driveAssignment?.activePrimaryDrivePos
            const disk = drives.find(drive => drive.position === activePrimaryDrivePos);
            const primary = new ChannelDiskInfo(encoder?.profile, disk, filenames[index]?.primary, driveAssignment?.overridePrimaryPath);
            info.push(primary);
            if (driveAssignment && (driveAssignment.activeWriteMode === 'redundant' || driveAssignment.activeWriteMode === 'rollover')) {
              const activeSecondaryDrivePos = driveAssignment?.overrideSecondaryPath
                ? driveAssignment?.activeSecondaryOverrideDrivePos
                : driveAssignment?.activeSecondaryDrivePos
              const diskSecondary = drives.find(drive => drive.position === activeSecondaryDrivePos);
              const secondary = new ChannelDiskInfo(encoder?.profile + ' (R)', diskSecondary, filenames[index]?.secondary, driveAssignment?.overrideSecondaryPath);
              info.push(secondary);
            }
          });
          return info;
        }),
        distinctUntilChanged(isEqual),
        takeUntil(this.updateInfo),
        tap(info => console.log('[DISK][INFO] ', info)),
      )
      .subscribe(info => this.info = info);

    // Update record status
    this.streamService.getStatus(this.stream)
      .pipe(
        map(status => status?.record?.record),
        distinctUntilChanged(isEqual),
        takeUntil(this.updateInfo)
      )
      .subscribe(record => {
        if (record !== this.isRecord) {
          this.isRecord = record;
          this.getInfo();
        }
      });

    // Update filename
    this.streamService.filenameChanged(this.stream)
      .pipe(
        filter(result => !!result),
        takeUntil(this.updateInfo)
      )
      .subscribe(() => this.getInfo());

  }

  changeShowView(): void {
    this.changeView.next();
  }

}
