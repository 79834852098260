import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

// RxJS
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, throttleTime } from 'rxjs/operators';

// Types
import { Timecode } from '@modules/elements/types/timecode';
import { Clip } from '@modules/deck/types/clip';
import { Playlist, TimecodeNull } from '@modules/playlist-editor/types/playlist';
import { MoveElementEvent } from '@modules/elements/directives/move-element/move-element.directive';
import { Marker } from '@modules/marker/types/marker';
import { PlaylistService } from '@modules/playlist-editor/services/playlist.service';
import { Stream } from '@modules/stream/types/stream';
import { isEqual } from 'lodash-es';
import { DeckService } from '@modules/deck/services/deck.service';

@Component({
  selector: 'app-player-progress-bar',
  templateUrl: './player-progress-bar.component.html',
  styleUrls: ['./player-progress-bar.component.less']
})
export class PlayerProgressBarComponent implements OnInit, OnChanges, OnDestroy {

  // ViewChild
  @ViewChild('progress', { static: false }) progressRef: ElementRef;
  @ViewChild('currentPosition', { static: false }) currentPositionRef: ElementRef;
  @ViewChild('hoverPosition', { static: false }) hoverPositionRef: ElementRef;
  @ViewChild('hoverTC', { static: false }) hoverTCRef: ElementRef;
  @ViewChild('inPointPosition', { static: false }) inPointPositionRef: ElementRef;
  @ViewChild('outPointPosition', { static: false }) outPointPositionRef: ElementRef;
  @ViewChild('clipProgress', { static: false }) clipProgressRef: ElementRef;

  // Input
  @Input() stream: Stream;
  @Input() clip: Clip;
  @Input() inPoint: string | number = null;
  @Input() outPoint: string | number = null;
  @Input() showMarkers: boolean = false;

  // Output
  @Output() jump = new EventEmitter<{tc?: string, frame?: number, offset?: number}>();

  // Public
  public currentFrame: number = 0;
  public duration: number = 1;
  public showHoverPosition = false;
  public hoverCurrentPosition = false;
  public hoverMarker = false;
  public dragCurrentPosition = false;
  public dragFromFrame: number;
  public markers: Marker[] = [];
  public clipId = new BehaviorSubject<number>(null);

  // Private
  private alive = new Subject<void>();
  private jumpToFrame = new Subject<number>();

  /**
   * Constructor
   */

  constructor(
    public playlistService: PlaylistService,
    public deckService: DeckService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Handle Jump to Frame
    this.jumpToFrame
      .pipe(
        throttleTime(100),
        takeUntil(this.alive)
      )
      .subscribe(frame => {
        this.jump.emit({ frame });
      });
    // Get Markers
    this.clipId.asObservable()
      .pipe(
        map(clipId => ({clipId, deckId: this.stream?.deckId})),
        distinctUntilChanged(isEqual),
        filter(({clipId, deckId}) => {
          if (!clipId || !deckId) {
            this.markers = [];
            return false;
          }
          return true;
        }),
        switchMap(result => {
          const deck = this.deckService.getDeckSync(result.deckId);
          return this.deckService.loggerDBChanged(deck)
            .pipe(
              startWith(<string>null),
              map(() => result)
            )
        }),
        switchMap(({clipId, deckId}) => this.playlistService.getMarkers(deckId, [clipId])),
        takeUntil(this.alive)
      )
      .subscribe(markers => this.markers = markers);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('stream' in changes) {
      this.clipId.next(this.clip?.id);
    }
    if ('clip' in changes) {
      this.duration = this.clip?.durationFrames ?? 1;
      console.log('[CLIP] ', this.clip);
      setTimeout(() => this.updateInOutPoints(), 100);
      this.clipId.next(this.clip?.id);
    }
    if ('inPoint' in changes || 'outPoint' in changes) {
      this.inPoint = (this.inPoint && this.inPoint === TimecodeNull) ? null : +this.inPoint;
      this.outPoint = (this.outPoint && this.outPoint === TimecodeNull) ? null : +this.outPoint;
      setTimeout(() => this.updateInOutPoints(), 100);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  setValue(value: number | string): void {
    if (value !== null && value !== undefined && this.clip) {
      try {
        const timecode = new Timecode(value, this.clip.timebase, !!this.clip.drop);
        this.currentFrame = timecode.frameCount - this.clip.startTimecodeFrames;
        if (this.currentPositionRef && !this.dragCurrentPosition) {
          this.currentPositionRef.nativeElement.style.left = ((this.currentFrame / this.duration) * 100) + '%';
        }
      } catch (error) {
        console.error('[ProgressBar] ', error)
      }
    }
  }

  getPositionAndTimecode(event: MouseEvent): ({x: number, timecode: Timecode, frame: number}) {
    const rect = this.progressRef.nativeElement.getBoundingClientRect();
    const x = Math.min(event.clientX - rect.left, rect.width);
    const persent = x / rect.width;
    let frames = Math.floor(this.duration * persent);
    frames = Math.min(Math.max(frames, 0), (this.clip.durationFrames - 1));
    const timecode = new Timecode(this.clip.startTimecode, this.clip.timebase, !!this.clip.drop);
    timecode.add(frames);
    return {x, timecode, frame: frames};
  }

  updateInOutPoints(): void {
    // In Point
    if (this.inPoint !== null && this.inPointPositionRef && this.clip) {
      const inPointCurrentFrame = +this.inPoint - this.clip.startTimecodeFrames;
      this.inPointPositionRef.nativeElement.style.left = ((inPointCurrentFrame / this.duration) * 100) + '%';
    }
    // Out Point
    if (this.outPoint !== null && this.outPointPositionRef && this.clip) {
      const outPointCurrentFrame = +this.outPoint - this.clip.startTimecodeFrames;
      this.outPointPositionRef.nativeElement.style.left = ((outPointCurrentFrame / this.duration) * 100) + '%';
    }
    if (this.clipProgressRef) {
      if (this.clip && 'startTimecodeFrames' in this.clip && 'endTimecodeFrames' in this.clip) {
        const inPointCurrentFrame = Math.max(+this.inPoint - this.clip.startTimecodeFrames, 0);
        const clipFramesSize = (+this.outPoint || this.clip.endTimecodeFrames) - (+this.inPoint || this.clip.startTimecodeFrames);
        this.clipProgressRef.nativeElement.style.left = ((inPointCurrentFrame / this.duration) * 100) + '%';
        this.clipProgressRef.nativeElement.style.width = ((clipFramesSize / this.duration) * 100) + '%';
      } else {
        this.clipProgressRef.nativeElement.style.left = 0;
        this.clipProgressRef.nativeElement.style.width = 0;
      }
    }
  }

  /**
   * Actions
   */

  selectProgress(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { frame } = this.getPositionAndTimecode(event);
    this.jump.emit({ frame });
  }

  hoverProgress(event): void {
    const { x, timecode } = this.getPositionAndTimecode(event);
    if (this.hoverPositionRef && x > 0) {
      this.hoverPositionRef.nativeElement.style.left = x + 'px';
    }
    if (this.hoverTCRef) {
      this.hoverTCRef.nativeElement.style.left = x + 'px';
      this.hoverTCRef.nativeElement.innerHTML = timecode.toString() ?? '00:00:00:00';
    }
  }

  /**
   * Drag and drop Playback position
   */

  dragStartPlaybackPosition(event: MoveElementEvent): void {
    this.dragCurrentPosition = true;
    this.dragFromFrame = this.currentFrame;
  }

  dragMovePlaybackPosition(event: MoveElementEvent): void {
    const diffPersent = event.difference.x / this.progressRef.nativeElement.offsetWidth;
    const diffFrame = Math.floor((this.duration - 1) * diffPersent);
    const frame = Math.max(0, Math.min(this.duration - 1, this.dragFromFrame + diffFrame));
    
    const timecode = new Timecode(this.clip.startTimecode, this.clip.timebase, !!this.clip.drop);
    timecode.add(frame);
    if (this.currentPositionRef) {
      this.currentPositionRef.nativeElement.style.left = (frame / (this.duration - 1)) * 100 + '%';
    }
    if (this.hoverTCRef) {
      this.hoverTCRef.nativeElement.style.left = (frame / (this.duration - 1)) * 100 + '%';
      this.hoverTCRef.nativeElement.innerHTML = timecode.toString() ?? '00:00:00:00';
    }
    this.jumpToFrame.next(frame);
  }

  dragEndPlaybackPosition(event: MoveElementEvent): void {
    this.dragCurrentPosition = false;
  }

  jumpTo(frame: number): void {
    if (frame >= 0 && frame < this.clip.durationFrames) {
      this.jump.emit({ frame });
    }
  }

  jumpToMarker(marker: Marker, event: MouseEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
    const point = +marker.inPoint - this.clip.startTimecodeFrames;
    this.jumpTo(point);
  }

}
