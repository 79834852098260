export class ChannelsGrid {
  row: number;
  column: number;
  count: number;
  size?: ChannelsGridSize[];

  constructor(row: number, column: number, count?: number, size?: ChannelsGridSize[]) {
    this.row = row;
    this.column = column;
    this.count = count ?? (row * column);
    this.size = size;
    if (!this.size) {
      this.size = [];
      for (let index = 0; index < this.count; index++) {
        this.size.push(new ChannelsGridSize(1, 1));
      }
    }
  }

  static options(): ChannelsGrid[] {
    return [
      // 1
      new ChannelsGrid(1, 1),
      // 2
      new ChannelsGrid(1, 2),
      new ChannelsGrid(2, 1),
      // 3
      new ChannelsGrid(1, 3),
      new ChannelsGrid(3, 1),
      new ChannelsGrid(3, 2, 3, [
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      // 4
      new ChannelsGrid(2, 2),
      new ChannelsGrid(4, 3, 4, [
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      new ChannelsGrid(3, 5, 4, [
        new ChannelsGridSize(3, 4),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      // 5
      new ChannelsGrid(2, 4, 5, [
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      new ChannelsGrid(5, 6, 5, [
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
      ]),
      new ChannelsGrid(4, 2, 5, [
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      // 6
      new ChannelsGrid(2, 3),
      new ChannelsGrid(3, 2),
      new ChannelsGrid(3, 4, 6, [
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      new ChannelsGrid(5, 4, 6, [
        new ChannelsGridSize(3, 4),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      // 8
      new ChannelsGrid(2, 4),
      new ChannelsGrid(4, 2),
      new ChannelsGrid(7, 6, 8, [
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
      ]),
      new ChannelsGrid(7, 6, 8, [
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(3, 3), 
      ]),
      new ChannelsGrid(4, 6, 8, [
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(3, 3),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      // 9
      new ChannelsGrid(3, 3),
      new ChannelsGrid(3, 4, 9, [
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      new ChannelsGrid(5, 14, 9, [
        new ChannelsGridSize(4, 7),
        new ChannelsGridSize(4, 7),
        new ChannelsGridSize(1, 2),
        new ChannelsGridSize(1, 2),
        new ChannelsGridSize(1, 2),
        new ChannelsGridSize(1, 2),
        new ChannelsGridSize(1, 2),
        new ChannelsGridSize(1, 2),
        new ChannelsGridSize(1, 2),
      ]),
      new ChannelsGrid(9, 12, 9, [
        new ChannelsGridSize(4, 6),
        new ChannelsGridSize(4, 6),
        new ChannelsGridSize(3, 4),
        new ChannelsGridSize(3, 4),
        new ChannelsGridSize(3, 4),
        new ChannelsGridSize(2, 3),
        new ChannelsGridSize(2, 3),
        new ChannelsGridSize(2, 3),
        new ChannelsGridSize(2, 3),
      ]),
      // 10
      new ChannelsGrid(4, 4, 10, [
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      new ChannelsGrid(4, 4, 10, [
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(2, 2),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
        new ChannelsGridSize(1, 1),
      ]),
      // 12
      new ChannelsGrid(3, 4),
      // 16
      new ChannelsGrid(4, 4),
    ];
  }
}

export class ChannelsGridSize {
  rows: number;
  cols: number;

  constructor(rows: number, cols: number) {
    this.rows = rows;
    this.cols = cols;
  }
}
