import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { ChannelService } from '@modules/channel/services/channel.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { LayoutService } from '@modules/layout/services/layout.service';

// Types
import { Channel } from '@modules/channel/types/channel';
import { Preset } from '@modules/preset/types/preset';
import { StreamService } from '@modules/stream/services/stream.service';
import { DraggableEvent } from '@modules/drag-n-drop/types/draggable-event';
import { Stream } from '@modules/stream/types/stream';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.less']
})
export class ChannelsListComponent implements OnInit, OnDestroy {

  // Public
  public locked = false;
  public channels: Channel[] = [];
  public fullscreenChannel: Channel;
  public dropArea = {
    show: false,
    type: 'stream' // 'stream' or 'preset'
  };
  public columns = 1;

  // Private
  private alive = new Subject<void>();
  private selectedPreset = new BehaviorSubject<Preset>(null);

  /**
   * Constructor
   */

  constructor(
    private channelService: ChannelService,
    private streamService: StreamService,
    private presetService: PresetService,
    private layoutService: LayoutService,
    public elementRef: ElementRef,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Streams
    this.channelService.getChannelsList()
      .pipe(takeUntil(this.alive))
      .subscribe(channels => {
        this.channels = channels;
        const row = this.elementRef?.nativeElement?.offsetHeight / 46;
        this.columns = Math.ceil(this.channels?.length / row);
      });

    // Fullscreen channel
    this.channelService.getFullscreenChannel()
      .pipe(takeUntil(this.alive))
      .subscribe(channel => this.fullscreenChannel = channel);

    // Get selected preset
    this.presetService.getCurrentPreset()
      .pipe(takeUntil(this.alive))
      .subscribe(this.selectedPreset);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  lock(): void {
    this.locked = !this.locked;
  }

  showChannelGrid(): void {
    this.channelService.selectChannels([]);
    this.layoutService.getLayout()
      .pipe(
        take(1)
      )
      .subscribe(layout => {
        layout.channelList = false;
        this.layoutService.updateLayout(layout);
      });
  }
  
  /**
   * Drag and drop List
   */

  dndDropList(event: CdkDragDrop<Channel[]>): void {
    moveItemInArray(this.channels, event.previousIndex, event.currentIndex);
  }

  /**
   * Drag and drop
   */

  dndEnter(event: DraggableEvent): void {
    this.dropArea.show = true;
    this.dropArea.type = event.dragData.type;
  }

  dndLeave(event: DraggableEvent): void {
    this.dropArea.show = false;
  }

  dndDragOver(event: DraggableEvent): void {
  }

  dndDrop(event: DraggableEvent): void {
    this.dropArea.show = false;
    if (event.dragData.type === 'stream') {
      const stream: Stream = event.dragData.data;
      const channel = new Channel();
      channel.streamId = stream?.id;
      if (channel.streamId) {
        this.channelService.addChannelToChannelsList(channel);
      }
    }
  }

}
