<div mat-dialog-title>
  <div class="dialog-header">
    <div class="title">Audio Delays</div>
    <div class="actions">
      <mat-icon svgIcon="close" class="action-icon" (click)="close()"></mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class="preset-settings-audio-delays-content">

    <div class="audio-delays" *ngIf="settings">
      <!-- Channels -->
      <div class="channels">
          <div class="channel" *ngFor="let audio of settings?.input?.audioDelays; let index = index" [class.inherit]="!isDefaultSetting('input.audioDelays[' + index + ']')">
            <div class="label">Channel {{ index + 1 }}</div>
            <input
              #inputAudioDelay
              class="input"
              type="number"
              min="0" max="1000"
              (keydown.enter)="$event.target.blur()"
              [value]="settings?.input?.audioDelays[index]"
              (blur)="updateSettings(index, $event)"
            />
          </div>
      </div>
    </div>

  </div>
</div>
