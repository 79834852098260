import { Injectable } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

// Types
import { Layout } from '../types/layout';
import { GlobalState } from '@modules/settings/types/global-state';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  // Private
  private layout: Layout;

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService
  ) {
    this.stateService.getState().subscribe((state: GlobalState) => this.layout = state.layout);
  }

  /**
   * Actions
   */

  getLayout(): Observable<Layout> {
    return this.stateService.getState()
      .pipe(
        map((state: GlobalState) => state.layout)
      );
  }

  updateLayout(layout: Layout): void {
    this.stateService.updateState({layout: {...this.layout, ...layout} });
  }

}
