<div class="stream-grid-content">

  <!-- Header -->
  <div class="list-header">
    <div class="title">Previews</div>
    <div class="actions">
      <mat-icon [svgIcon]="locked? 'lock' : 'unlock'" class="action-icon" (click)="lock()"></mat-icon>
      <mat-icon
        svgIcon="grid"
        class="action-icon"
        popover
        [popoverContent]="gridPopover"
        popoverPlacement="bottom"
        popoverTrigger="click"
        [popoverOffsetY]="-5"
        [popoverShowUntil]="popoverClose"
      ></mat-icon>
      <mat-icon svgIcon="channel-list" class="action-icon" (click)="showChannelList()"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="list-content" *ngIf="channels">
    <mat-grid-list [cols]="channelsGrid?.column" rowHeight="fit" class="grid-list">
      <mat-grid-tile
        *ngFor="let channel of channels; index as index"
        [colspan]="channelsGrid?.size[index]?.cols || 1"
        [rowspan]="channelsGrid?.size[index]?.rows || 1"
      >
        <app-channel
          class="stream"
          [selected]="selectedChannels | includesBy: channel.id : 'id'"
          [multiSelected]="selectedChannels?.length > 1"
          [disabled]="locked"
          [channel]="channel"
          (click)="selectChannel(channel, $event)"
        ></app-channel>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

</div>

<!-- Grid Popover -->
<ng-template #gridPopover>
  <app-channels-grid-options (valueChanged)="changedGrid()"></app-channels-grid-options>
</ng-template>


<!-- Fullscreen Channel -->
<div class="stream-grid-content fullscreen" *ngIf="fullscreenChannel">

  <!-- Content -->
  <div class="list-content">
    <app-channel
      class="stream"
      [selected]="false"
      [disabled]="locked"
      [channel]="fullscreenChannel"
      [fullscreen]="true"
    ></app-channel>
  </div>

</div>
