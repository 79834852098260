import { Injectable } from '@angular/core';

// RxJS
import { Observable, from } from 'rxjs';

// Services
import { ElectronService } from 'ngx-electron';

// Types
import { MessageBoxOptions, MessageBoxReturnValue } from 'electron';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  /**
   * Constructor
   */

  constructor(
    private electronService: ElectronService,
  ) { }

  /**
   * Methods
   */
  
  show(message: string = null, detail: string = null, buttons: string[] = ['Cancel', 'OK'], defaultButton: number = null): Observable<number> {
    return this.electronService.isElectronApp
      ? this.showElectron(message, detail, buttons, defaultButton)
      : this.showWeb(message, detail, buttons, defaultButton);
  }

  showElectron(message: string = null, detail: string = null, buttons: string[] = ['Cancel', 'OK'], defaultButton: number = null): Observable<number> {
    const options: MessageBoxOptions = {
      type: 'question',
      buttons: buttons,
      defaultId: defaultButton,
      title: 'Question',
      message: message,
      detail: detail,
    };
    const currentWindow = this.electronService.remote.getCurrentWindow();
    const promise = this.electronService.remote.dialog.showMessageBox(currentWindow, options);
    return from(promise)
      .pipe(
        map((response: MessageBoxReturnValue) => response.response)
      );
  }

  showWeb(message: string = null, detail: string = null, buttons: string[] = ['Cancel', 'OK'], defaultButton: number = null): Observable<number> {
    const confirm = new Promise<number>(function (resolve, reject) {
      const response = window.confirm(detail);
      return resolve(+response);
    });
    return from(confirm);
  }

}
