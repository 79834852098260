import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';

// External modules
import { ContextMenuModule } from 'ngx-contextmenu';

// Modules
import { ElementsModule } from '@modules/elements/elements.module';
import { PlaylistEditorModule } from '@modules/playlist-editor/playlist-editor.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';

// Components
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { ClipManagerComponent } from './components/clip-manager/clip-manager.component';
import { DirectoryTreeComponent } from './components/directory-tree/directory-tree.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    // Modules
    ElementsModule,
    PlaylistEditorModule,
    DragNDropModule,

    // External
    ContextMenuModule.forRoot(),

    // Material
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatTreeModule,
    DragDropModule,
  ],
  declarations: [
    FileManagerComponent,
    ClipManagerComponent,
    DirectoryTreeComponent
  ],
  exports: [
    FileManagerComponent
  ],
})
export class FileManagerModule { }
