import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// Services
import { StreamService } from '@modules/stream/services/stream.service';
import { PresetService } from '@modules/preset/services/preset.service';
import { PresetSettingsService } from '@modules/preset/services/preset-settings.service';

// Types
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputSettingsCopy } from '@modules/preset/types/input-settings-copy';
import { Stream } from '@modules/stream/types/stream';
import { Preset } from '@modules/preset/types/preset';
import { CharOutStatus } from '@modules/stream/types/charout-status';

@Component({
  selector: 'app-preset-settings-copy',
  templateUrl: './preset-settings-copy.component.html',
  styleUrls: ['./preset-settings-copy.component.less']
})
export class PresetSettingsCopyComponent implements OnInit, OnDestroy {

  // Public
  public stream: Stream;
  public preset: Preset;
  public streams: Stream[] = [];
  public selectedStreams: string[] = [];
  public settingCopy: InputSettingsCopy = new InputSettingsCopy();
  public charOutStatus: CharOutStatus;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private streamService: StreamService,
    private presetService: PresetService,
    private presetSettingsService: PresetSettingsService,
    public dialogRef: MatDialogRef<PresetSettingsCopyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {stream: Stream},
  ) {
    this.stream = data.stream;
  }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    // Get Streams
    this.presetService.getPreset(this.stream.presetId)
      .pipe(
        tap(preset => this.preset = preset),
        map(preset => Object.keys(preset.settings)),
        switchMap(keys => 
          this.streamService.getStreams()
            .pipe(
              map(streams => streams.filter(stream => keys.includes(stream.id)))
            )
        ),
        take(1)
      )
      .subscribe(streams => {
        this.streams = streams.filter(stream => stream.id !== this.stream.id);
        this.selectedStreams = this.streams.map(stream => stream.id);
      });
    // Enable status
    this.streamService.getCharOut(this.stream)
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(status => this.charOutStatus = status);
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  selectInput(stream: Stream): void {    
    const index = this.selectedStreams.findIndex(item => item === stream.id);
    if (index !== -1) {
      this.selectedStreams.splice(index, 1);
    } else {
      this.selectedStreams.push(stream.id);
    }
  }

  updateSettings(key: string): void {
    this.settingCopy[key] = !this.settingCopy[key];
  }

  copyCharout(streams: Stream[]): void {
    streams.forEach(stream => {
      this.streamService.updateCharOut(stream, this.charOutStatus);
    });
  }

  /**
   * Actions
   */

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    const streams = this.streams.filter(stream => this.selectedStreams.includes(stream.id));
    this.presetSettingsService.copySettings(this.stream, streams, this.settingCopy);
    if (this.settingCopy.outputOverlays) {
      this.copyCharout(streams);
    }
    this.dialogRef.close();
  }

}
