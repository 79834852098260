import { Pipe, PipeTransform } from '@angular/core';

// Services
import { NodeService } from '@modules/node/services/node.service';

@Pipe({
  name: 'path'
})
export class PathPipe implements PipeTransform {

  /**
   * Constructor
   */

  constructor(private nodeService: NodeService) { }

  /**
   * Transform
   */

  transform(value: string, ...args: unknown[]): unknown {
    return this.nodeService.path?.win32.normalize(value);
  }

}
